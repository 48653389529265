import { useContext, useEffect } from "react";
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { Box } from "@material-ui/core";
import { AppBarTitleContext } from "../../Context/AppBarTitleContext";
import { LocaleContext } from "../../Context/LocaleContext";
import { useHistory } from "react-router";

export default function SsafeLanguage() {
    const { updateLocale } = useContext(LocaleContext);
    const history = useHistory();

    const { updateTitle } = useContext(AppBarTitleContext);
    useEffect(() => {
        updateTitle(null);
    });


    const selectLocale = (locale) => {
        updateLocale(locale);
        history.push('/signup/ssafe');
    }

    const buttonStyle = {
        minWidth: '30%',
        minHeight: '60px',
        marginRight: '8px',
        marginLeft: '8px'
    };

    return (
        <Grid container spacing={0} justifyContent="center">
            <Grid item sm={12} md={5} lg={3}>
                <Box pb={2} display="flex" justifyContent="space-between">
                    <Button onClick={() => { selectLocale('en') }} style={buttonStyle} variant="contained" color="secondary">
                        English
                    </Button>
                    <Button onClick={() => { selectLocale('ar') }} style={buttonStyle} variant="contained" color="secondary">
                        عربى
                    </Button>
                    <Button onClick={() => { selectLocale('zh') }} style={buttonStyle} variant="contained" color="secondary">
                        中文
                    </Button>
                </Box>

                <Box display="flex" justifyContent="space-between">
                    <Button onClick={() => { selectLocale('fr') }} style={buttonStyle} variant="contained" color="secondary">
                        Français
                    </Button>
                    <Button onClick={() => { selectLocale('ru') }} style={buttonStyle} variant="contained" color="secondary">
                        Русский
                    </Button>
                    <Button onClick={() => { selectLocale('es') }} style={buttonStyle} variant="contained" color="secondary">
                        Español
                    </Button>
                </Box>
            </Grid>
        </Grid>);
}
