import { Box, Table, TableCell, TableContainer, TableSortLabel, TableHead, TableRow, TableBody } from "@material-ui/core";
import { Fragment, useEffect, useRef, useState } from "react";
import { BRCGS_CHART_COLORS } from "../../../Constants/BrcgsChartColors";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import { FormattedMessage, useIntl } from "react-intl";
import FilterListIcon from '@material-ui/icons/FilterList';
import PriorityLabel from "../../Translation/PriorityLabel";
import { priorityTranslated } from "../../Translation/Priority";
import BarLazy from "../../Chart/BarLazy";

export default function Priorities(props) {
    const intl = useIntl();
    const [visType, setVisType] = useState('chart');
    const [sortBy, setSortBy] = useState(null);
    const [sortOrder, setSortOrder] = useState('desc');
    const chartRef = useRef(null);
    const prioTypes = ['Personal', 'Company'];

    useEffect(() => {
        const mediaQueryListener = window.matchMedia('print');
        const onPrint = (mql) => {
            if (chartRef.current && mql.matches) {
                console.log('onPrint resize');
                chartRef.current.resize();
            }
        }

        const afterPrint = () => {
            if (chartRef.current) {
                console.log('afterPrint resize');
                chartRef.current.resize();
            }
        };
        window.addEventListener('afterprint', afterPrint);
        mediaQueryListener.addEventListener('change', onPrint);

        return () => {
            window.removeEventListener('afterprint', afterPrint);
            mediaQueryListener.removeEventListener('change', onPrint);
        }
    }, [chartRef]);

    if (!props.reportData.data || 0 === Object.keys(props.reportData.data).length || !props.surveyData || 0 === Object.keys(props.surveyData.attributes).length) {
        return null;
    }

    const prioColors = {
        chart: {
            Personal: {
                background: props.surveyData.attributes.type === 'brcgs' ? BRCGS_CHART_COLORS[0].backgroundColor : '#f8de57',
                border: props.surveyData.attributes.type === 'brcgs' ? BRCGS_CHART_COLORS[0].borderColor : '#fbeea5',
            },
            Company: {
                background: props.surveyData.attributes.type === 'brcgs' ? BRCGS_CHART_COLORS[1].backgroundColor : '#e21f4d',
                border: props.surveyData.attributes.type === 'brcgs' ? BRCGS_CHART_COLORS[1].borderColor : '#ef848e',
            },
        },
        table: {
            Personal: {
                head: props.surveyData.attributes.type === 'brcgs'? BRCGS_CHART_COLORS[0].hoverBackgroundColor : '#fbeea5',
                body: props.surveyData.attributes.type === 'brcgs' ? BRCGS_CHART_COLORS[0].hoverBackgroundColor : '#fef6ce',
                border: props.surveyData.attributes.type === 'brcgs' ? BRCGS_CHART_COLORS[0].borderColor : '#ffd600',
            },
            Company: {
                head: props.surveyData.attributes.type === 'brcgs' ? BRCGS_CHART_COLORS[1].hoverBorderColor : '#ef848e',
                body: props.surveyData.attributes.type === 'brcgs' ? BRCGS_CHART_COLORS[1].hoverBackgroundColor : '#f6bbbc',
                border: props.surveyData.attributes.type === 'brcgs' ? BRCGS_CHART_COLORS[1].borderColor : '#e21f4d',
            },
        }
    };

    const toggleSort = (column) => {
        // Toggle direction on already sorted by column
        if (sortBy === column) {
            setSortOrder(prevOrder => (prevOrder === 'asc' ? 'desc' : 'asc'));
        } else {
            // Sort by new column, descending
            setSortBy(column);
            setSortOrder('desc');
        }
    };

    const chartData = {
        labels: [],
        datasets: [],
    };

    const prioTypesTranslated = {
        Personal: intl.formatMessage({id: 'priorities.personal', defaultMessage: 'Personal'}),
        Company: intl.formatMessage({id: 'priorities.company', defaultMessage: 'Company'})
    };

    prioTypes.forEach(prioType => {
        chartData.datasets.push({
            label: prioTypesTranslated[prioType],
            backgroundColor: prioColors.chart[prioType] ? prioColors.chart[prioType].background : null,
            borderColor: prioColors.chart[prioType] ? prioColors.chart[prioType].border : null,
            data: [],
        });
    });

    // Labels for data series
    Object.keys(props.reportData.data).forEach(prioKey => {
        const prioLabel = priorityTranslated(prioKey, intl);
        if (props.print) {
            chartData.labels.push(prioLabel.split(' '));
        } else {
            chartData.labels.push(prioLabel);
        }

        // Add each prio type to datasets
        prioTypes.forEach((prioType, index) => {
            chartData.datasets[index].data.push(props.reportData.data[prioKey][prioType.toLowerCase()]);
        });
    });

    const chartOptions = {
        maintainAspectRatio: true,
        responsive: true,
        scales: {
            y: {
                min: 0,
                max: 5,
                title: {
                    display: true,
                    text: intl.formatMessage({id: 'priorities.axisTitle', defaultMessage: 'Average ranking (5 is highest)'})
                },
                ticks: {
                    stepSize: 0.5,
                }
            },
        },
        animation: {
            duration: 0,
        },
        plugins: {
            tooltip: {
                position: 'nearest',
            },
            datalabels: {
                anchor: 'end',
                align: 'top',
                clamp: true
            }
        },
    };

    if (props.print) {
        chartOptions.devicePixelRatio = 4; // Avoid charts looking blurry when printed
    }

    const table = () => {
        const sortedData = [];
        Object.keys(props.reportData.data).forEach((element) => {
            sortedData.push({
                personal: props.reportData.data[element].personal,
                company: props.reportData.data[element].company,
                label: <PriorityLabel title={element} />,
                conflict: Math.abs((props.reportData.data[element].personal-props.reportData.data[element].company).toFixed(2))
            });
        });

        if (sortBy) {
            sortedData.sort((a, b) => {
                return (a[sortBy] === null ? Number.MIN_SAFE_INTEGER : parseFloat(a[sortBy])) > (b[sortBy] === null ? Number.MIN_SAFE_INTEGER : parseFloat(b[sortBy])) ? 1 : -1
            });

            if (sortOrder === 'desc') {
                sortedData.reverse();
            }
        }

        return (
            <Box pt={1}>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell></TableCell>
                                <TableCell align="center" style={{backgroundColor: prioColors.table['Company'].head, borderColor: prioColors.table['Company'].border}}>
                                    <TableSortLabel
                                        IconComponent={FilterListIcon}
                                        active={sortBy === 'company'}
                                        direction={(sortBy === 'company' ? sortOrder : 'desc')}
                                        onClick={() => {toggleSort('company')}}
                                    >
                                        <FormattedMessage id="prioritiesReport.company" defaultMessage={"Company"} />
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell align="center" style={{backgroundColor: prioColors.table['Personal'].head, borderColor: prioColors.table['Personal'].border}}>
                                    <TableSortLabel
                                        IconComponent={FilterListIcon}
                                        active={sortBy === 'personal'}
                                        direction={(sortBy === 'personal' ? sortOrder : 'desc')}
                                        onClick={() => {toggleSort('personal')}}
                                    >
                                        <FormattedMessage id="prioritiesReport.personal" defaultMessage={"Personal"} />
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell align="center">
                                    <FormattedMessage id="prioritiesReport.conflict" defaultMessage={"Conflict"} />
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {sortedData.map((row, i) => (
                                <TableRow key={i}>
                                    <TableCell>{row.label}</TableCell>
                                    <TableCell align="center" style={{backgroundColor: prioColors.table['Company'].body, borderColor: prioColors.table['Company'].border}}>{row.company}</TableCell>
                                    <TableCell align="center" style={{backgroundColor: prioColors.table['Personal'].body, borderColor: prioColors.table['Personal'].border}}>{row.personal}</TableCell>
                                    <TableCell align="center">{row.conflict}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        );
    }

    return (
        <Fragment>
            <Box display="flex" justifyContent="center" style={props.print ? {display: 'none'} : {} }>
                <ToggleButtonGroup
                    value={visType}
                    exclusive
                    onChange={(event, newVisType) => setVisType(newVisType)}
                >
                    <ToggleButton value="chart" style={{textTransform: 'none'}}>
                        <FormattedMessage id="priorities.chart" defaultMessage="Chart"/>
                    </ToggleButton>
                    <ToggleButton value="table" style={{textTransform: 'none'}}>
                        <FormattedMessage id="priorities.table" defaultMessage="Table"/>
                    </ToggleButton>
                </ToggleButtonGroup>
            </Box>
            <div ref={props.setScreenshotRef}>
                {visType === 'chart' ?
                    <Box style={{overflow: 'auto', width: '100%', margin: '0 auto'}}>
                        <BarLazy innerRef={chartRef} className="chart-canvas" data={chartData} options={chartOptions} dataLabels={true} />
                    </Box>
                : table() }
            </div>
        </Fragment>
    );
}
