import { Button, Chip, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, TextField, Typography } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { Fragment, useContext, useMemo, useState } from "react";
import CompanyService from "../../Services/CompanyService";
import debounce from 'lodash.debounce';
import { NotificationContext } from "../../Context/NotificationContext";
import SurveyService from "../../Services/SurveyService";
import { SpinnerContext } from "../../Context/SpinnerContext";

export default function SurveyCopyMerge(props) {
    const { updateLoading, loading } = useContext(SpinnerContext);
    const { updateNotification } = useContext(NotificationContext);
    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
    const [targetSurveyTitle, setTargetSurveyTitle] = useState('');
    const [doneDialogOpen, setDoneDialogOpen] = useState(false);
    const [selectedSurveys, setSelectedSurveys] = useState([]);
    const [surveys, setSurveys] = useState([]);
    const [selectedCompany, setSelectedCompany] = useState(null);
    const [companies, setCompanies] = useState([]);
    const [loadingSurveys, setLoadingSurveys] = useState(false);
    const [loadingCompanies, setLoadingCompanies] = useState(false);

    const copyAndMerge = () => {
        updateLoading(true);
        setConfirmDialogOpen(false);
        SurveyService.copyAndMerge(selectedSurveys, selectedCompany, targetSurveyTitle)
        .then(function () {
            setSurveys([]);
            setSelectedSurveys([]);
            setCompanies([]);
            setTargetSurveyTitle('');
            setSelectedCompany(null);
            setDoneDialogOpen(true);
        }).catch(function () {
            updateNotification(true, 'An unknown error occurred!', 'error');
        }).then(function() {
            updateLoading(false);
        });
    };

    return (
        <Grid container spacing={2} justifyContent="center">
            <Grid item sm={8}>
                <Typography variant="h6">Merge Surveys</Typography>
                <Autocomplete
                    multiple
                    value={selectedSurveys}
                    disabled={loading}
                    options={surveys}
                    loading={loadingSurveys}
                    getOptionDisabled={(option) => option.attributes.providerStatus !== 'closed'}
                    onInputChange = {useMemo(() => debounce((event, value) => {
                        setLoadingSurveys(true)
                        SurveyService.surveysByHash(0, 20, null, value)
                        .then(function (response) {
                            setSurveys(response.data.data);
                        }).catch(function () {
                            updateNotification(true, 'An unknown error occurred!', 'error');
                        }).then(function() {
                            setLoadingSurveys(false);
                        });
                    }, 200), [updateNotification])}
                    onChange={(event, newValue) => {
                        if (newValue) {
                            setSelectedSurveys(newValue);
                        }
                    }}
                    getOptionLabel={(option) => null !== option ? (option.relationships['survey-title'] ? option.relationships['survey-title'].data.title : option.attributes.title) : null} // TODO: override title
                    renderOption={(option) => (
                        <Fragment>
                            {null !== option ? (option.relationships['survey-title'] ? option.relationships['survey-title'].data.title : option.attributes.title) : null}
                            <Chip color="primary" label={'Status: '+option.attributes.providerStatus} size="small" style={{marginRight: 5, marginLeft: 5 }} />
                            <Chip color="secondary" label={'Company: '+option.relationships.company.data.name} size="small" style={{marginRight: 5 }} />
                            {option.attributes.lastRespondentAt ? <Chip label={'Last response: '+(new Date(option.attributes.lastRespondentAt)).toLocaleDateString('en-gb', {year: 'numeric', month: 'short', day: 'numeric'})} size="small" style={{marginRight: 5}} />: null}
                        </Fragment>
                    )}
                    getOptionSelected={(option, value) => option.id === value.id}
                    renderInput={(params) => (
                        <TextField {...params}
                            label="Surveys"
                            required
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                  <Fragment>
                                      {loadingSurveys ? <CircularProgress color="inherit" size={20} /> : null}
                                      {params.InputProps.endAdornment}
                                  </Fragment>
                                ),
                              }}
                        />
                    )}
                />
            </Grid>
            <Grid item sm={4}>
                <Typography variant="h6">Target Company</Typography>
                <Autocomplete
                    options={companies}
                    value={selectedCompany}
                    loading={loadingCompanies}
                    disabled={loading}
                    getOptionLabel={(option) => option.attributes.name}
                    getOptionSelected={(option, value) => option.id === value.id}
                    onChange={(event, newValue) => {
                        if (newValue) {
                            setSelectedCompany(newValue);
                        }
                    }}
                    onInputChange = {useMemo(() => debounce((event, value) => {
                        setLoadingCompanies(true);
                        CompanyService.companies(0, 20, 'name', value)
                        .then(function (response) {
                            setCompanies(response.data.data);
                        }).catch(function () {
                            updateNotification(true, 'An unknown error occurred!', 'error');
                        }).then(function() {
                            setLoadingCompanies(false);
                        });
                    }, 200), [updateNotification])}
                    renderInput={(params) => (
                        <TextField {...params}
                            label="Company"
                            required
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                  <Fragment>
                                      {loadingCompanies ? <CircularProgress color="inherit" size={20} /> : null}
                                      {params.InputProps.endAdornment}
                                  </Fragment>
                                ),
                              }}
                        />
                    )}
                />
            </Grid>
            <Grid item sm={12}>
                <Typography variant="h6">Target Survey Title</Typography>
                <TextField
                    fullWidth={true}
                    disabled={loading}
                    label={'Title'}
                    value={targetSurveyTitle}
                    onChange={(e) => setTargetSurveyTitle(e.target.value)}
                    margin="normal" />
                <Button variant="contained" onClick={() => {setConfirmDialogOpen(true)}} color="secondary" disabled={1 >= selectedSurveys.length || !selectedCompany || loading}>
                    {'Copy & Merge Surveys'}
                </Button>

                <Dialog
                    open={confirmDialogOpen}
                    fullWidth={true}
                    maxWidth='lg'
                    onClose={() => {setConfirmDialogOpen(false)}}
                >
                    <DialogTitle>Surveys to Copy &amp; Merge</DialogTitle>
                    <DialogContent>
                        Please confirm the target company and the selected surveys seen below.<br />
                        <br />
                        Target company: <strong>{selectedCompany ? selectedCompany.attributes.name : null}</strong> <br />
                        <br />
                        Surveys:
                        <ul>
                            {selectedSurveys.map((survey, i) => (
                                <li key={i} style={{paddingBottom: 15}}>Title: <strong style={{backgroundColor: '#ddd'}}>{survey.relationships['survey-title'] ? survey.relationships['survey-title'].data.title : survey.attributes.title}</strong><br />Company: <strong style={{backgroundColor: '#ddd'}}>{survey.relationships.company.data.name}</strong></li>
                            ))}
                        </ul>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" onClick={() => {setConfirmDialogOpen(false)}} color="primary">
                            Cancel
                        </Button>
                        <Button variant="contained" onClick={copyAndMerge} color="secondary" autoFocus>
                            Confirm
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={doneDialogOpen}
                    onClose={() => setDoneDialogOpen(false)}
                >
                    <DialogTitle>Job Queued...</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Job to copy and merge the selected surveys into the target company has been queued, it may take some time to complete.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" onClick={() => {setDoneDialogOpen(false)}} color="primary">
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
            </Grid>
        </Grid>
    );
}