import { Box, Button, Grid, TextField, Tooltip, Typography } from "@material-ui/core";
import { FormattedMessage, useIntl } from 'react-intl'
import { useContext, useEffect, useRef, useState } from "react";
import { NotificationContext } from "../../Context/NotificationContext";
import { AppBarTitleContext } from "../../Context/AppBarTitleContext";
import { SpinnerContext } from "../../Context/SpinnerContext";
import { UserContext } from "../../Context/UserContext";
import SiteService from "../../Services/SiteService";
import CompanyService from "../../Services/CompanyService";
import { useParams } from "react-router-dom";
import { COUNTRIES } from '../../Constants/Countries';
import { countryTranslated } from "../Translation/Country";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import PersonIcon from '@material-ui/icons/Person';
import PeopleIcon from '@material-ui/icons/People';
import { industries } from "../../Utilities/Industry";


export default function SiteEdit() {
    const { updateNotification } = useContext(NotificationContext);
    const updateNotificationRef = useRef();
    updateNotificationRef.current = (open, message, level) => {
        updateNotification(open, message, level);
    }

    const { updateTitle } = useContext(AppBarTitleContext);
    const updateTitleRef = useRef();
    updateTitleRef.current = (newTitle) => {
        updateTitle(newTitle);
    };

    const { updateLoading } = useContext(SpinnerContext);
    const updateLoadingRef = useRef();
    updateLoadingRef.current = (loading) => {
        updateLoading(loading);
    };
    const { user } = useContext(UserContext);
    const intl = useIntl();

    const intlRef = useRef();
    intlRef.current = intl;

    let { uuid } = useParams();

    const [targetType, setTargetType] = useState('total');
    const [roles, setRoles] = useState({});

    const [values, setValues] = useState({
        name: '',
        value: '',
        code: '',
        countryCode: '',
        industry: '',
        totalTarget: '',
        roleTargets: [],
        companyUuid: null,
    });

    const countries = [];
    for (let key in COUNTRIES) {
        countries.push({label: countryTranslated(COUNTRIES[key], intl), value: key});
    }

    useEffect(() => {
        updateTitleRef.current(null);
        updateLoadingRef.current(true);

        // Fetch site info
        SiteService.site(uuid).then(function (response) {
            setValues(prevValues => ({
                ...prevValues,
                name: response.data.data.attributes.name,
                value: response.data.data.attributes.value,
                code: response.data.data.attributes.code ? response.data.data.attributes.code : '',
                countryCode: response.data.data.attributes.countryCode ? response.data.data.attributes.countryCode : '',
                industry: response.data.data.attributes.industry ? response.data.data.attributes.industry : '',
                totalTarget: response.data.data.attributes.totalTarget ? response.data.data.attributes.totalTarget : '',
                roleTargets: response.data.data.attributes.roleTargets ? response.data.data.attributes.roleTargets : prevValues.roleTargets,
                companyUuid: response.data.data.relationships.company.id
            }));
        }).catch(function (error) {
            updateNotificationRef.current(true, intlRef.current.formatMessage({id: 'generic.unknownError', defaultMessage: 'An unknown error occurred!'}), 'error');
        }).then(function() {
            updateLoadingRef.current(false);
        });
    }, [uuid]);

    useEffect(() => {
        // Fetch role info for company
        if (values.companyUuid) {
            CompanyService.roles(values.companyUuid).then(function (rolesResponse) {
                setRoles(rolesResponse.data.data);
                const companyRoleTargets = {};
                rolesResponse.data.data.forEach((role) => {
                    companyRoleTargets[role] = '';
                })

                setValues(prevValues => ({
                    ...prevValues,
                    roleTargets: {...companyRoleTargets, ...prevValues.roleTargets}
                }));
            });
        }
    }, [values.companyUuid]);

    const handleChange = name => event => {
        setValues({ ...values, [name]: event.target.value });
    };

    const handleRoleTargetChange = role => event => {
        setValues(prevValues => ({
            ...prevValues,
            roleTargets: ({
                ...prevValues.roleTargets,
                [role]: event.target.value
            })
        }));
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        updateLoading(true);

        const patchData = {...values};
        delete patchData.companyUuid;

        SiteService.patch(uuid, patchData).then(function (response) {
            updateNotification(true, intl.formatMessage({id: 'editSite.updated', defaultMessage: 'Site updated'}), 'success')
        }).catch(function (error) {
            updateNotification(true, intl.formatMessage({id: 'generic.unknownError', defaultMessage: 'An unknown error occurred!'}), 'error');
        }).then(function() {
            updateLoading(false);
        });
    };

    return(
        <Grid container spacing={0} justifyContent="center">
            <Grid item sm={10} md={5} lg={4}>
                <Box pb={1}>
                    <Typography variant="h6" paragraph>
                        <FormattedMessage id="editSite.header" defaultMessage="Edit Site" />
                    </Typography>
                    <form onSubmit={handleSubmit}>
                        <Box pb={1}>
                            <TextField
                                fullWidth
                                disabled={true}
                                label={intl.formatMessage({id: 'generic.name', defaultMessage: 'Name'})}
                                value={values.name}
                                onChange={handleChange('name')}
                                margin="normal" />
                            { user && user.role === 'admin' ?
                                <TextField
                                    fullWidth
                                    disabled={true}
                                    required
                                    label="Value"
                                    value={values.value}
                                    onChange={handleChange('value')}
                                    margin="normal" />
                            : null}
                            { user && user.role === 'admin' ?
                                <TextField
                                    fullWidth
                                    label="Code"
                                    value={values.code}
                                    onChange={handleChange('code')}
                                    margin="normal" />
                            : null }
                            <TextField
                                id="countryCode"
                                fullWidth
                                select
                                label={intl.formatMessage({id: 'generic.country', defaultMessage: 'Country'})}
                                value={values.countryCode}
                                onChange={handleChange('countryCode')}
                                margin="normal"
                                SelectProps={{
                                    native: true,
                                }}
                            >
                                <option value="" disabled></option>
                                {countries.map(option => (
                                    <option key={option.value} value={option.value} >
                                        {option.label}
                                    </option>
                                ))}
                            </TextField>
                            <TextField
                                id="industry"
                                fullWidth
                                select
                                label={intl.formatMessage({id: 'generic.industry', defaultMessage: 'Industry'})}
                                value={values.industry}
                                onChange={handleChange('industry')}
                                SelectProps={{
                                    native: true,
                                }}
                                margin="normal"
                            >
                                <option value="" disabled></option>
                                {industries().map(option => (
                                    <option key={option} value={option}>
                                        {option}
                                    </option>
                                ))}
                            </TextField>

                            <Typography variant="h6" style={{paddingTop: 16, paddingBottom: 16}}>
                                <FormattedMessage id="editSite.targets" defaultMessage="Targets" />
                            </Typography>

                            <ToggleButtonGroup
                                value={targetType}
                                exclusive
                                onChange={(e, newValue) => setTargetType(newValue)}
                            >
                                <ToggleButton value="total">
                                    <Tooltip title={intl.formatMessage({id: 'editSite.setOverallTarget', defaultMessage: 'Set overall target'})}>
                                        <PersonIcon />
                                    </Tooltip>
                                </ToggleButton>
                                <ToggleButton value="roles">
                                    <Tooltip title={intl.formatMessage({id: 'editSite.setTargetsPerRole', defaultMessage: 'Set targets per role'})}>
                                        <PeopleIcon />
                                    </Tooltip>
                                </ToggleButton>
                            </ToggleButtonGroup>
                            <Typography variant="body1" style={{paddingTop: 8}}>
                                <FormattedMessage id="editSite.targetNote" defaultMessage="Set either an overall target for the number of employees at the site or set the targets per role, you cannot set both." />
                            </Typography>

                            { 'total' === targetType ? <TextField
                                fullWidth
                                label={intl.formatMessage({id: 'editSite.totalEmployees', defaultMessage: 'Total employees'})}
                                value={values.totalTarget}
                                onChange={handleChange('totalTarget')}
                                margin="normal" />
                            : roles.map((val) => (
                                <TextField
                                    key={val}
                                    type="number"
                                    InputProps={{
                                        inputProps: {
                                            step: 1,
                                            min: 0,
                                        }
                                    }}
                                    fullWidth
                                    label={val}
                                    value={values.roleTargets[val]}
                                    onChange={handleRoleTargetChange(val)}
                                    margin="normal" />
                            ))}
                        </Box>
                        <Button
                            variant="contained"
                            className="submit"
                            color="secondary"
                            type="submit"
                            disabled={false}
                        >
                            <FormattedMessage id="generic.update" defaultMessage="Update" />
                        </Button>
                    </form>
                </Box>
            </Grid>
        </Grid>
    );
}
