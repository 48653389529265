import domtoimage from 'dom-to-image-more';

export function screenshot(ref, filename, bgcolor = '#ffffff') {
    const screenshotWidth = ref.current && ref.current.offsetWidth ? ref.current.offsetWidth : 800;
    const screenshotHeight = ref.current && ref.current.offsetHeight ? ref.current.offsetHeight : 600;
    domtoimage.toBlob(ref.current, {width:screenshotWidth, height:screenshotHeight, bgcolor: bgcolor }).then(blob => {
        const a = document.createElement('a');
        a.href = window.URL.createObjectURL(blob);
        a.download = filename;
        a.click();
    });
}