import React, { useContext, useEffect, useState } from "react";
import HCaptcha from '@hcaptcha/react-hcaptcha';
import { useParams } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Typography from "@material-ui/core/Typography";
import { COUNTRIES } from '../../Constants/Countries';
import { SURVEY_LANGUAGES } from '../../Constants/SurveyLanguages';
import Button from '@material-ui/core/Button';
import { Box, Dialog, DialogContent, DialogTitle, DialogActions, DialogContentText, FormControl, FormControlLabel, Link, InputAdornment, Tooltip, Checkbox } from "@material-ui/core";
import UserService from './../../Services/UserService';
import { NotificationContext } from "../../Context/NotificationContext";
import { SpinnerContext  } from "../../Context/SpinnerContext";
import { AppBarTitleContext } from "../../Context/AppBarTitleContext";
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { FormattedMessage, useIntl } from "react-intl";
import { LocaleContext } from "../../Context/LocaleContext";
import { industryTypeTranslated } from "../Translation/IndustryType";
import { countryTranslated } from "../Translation/Country";
import { languageTranslated } from "../Translation/Language";
import { brcgsIndustries, industries } from "../../Utilities/Industry";


const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
}));

export default function Signup() {
    const classes = useStyles();
    let { type } = useParams();
    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
    const [notVerifiedDialogOpen, setNotVerifiedDialogOpen] = useState(false);
    const [notVerifiedErrorMessage, setNotVerifiedErrorMessage] = useState(null);
    const [termsDialogOpen, setTermsDialogOpen] = useState(false);
    const [captchaToken, setCaptchaToken] = useState(null);
    const { updateNotification } = useContext(NotificationContext);
    const { updateLoading } = useContext(SpinnerContext);
    const { locale } = useContext(LocaleContext);
    const intl = useIntl();

    const { updateTitle } = useContext(AppBarTitleContext);
    useEffect(() => {
        updateTitle(null);
    });

    let initialLocale = 'en';
    if (locale !== initialLocale) {
        const localeSurveyMap = {
            ar: 'ar',
            zh: 'zh_CN',
            fr: 'fr',
            ru: 'ru',
            es: 'es'
        };
        initialLocale = locale in localeSurveyMap ? localeSurveyMap[locale] : 'en';
    }

    const [values, setValues] = React.useState({
        type: (type === 'brcgs-basic' ? 'brcgs-free' : type),
        siteCode: '',
        companyName: '',
        siteName: '',
        siteCountry: '',
        contactName: '',
        contactEmail: '',
        productCategory: '',
        defaultSurveyLanguage: initialLocale,
        acceptedTerms: false,
        locale: locale
    });

    // Update locale when user changes language in top bar
    useEffect(() => {
        const localeToSurveyLang = {
            ar: 'ar',
            zh: 'zh_CN',
            fr: 'fr',
            ru: 'ru',
            es: 'es'
        };
        setValues(oldValues => ({ ...oldValues, ...{locale: locale, defaultSurveyLanguage: (locale in localeToSurveyLang ? localeToSurveyLang[locale] : 'en')}}));
    }, [locale])

    const [message, setMessage] = React.useState(null);

    const countries = [];

    for (var key in COUNTRIES) {
        countries.push({ label: countryTranslated(COUNTRIES[key], intl), value: key });
    }

    //Sort countries by name
    countries.sort((a, b) => (a.label > b.label ? 1 : -1));

    if (!['ssafe', 'brcgs-basic'].includes(type)) {
        // TODO: Some NotFound component
        return (<div>Not found</div>);
    }

    const handleChange = name => event => {
        if ('acceptedTerms' === name) {
            setValues({ ...values, [name]: event.target.checked });
        } else {
            // Replace is due to alchemer bug (their API replaces ’ with ')
            setValues({ ...values, [name]: event.target.value.replace("’", "'").replace("‘", "'").replace("“", '"').replace("”", '"')});
        }
    };

    const confirmSubmit = (event) => {
        event.preventDefault();
        setConfirmDialogOpen(true);
    }

    const handleSubmit = () => {
        // To avoid multiple submits
        if (!confirmDialogOpen) {
            return;
        }
        setConfirmDialogOpen(false);
        updateLoading(true);

        UserService.signup(values, captchaToken)
        .then(function (response) {
            // If user is already enabled, don't show "registration in progress" message
            if (response.data.data.attributes.enabled) {
                setMessage(<React.Fragment>
                    <Typography variant="h6" paragraph>
                        <FormattedMessage id="signup.existingUserSurveyCreation" defaultMessage="Survey Setup in Progress" />
                    </Typography>
                    <Typography variant="body1" paragraph>
                        <FormattedMessage id="signup.existingUserConfirmation" defaultMessage="You will receive a welcome email when your survey has been set up, this may take a few minutes!" />
                    </Typography>
                    <Typography variant="body1" paragraph>
                        <FormattedMessage
                            id="signup.confirmationSpamNotice"
                            defaultMessage="<strong>Important</strong>: If you do not receive an email from <em>noreply@culturexcellence.com</em>, please check your spam/junk folder and any email filtering services being used."
                            values = {{
                                strong: chunks => <strong>{chunks}</strong>,
                                em: chunks => <em>{chunks}</em>,
                            }}
                        />
                    </Typography>
                </React.Fragment>);
            } else {
                setMessage(<React.Fragment>
                    <Typography variant="h6" paragraph>
                        <FormattedMessage id="signup.title" defaultMessage="Registration in Progress" />
                    </Typography>
                    <Typography variant="body1" paragraph>
                        <FormattedMessage id="signup.confirmation" defaultMessage="We have sent you a confirmation email. Please click the link in the email to confirm your registration." />
                    </Typography>
                    <Typography variant="body1" paragraph>
                        <FormattedMessage
                            id="signup.confirmationSpamNotice"
                            defaultMessage="<strong>Important</strong>: If you do not receive an email from <em>noreply@culturexcellence.com</em>, please check your spam/junk folder and any email filtering services being used."
                            values = {{
                                strong: chunks => <strong>{chunks}</strong>,
                                em: chunks => <em>{chunks}</em>,
                            }}
                        />
                    </Typography>
                </React.Fragment>);
            }


        }).catch(function (error) {
            if (error.response && error.response.status) {
                if (409 === error.response.status) {
                    console.log(error.response.data);
                    updateNotification(true, intl.formatMessage({id: 'signup.userWithUnverifiedEmailExists', defaultMessage: 'The email address was already registered but has not been verified.'}), 'warning');
                    setNotVerifiedDialogOpen(true);
                    setNotVerifiedErrorMessage(<span>
                        <FormattedMessage
                            id="signup.userWithUnverifiedEmailExistsDetails"
                            defaultMessage="The email address was already registered but has not been verified ({email})."
                            values={{email: values.contactEmail}}
                        />
                    </span>);

                } else if (400 === error.response.status && error.response.data.error.detail) {
                    updateNotification(true, error.response.data.error.detail, 'warning');
                } else {
                    updateNotification(true, intl.formatMessage({id: 'generic.unknownError', defaultMessage: 'An unknown error occurred!'}), 'error');
                }
            } else {
                updateNotification(true, intl.formatMessage({id: 'generic.unknownError', defaultMessage: 'An unknown error occurred!'}), 'error');
            }
            console.error(error);
        }).then(function () {
            updateLoading(false);
        });
    }

    const privacyStatement = (type) => {
        if ('brcgs-basic' === type) {
            return <FormattedMessage
                id="signup.brcgsBasicPrivacy"
                defaultMessage="Privacy statement: BRC Global Standards (BRCGS) is offering this Food Safety Culture Basic in partnership with TSI Quality Services (TSI).
                    BRCGS* and TSI Quality Services will process any personal data provided on this form in accordance with this Privacy Statement.
                    BRCGS and TSI will use such personal data for the purpose of administration and management of the Food Safety Culture Basic,
                    to follow up during and post-assessment, including information about promotions and upgrades.
                    Personal data will be transferred to TSI, which is located in Dubai, for these purposes.{br}
                    {br}
                    Additional information about how BRCGS processes personal data, including an explanation of data subject rights and how to exercise them,
                    a description of the legal basis for BRCGS’ processing, and how to raise a complaint with BRCGS, are set out in BRCGS’ Privacy Policy which
                    is available at: <a>https://www.brcgs.com/privacy-notice</a>{br}
                    {br}
                    * For UK data protection purposes, BRC Trading Limited is the name of the data controller."
                values = {{
                    a: chunks => <a href="https://www.brcgs.com/privacy-notice" target="_blank" rel="noreferrer">{chunks}</a>,
                    br: <br />
                }}
            />
        } else if ('ssafe' === type) {
            return <FormattedMessage
                id="signup.ssafePrivacy"
                defaultMessage="SSAFE is offering this SSAFE Food Safety Culture assessment in partnership with TSI Quality Services (TSI).
                    SSAFE and TSI Quality Services (TSI) will process any personal data provided on this form in accordance with this Privacy Statement.{br}
                    {br}
                    SSAFE and TSI will use such personal data for the purpose of administration and management of the SSAFE Food Safety Culture assessment,
                    to follow up during and post-assessment, including information about promotions and upgrades. Personal data will be transferred to TSI,
                    which is located in Dubai, for these purposes."
                values = {{
                    br: <br />
                }}
            />
        }
    }

    const onVerifyCaptcha = (token, ekey) => {
        setCaptchaToken(token);
    };

    // maintenance notice
    if (false) {
        return (
            <Grid container spacing={0} justifyContent="center">
                <Grid item sm={10} md={5} lg={4}>
                    <Typography variant="h6" paragraph>Ongoing Maintenance</Typography>
                    <Typography variant="body1" paragraph>Registration is down for maintenance, please try again at a later time!</Typography>
                </Grid>
            </Grid>
        );
    }

    return (
        <Grid container spacing={0} justifyContent="center">
            <Grid item sm={10} md={5} lg={4}>
                {message ? message :
                    <React.Fragment>
                        <Typography variant="h6" paragraph>
                            {(type === 'brcgs-basic' ?
                                intl.formatMessage({id: 'signup.brcgsFreeTitle', defaultMessage: 'BRCGS Food Safety Culture Excellence Basic: Registration'})
                            : intl.formatMessage({id: 'signup.ssafeTitle', defaultMessage: 'SSAFE Food Safety Culture Excellence Registration'}))}
                        </Typography>
                        <Typography variant="body1" paragraph>
                            <FormattedMessage id="signup.instructions" defaultMessage="Please complete the registration form." />
                        </Typography>
                        <Typography variant="body1" paragraph>
                            <FormattedMessage
                                id="signup.spamNotice"
                                defaultMessage="<strong>Important</strong>: If you do not receive an email from <em>noreply@culturexcellence.com</em> after registering, please check your spam/junk folder and any email filtering services being used."
                                values = {{
                                    strong: chunks => <strong>{chunks}</strong>,
                                    em: chunks => <em>{chunks}</em>,
                                }}
                            />
                        </Typography>

                        <form className={classes.container} onSubmit={confirmSubmit}>
                            <Box pb={1} style={{width: '100%'}}>
                                {'brcgs-basic' === type ? <TextField
                                    id="siteCode"
                                    fullWidth
                                    required
                                    type="number"
                                    InputProps={{
                                        inputProps: {
                                            step: 1,
                                            min: 0,
                                        },
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <Tooltip title={intl.formatMessage({id: 'signup.brcgsSiteCodeTooltip', defaultMessage: 'Please note: only BRCGS-certificated sites are eligible to register.'})} placement="right">
                                                    <HelpOutlineIcon />
                                                </Tooltip>
                                            </InputAdornment>
                                        )
                                    }}
                                    label={intl.formatMessage({id: 'signup.brcgsSiteCode', defaultMessage: 'BRCGS site code'})}
                                    value={values.siteCode}
                                    onChange={handleChange('siteCode')}
                                    margin="normal"
                                /> : null}
                                <TextField
                                    id="companyName"
                                    fullWidth
                                    required
                                    label={intl.formatMessage({id: 'signup.companyName', defaultMessage: 'Company name'})}
                                    value={values.companyName}
                                    onChange={handleChange('companyName')}
                                    margin="normal"
                                />
                                <Box pt={3}>
                                    <Typography variant="body1">
                                        {(
                                            'brcgs-basic' === type ?
                                                intl.formatMessage({id: 'signup.siteNameHelp', defaultMessage: 'Please use a unique name for the site, such as a town or geographical location that will be recognised by the employees who will take the survey. For example: London'}) :
                                                intl.formatMessage({id: 'signup.workplaceNameHelp', defaultMessage: 'Please use a unique name for the workplace, such as a town or geographical location that will be recognised by the employees who will take the survey. For example: London'})
                                        )}
                                    </Typography>
                                </Box>
                                <TextField
                                    id="siteName"
                                    fullWidth
                                    required
                                    label={'brcgs-basic' === type ? intl.formatMessage({id: 'signup.siteName', defaultMessage: 'Site name'}) : intl.formatMessage({id: 'signup.workplaceName', defaultMessage: 'Workplace name'})}
                                    value={values.siteName}
                                    onChange={handleChange('siteName')}
                                    margin="normal"
                                />
                                <TextField
                                    id="siteCountry"
                                    fullWidth
                                    select
                                    required
                                    label={'brcgs-basic' === type ? intl.formatMessage({id: 'signup.siteCountry', defaultMessage: 'Site country'}) : intl.formatMessage({id: 'signup.workplaceCountry', defaultMessage: 'Workplace country'})}
                                    value={values.siteCountry}
                                    onChange={handleChange('siteCountry')}
                                    SelectProps={{
                                        native: true
                                    }}
                                    margin="normal"
                                >
                                    <option value="" disabled></option>
                                    {countries.map(option => (
                                        <option key={option.value} value={option.value} >
                                            {option.label}
                                        </option>
                                    ))}
                                </TextField>
                                <Box pt={3}>
                                    <Typography variant="body1">
                                        {intl.formatMessage({id: 'signup.contactNameTooltip', defaultMessage: 'This is the person at the site who will receive the survey link, co-ordinate the collection of responses and have access to the report.'})}
                                    </Typography>
                                </Box>
                                <TextField
                                    style={{marginTop: '4px'}}
                                    id="contactName"
                                    fullWidth
                                    required
                                    label={intl.formatMessage({id: 'signup.contactName', defaultMessage: 'Contact name'})}
                                    value={values.contactName}
                                    onChange={handleChange('contactName')}
                                    margin="normal"
                                />
                                <TextField
                                    id="contactEmail"
                                    dir="ltr"
                                    InputProps={{style: { textAlign: 'ar' === locale ? 'right' : 'left' }}}
                                    fullWidth
                                    required
                                    label={intl.formatMessage({id: 'signup.contactEmail', defaultMessage: 'Contact email'})}
                                    type="email"
                                    value={values.contactEmail}
                                    onChange={handleChange('contactEmail')}
                                    margin="normal"
                                />
                                <TextField
                                    id="productCategory"
                                    fullWidth
                                    select
                                    required
                                    label={'brcgs-basic' === type ? intl.formatMessage({id: 'signup.productCategory', defaultMessage: 'Product category'}) : intl.formatMessage({id: 'signup.workplaceActivity', defaultMessage: 'Workplace activity'})}
                                    value={values.productCategory}
                                    onChange={handleChange('productCategory')}
                                    SelectProps={{
                                        native: true,
                                    }}
                                    helperText={'brcgs-basic' === type ?
                                        intl.formatMessage({id: 'signup.productCategoryHelp', defaultMessage: 'Please select your primary BRCGS Product Category. If your site covers more than one Product Category, please select the main category.'}) :
                                        intl.formatMessage({id: 'signup.workplaceActivityHelp', defaultMessage: 'If your workplace covers more than one activity, please select the main activity.'})
                                    }
                                    margin="normal"
                                >
                                    <option value="" disabled></option>
                                    {'brcgs-basic' === type ?
                                        brcgsIndustries().map(option => (
                                            <option key={option} value={option}>
                                                {industryTypeTranslated(option, intl)}
                                            </option>
                                        ))
                                        : industries().map(option => {
                                            if ('ssafe' === type && option !== 'Government (non-food)') {
                                                return (<option key={option} value={option}>
                                                    {industryTypeTranslated(option, intl)}
                                                </option>);
                                            }
                                            return null;
                                        })
                                    }
                                </TextField>
                                <Box pt={3}>
                                    <Typography variant="body1">
                                        {intl.formatMessage({id: 'signup.defaultSurveyLanguageHelp', defaultMessage: 'The survey is available in 50+ languages. Please select the default language for the survey, employees can change the language when taking the survey if required.'})}
                                    </Typography>
                                </Box>
                                <TextField
                                    id="defaultSurveyLanguage"
                                    fullWidth
                                    select
                                    value={values.defaultSurveyLanguage}
                                    onChange={handleChange('defaultSurveyLanguage')}
                                    SelectProps={{
                                        native: true
                                    }}
                                    margin="normal"
                                >
                                    {Object.keys(SURVEY_LANGUAGES).map(loc => (
                                        <option key={loc} value={loc}>
                                            {languageTranslated(SURVEY_LANGUAGES[loc], intl)}
                                        </option>
                                    ))}
                                </TextField>
                                <FormControl fullWidth>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={values.acceptedTerms}
                                                onChange={handleChange('acceptedTerms')}
                                                name="acceptedTerms"
                                                color="primary"
                                            />
                                        }
                                        label={<FormattedMessage
                                            id="signup.readTerms"
                                            defaultMessage="I have read and understood the <a>privacy statement</a>."
                                            values={ 'brcgs-basic' === type ? {
                                                a: chunks => <Link href="#" onClick={() => {setTermsDialogOpen(true);return false;}} style={{ color: 'blue', textDecoration: 'underline'}}>{chunks}</Link>,
                                            } : {
                                                a: chunks => <a href="https://www.ssafe-food.org/privacy-policy" target="_blank" rel="noreferrer">{chunks}</a>,
                                            }}
                                        />}
                                    />
                                </FormControl>
                            </Box>
                            <Box>
                            <HCaptcha
                                sitekey={process.env.REACT_APP_HCAPTCHA_SITEKEY}
                                onVerify={(token,ekey) => onVerifyCaptcha(token, ekey)}
                            />
                                <Button
                                    variant="contained"
                                    className="submit"
                                    color="secondary"
                                    type="submit"
                                    disabled={!values.acceptedTerms || null === captchaToken}
                                    >
                                        <FormattedMessage id="signup.register" defaultMessage="Register" />
                                </Button>
                            </Box>
                        </form>
                        <Dialog
                            open={confirmDialogOpen}
                            onClose={() => {setConfirmDialogOpen(false)}}
                        >
                            <DialogTitle><FormattedMessage id="signup.confirmTitle" defaultMessage="Important" /></DialogTitle>
                            <DialogContent>
                                <DialogContentText>
                                    <FormattedMessage id="signup.confirmQuestion" defaultMessage="The details entered on this page cannot be changed after registration. Are you sure you want to proceed?" />
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button variant="contained" onClick={() => {setConfirmDialogOpen(false)}} color="primary">
                                    <FormattedMessage id="signup.confirmCancel" defaultMessage="Cancel" />
                                </Button>
                                <Button variant="contained" onClick={handleSubmit} color="secondary" autoFocus>
                                    <FormattedMessage id="signup.confirmConfirm" defaultMessage="Confirm" />
                                </Button>
                            </DialogActions>
                        </Dialog>
                        <Dialog
                            open={termsDialogOpen}
                            onClose={() => {setTermsDialogOpen(false)}}
                        >
                            <DialogTitle><FormattedMessage id="signup.privacyTitle" defaultMessage="Privacy statement" /></DialogTitle>
                            <DialogContent>
                                <DialogContentText>
                                    {privacyStatement(type)}
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button variant="contained" onClick={() => {setTermsDialogOpen(false)}} color="secondary">
                                    <FormattedMessage id="signup.privacyClose" defaultMessage="Close" />
                                </Button>
                            </DialogActions>
                        </Dialog>
                        <Dialog
                            open={notVerifiedDialogOpen}
                            onClose={() => {setNotVerifiedDialogOpen(false)}}
                        >
                            <DialogTitle><FormattedMessage id="signup.notVerifiedTitle" defaultMessage="Email not verified" /></DialogTitle>
                            <DialogContent>
                                <DialogContentText>
                                    <Typography variant="body1" paragraph>
                                        {notVerifiedErrorMessage} <FormattedMessage id="signup.notVerifiedEmailSent" defaultMessage="An email was sent to the address containing a verification link."/>
                                    </Typography>
                                    <Typography variant="body1" paragraph>
                                        <FormattedMessage id="signup.notVerifiedSites" defaultMessage="No additional sites can be registered until the email address has been verified. Please locate the original email and click the verification link. After verification, additional sites can be registered."/>
                                    </Typography>
                                    <Typography variant="body1" paragraph>
                                        <FormattedMessage id="signup.notVerifiedWait" defaultMessage="If you cannot locate the original email, please wait 7 days then restart the registration process." />
                                    </Typography>
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button variant="contained" onClick={() => {setNotVerifiedDialogOpen(false)}} color="secondary">
                                    <FormattedMessage id="signup.notVerifiedClose" defaultMessage="Close" />
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </React.Fragment>
                }
            </Grid>
        </Grid>);
}
