import { Box, Button, Checkbox, FormControl, FormControlLabel, Grid, TextField, Tooltip, Typography } from "@material-ui/core";
import { SURVEY_LANGUAGES } from '../../Constants/SurveyLanguages';
import { FormattedMessage, useIntl } from 'react-intl'
import { useContext, useEffect, useState } from "react";
import { NotificationContext } from "../../Context/NotificationContext";
import { AppBarTitleContext } from "../../Context/AppBarTitleContext";
import { SpinnerContext } from "../../Context/SpinnerContext";
import CompanyService from "../../Services/CompanyService";
import { languageTranslated } from "../Translation/Language";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import { COUNTRIES } from '../../Constants/Countries';
import { countryTranslated } from "../Translation/Country";

export default function CompanyNew() {
    const { updateNotification } = useContext(NotificationContext);
    const { updateTitle } = useContext(AppBarTitleContext);
    const { updateLoading } = useContext(SpinnerContext);
    const intl = useIntl();

    const [values, setValues] = useState({
        name: '',
        defaultSurveyLanguage: '',
        countryCode: '',
    });

    const countries = [];
    for (let key in COUNTRIES) {
        countries.push({label: countryTranslated(COUNTRIES[key], intl), value: key});
    }

    useEffect(() => {
        updateTitle(null);
        // eslint-disable-next-line
    }, []);

    const handleChange = name => event => {
        if ('excludeBenchmark' === name) {
            setValues({ ...values, [name]: event.target.checked });
        } else {
            setValues({ ...values, [name]: event.target.value });
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        updateLoading(true);

        CompanyService.createCompany(values).then(function (response) {
            updateNotification(true, 'Company created', 'success');
        }).catch(function (error) {
            updateNotification(true, intl.formatMessage({id: 'generic.unknownError', defaultMessage: 'An unknown error occurred!'}), 'error');
        }).then(function() {
            updateLoading(false);
        });
    };

    return(
        <Grid container spacing={0} justifyContent="center">
            <Grid item sm={10} md={5} lg={4}>
                <Box pb={1}>
                    <Typography variant="h6" paragraph>
                        Create Company
                    </Typography>
                    <form onSubmit={handleSubmit}>
                        <Box pb={1}>
                            <TextField
                                id="name"
                                fullWidth
                                required
                                label="Name"
                                value={values.name}
                                onChange={handleChange('name')}
                                margin="normal" />
                            <TextField
                                id="defaultSurveyLanguage"
                                fullWidth
                                select
                                required
                                label="Default survey language"
                                value={values.defaultSurveyLanguage}
                                onChange={handleChange('defaultSurveyLanguage')}
                                SelectProps={{
                                    native: true,
                                }}
                                margin="normal"
                            >
                                <option value="" disabled></option>
                                {Object.keys(SURVEY_LANGUAGES).map(loc => (
                                    <option key={loc} value={loc}>
                                        {languageTranslated(SURVEY_LANGUAGES[loc], intl)}
                                    </option>
                                ))}
                            </TextField>
                            <TextField
                                id="countryCode"
                                fullWidth
                                select
                                label={intl.formatMessage({id: 'generic.country', defaultMessage: 'Country'})}
                                value={values.countryCode}
                                onChange={handleChange('countryCode')}
                                margin="normal"
                                SelectProps={{
                                    native: true,
                                }}
                            >
                                <option value=""></option>
                                {countries.map(option => (
                                    <option key={option.value} value={option.value} >
                                        {option.label}
                                    </option>
                                ))}
                            </TextField>
                            <FormControl fullWidth>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={values.excludeBenchmark}
                                            onChange={handleChange('excludeBenchmark')}
                                            name="enabled"
                                            color="secondary"
                                        />
                                    }
                                    label={
                                        <div style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            flexWrap: 'wrap',
                                        }}>
                                            <span>Exclude Benchmark</span>
                                            <Tooltip title="Exclude surveys and sites in this company in benchmark data (only has effect on imports/reimports AFTER this is enabled)" placement="right"><HelpOutlineIcon style={{marginLeft: 5}} /></Tooltip>
                                        </div>
                                    }
                                />
                            </FormControl>
                        </Box>
                        <Button
                            variant="contained"
                            className="submit"
                            color="secondary"
                            type="submit"
                            disabled={values.name.trim() === '' || values.defaultSurveyLanguage === ''}
                        >
                            <FormattedMessage id="generic.create" defaultMessage="Create" />
                        </Button>
                    </form>
                </Box>
            </Grid>
        </Grid>
    );
}
