import React, { useContext, useEffect, useRef, useState } from "react";
import { Redirect } from "react-router-dom";
import { UserContext } from "../../../Context/UserContext";
import { SpinnerContext } from "../../../Context/SpinnerContext";
import UserService from "../../../Services/UserService";

export default function Logout() {
    const { user, updateUser, updateToken } = useContext(UserContext);

    const userRef = useRef();
    userRef.current = user;
    const updateUserRef = useRef();
    updateUserRef.current = (newUser) => {
        updateUser(newUser);
    };
    const updateTokenRef = useRef();
    updateTokenRef.current = (newToken) => {
        updateToken(newToken);
    };

    const [redirect, setRedirect] = useState(null);
    const { updateLoading } = useContext(SpinnerContext);
    const updateLoadingRef = useRef();
    updateLoadingRef.current = (loading) => {
        updateLoading(loading);
    };

    useEffect(() => {
        updateLoadingRef.current(true);
        UserService.logout().catch(function (error) {
            console.error(error);
        }).then(function() {
            updateLoadingRef.current(false);
            const clearUser = () => {
                updateUserRef.current(null);
                updateTokenRef.current({expiry: 0, token: null});
            };
            // Force reload on themed login to avoid theme sometimes not being applied
            if (userRef.current && userRef.current.role && -1 !== ['brcgs-free', 'brcgs', 'brcgs-cb'].indexOf(userRef.current.role)) {
                clearUser();
                updateLoadingRef.current(false);
                window.location.href= '/login?theme=brcgs';
            } else if (userRef.current && userRef.current.role && 'ssafe' === userRef.current.role) {
                clearUser();
                updateLoadingRef.current(false);
                window.location.href= '/login?theme=ssafe';
            } else {
                clearUser();
                updateLoadingRef.current(false);
                setRedirect('/login');
            }
        });
    }, []);

    if (redirect) {
        return <Redirect to={redirect} />
    } else {
        return null;
    }
}