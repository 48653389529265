import { lazy, Suspense } from "react";
const Line = lazy(() => import(/* webpackChunkName: "charts" */'./Line'));

export default function LineLazy(props) {
    const {innerRef, ...rest} = props;
    return (
        <Suspense fallback={<div />}>
            <Line innerRef={innerRef} {...rest} />
        </Suspense>
    );
}