import { Alert } from "@material-ui/lab";
import { Fragment } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { barrierTranslated } from "../../../Translation/Barrier";

export default function Barriers(props) {
    const intl = useIntl();

    return (
        <Fragment>
            { props.title && props.print ? <h2 style={{fontWeight: 400}}>{props.title}</h2> : null}

            {!props.reportData['barriers'] || props.reportData['barriers'].length === 0 ? (
                <Alert style={{justifyContent: 'center'}} severity="warning" variant="filled">
                    {intl.formatMessage({id: 'report.noDataError', defaultMessage: 'There is no data available for this report'})}
                </Alert>
            ) : (
                <Fragment>
                    <p>
                        <FormattedMessage
                            id="execSummaryBarriers.p1"
                            defaultMessage="When asked for their opinion on cultural barriers, most people chose:"
                        />
                    </p>

                    <ol>
                        {props.reportData['barriers'].slice(0, 3).map((p, i) => (
                            <li key={i}>
                                {barrierTranslated(p.title, intl)}: {p.count} {intl.formatMessage({id: 'execSummaryBarriers.people', defaultMessage: 'people'})}
                            </li>
                        ))}
                    </ol>

                    <p>
                        <FormattedMessage
                            id="execSummaryBarriers.p2"
                            defaultMessage={
                                "The full range of cultural barriers identified can be seen and filtered in the "+
                                "<strong>Barriers</strong> view; these can be cross-referenced with the lowest dimensions "+
                                "and elements to gain a deeper insight and understanding of where the focus for cultural "+
                                "improvement should be."
                            }
                            values = {{
                                strong: chunks => <strong>{chunks}</strong>
                            }}
                        />
                    </p>
                </Fragment>
            )}
        </Fragment>
    );
}
