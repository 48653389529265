import { Box, Button, Tab, Tabs, makeStyles } from "@material-ui/core";
import { Fragment, useState } from "react"
import { FormattedMessage, useIntl } from "react-intl";

const useStyles = makeStyles(theme => ({
    topIndicator: {
        backgroundColor: theme.palette.secondary.main,
    },
    topTabs: {
        backgroundColor: theme.palette.primary.main,
        color: 'white'
    },
}));

export default function DataExport(props) {
    const intl = useIntl();
    const classes = useStyles();
    const [activeTab, setActiveTab] = useState(0);

    const downloadReport = (data, filename) => () => {
        const blob = new Blob([data], { type: 'text/csv' });
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement('a');
        a.href = url;
        const date = new Date();
        a.download = -1 !== filename.indexOf('%s') ? (filename.replace('%s', date.getFullYear()+'-'+(date.getMonth()+1)+'-'+date.getDate()+'_'+date.getHours()+'-'+date.getMinutes())) : filename;
        a.click();
    };

    const tabs = () => {
        if (0 === activeTab) {
            return (<Box py={2}>
                <p>
                    <FormattedMessage id="dataExport.basicDataDesc" defaultMessage="Basic data report with total, category and dimension scores for selected sites (if any) along with total, category and dimension scores for each selected site." />
                </p>
                <Button size="small" color="secondary" variant="outlined" onClick={downloadReport(props.reportData.data.basicDataCsv, 'basic-data-export-%s.csv')}>
                    <FormattedMessage id="generic.download" defaultMessage="Download" />
                </Button>
            </Box>);
        } else if (1 === activeTab) {
            return (<Box py={2}>
                <p>
                    <FormattedMessage id="dataExport.elementsDesc" defaultMessage="Elements report with element scores for selected sites (if any) along with element scores for each selected site." />
                </p>
                <Button size="small" color="secondary" variant="outlined" onClick={downloadReport(props.reportData.data.elementsCsv, 'elements-export-%s.csv')}>
                    <FormattedMessage id="generic.download" defaultMessage="Download" />
                </Button>
            </Box>);
        }
    };

    const handleTabChange = (event, newValue) => {
        setActiveTab(newValue);
    };

    return (
        <Fragment>
            <Tabs classes={{ root: classes.topTabs, indicator: classes.topIndicator }} value={activeTab} onChange={handleTabChange} variant="fullWidth">
                <Tab label={intl.formatMessage({id: 'reportmenu.basicData', defaultMessage: 'Basic Data'})} />
                <Tab label={intl.formatMessage({id: 'reportmenu.elements', defaultMessage: 'Elements'})} />
            </Tabs>
            {tabs()}
        </Fragment>
    );
}