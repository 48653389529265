import { Box } from "@material-ui/core";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import { useState } from "react";
import { Fragment } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import BarLazy from "../../Chart/BarLazy";
import { qualityTranslated } from "../../Translation/Quality";
import { chartColors } from "../../../Utilities/ChartColors";

export default function Quality(props) {
    const intl = useIntl();
    const [unit, setUnit] = useState('percentages');

    if (!props.reportData.data) {
        return null;
    }

    const chartData = {
        labels: [],
        datasets: [{
                data: [],
                backgroundColor: [],
                borderColor: [],
                hoverBackgroundColor: [],
                hoverBorderColor: [],
        }],
    };

    let max = 0;
    Object.keys(props.reportData.data).forEach(index => {
        if ('other' !== props.reportData.data[index].title.toLowerCase()) {
            chartData.labels.push(qualityTranslated(props.reportData.data[index].value, intl))
        } else {
            // Others should be multi to save horizontal space
            chartData.labels.push([
                intl.formatMessage({id: 'perceptionOfQuality.other', defaultMessage: 'Other'}),
                intl.formatMessage({id: 'perceptionOfQuality.otherNote', defaultMessage: '(see Qualitative Comments)'}),
            ]);
        }

        const c = chartColors('quality', props.reportData.data[index].title);
        chartData.datasets[0].backgroundColor.push(c.backgroundColor);
        chartData.datasets[0].borderColor.push(c.borderColor);
        chartData.datasets[0].hoverBackgroundColor.push(c.hoverBackgroundColor);
        chartData.datasets[0].hoverBorderColor.push(c.hoverBorderColor);

        if (unit === 'percentages') {
            chartData.datasets[0].data.push(props.reportData.data[index].percentage);
            max = props.reportData.data[index].percentage > max ? props.reportData.data[index].percentage : max;
        } else {
            chartData.datasets[0].data.push(props.reportData.data[index].count)
            max = props.reportData.data[index].count > max ? props.reportData.data[index].count : max;
        }

    });

    const chartOptions = {
        scales: {
            x: {
                title: {
                    display: true,
                    text: unit === 'percentages' ? intl.formatMessage({id: 'perceptionOfQuality.percent', defaultMessage: 'Percent (%)'}) : intl.formatMessage({id: 'perceptionOfQuality.responses', defaultMessage: 'Responses'})
                },
                min: 0,
                max: max + Math.floor(max/10), // Add a bit of space at the end to make sure datalabels do not overflow
                ticks: {
                    callback: (value, index, values) => {
                        return index === values.length-1 ? '' : value
                    }
                },
            }
        },
        indexAxis: 'y',
        animation: {
            duration: 0,
        },
        plugins: {
            legend: {
                display: false,
            },
            tooltip: {
                callbacks: {
                    label: (context) => {
                        const label = context.label || '';
                        if ('percentages' === unit) {
                            return label + ': ' + context.parsed.x.toFixed(1) + '%';
                        } else {
                            return label + ': ' + context.parsed.x;
                        }

                    }
                },
            },
            datalabels: {
                anchor: 'end',
                align: 'end',
                clamp: true,
                offset: 5,
                formatter: (value) => {
                    return value && 'percentages' === unit ? value.toFixed(0)+' %' : value;
                }
            }
        },
    };

    return (
        <Fragment>
            <Box display="flex" justifyContent="center">
                <ToggleButtonGroup
                    value={unit}
                    exclusive
                    onChange={(event, newUnit) => setUnit(newUnit)}
                >
                    <ToggleButton value="percentages" style={{textTransform: 'none'}}>
                        <FormattedMessage id="perceptionOfQuality.percentages" defaultMessage="Percentages"/>
                    </ToggleButton>
                    <ToggleButton value="numbers" style={{textTransform: 'none'}}>
                        <FormattedMessage id="perceptionOfQuality.numbers" defaultMessage="Numbers"/>
                    </ToggleButton>
                </ToggleButtonGroup>
            </Box>
            <div ref={props.setScreenshotRef}>
                <BarLazy data={chartData} options={chartOptions} dataLabels={true} />
            </div>
        </Fragment>
    );
}
