import axios from 'axios';
import BaseService from './BaseService';

class ContactService extends BaseService {
    brcgsUpgrade(siteCount, surveyId) {
        return axios.post(this.apiUrl('/contact/sales/brcgs-upgrade'), {
            data: {
                siteCount: siteCount,
                surveyId: surveyId,
            }
        },{
            headers: this.getAuthHeader()
        });
    }
}

export default new ContactService();
