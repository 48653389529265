import { Box, Button, Grid, InputAdornment, TextField, Tooltip, Typography, FormControl, FormControlLabel, Checkbox } from "@material-ui/core";
import { FormattedMessage, useIntl } from 'react-intl'
import { useContext, useEffect, useState } from "react";
import { NotificationContext } from "../../Context/NotificationContext";
import { AppBarTitleContext } from "../../Context/AppBarTitleContext";
import { UserContext } from "../../Context/UserContext";
import { SpinnerContext } from "../../Context/SpinnerContext";
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import UserService from "../../Services/UserService";
import { NavLink, useParams } from "react-router-dom";
import PasswordStrengthLazy from "../Auth/SetPassword/PasswordStrengthLazy";

export default function UserEdit() {
    const { updateNotification } = useContext(NotificationContext);
    const { user } = useContext(UserContext);
    const { updateTitle } = useContext(AppBarTitleContext);
    const { updateLoading } = useContext(SpinnerContext);
    const intl = useIntl();
    const minPasswordLength = 10;
    let { uuid } = useParams();

    const [values, setValues] = useState({
        enabled: false,
        role: '',
        name: '',
        email: '',
        password: '',
        verifyPassword: '',
    });

    const [company, setCompany] = useState('');

    useEffect(() => {
        updateTitle(null);
        updateLoading(true);

        // Fetch user info
        UserService.user(uuid).then(function (response) {
            setValues({
                enabled: response.data.data.attributes.enabled,
                role: response.data.data.attributes.role,
                name: response.data.data.attributes.name,
                email: response.data.data.attributes.email,
                password: '',
                verifyPassword: '',
            });
        setCompany(response.data.data.relationships.company.data.name);
        }).catch(function (error) {
            updateNotification(true, intl.formatMessage({id: 'generic.unknownError', defaultMessage: 'An unknown error occurred!'}), 'error');
        }).then(function() {
            updateLoading(false);
        });
        // eslint-disable-next-line
    }, []);



    const handleChange = name => event => {
        if ('enabled' === name) {
            setValues({ ...values, [name]: event.target.checked });
        } else {
            setValues({ ...values, [name]: event.target.value });
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        updateLoading(true);
        const valuesCopy = JSON.parse(JSON.stringify(values ? values : {}));
        delete valuesCopy.verifyPassword;
        if (valuesCopy.password === '') {
            delete valuesCopy.password;
        }

        UserService.patchUser(uuid, valuesCopy).then(function (response) {
            updateNotification(true, intl.formatMessage({id: 'account.accountUpdated', defaultMessage: 'Account updated'}), 'success')
        }).catch(function (error) {
            updateNotification(true, intl.formatMessage({id: 'generic.unknownError', defaultMessage: 'An unknown error occurred!'}), 'error');
        }).then(function() {
            updateLoading(false);
        });
    };

    const editableRoles = ['brcgs-free', 'brcgs', 'ssafe', 'premium'];
    const availableRoles = [];

    if (-1 !== editableRoles.indexOf(values.role)) {
        availableRoles.push(...editableRoles);
    } else {
        availableRoles.push(values.role);
    }

    return(
        <Grid container spacing={0} justifyContent="center">
            <Grid item sm={10} md={5} lg={4}>
                <Box pb={1}>
                    <Typography variant="h6" paragraph>
                        <FormattedMessage id="editUser.header" defaultMessage="Edit User" />
                    </Typography>
                    <form onSubmit={handleSubmit}>
                        <Box pb={1}>
                            <TextField
                                id="name"
                                fullWidth
                                required
                                label="Name"
                                value={values.name}
                                onChange={handleChange('name')}
                                margin="normal" />
                            <TextField
                                id="email"
                                fullWidth
                                type="email"
                                required
                                label="Email"
                                value={values.email}
                                onChange={handleChange('email')}
                                margin="normal"
                                helperText={(uuid === user.id ? <span>You can't edit the email address of your own account here, use the <NavLink to="/account">account page</NavLink> instead.</span> : null)}
                                InputProps={{
                                    disabled: uuid === user.id
                                }}/>
                            <TextField
                                id="company"
                                fullWidth
                                disabled={true}
                                type="text"
                                label="Company"
                                value={company}
                                margin="normal"
                                InputProps={{
                                    disabled: true
                                }}/>
                            <TextField
                                id="role"
                                disabled={-1 === editableRoles.indexOf(values.role)}
                                fullWidth
                                select
                                label="Role"
                                value={values.role}
                                onChange={handleChange('role')}
                                SelectProps={{
                                    native: true,
                                }}
                                margin="normal">
                                    {availableRoles.map(option => (
                                        <option key={option} value={option} >
                                            {option}
                                        </option>
                                    ))}
                            </TextField>
                            <TextField
                                error={(values.password !== '' && values.password.length < minPasswordLength)}
                                helperText={(values.password !== '' && values.password.length < minPasswordLength ? intl.formatMessage({id: 'userEdit.passwordTooShort', defaultMessage: 'The password has to be at least 10 characters'}) : ' ')}
                                id="password"
                                fullWidth
                                type="password"
                                label="New password"
                                value={values.password}
                                onChange={handleChange('password')}
                                margin="normal"
                                style={{marginBottom: 0}}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <Tooltip title={intl.formatMessage({id: 'userEdit.newPasswordTooltip', defaultMessage: 'If you do not wish to change the password, you can leave this field blank.'})} placement="right">
                                                <HelpOutlineIcon />
                                            </Tooltip>
                                        </InputAdornment>
                                    )
                                }} />
                            <PasswordStrengthLazy password={values.password} minPasswordLength={minPasswordLength} />
                            <TextField
                                id="verifyPassword"
                                error={values.verifyPassword !== '' && values.password !== values.verifyPassword}
                                helperText={(values.verifyPassword !== '' && values.password !== values.verifyPassword ? intl.formatMessage({id: 'userEdit.passwordsDoNotMatch', defaultMessage: 'Passwords do not match!'}) : ' ')}
                                fullWidth
                                type="password"
                                label="Repeat new password"
                                value={values.verifyPassword}
                                onChange={handleChange('verifyPassword')}
                                margin="normal"
                                style={{marginTop: 0}}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <Tooltip title={intl.formatMessage({id: 'userEdit.newPasswordTooltip', defaultMessage: 'If you do not wish to change the password, you can leave this field blank.'})} placement="right">
                                                <HelpOutlineIcon />
                                            </Tooltip>
                                        </InputAdornment>
                                    )
                                }}
                            />
                            <FormControl fullWidth>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={values.enabled}
                                            onChange={handleChange('enabled')}
                                            name="enabled"
                                            color="secondary"
                                            disabled={(uuid === user.id)}
                                        />
                                    }
                                    label="Enabled?"
                                />
                            </FormControl>
                        </Box>
                        <Button
                            variant="contained"
                            className="submit"
                            color="secondary"
                            type="submit"
                            disabled={values.password !== values.verifyPassword || ('' !== values.password && values.password.length < minPasswordLength) }
                        >
                            <FormattedMessage id="generic.update" defaultMessage="Update" />
                        </Button>
                    </form>
                </Box>
            </Grid>
        </Grid>
    );
}
