import { Box } from "@material-ui/core";
import { useIntl } from "react-intl"
import { chartColors } from "../../../Utilities/ChartColors";
import PolarAreaLazy from "../../Chart/PolarAreaLazy";
import { RGBAToHexA } from "../../../Utilities/Color";
import { environmentTranslated } from "../../Translation/Environment";

export default function Environment(props) {
    const intl = useIntl();

    if (!props.reportData.data) {
        return null;
    }

    const chartData = {
        labels: [],
        datasets: [
            {
                label: 'Environmental journey',
                hoverBackgroundColor: [],
                hoverBorderColor: [],
                backgroundColor: [],
                borderColor: [],
                data: [],
            }
        ]
    };

    props.reportData.data.forEach(r => {
        const stripStart = r.shortName;
        const alpha = '66';
        chartData.labels.push(stripStart.charAt(0).toUpperCase() + stripStart.slice(1));
        chartData.datasets[0].data.push(parseFloat(r.score));

        const c = chartColors('environment', r.shortName);
        chartData.datasets[0].backgroundColor.push(RGBAToHexA(c['backgroundColor']).substring(0,7)+alpha);
        chartData.datasets[0].borderColor.push(c['borderColor']);
        chartData.datasets[0].hoverBackgroundColor.push(c['hoverBackgroundColor']);
        chartData.datasets[0].hoverBorderColor.push(c['hoverBorderColor']);
    })

    const chartOptions = {
        scales: {
            r: {
                min: 0,
                max: 5,
                ticks: {
                    stepSize: 1,
                    callback: (value) => {
                        switch (value) {
                            case 5:
                                return intl.formatMessage({id: 'environment.leader', defaultMessage: 'Leader'});
                            case 4:
                                return intl.formatMessage({id: 'environment.well', defaultMessage: 'Doing well'});
                            case 3:
                                return intl.formatMessage({id: 'environment.progress', defaultMessage: 'Making progress'});
                            case 2:
                                return intl.formatMessage({id: 'environment.atStart', defaultMessage: 'At start with goals'});
                            case 1:
                                return intl.formatMessage({id: 'environment.noGoals', defaultMessage: 'No goals'});
                            default:
                                return '';
                        }
                    }
                },
            }
        },
        animation: {
            duration: 0,
        },
        plugins: {
            legend: {
                display: true,
            },
            tooltip: {
                position: 'nearest',
                callbacks: {
                    label: (context) => {
                        const label = context.label || '';
                        return label ? environmentTranslated(label, intl) : '';
                    }
                },
            },
        },
    };

    if (props.print) {
        chartOptions.devicePixelRatio = 4; // Avoid charts looking blurry when printed
        chartOptions.hover = {mode: null}; // Hover makes the chart disappear when printed
        chartOptions.plugins.tooltip.enabled = false; // Tooltip makes the chart disappear when printed
    }

    return (
        <Box style={{overflow: 'auto', width: '50vw', margin: '0 auto'}}>
            <div ref={props.setScreenshotRef}>
                <PolarAreaLazy data={chartData} options={chartOptions} dataLabels={false} />
            </div>
        </Box>
    );
}