export const countryTranslated = (country, intl) => {
    switch (country) {
        case 'Afghanistan':
            return intl.formatMessage({id: 'country.Afghanistan', defaultMessage: 'Afghanistan'});

        case 'Albania':
            return intl.formatMessage({id: 'country.Albania', defaultMessage: 'Albania'});

        case 'Algeria':
            return intl.formatMessage({id: 'country.Algeria', defaultMessage: 'Algeria'});

        case 'American Samoa':
            return intl.formatMessage({id: 'country.AmericanSamoa', defaultMessage: 'American Samoa'});

        case 'Andorra':
            return intl.formatMessage({id: 'country.Andorra', defaultMessage: 'Andorra'});

        case 'Angola':
            return intl.formatMessage({id: 'country.Angola', defaultMessage: 'Angola'});

        case 'Anguilla':
            return intl.formatMessage({id: 'country.Anguilla', defaultMessage: 'Anguilla'});

        case 'Antigua and Barbuda':
            return intl.formatMessage({id: 'country.AntiguaandBarbuda', defaultMessage: 'Antigua and Barbuda'});

        case 'Argentina':
            return intl.formatMessage({id: 'country.Argentina', defaultMessage: 'Argentina'});

        case 'Armenia':
            return intl.formatMessage({id: 'country.Armenia', defaultMessage: 'Armenia'});

        case 'Aruba':
            return intl.formatMessage({id: 'country.Aruba', defaultMessage: 'Aruba'});

        case 'Australia':
            return intl.formatMessage({id: 'country.Australia', defaultMessage: 'Australia'});

        case 'Austria':
            return intl.formatMessage({id: 'country.Austria', defaultMessage: 'Austria'});

        case 'Azerbaijan':
            return intl.formatMessage({id: 'country.Azerbaijan', defaultMessage: 'Azerbaijan'});

        case 'Bahamas':
            return intl.formatMessage({id: 'country.Bahamas', defaultMessage: 'Bahamas'});

        case 'Bahrain':
            return intl.formatMessage({id: 'country.Bahrain', defaultMessage: 'Bahrain'});

        case 'Bangladesh':
            return intl.formatMessage({id: 'country.Bangladesh', defaultMessage: 'Bangladesh'});

        case 'Barbados':
            return intl.formatMessage({id: 'country.Barbados', defaultMessage: 'Barbados'});

        case 'Belarus':
            return intl.formatMessage({id: 'country.Belarus', defaultMessage: 'Belarus'});

        case 'Belgium':
            return intl.formatMessage({id: 'country.Belgium', defaultMessage: 'Belgium'});

        case 'Belize':
            return intl.formatMessage({id: 'country.Belize', defaultMessage: 'Belize'});

        case 'Benin':
            return intl.formatMessage({id: 'country.Benin', defaultMessage: 'Benin'});

        case 'Bermuda':
            return intl.formatMessage({id: 'country.Bermuda', defaultMessage: 'Bermuda'});

        case 'Bhutan':
            return intl.formatMessage({id: 'country.Bhutan', defaultMessage: 'Bhutan'});

        case 'Bolivia':
            return intl.formatMessage({id: 'country.Bolivia', defaultMessage: 'Bolivia'});

        case 'Bosnia and Herzegovina':
            return intl.formatMessage({id: 'country.BosniaandHerzegovina', defaultMessage: 'Bosnia and Herzegovina'});

        case 'Botswana':
            return intl.formatMessage({id: 'country.Botswana', defaultMessage: 'Botswana'});

        case 'Brazil':
            return intl.formatMessage({id: 'country.Brazil', defaultMessage: 'Brazil'});

        case 'Brunei Darussalam':
            return intl.formatMessage({id: 'country.BruneiDarussalam', defaultMessage: 'Brunei Darussalam'});

        case 'Bulgaria':
            return intl.formatMessage({id: 'country.Bulgaria', defaultMessage: 'Bulgaria'});

        case 'Burkina Faso':
            return intl.formatMessage({id: 'country.BurkinaFaso', defaultMessage: 'Burkina Faso'});

        case 'Burundi':
            return intl.formatMessage({id: 'country.Burundi', defaultMessage: 'Burundi'});

        case 'Cambodia':
            return intl.formatMessage({id: 'country.Cambodia', defaultMessage: 'Cambodia'});

        case 'Cameroon':
            return intl.formatMessage({id: 'country.Cameroon', defaultMessage: 'Cameroon'});

        case 'Canada':
            return intl.formatMessage({id: 'country.Canada', defaultMessage: 'Canada'});

        case 'Cape Verde':
            return intl.formatMessage({id: 'country.CapeVerde', defaultMessage: 'Cape Verde'});

        case 'Cayman Islands':
            return intl.formatMessage({id: 'country.CaymanIslands', defaultMessage: 'Cayman Islands'});

        case 'Central African Republic':
            return intl.formatMessage({id: 'country.CentralAfricanRepublic', defaultMessage: 'Central African Republic'});

        case 'Chad':
            return intl.formatMessage({id: 'country.Chad', defaultMessage: 'Chad'});

        case 'Chile':
            return intl.formatMessage({id: 'country.Chile', defaultMessage: 'Chile'});

        case 'China':
            return intl.formatMessage({id: 'country.China', defaultMessage: 'China'});

        case 'Christmas Island':
            return intl.formatMessage({id: 'country.ChristmasIsland', defaultMessage: 'Christmas Island'});

        case 'Cocos (Keeling) Islands':
            return intl.formatMessage({id: 'country.CocosKeelingIslands', defaultMessage: 'Cocos (Keeling) Islands'});

        case 'Colombia':
            return intl.formatMessage({id: 'country.Colombia', defaultMessage: 'Colombia'});

        case 'Comoros':
            return intl.formatMessage({id: 'country.Comoros', defaultMessage: 'Comoros'});

        case 'Congo':
            return intl.formatMessage({id: 'country.Congo', defaultMessage: 'Congo'});

        case 'Congo, the Democratic Republic of the':
            return intl.formatMessage({id: 'country.CongotheDemocraticRepublicofthe', defaultMessage: 'Congo, the Democratic Republic of the'});

        case 'Cook Islands':
            return intl.formatMessage({id: 'country.CookIslands', defaultMessage: 'Cook Islands'});

        case 'Costa Rica':
            return intl.formatMessage({id: 'country.CostaRica', defaultMessage: 'Costa Rica'});

        case 'Cote D\'Ivoire':
            return intl.formatMessage({id: 'country.CoteDIvoire', defaultMessage: 'Cote D\'Ivoire'});

        case 'Croatia':
            return intl.formatMessage({id: 'country.Croatia', defaultMessage: 'Croatia'});

        case 'Cuba':
            return intl.formatMessage({id: 'country.Cuba', defaultMessage: 'Cuba'});

        case 'Cyprus':
            return intl.formatMessage({id: 'country.Cyprus', defaultMessage: 'Cyprus'});

        case 'Czech Republic':
            return intl.formatMessage({id: 'country.CzechRepublic', defaultMessage: 'Czech Republic'});

        case 'Denmark':
            return intl.formatMessage({id: 'country.Denmark', defaultMessage: 'Denmark'});

        case 'Djibouti':
            return intl.formatMessage({id: 'country.Djibouti', defaultMessage: 'Djibouti'});

        case 'Dominica':
            return intl.formatMessage({id: 'country.Dominica', defaultMessage: 'Dominica'});

        case 'Dominican Republic':
            return intl.formatMessage({id: 'country.DominicanRepublic', defaultMessage: 'Dominican Republic'});

        case 'Ecuador':
            return intl.formatMessage({id: 'country.Ecuador', defaultMessage: 'Ecuador'});

        case 'Egypt':
            return intl.formatMessage({id: 'country.Egypt', defaultMessage: 'Egypt'});

        case 'El Salvador':
            return intl.formatMessage({id: 'country.ElSalvador', defaultMessage: 'El Salvador'});

        case 'Equatorial Guinea':
            return intl.formatMessage({id: 'country.EquatorialGuinea', defaultMessage: 'Equatorial Guinea'});

        case 'Eritrea':
            return intl.formatMessage({id: 'country.Eritrea', defaultMessage: 'Eritrea'});

        case 'Estonia':
            return intl.formatMessage({id: 'country.Estonia', defaultMessage: 'Estonia'});

        case 'Ethiopia':
            return intl.formatMessage({id: 'country.Ethiopia', defaultMessage: 'Ethiopia'});

        case 'Falkland Islands (Malvinas)':
            return intl.formatMessage({id: 'country.FalklandIslandsMalvinas', defaultMessage: 'Falkland Islands (Malvinas)'});

        case 'Faroe Islands':
            return intl.formatMessage({id: 'country.FaroeIslands', defaultMessage: 'Faroe Islands'});

        case 'Fiji':
            return intl.formatMessage({id: 'country.Fiji', defaultMessage: 'Fiji'});

        case 'Finland':
            return intl.formatMessage({id: 'country.Finland', defaultMessage: 'Finland'});

        case 'France':
            return intl.formatMessage({id: 'country.France', defaultMessage: 'France'});

        case 'French Guiana':
            return intl.formatMessage({id: 'country.FrenchGuiana', defaultMessage: 'French Guiana'});

        case 'French Polynesia':
            return intl.formatMessage({id: 'country.FrenchPolynesia', defaultMessage: 'French Polynesia'});

        case 'French Southern Territories':
            return intl.formatMessage({id: 'country.FrenchSouthernTerritories', defaultMessage: 'French Southern Territories'});

        case 'Gabon':
            return intl.formatMessage({id: 'country.Gabon', defaultMessage: 'Gabon'});

        case 'Gambia':
            return intl.formatMessage({id: 'country.Gambia', defaultMessage: 'Gambia'});

        case 'Georgia':
            return intl.formatMessage({id: 'country.Georgia', defaultMessage: 'Georgia'});

        case 'Germany':
            return intl.formatMessage({id: 'country.Germany', defaultMessage: 'Germany'});

        case 'Ghana':
            return intl.formatMessage({id: 'country.Ghana', defaultMessage: 'Ghana'});

        case 'Gibraltar':
            return intl.formatMessage({id: 'country.Gibraltar', defaultMessage: 'Gibraltar'});

        case 'Greece':
            return intl.formatMessage({id: 'country.Greece', defaultMessage: 'Greece'});

        case 'Greenland':
            return intl.formatMessage({id: 'country.Greenland', defaultMessage: 'Greenland'});

        case 'Grenada':
            return intl.formatMessage({id: 'country.Grenada', defaultMessage: 'Grenada'});

        case 'Guadeloupe':
            return intl.formatMessage({id: 'country.Guadeloupe', defaultMessage: 'Guadeloupe'});

        case 'Guam':
            return intl.formatMessage({id: 'country.Guam', defaultMessage: 'Guam'});

        case 'Guatemala':
            return intl.formatMessage({id: 'country.Guatemala', defaultMessage: 'Guatemala'});

        case 'Guinea':
            return intl.formatMessage({id: 'country.Guinea', defaultMessage: 'Guinea'});

        case 'Guinea-Bissau':
            return intl.formatMessage({id: 'country.Guinea-Bissau', defaultMessage: 'Guinea-Bissau'});

        case 'Guyana':
            return intl.formatMessage({id: 'country.Guyana', defaultMessage: 'Guyana'});

        case 'Haiti':
            return intl.formatMessage({id: 'country.Haiti', defaultMessage: 'Haiti'});

        case 'Holy See (Vatican City State)':
            return intl.formatMessage({id: 'country.HolySeeVaticanCityState', defaultMessage: 'Holy See (Vatican City State)'});

        case 'Honduras':
            return intl.formatMessage({id: 'country.Honduras', defaultMessage: 'Honduras'});

        case 'Hong Kong':
            return intl.formatMessage({id: 'country.HongKong', defaultMessage: 'Hong Kong'});

        case 'Hungary':
            return intl.formatMessage({id: 'country.Hungary', defaultMessage: 'Hungary'});

        case 'Iceland':
            return intl.formatMessage({id: 'country.Iceland', defaultMessage: 'Iceland'});

        case 'India':
            return intl.formatMessage({id: 'country.India', defaultMessage: 'India'});

        case 'Indonesia':
            return intl.formatMessage({id: 'country.Indonesia', defaultMessage: 'Indonesia'});

        case 'Iran, Islamic Republic of':
            return intl.formatMessage({id: 'country.IranIslamicRepublicof', defaultMessage: 'Iran, Islamic Republic of'});

        case 'Iraq':
            return intl.formatMessage({id: 'country.Iraq', defaultMessage: 'Iraq'});

        case 'Ireland':
            return intl.formatMessage({id: 'country.Ireland', defaultMessage: 'Ireland'});

        case 'Israel':
            return intl.formatMessage({id: 'country.Israel', defaultMessage: 'Israel'});

        case 'Italy':
            return intl.formatMessage({id: 'country.Italy', defaultMessage: 'Italy'});

        case 'Jamaica':
            return intl.formatMessage({id: 'country.Jamaica', defaultMessage: 'Jamaica'});

        case 'Japan':
            return intl.formatMessage({id: 'country.Japan', defaultMessage: 'Japan'});

        case 'Jordan':
            return intl.formatMessage({id: 'country.Jordan', defaultMessage: 'Jordan'});

        case 'Kazakhstan':
            return intl.formatMessage({id: 'country.Kazakhstan', defaultMessage: 'Kazakhstan'});

        case 'Kenya':
            return intl.formatMessage({id: 'country.Kenya', defaultMessage: 'Kenya'});

        case 'Kiribati':
            return intl.formatMessage({id: 'country.Kiribati', defaultMessage: 'Kiribati'});

        case 'Korea, Democratic People\'s Republic of':
            return intl.formatMessage({id: 'country.KoreaDemocraticPeoplesRepublicof', defaultMessage: 'Korea, Democratic People\'s Republic of'});

        case 'Korea, Republic of':
            return intl.formatMessage({id: 'country.KoreaRepublicof', defaultMessage: 'Korea, Republic of'});

        case 'Kuwait':
            return intl.formatMessage({id: 'country.Kuwait', defaultMessage: 'Kuwait'});

        case 'Kyrgyzstan':
            return intl.formatMessage({id: 'country.Kyrgyzstan', defaultMessage: 'Kyrgyzstan'});

        case 'Lao People\'s Democratic Republic':
            return intl.formatMessage({id: 'country.LaoPeoplesDemocraticRepublic', defaultMessage: 'Lao People\'s Democratic Republic'});

        case 'Latvia':
            return intl.formatMessage({id: 'country.Latvia', defaultMessage: 'Latvia'});

        case 'Lebanon':
            return intl.formatMessage({id: 'country.Lebanon', defaultMessage: 'Lebanon'});

        case 'Lesotho':
            return intl.formatMessage({id: 'country.Lesotho', defaultMessage: 'Lesotho'});

        case 'Liberia':
            return intl.formatMessage({id: 'country.Liberia', defaultMessage: 'Liberia'});

        case 'Libya':
            return intl.formatMessage({id: 'country.LibyanArabJamahiriya', defaultMessage: 'Libya'});

        case 'Liechtenstein':
            return intl.formatMessage({id: 'country.Liechtenstein', defaultMessage: 'Liechtenstein'});

        case 'Lithuania':
            return intl.formatMessage({id: 'country.Lithuania', defaultMessage: 'Lithuania'});

        case 'Luxembourg':
            return intl.formatMessage({id: 'country.Luxembourg', defaultMessage: 'Luxembourg'});

        case 'Macao':
            return intl.formatMessage({id: 'country.Macao', defaultMessage: 'Macao'});

        case 'North Macedonia':
            return intl.formatMessage({id: 'country.MacedoniatheFormerYugoslavRepublicof', defaultMessage: 'North Macedonia'});

        case 'Madagascar':
            return intl.formatMessage({id: 'country.Madagascar', defaultMessage: 'Madagascar'});

        case 'Malawi':
            return intl.formatMessage({id: 'country.Malawi', defaultMessage: 'Malawi'});

        case 'Malaysia':
            return intl.formatMessage({id: 'country.Malaysia', defaultMessage: 'Malaysia'});

        case 'Maldives':
            return intl.formatMessage({id: 'country.Maldives', defaultMessage: 'Maldives'});

        case 'Mali':
            return intl.formatMessage({id: 'country.Mali', defaultMessage: 'Mali'});

        case 'Malta':
            return intl.formatMessage({id: 'country.Malta', defaultMessage: 'Malta'});

        case 'Marshall Islands':
            return intl.formatMessage({id: 'country.MarshallIslands', defaultMessage: 'Marshall Islands'});

        case 'Martinique':
            return intl.formatMessage({id: 'country.Martinique', defaultMessage: 'Martinique'});

        case 'Mauritania':
            return intl.formatMessage({id: 'country.Mauritania', defaultMessage: 'Mauritania'});

        case 'Mauritius':
            return intl.formatMessage({id: 'country.Mauritius', defaultMessage: 'Mauritius'});

        case 'Mayotte':
            return intl.formatMessage({id: 'country.Mayotte', defaultMessage: 'Mayotte'});

        case 'Mexico':
            return intl.formatMessage({id: 'country.Mexico', defaultMessage: 'Mexico'});

        case 'Micronesia, Federated States of':
            return intl.formatMessage({id: 'country.MicronesiaFederatedStatesof', defaultMessage: 'Micronesia, Federated States of'});

        case 'Moldova, Republic of':
            return intl.formatMessage({id: 'country.MoldovaRepublicof', defaultMessage: 'Moldova, Republic of'});

        case 'Monaco':
            return intl.formatMessage({id: 'country.Monaco', defaultMessage: 'Monaco'});

        case 'Mongolia':
            return intl.formatMessage({id: 'country.Mongolia', defaultMessage: 'Mongolia'});

        case 'Montserrat':
            return intl.formatMessage({id: 'country.Montserrat', defaultMessage: 'Montserrat'});

        case 'Morocco':
            return intl.formatMessage({id: 'country.Morocco', defaultMessage: 'Morocco'});

        case 'Mozambique':
            return intl.formatMessage({id: 'country.Mozambique', defaultMessage: 'Mozambique'});

        case 'Myanmar':
            return intl.formatMessage({id: 'country.Myanmar', defaultMessage: 'Myanmar'});

        case 'Namibia':
            return intl.formatMessage({id: 'country.Namibia', defaultMessage: 'Namibia'});

        case 'Nauru':
            return intl.formatMessage({id: 'country.Nauru', defaultMessage: 'Nauru'});

        case 'Nepal':
            return intl.formatMessage({id: 'country.Nepal', defaultMessage: 'Nepal'});

        case 'Netherlands':
            return intl.formatMessage({id: 'country.Netherlands', defaultMessage: 'Netherlands'});

        case 'New Caledonia':
            return intl.formatMessage({id: 'country.NewCaledonia', defaultMessage: 'New Caledonia'});

        case 'New Zealand':
            return intl.formatMessage({id: 'country.NewZealand', defaultMessage: 'New Zealand'});

        case 'Nicaragua':
            return intl.formatMessage({id: 'country.Nicaragua', defaultMessage: 'Nicaragua'});

        case 'Niger':
            return intl.formatMessage({id: 'country.Niger', defaultMessage: 'Niger'});

        case 'Nigeria':
            return intl.formatMessage({id: 'country.Nigeria', defaultMessage: 'Nigeria'});

        case 'Niue':
            return intl.formatMessage({id: 'country.Niue', defaultMessage: 'Niue'});

        case 'Norfolk Island':
            return intl.formatMessage({id: 'country.NorfolkIsland', defaultMessage: 'Norfolk Island'});

        case 'Northern Mariana Islands':
            return intl.formatMessage({id: 'country.NorthernMarianaIslands', defaultMessage: 'Northern Mariana Islands'});

        case 'Norway':
            return intl.formatMessage({id: 'country.Norway', defaultMessage: 'Norway'});

        case 'Oman':
            return intl.formatMessage({id: 'country.Oman', defaultMessage: 'Oman'});

        case 'Pakistan':
            return intl.formatMessage({id: 'country.Pakistan', defaultMessage: 'Pakistan'});

        case 'Palau':
            return intl.formatMessage({id: 'country.Palau', defaultMessage: 'Palau'});

        case 'Palestinian Territory, Occupied':
            return intl.formatMessage({id: 'country.PalestinianTerritoryOccupied', defaultMessage: 'Palestinian Territory, Occupied'});

        case 'Panama':
            return intl.formatMessage({id: 'country.Panama', defaultMessage: 'Panama'});

        case 'Papua New Guinea':
            return intl.formatMessage({id: 'country.PapuaNewGuinea', defaultMessage: 'Papua New Guinea'});

        case 'Paraguay':
            return intl.formatMessage({id: 'country.Paraguay', defaultMessage: 'Paraguay'});

        case 'Peru':
            return intl.formatMessage({id: 'country.Peru', defaultMessage: 'Peru'});

        case 'Philippines':
            return intl.formatMessage({id: 'country.Philippines', defaultMessage: 'Philippines'});

        case 'Poland':
            return intl.formatMessage({id: 'country.Poland', defaultMessage: 'Poland'});

        case 'Portugal':
            return intl.formatMessage({id: 'country.Portugal', defaultMessage: 'Portugal'});

        case 'Puerto Rico':
            return intl.formatMessage({id: 'country.PuertoRico', defaultMessage: 'Puerto Rico'});

        case 'Qatar':
            return intl.formatMessage({id: 'country.Qatar', defaultMessage: 'Qatar'});

        case 'Reunion':
            return intl.formatMessage({id: 'country.Reunion', defaultMessage: 'Reunion'});

        case 'Romania':
            return intl.formatMessage({id: 'country.Romania', defaultMessage: 'Romania'});

        case 'Russian Federation':
            return intl.formatMessage({id: 'country.RussianFederation', defaultMessage: 'Russian Federation'});

        case 'Rwanda':
            return intl.formatMessage({id: 'country.Rwanda', defaultMessage: 'Rwanda'});

        case 'Saint Helena':
            return intl.formatMessage({id: 'country.SaintHelena', defaultMessage: 'Saint Helena'});

        case 'Saint Kitts and Nevis':
            return intl.formatMessage({id: 'country.SaintKittsandNevis', defaultMessage: 'Saint Kitts and Nevis'});

        case 'Saint Lucia':
            return intl.formatMessage({id: 'country.SaintLucia', defaultMessage: 'Saint Lucia'});

        case 'Saint Vincent and the Grenadines':
            return intl.formatMessage({id: 'country.SaintVincentandtheGrenadines', defaultMessage: 'Saint Vincent and the Grenadines'});

        case 'Samoa':
            return intl.formatMessage({id: 'country.Samoa', defaultMessage: 'Samoa'});

        case 'San Marino':
            return intl.formatMessage({id: 'country.SanMarino', defaultMessage: 'San Marino'});

        case 'Sao Tome and Principe':
            return intl.formatMessage({id: 'country.SaoTomeandPrincipe', defaultMessage: 'Sao Tome and Principe'});

        case 'Saudi Arabia':
            return intl.formatMessage({id: 'country.SaudiArabia', defaultMessage: 'Saudi Arabia'});

        case 'Senegal':
            return intl.formatMessage({id: 'country.Senegal', defaultMessage: 'Senegal'});

        case 'Seychelles':
            return intl.formatMessage({id: 'country.Seychelles', defaultMessage: 'Seychelles'});

        case 'Sierra Leone':
            return intl.formatMessage({id: 'country.SierraLeone', defaultMessage: 'Sierra Leone'});

        case 'Singapore':
            return intl.formatMessage({id: 'country.Singapore', defaultMessage: 'Singapore'});

        case 'Slovakia':
            return intl.formatMessage({id: 'country.Slovakia', defaultMessage: 'Slovakia'});

        case 'Slovenia':
            return intl.formatMessage({id: 'country.Slovenia', defaultMessage: 'Slovenia'});

        case 'Solomon Islands':
            return intl.formatMessage({id: 'country.SolomonIslands', defaultMessage: 'Solomon Islands'});

        case 'Somalia':
            return intl.formatMessage({id: 'country.Somalia', defaultMessage: 'Somalia'});

        case 'South Africa':
            return intl.formatMessage({id: 'country.SouthAfrica', defaultMessage: 'South Africa'});

        case 'Spain':
            return intl.formatMessage({id: 'country.Spain', defaultMessage: 'Spain'});

        case 'Sri Lanka':
            return intl.formatMessage({id: 'country.SriLanka', defaultMessage: 'Sri Lanka'});

        case 'Sudan':
            return intl.formatMessage({id: 'country.Sudan', defaultMessage: 'Sudan'});

        case 'Suriname':
            return intl.formatMessage({id: 'country.Suriname', defaultMessage: 'Suriname'});

        case 'Swaziland':
            return intl.formatMessage({id: 'country.Swaziland', defaultMessage: 'Swaziland'});

        case 'Sweden':
            return intl.formatMessage({id: 'country.Sweden', defaultMessage: 'Sweden'});

        case 'Switzerland':
            return intl.formatMessage({id: 'country.Switzerland', defaultMessage: 'Switzerland'});

        case 'Syrian Arab Republic':
            return intl.formatMessage({id: 'country.SyrianArabRepublic', defaultMessage: 'Syrian Arab Republic'});

        case 'Taiwan':
            return intl.formatMessage({id: 'country.Taiwan', defaultMessage: 'Taiwan'});

        case 'Tajikistan':
            return intl.formatMessage({id: 'country.Tajikistan', defaultMessage: 'Tajikistan'});

        case 'Tanzania, United Republic of':
            return intl.formatMessage({id: 'country.TanzaniaUnitedRepublicof', defaultMessage: 'Tanzania, United Republic of'});

        case 'Thailand':
            return intl.formatMessage({id: 'country.Thailand', defaultMessage: 'Thailand'});

        case 'Timor-Leste':
            return intl.formatMessage({id: 'country.Timor-Leste', defaultMessage: 'Timor-Leste'});

        case 'Togo':
            return intl.formatMessage({id: 'country.Togo', defaultMessage: 'Togo'});

        case 'Tokelau':
            return intl.formatMessage({id: 'country.Tokelau', defaultMessage: 'Tokelau'});

        case 'Tonga':
            return intl.formatMessage({id: 'country.Tonga', defaultMessage: 'Tonga'});

        case 'Trinidad and Tobago':
            return intl.formatMessage({id: 'country.TrinidadandTobago', defaultMessage: 'Trinidad and Tobago'});

        case 'Tunisia':
            return intl.formatMessage({id: 'country.Tunisia', defaultMessage: 'Tunisia'});

        case 'Turkey':
            return intl.formatMessage({id: 'country.Turkey', defaultMessage: 'Turkey'});

        case 'Turkmenistan':
            return intl.formatMessage({id: 'country.Turkmenistan', defaultMessage: 'Turkmenistan'});

        case 'Turks and Caicos Islands':
            return intl.formatMessage({id: 'country.TurksandCaicosIslands', defaultMessage: 'Turks and Caicos Islands'});

        case 'Tuvalu':
            return intl.formatMessage({id: 'country.Tuvalu', defaultMessage: 'Tuvalu'});

        case 'Uganda':
            return intl.formatMessage({id: 'country.Uganda', defaultMessage: 'Uganda'});

        case 'Ukraine':
            return intl.formatMessage({id: 'country.Ukraine', defaultMessage: 'Ukraine'});

        case 'United Arab Emirates':
            return intl.formatMessage({id: 'country.UnitedArabEmirates', defaultMessage: 'United Arab Emirates'});

        case 'United Kingdom':
            return intl.formatMessage({id: 'country.UnitedKingdom', defaultMessage: 'United Kingdom'});

        case 'United States':
            return intl.formatMessage({id: 'country.UnitedStates', defaultMessage: 'United States'});

        case 'Uruguay':
            return intl.formatMessage({id: 'country.Uruguay', defaultMessage: 'Uruguay'});

        case 'Uzbekistan':
            return intl.formatMessage({id: 'country.Uzbekistan', defaultMessage: 'Uzbekistan'});

        case 'Vanuatu':
            return intl.formatMessage({id: 'country.Vanuatu', defaultMessage: 'Vanuatu'});

        case 'Venezuela':
            return intl.formatMessage({id: 'country.Venezuela', defaultMessage: 'Venezuela'});

        case 'Vietnam':
            return intl.formatMessage({id: 'country.VietNam', defaultMessage: 'Vietnam'});

        case 'Virgin Islands, British':
            return intl.formatMessage({id: 'country.VirginIslandsBritish', defaultMessage: 'Virgin Islands, British'});

        case 'Virgin Islands, U.s.':
            return intl.formatMessage({id: 'country.VirginIslandsU.s.', defaultMessage: 'Virgin Islands, U.s.'});

        case 'Wallis and Futuna':
            return intl.formatMessage({id: 'country.WallisandFutuna', defaultMessage: 'Wallis and Futuna'});

        case 'Yemen':
            return intl.formatMessage({id: 'country.Yemen', defaultMessage: 'Yemen'});

        case 'Zambia':
            return intl.formatMessage({id: 'country.Zambia', defaultMessage: 'Zambia'});

        case 'Zimbabwe':
            return intl.formatMessage({id: 'country.Zimbabwe', defaultMessage: 'Zimbabwe'});

        case 'Bonaire, Sint Eustatius and Saba':
            return intl.formatMessage({id: 'country.BonaireSintEustatiusandSaba', defaultMessage: 'Bonaire, Sint Eustatius and Saba'});

        case 'Curaçao':
            return intl.formatMessage({id: 'country.Curaçao', defaultMessage: 'Curaçao'});

        case 'Guernsey':
            return intl.formatMessage({id: 'country.Guernsey', defaultMessage: 'Guernsey'});

        case 'Isle of Man':
            return intl.formatMessage({id: 'country.IsleofMan', defaultMessage: 'Isle of Man'});

        case 'Jersey':
            return intl.formatMessage({id: 'country.Jersey', defaultMessage: 'Jersey'});

        case 'Montenegro':
            return intl.formatMessage({id: 'country.Montenegro', defaultMessage: 'Montenegro'});

        case 'Saint Barthélemy':
            return intl.formatMessage({id: 'country.SaintBarthélemy', defaultMessage: 'Saint Barthélemy'});

        case 'Saint Martin (French part)':
            return intl.formatMessage({id: 'country.SaintMartinFrenchpart', defaultMessage: 'Saint Martin (French part)'});

        case 'Serbia':
            return intl.formatMessage({id: 'country.Serbia', defaultMessage: 'Serbia'});

        case 'Sint Maarten (Dutch part)':
            return intl.formatMessage({id: 'country.SintMaartenDutchpart', defaultMessage: 'Sint Maarten (Dutch part)'});

        case 'South Sudan':
            return intl.formatMessage({id: 'country.SouthSudan', defaultMessage: 'South Sudan'});

        default:
            // Unknown country
            return country;
    }
}
