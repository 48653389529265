import { Table, TableBody, TableHead, TableCell, TableContainer, TableRow, TableSortLabel, Checkbox, makeStyles, Tooltip } from "@material-ui/core";
import { CATEGORY_COLORS } from '../../../Constants/CategoryColors';
import { BRCGS_CATEGORY_COLORS } from "../../../Constants/BrcgsCategoryColors";
import CategoryLabel from "../../Translation/CategoryLabel";
import CompareArrow from "./Common/CompareArrow";
import { FormGroup } from "@material-ui/core";
import { FormControlLabel } from "@material-ui/core";
import { useState } from "react";
import FilterListIcon from '@material-ui/icons/FilterList';
import ElementLabel from "../../Translation/ElementLabel";
import Decimal from 'decimal.js';
import { FormattedMessage } from "react-intl";

const useStyles = makeStyles((theme) => ({
    label: {
        marginLeft: 0
    },
}));

export default function BenchmarkElementsIndustry(props) {
    const classes = useStyles();
    const [sortOrder, setSortOrder] = useState('desc');
    const [sortBy, setSortBy] = useState('score');
    const [categories, setCategories] = useState({
        people: true,
        process: true,
        purpose: true,
        proactivity: true
    });

    const toggleSort = (column) => {
        // Toggle direction on already sorted by column
        if (sortBy === column) {
            setSortOrder(prevOrder => (prevOrder === 'asc' ? 'desc' : 'asc'));
        } else {
            // Sort by new column, descending
            setSortBy(column);
            setSortOrder('desc');
        }
    };

    const toggleCategory = (category) => {
        // Don't allow the last category to be deselected
        const enabledCategories = Object.keys(categories).filter((current) => {
            return categories[current];
        });
        if (1 === enabledCategories.length && categories[category]) {
            return;
        }
        setCategories(prevState => ({
            ...prevState,
            [category]: !prevState[category]
        }));
    };

    if (!props.reportData.data || 0 === Object.keys(props.reportData.data).length || !props.surveyData || 0 === Object.keys(props.surveyData.attributes).length) {
        return null;
    }

    // Determine colors for report based on type
    const categoryColors = props.surveyData.attributes.type === 'brcgs' ? BRCGS_CATEGORY_COLORS : CATEGORY_COLORS;

    const sortedData = () => {
        const dataCopy = [];

        //Find old score, if available
        props.reportData.data.current.forEach((element, index) => {
            element['industry'] = null;
            element['industryMax'] = null;
            element['industryMin'] = null;
            if (props.reportData.data.industry) {
                for (let i = 0; i < props.reportData.data.industry.length; i++) {
                    if (props.reportData.data.industry[i]['shortName'] === element['shortName']) {
                        element['industry'] = props.reportData.data.industry[i]['score'];
                        element['industryMax'] = props.reportData.data.industry[i]['max'];
                        element['industryMin'] = props.reportData.data.industry[i]['min'];
                        break;
                    }
                }
            }

            // Might be a mapped question, search element mappings
            if (null === element['industry'] &&
                props.reportData.data.elementMappings &&
                props.reportData.data.elementMappings.length
            ) {
                const findMappedIndustryScore = (element) => {
                    for (let i = 0; i < props.reportData.data.elementMappings.length; i++) {
                        if (-1 !== props.reportData.data.elementMappings[i].indexOf(element['shortName'])) {
                            for (let j = 0; j < props.reportData.data.elementMappings[i].length; j++) {
                                const currentElement = props.reportData.data.elementMappings[i][j];
                                for (let k = 0; k < props.reportData.data.industry.length; k++) {
                                    if (props.reportData.data.industry[k]['shortName'] === currentElement) {
                                        return {
                                            score: props.reportData.data.industry[k]['score'],
                                            max: props.reportData.data.industry[k]['max'],
                                            min: props.reportData.data.industry[k]['min'],
                                        };
                                    }
                                }
                            }
                        }
                    }
                    return null;
                };

                const industryScores = findMappedIndustryScore(element);
                if (industryScores) {
                    element['industry'] = industryScores['score'];
                    element['industryMax'] = industryScores['max'];
                    element['industryMin'] = industryScores['min'];
                }
            }

            if (null !== element['industry']) {
                element['change'] = scoreChange(element['score'], element['industry']);
            } else {
                element['change'] = null;
            }

            dataCopy.push(element);
        });

        dataCopy.sort((a, b) => {
            return (a[sortBy] === null ? Number.MIN_SAFE_INTEGER : parseFloat(a[sortBy])) > (b[sortBy] === null ? Number.MIN_SAFE_INTEGER : parseFloat(b[sortBy])) ? 1 : -1
        });
        if ('desc' === sortOrder) {
            dataCopy.reverse();
        }
        return dataCopy;
    }

    const scoreChange = (current, industry, precision = 1) => {
        if (!current || !industry) {
            return null;
        }
        return (Decimal(current).minus(Decimal(industry))).toFixed(precision);
    };

    return (
        <div ref={props.setScreenshotRef}>
            <FormGroup row style={{justifyContent: 'center'}}>
                <FormControlLabel
                    className={classes.label}
                    label={<CategoryLabel category="people"/>}
                    style={{backgroundColor: categoryColors['people'].light, borderLeft: '5px solid '+categoryColors['people'].dark, paddingRight: '10px'}}
                    control={
                        <Checkbox
                            color="primary"
                            style={{ padding: '5px' }}
                            checked={categories.people}
                            onChange={() => toggleCategory('people')}
                        />
                    }
                />
                <FormControlLabel
                    label={<CategoryLabel category="process"/>}
                    style={{backgroundColor: categoryColors['process'].light, borderLeft: '5px solid '+categoryColors['process'].dark, paddingRight: '10px'}}
                    control={
                        <Checkbox
                            color="primary"
                            style={{ padding: '5px' }}
                            checked={categories.process}
                            onChange={() => toggleCategory('process')}
                        />
                    }
                />
                <FormControlLabel
                    label={<CategoryLabel category="purpose"/>}
                    style={{backgroundColor: categoryColors['purpose'].light, borderLeft: '5px solid '+categoryColors['purpose'].dark, paddingRight: '10px'}}
                    control={
                        <Checkbox
                            color="primary"
                            style={{ padding: '5px' }}
                            checked={categories.purpose}
                            onChange={() => toggleCategory('purpose')}
                        />
                    }
                />
                <FormControlLabel
                    label={<CategoryLabel category="proactivity"/>}
                    style={{backgroundColor: categoryColors['proactivity'].light, borderLeft: '5px solid '+categoryColors['proactivity'].dark, paddingRight: '10px'}}
                    control={
                        <Checkbox
                            color="primary"
                            style={{ padding: '5px' }}
                            checked={categories.proactivity}
                            onChange={() => toggleCategory('proactivity')}
                        />
                    }
                />
            </FormGroup>
            <TableContainer>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <FormattedMessage id="generic.category" defaultMessage={"Category"} />
                            </TableCell>
                            <TableCell>
                                <FormattedMessage id="generic.element" defaultMessage={"Element"} />
                            </TableCell>
                            <TableCell align="center">
                                <TableSortLabel
                                    IconComponent={FilterListIcon}
                                    active={sortBy === 'score'}
                                    direction={sortOrder}
                                    onClick={() => {toggleSort('score')}}
                                >
                                    <FormattedMessage id="generic.scorePercent" defaultMessage={"Score (%)"}/>
                                </TableSortLabel>
                            </TableCell>
                            <TableCell align="center">
                                <TableSortLabel
                                    IconComponent={FilterListIcon}
                                    active={sortBy === 'industry'}
                                    direction={sortOrder}
                                    onClick={() => {toggleSort('industry')}}
                                >
                                    <FormattedMessage id="generic.industryScorePercent" defaultMessage={"Industry Score (%)"}/>
                                </TableSortLabel>
                            </TableCell>
                            <TableCell align="center">
                                <TableSortLabel
                                    IconComponent={FilterListIcon}
                                    active={sortBy === 'change'}
                                    direction={sortOrder}
                                    onClick={() => {toggleSort('change')}}
                                >
                                    <FormattedMessage id="generic.change" defaultMessage={"Change"}/>
                                </TableSortLabel>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {sortedData().map((entity, index) => {
                            if (!categories[entity.category]) {
                                return null;
                            }
                            return (
                                <TableRow key={index} style={{backgroundColor: categoryColors[entity.category].light, borderBottom: '10px solid white'}}>
                                    <TableCell style={{borderLeft: '5px solid '+categoryColors[entity.category].dark}}>
                                        <CategoryLabel category={entity.category} />
                                    </TableCell>
                                    <TableCell>
                                        {entity.shortName ? <ElementLabel element={entity.shortName} /> : entity.title}
                                    </TableCell>
                                    <TableCell align="center"><span title={entity.score}>{Decimal(entity.score).toFixed(1)}</span></TableCell>
                                    <TableCell align="center">
                                        {entity.industry != null ?
                                            <Tooltip title={
                                                <span>
                                                    Avg: <strong>{entity.industry || '-'}</strong><br />
                                                    Max: <strong>{entity.industryMax || '-'}</strong><br />
                                                    Min: <strong>{entity.industryMin || '-'}</strong>
                                                </span>
                                            }>
                                                <span>
                                                    {Decimal(entity.industry).toFixed(1)}
                                                </span>
                                            </Tooltip>
                                        : '-'}
                                    </TableCell>
                                    <TableCell align="center">
                                        {(entity.change ?
                                            <div style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center'
                                            }}>
                                                <span>{entity.change}</span>
                                                <CompareArrow change={Decimal(entity.change).toFixed(1)} />
                                            </div>
                                        : '-')}
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
}
