import { Drawer, Hidden, Link, List, ListItem, ListItemText, makeStyles, Typography } from "@material-ui/core";
import { Link as RouterLink, useLocation } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { Fragment, useContext, useEffect, useMemo, useState } from "react";
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { UserContext } from "../../../Context/UserContext";
import { Collapse } from "@material-ui/core";

const drawerWidth = 300;
const useStyles = makeStyles((theme) => ({
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
        width: drawerWidth
    },
    drawer: {
        [theme.breakpoints.up("sm")]: {
            width: drawerWidth,
            flexShrink: 0
        }
    },
    indent: {
        paddingLeft: 28
    },
    indent2: {
        paddingLeft: 40
    },
    indent3: {
        paddingLeft: 52
    }
}));

export default function ReportMenu(props) {
    const { w } = props;
    const container = w !== undefined ? () => w().document.body : undefined;
    const classes = useStyles();
    const location = useLocation();
    const { user } = useContext(UserContext);

    const premiumList = useMemo(() => {
        const siteQuestion = props.demographicQuestions ? props.demographicQuestions.find(q => q.attributes.demographic === 'site') : null;
        const reportsContain = (reportType) => {
            if (user && (user.role === 'admin' || user.role === 'partner')) {
                return true;
            } else if (props.reports && props.reports.length && -1 !== props.reports.indexOf(reportType)) {
                return true;
            }
            return false;
        };

        const upgrade = (t) => {
            return user && user.role !== 'admin' && props.surveyType === 'brcgs' && !reportsContain(t);
        }

        return [
            {
                header: null,
                headerVariant: null,
                show: true,
                collapse: null,
                reports: [
                    {
                        type: 'summary',
                        label: <FormattedMessage id="reportmenu.summary" defaultMessage="Summary" />,
                    }
                ]
            },
            {
                header: <FormattedMessage id="reportmenu.categoriesAndDimensions" defaultMessage={"Categories & Dimensions"} />,
                headerVariant: 'subtitle1',
                show: true,
                collapse: 'categoriesAndDimensions',
                reports: [
                    {
                        type: 'basic-data',
                        label: <FormattedMessage id="reportmenu.basicData" defaultMessage={"Basic Data"} />,
                        class: classes.indent
                    },
                    {
                        type: 'demographic-comparison',
                        label: <FormattedMessage id="reportmenu.demographicComparison" defaultMessage="Demographic Comparison" />,
                        class: classes.indent,
                        show: !upgrade('demographic-comparison')
                    },
                    {
                        type: 'culture-type',
                        label: <FormattedMessage id="filterMenu.cultureType" defaultMessage="Culture Type" />,
                        class: classes.indent,
                        show: !upgrade('culture-type')
                    }
                ]
            },
            {
                header: <FormattedMessage id="reportmenu.elements" defaultMessage="Elements" />,
                headerVariant: 'subtitle1',
                show: true,
                collapse: 'elements',
                reports: [
                    {
                        type: 'elements',
                        label: <FormattedMessage id="reportmenu.allElements" defaultMessage="All Elements" />,
                        class: classes.indent,
                        show: props.surveyType !== 'brcgs' || reportsContain('elements')
                    },
                    {
                        type: 'elements-brcgs',
                        label: <FormattedMessage id="reportmenu.highestAndLowest" defaultMessage={"Highest & Lowest"} />,
                        class: classes.indent,
                        show: props.surveyType === 'brcgs' && !reportsContain('elements')
                    },
                    {
                        type: 'response-distribution',
                        label: <FormattedMessage id="reportmenu.responseDistribution" defaultMessage="Response Distribution" />,
                        class: classes.indent,
                        show: !upgrade('response-distribution')
                    },
                    {
                        type: 'element-hotspots',
                        label: <FormattedMessage id="reportmenu.hotspots" defaultMessage="Hotspots" />,
                        class: classes.indent,
                        show: !upgrade('element-hotspots')
                    },
                    {
                        type: 'element-ranges',
                        label: <FormattedMessage id="reportmenu.ranges" defaultMessage="Ranges" />,
                        class: classes.indent,
                        show: !upgrade('element-ranges') && siteQuestion && siteQuestion.attributes.options && siteQuestion.attributes.options.length > 1
                    },
                ]
            },
            {
                header: <FormattedMessage id="reportmenu.priorities" defaultMessage="Priorities" />,
                headerVariant: 'subtitle1',
                show: props.rankTypes && -1 !== props.rankTypes.indexOf('personal_priorities') && -1 !== props.rankTypes.indexOf('company_priorities') && !upgrade('priorities'),
                collapse: 'priorities',
                reports: [
                    {
                        type: 'priorities',
                        label: <FormattedMessage id="reportmenu.companyPersonal" defaultMessage={"Company & Personal"} />,
                        class: classes.indent,
                        show: !upgrade('priorities')
                    },
                    {
                        type: 'priority-hotspots',
                        label: <FormattedMessage id="reportmenu.priorityHotspots" defaultMessage={"Priority Hotspots"} />,
                        class: classes.indent,
                        show: !upgrade('priority-hotspots')
                    },
                    {
                        type: 'priority-conflict-hotspots',
                        label: <FormattedMessage id="reportmenu.conflictHotspots" defaultMessage={"Conflict Hotspots"} />,
                        class: classes.indent,
                        show: !upgrade('priority-conflict-hotspots')
                    },
                ]
            },
            {
                header: <FormattedMessage id="reportmenu.positivesAndBarriers" defaultMessage={"Positives & Barriers"} />,
                headerVariant: 'subtitle1',
                show: props.choiceTypes && (-1 !== props.choiceTypes.indexOf('positives') || -1 !== props.choiceTypes.indexOf('challenges')),
                collapse: 'positivesAndBarriers',
                reports: [
                    {
                        type: 'positives',
                        label: <FormattedMessage id="reportmenu.positives" defaultMessage="Positives" />,
                        class: classes.indent,
                        show: props.surveyType !== 'brcgs' || reportsContain('positives')
                    },
                    {
                        type: 'positives-brcgs',
                        label: <FormattedMessage id="reportmenu.positives" defaultMessage="Positives" />,
                        class: classes.indent,
                        show: props.surveyType === 'brcgs' && !reportsContain('positives')
                    },
                    {
                        type: 'barriers',
                        label: <FormattedMessage id="reportmenu.barriers" defaultMessage="Barriers" />,
                        class: classes.indent,
                        show: props.surveyType !== 'brcgs' || reportsContain('barriers')
                    },
                    {
                        type: 'barriers-brcgs',
                        label: <FormattedMessage id="reportmenu.barriers" defaultMessage="Barriers" />,
                        class: classes.indent,
                        show: props.surveyType === 'brcgs' && !reportsContain('barriers')
                    },
                ]
            },
            {
                header: <FormattedMessage id="reportmenu.risks" defaultMessage={"Risks"} />,
                headerVariant: 'subtitle1',
                show: props.riskTypes && props.riskTypes.length && !upgrade('priorities'),
                collapse: 'risks',
                reports: [
                    {
                        type: 'risks',
                        label: <FormattedMessage id="reportmenu.riskRatings" defaultMessage="Risk Ratings" />,
                        class: classes.indent,
                        show: !upgrade('risks')
                    },
                ]
            },
            {
                header: <FormattedMessage id="reportmenu.quality" defaultMessage={"Quality"} />,
                headerVariant: 'subtitle1',
                show: props.choiceTypes && -1 !== props.choiceTypes.indexOf('quality'),
                collapse: 'quality',
                reports: [
                    {
                        type: 'quality',
                        label: <FormattedMessage id="reportmenu.meaningOfQuality" defaultMessage="Meaning of Quality" />,
                        class: classes.indent
                    },
                ]
            },
            {
                header: "Environment",
                headerVariant: 'subtitle1',
                show: props.choiceTypes && -1 !== props.choiceTypes.indexOf('environment'),
                collapse: 'environment',
                reports: [
                    {
                        type: 'environment',
                        label: "Environmental Journey",
                        class: classes.indent
                    },
                ]
            },
            {
                header: <FormattedMessage id="reportmenu.benchmarking" defaultMessage="Benchmarking" />,
                headerVariant: 'subtitle1',
                show: true,
                collapse: 'benchmarking',
                reports: [
                    {
                        type: null,
                        header: <FormattedMessage id="reportmenu.categoriesAndDimensions" defaultMessage={"Categories & Dimensions"} />,
                        headerVariant: 'subtitle1',
                        class: classes.indent,
                    },
                    {
                        type: 'benchmark-company',
                        label: <FormattedMessage id="reportmenu.benchmarkCompany" defaultMessage={"Company"} />,
                        class: classes.indent2,
                        show: !upgrade('benchmark-company')
                    },
                    {
                        type: 'benchmark-industry',
                        label: <FormattedMessage id="reportmenu.benchmarkIndustry" defaultMessage={"Industry"} />,
                        class: classes.indent2,
                    },
                    {
                        type: 'benchmark-sector',
                        label: <FormattedMessage id="reportmenu.benchmarkSector" defaultMessage={"Sector"} />,
                        class: classes.indent2,
                        show: !upgrade('benchmark-sector')
                    },
                    {
                        type: 'benchmark-region',
                        label: <FormattedMessage id="reportmenu.benchmarkRegion" defaultMessage={"Region"} />,
                        class: classes.indent2,
                        show: !upgrade('benchmark-region')
                    },
                    {
                        type: 'benchmark-country',
                        label: <FormattedMessage id="reportmenu.benchmarkCountry" defaultMessage={"Country"} />,
                        class: classes.indent2,
                        show: !upgrade('benchmark-country')
                    },
                    {
                        type: null,
                        header: <FormattedMessage id="reportmenu.elements" defaultMessage={"Elements"} />,
                        headerVariant: 'subtitle1',
                        class: classes.indent,
                    },
                    {
                        type: 'benchmark-elements-company',
                        label: <FormattedMessage id="reportmenu.benchmarkCompany" defaultMessage={"Company"} />,
                        class: classes.indent2,
                        show: !upgrade('benchmark-elements-company')
                    },
                    {
                        type: 'benchmark-elements-industry',
                        label: <FormattedMessage id="reportmenu.benchmarkIndustry" defaultMessage={"Industry"} />,
                        class: classes.indent2,
                        show: props.surveyType !== 'brcgs' || reportsContain('benchmark-elements-industry')
                    },
                    {
                        type: 'benchmark-elements-sector',
                        label: <FormattedMessage id="reportmenu.benchmarkSector" defaultMessage={"Sector"} />,
                        class: classes.indent2,
                        show: !upgrade('benchmark-elements-sector')
                    },
                    {
                        type: 'benchmark-elements-industry-brcgs',
                        label: <FormattedMessage id="reportmenu.benchmarkIndustry" defaultMessage={"Industry"} />,
                        class: classes.indent2,
                        show: props.surveyType === 'brcgs' && !reportsContain('benchmark-elements-country')
                    },
                    {
                        type: 'benchmark-elements-region',
                        label: <FormattedMessage id="reportmenu.benchmarkRegion" defaultMessage={"Region"} />,
                        class: classes.indent2,
                        show: !upgrade('benchmark-elements-region')
                    },
                    {
                        type: 'benchmark-elements-country',
                        label: <FormattedMessage id="reportmenu.benchmarkCountry" defaultMessage={"Country"} />,
                        class: classes.indent2,
                        show: !upgrade('benchmark-elements-country')
                    },
                    {
                        type: null,
                        header: <FormattedMessage id="reportmenu.positives" defaultMessage="Positives" />,
                        headerVariant: 'subtitle1',
                        class: classes.indent,
                        show: props.choiceTypes && -1 !== props.choiceTypes.indexOf('positives') &&
                        (!upgrade('benchmark-positives-company') || !upgrade('benchmark-positives-industry') || !upgrade('benchmark-positives-region') || !upgrade('benchmark-positives-country')),
                    },
                    {
                        type: 'benchmark-positives-company',
                        label: <FormattedMessage id="reportmenu.benchmarkCompany" defaultMessage={"Company"} />,
                        class: classes.indent2,
                        show: props.choiceTypes && -1 !== props.choiceTypes.indexOf('positives') && !upgrade('benchmark-positives-company')
                    },
                    {
                        type: 'benchmark-positives-industry',
                        label: <FormattedMessage id="reportmenu.benchmarkIndustry" defaultMessage={"Industry"} />,
                        class: classes.indent2,
                        show: props.choiceTypes && -1 !== props.choiceTypes.indexOf('positives') && !upgrade('benchmark-positives-industry')
                    },
                    {
                        type: 'benchmark-positives-sector',
                        label: <FormattedMessage id="reportmenu.benchmarkSector" defaultMessage={"Sector"} />,
                        class: classes.indent2,
                        show: !upgrade('benchmark-positives-sector')
                    },
                    {
                        type: 'benchmark-positives-region',
                        label: <FormattedMessage id="reportmenu.benchmarkRegion" defaultMessage={"Region"} />,
                        class: classes.indent2,
                        show: props.choiceTypes && -1 !== props.choiceTypes.indexOf('positives') && !upgrade('benchmark-positives-region')
                    },
                    {
                        type: 'benchmark-positives-country',
                        label: <FormattedMessage id="reportmenu.benchmarkCountry" defaultMessage={"Country"} />,
                        class: classes.indent2,
                        show: props.choiceTypes && -1 !== props.choiceTypes.indexOf('positives') && !upgrade('benchmark-positives-country')
                    },
                    {
                        type: null,
                        header: <FormattedMessage id="reportmenu.barriers" defaultMessage="Barriers" />,
                        headerVariant: 'subtitle1',
                        class: classes.indent,
                        show: props.choiceTypes && -1 !== props.choiceTypes.indexOf('challenges') &&
                            (!upgrade('benchmark-barriers-company') || !upgrade('benchmark-barriers-industry') || !upgrade('benchmark-barriers-region') || !upgrade('benchmark-barriers-country')),
                    },
                    {
                        type: 'benchmark-barriers-company',
                        label: <FormattedMessage id="reportmenu.benchmarkCompany" defaultMessage={"Company"} />,
                        class: classes.indent2,
                        show: props.choiceTypes && -1 !== props.choiceTypes.indexOf('challenges') && !upgrade('benchmark-barriers-company')
                    },
                    {
                        type: 'benchmark-barriers-industry',
                        label: <FormattedMessage id="reportmenu.benchmarkIndustry" defaultMessage={"Industry"} />,
                        class: classes.indent2,
                        show: props.choiceTypes && -1 !== props.choiceTypes.indexOf('challenges') && !upgrade('benchmark-barriers-industry')
                    },
                    {
                        type: 'benchmark-barriers-sector',
                        label: <FormattedMessage id="reportmenu.benchmarkSector" defaultMessage={"Sector"} />,
                        class: classes.indent2,
                        show: !upgrade('benchmark-barriers-sector')
                    },
                    {
                        type: 'benchmark-barriers-region',
                        label: <FormattedMessage id="reportmenu.benchmarkRegion" defaultMessage={"Region"} />,
                        class: classes.indent2,
                        show: props.choiceTypes && -1 !== props.choiceTypes.indexOf('challenges') && !upgrade('benchmark-barriers-region')
                    },
                    {
                        type: 'benchmark-barriers-country',
                        label: <FormattedMessage id="reportmenu.benchmarkCountry" defaultMessage={"Country"} />,
                        class: classes.indent2,
                        show: props.choiceTypes && -1 !== props.choiceTypes.indexOf('challenges') && !upgrade('benchmark-barriers-country')
                    },
                    {
                        type: null,
                        header: <FormattedMessage id="reportmenu.meaningOfQuality" defaultMessage="Meaning of Quality" />,
                        headerVariant: 'subtitle1',
                        show: props.choiceTypes && -1 !== props.choiceTypes.indexOf('quality') &&
                            (!upgrade('benchmark-quality-company') || !upgrade('benchmark-quality-industry') || !upgrade('benchmark-quality-region') || !upgrade('benchmark-quality-country')),
                        class: classes.indent,
                    },
                    {
                        type: 'benchmark-quality-company',
                        label: <FormattedMessage id="reportmenu.benchmarkCompany" defaultMessage={"Company"} />,
                        class: classes.indent2,
                        show: props.choiceTypes && -1 !== props.choiceTypes.indexOf('quality') && !upgrade('benchmark-quality-company')
                    },
                    {
                        type: 'benchmark-quality-industry',
                        label: <FormattedMessage id="reportmenu.benchmarkIndustry" defaultMessage={"Industry"} />,
                        class: classes.indent2,
                        show: props.choiceTypes && -1 !== props.choiceTypes.indexOf('quality') && !upgrade('benchmark-quality-industry')
                    },
                    {
                        type: 'benchmark-quality-sector',
                        label: <FormattedMessage id="reportmenu.benchmarkSector" defaultMessage={"Sector"} />,
                        class: classes.indent2,
                        show: !upgrade('benchmark-quality-sector')
                    },
                    {
                        type: 'benchmark-quality-region',
                        label: <FormattedMessage id="reportmenu.benchmarkRegion" defaultMessage={"Region"} />,
                        class: classes.indent2,
                        show: props.choiceTypes && -1 !== props.choiceTypes.indexOf('quality') && !upgrade('benchmark-quality-region')
                    },
                    {
                        type: 'benchmark-quality-country',
                        label: <FormattedMessage id="reportmenu.benchmarkCountry" defaultMessage={"Country"} />,
                        class: classes.indent2,
                        show: props.choiceTypes && -1 !== props.choiceTypes.indexOf('quality') && !upgrade('benchmark-quality-country')
                    },
                ],
            },
            {
                header: <FormattedMessage id="reportmenu.changeOverTime" defaultMessage="Change Over Time" />,
                headerVariant: 'subtitle1',
                show: true,
                collapse: 'changeOverTime',
                reports: [
                    {
                        type: 'basic-data-comparison',
                        label: <FormattedMessage id="reportmenu.categoriesAndDimensions" defaultMessage={"Categories & Dimensions"} />,
                        class: classes.indent
                    },
                    {
                        type: 'elements-comparison',
                        label: <FormattedMessage id="reportmenu.elements" defaultMessage="Elements" />,
                        class: classes.indent,
                        show: !upgrade('elements-comparison')
                    },
                    {
                        type: 'priorities-comparison',
                        label: <FormattedMessage id="reportmenu.priorities" defaultMessage="Priorities" />,
                        class: classes.indent,
                        show: !upgrade('priorities-comparison')
                    },
                    {
                        type: 'risks-comparison',
                        label: <FormattedMessage id="reportmenu.risks" defaultMessage="Risks" />,
                        class: classes.indent,
                        show: !upgrade('risks-comparison')
                    },
                    {
                        type: 'progression',
                        label: <FormattedMessage id="reportmenu.progression" defaultMessage="Progression" />,
                        class: classes.indent,
                        show: !upgrade('progression')
                    },
                    {
                        type: 'positives-comparison',
                        label: <FormattedMessage id="reportmenu.positives" defaultMessage="Positives" />,
                        class: classes.indent,
                        show: props.surveyType !== 'brcgs' || reportsContain('positives')
                    },
                    {
                        type: 'barriers-comparison',
                        label: <FormattedMessage id="reportmenu.barriers" defaultMessage="Barriers" />,
                        class: classes.indent,
                        show: props.surveyType !== 'brcgs' || reportsContain('positives')
                    },
                    {
                        type: 'quality-comparison',
                        label: <FormattedMessage id="reportmenu.meaningOfQuality" defaultMessage="Meaning of Quality" />,
                        class: classes.indent,
                        show: props.choiceTypes && -1 !== props.choiceTypes.indexOf('quality'),
                    },
                    {
                        type: 'environment-comparison',
                        label: "Environmental Journey",
                        class: classes.indent,
                        show: props.choiceTypes && -1 !== props.choiceTypes.indexOf('environment'),
                    },
                ]
            },
            {
                header: <FormattedMessage id="reportmenu.qualitativeData" defaultMessage="Qualitative Data" />,
                headerVariant: 'subtitle1',
                show: props.commentTypes && props.commentTypes.length && reportsContain('qualitative-comments', user, props.reports),
                collapse: 'qualitativeData',
                reports: [
                    {
                        type: 'qualitative-comments',
                        label: <FormattedMessage id="reportmenu.comments" defaultMessage="Comments" />,
                        class: classes.indent
                    },
                ]
            },
            {
                header: <FormattedMessage id="reportmenu.information" defaultMessage="Information" />,
                headerVariant: 'subtitle1',
                show: true,
                collapse: 'information',
                reports: [
                    {
                        type: 'demographics',
                        label: <FormattedMessage id="reportmenu.tracking" defaultMessage="Tracking" />,
                        class: classes.indent
                    },
                    {
                        type: 'correlation',
                        label: <FormattedMessage id="reportmenu.correlation" defaultMessage="Correlation" />,
                        class: classes.indent,
                        show: user && user.role === 'admin',
                        upgrade: upgrade('correlation')
                    },
                    {
                        type: 'ideas',
                        label: <FormattedMessage id="reportmenu.ideas" defaultMessage="Ideas for Improvement" />,
                        class: classes.indent
                    },
                    {
                        type: 'definitions',
                        label: <FormattedMessage id="reportmenu.definitions" defaultMessage="Definitions" />,
                        class: classes.indent
                    },
                    {
                        type: 'executive-summary',
                        label: <FormattedMessage id="reportMenu.executiveSummary" defaultMessage="Executive Summary" />,
                        class: classes.indent,
                        show: props.surveyType !== 'brcgs' || reportsContain('executive-summary')
                    },
                    {
                        type: 'data-export',
                        label: <FormattedMessage id="reportMenu.dataExport" defaultMessage="Data Export" />,
                        class: classes.indent,
                        show: reportsContain('data-export')
                    }
                ]
            },
            {
                header: <FormattedMessage id="reportmenu.downloads" defaultMessage="Downloads" />,
                headerVariant: 'subtitle1',
                show: props.surveyType === 'brcgs',
                collapse: 'downloads',
                reports: [
                    {
                        type: 'executive-report-brcgs',
                        label: <FormattedMessage id="reportMenu.executiveReport" defaultMessage="Executive Report" />,
                        class: classes.indent,
                    },
                    {
                        type: null,
                        label: <FormattedMessage id="reportMenu.actionPlanningTemplate" defaultMessage="Action Planning Template" />,
                        class: classes.indent,
                        url: 'https://resources.culturexcellence.com/BRCGS_FSCE_Action_Plan_Template.docx'
                    },
                    {
                        type: null,
                        label: <FormattedMessage id="reportMenu.userGuide" defaultMessage="User Guide" />,
                        class: classes.indent,
                        url: 'https://resources.culturexcellence.com/BRCGS_FSCE_User_Guide.pdf'
                    },
                ]
            },
            {
                header: <FormattedMessage id="reportmenu.upgrade" defaultMessage="Upgrade" />,
                headerVariant: 'subtitle1',
                show: props.surveyType === 'brcgs' && !reportsContain('elements'),
                collapse: 'upgrade',
                reports: [
                    {
                        type: 'overview',
                        label: <FormattedMessage id="reportmenu.overview" defaultMessage="Overview" />,
                        class: classes.indent,
                        upgrade: true
                    },
                    {
                        type: null,
                        header: <FormattedMessage id="reportmenu.categoriesAndDimensions" defaultMessage={"Categories & Dimensions"} />,
                        headerVariant: 'subtitle1',
                        class: classes.indent,
                    },
                    {
                        type: 'demographic-comparison',
                        label: <FormattedMessage id="reportmenu.demographicComparison" defaultMessage="Demographic Comparison" />,
                        class: classes.indent2,
                        show: upgrade('demographic-comparison'),
                        upgrade: upgrade('demographic-comparison')
                    },
                    {
                        type: null,
                        header: <FormattedMessage id="reportmenu.elements" defaultMessage={"Elements"} />,
                        headerVariant: 'subtitle1',
                        class: classes.indent,
                    },
                    {
                        type: 'response-distribution',
                        label: <FormattedMessage id="reportmenu.responseDistribution" defaultMessage="Response Distribution" />,
                        class: classes.indent2,
                        upgrade: upgrade('response-distribution')
                    },
                    {
                        type: 'element-hotspots',
                        label: <FormattedMessage id="reportmenu.hotspots" defaultMessage="Hotspots" />,
                        class: classes.indent2,
                        upgrade: upgrade('element-hotspots')
                    },
                    {
                        type: null,
                        header: <FormattedMessage id="reportmenu.priorities" defaultMessage="Priorities" />,
                        headerVariant: 'subtitle1',
                        class: classes.indent,
                    },
                    {
                        type: 'priorities',
                        label: <FormattedMessage id="reportmenu.companyPersonal" defaultMessage={"Company & Personal"} />,
                        class: classes.indent2,
                        upgrade: upgrade('priorities')
                    },
                    {
                        type: 'priority-hotspots',
                        label: <FormattedMessage id="reportmenu.priorityHotspots" defaultMessage={"Priority Hotspots"} />,
                        class: classes.indent2,
                        upgrade: upgrade('priority-hotspots')
                    },
                    {
                        type: 'priority-conflict-hotspots',
                        label: <FormattedMessage id="reportmenu.conflictHotspots" defaultMessage={"Conflict Hotspots"} />,
                        class: classes.indent2,
                        upgrade: upgrade('priority-conflict-hotspots')
                    },
                    {
                        type: null,
                        header: <FormattedMessage id="reportmenu.risks" defaultMessage={"Risks"} />,
                        headerVariant: 'subtitle1',
                        class: classes.indent,
                    },
                    {
                        type: 'risks',
                        label: <FormattedMessage id="reportmenu.riskRatings" defaultMessage="Risk Ratings" />,
                        class: classes.indent2,
                        upgrade: upgrade('risks')
                    },
                    {
                        type: null,
                        header: <FormattedMessage id="reportmenu.benchmarking" defaultMessage="Benchmarking" />,
                        headerVariant: 'subtitle1',
                        class: classes.indent,
                    },
                    {
                        type: null,
                        header: <FormattedMessage id="reportmenu.categoriesAndDimensions" defaultMessage={"Categories & Dimensions"} />,
                        headerVariant: 'subtitle1',
                        class: classes.indent2,
                    },
                    {
                        type: 'benchmark-company',
                        label: <FormattedMessage id="reportmenu.benchmarkCompany" defaultMessage={"Company"} />,
                        class: classes.indent3,
                        show: !upgrade('benchmark-company')
                    },
                    {
                        type: 'benchmark-region',
                        label: <FormattedMessage id="reportmenu.benchmarkRegion" defaultMessage={"Region"} />,
                        class: classes.indent3,
                        upgrade: upgrade('benchmark-region')
                    },
                    {
                        type: 'benchmark-country',
                        label: <FormattedMessage id="reportmenu.benchmarkCountry" defaultMessage={"Country"} />,
                        class: classes.indent3,
                        upgrade: upgrade('benchmark-country')
                    },
                    {
                        type: null,
                        header: <FormattedMessage id="reportmenu.elements" defaultMessage={"Elements"} />,
                        headerVariant: 'subtitle1',
                        class: classes.indent2,
                    },
                    {
                        type: 'benchmark-elements-company',
                        label: <FormattedMessage id="reportmenu.benchmarkCompany" defaultMessage={"Company"} />,
                        class: classes.indent3,
                        upgrade: upgrade('benchmark-elements-company')
                    },
                    {
                        type: null,
                        header: <FormattedMessage id="reportmenu.changeOverTime" defaultMessage="Change Over Time" />,
                        headerVariant: 'subtitle1',
                        class: classes.indent,
                    },
                    {
                        type: 'elements-comparison',
                        label: <FormattedMessage id="reportmenu.elements" defaultMessage="Elements" />,
                        class: classes.indent2,
                        upgrade: upgrade('elements-comparison')
                    },
                    {
                        type: 'priorities-comparison',
                        label: <FormattedMessage id="reportmenu.priorities" defaultMessage="Priorities" />,
                        class: classes.indent2,
                        upgrade: upgrade('priorities-comparison')
                    },
                    {
                        type: 'risks-comparison',
                        label: <FormattedMessage id="reportmenu.risks" defaultMessage="Risks" />,
                        class: classes.indent2,
                        upgrade: upgrade('risks-comparison')
                    },
                ]
            },
        ]
    }, [props, classes.indent, classes.indent2, classes.indent3, user]);

    const [expand, setExpand] = useState({
        categoriesAndDimensions: false,
        elements: false,
        priorities: false,
        positivesAndBarriers: false,
        risks: false,
        quality: false,
        benchmarking: false,
        changeOverTime: false,
        qualitativeData: false,
        information: false
    });

    useEffect(() => {
        premiumList.forEach(e => {
            if (e.collapse !== null && e.reports) {
                e.reports.forEach(r => {
                    if (r.type !== null && (props.reportType === r.type || (props.reportType === 'upgrade' && location.search.endsWith('='+r.type)))) {
                        setExpand(prevState => ({...prevState, [e.collapse]: true }));
                    }
                });
            }
        });
    }, [props.reportType, premiumList, location.search]);


    const toggleExpand = (name) => {
        setExpand(prevState => ({...prevState, [name]: !prevState[name] }));
    };

    const brcgsBasicList = (location) => {
        return (
            <List>
                <ListItem>
                    <Typography variant="h6">
                        <FormattedMessage id="reportmenu.title" defaultMessage="Reports" />
                    </Typography>
                </ListItem>
                <Link component={RouterLink} to={"/survey-report/"+props.hash+"/basic-data"} onClick={props.closeDrawer}>
                    <ListItem selected={location.pathname.endsWith('/basic-data')} button>
                        <ListItemText>
                            <FormattedMessage id="reportmenu.overview" defaultMessage={"Overview"} />
                        </ListItemText>
                    </ListItem>
                </Link>
                <Link component={RouterLink} to={"/survey-report/"+props.hash+"/elements"} onClick={props.closeDrawer}>
                    <ListItem selected={location.pathname.endsWith('/elements')} button>
                        <ListItemText>
                            <FormattedMessage id="reportmenu.elements" defaultMessage="Elements" />
                        </ListItemText>
                    </ListItem>
                </Link>
                <Link component={RouterLink} to={"/survey-report/"+props.hash+"/ideas"} onClick={props.closeDrawer}>
                    <ListItem selected={location.pathname.endsWith('/ideas')} button>
                        <ListItemText>
                            <FormattedMessage id="reportmenu.ideas" defaultMessage="Ideas for Improvement" />
                        </ListItemText>
                    </ListItem>
                </Link>
                <Link component={RouterLink} to={"/survey-report/"+props.hash+"/definitions"} onClick={props.closeDrawer}>
                    <ListItem selected={location.pathname.endsWith('/definitions')} button>
                        <ListItemText>
                            <FormattedMessage id="reportmenu.definitions" defaultMessage="Definitions" />
                        </ListItemText>
                    </ListItem>
                </Link>
            </List>
        );
    };

    const ssafeList = (location) => {
        return (
            <List>
                <ListItem>
                    <Typography variant="h6">
                        <FormattedMessage id="reportmenu.title" defaultMessage="Reports" />
                    </Typography>
                </ListItem>
                <Link component={RouterLink} to={"/survey-report/"+props.hash+"/basic-data"} onClick={props.closeDrawer}>
                    <ListItem selected={location.pathname.endsWith('/basic-data')} button>
                        <ListItemText>
                            <FormattedMessage id="reportmenu.overview" defaultMessage={"Overview"} />
                        </ListItemText>
                    </ListItem>
                </Link>
                <Link component={RouterLink} to={"/survey-report/"+props.hash+"/elements"} onClick={props.closeDrawer}>
                    <ListItem selected={location.pathname.endsWith('/elements')} button>
                        <ListItemText>
                            <FormattedMessage id="reportmenu.questions" defaultMessage="Questions" />
                        </ListItemText>
                    </ListItem>
                </Link>
                {user && user.role === 'admin' ?
                    <Fragment>
                        <ListItem>
                            <Typography variant="subtitle1" style={{fontWeight: 500}}>
                                <FormattedMessage id="reportmenu.benchmarking" defaultMessage="Benchmarking" />
                            </Typography>
                        </ListItem>
                        <Link component={RouterLink} to={"/survey-report/"+props.hash+"/benchmark-ssafe"} onClick={props.closeDrawer}>
                            <ListItem selected={location.pathname.endsWith('/benchmark-ssafe')} button className={classes.indent}>
                                <ListItemText>
                                    <FormattedMessage id="reportmenu.benchmarkSsafe" defaultMessage={"SSAFE"} />
                                </ListItemText>
                            </ListItem>
                        </Link>
                        <Link component={RouterLink} to={"/survey-report/"+props.hash+"/benchmark-elements-ssafe"} onClick={props.closeDrawer}>
                            <ListItem selected={location.pathname.endsWith('/benchmark-elements-ssafe')} button className={classes.indent}>
                                <ListItemText>
                                    <FormattedMessage id="reportmenu.elements" defaultMessage={"Elements"} />
                                </ListItemText>
                            </ListItem>
                        </Link>
                    </Fragment>
                : null }
            </List>
        );
    };

    const list = (location, surveyType) => {
        // Survey not loaded, no reason to render a (partial) menu
        if (!surveyType) {
            return (
                <List></List>
            );
        }

        if ('ssafe' === surveyType) {
            return ssafeList(location);
        }

        if ('brcgs-free' === surveyType) {
            return brcgsBasicList(location);
        }

        return (<List>
            {premiumList.map((item, index) => {
                if (!item.show) {
                    return null;
                }
                return (<Fragment key={index}>
                    {item.collapse ?
                        <ListItem button onClick={() => toggleExpand(item.collapse)}>
                            <ListItemText>
                                <Typography variant={item.headerVariant}>
                                    {item.header}
                                </Typography>
                            </ListItemText>
                            {expand[item.collapse] ? <ExpandLess /> : <ExpandMore />}
                        </ListItem>
                    :
                        <ListItem>
                            <Typography variant={item.headerVariant}>
                                {item.header}
                            </Typography>
                        </ListItem>
                    }
                    <Collapse in={item.collapse ? expand[item.collapse] : true}>
                        {item.reports.map((report, reportIndex) => {
                            let theme = null;
                            if (props.surveyType === 'brcgs' || props.surveyType === 'brcgs-free') {
                                theme = 'brcgs';
                            } else if (props.surveyType === 'ssafe') {
                                theme = 'ssafe';
                            }

                            if (false === report.show) {
                                return null;
                            }
                            else if (report.type) {
                                if (!report.upgrade) {
                                    return (
                                        <Link component={RouterLink} to={"/survey-report/"+props.hash+"/"+report.type+(theme ? '?theme='+theme : '')} onClick={props.closeDrawer} key={reportIndex}>
                                            <ListItem selected={location.pathname.endsWith('/'+report.type)} button className={report.class}>
                                                <ListItemText>
                                                    {report.label}
                                                </ListItemText>
                                            </ListItem>
                                        </Link>
                                    );
                                } else {
                                    return (
                                        <Link component={RouterLink} to={"/survey-report/"+props.hash+"/upgrade?type="+report.type} onClick={props.closeDrawer} key={reportIndex}>
                                            <ListItem selected={location.search.endsWith('='+report.type)} button className={report.class}>
                                                <ListItemText>
                                                    {report.label}
                                                </ListItemText>
                                            </ListItem>
                                        </Link>
                                    );
                                }
                            } else if (report.url) {
                                return (
                                    <Link href={report.url} target="_blank" rel="noreferrer" key={reportIndex}>
                                        <ListItem button className={report.class}>
                                            <ListItemText>
                                                {report.label}
                                            </ListItemText>
                                        </ListItem>
                                    </Link>
                                );
                            } else {
                                return (
                                    <ListItem className={report.class} key={reportIndex}>
                                        <Typography variant={report.headerVariant}>
                                            {report.header}
                                        </Typography>
                                    </ListItem>
                                );
                            }
                        })}
                    </Collapse>
                </Fragment>);
            })}
        </List>);
    }

    return (
        <nav className={classes.drawer}>
            {/* Desktop */}
            <Hidden xsDown implementation="css">
                <Drawer
                    classes={{
                        paper: classes.drawerPaper,
                    }}
                    container={container}
                    variant="permanent"
                    anchor="left"
                    open
                >
                    <div>
                        <div className={classes.toolbar} />
                        {list(location, props.surveyType)}
                    </div>
                </Drawer>
            </Hidden>
            {/* Mobile */}
            <Hidden smUp implementation="css">
                <Drawer
                    container={container}
                    variant="temporary"
                    anchor="left"
                    open={props.mobileOpen}
                    onClose={props.handleDrawerToggle}
                    classes={{
                        paper: classes.drawerPaper
                    }}
                >
                    <div>
                        <div className={classes.toolbar} />
                        {list(location, props.surveyType)}
                    </div>
                </Drawer>
            </Hidden>
        </nav>
    );
}