export default class BaseService {
    constructor() {
        this.baseUrl = process.env.REACT_APP_API_URL;
    }

    apiUrl(path) {
        return this.baseUrl + '/v1' + path;
    }

    getAuthHeader() {
        const token = JSON.parse(localStorage.getItem('token'));
        if (!token) {
            return {};
        }
        return {
            'X-Auth-Token': token.token,
        };
    }
}