import axios from 'axios';
import BaseService from './BaseService';

class CompanyService extends BaseService {

    companies(offset = 0, limit = 50, sort = null, search = null, fields = null) {
        return axios.get(this.apiUrl('/companies'), {
            params: {
                'page[offset]': offset,
                'page[limit]': limit,
                'search': search,
                'sort': sort,
                'fields': fields
            },
            headers: this.getAuthHeader()
        });
    }

    async allCompanies(sort = null, search = null, filter = null, offset = 0) {
        const response = await this.companies(offset, 50, sort, search, filter);
        const data = response.data.data;
        if (response.data.links.next) {
            return data.concat(await this.allCompanies(sort, search, filter, offset+50));
        } else {
            return data;
        }
    }

    company(uuid) {
        return axios.get(this.apiUrl('/companies/'+uuid), {
            headers: this.getAuthHeader()
        });
    }

    createCompany(data) {
        return axios.post(this.apiUrl('/companies'), {
            data: {
                type: 'company',
                attributes: data,
            },
        },{
            headers: this.getAuthHeader()
        });
    }

    patchCompany(uuid, data) {
        return axios.patch(this.apiUrl('/companies/'+uuid), {
            data: {
                type: 'company',
                attributes: data
            }
        },{
            headers: this.getAuthHeader()
        });
    }

    roles(uuid) {
        return axios.get(this.apiUrl('/companies/'+uuid+'/roles'), {
            headers: this.getAuthHeader()
        });
    }

    importSurvey(uuid, surveyProvider, providerId, surveyType, closeDate = null) {
        return axios.post(this.apiUrl('/companies/'+uuid+'/import-survey'), {
            data: {
                type: 'import-survey',
                attributes: {
                    surveyProvider: surveyProvider,
                    providerId: providerId,
                    surveyType: surveyType,
                    closedAt: closeDate
                },
            },
        },{
            headers: this.getAuthHeader()
        });
    }

    deleteSiteMapping(companyUuid, siteMappingUuid) {
        return axios.delete(this.apiUrl('/companies/'+companyUuid+'/site-mappings/'+siteMappingUuid), {
            headers: this.getAuthHeader()
        });
    }

    createSiteMapping(companyUuid, sites) {
        const siteRelations = [];
        sites.forEach(siteId => {
            siteRelations.push({
                type: 'site',
                id: siteId
            });
        });

        return axios.post(this.apiUrl('/companies/'+companyUuid+'/site-mappings'), {
            data: {
                type: 'site-mapping',
                attributes: {},
                relationships: {
                    sites: siteRelations,
                },
            }
        },{
            headers: this.getAuthHeader()
        });
    }

    siteMappings(uuid, offset = 0, limit = 50) {
        const params = {
            'page[offset]': offset,
            'page[limit]': limit,
        };

        return axios.get(this.apiUrl('/companies/'+uuid+'/site-mappings'), {
            params: params,
            headers: this.getAuthHeader()
        });
    }

    async allSiteMappings(uuid, offset = 0) {
        const response = await this.siteMappings(uuid, offset, 50);
        const data = response.data.data;
        if (response.data.links.next) {
            return data.concat(await this.allSiteMappings(uuid, offset+50));
        } else {
            return data;
        }
    }
}

export default new CompanyService();
