import { lazy, Suspense } from "react";
const Radar = lazy(() => import(/* webpackChunkName: "charts" */'./Radar'));

export default function RadarLazy(props) {
    const {innerRef, ...rest} = props;
    return (
        <Suspense fallback={<div />}>
            <Radar innerRef={innerRef} {...rest} />
        </Suspense>
    );
}