export const SURVEY_LANGUAGES = {
    "sq" : "Albanian",
    "ar" : "Arabic",
    "hy" : "Armenian",
    "be" : "Belarusian",
    "bn" : "Bengali",
    "bs" : "Bosnian",
    "bg" : "Bulgarian",
    "my" : "Burmese",
    "zh_TW" : "Chinese (traditional)",
    "zh_CN" : "Chinese (simplified)",
    "hr" : "Croatian",
    "cs" : "Czech",
    "da" : "Danish",
    "nl" : "Dutch",
    "en" : "English",
    "et" : "Estonian",
    "fi" : "Finnish",
    "fr" : "French",
    "de" : "German",
    "el" : "Greek",
    "hi" : "Hindi",
    "hu" : "Hungarian",
    "is" : "Icelandic",
    "id" : "Indonesian",
    "it" : "Italian",
    "ja" : "Japanese",
    "km" : "Khmer",
    "ko" : "Korean",
    "lv" : "Latvian",
    "lt" : "Lithuanian",
    "mk" : "Macedonian",
    "ms" : "Malay",
    "ml" : "Malayalam",
    "mr" : "Marathi",
    "ne" : "Nepali",
    "no" : "Norwegian",
    "pl" : "Polish",
    "pt" : "Portuguese",
    "pt_BR" : "Portuguese (Brazil)",
    "ro" : "Romanian",
    "ru" : "Russian",
    "sr" : "Serbian",
    "si" : "Sinhala",
    "sk" : "Slovak",
    "sl" : "Slovenian",
    "es" : "Spanish",
    "es_US" : "Spanish (LATAM)",
    "sv" : "Swedish",
    "tl" : "Tagalog (Philippines)",
    "ta" : "Tamil",
    "th" : "Thai",
    "tr" : "Turkish",
    "uk" : "Ukrainian",
    "ur" : "Urdu",
    "vi" : "Vietnamese"
}