import { Button, Grid, Checkbox, Collapse, Drawer, Hidden, List, ListItem, ListItemText, makeStyles, Typography, FormControlLabel } from "@material-ui/core";
import { Fragment, useState, useEffect } from "react";
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { FormattedMessage, useIntl } from "react-intl";
import DemographicLabel from "../../Translation/DemographicLabel";
import DemographicOptionLabel from "../../Translation/DemographicOptionLabel";
import { typeTranslated } from "../../Translation/Type";

const drawerWidth = 300;
const useStyles = makeStyles((theme) => ({
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
        width: drawerWidth,
        paddingBottom: '64px'
    },
    drawer: {
        [theme.breakpoints.up("xl")]: {
            width: drawerWidth,
            flexShrink: 0
        }
    }
}));

export default function FilterMenu(props) {
    const { w } = props;
    const container = w !== undefined ? () => w().document.body : undefined;
    const classes = useStyles();
    const [typesExpand, setTypesExpand] = useState(true);
    const [typesChecked, setTypesChecked] = useState(null);
    const [questionsExpand, setQuestionsExpand] = useState(null);
    const [questionsChecked, setQuestionsChecked] = useState(null);
    const [filterActionTaken, setFilterActionTaken] = useState(false);
    const intl = useIntl();

    // Whenever props change, generate state
    useEffect(() => {
        const initialQuestionsExpand = {};
        props.demographicQuestions.forEach((question) => {
            if (!(question.id in questionsExpand)) {
                // Expand
                initialQuestionsExpand[question.id] = true;
            } else {
                // Use whatever the expand state was previously
                initialQuestionsExpand[question.id] = questionsExpand[question.id];
            }

        });
        setQuestionsExpand(initialQuestionsExpand);
        setFilterActionTaken(false);

        // Use reset filter handler to set initial state
        initFilters();

        // eslint-disable-next-line
    }, [props.demographicQuestions, props.questionTypes, props.filterDemographics])

    useEffect(() => {
        if (!filterActionTaken || !questionsChecked || 0 === Object.keys(questionsChecked).length) {
            return;
        }
        const currentFilters = {};
        Object.keys(questionsChecked).forEach((questionKey) => {
            if (!questionsChecked[questionKey].all) {
                currentFilters[questionKey] = [];
                Object.keys(questionsChecked[questionKey].options).forEach((optionKey) => {
                    if (questionsChecked[questionKey].options[optionKey]) {
                        currentFilters[questionKey].push(optionKey)
                    }
                });
            }
        });

        const currentTypes = [];
        Object.keys(typesChecked).forEach((type) => {
            if (type !== 'all' && typesChecked[type]) {
                currentTypes.push(type);
            }
        });

        props.onFilterChange(currentFilters, currentTypes);
        // eslint-disable-next-line
    }, [questionsChecked, filterActionTaken, typesChecked])

    const handleResetFilters = () => {
        setFilterActionTaken(true);
        // Force filters to be reset
        initFilters(true);
    };

    const initFilters = (force = false) => {
        // Reset demographic filters
        const resetquestionsChecked = {};
        props.demographicQuestions.forEach((question) => {
            resetquestionsChecked[question.attributes.demographic] = {
                all: true,
                options: {}
            };
            question.attributes.options.forEach((option) => {
                if (force === false &&
                    props.filterDemographics && // There is an existing filter
                    props.filterDemographics[question.attributes.demographic] && // This demographic type is present
                    -1 !== props.filterDemographics[question.attributes.demographic].indexOf(option.attributes.value) // This value is filtered on
                ) {
                    // Check box
                    resetquestionsChecked[question.attributes.demographic].options[option.attributes.value] = true;
                    // Uncheck all
                    resetquestionsChecked[question.attributes.demographic].all = false;
                } else {
                    resetquestionsChecked[question.attributes.demographic].options[option.attributes.value] = false;
                }
            });
        });
        setQuestionsChecked(resetquestionsChecked);
        const resetTypesChecked = {
            all: true
        };
        if (props.questionTypes) {
            props.questionTypes.forEach((type) => {
                if (force === false &&
                    props.filterTypes &&
                    -1 !== props.filterTypes.indexOf(type)
                ) {
                    // Check box
                    resetTypesChecked[type] = true;
                    // Uncheck all
                    resetTypesChecked.all = false;
                } else {
                    resetTypesChecked[type] = false;
                }
            });
        }

        setTypesChecked(resetTypesChecked);
    };

    const handleDemographicChange = (demographic, type, value, event) => {
        setFilterActionTaken(true);
        if ('all' === type) {
            // Do nothing, can only uncheck "all" by selecting another checkbox
            if (false === event.target.checked) {
                return;
            } else {
                // Uncheck all checkboxes below
                Object.keys(questionsChecked[demographic].options).forEach((optionValue) => {
                    setQuestionsChecked(prevState => ({
                        ...prevState,
                        [demographic]: {
                            ...prevState[demographic],
                            options: {
                                ...prevState[demographic].options,
                                [optionValue]: false
                            }
                        }
                    }));
                });
            }
            setQuestionsChecked(prevState => ({
                ...prevState,
                [demographic]: {
                    ...prevState[demographic],
                    all: event.target.checked
                }
            }));
        } else {
            // Check if this is the last checked box and set "All" to checked if so
            if (false === event.target.checked) {
                const lastChecked = !Object.keys(questionsChecked[demographic].options).some((optionValue) => {
                    return optionValue !== value && questionsChecked[demographic].options[optionValue];
                });

                if (lastChecked) {
                    setQuestionsChecked(prevState => ({
                        ...prevState,
                        [demographic]: {
                            ...prevState[demographic],
                            all: true
                        }
                    }));
                }
            } else {
                // Uncheck "all" checkbox
                setQuestionsChecked(prevState => ({
                    ...prevState,
                    [demographic]: {
                        ...prevState[demographic],
                        all: false
                    }
                }));
            }

            // Set checkbox checked state
            setQuestionsChecked(prevState => ({
                ...prevState,
                [demographic]: {
                    ...prevState[demographic],
                    options: {
                        ...prevState[demographic].options,
                        [value]: event.target.checked
                    }
                }
            }));
        }
    };

    const handleTypeChange = (type, event) => {
        if ('all' === type) {
            // Do nothing, can only uncheck "all" by selecting another checkbox
            if (false === event.target.checked) {
                return;
            } else {
                // Uncheck all other types
                Object.keys(typesChecked).forEach((currentType) => {
                    if ('all' !== currentType) {
                        setTypesChecked(prevState => ({
                            ...prevState,
                            [currentType]: false
                        }));
                    }
                });
            }
        } else {
            // Check if this is the last checked box and set "All" to checked if so
            if (false === event.target.checked) {
                const lastChecked = !Object.keys(typesChecked).some((currentType) => {
                    return currentType !== 'all' && currentType !== type && typesChecked[currentType];
                });

                if (lastChecked) {
                    setTypesChecked(prevState => ({
                        ...prevState,
                        all: true
                    }));
                }
            } else {
                setTypesChecked(prevState => ({
                    ...prevState,
                    all: false
                }));
            }
        }
        setTypesChecked(prevState => ({
            ...prevState,
            [type]: event.target.checked
        }));
        setFilterActionTaken(true);
    };

    // TODO: Move styling up into css classes stuff instead of style={{...}} crap
    const generateList = (demographicQuestions, questionsChecked, questionsExpand, questionTypes, typesChecked) => {
        if (!questionsChecked || 0 === Object.keys(questionsChecked).length ||
            !questionsExpand || 0 === Object.keys(questionsExpand).length ||
            !typesChecked || 0 === Object.keys(typesChecked).length) {
            return null;
        }

        return (<List>
            <ListItem>
                <Grid justifyContent="space-between" container>
                    <Grid item>
                        <Typography variant="h6">
                            <FormattedMessage id="filterMenu.title" defaultMessage="Filters" />
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Hidden smUp implementation="css">
                            <Button
                                variant="contained"
                                color="secondary"
                                size="small"
                                onClick={props.handleDrawerToggle}
                            >
                                <FormattedMessage id="filterMenu.apply" defaultMessage="Apply" />
                            </Button>
                        </Hidden>
                    </Grid>
                    <Grid item>
                        <Button
                            variant="contained"
                            color="secondary"
                            size="small"
                            onClick={handleResetFilters}
                        >
                            <FormattedMessage id="filterMenu.reset" defaultMessage="Reset" />
                        </Button>
                    </Grid>
                </Grid>
            </ListItem>
            <ListItem style={ props.hideType || 'ssafe' === props.surveyType ? {display: 'none' } : { paddingBottom: 0}} button onClick={() => setTypesExpand(!typesExpand)}>
                <ListItemText>
                    <Typography variant="subtitle1">
                        <FormattedMessage id="filterMenu.cultureType" defaultMessage="Culture Type" />
                    </Typography>
                </ListItemText>
                {typesExpand ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={typesExpand} style={ props.hideType || 'ssafe' === props.surveyType ? {display: 'none' } : {}}>
                <ListItem style={{paddingTop: '4px', paddingBottom: '4px'}}>
                    <FormControlLabel
                        style={{marginLeft: 0}}
                        control={<Checkbox
                            onChange={(event) => handleTypeChange('all', event)}
                            style={{ padding: '5px 5px 5px 0px' }}
                            checked={typesChecked.all} />}
                        label={<FormattedMessage id="filterMenu.all" defaultMessage="All" />}
                    />
                </ListItem>
                {questionTypes.map((type, index) => {
                    if ('foodsafety-quality' === type) {
                        return null;
                    }

                    return (
                        <ListItem key={index} style={{paddingTop: '4px', paddingBottom: '4px'}}>
                            <FormControlLabel
                                style={{marginLeft: 0}}
                                control={<Checkbox
                                    onChange={(event) => handleTypeChange(type, event)}
                                    style={{ padding: '5px 5px 5px 0px' }}
                                    checked={typesChecked[type]}
                                />}
                                label={typeTranslated(type, intl)}
                            />
                        </ListItem>
                    );
                })}
            </Collapse>
            {demographicQuestions.map((question, index) => {
                // If force hidden or there is only one option to check, hide question
                if (props.hideDemographic || question.attributes.options.length < 1) {
                    return null;
                }

                if (props.show && props.show.length && -1 === props.show.indexOf(question.attributes.demographic)) {
                    return null; // Only show demographic questions in the show list
                } else if (props.hide && props.hide.length && -1 !== props.hide.indexOf(question.attributes.demographic)) {
                    return null; // Hide demographic questions in the hide list
                }

                return (
                    <Fragment key={index}>
                        <ListItem style={{ paddingBottom: 0}} button onClick={() => setQuestionsExpand(prevState => ({...prevState, [question.id]: !questionsExpand[question.id]}))}>
                            <ListItemText>
                                <Typography variant="subtitle1">
                                    {question.attributes.shortName ? <DemographicLabel demographic={question.attributes.shortName}/> : question.attributes.title}
                                </Typography>
                            </ListItemText>
                            {questionsExpand[question.id] ? <ExpandLess /> : <ExpandMore />}
                        </ListItem>
                        <Collapse in={questionsExpand[question.id]}>
                            {question.attributes.options.length !== 1 ?
                                <ListItem style={{paddingTop: '4px', paddingBottom: '4px'}}>
                                    <FormControlLabel
                                        style={{marginLeft: 0}}
                                        control={<Checkbox
                                            onChange={(event) => handleDemographicChange(question.attributes.demographic, 'all', null, event)}
                                            checked={questionsChecked[question.attributes.demographic].all}
                                            style={{ padding: '5px 5px 5px 0px' }} />}
                                        label={<FormattedMessage id="filterMenu.all" defaultMessage="All" />}
                                    />
                                </ListItem>
                            : null}
                            {question.attributes.options.map((option, index) => (
                                <ListItem key={index} style={{paddingTop: '4px', paddingBottom: '4px'}}>
                                    <FormControlLabel
                                        style={{marginLeft: 0}}
                                        control={<Checkbox
                                            onChange={(event) => {
                                                if (question.attributes.options.length === 1) {
                                                    return;
                                                } else {
                                                    handleDemographicChange(question.attributes.demographic, null, option.attributes.value, event);
                                                }
                                            }}
                                            checked={questionsChecked[question.attributes.demographic].options[option.attributes.value] || question.attributes.options.length === 1}
                                            style={{ padding: '5px 5px 5px 0px' }} />}
                                        label={
                                            <DemographicOptionLabel
                                                demographic={question.attributes.demographic}
                                                demographicOptionLabels={props.demographicOptionLabels}
                                                option={option.attributes.title}
                                                translations={option.attributes.translations}
                                            />
                                        }
                                    />
                                </ListItem>
                            ))}
                        </Collapse>
                    </Fragment>
                );
            })}
        </List>);
    }

    const allHidden = props.hideDemographic && props.hideType;
    return (
        <nav className={classes.drawer} style={ ('brcgs-free' === props.surveyType || allHidden ? {display: 'none'} : {})}>
            {/* Desktop */}
            <Hidden lgDown implementation="css">
                <Drawer
                    classes={{
                        paper: classes.drawerPaper,
                    }}
                    container={container}
                    variant="permanent"
                    anchor="right"
                    open
                >
                    <div>
                        <div className={classes.toolbar} />
                        {generateList(props.demographicQuestions, questionsChecked, questionsExpand, props.questionTypes, typesChecked)}
                    </div>
                </Drawer>
            </Hidden>
            {/* Mobile */}
            <Hidden xlUp implementation="css">
                <Drawer
                    container={container}
                    variant="temporary"
                    anchor="right"
                    open={props.mobileOpen}
                    onClose={props.handleDrawerToggle}
                    classes={{
                        paper: classes.drawerPaper
                    }}
                >
                    <div>
                        <div className={classes.toolbar} />
                        {generateList(props.demographicQuestions, questionsChecked, questionsExpand, props.questionTypes, typesChecked)}
                    </div>

                </Drawer>
            </Hidden>
        </nav>
    );
}
