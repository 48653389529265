import { Grid, Typography } from "@material-ui/core";
import { useContext, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { NotificationContext } from "../../../Context/NotificationContext";
import { SpinnerContext } from "../../../Context/SpinnerContext";
import UserService from "./../../../Services/UserService";


export default function Verify(props) {
    const intl = useIntl();
    const [started, setStarted] = useState(false);
    const [message, setMessage] = useState(intl.formatMessage({id: 'verify.inProgress', defaultMessage: 'Your account is being verified...'}));
    const [details, setDetails] = useState(null);

    const { updateLoading } = useContext(SpinnerContext);
    const { updateNotification } = useContext(NotificationContext);

    useEffect(() => {
        if (!started) {
            setStarted(true);

            const query = new URLSearchParams(window.location.search);
            updateLoading(true);

            UserService.verify(query.get('token'))
            .then(function (response) {
                setMessage(intl.formatMessage({id: 'verify.thanks', defaultMessage: 'Thank you for confirming your registration. You will receive a welcome email when your survey has been set up, this may take a few minutes!'}));
                setDetails(<FormattedMessage
                    id="verify.confirmationSpamNotice"
                    defaultMessage="<strong>Important</strong>: If you do not receive an email from <em>noreply@culturexcellence.com</em>, please check your spam/junk folder and any email filtering services being used."
                    values = {{
                        em: chunks => <em>{chunks}</em>,
                        strong: chunks => <strong>{chunks}</strong>
                    }}
                />);
            }).catch(function (error) {
                if (error.response && error.response.status && 401 === error.response.status) {
                    updateNotification(true, intl.formatMessage({id: 'verify.couldNotConfirmError', defaultMessage: 'Registration could not be confirmed'}), 'error');
                } else {
                    updateNotification(true, intl.formatMessage({id: 'generic.unknownError', defaultMessage: 'An unknown error occurred!'}), 'error');
                }
                setMessage(intl.formatMessage({id: 'verify.confirmationFailed', defaultMessage: 'Registration could not be confirmed'}));
                setDetails(<FormattedMessage
                    id="verify.confirmationFailedSpamNotice"
                    defaultMessage="If you have already confirmed your registration, please check your inbox (including spam/junk folders) for an email from <em>noreply@culturexcellence.com</em>"
                    values = {{
                        em: chunks => <em>{chunks}</em>,
                    }}
                />);
            }).then(function() {
                updateLoading(false);
            });
        }
    }, [updateLoading, updateNotification, started, intl]);

    return(
        <Grid container spacing={0} justifyContent="center">
            <Grid item sm={10} md={5} lg={4}>
                <Typography variant="h6" paragraph>
                    <FormattedMessage id="verify.header" defaultMessage="Registration Confirmation"/>
                </Typography>
                <Typography variant="body1" paragraph>
                    { message }
                </Typography>
                <Typography variant="body1" paragraph>
                    { details }
                </Typography>
            </Grid>
        </Grid>
    );
}