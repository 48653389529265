import { Box, Grid, Table, TableBody, Typography, Button, TableRow, TableCell, TableHead, Dialog, DialogContent, DialogTitle, DialogActions, DialogContentText, makeStyles, IconButton, List, ListItem, ListItemText, ListItemSecondaryAction, Checkbox, Divider, Fab, Tooltip } from "@material-ui/core";
import { useContext, useEffect, useState } from "react";
import ElementMappingService from "../../Services/ElementMappingService";
import { SpinnerContext } from "../../Context/SpinnerContext";
import { NotificationContext } from "../../Context/NotificationContext";
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import CloseIcon from '@material-ui/icons/Close';
import { AppBarTitleContext } from "../../Context/AppBarTitleContext";
import { Fragment } from "react";

const useStyles = makeStyles((theme) => ({
    fab: {
        position: 'fixed',
        bottom: theme.spacing(1.5),
        right: theme.spacing(1.5),
    },
    rowButton: {
        padding: '6px'
    },
    list: {
        width: '100%',
    },
}));

export default function ElementMappings(props) {
    const classes = useStyles();
    const { updateLoading } = useContext(SpinnerContext);
    const { updateTitle } = useContext(AppBarTitleContext);
    const { updateNotification } = useContext(NotificationContext);

    const [elementMappings, setElementMappings] = useState([]);
    const [uniqueElements, setUniqueElements] = useState([]);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [deleteUuid, setDeleteUuid] = useState(null);
    const [selectedElements, setSelectedElements] = useState([]);

    useEffect(() => {
        fetchElementMappings();
        fetchUniqueElements();
        updateTitle(null);
        // eslint-disable-next-line
    }, [location.search]);

    const fetchElementMappings = () => {
        updateLoading(true);
        ElementMappingService.allElementMappings().then(response => {
            setElementMappings(response);
        }).catch(error => {
            updateNotification(true, 'An unknown error occurred', 'error');
            console.log(error);
        }).then(() => {
            updateLoading(false);
        });
    };

    const fetchUniqueElements = () => {
        updateLoading(true);
        ElementMappingService.uniqueElements().then(response => {
            setUniqueElements(response.data.data);
        }).catch(error => {
            updateNotification(true, 'An unknown error occurred', 'error');
            console.log(error);
        }).then(() => {
            updateLoading(false);
        });
    };

    const handleDelete = () => {
        // To avoid multiple submits
        if (!deleteDialogOpen) {
            return;
        }

        setDeleteDialogOpen(false);
        updateLoading(true);

        ElementMappingService.delete(deleteUuid)
        .then(function (response) {
            setDeleteUuid(null);

            // Refetch stuff
            fetchElementMappings();
            fetchUniqueElements();
        }).catch(function (error) {
            if (error.response && error.response.status) {
                if (error.response.data.error.detail) {
                    updateNotification(true, error.response.data.error.detail, 'error');
                } else {
                    updateNotification(true, 'An unknown error occurred!', 'error');
                }
            } else {
                updateNotification(true, 'An unknown error occurred!', 'error');
            }
            console.error(error);
        }).then(function () {
            updateLoading(false);
        });
    }

    const removeFromElementMapping = (em, elementToRemove) => {
        updateLoading(true);
        const elements = em.attributes.elements.filter(e => e !== elementToRemove);
        ElementMappingService.patch(em.id, {elements: elements})
        .then(function (response) {
            // Refetch stuff
            setSelectedElements([]);
            fetchElementMappings();
            fetchUniqueElements();
        }).catch(function (error) {
            if (error.response && error.response.status) {
                if (error.response.data.error.detail) {
                    updateNotification(true, error.response.data.error.detail, 'error');
                } else {
                    updateNotification(true, 'An unknown error occurred!', 'error');
                }
            } else {
                updateNotification(true, 'An unknown error occurred!', 'error');
            }
            console.error(error);
        }).then(function () {
            updateLoading(false);
        });
    };

    const addToElementMapping = (em) => {
        updateLoading(true);
        const elements = [];
        elements.push(...em.attributes.elements, ...selectedElements);

        ElementMappingService.patch(em.id, {elements: elements})
        .then(function (response) {
            // Refetch stuff
            setSelectedElements([]);
            fetchElementMappings();
            fetchUniqueElements();
        }).catch(function (error) {
            if (error.response && error.response.status) {
                if (error.response.data.error.detail) {
                    updateNotification(true, error.response.data.error.detail, 'error');
                } else {
                    updateNotification(true, 'An unknown error occurred!', 'error');
                }
            } else {
                updateNotification(true, 'An unknown error occurred!', 'error');
            }
            console.error(error);
        }).then(function () {
            updateLoading(false);
        });
    };

    const createElementMapping = () => {
        updateLoading(true);
        ElementMappingService.create({elements: selectedElements})
        .then(function (response) {
            // Refetch stuff
            setSelectedElements([]);
            fetchElementMappings();
            fetchUniqueElements();
        }).catch(function (error) {
            if (error.response && error.response.status) {
                if (error.response.data.error.detail) {
                    updateNotification(true, error.response.data.error.detail, 'error');
                } else {
                    updateNotification(true, 'An unknown error occurred!', 'error');
                }
            } else {
                updateNotification(true, 'An unknown error occurred!', 'error');
            }
            console.error(error);
        }).then(function () {
            updateLoading(false);
        });
    }

    const isInElementMapping = (element) => {
        const result = elementMappings.find((em) => {
            for (const e of em.attributes.elements) {
                if (e === element) {
                    return true;
                }
            }
            return false;
        });
        return result !== undefined;
    };

    const toggleElement = (element) => {
        const currentIndex = selectedElements.indexOf(element);
        const newChecked = [...selectedElements];

        if (currentIndex === -1) {
            newChecked.push(element);
        } else {
            newChecked.splice(currentIndex, 1);
        }
        setSelectedElements(newChecked);
    };

    return (
        <Fragment>
            <Grid container spacing={2} justifyContent="center">
                <Grid item sm={5}>
                    <Typography variant="h6">Unmapped Elements</Typography>
                    <Box pt={2} style={{maxHeight: '85vh', overflow: 'auto'}}>
                        <List className={classes.list}>
                            {uniqueElements.map((ele) => {
                                if (!isInElementMapping(ele)) {
                                    return <Fragment key={ele}>
                                        <ListItem button onClick={() => toggleElement(ele)}>
                                            <ListItemText primary={ele} />
                                            <ListItemSecondaryAction>
                                                <Checkbox
                                                    edge="end"
                                                    onChange={() => toggleElement(ele)}
                                                    checked={selectedElements.indexOf(ele) !== -1}
                                                />
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                        <Divider variant="inset" component="li" />
                                    </Fragment>
                                } else {
                                    return null;
                                }
                            })}
                        </List>
                    </Box>
                </Grid>
                <Grid item sm={7}>
                    <Typography variant="h6">Mapped Elements</Typography>
                    <Box pt={2}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Elements</TableCell>
                                    <TableCell align="center">Created</TableCell>
                                    <TableCell align="center">Updated</TableCell>
                                    <TableCell align="right"></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {elementMappings.map((em, i) => (
                                    <TableRow key={i}>
                                        <TableCell>
                                            <ul>
                                                {em.attributes.elements.map((e, j) => (
                                                    <li key={j}>{e}
                                                        {em.attributes.elements.length > 2 ?
                                                            <Tooltip title="Remove element from this mapping">
                                                                <IconButton style={{padding: 0}} color="secondary" onClick={() => {removeFromElementMapping(em, e);}}>
                                                                    <CloseIcon fontSize="small" />
                                                                </IconButton>
                                                            </Tooltip>
                                                        : null}
                                                    </li>
                                                ))}
                                            </ul>
                                        </TableCell>
                                        <TableCell align="center">{em.attributes.createdAt}</TableCell>
                                        <TableCell align="center">{em.attributes.updatedAt}</TableCell>
                                        <TableCell align="right">
                                            <Box>
                                                <Tooltip title="Add selected elements to this mapping">
                                                    <span>
                                                        <IconButton disabled={selectedElements.length < 1} className={classes.rowButton} onClick={() => {addToElementMapping(em);}}>
                                                            <AddIcon fontSize="small" style={selectedElements.length < 1 ? null : {color: '#4caf50'}} />
                                                        </IconButton>
                                                    </span>
                                                </Tooltip>
                                                <Tooltip title="Delete mapping">
                                                    <IconButton className={classes.rowButton} color="secondary" onClick={() => {setDeleteDialogOpen(true); setDeleteUuid(em.id)}}>
                                                        <DeleteIcon fontSize="small" />
                                                    </IconButton>
                                                </Tooltip>
                                            </Box>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </Box>
                </Grid>
            </Grid>
            <Dialog
                open={deleteDialogOpen}
                onClose={() => {setDeleteDialogOpen(false)}}
            >
                <DialogTitle>Delete</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        This will delete the element mapping, continue?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={() => {setDeleteDialogOpen(false)}} color="primary" autoFocus>
                        Cancel
                    </Button>
                    <Button variant="contained" onClick={handleDelete} color="secondary">
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
            <Tooltip title="Map selected elements" enterDelay={0}>
                <div className={classes.fab}>
                    <Fab disabled={selectedElements.length < 2} color="secondary" onClick={() => {createElementMapping()}}>
                        <AddIcon />
                    </Fab>
                </div>
            </Tooltip>
        </Fragment>
    );
}
