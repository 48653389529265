import { Box, Paper, Typography, Grid, Divider, Button } from "@material-ui/core";
import { Fragment } from "react";
import { Link as RouterLink } from "react-router-dom";
import { BRCGS_CATEGORY_COLORS } from '../../../Constants/BrcgsCategoryColors';
import NoData from "../../NoData/NoData";
import CategoryLabel from "../../Translation/CategoryLabel";
import ElementLabel from "../../Translation/ElementLabel";


export default function BrcgsElements(props) {
    if (!props.reportData.data || !props.surveyData.type) {
        return null;
    }

    if (0 === props.reportData.data.length ) {
        return <NoData />;
    }

    const lastElement = props.reportData.data.length - 1;
    const partSize = Math.floor(props.reportData.data.length / 2);

    return (
        <Fragment>
            <div ref={props.setScreenshotRef}>
                <Box pb={2}>
                    <Paper elevation={1} style={{borderLeft: '5px solid '+BRCGS_CATEGORY_COLORS['people'].dark, backgroundColor: BRCGS_CATEGORY_COLORS['people'].light}}>
                        <Box p={1} px={2}>
                            <Typography variant="h6">Highest Elements</Typography>
                            {[...Array(partSize)].map((item, i) => (
                                <Fragment>
                                    <Divider />
                                    <Grid container direction="row" justifyContent="center" alignItems="center">
                                        <Grid item sm={2} align="left">
                                            <Box py={2} style={{fontSize: '1rem'}}>
                                                <CategoryLabel category={props.reportData.data[i].category} />
                                            </Box>
                                        </Grid>
                                        <Grid item sm={8} align="left" style={{fontSize: '1rem'}}>
                                            {props.reportData.data[i].shortName ? <ElementLabel element={props.reportData.data[i].shortName} /> : props.reportData.data[i].title}
                                        </Grid>
                                        <Grid item sm={2} align="right" style={{fontSize: '1rem'}}>
                                            {props.reportData.data[i].roundedScore}
                                        </Grid>
                                    </Grid>
                                </Fragment>
                            ))}
                        </Box>
                    </Paper>
                </Box>
                <Box pb={2}>
                    <Paper elevation={1} style={{borderLeft: '5px solid '+BRCGS_CATEGORY_COLORS['process'].dark, backgroundColor: BRCGS_CATEGORY_COLORS['process'].light}}>
                        <Box p={1} px={2}>
                            <Typography variant="h6">Lowest Elements</Typography>
                            {[...Array(partSize)].map((item, i) => (
                                <Fragment>
                                    <Divider />
                                    <Grid container direction="row" justifyContent="center" alignItems="center">
                                        <Grid item sm={2} align="left">
                                            <Box py={2} style={{fontSize: '1rem'}}>
                                                <CategoryLabel category={props.reportData.data[lastElement-i].category} />
                                            </Box>
                                        </Grid>
                                        <Grid item sm={8} align="left" style={{fontSize: '1rem'}}>
                                            {props.reportData.data[lastElement-i].shortName ? <ElementLabel element={props.reportData.data[lastElement-i].shortName} /> : props.reportData.data[lastElement-i].title}
                                        </Grid>
                                        <Grid item sm={2} align="right" style={{fontSize: '1rem'}}>
                                            {props.reportData.data[lastElement-i].roundedScore}
                                        </Grid>
                                    </Grid>
                                </Fragment>
                            ))}
                        </Box>
                    </Paper>
                </Box>
            </div>

            <Grid container justifyContent="center">
                <Grid item sm={5} xs={10}>
                    <Paper variant="outlined" style={{borderColor: BRCGS_CATEGORY_COLORS['people'].dark, backgroundColor: BRCGS_CATEGORY_COLORS['people'].light}}>
                        <Box p={1} px={2}>
                            <Typography variant="subtitle1" align="center">
                                The highest and lowest Element scores shown above enable detailed action planning and can be used in conjunction with the Action Planning template (<a href="https://resources.culturexcellence.com/BRCGS_FSCE_Action_Plan_Template.docx" target="_blank" rel="noreferrer">click here to download</a>).
                                Scoring for 76 additional Elements is accessible via upgrade.
                            </Typography>
                            <Box p={1} textAlign='center'>
                                <Button component={RouterLink} variant="contained" color="secondary" to={'/survey-report/'+props.surveyData.attributes.combinedHash+'/upgrade?type=overview'}>
                                    Click here to learn more about upgrade
                                </Button>
                            </Box>
                        </Box>
                    </Paper>
                </Grid>
            </Grid>
        </Fragment>
    );
}
