import { defineMessages } from "react-intl";

export const riskTranslated = (risk, intl) => {
    const messages = defineMessages({
        "allergens": {
            id: "generic.risk.allergens",
            defaultMessage: "Allergens"
        },
        "chemical_hazards": {
            id: "generic.risk.chemicalHazards",
            defaultMessage: "Chemical Hazards"
        },
        "microbiological_/_biological_hazards": {
            id: "generic.risk.microBioHazards",
            defaultMessage: "Microbiological / Biological hazards"
        },
        "physical_hazards": {
            id: "generic.risk.physicalHazards",
            defaultMessage: "Physical hazards"
        },
    });
    const alias = risk ? risk.replace(/ /g, '_') : null;

    if (risk && alias.toLowerCase() in messages) {
        return intl.formatMessage(messages[alias.toLowerCase()]);
    } else {
        return risk;
    }
}
