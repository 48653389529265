export const CATEGORIES_AND_DIMENSIONS = {
    people: [
        'empowerment',
        'reinforcement',
        'teamwork',
        'training',
        'communication'
    ],
    process: [
        'control',
        'coordination',
        'consistency',
        'systems',
        'premises',
    ],
    purpose: [
        'vision',
        'values',
        'strategy',
        'objectives',
        'metrics',
    ],
    proactivity: [
        'awareness',
        'foresight',
        'change',
        'learning',
        'investment',
    ]
};
