import { Box, Divider, Grid, List, ListItem, ListItemIcon, ListItemText, makeStyles, Tab, Tabs, Typography } from "@material-ui/core";
import { Fragment, useState } from "react";
import PdfIcon from '@material-ui/icons/PictureAsPdf';
import DocumentIcon from '@material-ui/icons/Description';

const useStyles = makeStyles(theme => ({
    indicator: {
        backgroundColor: theme.palette.primary.light,
    },
    tabs: {
        backgroundColor: theme.palette.secondary.main,
        color: 'white'
    }
}));

export default function BrcgsAdminResources(props) {
    const classes = useStyles();
    const [activeTab, setActiveTab] = useState(0);

    const documents = [
        {
            url: 'https://resources.culturexcellence.com/BRCGS_FSCE_Site_Application_Form.docx',
            icon: <DocumentIcon />,
            name: 'CE-001: BRCGS FSCE Site Application Form'
        },
        {
            url: 'https://resources.culturexcellence.com/BRCGS_FSCE_Implementation_Guide.pdf',
            icon: <PdfIcon />,
            name: 'CE-002: BRCGS FSCE Site Implementation Guide'
        },
        {
            url: 'https://resources.culturexcellence.com/BRCGS_FSCE_Administration_Guide.pdf',
            icon: <PdfIcon />,
            name: 'CE-008: BRCGS FSCE Administration Guide'
        },
        {
            url: 'https://resources.culturexcellence.com/BRCGS_FSCE_Action_Plan_Template.docx',
            icon: <DocumentIcon />,
            name: 'Action Planning Template'
        },
    ];

    const tabContent = (tab) => {
        if (0 === tab) {
            return (<Box py={2}>
                <Typography variant="h6">Documents</Typography>
                <List>
                    {documents.map(doc => (
                        <Fragment key={doc.name}>
                            <ListItem>
                                <ListItemIcon>
                                    {doc.icon }
                                </ListItemIcon>
                                <ListItemText primary={<a style={{color: '#000000'}} href={doc.url} rel="noreferrer" target="_blank">{doc.name}</a>} />
                            </ListItem>
                            <Divider />
                        </Fragment>
                    ))}
                </List>
            </Box>);
        } else if (1 === tab) {
            return (<Box py={2}>
                <Typography variant="h6">Demo Platform Account</Typography>
                <Typography variant="body1">A demo account is available for the online reporting platform. Please use the following credentials:</Typography>
                <ul>
                    <li><Typography variant="body1">Login URL: https://app.culturexcellence.com</Typography></li>
                    <li><Typography variant="body1">User email: demo+brcgs@culturexcellence.com</Typography></li>
                    <li><Typography variant="body1">Password: BRCGSDemo2023</Typography></li>
                </ul>
            </Box>);
        }
    };

    return (
        <Fragment>
            <Tabs value={activeTab} onChange={(event, newValue) => setActiveTab(newValue)} variant="fullWidth" classes={{ root: classes.tabs, indicator: classes.indicator }}>
                <Tab label="Documents" />
                <Tab label="Demo Platform Account" />
            </Tabs>
            <Grid container direction="row" justifyContent="flex-start">
                <Grid item sm={12}>
                    {tabContent(activeTab)}
                </Grid>
            </Grid>
        </Fragment>
    );
}