import { Table, TableBody, TableHead, TableCell, TableContainer, TableRow, FormGroup, FormControlLabel, Checkbox, Box, makeStyles, Grid } from "@material-ui/core";
import { useEffect, useState } from "react";
import { BRCGS_CHART_COLORS } from "../../../Constants/BrcgsChartColors";
import PriorityLabel from "../../Translation/PriorityLabel";
import Decimal from 'decimal.js';
import { FormattedMessage } from "react-intl";
import DemographicOptionLabel from "../../Translation/DemographicOptionLabel";

const useStyles = makeStyles((theme) => ({
    label: {
        marginLeft: 0
    },
}));

export default function PriorityHotspots(props) {
    const classes = useStyles();
    const [types, setTypes] = useState({
        personal: true,
        company: true,
    });

    const [areas, setAreas] = useState({});

    const toggleType = (type) => {
        setTypes(prevState => ({
            ...prevState,
            [type]: !prevState[type]
        }));
    };

    useEffect(() => {
        if (props.reportData.data.areas) {
            setAreas(prevAreas => ({...Object.fromEntries(props.reportData.data.areas.map(a => [a, true])), ...prevAreas}));
        }
    }, [props.reportData]);

    const toggleArea = (area) => {
        setAreas(prevState => ({
            ...prevState,
            [area]: !prevState[area]
        }));
    };

    if (!props.reportData.data || 0 === Object.keys(props.reportData.data).length || !props.surveyData || 0 === Object.keys(props.surveyData.attributes).length) {
        return null;
    }

    const prioColors = {
        personal: {
            body: props.surveyData.attributes.type === 'brcgs' ? BRCGS_CHART_COLORS[0].hoverBackgroundColor : '#fef6ce',
            border: props.surveyData.attributes.type === 'brcgs' ? BRCGS_CHART_COLORS[0].hoverBackgroundColor : '#ffd600',
        },
        company: {
            body: props.surveyData.attributes.type === 'brcgs' ? BRCGS_CHART_COLORS[1].hoverBackgroundColor : '#f6bbbc',
            border: props.surveyData.attributes.type === 'brcgs' ? BRCGS_CHART_COLORS[1].hoverBackgroundColor : '#e21f4d',
        },
    };

    const demCell = (dems) => {
        const label = [];
        props.demographicQuestions.forEach((demQ, i) => {
            if (demQ.attributes.demographic in dems) {
                demQ.attributes.options.forEach((option) => {
                    if (dems[demQ.attributes.demographic] === option.attributes.value) {
                        label.push(<DemographicOptionLabel key={i} option={option.attributes.title} translations={option.attributes.translations} demographic={demQ.attributes.demographic} demographicOptionLabels={props.demographicOptionLabels} />)
                        label.push(<strong key={'sep'+i}> | </strong>);
                    }
                })
            }
        });

        // Remove last separator
        label.pop();
        return label;
    };

    const tableRows = (entries) => {
        const rows = [];
        entries.forEach((area, index) => {
            if (!types[area.type] || !areas[area.title]) {
                return null;
            } else {
                rows.push(
                    <TableRow key={index} style={{borderBottom: '10px solid white'}}>
                        <TableCell style={{backgroundColor: prioColors[area.type].body, borderColor: prioColors[area.type].border}}>
                            {demCell(area.demographics)}
                        </TableCell>
                        <TableCell style={{backgroundColor: prioColors[area.type].body, borderColor: prioColors[area.type].border}}>
                            <PriorityLabel title={area.title} />
                        </TableCell>
                        <TableCell style={{backgroundColor: prioColors[area.type].body, borderColor: prioColors[area.type].border}} align="center">
                            <span title={area.score}>{area.score !== null ? Decimal(area.score).toFixed(1) : '-'}</span>
                        </TableCell>
                    </TableRow>
                );
            }
        });
        return rows.slice(0, 50);
    };

    const top = props.reportData.data.all.filter(p => areas[p.title] && types[p.type]).slice(0,50);
    const bottom = props.reportData.data.all.slice().reverse().filter(p => areas[p.title] && types[p.type]).slice(0,50);

    return (
        <Box pt={2}>
            <div ref={props.setScreenshotRef}>
                <FormGroup row style={{justifyContent: 'center'}}>
                    {props.reportData.data.areas.map((area) => {
                        if (area in areas) {
                            return (<FormControlLabel
                                key={area}
                                className={classes.label}
                                label={<PriorityLabel title={area} />}
                                style={{backgroundColor: 'rgba(0, 0, 0, 0.05)', borderLeft: '5px solid #ccc', paddingRight: '10px'}}
                                control={
                                    <Checkbox
                                        color="primary"
                                        style={{ padding: '5px' }}
                                        checked={areas[area]}
                                        onChange={() => toggleArea(area)}
                                    />
                                }
                            />);
                        } else {
                            return null;
                        }
                    })}
                </FormGroup>
                <FormGroup row style={{justifyContent: 'center', paddingTop: 15}}>
                    <FormControlLabel
                        className={classes.label}
                        label={<FormattedMessage id="priorityHotspotsReport.personal" defaultMessage={"Personal"} />}
                        style={{backgroundColor: prioColors['personal'].body, borderLeft: '5px solid '+prioColors['personal'].border, paddingRight: '10px'}}
                        control={
                            <Checkbox
                                color="primary"
                                style={{ padding: '5px' }}
                                checked={types.personal}
                                onChange={() => toggleType('personal')}
                            />
                        }
                    />
                    <FormControlLabel
                        className={classes.label}
                        label={<FormattedMessage id="priorityHotspotsReport.company" defaultMessage={"Company"} />}
                        style={{backgroundColor: prioColors['company'].body, borderLeft: '5px solid '+prioColors['company'].border, paddingRight: '10px'}}
                        control={
                            <Checkbox
                                color="primary"
                                style={{ padding: '5px' }}
                                checked={types.company}
                                onChange={() => toggleType('company')}
                            />
                        }
                    />
                </FormGroup>
                <Grid container justifyContent="center" style={{overflowY: 'hidden'}}>
                    <Grid item lg={12} xl={6}>
                        <Box p={1}>
                            <TableContainer>
                                <Table size="small">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>
                                                <FormattedMessage id="priorityHotspotsReport.highestDemographicCombinations" defaultMessage={"Highest Demographic Combinations"} />
                                            </TableCell>
                                            <TableCell>
                                                <FormattedMessage id="priorityHotspotsReport.area" defaultMessage={"Area"} />
                                            </TableCell>
                                            <TableCell align="center">
                                                <FormattedMessage id="generic.score" defaultMessage={"Score"} />
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {tableRows(top)}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                    </Grid>
                    <Grid item lg={12} xl={6}>
                        <Box p={1}>
                            <TableContainer>
                                <Table size="small">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>
                                                <FormattedMessage id="priorityHotspotsReport.lowestDemographicCombinations" defaultMessage={"Lowest Demographic Combinations"} />
                                            </TableCell>
                                            <TableCell>
                                                <FormattedMessage id="priorityHotspotsReport.area" defaultMessage={"Area"} />
                                            </TableCell>
                                            <TableCell align="center">
                                                <FormattedMessage id="generic.score" defaultMessage={"Score"} />
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {tableRows(bottom)}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                    </Grid>
                </Grid>
            </div>
        </Box>
    );
}
