import { useIntl } from "react-intl";
import { typeTranslated } from "../../Translation/Type";
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import ZoomOutIcon from '@material-ui/icons/ZoomOut';
import CameraAltIcon from '@material-ui/icons/CameraAlt';
import RadarLazy from "../../Chart/RadarLazy";
import { categoryTranslated } from "../../Translation/Category";
import { RGBAToHexA } from "../../../Utilities/Color";
import { Box, Grid, IconButton, Tooltip, makeStyles } from "@material-ui/core";
import { useRef, useState } from "react";
import { screenshot } from "../../../Utilities/Screenshot";

const useStyles = makeStyles((theme) => ({
    screenshot: {
        [theme.breakpoints.down('lg')]: {
            paddingRight: 40
        },
    }
}));

export default function CultureType(props) {
    const intl = useIntl();
    const [zoomed, setZoomed] = useState(true);
    const classes = useStyles();
    const screenshotRef = useRef();

    if (!props.reportData.data) {
        return null;
    }

    const colors = {
        'general': 'rgb(56,93,168)',
        'foodsafety': 'rgb(191,12,107)',
        'quality': 'rgb(214,80,51)',
        'hs': 'rgb(214,210,25)',
        'environment': 'rgb(92,186,152)',
        'productsafety': 'rgb(100,192,190)',
        'dei': 'rgb(147,51,138)'
    };

    const chartData = {
        labels: [
            intl.formatMessage({id: 'generic.total', defaultMessage: 'Total'}),
            categoryTranslated('people', intl),
            categoryTranslated('process', intl),
            categoryTranslated('purpose', intl),
            categoryTranslated('proactivity', intl),
        ],
        datasets: [],
    };

    let min = 100;
    let max = 0;
    Object.keys(props.reportData.data).forEach(questionType => {
        const color = (questionType in colors) ? colors[questionType] : 'rgb(100,100,100)';

        const scores = props.reportData.data[questionType];
        [
            scores.total.roundedScore,
            scores.categories.people.roundedScore,
            scores.categories.process.roundedScore,
            scores.categories.purpose.roundedScore,
            scores.categories.proactivity.roundedScore,
        ].forEach((s) => {
            if (s > max) {
                max = s;
            }
            if (s < min) {
                min = s;
            }
        });

        chartData.datasets.push({
            label: typeTranslated(questionType, intl),
            data: [
                props.reportData.data[questionType].total.roundedScore,
                props.reportData.data[questionType].categories.people.roundedScore,
                props.reportData.data[questionType].categories.process.roundedScore,
                props.reportData.data[questionType].categories.purpose.roundedScore,
                props.reportData.data[questionType].categories.proactivity.roundedScore,
            ],
            backgroundColor: RGBAToHexA(color).substring(0,7)+'0D',
            borderColor: RGBAToHexA(color).substring(0,7)+'CC',
            hoverBackgroundColor: RGBAToHexA(color).substring(0,7)+'0D',
            hoverBorderColor: RGBAToHexA(color).substring(0,7)+'CC',
        });
    });

    const chartOptions = {
        animation: {
            duration: 0,
        },
        responsive: true,
        maintainAspectRatio: false,
        scale: {
            min: zoomed ? Math.floor((min-1) / 10) * 10 : 20,
            max: zoomed ? Math.ceil((max+1) / 10) * 10 : 100,
        },
    };

    return (
        <Box p={0}>
            <Grid container justifyContent="space-between" alignItems="center" direction="row">
                <Grid item>
                    <Tooltip title="Zoom">
                        <IconButton
                            size="small"
                            color="inherit"
                            onClick={() => {setZoomed(!zoomed)}}
                        >
                            {zoomed ? <ZoomOutIcon /> : <ZoomInIcon />}
                        </IconButton>
                    </Tooltip>
                </Grid>
                <Grid item>
                    <Tooltip title="Screenshot">
                        <IconButton
                            className={classes.screenshot}
                            size="small"
                            color="inherit"
                            target="_blank"
                            rel="noreferrer"
                            onClick={() => {screenshot(screenshotRef, 'screenshot.png')}}
                        >
                            <CameraAltIcon />
                        </IconButton>
                    </Tooltip>
                </Grid>
            </Grid>

            <div ref={screenshotRef} style={{height: '90vh', position: 'relative'}}>
                <RadarLazy data={chartData} options={chartOptions} dataLabels={false} />
            </div>
        </Box>
    );
}