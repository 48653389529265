import { useContext, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { NotificationContext } from "../../Context/NotificationContext";
import { AppBarTitleContext } from "../../Context/AppBarTitleContext";
import { SpinnerContext } from "../../Context/SpinnerContext";
import SurveyService from "../../Services/SurveyService";
import { Box, Button, Grid, TextField, Typography } from "@material-ui/core";
import SurveyTitleService from "../../Services/SurveyTitleService";

export default function SurveyTitle() {
    let { hash } = useParams();
    const [refresh, setRefresh] = useState(0);
    const [survey, setSurvey] = useState(null);
    const [surveyTitle, setSurveyTitle] = useState(null);

    const { updateNotification } = useContext(NotificationContext);
    const updateNotificationRef = useRef();
    updateNotificationRef.current = (open, message, level) => {
        updateNotification(open, message, level);
    }

    const { updateTitle } = useContext(AppBarTitleContext);
    const updateTitleRef = useRef();
    updateTitleRef.current = (title) => {
        updateTitle(title);
    };

    const { updateLoading } = useContext(SpinnerContext);
    const updateLoadingRef = useRef();
    updateLoadingRef.current = (loading) => {
        updateLoading(loading);
    };

    useEffect(() => {
        updateTitleRef.current(null);
        updateLoadingRef.current(true);

        SurveyService.surveyByHash(hash, null, ['survey-title']).then(response => {
            setSurvey(response.data.data);

            if (response.data.included && response.data.included.length === 1 && response.data.included[0]['type'] === 'survey-title') {
                setSurveyTitle(response.data.included[0]);
            } else {
                setSurveyTitle({type: 'survey-title', id: null, attributes:{surveyCombinedHash: response.data.data.attributes.combinedHash, title: ''}});
            }

        }).catch((error) => {
            console.error(error);
            updateNotificationRef.current(true, 'An unknown error occurred!', 'error');
        }).then(() => {
            updateLoadingRef.current(false);
        });
    }, [hash, refresh]);

    const handleChange = () => event => {
        setSurveyTitle({...surveyTitle, attributes: {...surveyTitle.attributes, title: event.target.value}});
    };

    const handleSave = () => {
        let request = null;
        if (null === surveyTitle.id && surveyTitle.attributes.title.trim()) {
            // Create
            request = SurveyTitleService.create(surveyTitle.attributes);
        } else if (null !== surveyTitle.id && surveyTitle.attributes.title.trim()){
            // Update
            request = SurveyTitleService.patch(surveyTitle.id, {surveyCombinedHash: surveyTitle.attributes.surveyCombinedHash, title: surveyTitle.attributes.title});
        } else if (null !== surveyTitle.id) {
            // Delete
            request = SurveyTitleService.delete(surveyTitle.id);
        }

        if (request) {
            updateLoadingRef.current(true);
            request.then(response => {
                setRefresh(prev => prev+1);
            }).catch((error) => {
                console.error(error);
                updateNotificationRef.current(true, 'An unknown error occurred!', 'error');
            }).then(() => {
                updateLoadingRef.current(false);
            });
        }
    };

    return (
        <Grid container spacing={0}>
            <Grid item xs={12} md={6}>
                <Typography variant="h5">
                    {survey && survey.attributes ? survey.attributes.title + ' | Title Override' : null}
                </Typography>

                <TextField
                    fullWidth
                    value={surveyTitle ? surveyTitle.attributes.title : ''}
                    onChange={handleChange()}
                    label="Title" />
            </Grid>
            <Grid item xs={12}>
                <Box py={2}>
                <Button onClick={handleSave} variant="contained" color="secondary" disabled={surveyTitle === null}>Save</Button>
                </Box>
            </Grid>
        </Grid>
    );
}