import axios from 'axios';
import BaseService from './BaseService';

class CrashService extends BaseService {
    // Use https://www.npmjs.com/package/source-map-cli to analyse stack traces
    log(message, stack, compStack) {
        return axios.post(this.baseUrl+'/health/crash', {
            data: {
                message: message,
                stack: stack,
                componentStack: compStack
            }
        });
    }
}

export default new CrashService();
