import { Table, TableBody, TableHead, TableCell, TableContainer, TableRow, Grid, Paper, Box, Typography, Button } from "@material-ui/core";
import { BRCGS_CATEGORY_COLORS } from '../../../Constants/BrcgsCategoryColors';
import CategoryLabel from "../../Translation/CategoryLabel";
import CompareArrow from "./Common/CompareArrow";
import ElementLabel from "../../Translation/ElementLabel";
import { Link as RouterLink } from "react-router-dom";
import Decimal from 'decimal.js';
import { FormattedMessage } from "react-intl";
import { Fragment } from "react";

export default function BenchmarkElementsIndustryBrcgs(props) {
    const sortOrder = 'desc';
    const sortBy = 'score';

    if (!props.reportData.data || 0 === Object.keys(props.reportData.data).length) {
        return null;
    }

    const sortedData = () => {
        const dataCopy = [];

        //Find old score, if available
        props.reportData.data.current.forEach((element, index) => {

            element['industry'] = null;
            if (props.reportData.data.industry) {
                for (let i = 0; i < props.reportData.data.industry.length; i++) {
                    if (props.reportData.data.industry[i]['shortName'] === element['shortName']) {
                        element['industry'] = props.reportData.data.industry[i]['score'];
                        break;
                    }
                }
            }

            // Might be a mapped question, search element mappings
            if (null === element['industry'] &&
                props.reportData.data.elementMappings &&
                props.reportData.data.elementMappings.length
            ) {
                const findMappedIndustryScore = (element) => {
                    for (let i = 0; i < props.reportData.data.elementMappings.length; i++) {
                        if (-1 !== props.reportData.data.elementMappings[i].indexOf(element['shortName'])) {

                            for (let j = 0; j < props.reportData.data.elementMappings[i].length; j++) {
                                const currentElement = props.reportData.data.elementMappings[i][j];
                                for (let k = 0; k < props.reportData.data.industry.length; k++) {
                                    if (props.reportData.data.industry[k]['shortName'] === currentElement) {
                                        return props.reportData.data.industry[k]['score'];
                                    }
                                }
                            }
                        }
                    }
                    return null;
                };

                element['industry'] = findMappedIndustryScore(element);
            }

            if (null !== element['industry']) {
                element['change'] = scoreChange(element['score'], element['industry']);
            } else {
                element['change'] = null;
            }

            dataCopy.push(element);
        });

        dataCopy.sort((a, b) => {
            return (a[sortBy] === null ? Number.MIN_SAFE_INTEGER : parseFloat(a[sortBy])) > (b[sortBy] === null ? Number.MIN_SAFE_INTEGER : parseFloat(b[sortBy])) ? 1 : -1
        });
        if ('desc' === sortOrder) {
            dataCopy.reverse();
        }
        return dataCopy;
    }

    const scoreChange = (current, industry, precision = 1) => {
        if (!current || !industry) {
            return null;
        }
        return (Decimal(current).minus(Decimal(industry))).toFixed(precision);
    };

    return (
        <Fragment>
            <TableContainer ref={props.setScreenshotRef}>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <FormattedMessage id="generic.category" defaultMessage={"Category"} />
                            </TableCell>
                            <TableCell>
                                <FormattedMessage id="generic.element" defaultMessage={"Element"} />
                            </TableCell>
                            <TableCell align="center">
                                <FormattedMessage id="generic.scorePercent" defaultMessage={"Score (%)"}/>
                            </TableCell>
                            <TableCell align="center">
                                <FormattedMessage id="generic.industryScorePercent" defaultMessage={"Industry Score (%)"}/>
                            </TableCell>
                            <TableCell align="center">
                                <FormattedMessage id="generic.change" defaultMessage={"Change"}/>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {sortedData().map((entity, index) => {
                            const bgColor = index <= 2 ? BRCGS_CATEGORY_COLORS['people'].light : BRCGS_CATEGORY_COLORS['process'].light;
                            const borderColor = index <= 2 ? BRCGS_CATEGORY_COLORS['people'].dark : BRCGS_CATEGORY_COLORS['process'].dark;
                            return (
                                <TableRow key={index} style={{backgroundColor: bgColor, borderBottom: '10px solid white'}}>
                                    <TableCell style={{borderLeft: '5px solid '+borderColor}}>
                                        <CategoryLabel category={entity.category} />
                                    </TableCell>
                                    <TableCell>
                                        {entity.shortName ? <ElementLabel element={entity.shortName} /> : entity.title}
                                    </TableCell>
                                    <TableCell align="center"><span title={entity.score}>{Decimal(entity.score).toFixed(1)}</span></TableCell>
                                    <TableCell align="center">
                                        {entity.industry != null ?
                                            <span title={entity.industry}>
                                                {Decimal(entity.industry).toFixed(1)}
                                            </span>
                                        : '-'}
                                    </TableCell>
                                    <TableCell align="center">
                                        {(entity.change ?
                                            <div style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center'
                                            }}>
                                                <span>{entity.change}</span>
                                                <CompareArrow change={Decimal(entity.change).toFixed(1)} />
                                            </div>
                                        : '-')}
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>

            <Grid container justifyContent="center">
                <Grid item sm={5} xs={10}>
                    <Paper variant="outlined" style={{borderColor: BRCGS_CATEGORY_COLORS['people'].dark, backgroundColor: BRCGS_CATEGORY_COLORS['people'].light, marginTop: 5}}>
                        <Box p={1} px={2}>
                            <Typography variant="subtitle1" align="center">
                                Benchmarking is available for 76 additional Elements via upgrade.
                            </Typography>
                            <Box p={1} textAlign='center'>
                                <Button component={RouterLink} variant="contained" color="secondary" to={'/survey-report/'+props.surveyData.attributes.combinedHash+'/upgrade?type=overview'}>
                                    Click here to learn more about upgrade
                                </Button>
                            </Box>
                        </Box>
                    </Paper>
                </Grid>
            </Grid>
        </Fragment>
    );
}
