import { Table, TableBody, TableHead, TableCell, TableContainer, TableRow, TableSortLabel, Box, Typography } from "@material-ui/core";
import { CATEGORY_COLORS } from '../../../Constants/CategoryColors';
import CategoryLabel from "../../Translation/CategoryLabel";
import CompareArrow from "./Common/CompareArrow";
import { useState } from "react";
import FilterListIcon from '@material-ui/icons/FilterList';
import ElementLabel from "../../Translation/ElementLabel";
import Decimal from 'decimal.js';

export default function BenchmarkElementsSsafe(props) {
    const [sortOrder, setSortOrder] = useState('desc');

    const toggleSort = () => {
        // Toggle direction
        setSortOrder(prevOrder => (prevOrder === 'asc' ? 'desc' : 'asc'));
    };

    if (!props.reportData.data || 0 === Object.keys(props.reportData.data).length) {
        return null;
    }

    const sortedData = () => {
        const dataCopy = [];

        //Find old score, if available
        props.reportData.data.current.forEach((element, index) => {

            element['ssafe'] = null;
            if (props.reportData.data.ssafe) {
                for (let i = 0; i<props.reportData.data.ssafe.length; i++) {
                    if (props.reportData.data.ssafe[i]['shortName'] === element['shortName']) {
                        element['ssafe'] = props.reportData.data.ssafe[i]['score'];
                        break;
                    }
                }
            }

            if (null !== element['ssafe']) {
                element['change'] = scoreChange(element['score'], element['ssafe']);
            } else {
                element['change'] = null;
            }

            dataCopy.push(element);
        });

        if ('asc' === sortOrder) {
            dataCopy.sort((a, b) => parseFloat(a.score) > parseFloat(b.score) ? 1 : -1);
        } else {
            dataCopy.sort((a, b) => parseFloat(a.score) > parseFloat(b.score) ? 1 : -1).reverse();
        }
        return dataCopy;
    }

    const scoreChange = (current, ssafe, precision = 1) => {
        if (!current || !ssafe) {
            return null;
        }
        return (Decimal(current).minus(Decimal(ssafe))).toFixed(precision);
    };

    return (
        <Box>
            <TableContainer>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>Category</TableCell>
                            <TableCell>Element</TableCell>
                            <TableCell align="center">
                                <TableSortLabel
                                    IconComponent={FilterListIcon}
                                    active={true}
                                    direction={sortOrder}
                                    onClick={() => {toggleSort()}}
                                >
                                    Score (%)
                                </TableSortLabel>
                            </TableCell>
                            <TableCell align="center">
                                SSAFE Score (%)
                            </TableCell>
                            <TableCell align="center">
                                Change
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {sortedData().map((entity, index) => {
                            return (
                                <TableRow key={index} style={{backgroundColor: CATEGORY_COLORS[entity.category].light, borderBottom: '10px solid white'}}>
                                    <TableCell style={{borderLeft: '5px solid '+CATEGORY_COLORS[entity.category].dark}}>
                                        <CategoryLabel category={entity.category} />
                                    </TableCell>
                                    <TableCell>
                                        {entity.shortName ? <ElementLabel element={entity.shortName} /> : entity.title}
                                    </TableCell>
                                    <TableCell align="center"><span title={entity.score}>{Decimal(entity.score).toFixed(1)}</span></TableCell>
                                    <TableCell align="center">
                                        {entity.ssafe != null ?
                                            <span title={entity.ssafe}>
                                                {Decimal(entity.ssafe).toFixed(1)}
                                            </span>
                                        : '-'}
                                    </TableCell>
                                    <TableCell align="center">
                                        {(entity.change ?
                                            <div style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center'
                                            }}>
                                                <span>{entity.change}</span>
                                                <CompareArrow change={Decimal(entity.change).toFixed(1)} />
                                            </div>
                                        : '-')}
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>

            <Typography variant="h6" style={{paddingTop: 24}}>
                Ranges
            </Typography>
            <TableContainer>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell />
                            <TableCell>Element</TableCell>
                            <TableCell>Min</TableCell>
                            <TableCell>Max</TableCell>
                            <TableCell>Range</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell>Broadest</TableCell>
                            <TableCell>{props.reportData.data.range.max.shortName}</TableCell>
                            <TableCell>{props.reportData.data.range.max.min}</TableCell>
                            <TableCell>{props.reportData.data.range.max.max}</TableCell>
                            <TableCell>{props.reportData.data.range.max.range}</TableCell>
                        </TableRow>
                    </TableBody>
                    <TableBody>
                        <TableRow>
                            <TableCell>Narrowest</TableCell>
                            <TableCell>{props.reportData.data.range.min.shortName}</TableCell>
                            <TableCell>{props.reportData.data.range.min.min}</TableCell>
                            <TableCell>{props.reportData.data.range.min.max}</TableCell>
                            <TableCell>{props.reportData.data.range.min.range}</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
}
