import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, Typography } from "@material-ui/core";
import { Fragment, useContext, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { NotificationContext } from "../../Context/NotificationContext";
import { SpinnerContext } from "../../Context/SpinnerContext";
import SurveyService from "../../Services/SurveyService";

export default function SurveyCloseSurveyClose(props) {
    const intl = useIntl();
    const query = new URLSearchParams(window.location.search);
    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
    const [message, setMessage] = useState(null);
    const [surveyData, setSurveyData] = useState(null);
    const { updateLoading, loading } = useContext(SpinnerContext);
    const { updateNotification } = useContext(NotificationContext);

    const closeSurvey = () => {
        updateLoading(true);
        setConfirmDialogOpen(false);
        SurveyService.surveyClose(query.get('hash'), query.get('token'))
        .then(function () {
            setMessage(
                <Fragment>
                    <Typography variant="h6">
                        <FormattedMessage id="surveyClose.successHeader" defaultMessage="Survey Closed" />
                    </Typography>
                    <Typography variant="body1" paragraph>
                        {intl.formatMessage({id: 'surveyClose.descriptionSuccess', defaultMessage: 'Your BRCGS Food Safety Culture Excellence survey has been closed. You will receive an email confirmation.'})}
                    </Typography>
                    <Typography variant="body1" paragraph>
                        <FormattedMessage id="surveyClose.closeWindow" defaultMessage="You may close this window." />
                    </Typography>
                </Fragment>
            );
        }).catch(function (error) {
            setMessage(<Fragment>
                <Typography variant="h6">
                    <FormattedMessage id="surveyClose.failedHeader" defaultMessage="Closing Survey Failed" />
                </Typography>
                <Typography variant="body1" paragraph>
                    {intl.formatMessage({id: 'surveyClose.failedMessage', defaultMessage: 'Survey could not be closed.'})}
                </Typography>
            </Fragment>);
            updateNotification(true, intl.formatMessage({id: 'surveyClose.failedMessage', defaultMessage: 'Survey could not be closed.'}), 'warning');
            console.error(error);
        }).then(function() {
            updateLoading(false);
        });
    };

    useEffect(() => {
        updateLoading(true);
        SurveyService.surveyCloseCheck(query.get('hash'), query.get('token'))
        .then(function (response) {
            setSurveyData(response.data.data);
        }).catch(function (error) {
            setMessage(<Fragment>
                <Typography variant="h6">
                    <FormattedMessage id="surveyClose.failedHeader" defaultMessage="Closing Survey Failed" />
                </Typography>
                <Typography variant="body1" paragraph>
                    {intl.formatMessage({id: 'surveyClose.failedMessage', defaultMessage: 'Survey could not be closed.'})}
                </Typography>
            </Fragment>);
            updateNotification(true, intl.formatMessage({id: 'surveyClose.failedMessage', defaultMessage: 'Survey could not be closed.'}), 'warning');
            console.error(error);
        }).then(function() {
            updateLoading(false);
        });
        // eslint-disable-next-line
    }, []);

    const closed = surveyData && surveyData.providerStatus && surveyData.providerStatus === 'closed';
    //const closed = true;
    return(
        <Grid container spacing={0} justifyContent="center">
            <Grid item sm={10} md={5} lg={4}>
                { message === null ?
                    <Typography variant="h6">
                        {closed ?
                            <FormattedMessage id="surveyClose.headerClosed" defaultMessage="Survey Closed" /> :
                            <FormattedMessage id="surveyClose.header" defaultMessage="Close Survey?" />
                        }
                    </Typography>
                : null}

                {message ?
                    message :
                    <Typography variant="body1" paragraph>
                            {closed ?
                                <FormattedMessage id="surveyClose.descriptionClosed" defaultMessage="The survey is already closed." /> :
                                <FormattedMessage id="surveyClose.description" defaultMessage="Click the button below to close this assessment:" />
                            }
                    </Typography>
                }

                {!closed && message === null ?
                    <Typography variant="body1" paragraph>
                        {message === null && surveyData && surveyData.title ? <em>{surveyData.title}</em> : <span style={{opacity: 0}}>.</span>}
                    </Typography>
                : null }
                {!closed && message === null ?
                    <Typography variant="body1" paragraph><FormattedMessage id="surveyClose.notice" defaultMessage="IMPORTANT: surveys cannot be reopened after closure." /></Typography>
                : null }

                {!closed && message === null ?
                    <Fragment>
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={() => {setConfirmDialogOpen(true);}}
                            disabled={loading || null === surveyData || closed}
                        >
                            <FormattedMessage id="surveyClose.closeSurvey" defaultMessage="Close Survey" />
                        </Button>
                    </Fragment>
                : null}
                <Dialog
                    open={confirmDialogOpen}
                    onClose={() => {setConfirmDialogOpen(false)}}
                >
                    <DialogTitle><FormattedMessage id="surveyClose.confirmTitle" defaultMessage="Important" /></DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            <FormattedMessage id="surveyClose.confirmQuestion" defaultMessage="Are you sure you want to close the assessment?" />
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" onClick={() => {setConfirmDialogOpen(false)}} color="primary">
                            <FormattedMessage id="generic.no" defaultMessage="No" />
                        </Button>
                        <Button variant="contained" onClick={closeSurvey} color="secondary" autoFocus>
                            <FormattedMessage id="surveyClose.yesCloseSurvey" defaultMessage="Yes - Close Survey" />
                        </Button>
                    </DialogActions>
                </Dialog>
            </Grid>
        </Grid>
    );
}
