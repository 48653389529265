import { Fragment } from "react";
import ElementsTable from "./ElementsTable";
import { FormattedMessage } from "react-intl";

export default function TrainingDimension(props) {
    return (
        <Fragment>
            <p>
                <FormattedMessage
                    id="execSummaryDiscAndRecs.training"
                    defaultMessage={
                        "Training is a core part of developing and maintaining safety and quality standards, and it is identified "+
                        "as a primary cultural embedding mechanism (e.g. Schein). However, making it effective, relevant, "+
                        "practical and enjoyable is an ongoing area for improvement in most companies. Training includes "+
                        "classroom training, on-the-job training, and refresher sessions, and has scores for both frequency and "+
                        "effectiveness (see table below)."
                    }
                />
            </p>
            <ElementsTable dimension={props.dimension} elements={props.elements} lowestElements={props.lowestElements} />
        </Fragment>
    );
}
