import { Table, TableBody, TableHead, TableCell, TableContainer, TableRow, Box, Grid, FormGroup, Checkbox, FormControlLabel, makeStyles } from "@material-ui/core";
import PriorityLabel from "../../Translation/PriorityLabel";
import Decimal from 'decimal.js';
import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import DemographicOptionLabel from "../../Translation/DemographicOptionLabel";

const useStyles = makeStyles((theme) => ({
    label: {
        marginLeft: 0
    },
}));

export default function PriorityConflictHotspots(props) {
    const classes = useStyles();
    const [areas, setAreas] = useState({});

    useEffect(() => {
        if (props.reportData.data.areas) {
            setAreas(prevAreas => ({...Object.fromEntries(props.reportData.data.areas.map(a => [a, true])), ...prevAreas}));
        }
    }, [props.reportData]);

    const toggleArea = (area) => {
        setAreas(prevState => ({
            ...prevState,
            [area]: !prevState[area]
        }));
    };

    if (!props.reportData.data) {
        return null;
    }

    const demCell = (dems) => {
        const label = [];
        props.demographicQuestions.forEach((demQ, i) => {
            if (demQ.attributes.demographic in dems) {
                demQ.attributes.options.forEach((option) => {
                    if (dems[demQ.attributes.demographic] === option.attributes.value) {
                        label.push(<DemographicOptionLabel key={i} option={option.attributes.title} translations={option.attributes.translations} demographic={demQ.attributes.demographic} demographicOptionLabels={props.demographicOptionLabels} />)
                        label.push(<strong key={'sep'+i}> | </strong>);
                    }
                })
            }
        });

        // Remove last separator
        label.pop();
        return label;
    };

    const tableRows = (entries) => {
        const rows = [];
        entries.forEach((area, index) => {
            if (!areas[area.title]) {
                return null;
            } else {
                rows.push(
                    <TableRow key={index} style={{borderBottom: '10px solid white', backgroundColor: (rows.length % 2 === 0 ? 'rgba(56,93,168,0.2)' : null)}}>
                        <TableCell>
                            {demCell(area.demographics)}
                        </TableCell>
                        <TableCell>
                            <PriorityLabel title={area.title} />
                        </TableCell>
                        <TableCell align="center"><span title={area.score}>
                            {area.score !== null ? Decimal(area.score).toFixed(1) : '-'}</span>
                        </TableCell>
                    </TableRow>
                );
            }
        });
        return rows;
    };

    const top = props.reportData.data.all.filter(p => areas[p.title]).slice(0,50);
    const bottom = props.reportData.data.all.slice().reverse().filter(p => areas[p.title]).slice(0,50);

    return (
        <Box pt={2}>
            <div ref={props.setScreenshotRef}>
                <FormGroup row style={{justifyContent: 'center'}}>
                    {props.reportData.data.areas.map((area) => {
                        if (area in areas) {
                            return (<FormControlLabel
                                key={area}
                                className={classes.label}
                                label={<PriorityLabel title={area} />}
                                style={{backgroundColor: 'rgba(0, 0, 0, 0.05)', borderLeft: '5px solid #ccc', paddingRight: '10px'}}
                                control={
                                    <Checkbox
                                        color="primary"
                                        style={{ padding: '5px' }}
                                        checked={areas[area]}
                                        onChange={() => toggleArea(area)}
                                    />
                                }
                            />);
                        } else {
                            return null;
                        }
                    })}
                </FormGroup>
                <Grid container justifyContent="center" style={{overflowY: 'hidden'}}>
                    <Grid item lg={12} xl={6}>
                        <Box p={1}>
                            <TableContainer>
                                <Table size="small">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>
                                                <FormattedMessage id="priorityConflictHotspotsReport.highestDemographicCombinations" defaultMessage={"Highest Demographic Combinations"} />
                                            </TableCell>
                                            <TableCell>
                                                <FormattedMessage id="priorityConflictHotspotsReport.area" defaultMessage={"Area"} />
                                            </TableCell>
                                            <TableCell align="center">
                                                <FormattedMessage id="priorityConflictHotspotsReport.conflict" defaultMessage={"Conflict"} />
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {tableRows(top)}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                    </Grid>
                    <Grid item lg={12} xl={6}>
                        <Box p={1}>
                            <TableContainer>
                                <Table size="small">
                                    <TableHead>
                                        <TableRow>
                                        <TableCell>
                                                <FormattedMessage id="priorityConflictHotspotsReport.lowestDemographicCombinations" defaultMessage={"Lowest Demographic Combinations"} />
                                            </TableCell>
                                            <TableCell>
                                                <FormattedMessage id="priorityConflictHotspotsReport.area" defaultMessage={"Area"} />
                                            </TableCell>
                                            <TableCell align="center">
                                                <FormattedMessage id="priorityConflictHotspotsReport.conflict" defaultMessage={"Conflict"} />
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {tableRows(bottom)}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                    </Grid>
                </Grid>
            </div>
        </Box>
    );
}
