import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Fab, FormControl, FormControlLabel, Grid, IconButton, InputLabel, Link, makeStyles, MenuItem, Select, Table, TableBody, TableCell, TableHead, TableRow, TextField, Tooltip } from "@material-ui/core";
import { NotificationContext } from "../../../Context/NotificationContext";
import { UserContext } from "../../../Context/UserContext";
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import BackIcon from '@material-ui/icons/NavigateBefore';
import { Fragment, useContext, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import SurveyService from '../../../Services/SurveyService';
import ScatterLazy from '../../Chart/ScatterLazy';

const useStyles = makeStyles((theme) => ({
    fab: {
        position: 'fixed',
        [theme.breakpoints.down('lg')]: {
            bottom: theme.spacing(1.5),
            right: theme.spacing(0.5),
        },
        [theme.breakpoints.up('xl')]: {
            bottom: theme.spacing(1.5),
            right: theme.spacing(40),
        }
    },
}));

export default function Correlation(props) {
    const classes = useStyles();
    const siteQuestion = props.demographicQuestions.find(q => q.attributes.demographic === 'site');
    const { updateNotification } = useContext(NotificationContext);
    const intl = useIntl();
    const [activeCorrelationId, setActiveCorrelationId] = useState(null);
    const [showLabels, setShowLabels] = useState(true);
    const [showDialog, setShowDialog] = useState(false);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [deleteId, setDeleteId] = useState(null);
    const [editId, setEditId] = useState(null);
    const [correlationTitle, setCorrelationTitle] = useState('');
    const [scaleType, setScaleType] = useState('0-20');
    const [scaleScores, setScaleScores] = useState({});
    const { user } = useContext(UserContext);

    //Score 1-100
    const hundredScores = [...Array(101).keys()];
    hundredScores.shift();
    //A-E scores
    const aeScores = { A: 5, B: 4, C: 3, D: 2, E: 1 };
    const scaleTypes = {
        '0-20': [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20],
        '1-3': [1, 2, 3],
        '1-4': [1, 2, 3, 4],
        '1-5': [1, 2, 3, 4, 5],
        '1-100': hundredScores,
        '1-10': [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
        'A-C': Object.keys(aeScores).slice(0, 3),
        'A-D': Object.keys(aeScores).slice(0, 4),
        'A-E': Object.keys(aeScores),
        'pass-fail': ['Pass', 'Fail'],
    };

    const convertAeScore = function(score) {
        if (score in aeScores) {
            return aeScores[score];
        } else {
            return 0;
        }
    };
    const revertAeScore = function(score) {
        const found = Object.keys(aeScores).find(key => aeScores[key] === score);
        if (found) {
            return found;
        } else {
            return '';
        }
    };

    const scaleFunctions = {
        '0-20': {
            convert: function(score) {
                return score;
            },
            revert: function(score) {
                return score;
            },
            stepSize: 1,
            min: 0,
            max: 20,
        },
        '1-3': {
            convert: function(score) {
                return score;
            },
            revert: function(score) {
                return score;
            },
            stepSize: 1,
            min: 1,
            max: 3,
        },
        '1-4': {
            convert: function(score) {
                return score;
            },
            revert: function(score) {
                return score;
            },
            stepSize: 1,
            min: 1,
            max: 4,
        },
        '1-5': {
            convert: function(score) {
                return score;
            },
            revert: function(score) {
                return score;
            },
            stepSize: 1,
            min: 1,
            max: 5,
        },
        '1-10': {
            convert: function(score) {
                return score;
            },
            revert: function(score) {
                return score;
            },
            stepSize: 1,
            min: 1,
            max: 10,
        },
        '1-100': {
            convert: function(score) {
                return score;
            },
            revert: function(score) {
                return score;
            },
            stepSize: 10,
            min: 0,
            max: 100,
        },
        'A-C': {
            convert: convertAeScore,
            revert: revertAeScore,
            stepSize: 1,
            min: 3,
            max: 5,
        },
        'A-D': {
            convert: convertAeScore,
            revert: revertAeScore,
            stepSize: 1,
            min: 2,
            max: 5,
        },
        'A-E': {
            convert: convertAeScore,
            revert: revertAeScore,
            stepSize: 1,
            min: 1,
            max: 5,
        },
        'pass-fail': {
            convert: function(score) {
                if ('Fail' === score) {
                    return 2;
                } else if ('Pass' === score) {
                    return 4;
                } else {
                    return 0;
                }
            },
            revert: function(score) {
                if (2 === score) {
                    return 'Fail';
                } else if (4 === score) {
                    return 'Pass';
                } else {
                    return '';
                }
            },
            stepSize: 1,
            min: 1,
            max: 5,
        },
    };

    const viewCorrelation = (correlation) => event => {
        event.preventDefault();
        setActiveCorrelationId(correlation.id);
    };

    const handleDelete = () => {
        props.loadingReport(true);
        setShowDeleteDialog(false);
        SurveyService.deleteSurveyCorrelation(deleteId)
        .then(function() {
            updateNotification(true, 'Deleted', 'success');
            props.fetchSurvey(props.surveyCombinedHash);
        }).catch(function (error) {
            updateNotification(true, intl.formatMessage({id: 'generic.unknownError', defaultMessage: 'An unknown error occurred!'}), 'error');
            console.log(error);
        }).then(() => {
            props.loadingReport(false);
        });


    };

    const handleSave = () => {
        props.loadingReport(true);
        setShowDialog(false);
        (editId ? SurveyService.patchSurveyCorrelation(editId, props.surveyCombinedHash, correlationTitle, scaleType, scaleScores) :
            SurveyService.createSurveyCorrelation(props.surveyCombinedHash, correlationTitle, scaleType, scaleScores)
        ).then(function () {
            updateNotification(true, editId ? <FormattedMessage id="generic.updated" defaultMessage="Updated" /> : <FormattedMessage id="generic.created" defaultMessage="Created" />, 'success');
            props.fetchSurvey(props.surveyCombinedHash);
        }).catch(function (error) {
            updateNotification(true, intl.formatMessage({id: 'generic.unknownError', defaultMessage: 'An unknown error occurred!'}), 'error');
            console.log(error);
        }).then(() => {
            props.loadingReport(false);
        });
    };

    const deleteDialog = () => {
        return (<Dialog open={showDeleteDialog} onClose={() => setShowDeleteDialog(false)} style={{paddingTop: 40}}>
            <DialogTitle>Delete</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    <span>
                        Are you sure you want to delete the correlation?<br /><br />
                        <strong>Note:</strong> All data entered for this correlation will be deleted!
                    </span>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={() => {setShowDeleteDialog(false)}} color="primary">
                    <FormattedMessage id="generic.cancel" defaultMessage="Cancel" />
                </Button>
                <Button variant="contained" onClick={handleDelete} color="secondary">
                    <FormattedMessage id="generic.cofirm" defaultMessage="Confirm" />
                </Button>
            </DialogActions>
        </Dialog>);
    };

    const dialog = () => {
        return (
            <Dialog open={showDialog} onClose={() => setShowDialog(false)} fullWidth maxWidth="md" style={{paddingTop: 40}}>
                <DialogTitle>{editId ? 'Edit Correlation' : 'Create Correlation'}</DialogTitle>
                <DialogContent style={{overflowX: 'hidden'}}>
                    <DialogContentText>
                        {editId ? 'Edit correlation scores below' : 'Create a new correlation by filling out the fields below'}
                    </DialogContentText>
                    <Grid container justifyContent="center" spacing={8}>
                        <Grid item xs={6}>
                            <TextField
                                value={correlationTitle}
                                fullWidth
                                type="text"
                                required
                                label="Title"
                                onChange={(e) => setCorrelationTitle(e.target.value)}
                                disabled={editId !== null}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <InputLabel>
                                    Scale type
                                </InputLabel>
                                <Select
                                    value={scaleType}
                                    onChange={(e) => {setScaleType(e.target.value)}}
                                    disabled={editId !== null}
                                >
                                    <MenuItem value={'0-20'}>0 - 20</MenuItem>
                                    <MenuItem value={'1-3'}>1 - 3</MenuItem>
                                    <MenuItem value={'1-4'}>1 - 4</MenuItem>
                                    <MenuItem value={'1-5'}>1 - 5</MenuItem>
                                    <MenuItem value={'1-100'}>1 - 100</MenuItem>
                                    <MenuItem value={'A-C'}>A - C</MenuItem>
                                    <MenuItem value={'A-D'}>A - D</MenuItem>
                                    <MenuItem value={'A-E'}>A - E</MenuItem>
                                    <MenuItem value={'pass-fail'}>Pass / Fail</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} style={{ paddingBottom: '20px' }} />
                        {siteQuestion.attributes.options.map((option, i) => (
                            <Grid key={i} item xs={12}>
                                <FormControl fullWidth>
                                    <InputLabel>{option.attributes.value}</InputLabel>
                                    <Select
                                        value={scaleScores[option.attributes.value] || 0 === scaleScores[option.attributes.value] ? scaleScores[option.attributes.value] : ''}
                                        onChange={(e) => {setScaleScores({...scaleScores, [option.attributes.value]: e.target.value})}}
                                    >
                                        <MenuItem value={''}>Select</MenuItem>
                                        {scaleTypes[scaleType].map((score, j) => (
                                            <MenuItem value={score} key={j}>
                                                {score.toString()}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>

                        ))}
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={() => {setShowDialog(false)}} color="primary">
                        <FormattedMessage id="generic.cancel" defaultMessage="Cancel" />
                    </Button>
                    <Button variant="contained" onClick={handleSave} color="secondary">
                        {editId ? <FormattedMessage id="generic.update" defaultMessage="Update" /> : <FormattedMessage id="generic.create" defaultMessage="Create" />}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    };

    const chart = () => {
        if (null === activeCorrelationId) {
            return null;
        }

        // Find the current correlation from props via. id
        const activeCorrelation = props.reportData.data.correlations.find(sc => sc.id === activeCorrelationId);

        const chartDataArr = {
            label: intl.formatMessage({id:'generic.sites', defaultMessage:'Sites'}),
            data: [],
        };

        Object.keys(activeCorrelation.attributes.siteScores).forEach(key => {
            if (key in props.reportData.data.sites) {
                chartDataArr.data.push({
                    xTooltipScore: activeCorrelation.attributes.siteScores[key],
                    xTooltipLabel: key,
                    x: scaleFunctions[activeCorrelation.attributes.scale]['convert'](activeCorrelation.attributes.siteScores[key]),
                    y: parseFloat(props.reportData.data.sites[key]['score']),
                    r: 7, //Radius of bubble
                    label: key,
                });
            }
        });

        const chartData = {
            labels: [intl.formatMessage({id:'generic.sites', defaultMessage:'Sites'})],
            datasets: [chartDataArr],
            xAxisLabel: activeCorrelation.attributes.title ? activeCorrelation.attributes.title : '',
            xAxisScaleType:  activeCorrelation.attributes.scale ?  activeCorrelation.attributes.scale : '1-100',
        };

        const chartOptions = {
            responsiveAnimationDuration: 0,
            animation: {
                duration: 0
            },
            scales: {
                x: {
                    title: {
                        display: true,
                        text: chartData.xAxisLabel
                    },
                    min: scaleFunctions[chartData.xAxisScaleType]['min'],
                    max: scaleFunctions[chartData.xAxisScaleType]['max'],
                    ticks: {
                        stepSize: scaleFunctions[chartData.xAxisScaleType]['stepSize'],
                        callback: value => {
                            return scaleFunctions[chartData.xAxisScaleType]['revert'](value);
                        },
                    },
                    grid: {
                        display: false
                    }
                },
                y: {
                    title: {
                        display: true,
                        text: 'Culture Excellence Score'
                    },
                    min: 20,
                    max: 100,
                    ticks: {
                        stepSize: 10,
                        callback: value => value,
                    },
                    grid: {
                        display: false
                    }
                },
            },
            plugins: {
                legend: {
                    display: false,
                },
                datalabels: {
                    anchor: 'end',
                    align: 'end',
                    clamp: true,
                    offset: 5,
                    formatter: (value) => {
                        return showLabels ? value.label : '';
                    }
                },
                tooltip: {
                    callbacks: {
                        label: (context) => {
                            return [
                                context.raw.label,
                                activeCorrelation.attributes.title+': '+scaleFunctions[activeCorrelation.attributes.scale]['revert'](context.raw.x),
                                'Culture Excellence Score: '+context.raw.y,
                            ];
                        }
                    },
                },
            },
            responsive: true,
            elements: {
                point: {
                    backgroundColor: '#3c3e40',
                },
            },
        };

        // Darken/lighten an RGB color, i.e. rgbLinearShade(0.10, 'rgb(10,20,30)') to make it lighter and rgbLinearShade(-0.10, 'rgb(10,20,30)') to make it darker
        const rgbLinearShade=(p, c0)=>{
            var i=parseInt,r=Math.round,[a,b,c,d]=c0.split(","),n=p<0,t=n?0:255*p,P=n?1+p:1-p;
            return"rgb"+(d?"a(":"(")+r(i(a[3]==="a"?a.slice(5):a.slice(4))*P+t)+","+r(i(b)*P+t)+","+r(i(c)*P+t)+(d?","+d:")");
        };

        const chartPlugins = [
            {
                beforeDraw: function(chart) {
                    let ctx = chart.ctx;
                    const chartAreaHeight = chart.chartArea.bottom - chart.chartArea.top;
                    const chartAreaWidth = chart.chartArea.right - chart.chartArea.left;
                    const red = 'rgb(239, 132, 142)';
                    const orange = 'rgb(248, 181, 154)';
                    const green = 'rgb(174, 224, 185)';

                    // Colors in a 10x10 grid starting from top right
                    const bgColors = [
                        [
                            rgbLinearShade(0.30, orange),
                            rgbLinearShade(0.40, orange),
                            rgbLinearShade(0.50, orange),
                            rgbLinearShade(0.60, orange),
                            rgbLinearShade(0.70, orange),
                            rgbLinearShade(0.40, green),
                            rgbLinearShade(0.30, green),
                            rgbLinearShade(0.20, green),
                            rgbLinearShade(0.10, green),
                            green,
                        ],
                        [
                            rgbLinearShade(0.20, orange),
                            rgbLinearShade(0.30, orange),
                            rgbLinearShade(0.40, orange),
                            rgbLinearShade(0.50, orange),
                            rgbLinearShade(0.60, orange),
                            rgbLinearShade(0.70, orange),
                            rgbLinearShade(0.40, green),
                            rgbLinearShade(0.30, green),
                            rgbLinearShade(0.20, green),
                            rgbLinearShade(0.10, green),
                        ],
                        [
                            rgbLinearShade(0.10, orange),
                            rgbLinearShade(0.20, orange),
                            rgbLinearShade(0.30, orange),
                            rgbLinearShade(0.40, orange),
                            rgbLinearShade(0.50, orange),
                            rgbLinearShade(0.60, orange),
                            rgbLinearShade(0.70, orange),
                            rgbLinearShade(0.40, green),
                            rgbLinearShade(0.30, green),
                            rgbLinearShade(0.20, green),
                        ],
                        [
                            orange,
                            rgbLinearShade(0.10, orange),
                            rgbLinearShade(0.20, orange),
                            rgbLinearShade(0.30, orange),
                            rgbLinearShade(0.40, orange),
                            rgbLinearShade(0.50, orange),
                            rgbLinearShade(0.60, orange),
                            rgbLinearShade(0.70, orange),
                            rgbLinearShade(0.40, green),
                            rgbLinearShade(0.30, green),
                        ],
                        [
                            rgbLinearShade(0.50, red),
                            orange,
                            rgbLinearShade(0.10, orange),
                            rgbLinearShade(0.20, orange),
                            rgbLinearShade(0.30, orange),
                            rgbLinearShade(0.40, orange),
                            rgbLinearShade(0.50, orange),
                            rgbLinearShade(0.60, orange),
                            rgbLinearShade(0.70, orange),
                            rgbLinearShade(0.40, green),
                        ],
                        [
                            rgbLinearShade(0.40, red),
                            rgbLinearShade(0.50, red),
                            orange,
                            rgbLinearShade(0.10, orange),
                            rgbLinearShade(0.20, orange),
                            rgbLinearShade(0.30, orange),
                            rgbLinearShade(0.40, orange),
                            rgbLinearShade(0.50, orange),
                            rgbLinearShade(0.60, orange),
                            rgbLinearShade(0.70, orange),
                        ],
                        [
                            rgbLinearShade(0.30, red),
                            rgbLinearShade(0.40, red),
                            rgbLinearShade(0.50, red),
                            orange,
                            rgbLinearShade(0.10, orange),
                            rgbLinearShade(0.20, orange),
                            rgbLinearShade(0.30, orange),
                            rgbLinearShade(0.40, orange),
                            rgbLinearShade(0.50, orange),
                            rgbLinearShade(0.60, orange),
                        ],
                        [
                            rgbLinearShade(0.20, red),
                            rgbLinearShade(0.30, red),
                            rgbLinearShade(0.40, red),
                            rgbLinearShade(0.50, red),
                            orange,
                            rgbLinearShade(0.10, orange),
                            rgbLinearShade(0.20, orange),
                            rgbLinearShade(0.30, orange),
                            rgbLinearShade(0.40, orange),
                            rgbLinearShade(0.50, orange),
                        ],
                        [
                            rgbLinearShade(0.10, red),
                            rgbLinearShade(0.20, red),
                            rgbLinearShade(0.30, red),
                            rgbLinearShade(0.40, red),
                            rgbLinearShade(0.50, red),
                            orange,
                            rgbLinearShade(0.10, orange),
                            rgbLinearShade(0.20, orange),
                            rgbLinearShade(0.30, orange),
                            rgbLinearShade(0.40, orange),

                        ],
                        [
                            red,
                            rgbLinearShade(0.10, red),
                            rgbLinearShade(0.20, red),
                            rgbLinearShade(0.30, red),
                            rgbLinearShade(0.40, red),
                            rgbLinearShade(0.50, red),
                            orange,
                            rgbLinearShade(0.10, orange),
                            rgbLinearShade(0.20, orange),
                            rgbLinearShade(0.30, orange),
                        ],
                    ];

                    bgColors.forEach((row, y) => {
                        row.forEach((color, x) => {
                            ctx.fillStyle = color;
                            ctx.fillRect(
                                chart.chartArea.left + (chartAreaWidth * x/10),
                                chart.chartArea.top + (chartAreaHeight * y/10),
                                chartAreaWidth/10,
                                chartAreaHeight/10,
                            );
                        });
                    });
                },
            },
        ];

        return (<ScatterLazy data={chartData} options={chartOptions} plugins={chartPlugins} dataLabels={true} />);
    };


    return (
        <div ref={props.setScreenshotRef}>
            {activeCorrelationId === null ?
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Title</TableCell>
                            <TableCell>Scale Type</TableCell>
                            <TableCell align="right">Sites</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.reportData.data.correlations.map((sc, i) => {
                            const siteCount = Object.keys(sc.attributes.siteScores).length;
                            return (
                                <TableRow key={i}>
                                    <TableCell>
                                        {siteCount !== 0 ?
                                            <Link href="#" style={{textDecoration: 'underline'}} onClick={viewCorrelation(sc)}>{sc.attributes.title}</Link>
                                        :
                                            <Tooltip title="No matching sites"><span>{sc.attributes.title}</span></Tooltip>
                                        }
                                    </TableCell>
                                    <TableCell>{sc.attributes.scale}</TableCell>
                                    <TableCell align="right">{siteCount}</TableCell>
                                    <TableCell align="right">
                                        <Fragment>
                                            <IconButton color="primary" onClick={() => {setScaleScores(sc.attributes.allSiteScores); setCorrelationTitle(sc.attributes.title); setScaleType(sc.attributes.scale); setEditId(sc.id); setShowDialog(true);}}>
                                                <EditIcon fontSize="small" />
                                            </IconButton>
                                            { user && user.role === 'admin' ?
                                                <IconButton color="secondary" onClick={() => {setShowDeleteDialog(true); setDeleteId(sc.id);}}>
                                                    <DeleteIcon fontSize="small" />
                                                </IconButton>
                                            : null }
                                        </Fragment>
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            :
                <Grid container justifyContent="center">
                    <Grid item xs={2}>
                        <Tooltip title="Back to correlation list">
                            <Fab
                                onClick={() => {setActiveCorrelationId(null);}}
                                size="small"
                                color="secondary"
                                style={{ marginBottom: '10px' }}
                            >
                                <BackIcon />
                            </Fab>
                        </Tooltip>
                    </Grid>
                    <Grid item align="center" xs={4}></Grid>
                    <Grid item align="center" xs={4}></Grid>
                    <Grid item xs={2} align="right">
                        <FormControlLabel
                            label="Show labels"
                            control={
                                <Checkbox
                                    color="primary"
                                    checked={showLabels}
                                    onChange={() => {setShowLabels(!showLabels);}}
                                />
                            }
                        />
                    </Grid>
                    <Grid item xs={12}>
                        {chart()}
                    </Grid>
                </Grid>
            }
            {dialog()}
            {deleteDialog()}
            {user && user.role === 'admin' ?
                <Fab className={classes.fab} color="secondary" onClick={() => {setScaleScores({}); setCorrelationTitle(''); setScaleType('0-20'); setEditId(null); setShowDialog(true); }}>
                    <AddIcon />
                </Fab>
            : null}
        </div>
    );
}