export const positiveTranslated = (positive, intl) => {
    if (!positive) {
        return null;
    }

    const labels = {
        'employee commitment': intl.formatMessage({id: 'positives.employeeCommitment', defaultMessage: 'Employee commitment'}),
        'technology': intl.formatMessage({id: 'positives.tech', defaultMessage: 'Technology'}),
        'accountability / responsibility': intl.formatMessage({id: 'positives.accountabilityResponsibility', defaultMessage: 'Accountability / responsibility'}),
        'training / communication': intl.formatMessage({id: 'positives.trainingCommunication', defaultMessage: 'Training / communication'}),
        'management commitment': intl.formatMessage({id: 'positives.mgmntCommitment', defaultMessage: 'Management commitment'}),
        'time': intl.formatMessage({id: 'positives.time', defaultMessage: 'Time'}),
        'physical space / premises': intl.formatMessage({id: 'positives.physical', defaultMessage: 'Physical space / premises'}),
        'staff retention': intl.formatMessage({id: 'positives.staffRetention', defaultMessage: 'Staff retention'}),
        'budget': intl.formatMessage({id: 'positives.budget', defaultMessage: 'Budget'}),
        'incentives / benefits': intl.formatMessage({id: 'positives.incentives', defaultMessage: 'Incentives / benefits'}),
        'multi-cultural workforce (different cultures)': intl.formatMessage({id: 'positives.multiCultural', defaultMessage: 'Multi-cultural workforce (different cultures)'}),
        'awareness of culture': intl.formatMessage({id: 'positives.awarenessOfCulture', defaultMessage: 'Awareness of culture'}),
        'co-ordination across departments / sites': intl.formatMessage({id: 'positives.coordinationAcross', defaultMessage: 'Co-ordination across departments / sites'}),
        'co-ordination across departments': intl.formatMessage({id: 'positives.coordinationAcrossDepartments', defaultMessage: 'Co-ordination across departments'}),
    };

    if (positive.toLowerCase() in labels) {
        return labels[positive.toLowerCase()];
    }

    return positive;
}