import { Box } from "@material-ui/core";
import { RGBAToHexA } from "../../../Utilities/Color";
import { chartColors } from "../../../Utilities/ChartColors";
import PolarAreaLazy from "../../Chart/PolarAreaLazy";
import { useIntl } from "react-intl";
import { environmentTranslated } from "../../Translation/Environment";

export default function EnvironmentComparison(props) {
    const intl = useIntl();
    if (!props.reportData.data ) {
        return null;
    }

    const chartData = {
        labels: [],
        datasets: [{
            label: 'Current',
            data: [],
            backgroundColor: [],
            borderColor: [],
            hoverBackgroundColor: [],
            hoverBorderColor: [],
        }],
    };

    props.reportData.data.current.forEach(r => {
        chartData.labels.push(environmentTranslated(r.shortName, intl));
        chartData.datasets[0].data.push(parseFloat(r.score));
        const currentAlpha = '66';
        const c = chartColors('environment', r.shortName);
        chartData.datasets[0].backgroundColor.push(RGBAToHexA(c['backgroundColor']).substring(0,7)+currentAlpha);
        chartData.datasets[0].borderColor.push(RGBAToHexA(c['borderColor']).substring(0,7)+currentAlpha);
        chartData.datasets[0].hoverBackgroundColor.push(c.hoverBackgroundColor);
        chartData.datasets[0].hoverBorderColor.push(c.hoverBorderColor);

        let prevColor = '#ccc';
        let prevHoverColor = '#ccc';
        const alpha = '33';
        if (c['backgroundColor'].startsWith('rgba(')) {
            prevColor = RGBAToHexA(c['backgroundColor']).substring(0,7)+alpha;
            prevHoverColor = RGBAToHexA(c['backgroundColor']).substring(0,7)+'1A';
        }
        chartData.datasets[0].backgroundColor.push(prevColor);
        chartData.datasets[0].borderColor.push(prevColor);
        chartData.datasets[0].hoverBackgroundColor.push(prevHoverColor);
        chartData.datasets[0].hoverBorderColor.push(prevHoverColor);

        chartData.labels.push(environmentTranslated(r.shortName, intl) + ' (' + intl.formatMessage({id: 'generic.comparison', defaultMessage: 'Comparison'}).toLowerCase()+')');
        // Data from previous (if present)
        if (props.reportData.data.previous) {
            const matchingPrevious = props.reportData.data.previous.find(opt => opt.shortName.toLowerCase() === r.shortName.toLowerCase())
            if (matchingPrevious) {
                chartData.datasets[0].data.push(parseFloat(matchingPrevious.score));
            } else {
                chartData.datasets[0].data.push(0);
            }
        } else {
            chartData.datasets[0].data.push(0);
        }
    });

    const chartOptions = {
        scales: {
            r: {
                min: 0,
                max: 5,
                ticks: {
                    stepSize: 1,
                    callback: (value) => {
                        switch (value) {
                            case 5:
                                return intl.formatMessage({id: 'environment.leader', defaultMessage: 'Leader'});
                            case 4:
                                return intl.formatMessage({id: 'environment.well', defaultMessage: 'Doing well'});
                            case 3:
                                return intl.formatMessage({id: 'environment.progress', defaultMessage: 'Making progress'});
                            case 2:
                                return intl.formatMessage({id: 'environment.atStart', defaultMessage: 'At start with goals'});
                            case 1:
                                return intl.formatMessage({id: 'environment.noGoals', defaultMessage: 'No goals'});
                            default:
                                return '';
                        }
                    }
                },
            },
        },
        animation: {
            duration: 0,
        },
        plugins: {
            legend: {
                display: true,
            },
            tooltip: {
                callbacks: {
                    label: (context) => {
                        const label = context.label || '';
                        return label ? environmentTranslated(label, intl) : '';
                    }
                },
            },
        },
    };

    return (
        <Box style={{overflow: 'auto', width: '50vw', margin: '0 auto'}}>
            <div ref={props.setScreenshotRef}>
                <PolarAreaLazy data={chartData} options={chartOptions} />
            </div>
        </Box>
    );
}