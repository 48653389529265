import { defineMessages, useIntl } from "react-intl";

export default function ElementLabel(props) {
    const intl = useIntl();

    const messages = defineMessages({
        "Agreement_with_long_term_plans_(S/M)": {
            id: "generic.element.Agreement_with_long_term_plans_(S/M)",
            defaultMessage: "Agreement with long term plans (S/M)"
        },
        "Being_corrected_by_managers_for_not_following_procedures": {
            id: "generic.element.Being_corrected_by_managers_for_not_following_procedures",
            defaultMessage: "Being corrected by managers for not following procedures"
        },
        "Being_corrected_by_managers_for_not_working_hygienically": {
            id: "generic.element.Being_corrected_by_managers_for_not_working_hygienically",
            defaultMessage: "Being corrected by managers for not working hygienically"
        },
        "Being_corrected_by_managers_for_not_working_safely": {
            id: "generic.element.Being_corrected_by_managers_for_not_working_safely",
            defaultMessage: "Being corrected by managers for not working safely"
        },
        "Being_encouraged_to_attend_external_events_(M)": {
            id: "generic.element.Being_encouraged_to_attend_external_events_(M)",
            defaultMessage: "Being encouraged to attend external events (M)"
        },
        "Being_encouraged_to_praise_employees_for_good_work_(M)": {
            id: "generic.element.Being_encouraged_to_praise_employees_for_good_work_(M)",
            defaultMessage: "Being encouraged to praise employees for good work (M)"
        },
        "Being_open_to_change": {
            id: "generic.element.Being_open_to_change",
            defaultMessage: "Being open to change"
        },
        "Being_praised_for_identifying_a_potential_food_safety_issue": {
            id: "generic.element.Being_praised_for_identifying_a_potential_food_safety_issue",
            defaultMessage: "Being praised for identifying a potential food safety issue"
        },
        "Being_quickly_reminded_for_a_lack_of_hygienic_practice": {
            id: "generic.element.Being_quickly_reminded_for_a_lack_of_hygienic_practice",
            defaultMessage: "Being quickly reminded for a lack of hygienic practice"
        },
        "Clear_definition_of_roles_and_responsibilities_(S/M)": {
            id: "generic.element.Clear_definition_of_roles_and_responsibilities_(S/M)",
            defaultMessage: "Clear definition of roles and responsibilities (S/M)"
        },
        "Clear_departmental_targets_are_provided": {
            id: "generic.element.Clear_departmental_targets_are_provided",
            defaultMessage: "Clear departmental targets are provided"
        },
        "Clear_departmental_targets_are_provided_(O/S)": {
            id: "generic.element.Clear_departmental_targets_are_provided_(O/S)",
            defaultMessage: "Clear departmental targets are provided (O/S)"
        },
        "Colleagues_challenge_inaccurate_behaviour": {
            id: "generic.element.Colleagues_challenge_inaccurate_behaviour",
            defaultMessage: "Colleagues challenge inaccurate behaviour"
        },
        "Colleagues_challenge_inaccurate_behaviour_(O/S)": {
            id: "generic.element.Colleagues_challenge_inaccurate_behaviour_(O/S)",
            defaultMessage: "Colleagues challenge inaccurate behaviour (O/S)"
        },
        "Colleagues_challenge_unhygienic_behaviour": {
            id: "generic.element.Colleagues_challenge_unhygienic_behaviour",
            defaultMessage: "Colleagues challenge unhygienic behaviour"
        },
        "Colleagues_challenge_unhygienic_behaviour_(O/S)": {
            id: "generic.element.Colleagues_challenge_unhygienic_behaviour_(O/S)",
            defaultMessage: "Colleagues challenge unhygienic behaviour (O/S)"
        },
        "Colleagues_challenge_unsafe_behaviour": {
            id: "generic.element.Colleagues_challenge_unsafe_behaviour",
            defaultMessage: "Colleagues challenge unsafe behaviour"
        },
        "Colleagues_challenge_unsafe_behaviour_(O/S)": {
            id: "generic.element.Colleagues_challenge_unsafe_behaviour_(O/S)",
            defaultMessage: "Colleagues challenge unsafe behaviour (O/S)"
        },
        "Communicating_lessons_learned_from_customer_and_consumer_complaints_across_the_site": {
            id: "generic.element.Communicating_lessons_learned_from_customer_and_consumer_complaints_across_the_site",
            defaultMessage: "Communicating lessons learned from customer and consumer complaints across the site"
        },
        "Communicating_lessons_learned_from_employee_injuries_across_the_site": {
            id: "generic.element.Communicating_lessons_learned_from_employee_injuries_across_the_site",
            defaultMessage: "Communicating lessons learned from employee injuries across the site"
        },
        "Communicating_lessons_learned_from_food_safety_incidents_across_the_site": {
            id: "generic.element.Communicating_lessons_learned_from_food_safety_incidents_across_the_site",
            defaultMessage: "Communicating lessons learned from food safety incidents across the site"
        },
        "Company_plans_include_improvements_in_customer_satisfaction": {
            id: "generic.element.Company_plans_include_improvements_in_customer_satisfaction",
            defaultMessage: "Company plans include improvements in customer satisfaction"
        },
        "Company_plans_include_improvements_in_customer_satisfaction_(M)": {
            id: "generic.element.Company_plans_include_improvements_in_customer_satisfaction_(M)",
            defaultMessage: "Company plans include improvements in customer satisfaction (M)"
        },
        "Company_plans_include_improvements_in_customer_satisfaction_(S/M)": {
            id: "generic.element.Company_plans_include_improvements_in_customer_satisfaction_(S/M)",
            defaultMessage: "Company plans include improvements in customer satisfaction (S/M)"
        },
        "Company_plans_include_improvements_in_efficiency": {
            id: "generic.element.Company_plans_include_improvements_in_efficiency",
            defaultMessage: "Company plans include improvements in efficiency"
        },
        "Company_plans_include_improvements_in_efficiency_(M)": {
            id: "generic.element.Company_plans_include_improvements_in_efficiency_(M)",
            defaultMessage: "Company plans include improvements in efficiency (M)"
        },
        "Company_plans_include_improvements_in_efficiency_(S/M)": {
            id: "generic.element.Company_plans_include_improvements_in_efficiency_(S/M)",
            defaultMessage: "Company plans include improvements in efficiency (S/M)"
        },
        "Company_plans_include_improvements_in_employee_health_and_safety": {
            id: "generic.element.Company_plans_include_improvements_in_employee_health_and_safety",
            defaultMessage: "Company plans include improvements in employee health and safety"
        },
        "Company_plans_include_improvements_in_employee_health_and_safety_(M)": {
            id: "generic.element.Company_plans_include_improvements_in_employee_health_and_safety_(M)",
            defaultMessage: "Company plans include improvements in employee health and safety (M)"
        },
        "Company_plans_include_improvements_in_food_safety": {
            id: "generic.element.Company_plans_include_improvements_in_food_safety",
            defaultMessage: "Company plans include improvements in food safety"
        },
        "Company_plans_include_improvements_in_food_safety_(M)": {
            id: "generic.element.Company_plans_include_improvements_in_food_safety_(M)",
            defaultMessage: "Company plans include improvements in food safety (M)"
        },
        "Company_plans_include_improvements_in_food_safety_(S/M)": {
            id: "generic.element.Company_plans_include_improvements_in_food_safety_(S/M)",
            defaultMessage: "Company plans include improvements in food safety (S/M)"
        },
        "Continual_improvement_is_included_in_departmental_targets_(M)": {
            id: "generic.element.Continual_improvement_is_included_in_departmental_targets_(M)",
            defaultMessage: "Continual improvement is included in departmental targets (M)"
        },
        "Continual_improvement_is_included_in_departmental_targets_(S/M)": {
            id: "generic.element.Continual_improvement_is_included_in_departmental_targets_(S/M)",
            defaultMessage: "Continual improvement is included in departmental targets (S/M)"
        },
        "Customer_and_consumer_suggestions_and_complaints_being_taken_seriously": {
            id: "generic.element.Customer_and_consumer_suggestions_and_complaints_being_taken_seriously",
            defaultMessage: "Customer and consumer suggestions and complaints being taken seriously"
        },
        "Customer_feedback_is_sought_to_improve_products_(M)": {
            id: "generic.element.Customer_feedback_is_sought_to_improve_products_(M)",
            defaultMessage: "Customer feedback is sought to improve products (M)"
        },
        "Customer_feedback_is_taken_seriously": {
            id: "generic.element.Customer_feedback_is_taken_seriously",
            defaultMessage: "Customer feedback is taken seriously"
        },
        "Customer_feedback_taken_seriously": {
            id: "generic.element.Customer_feedback_taken_seriously",
            defaultMessage: "Customer feedback taken seriously"
        },
        "Customer_satisfaction_levels_improve_every_year_(M)": {
            id: "generic.element.Customer_satisfaction_levels_improve_every_year_(M)",
            defaultMessage: "Customer satisfaction levels improve every year (M)"
        },
        "Customer_satisfaction_levels_improve_every_year_(S/M)": {
            id: "generic.element.Customer_satisfaction_levels_improve_every_year_(S/M)",
            defaultMessage: "Customer satisfaction levels improve every year (S/M)"
        },
        "Customer_satisfaction_levels_improving_every_year_(S/M)": {
            id: "generic.element.Customer_satisfaction_levels_improving_every_year_(S/M)",
            defaultMessage: "Customer satisfaction levels improving every year (S/M)"
        },
        "Data_is_used_to_analyse_trends_and_predict_risks_(M)": {
            id: "generic.element.Data_is_used_to_analyse_trends_and_predict_risks_(M)",
            defaultMessage: "Data is used to analyse trends and predict risks (M)"
        },
        "Data_is_used_to_drive_continual_improvement_(M)": {
            id: "generic.element.Data_is_used_to_drive_continual_improvement_(M)",
            defaultMessage: "Data is used to drive continual improvement (M)"
        },
        "Departmental_team_spirit": {
            id: "generic.element.Departmental_team_spirit",
            defaultMessage: "Departmental team spirit"
        },
        "Different_departments_communicating_and_working_well_together": {
            id: "generic.element.Different_departments_communicating_and_working_well_together",
            defaultMessage: "Different departments communicating and working well together"
        },
        "Different_departments_share_important_information_(S/M)": {
            id: "generic.element.Different_departments_share_important_information_(S/M)",
            defaultMessage: "Different departments share important information (S/M)"
        },
        "Different_departments_sharing_important_information_(S/M)": {
            id: "generic.element.Different_departments_sharing_important_information_(S/M)",
            defaultMessage: "Different departments sharing important information (S/M)"
        },
        "Different_departments_work_well_together": {
            id: "generic.element.Different_departments_work_well_together",
            defaultMessage: "Different departments work well together"
        },
        "Different_departments_working_well_together": {
            id: "generic.element.Different_departments_working_well_together",
            defaultMessage: "Different departments working well together"
        },
        "Different_people_do_tasks_in_the_same_way": {
            id: "generic.element.Different_people_do_tasks_in_the_same_way",
            defaultMessage: "Different people do tasks in the same way"
        },
        "Different_people_do_tasks_in_the_same_way_(O/S)": {
            id: "generic.element.Different_people_do_tasks_in_the_same_way_(O/S)",
            defaultMessage: "Different people do tasks in the same way (O/S)"
        },
        "Different_people_doing_tasks_in_the_same_way": {
            id: "generic.element.Different_people_doing_tasks_in_the_same_way",
            defaultMessage: "Different people doing tasks in the same way"
        },
        "Easily_manageable_amount_of_paperwork": {
            id: "generic.element.Easily_manageable_amount_of_paperwork",
            defaultMessage: "Easily manageable amount of paperwork"
        },
        "Effectiveness_of_employee_health_and_safety_communications": {
            id: "generic.element.Effectiveness_of_employee_health_and_safety_communications",
            defaultMessage: "Effectiveness of employee health and safety communications"
        },
        "Effectiveness_of_employee_health_and_safety_work_instructions": {
            id: "generic.element.Effectiveness_of_employee_health_and_safety_work_instructions",
            defaultMessage: "Effectiveness of employee health and safety work instructions"
        },
        "Effectiveness_of_employee_health_and_safety_work_instructions_(O)": {
            id: "generic.element.Effectiveness_of_employee_health_and_safety_work_instructions_(O)",
            defaultMessage: "Effectiveness of employee health and safety work instructions (O)"
        },
        "Effectiveness_of_food_safety_communications": {
            id: "generic.element.Effectiveness_of_food_safety_communications",
            defaultMessage: "Effectiveness of food safety communications"
        },
        "Effectiveness_of_food_safety_records": {
            id: "generic.element.Effectiveness_of_food_safety_records",
            defaultMessage: "Effectiveness of food safety records"
        },
        "Effectiveness_of_food_safety_work_instructions": {
            id: "generic.element.Effectiveness_of_food_safety_work_instructions",
            defaultMessage: "Effectiveness of food safety work instructions"
        },
        "Effectiveness_of_food_safety_work_instructions_(O)": {
            id: "generic.element.Effectiveness_of_food_safety_work_instructions_(O)",
            defaultMessage: "Effectiveness of food safety work instructions (O)"
        },
        "Effectiveness_of_product_quality_communications": {
            id: "generic.element.Effectiveness_of_product_quality_communications",
            defaultMessage: "Effectiveness of product quality communications"
        },
        "Effectiveness_of_quality_specifications": {
            id: "generic.element.Effectiveness_of_quality_specifications",
            defaultMessage: "Effectiveness of quality specifications"
        },
        "Effectiveness_of_quality_specifications_(O)": {
            id: "generic.element.Effectiveness_of_quality_specifications_(O)",
            defaultMessage: "Effectiveness of quality specifications (O)"
        },
        "Effectiveness_of_written_guidance_in_case_of_potential_problems": {
            id: "generic.element.Effectiveness_of_written_guidance_in_case_of_potential_problems",
            defaultMessage: "Effectiveness of written guidance in case of potential problems"
        },
        "Effectiveness_of_written_guidance_in_case_of_potential_problems_(O)": {
            id: "generic.element.Effectiveness_of_written_guidance_in_case_of_potential_problems_(O)",
            defaultMessage: "Effectiveness of written guidance in case of potential problems (O)"
        },
        "Employee_health_and_safety_excellence_being_part_of_company_vision": {
            id: "generic.element.Employee_health_and_safety_excellence_being_part_of_company_vision",
            defaultMessage: "Employee health and safety excellence being part of company vision"
        },
        "Employee_health_and_safety_excellence_is_part_of_the_company_vision": {
            id: "generic.element.Employee_health_and_safety_excellence_is_part_of_the_company_vision",
            defaultMessage: "Employee health and safety excellence is part of the company vision"
        },
        "Employee_health_and_safety_excellence_is_part_of_the_company_vision_(M)": {
            id: "generic.element.Employee_health_and_safety_excellence_is_part_of_the_company_vision_(M)",
            defaultMessage: "Employee health and safety excellence is part of the company vision (M)"
        },
        "Employee_health_and_safety_levels_improve_every_year_(M)": {
            id: "generic.element.Employee_health_and_safety_levels_improve_every_year_(M)",
            defaultMessage: "Employee health and safety levels improve every year (M)"
        },
        "Employee_health_and_safety_levels_improve_every_year_(S/M)": {
            id: "generic.element.Employee_health_and_safety_levels_improve_every_year_(S/M)",
            defaultMessage: "Employee health and safety levels improve every year (S/M)"
        },
        "Employee_health_and_safety_levels_improving_every_year_(S/M)": {
            id: "generic.element.Employee_health_and_safety_levels_improving_every_year_(S/M)",
            defaultMessage: "Employee health and safety levels improving every year (S/M)"
        },
        "Employee_health_and_safety_standards_are_higher_than_similar_companies_(M)": {
            id: "generic.element.Employee_health_and_safety_standards_are_higher_than_similar_companies_(M)",
            defaultMessage: "Employee health and safety standards are higher than similar companies (M)"
        },
        "Employee_health_and_safety_standards_are_maintained_regardless_of_external_visit_or_audit": {
            id: "generic.element.Employee_health_and_safety_standards_are_maintained_regardless_of_external_visit_or_audit",
            defaultMessage: "Employee health and safety standards are maintained regardless of external visit or audit"
        },
        "Employee_health_and_safety_standards_are_maintained_regardless_of_external_visit_or_audit_(S/M)": {
            id: "generic.element.Employee_health_and_safety_standards_are_maintained_regardless_of_external_visit_or_audit_(S/M)",
            defaultMessage: "Employee health and safety standards are maintained regardless of external visit or audit (S/M)"
        },
        "Employee_health_and_safety_standards_higher_than_similar_companies_(M)": {
            id: "generic.element.Employee_health_and_safety_standards_higher_than_similar_companies_(M)",
            defaultMessage: "Employee health and safety standards higher than similar companies (M)"
        },
        "Employee_health_and_safety_team_is_respected_(S/M)": {
            id: "generic.element.Employee_health_and_safety_team_is_respected_(S/M)",
            defaultMessage: "Employee health and safety team is respected (S/M)"
        },
        "Empowerment_People_feel_comfortable_about_raising_concerns": {
            id: "generic.element.Empowerment_People_feel_comfortable_about_raising_concerns",
            defaultMessage: "Empowerment People feel comfortable about raising concerns"
        },
        "Enjoyment_of_food_safety_training": {
            id: "generic.element.Enjoyment_of_food_safety_training",
            defaultMessage: "Enjoyment of food safety training"
        },
        "Enjoyment_of_training": {
            id: "generic.element.Enjoyment_of_training",
            defaultMessage: "Enjoyment of training"
        },
        "Equipment_and_materials_are_sufficient_for_employee_safety": {
            id: "generic.element.Equipment_and_materials_are_sufficient_for_employee_safety",
            defaultMessage: "Equipment and materials are sufficient for employee safety"
        },
        "Equipment_and_materials_are_sufficient_for_employee_safety_(O)": {
            id: "generic.element.Equipment_and_materials_are_sufficient_for_employee_safety_(O)",
            defaultMessage: "Equipment and materials are sufficient for employee safety (O)"
        },
        "Equipment_and_materials_are_sufficient_for_high_quality_work": {
            id: "generic.element.Equipment_and_materials_are_sufficient_for_high_quality_work",
            defaultMessage: "Equipment and materials are sufficient for high quality work"
        },
        "Equipment_and_materials_are_sufficient_for_high_quality_work_(O)": {
            id: "generic.element.Equipment_and_materials_are_sufficient_for_high_quality_work_(O)",
            defaultMessage: "Equipment and materials are sufficient for high quality work (O)"
        },
        "Equipment_and_materials_are_sufficient_for_hygiene": {
            id: "generic.element.Equipment_and_materials_are_sufficient_for_hygiene",
            defaultMessage: "Equipment and materials are sufficient for hygiene"
        },
        "Equipment_and_materials_are_sufficient_for_hygiene_(O)": {
            id: "generic.element.Equipment_and_materials_are_sufficient_for_hygiene_(O)",
            defaultMessage: "Equipment and materials are sufficient for hygiene (O)"
        },
        "Excitement_about_direction_company_is_moving_in": {
            id: "generic.element.Excitement_about_direction_company_is_moving_in",
            defaultMessage: "Excitement about direction company is moving in"
        },
        "Excitement_about_direction_site_is_moving_in": {
            id: "generic.element.Excitement_about_direction_site_is_moving_in",
            defaultMessage: "Excitement about direction site is moving in"
        },
        "External_visitors_follow_employee_health_and_safety_rules": {
            id: "generic.element.External_visitors_follow_employee_health_and_safety_rules",
            defaultMessage: "External visitors follow employee health and safety rules"
        },
        "External_visitors_follow_hygiene_rules": {
            id: "generic.element.External_visitors_follow_hygiene_rules",
            defaultMessage: "External visitors follow hygiene rules"
        },
        "External_visitors_following_employee_health_and_safety_rules": {
            id: "generic.element.External_visitors_following_employee_health_and_safety_rules",
            defaultMessage: "External visitors following employee health and safety rules"
        },
        "External_visitors_following_hygiene_rules": {
            id: "generic.element.External_visitors_following_hygiene_rules",
            defaultMessage: "External visitors following hygiene rules"
        },
        "Facilities_and_equipment_are_clean_and_well-maintained": {
            id: "generic.element.Facilities_and_equipment_are_clean_and_well-maintained",
            defaultMessage: "Facilities and equipment are clean and well-maintained"
        },
        "Facilities_and_equipment_are_clean_and_well-maintained_(O/S)": {
            id: "generic.element.Facilities_and_equipment_are_clean_and_well-maintained_(O/S)",
            defaultMessage: "Facilities and equipment are clean and well-maintained (O/S)"
        },
        "Fairness_of_promotion_and_reward": {
            id: "generic.element.Fairness_of_promotion_and_reward",
            defaultMessage: "Fairness of promotion and reward"
        },
        "Fairness_of_salaries": {
            id: "generic.element.Fairness_of_salaries",
            defaultMessage: "Fairness of salaries"
        },
        "Feeling_excited_by_the_direction_the_company_is_moving_in": {
            id: "generic.element.Feeling_excited_by_the_direction_the_company_is_moving_in",
            defaultMessage: "Feeling excited by the direction the company is moving in"
        },
        "Feeling_supported_by_managers_to_do_job_well": {
            id: "generic.element.Feeling_supported_by_managers_to_do_job_well",
            defaultMessage: "Feeling supported by managers to do job well"
        },
        "Feeling_trusted_and_valued_by_managers": {
            id: "generic.element.Feeling_trusted_and_valued_by_managers",
            defaultMessage: "Feeling trusted and valued by managers"
        },
        "Food_safety_excellence_being_part_of_company_vision": {
            id: "generic.element.Food_safety_excellence_being_part_of_company_vision",
            defaultMessage: "Food safety excellence being part of company vision"
        },
        "Food_safety_excellence_is_part_of_the_company_vision": {
            id: "generic.element.Food_safety_excellence_is_part_of_the_company_vision",
            defaultMessage: "Food safety excellence is part of the company vision"
        },
        "Food_safety_excellence_is_part_of_the_company_vision_(M)": {
            id: "generic.element.Food_safety_excellence_is_part_of_the_company_vision_(M)",
            defaultMessage: "Food safety excellence is part of the company vision (M)"
        },
        "Food_safety_excellence_is_part_of_the_company_vision_(S/M)": {
            id: "generic.element.Food_safety_excellence_is_part_of_the_company_vision_(S/M)",
            defaultMessage: "Food safety excellence is part of the company vision (S/M)"
        },
        "Food_safety_standards_are_maintained_regardless_of_external_visit_or_audit": {
            id: "generic.element.Food_safety_standards_are_maintained_regardless_of_external_visit_or_audit",
            defaultMessage: "Food safety standards are maintained regardless of external visit or audit"
        },
        "Food_safety_standards_are_maintained_regardless_of_external_visit_or_audit_(S/M)": {
            id: "generic.element.Food_safety_standards_are_maintained_regardless_of_external_visit_or_audit_(S/M)",
            defaultMessage: "Food safety standards are maintained regardless of external visit or audit (S/M)"
        },
        "Food_safety_standards_improve_every_year_(M)": {
            id: "generic.element.Food_safety_standards_improve_every_year_(M)",
            defaultMessage: "Food safety standards improve every year (M)"
        },
        "Food_safety_standards_improve_every_year_(S/M)": {
            id: "generic.element.Food_safety_standards_improve_every_year_(S/M)",
            defaultMessage: "Food safety standards improve every year (S/M)"
        },
        "Food_safety_standards_improving_every_year": {
            id: "generic.element.Food_safety_standards_improving_every_year",
            defaultMessage: "Food safety standards improving every year"
        },
        "Food_safety_standards_improving_every_year_(S/M)": {
            id: "generic.element.Food_safety_standards_improving_every_year_(S/M)",
            defaultMessage: "Food safety standards improving every year (S/M)"
        },
        "Food_safety_team_is_respected_(S/M)": {
            id: "generic.element.Food_safety_team_is_respected_(S/M)",
            defaultMessage: "Food safety team is respected (S/M)"
        },
        "Frequency_of_communications_about_employee_health_and_safety": {
            id: "generic.element.Frequency_of_communications_about_employee_health_and_safety",
            defaultMessage: "Frequency of communications about employee health and safety"
        },
        "Frequency_of_communications_about_food_safety": {
            id: "generic.element.Frequency_of_communications_about_food_safety",
            defaultMessage: "Frequency of communications about food safety"
        },
        "Frequency_of_communications_about_product_quality": {
            id: "generic.element.Frequency_of_communications_about_product_quality",
            defaultMessage: "Frequency of communications about product quality"
        },
        "Frequency_of_food_safety_communications": {
            id: "generic.element.Frequency_of_food_safety_communications",
            defaultMessage: "Frequency of food safety communications"
        },
        "Frequency_of_food_safety_training": {
            id: "generic.element.Frequency_of_food_safety_training",
            defaultMessage: "Frequency of food safety training"
        },
        "Frequency_of_general_internal_communications": {
            id: "generic.element.Frequency_of_general_internal_communications",
            defaultMessage: "Frequency of general internal communications"
        },
        "Frequency_of_internal_audit": {
            id: "generic.element.Frequency_of_internal_audit",
            defaultMessage: "Frequency of internal audit"
        },
        "Frequency_of_internal_audit_(M)": {
            id: "generic.element.Frequency_of_internal_audit_(M)",
            defaultMessage: "Frequency of internal audit (M)"
        },
        "Frequency_of_management_training_(M)": {
            id: "generic.element.Frequency_of_management_training_(M)",
            defaultMessage: "Frequency of management training (M)"
        },
        "Frequency_of_other_types_of_training": {
            id: "generic.element.Frequency_of_other_types_of_training",
            defaultMessage: "Frequency of other types of training"
        },
        "Frequency_of_training_in_employee_health_and_safety": {
            id: "generic.element.Frequency_of_training_in_employee_health_and_safety",
            defaultMessage: "Frequency of training in employee health and safety"
        },
        "Frequency_of_training_in_food_safety": {
            id: "generic.element.Frequency_of_training_in_food_safety",
            defaultMessage: "Frequency of training in food safety"
        },
        "Frequency_of_training_in_product_quality": {
            id: "generic.element.Frequency_of_training_in_product_quality",
            defaultMessage: "Frequency of training in product quality"
        },
        "Frequency_that_employee_health_and_safety_data_is_shared": {
            id: "generic.element.Frequency_that_employee_health_and_safety_data_is_shared",
            defaultMessage: "Frequency that employee health and safety data is shared"
        },
        "Frequency_that_food_safety_data_is_shared": {
            id: "generic.element.Frequency_that_food_safety_data_is_shared",
            defaultMessage: "Frequency that food safety data is shared"
        },
        "Frequency_that_quality_data_is_shared": {
            id: "generic.element.Frequency_that_quality_data_is_shared",
            defaultMessage: "Frequency that quality data is shared"
        },
        "Having_an_appropriate_amount_of_employee_health_and_safety_paperwork_(S/M)": {
            id: "generic.element.Having_an_appropriate_amount_of_employee_health_and_safety_paperwork_(S/M)",
            defaultMessage: "Having an appropriate amount of employee health and safety paperwork (S/M)"
        },
        "Having_an_appropriate_amount_of_food_safety_paperwork": {
            id: "generic.element.Having_an_appropriate_amount_of_food_safety_paperwork",
            defaultMessage: "Having an appropriate amount of food safety paperwork"
        },
        "Having_an_appropriate_amount_of_food_safety_paperwork_(S/M)": {
            id: "generic.element.Having_an_appropriate_amount_of_food_safety_paperwork_(S/M)",
            defaultMessage: "Having an appropriate amount of food safety paperwork (S/M)"
        },
        "Having_an_appropriate_amount_of_quality_paperwork_(S/M)": {
            id: "generic.element.Having_an_appropriate_amount_of_quality_paperwork_(S/M)",
            defaultMessage: "Having an appropriate amount of quality paperwork (S/M)"
        },
        "Having_authority_to_take_action_in_the_event_of_a_food_safety_problem": {
            id: "generic.element.Having_authority_to_take_action_in_the_event_of_a_food_safety_problem",
            defaultMessage: "Having authority to take action in the event of a food safety problem"
        },
        "Having_authority_to_take_action_in_the_event_of_a_product_quality_problem": {
            id: "generic.element.Having_authority_to_take_action_in_the_event_of_a_product_quality_problem",
            defaultMessage: "Having authority to take action in the event of a product quality problem"
        },
        "Having_authority_to_take_action_in_the_event_of_a_quality_problem": {
            id: "generic.element.Having_authority_to_take_action_in_the_event_of_a_quality_problem",
            defaultMessage: "Having authority to take action in the event of a quality problem"
        },
        "Having_authority_to_take_action_in_the_event_of_an_employee_health_and_safety_problem": {
            id: "generic.element.Having_authority_to_take_action_in_the_event_of_an_employee_health_and_safety_problem",
            defaultMessage: "Having authority to take action in the event of an employee health and safety problem"
        },
        "Having_enough_time_to_work_correctly_and_safely": {
            id: "generic.element.Having_enough_time_to_work_correctly_and_safely",
            defaultMessage: "Having enough time to work correctly and safely"
        },
        "Having_good_opportunities_to_develop_knowledge_and_skills": {
            id: "generic.element.Having_good_opportunities_to_develop_knowledge_and_skills",
            defaultMessage: "Having good opportunities to develop knowledge and skills"
        },
        "Hygiene_standards_are_higher_than_similar_companies_(M)": {
            id: "generic.element.Hygiene_standards_are_higher_than_similar_companies_(M)",
            defaultMessage: "Hygiene standards are higher than similar companies (M)"
        },
        "Hygiene_standards_being_higher_than_other_food_manufacturing_sites": {
            id: "generic.element.Hygiene_standards_being_higher_than_other_food_manufacturing_sites",
            defaultMessage: "Hygiene standards being higher than other food manufacturing sites"
        },
        "Hygiene_standards_higher_than_similar_companies_(M)": {
            id: "generic.element.Hygiene_standards_higher_than_similar_companies_(M)",
            defaultMessage: "Hygiene standards higher than similar companies (M)"
        },
        "IT_equipment_and_software_are_up-to-date_(S/M)": {
            id: "generic.element.IT_equipment_and_software_are_up-to-date_(S/M)",
            defaultMessage: "IT equipment and software are up-to-date (S/M)"
        },
        "Inaccurate_behaviour_challenged": {
            id: "generic.element.Inaccurate_behaviour_challenged",
            defaultMessage: "Inaccurate behaviour challenged"
        },
        "Inclusion_of_company_plans_for_improving_customer_satisfaction": {
            id: "generic.element.Inclusion_of_company_plans_for_improving_customer_satisfaction",
            defaultMessage: "Inclusion of company plans for improving customer satisfaction"
        },
        "Inclusion_of_company_plans_for_improving_efficiency": {
            id: "generic.element.Inclusion_of_company_plans_for_improving_efficiency",
            defaultMessage: "Inclusion of company plans for improving efficiency"
        },
        "Inclusion_of_company_plans_for_improving_employee_health_and_safety": {
            id: "generic.element.Inclusion_of_company_plans_for_improving_employee_health_and_safety",
            defaultMessage: "Inclusion of company plans for improving employee health and safety"
        },
        "Inclusion_of_company_plans_for_improving_food_safety": {
            id: "generic.element.Inclusion_of_company_plans_for_improving_food_safety",
            defaultMessage: "Inclusion of company plans for improving food safety"
        },
        "Inclusion_of_continual_improvement_in_targets_(S/M)": {
            id: "generic.element.Inclusion_of_continual_improvement_in_targets_(S/M)",
            defaultMessage: "Inclusion of continual improvement in targets (S/M)"
        },
        "Inclusion_of_food_safety_in_departmental_goals_and_targets": {
            id: "generic.element.Inclusion_of_food_safety_in_departmental_goals_and_targets",
            defaultMessage: "Inclusion of food safety in departmental goals and targets"
        },
        "Information_about_site_performance_is_shared": {
            id: "generic.element.Information_about_site_performance_is_shared",
            defaultMessage: "Information about site performance is shared"
        },
        "Information_about_site_performance_shared": {
            id: "generic.element.Information_about_site_performance_shared",
            defaultMessage: "Information about site performance shared"
        },
        "Instructions_are_realistic_so_exact_procedures_can_be_followed": {
            id: "generic.element.Instructions_are_realistic_so_exact_procedures_can_be_followed",
            defaultMessage: "Instructions are realistic so exact procedures can be followed"
        },
        "Instructions_are_realistic_so_exact_procedures_can_be_followed_(O/S)": {
            id: "generic.element.Instructions_are_realistic_so_exact_procedures_can_be_followed_(O/S)",
            defaultMessage: "Instructions are realistic so exact procedures can be followed (O/S)"
        },
        "Investigation_of_problems_focuses_on_learning_not_blame": {
            id: "generic.element.Investigation_of_problems_focuses_on_learning_not_blame",
            defaultMessage: "Investigation of problems focuses on learning not blame"
        },
        "Knowledge_of_company_plans_for_improving_food_safety": {
            id: "generic.element.Knowledge_of_company_plans_for_improving_food_safety",
            defaultMessage: "Knowledge of company plans for improving food safety"
        },
        "Knowledge_of_company_values": {
            id: "generic.element.Knowledge_of_company_values",
            defaultMessage: "Knowledge of company values"
        },
        "Knowledge_of_employee_safety_risks_at_site": {
            id: "generic.element.Knowledge_of_employee_safety_risks_at_site",
            defaultMessage: "Knowledge of employee safety risks at site"
        },
        "Knowledge_of_employee_safety_risks_at_the_site": {
            id: "generic.element.Knowledge_of_employee_safety_risks_at_the_site",
            defaultMessage: "Knowledge of employee safety risks at the site"
        },
        "Knowledge_of_food_safety_risks_at_site": {
            id: "generic.element.Knowledge_of_food_safety_risks_at_site",
            defaultMessage: "Knowledge of food safety risks at site"
        },
        "Knowledge_of_food_safety_risks_at_the_site": {
            id: "generic.element.Knowledge_of_food_safety_risks_at_the_site",
            defaultMessage: "Knowledge of food safety risks at the site"
        },
        "Knowledge_of_general_company_strategy": {
            id: "generic.element.Knowledge_of_general_company_strategy",
            defaultMessage: "Knowledge of general company strategy"
        },
        "Knowledge_of_general_company_vision": {
            id: "generic.element.Knowledge_of_general_company_vision",
            defaultMessage: "Knowledge of general company vision"
        },
        "Learning_from_mistakes_to_prevent_repeat_problems": {
            id: "generic.element.Learning_from_mistakes_to_prevent_repeat_problems",
            defaultMessage: "Learning from mistakes to prevent repeat problems"
        },
        "Learning_lessons_from_employee_safety_incidents_in_other_companies_(M)": {
            id: "generic.element.Learning_lessons_from_employee_safety_incidents_in_other_companies_(M)",
            defaultMessage: "Learning lessons from employee safety incidents in other companies (M)"
        },
        "Learning_lessons_from_food_safety_outbreaks_in_other_companies_(M)": {
            id: "generic.element.Learning_lessons_from_food_safety_outbreaks_in_other_companies_(M)",
            defaultMessage: "Learning lessons from food safety outbreaks in other companies (M)"
        },
        "Learning_lessons_from_near_misses_to_prevent_accidents_and_injuries_(S/M)": {
            id: "generic.element.Learning_lessons_from_near_misses_to_prevent_accidents_and_injuries_(S/M)",
            defaultMessage: "Learning lessons from near misses to prevent accidents and injuries (S/M)"
        },
        "Lessons_are_learned_from_employee_safety_incidents_in_other_companies_(M)": {
            id: "generic.element.Lessons_are_learned_from_employee_safety_incidents_in_other_companies_(M)",
            defaultMessage: "Lessons are learned from employee safety incidents in other companies (M)"
        },
        "Lessons_are_learned_from_food_safety_outbreaks_in_other_companies": {
            id: "generic.element.Lessons_are_learned_from_food_safety_outbreaks_in_other_companies",
            defaultMessage: "Lessons are learned from food safety outbreaks in other companies"
        },
        "Lessons_are_learned_from_food_safety_outbreaks_in_other_companies_(M)": {
            id: "generic.element.Lessons_are_learned_from_food_safety_outbreaks_in_other_companies_(M)",
            defaultMessage: "Lessons are learned from food safety outbreaks in other companies (M)"
        },
        "Lessons_are_learned_from_near_misses_to_prevent_accidents_and_injuries_(S/M)": {
            id: "generic.element.Lessons_are_learned_from_near_misses_to_prevent_accidents_and_injuries_(S/M)",
            defaultMessage: "Lessons are learned from near misses to prevent accidents and injuries (S/M)"
        },
        "Lessons_learned_from_customer_and_consumer_complaints_are_communicated_across_the_site": {
            id: "generic.element.Lessons_learned_from_customer_and_consumer_complaints_are_communicated_across_the_site",
            defaultMessage: "Lessons learned from customer and consumer complaints are communicated across the site"
        },
        "Lessons_learned_from_customer_and_consumer_complaints_are_communicated_across_the_site_(S/M)": {
            id: "generic.element.Lessons_learned_from_customer_and_consumer_complaints_are_communicated_across_the_site_(S/M)",
            defaultMessage: "Lessons learned from customer and consumer complaints are communicated across the site (S/M)"
        },
        "Lessons_learned_from_employee_injuries_are_communicated_across_the_site": {
            id: "generic.element.Lessons_learned_from_employee_injuries_are_communicated_across_the_site",
            defaultMessage: "Lessons learned from employee injuries are communicated across the site"
        },
        "Lessons_learned_from_employee_injuries_are_communicated_across_the_site_(S/M)": {
            id: "generic.element.Lessons_learned_from_employee_injuries_are_communicated_across_the_site_(S/M)",
            defaultMessage: "Lessons learned from employee injuries are communicated across the site (S/M)"
        },
        "Lessons_learned_from_food_safety_incidents_are_communicated_across_the_site": {
            id: "generic.element.Lessons_learned_from_food_safety_incidents_are_communicated_across_the_site",
            defaultMessage: "Lessons learned from food safety incidents are communicated across the site"
        },
        "Lessons_learned_from_food_safety_incidents_are_communicated_across_the_site_(S/M)": {
            id: "generic.element.Lessons_learned_from_food_safety_incidents_are_communicated_across_the_site_(S/M)",
            defaultMessage: "Lessons learned from food safety incidents are communicated across the site (S/M)"
        },
        "Maintenance_of_employee_health_and_safety_standards_regardless_of_external_visit_or_audit": {
            id: "generic.element.Maintenance_of_employee_health_and_safety_standards_regardless_of_external_visit_or_audit",
            defaultMessage: "Maintenance of employee health and safety standards regardless of external visit or audit"
        },
        "Maintenance_of_food_safety_standards_regardless_of_external_audit": {
            id: "generic.element.Maintenance_of_food_safety_standards_regardless_of_external_audit",
            defaultMessage: "Maintenance of food safety standards regardless of external audit"
        },
        "Maintenance_of_food_safety_standards_regardless_of_external_visit_or_audit": {
            id: "generic.element.Maintenance_of_food_safety_standards_regardless_of_external_visit_or_audit",
            defaultMessage: "Maintenance of food safety standards regardless of external visit or audit"
        },
        "Maintenance_of_quality_standards_regardless_of_external_visit_or_audit": {
            id: "generic.element.Maintenance_of_quality_standards_regardless_of_external_visit_or_audit",
            defaultMessage: "Maintenance of quality standards regardless of external visit or audit"
        },
        "Management_systems_are_reviewed_and_updated_(M)": {
            id: "generic.element.Management_systems_are_reviewed_and_updated_(M)",
            defaultMessage: "Management systems are reviewed and updated (M)"
        },
        "Management_systems_reviewed_and_updated_(M)": {
            id: "generic.element.Management_systems_reviewed_and_updated_(M)",
            defaultMessage: "Management systems reviewed and updated (M)"
        },
        "Managers_feel_encouraged_to_attend_external_events": {
            id: "generic.element.Managers_feel_encouraged_to_attend_external_events",
            defaultMessage: "Managers feel encouraged to attend external events"
        },
        "Managers_feel_encouraged_to_attend_external_events_(M)": {
            id: "generic.element.Managers_feel_encouraged_to_attend_external_events_(M)",
            defaultMessage: "Managers feel encouraged to attend external events (M)"
        },
        "Managers_feel_encouraged_to_praise_employees_for_good_work_(M)": {
            id: "generic.element.Managers_feel_encouraged_to_praise_employees_for_good_work_(M)",
            defaultMessage: "Managers feel encouraged to praise employees for good work (M)"
        },
        "Money_is_not_a_barrier_to_customer_satisfaction_(S/M)": {
            id: "generic.element.Money_is_not_a_barrier_to_customer_satisfaction_(S/M)",
            defaultMessage: "Money is not a barrier to customer satisfaction (S/M)"
        },
        "Money_is_not_a_barrier_to_employee_health_and_safety_(S/M)": {
            id: "generic.element.Money_is_not_a_barrier_to_employee_health_and_safety_(S/M)",
            defaultMessage: "Money is not a barrier to employee health and safety (S/M)"
        },
        "Money_is_not_a_barrier_to_food_safety_(S/M)": {
            id: "generic.element.Money_is_not_a_barrier_to_food_safety_(S/M)",
            defaultMessage: "Money is not a barrier to food safety (S/M)"
        },
        "Money_not_a_barrier_to_customer_satisfaction_(S/M)": {
            id: "generic.element.Money_not_a_barrier_to_customer_satisfaction_(S/M)",
            defaultMessage: "Money not a barrier to customer satisfaction (S/M)"
        },
        "Money_not_a_barrier_to_employee_health_and_safety_(S/M)": {
            id: "generic.element.Money_not_a_barrier_to_employee_health_and_safety_(S/M)",
            defaultMessage: "Money not a barrier to employee health and safety (S/M)"
        },
        "Money_not_a_barrier_to_food_safety_(S/M)": {
            id: "generic.element.Money_not_a_barrier_to_food_safety_(S/M)",
            defaultMessage: "Money not a barrier to food safety (S/M)"
        },
        "New_information_provided_by_food_safety_training": {
            id: "generic.element.New_information_provided_by_food_safety_training",
            defaultMessage: "New information provided by food safety training"
        },
        "New_information_provided_by_training": {
            id: "generic.element.New_information_provided_by_training",
            defaultMessage: "New information provided by training"
        },
        "New_initiatives_are_evaluated_for_effectiveness_(M)": {
            id: "generic.element.New_initiatives_are_evaluated_for_effectiveness_(M)",
            defaultMessage: "New initiatives are evaluated for effectiveness (M)"
        },
        "New_initiatives_are_evaluated_for_effectiveness_(S/M)": {
            id: "generic.element.New_initiatives_are_evaluated_for_effectiveness_(S/M)",
            defaultMessage: "New initiatives are evaluated for effectiveness (S/M)"
        },
        "New_initiatives_evaluated_for_effectiveness_(S/M)": {
            id: "generic.element.New_initiatives_evaluated_for_effectiveness_(S/M)",
            defaultMessage: "New initiatives evaluated for effectiveness (S/M)"
        },
        "People_agree_with_long_term_company_plans_(S/M)": {
            id: "generic.element.People_agree_with_long_term_company_plans_(S/M)",
            defaultMessage: "People agree with long term company plans (S/M)"
        },
        "People_are_corrected_by_managers_when_not_following_procedures": {
            id: "generic.element.People_are_corrected_by_managers_when_not_following_procedures",
            defaultMessage: "People are corrected by managers when not following procedures"
        },
        "People_are_corrected_by_managers_when_not_following_procedures_(O)": {
            id: "generic.element.People_are_corrected_by_managers_when_not_following_procedures_(O)",
            defaultMessage: "People are corrected by managers when not following procedures (O)"
        },
        "People_are_corrected_by_managers_when_not_working_hygienically": {
            id: "generic.element.People_are_corrected_by_managers_when_not_working_hygienically",
            defaultMessage: "People are corrected by managers when not working hygienically"
        },
        "People_are_corrected_by_managers_when_not_working_hygienically_(O)": {
            id: "generic.element.People_are_corrected_by_managers_when_not_working_hygienically_(O)",
            defaultMessage: "People are corrected by managers when not working hygienically (O)"
        },
        "People_are_corrected_by_managers_when_not_working_safely": {
            id: "generic.element.People_are_corrected_by_managers_when_not_working_safely",
            defaultMessage: "People are corrected by managers when not working safely"
        },
        "People_are_corrected_by_managers_when_not_working_safely_(O)": {
            id: "generic.element.People_are_corrected_by_managers_when_not_working_safely_(O)",
            defaultMessage: "People are corrected by managers when not working safely (O)"
        },
        "People_are_excited_about_the_direction_the_site_is_moving_in": {
            id: "generic.element.People_are_excited_about_the_direction_the_site_is_moving_in",
            defaultMessage: "People are excited about the direction the site is moving in"
        },
        "People_are_motivated_to_stay_with_the_company": {
            id: "generic.element.People_are_motivated_to_stay_with_the_company",
            defaultMessage: "People are motivated to stay with the company"
        },
        "People_are_open_to_change": {
            id: "generic.element.People_are_open_to_change",
            defaultMessage: "People are open to change"
        },
        "People_are_praised_for_identifying_a_potential_employee_health_and_safety_issue": {
            id: "generic.element.People_are_praised_for_identifying_a_potential_employee_health_and_safety_issue",
            defaultMessage: "People are praised for identifying a potential employee health and safety issue"
        },
        "People_are_praised_for_identifying_a_potential_employee_health_and_safety_issue_(O/S)": {
            id: "generic.element.People_are_praised_for_identifying_a_potential_employee_health_and_safety_issue_(O/S)",
            defaultMessage: "People are praised for identifying a potential employee health and safety issue (O/S)"
        },
        "People_are_praised_for_identifying_a_potential_food_safety_issue": {
            id: "generic.element.People_are_praised_for_identifying_a_potential_food_safety_issue",
            defaultMessage: "People are praised for identifying a potential food safety issue"
        },
        "People_are_praised_for_identifying_a_potential_food_safety_issue_(O/S)": {
            id: "generic.element.People_are_praised_for_identifying_a_potential_food_safety_issue_(O/S)",
            defaultMessage: "People are praised for identifying a potential food safety issue (O/S)"
        },
        "People_are_praised_for_identifying_a_potential_product_quality_issue": {
            id: "generic.element.People_are_praised_for_identifying_a_potential_product_quality_issue",
            defaultMessage: "People are praised for identifying a potential product quality issue"
        },
        "People_are_praised_for_identifying_a_potential_product_quality_issue_(O/S)": {
            id: "generic.element.People_are_praised_for_identifying_a_potential_product_quality_issue_(O/S)",
            defaultMessage: "People are praised for identifying a potential product quality issue (O/S)"
        },
        "People_believe_they_can_develop_their_career_in_the_company": {
            id: "generic.element.People_believe_they_can_develop_their_career_in_the_company",
            defaultMessage: "People believe they can develop their career in the company"
        },
        "People_can_be_trusted_to_work_correctly_without_supervision": {
            id: "generic.element.People_can_be_trusted_to_work_correctly_without_supervision",
            defaultMessage: "People can be trusted to work correctly without supervision"
        },
        "People_can_be_trusted_to_work_correctly_without_supervision_(S/M)": {
            id: "generic.element.People_can_be_trusted_to_work_correctly_without_supervision_(S/M)",
            defaultMessage: "People can be trusted to work correctly without supervision (S/M)"
        },
        "People_enjoy_working_with_their_colleagues": {
            id: "generic.element.People_enjoy_working_with_their_colleagues",
            defaultMessage: "People enjoy working with their colleagues"
        },
        "People_feel_happy_at_work": {
            id: "generic.element.People_feel_happy_at_work",
            defaultMessage: "People feel happy at work"
        },
        "People_feel_proud_to_work_at_the_company": {
            id: "generic.element.People_feel_proud_to_work_at_the_company",
            defaultMessage: "People feel proud to work at the company"
        },
        "People_get_effective_feedback_on_their_performance_": {
            id: "generic.element.People_get_effective_feedback_on_their_performance_",
            defaultMessage: "People get effective feedback on their performance "
        },
        "People_have_authority_to_take_action_in_the_event_of_a_food_safety_problem": {
            id: "generic.element.People_have_authority_to_take_action_in_the_event_of_a_food_safety_problem",
            defaultMessage: "People have authority to take action in the event of a food safety problem"
        },
        "People_have_authority_to_take_action_in_the_event_of_a_food_safety_problem_(S)": {
            id: "generic.element.People_have_authority_to_take_action_in_the_event_of_a_food_safety_problem_(S)",
            defaultMessage: "People have authority to take action in the event of a food safety problem (S)"
        },
        "People_have_authority_to_take_action_in_the_event_of_a_product_quality_problem": {
            id: "generic.element.People_have_authority_to_take_action_in_the_event_of_a_product_quality_problem",
            defaultMessage: "People have authority to take action in the event of a product quality problem"
        },
        "People_have_authority_to_take_action_in_the_event_of_a_product_quality_problem_(S)": {
            id: "generic.element.People_have_authority_to_take_action_in_the_event_of_a_product_quality_problem_(S)",
            defaultMessage: "People have authority to take action in the event of a product quality problem (S)"
        },
        "People_have_authority_to_take_action_in_the_event_of_an_employee_health_and_safety_problem": {
            id: "generic.element.People_have_authority_to_take_action_in_the_event_of_an_employee_health_and_safety_problem",
            defaultMessage: "People have authority to take action in the event of an employee health and safety problem"
        },
        "People_have_authority_to_take_action_in_the_event_of_an_employee_health_and_safety_problem_(S)": {
            id: "generic.element.People_have_authority_to_take_action_in_the_event_of_an_employee_health_and_safety_problem_(S)",
            defaultMessage: "People have authority to take action in the event of an employee health and safety problem (S)"
        },
        "People_help_each_other": {
            id: "generic.element.People_help_each_other",
            defaultMessage: "People help each other"
        },
        "People_perceive_that_the_company_vision_is_realistic": {
            id: "generic.element.People_perceive_that_the_company_vision_is_realistic",
            defaultMessage: "People perceive that the company vision is realistic"
        },
        "People_perceive_that_the_company_vision_is_realistic_(S/M)": {
            id: "generic.element.People_perceive_that_the_company_vision_is_realistic_(S/M)",
            defaultMessage: "People perceive that the company vision is realistic (S/M)"
        },
        "People_respect_company_values_(S/M)": {
            id: "generic.element.People_respect_company_values_(S/M)",
            defaultMessage: "People respect company values (S/M)"
        },
        "People_take_customer_complaint_risk_seriously": {
            id: "generic.element.People_take_customer_complaint_risk_seriously",
            defaultMessage: "People take customer complaint risk seriously"
        },
        "People_take_employee_safety_risk_seriously": {
            id: "generic.element.People_take_employee_safety_risk_seriously",
            defaultMessage: "People take employee safety risk seriously"
        },
        "People_take_food_safety_risk_seriously": {
            id: "generic.element.People_take_food_safety_risk_seriously",
            defaultMessage: "People take food safety risk seriously"
        },
        "People_understand_how_their_actions_affect_the_safety_of_other_employees": {
            id: "generic.element.People_understand_how_their_actions_affect_the_safety_of_other_employees",
            defaultMessage: "People understand how their actions affect the safety of other employees"
        },
        "People_understand_how_their_actions_affect_the_safety_of_other_employees_(S/M)": {
            id: "generic.element.People_understand_how_their_actions_affect_the_safety_of_other_employees_(S/M)",
            defaultMessage: "People understand how their actions affect the safety of other employees (S/M)"
        },
        "People_understand_how_their_job_affects_food_safety": {
            id: "generic.element.People_understand_how_their_job_affects_food_safety",
            defaultMessage: "People understand how their job affects food safety"
        },
        "People_understand_how_their_job_affects_food_safety_(S/M)": {
            id: "generic.element.People_understand_how_their_job_affects_food_safety_(S/M)",
            defaultMessage: "People understand how their job affects food safety (S/M)"
        },
        "People_understand_their_role_in_getting_things_right_first_time": {
            id: "generic.element.People_understand_their_role_in_getting_things_right_first_time",
            defaultMessage: "People understand their role in getting things right first time"
        },
        "People_understand_their_role_in_getting_things_right_first_time_(S/M)": {
            id: "generic.element.People_understand_their_role_in_getting_things_right_first_time_(S/M)",
            defaultMessage: "People understand their role in getting things right first time (S/M)"
        },
        "People_work_in_the_same_way_when_managers_are_not_present": {
            id: "generic.element.People_work_in_the_same_way_when_managers_are_not_present",
            defaultMessage: "People work in the same way when managers are not present"
        },
        "People_work_in_the_same_way_when_managers_are_not_present_(O/S)": {
            id: "generic.element.People_work_in_the_same_way_when_managers_are_not_present_(O/S)",
            defaultMessage: "People work in the same way when managers are not present (O/S)"
        },
        "People_would_recommend_the_company_to_others": {
            id: "generic.element.People_would_recommend_the_company_to_others",
            defaultMessage: "People would recommend the company to others"
        },
        "Perception_of_good_team_spirit": {
            id: "generic.element.Perception_of_good_team_spirit",
            defaultMessage: "Perception of good team spirit"
        },
        "Perception_of_personal_safety_in_the_working_environment": {
            id: "generic.element.Perception_of_personal_safety_in_the_working_environment",
            defaultMessage: "Perception of personal safety in the working environment"
        },
        "Perception_of_personal_safety_in_the_working_environment_(O/S)": {
            id: "generic.element.Perception_of_personal_safety_in_the_working_environment_(O/S)",
            defaultMessage: "Perception of personal safety in the working environment (O/S)"
        },
        "Perception_of_personal_safety_in_working_environment": {
            id: "generic.element.Perception_of_personal_safety_in_working_environment",
            defaultMessage: "Perception of personal safety in working environment"
        },
        "Perception_of_senior_managers_knowledge_of_what_goes_on_throughout_the_site": {
            id: "generic.element.Perception_of_senior_managers_knowledge_of_what_goes_on_throughout_the_site",
            defaultMessage: "Perception of senior managers knowledge of what goes on throughout the site"
        },
        "Perception_of_the_company_as_innovative": {
            id: "generic.element.Perception_of_the_company_as_innovative",
            defaultMessage: "Perception of the company as innovative"
        },
        "Perception_that_company_vision_is_realistic_(S/M)": {
            id: "generic.element.Perception_that_company_vision_is_realistic_(S/M)",
            defaultMessage: "Perception that company vision is realistic (S/M)"
        },
        "Perception_that_employees_respect_company_values_(S/M)": {
            id: "generic.element.Perception_that_employees_respect_company_values_(S/M)",
            defaultMessage: "Perception that employees respect company values (S/M)"
        },
        "Perception_that_managers_communicate_well": {
            id: "generic.element.Perception_that_managers_communicate_well",
            defaultMessage: "Perception that managers communicate well"
        },
        "Perception_that_managers_discipline_fairly": {
            id: "generic.element.Perception_that_managers_discipline_fairly",
            defaultMessage: "Perception that managers discipline fairly"
        },
        "Perception_that_managers_lead_by_example": {
            id: "generic.element.Perception_that_managers_lead_by_example",
            defaultMessage: "Perception that managers lead by example"
        },
        "Perception_that_managers_notice_good_work": {
            id: "generic.element.Perception_that_managers_notice_good_work",
            defaultMessage: "Perception that managers notice good work"
        },
        "Perception_that_managers_trust_and_value_people": {
            id: "generic.element.Perception_that_managers_trust_and_value_people",
            defaultMessage: "Perception that managers trust and value people"
        },
        "Perception_that_people_help_each_other": {
            id: "generic.element.Perception_that_people_help_each_other",
            defaultMessage: "Perception that people help each other"
        },
        "Perception_that_people_take_customer_complaint_risk_seriously": {
            id: "generic.element.Perception_that_people_take_customer_complaint_risk_seriously",
            defaultMessage: "Perception that people take customer complaint risk seriously"
        },
        "Perception_that_people_take_employee_safety_risk_seriously": {
            id: "generic.element.Perception_that_people_take_employee_safety_risk_seriously",
            defaultMessage: "Perception that people take employee safety risk seriously"
        },
        "Perception_that_people_take_food_safety_risk_seriously": {
            id: "generic.element.Perception_that_people_take_food_safety_risk_seriously",
            defaultMessage: "Perception that people take food safety risk seriously"
        },
        "Perception_that_senior_managers_ensure_company_values_are_implemented": {
            id: "generic.element.Perception_that_senior_managers_ensure_company_values_are_implemented",
            defaultMessage: "Perception that senior managers ensure company values are implemented"
        },
        "Perception_that_senior_managers_know_what_goes_on_throughout_the_site": {
            id: "generic.element.Perception_that_senior_managers_know_what_goes_on_throughout_the_site",
            defaultMessage: "Perception that senior managers know what goes on throughout the site"
        },
        "Perception_that_senior_managers_value_employee_health_and_safety_very_highly": {
            id: "generic.element.Perception_that_senior_managers_value_employee_health_and_safety_very_highly",
            defaultMessage: "Perception that senior managers value employee health and safety very highly"
        },
        "Perception_that_senior_managers_value_food_safety_very_highly": {
            id: "generic.element.Perception_that_senior_managers_value_food_safety_very_highly",
            defaultMessage: "Perception that senior managers value food safety very highly"
        },
        "Perception_that_senior_managers_value_quality_very_highly": {
            id: "generic.element.Perception_that_senior_managers_value_quality_very_highly",
            defaultMessage: "Perception that senior managers value quality very highly"
        },
        "Perception_that_site_is_innovative": {
            id: "generic.element.Perception_that_site_is_innovative",
            defaultMessage: "Perception that site is innovative"
        },
        "Perception_that_the_site_is_innovative": {
            id: "generic.element.Perception_that_the_site_is_innovative",
            defaultMessage: "Perception that the site is innovative"
        },
        "Personal_perception_that_food_safety_is_a_top_priority": {
            id: "generic.element.Personal_perception_that_food_safety_is_a_top_priority",
            defaultMessage: "Personal perception that food safety is a top priority"
        },
        "Position_of_food_safety_when_compared_to_other_company_priorities": {
            id: "generic.element.Position_of_food_safety_when_compared_to_other_company_priorities",
            defaultMessage: "Position of food safety when compared to other company priorities"
        },
        "Position_of_food_safety_when_compared_to_other_personal_priorities": {
            id: "generic.element.Position_of_food_safety_when_compared_to_other_personal_priorities",
            defaultMessage: "Position of food safety when compared to other personal priorities"
        },
        "Practical_impact_of_food_safety_training": {
            id: "generic.element.Practical_impact_of_food_safety_training",
            defaultMessage: "Practical impact of food safety training"
        },
        "Practical_impact_of_training": {
            id: "generic.element.Practical_impact_of_training",
            defaultMessage: "Practical impact of training"
        },
        "Praise_for_identification_of_potential_employee_health_and_safety_issue": {
            id: "generic.element.Praise_for_identification_of_potential_employee_health_and_safety_issue",
            defaultMessage: "Praise for identification of potential employee health and safety issue"
        },
        "Praise_for_identification_of_potential_food_safety_issue": {
            id: "generic.element.Praise_for_identification_of_potential_food_safety_issue",
            defaultMessage: "Praise for identification of potential food safety issue"
        },
        "Praise_for_identification_of_potential_product_quality_issue": {
            id: "generic.element.Praise_for_identification_of_potential_product_quality_issue",
            defaultMessage: "Praise for identification of potential product quality issue"
        },
        "Problems_are_not_repeated_because_people_learn_from_mistakes": {
            id: "generic.element.Problems_are_not_repeated_because_people_learn_from_mistakes",
            defaultMessage: "Problems are not repeated because people learn from mistakes"
        },
        "Production_targets_do_not_compromise_employee_health_and_safety": {
            id: "generic.element.Production_targets_do_not_compromise_employee_health_and_safety",
            defaultMessage: "Production targets do not compromise employee health and safety"
        },
        "Production_targets_do_not_compromise_employee_health_and_safety_(O/S)": {
            id: "generic.element.Production_targets_do_not_compromise_employee_health_and_safety_(O/S)",
            defaultMessage: "Production targets do not compromise employee health and safety (O/S)"
        },
        "Production_targets_do_not_compromise_food_safety": {
            id: "generic.element.Production_targets_do_not_compromise_food_safety",
            defaultMessage: "Production targets do not compromise food safety"
        },
        "Production_targets_do_not_compromise_food_safety_(O/S)": {
            id: "generic.element.Production_targets_do_not_compromise_food_safety_(O/S)",
            defaultMessage: "Production targets do not compromise food safety (O/S)"
        },
        "Production_targets_do_not_compromise_quality": {
            id: "generic.element.Production_targets_do_not_compromise_quality",
            defaultMessage: "Production targets do not compromise quality"
        },
        "Production_targets_do_not_compromise_quality_(O/S)": {
            id: "generic.element.Production_targets_do_not_compromise_quality_(O/S)",
            defaultMessage: "Production targets do not compromise quality (O/S)"
        },
        "Production_targets_not_compromising_employee_health_and_safety": {
            id: "generic.element.Production_targets_not_compromising_employee_health_and_safety",
            defaultMessage: "Production targets not compromising employee health and safety"
        },
        "Production_targets_not_compromising_food_safety": {
            id: "generic.element.Production_targets_not_compromising_food_safety",
            defaultMessage: "Production targets not compromising food safety"
        },
        "Production_targets_not_compromising_quality": {
            id: "generic.element.Production_targets_not_compromising_quality",
            defaultMessage: "Production targets not compromising quality"
        },
        "Promotions_and_rewards_are_issued_fairly": {
            id: "generic.element.Promotions_and_rewards_are_issued_fairly",
            defaultMessage: "Promotions and rewards are issued fairly"
        },
        "Promotions_and_rewards_being_allocated_fairly": {
            id: "generic.element.Promotions_and_rewards_being_allocated_fairly",
            defaultMessage: "Promotions and rewards being allocated fairly"
        },
        "Provision_of_clear_departmental_goals_and_targets": {
            id: "generic.element.Provision_of_clear_departmental_goals_and_targets",
            defaultMessage: "Provision of clear departmental goals and targets"
        },
        "Provision_of_clear_departmental_targets": {
            id: "generic.element.Provision_of_clear_departmental_targets",
            defaultMessage: "Provision of clear departmental targets"
        },
        "Provision_of_realistic_targets_(S/M)": {
            id: "generic.element.Provision_of_realistic_targets_(S/M)",
            defaultMessage: "Provision of realistic targets (S/M)"
        },
        "Provision_of_sufficient_budget_(S/M)": {
            id: "generic.element.Provision_of_sufficient_budget_(S/M)",
            defaultMessage: "Provision of sufficient budget (S/M)"
        },
        "Quality_excellence_being_part_of_company_vision": {
            id: "generic.element.Quality_excellence_being_part_of_company_vision",
            defaultMessage: "Quality excellence being part of company vision"
        },
        "Quality_excellence_is_part_of_the_company_vision": {
            id: "generic.element.Quality_excellence_is_part_of_the_company_vision",
            defaultMessage: "Quality excellence is part of the company vision"
        },
        "Quality_excellence_is_part_of_the_company_vision_(M)": {
            id: "generic.element.Quality_excellence_is_part_of_the_company_vision_(M)",
            defaultMessage: "Quality excellence is part of the company vision (M)"
        },
        "Quality_excellence_is_part_of_the_company_vision_(S/M)": {
            id: "generic.element.Quality_excellence_is_part_of_the_company_vision_(S/M)",
            defaultMessage: "Quality excellence is part of the company vision (S/M)"
        },
        "Quality_standards_are_maintained_regardless_of_external_visit_or_audit": {
            id: "generic.element.Quality_standards_are_maintained_regardless_of_external_visit_or_audit",
            defaultMessage: "Quality standards are maintained regardless of external visit or audit"
        },
        "Quality_standards_are_maintained_regardless_of_external_visit_or_audit_(S/M)": {
            id: "generic.element.Quality_standards_are_maintained_regardless_of_external_visit_or_audit_(S/M)",
            defaultMessage: "Quality standards are maintained regardless of external visit or audit (S/M)"
        },
        "Quality_team_is_respected_(S/M)": {
            id: "generic.element.Quality_team_is_respected_(S/M)",
            defaultMessage: "Quality team is respected (S/M)"
        },
        "Realistic_departmental_targets_are_provided_(M)": {
            id: "generic.element.Realistic_departmental_targets_are_provided_(M)",
            defaultMessage: "Realistic departmental targets are provided (M)"
        },
        "Realistic_departmental_targets_are_provided_(S/M)": {
            id: "generic.element.Realistic_departmental_targets_are_provided_(S/M)",
            defaultMessage: "Realistic departmental targets are provided (S/M)"
        },
        "Realistic_instructions_so_procedures_can_be_followed": {
            id: "generic.element.Realistic_instructions_so_procedures_can_be_followed",
            defaultMessage: "Realistic instructions so procedures can be followed"
        },
        "Relevance_of_food_safety_training": {
            id: "generic.element.Relevance_of_food_safety_training",
            defaultMessage: "Relevance of food safety training"
        },
        "Relevance_of_training": {
            id: "generic.element.Relevance_of_training",
            defaultMessage: "Relevance of training"
        },
        "Risks_and_weaknesses_actively_looked_for_(M)": {
            id: "generic.element.Risks_and_weaknesses_actively_looked_for_(M)",
            defaultMessage: "Risks and weaknesses actively looked for (M)"
        },
        "Risks_and_weaknesses_are_actively_looked_for_(M)": {
            id: "generic.element.Risks_and_weaknesses_are_actively_looked_for_(M)",
            defaultMessage: "Risks and weaknesses are actively looked for (M)"
        },
        "Roles_and_responsibilities_are_clearly_defined_(M)": {
            id: "generic.element.Roles_and_responsibilities_are_clearly_defined_(M)",
            defaultMessage: "Roles and responsibilities are clearly defined (M)"
        },
        "Roles_and_responsibilities_are_clearly_defined_(S/M)": {
            id: "generic.element.Roles_and_responsibilities_are_clearly_defined_(S/M)",
            defaultMessage: "Roles and responsibilities are clearly defined (S/M)"
        },
        "Salaries_are_fair": {
            id: "generic.element.Salaries_are_fair",
            defaultMessage: "Salaries are fair"
        },
        "Seeking_customer_feedback_to_improve_products_(M)": {
            id: "generic.element.Seeking_customer_feedback_to_improve_products_(M)",
            defaultMessage: "Seeking customer feedback to improve products (M)"
        },
        "Space_is_sufficient_to_work_comfortably_and_safely": {
            id: "generic.element.Space_is_sufficient_to_work_comfortably_and_safely",
            defaultMessage: "Space is sufficient to work comfortably and safely"
        },
        "Space_is_sufficient_to_work_comfortably_and_safely_(O/S)": {
            id: "generic.element.Space_is_sufficient_to_work_comfortably_and_safely_(O/S)",
            defaultMessage: "Space is sufficient to work comfortably and safely (O/S)"
        },
        "Successful_achievement_of_departmental_goals_and_targets": {
            id: "generic.element.Successful_achievement_of_departmental_goals_and_targets",
            defaultMessage: "Successful achievement of departmental goals and targets"
        },
        "Sufficient_budget_is_provided_(M)": {
            id: "generic.element.Sufficient_budget_is_provided_(M)",
            defaultMessage: "Sufficient budget is provided (M)"
        },
        "Sufficient_budget_is_provided_(S/M)": {
            id: "generic.element.Sufficient_budget_is_provided_(S/M)",
            defaultMessage: "Sufficient budget is provided (S/M)"
        },
        "Sufficient_skilled_employees_are_hired": {
            id: "generic.element.Sufficient_skilled_employees_are_hired",
            defaultMessage: "Sufficient skilled employees are hired"
        },
        "Sufficient_skilled_employees_hired": {
            id: "generic.element.Sufficient_skilled_employees_hired",
            defaultMessage: "Sufficient skilled employees hired"
        },
        "Suggestions_and_ideas_are_encouraged_from_employees": {
            id: "generic.element.Suggestions_and_ideas_are_encouraged_from_employees",
            defaultMessage: "Suggestions and ideas are encouraged from employees"
        },
        "Suggestions_and_ideas_encouraged_from_employees": {
            id: "generic.element.Suggestions_and_ideas_encouraged_from_employees",
            defaultMessage: "Suggestions and ideas encouraged from employees"
        },
        "Suppliers_are_chosen_for_quality_before_price_(M)": {
            id: "generic.element.Suppliers_are_chosen_for_quality_before_price_(M)",
            defaultMessage: "Suppliers are chosen for quality before price (M)"
        },
        "Suppliers_being_chosen_for_quality_before_price_(M)": {
            id: "generic.element.Suppliers_being_chosen_for_quality_before_price_(M)",
            defaultMessage: "Suppliers being chosen for quality before price (M)"
        },
        "Suppliers_being_chosen_for_safety_before_price": {
            id: "generic.element.Suppliers_being_chosen_for_safety_before_price",
            defaultMessage: "Suppliers being chosen for safety before price"
        },
        "Temporary_employees_work_in_the_same_way_as_full_time_employees": {
            id: "generic.element.Temporary_employees_work_in_the_same_way_as_full_time_employees",
            defaultMessage: "Temporary employees work in the same way as full time employees"
        },
        "Temporary_employees_working_in_the_same_way_as_full_time_employees": {
            id: "generic.element.Temporary_employees_working_in_the_same_way_as_full_time_employees",
            defaultMessage: "Temporary employees working in the same way as full time employees"
        },
        "The_company_cares_about_the_health_of_their_employees": {
            id: "generic.element.The_company_cares_about_the_health_of_their_employees",
            defaultMessage: "The company cares about the health of their employees"
        },
        "The_company_is_socially_and_environmentally_responsible": {
            id: "generic.element.The_company_is_socially_and_environmentally_responsible",
            defaultMessage: "The company is socially and environmentally responsible"
        },
        "The_company_promotes_a_good_balance_between_work_and_personal_life": {
            id: "generic.element.The_company_promotes_a_good_balance_between_work_and_personal_life",
            defaultMessage: "The company promotes a good balance between work and personal life"
        },
        "The_company_responded_well_to_Covid": {
            id: "generic.element.The_company_responded_well_to_Covid",
            defaultMessage: "The company responded well to Covid"
        },
        "The_company_values_equality_and_diversity": {
            id: "generic.element.The_company_values_equality_and_diversity",
            defaultMessage: "The company values equality and diversity"
        },
        "The_employee_health_and_safety_team_is_respected_(S/M)": {
            id: "generic.element.The_employee_health_and_safety_team_is_respected_(S/M)",
            defaultMessage: "The employee health and safety team is respected (S/M)"
        },
        "The_food_safety_team_is_respected_(S/M)": {
            id: "generic.element.The_food_safety_team_is_respected_(S/M)",
            defaultMessage: "The food safety team is respected (S/M)"
        },
        "The_quality_team_is_respected_(S/M)": {
            id: "generic.element.The_quality_team_is_respected_(S/M)",
            defaultMessage: "The quality team is respected (S/M)"
        },
        "There_are_good_opportunities_to_develop_knowledge_and_skills": {
            id: "generic.element.There_are_good_opportunities_to_develop_knowledge_and_skills",
            defaultMessage: "There are good opportunities to develop knowledge and skills"
        },
        "There_is_a_good_team_spirit": {
            id: "generic.element.There_is_a_good_team_spirit",
            defaultMessage: "There is a good team spirit"
        },
        "There_is_a_manageable_amount_of_paperwork": {
            id: "generic.element.There_is_a_manageable_amount_of_paperwork",
            defaultMessage: "There is a manageable amount of paperwork"
        },
        "There_is_a_manageable_amount_of_paperwork_(O/S)": {
            id: "generic.element.There_is_a_manageable_amount_of_paperwork_(O/S)",
            defaultMessage: "There is a manageable amount of paperwork (O/S)"
        },
        "There_is_an_appropriate_amount_of_employee_health_and_safety_documents_and_records_(S/M)": {
            id: "generic.element.There_is_an_appropriate_amount_of_employee_health_and_safety_documents_and_records_(S/M)",
            defaultMessage: "There is an appropriate amount of employee health and safety documents and records (S/M)"
        },
        "There_is_an_appropriate_amount_of_employee_health_and_safety_paperwork_(S/M)": {
            id: "generic.element.There_is_an_appropriate_amount_of_employee_health_and_safety_paperwork_(S/M)",
            defaultMessage: "There is an appropriate amount of employee health and safety paperwork (S/M)"
        },
        "There_is_an_appropriate_amount_of_food_safety_documents_and_records_(S/M)": {
            id: "generic.element.There_is_an_appropriate_amount_of_food_safety_documents_and_records_(S/M)",
            defaultMessage: "There is an appropriate amount of food safety documents and records (S/M)"
        },
        "There_is_an_appropriate_amount_of_food_safety_paperwork_(S/M)": {
            id: "generic.element.There_is_an_appropriate_amount_of_food_safety_paperwork_(S/M)",
            defaultMessage: "There is an appropriate amount of food safety paperwork (S/M)"
        },
        "There_is_an_appropriate_amount_of_quality_documents_and_records_(S/M)": {
            id: "generic.element.There_is_an_appropriate_amount_of_quality_documents_and_records_(S/M)",
            defaultMessage: "There is an appropriate amount of quality documents and records (S/M)"
        },
        "There_is_an_appropriate_amount_of_quality_paperwork_(S/M)": {
            id: "generic.element.There_is_an_appropriate_amount_of_quality_paperwork_(S/M)",
            defaultMessage: "There is an appropriate amount of quality paperwork (S/M)"
        },
        "There_is_enough_time_to_work_correctly_and_safely": {
            id: "generic.element.There_is_enough_time_to_work_correctly_and_safely",
            defaultMessage: "There is enough time to work correctly and safely"
        },
        "Trust_in_employees_to_work_correctly_without_supervision_(S/M)": {
            id: "generic.element.Trust_in_employees_to_work_correctly_without_supervision_(S/M)",
            defaultMessage: "Trust in employees to work correctly without supervision (S/M)"
        },
        "Understanding_how_an_individual_role_affects_food_safety": {
            id: "generic.element.Understanding_how_an_individual_role_affects_food_safety",
            defaultMessage: "Understanding how an individual role affects food safety"
        },
        "Understanding_how_an_individual_role_affects_quality": {
            id: "generic.element.Understanding_how_an_individual_role_affects_quality",
            defaultMessage: "Understanding how an individual role affects quality"
        },
        "Understanding_how_an_individual_role_affects_safety_of_other_employees": {
            id: "generic.element.Understanding_how_an_individual_role_affects_safety_of_other_employees",
            defaultMessage: "Understanding how an individual role affects safety of other employees"
        },
        "Unhygienic_behaviour_challenged": {
            id: "generic.element.Unhygienic_behaviour_challenged",
            defaultMessage: "Unhygienic behaviour challenged"
        },
        "Unsafe_behaviour_challenged": {
            id: "generic.element.Unsafe_behaviour_challenged",
            defaultMessage: "Unsafe behaviour challenged"
        },
        "Up-to-date_IT_equipment_and_software_(S/M)": {
            id: "generic.element.Up-to-date_IT_equipment_and_software_(S/M)",
            defaultMessage: "Up-to-date IT equipment and software (S/M)"
        },
        "Using_data_to_analyse_trends_and_predict_risks_(M)": {
            id: "generic.element.Using_data_to_analyse_trends_and_predict_risks_(M)",
            defaultMessage: "Using data to analyse trends and predict risks (M)"
        },
        "Using_data_to_drive_continual_improvement_(M)": {
            id: "generic.element.Using_data_to_drive_continual_improvement_(M)",
            defaultMessage: "Using data to drive continual improvement (M)"
        },
        "Valuing_the_food_safety_team": {
            id: "generic.element.Valuing_the_food_safety_team",
            defaultMessage: "Valuing the food safety team"
        },
        "Work_does_not_cause_people_stress": {
            id: "generic.element.Work_does_not_cause_people_stress",
            defaultMessage: "Work does not cause people stress"
        },
        "Working_better_as_a_team_than_individually": {
            id: "generic.element.Working_better_as_a_team_than_individually",
            defaultMessage: "Working better as a team than individually"
        },
        "Working_in_the_same_way_to_ensure_consistency": {
            id: "generic.element.Working_in_the_same_way_to_ensure_consistency",
            defaultMessage: "Working in the same way to ensure consistency"
        },
        "Working_in_the_same_way_when_manager_not_present": {
            id: "generic.element.Working_in_the_same_way_when_manager_not_present",
            defaultMessage: "Working in the same way when manager not present"
        }
    });

    const alias = props.element ? props.element.replace(/ /g, '_') : null;

    if (props.element && alias in messages) {
        return <span>{intl.formatMessage(messages[alias])}</span>
    } else {
        console.log('Missing element translation:', alias);
        return <span>{props.element}</span>
    }
}
