export const regionTranslated = (region, intl) => {
    switch (region) {
        case 'Africa':
            return intl.formatMessage({id: 'region.Africa', defaultMessage: 'Africa'});

        case 'Arab States':
            return intl.formatMessage({id: 'region.ArabStates', defaultMessage: 'Arab States'});

        case 'Asia and Pacific':
            return intl.formatMessage({id: 'region.AsiaAndPacific', defaultMessage: 'Asia and Pacific'});

        case 'Europe':
            return intl.formatMessage({id: 'region.Europe', defaultMessage: 'Europe'});

        case 'Middle East':
            return intl.formatMessage({id: 'region.MiddleEast', defaultMessage: 'Middle East'});

        case 'North America':
            return intl.formatMessage({id: 'region.NorthAmerica', defaultMessage: 'North America'});

        case 'South / Latin America':
            return intl.formatMessage({id: 'region.SouthLatinAmerica', defaultMessage: 'South / Latin America'});

        default:
            // Unknown region
            return region;
    }
}
