export const typeTranslated = (type, intl) => {
    if (!type) {
        return null;
    }

    const labels = {
        'general': intl.formatMessage({id: 'filterMenu.typeGeneral', defaultMessage: 'General'}),
        'foodsafety': intl.formatMessage({id: 'filterMenu.typeFoodSafety', defaultMessage: 'Food Safety'}),
        'quality': intl.formatMessage({id: 'filterMenu.typeQuality', defaultMessage: 'Quality'}),
        'hs': intl.formatMessage({id: 'filterMenu.typeHealthAndSafety', defaultMessage: 'Health & Safety'}),
        'environment': intl.formatMessage({id: 'filterMenu.typeEnvironment', defaultMessage: 'Environment'}),
        'productsafety': intl.formatMessage({id: 'filterMenu.typeProductSafety', defaultMessage: 'Product Safety'}),
        'dei': intl.formatMessage({id: 'filterMenu.typeDei', defaultMessage: 'DEI'}),
    };

    if (type.toLowerCase() in labels) {
        return labels[type.toLowerCase()];
    }

    return type;
}