export const DEMOGRAPHIC_TYPES = {
    lengthofservice: {
        title: 'Length of Service',
        options: [
            'Less than a year',
            '1 - 5 years',
            'More than 5 years',
            'More than 10 years',
        ],
    },
    role: {
        title: 'Role',
        options: [
            'Manager / Senior Manager',
            'Supervisor / Team Leader',
            'Operator / Team Member / Employee',
            'Agency / Temporary Worker',
        ],
    },
    department: {
        title: 'Department',
        options: [
            'Production / Processing / Manufacturing',
            'Administration / Support',
            'Logistics / Dispatch / Warehouse',
            'Engineering / Maintenance / Sanitation',
            'Technical / Safety / Quality',
            'Other',
        ],
    },
    bu: {
        title: 'Business Unit',
        options: [],
    },
    region: {
        title: 'Region',
        options: [],
    },
    country: {
        title: 'Country',
        options: [],
    },
    custom: {
        title: 'Custom',
        options: []
    }
};