import { createTheme } from '@material-ui/core/styles';

const white = '#ffffff';

// Default theme (Culture Excellence)
const theme = createTheme({
    typography: {
        subtitle1: {
            fontWeight: 500
        }
    },
    palette: {
        primary: {
            main: '#3c3e40',
        },
        secondary: {
            main: '#e41c4c',
        },
        text: {
            primary: 'rgba(0, 0, 0, 0.87)',
        },
        background: {
            default: white,
            paper: white,
        },
        // Custom palette colors
        people: {
            main: 'rgb(142, 60, 151)',
            light: 'rgba(142, 60, 151, 0.1)'
        },
        process: {
            main: 'rgb(8, 187, 214)',
            light: 'rgba(8, 187, 214, 0.1)'
        },
        purpose: {
            main: 'rgb(228, 28, 76)',
            light: 'rgba(228, 28, 76, 0.1)',
        },
        proactivity: {
            main: 'rgb(140, 196, 63)',
            light: 'rgba(140, 196, 63, 0.1)'
        }
    },
    overrides: {
        MuiSnackbarContent: {
            root: {
                fontSize: "10pt",
            }
        },
        MuiTooltip: {
            tooltip: {
                fontSize: "0.75rem",
                fontWeight: 400,
            }
        },
        MuiTableRow: {
            root: {
                "&:last-child td": {
                    borderBottom: 0,
                },
            }
        },
        MUIDataTableToolbar: {
            root: {
                paddingLeft: '16px',
                paddingRight: '16px',
            }
        },
        MuiTableSortLabel: {
            root: {
                '& $icon': {
                    opacity: 0.15,
                },
            }
        },
        // MUIDataTableBodyRow: {
        //     root: {
        //         '&:nth-child(odd)': {
        //             backgroundColor: '#F8F8F8'
        //         }
        //     }
        // },
        // MUIDataTableHeadCell: {
        //     fixedHeader: {
        //         background: 'red'
        //     }
        // },
        // MUIDataTableBodyCell: {
        //     root: {
        //         backgroundColor: "#FF0000"
        //     }
        // }
    }
  });
export default theme;