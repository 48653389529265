import { Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, FormControlLabel, Grid, InputAdornment, makeStyles, TextField, Tooltip, Typography } from "@material-ui/core";
import { SURVEY_LANGUAGES } from '../../Constants/SurveyLanguages';
import { industryTypeTranslated } from "../Translation/IndustryType";
import { COUNTRIES } from '../../Constants/Countries';
import { Fragment, useContext, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { SpinnerContext } from "../../Context/SpinnerContext";
import { NotificationContext } from "../../Context/NotificationContext";
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { countryTranslated } from "../Translation/Country";
import { languageTranslated } from "../Translation/Language";
import SurveyService from "../../Services/SurveyService";
import { Redirect } from "react-router-dom";
import Autocomplete, { createFilterOptions } from "@material-ui/lab/Autocomplete";
import { brcgsIndustries } from "../../Utilities/Industry";

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
}));

const filter = createFilterOptions();

export default function BrcgsRegister(props) {
    const intl = useIntl();
    const classes = useStyles();
    const { updateLoading } = useContext(SpinnerContext);
    const { updateNotification } = useContext(NotificationContext);
    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
    const [confirmCheck, setConfirmCheck] = useState(false);
    const [redirect, setRedirect] = useState(null);
    const [siteCodes, setSiteCodes] = useState({});
    const [companyNameReadOnly, setCompanyNameReadOnly] = useState(false);
    const [siteNameReadOnly, setSiteNameReadOnly] = useState(false);
    const [companies, setCompanies] = useState([]);
    const [companyError, setCompanyError] = useState(null);
    const [targetError, setTargetError] = useState(null);

    const [selectedCompany, setSelectedCompany] = useState(null);
    const [selectedSite, setSelectedSite] = useState(null);

    const [values, setValues] = useState({
        siteCode: '',
        companyId: null,
        companyName: null,
        siteId: null,
        siteName: '',
        siteCountry: '',
        contactName: '',
        contactEmail: '',
        productCategory: '',
        defaultSurveyLanguage: 'en',
        locale: 'en',
        roleTargets: {
            'Manager / Senior Manager': '',
            'Supervisor / Team Leader': '',
            'Operator / Operative': '',
        }
    });

    const roles = [
        {value: 'Manager / Senior Manager', label: intl.formatMessage({id: 'role.managerOrSeniorManager', defaultMessage: 'Manager / Senior Manager'})},
        {value: 'Supervisor / Team Leader', label: intl.formatMessage({id: 'role.supervisorOrTeamLeader', defaultMessage: 'Supervisor / Team Leader'})},
        {value: 'Operator / Operative', label: intl.formatMessage({id: 'role.operatorOrOperative', defaultMessage: 'Operator / Operative'})},
    ];

    const countries = [];

    for (var key in COUNTRIES) {
        countries.push({ label: countryTranslated(COUNTRIES[key], intl), value: key });
    }

    const sumOfTargets = () => {
        return Object.keys(values.roleTargets).reduce((acc, currentRole) => acc+parseInt(values.roleTargets[currentRole]), 0);
    };

    //Sort countries by name
    countries.sort((a, b) => (a.label > b.label ? 1 : -1));

    useEffect(() => {
        updateLoading(true);
        SurveyService.allSurveysByHash().then(response => {
            const companies = {};
            const siteCodes = {};
            response.forEach((currentSurvey) => {
                if (currentSurvey.attributes.type === 'brcgs' &&
                    currentSurvey.relationships.sites &&
                    currentSurvey.relationships.sites.length === 1 &&
                    currentSurvey.relationships.company
                ) {
                    const currentSite = currentSurvey.relationships.sites[0];
                    const currentCompany = currentSurvey.relationships.company;
                    siteCodes[currentSite.data.code] = {
                        siteName: currentSite.data.name,
                        siteId: currentSite.id,
                        companyName: currentCompany.data.name,
                        companyId: currentCompany.id,
                        industry: currentSite.data.industry,
                        siteCountry: currentSite.data.countryCode
                    };

                    if (!(currentCompany.id in companies)) {
                        companies[currentCompany.id] = {
                            name: currentCompany.data.name,
                            id: currentCompany.id,
                            sites: {}
                        };
                    }

                    // Add sites to company
                    if (!(currentSite.id in companies[currentCompany.id].sites)) {
                        companies[currentCompany.id].sites[currentSite.id] = {
                            name: currentSite.data.name,
                            id: currentSite.id,
                            industry: currentSite.data.industry,
                            countryCode: currentSite.data.countryCode,
                            roleTargets: currentSite.data.roleTargets
                        }
                    }
                }
            });

            // Turn into arays instead of object indexed by company/site id
            const userCompanies = Object.keys(companies).map(compId => {
                const compSites = Object.keys(companies[compId].sites).map(siteId => companies[compId].sites[siteId]);
                companies[compId].sites = compSites;
                return companies[compId];
            });
            setCompanies(userCompanies);
            setSiteCodes(siteCodes);
        }).catch(error => {
            console.log(error);
        }).then(() => {
            updateLoading(false);
        });
        // eslint-disable-next-line
    }, []);

    const handleChange = name => event => {
        if (name === 'siteCode') {
            if (event.target.value in siteCodes) {
                const company = companies.find(c => c.id === siteCodes[event.target.value].companyId);
                setSelectedCompany(company);
                const site = company.sites.find(s => s.id === siteCodes[event.target.value].siteId);
                setSelectedSite(site);
                setValues(previous => ({
                    ...previous,
                    siteCode: event.target.value,
                    siteName: siteCodes[event.target.value].siteName,
                    productCategory: siteCodes[event.target.value].industry ? siteCodes[event.target.value].industry : previous.industry,
                    siteCountry: siteCodes[event.target.value].siteCountry ? siteCodes[event.target.value].siteCountry : previous.siteCountry,
                    // Take targets from site if it has the same roles defined as in values.roleTargets
                    roleTargets: (site.roleTargets && JSON.stringify(Object.keys(values.roleTargets).sort()) === JSON.stringify(Object.keys(site.roleTargets).sort())) ? site.roleTargets : values.roleTargets
                }));
                setCompanyNameReadOnly(true);
                setSiteNameReadOnly(true);
            } else {
                setCompanyNameReadOnly(false);
                setSiteNameReadOnly(false);
                setValues(previous => ({...previous, siteCode: event.target.value}))
            }
        } else {
            // Replace is due to alchemer bug (their API replaces ’ with ')
            setValues({ ...values, [name]: event.target.value.replace("’", "'").replace("‘", "'").replace("“", '"').replace("”", '"')});
        }
    };

    const handleRoleTargetChange = role => event => {
        setTargetError(null);
        setValues(prevValues => ({
            ...prevValues,
            roleTargets: ({
                ...prevValues.roleTargets,
                [role]: event.target.value
            })
        }));
    };

    const confirmSubmit = (event) => {
        event.preventDefault();

        if (selectedCompany === null) {
            setCompanyError('Please select an existing company or create a new one');
        } else if (sumOfTargets() < 6) {
            setTargetError('To preserve anonymity, the minimum number of employees must total 6 or more.');
        } else {
            setConfirmDialogOpen(true);
        }

    };

    const handleSubmit = () => {
        // To avoid multiple submits
        if (!confirmDialogOpen) {
            return;
        }
        setConfirmDialogOpen(false);
        // updateLoading(true);

        const valuesCopy = { ...values };

        if (selectedCompany && selectedCompany.id) {
            valuesCopy.companyId = selectedCompany.id;
            valuesCopy.companyName = null;
        } else {
            valuesCopy.companyName = selectedCompany.name;
        }

        if (selectedSite && selectedSite.id) {
            valuesCopy.siteId = selectedSite.id;
            valuesCopy.siteName = null;
        }

        // Remove spaces from start/end of email address
        valuesCopy.contactEmail = valuesCopy.contactEmail.trim();

        SurveyService.register('brcgs', valuesCopy)
        .then(function (response) {
            updateNotification(true, intl.formatMessage({id: 'brcgsRegister.registerSuccess', defaultMessage: 'Survey registered'}), 'success');
            setTimeout(() => {updateNotification(false, '', 'info'); setRedirect('/surveys?brcgsRegisterNotice=1')}, 2000);
        }).catch(function (error) {
            if (error.response && error.response.status) {
                if (422 === error.response.status) {
                    console.log(error.response.data);
                    updateNotification(true, intl.formatMessage({id: 'brcgsRegister.siteHasSurveyInProgress', defaultMessage: 'A site with these details already has a survey in progress.'}), 'warning');
                } else if (400 === error.response.status) {
                    if (-1 !== error.response.data.error.detail.indexOf('already exists but is not enabled')) {
                        updateNotification(true,
                            <span>User with email <strong>{valuesCopy.contactEmail}</strong> already exists but is not enabled, please contact <a href="mailto:enquiries@culturexcellence.com">enquiries@culturexcellence.com</a> for details</span>,
                            'warning');
                    } else {
                        updateNotification(true,
                            <FormattedMessage
                                id="brcgsRegister.unableToRegister"
                                defaultMessage="Unable to register the site at this time, please contact <a>enquiries@culturexcellence.com</a> for details"
                                values = {{
                                    a: chunks => <a href="mailto:enquiries@culturexcellence.com">{chunks}</a>,
                                }}
                            />,
                            'warning');
                    }
                } else {
                    updateNotification(true, intl.formatMessage({id: 'generic.unknownError', defaultMessage: 'An unknown error occurred!'}), 'error');
                }
            } else {
                updateNotification(true, intl.formatMessage({id: 'generic.unknownError', defaultMessage: 'An unknown error occurred!'}), 'error');
            }
            console.error(error);
        }).then(function () {
            updateLoading(false);
        });
    };

    if (redirect) {
        return (<Redirect to={redirect} />);
    }

    return (
        <Grid container spacing={0} justifyContent="center">
            <Grid item sm={10} md={5} lg={4}>
                <Fragment>
                    <Typography variant="h6" paragraph>
                        <FormattedMessage id="brcgsRegister.header" defaultMessage="BRCGS Food Safety Culture Excellence Registration" />
                    </Typography>
                    <Typography variant="body1" paragraph>
                        <FormattedMessage id="brcgsRegister.instructions" defaultMessage="Please complete the registration form (all fields are mandatory)." />
                    </Typography>
                    <form className={classes.container} onSubmit={confirmSubmit}>
                        <Box pb={1} style={{width: '100%'}}>
                            <TextField
                                id="siteCode"
                                fullWidth
                                required
                                type="number"
                                InputProps={{
                                    inputProps: {
                                        step: 1,
                                        min: 0,
                                        pattern: "[0-9]+" // only allow whole numbers, no - or .
                                    },
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <Tooltip title={intl.formatMessage({id: 'brcgsRegister.brcgsSiteCodeTooltip', defaultMessage: 'If your site is not yet registered, please contact support@brcgs.com'})} placement="right">
                                                <HelpOutlineIcon />
                                            </Tooltip>
                                        </InputAdornment>
                                    )
                                }}
                                label={intl.formatMessage({id: 'signup.brcgsSiteCode', defaultMessage: 'BRCGS site code'})}
                                value={values.siteCode}
                                onChange={handleChange('siteCode')}
                                margin="normal"
                            />
                            <Autocomplete
                                fullWidth
                                freeSolo
                                disabled={companyNameReadOnly}
                                value={selectedCompany}
                                options={companies}
                                clearOnBlur={true}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        error={companyError !== null}
                                        label="Company *"
                                        margin="normal"
                                        helperText={companyNameReadOnly ? 'Company name taken from a previous registration of the specified BRCGS site code' : (companyError ? companyError : null)}
                                    />
                                )}
                                filterOptions={(options, params) => {
                                    const filtered = filter(options, params);

                                    // Suggest the creation of a new value
                                    if (params.inputValue !== "") {
                                        filtered.push({
                                            id: null,
                                            input: params.inputValue,
                                            name: params.inputValue
                                        });
                                    }
                                    return filtered;
                                }}
                                getOptionLabel={(option) => (option.name)}
                                renderOption={(option) => (('input' in option) ? 'New company: '+option.input : option.name)}
                                onChange={(event, newValue) => {
                                    if (typeof newValue === 'string') {
                                        setSelectedCompany({
                                            name: newValue,
                                            id: null
                                        });
                                    } else if (newValue && newValue.inputValue) {
                                        // Create new company
                                        setSelectedCompany({
                                            name: newValue.inputValue,
                                            id: null
                                        });
                                    } else {
                                        setSelectedCompany(newValue);
                                    }
                                    setSelectedSite(null);
                                    setCompanyError(null);
                                }}
                            />
                            <TextField
                                id="siteName"
                                disabled={siteNameReadOnly}
                                fullWidth
                                required
                                label={intl.formatMessage({id: 'signup.siteName', defaultMessage: 'Site name'})}
                                value={values.siteName}
                                onChange={handleChange('siteName')}
                                margin="normal"
                                helperText={siteNameReadOnly ? 'Site name taken from a previous registration of the specified BRCGS site code' :  intl.formatMessage({id: 'brcgsRegister.siteNameHelp', defaultMessage: 'Please use a unique name for the site that will be recognised by the employees who will take the survey. For example: London'})}
                            />
                            {(siteNameReadOnly || companyNameReadOnly) ? <Typography variant="body2">If you wish to change the company or site name for the given BRCGS site code, please contact <a href="mailto:enquiries@culturexcellence.com">enquiries@culturexcellence.com</a>.</Typography> : null}
                            <TextField
                                id="siteCountry"
                                fullWidth
                                select
                                required
                                label={intl.formatMessage({id: 'signup.siteCountry', defaultMessage: 'Site country'})}
                                value={values.siteCountry}
                                onChange={handleChange('siteCountry')}
                                SelectProps={{
                                    native: true
                                }}
                                margin="normal"
                            >
                                <option value="" disabled></option>
                                {countries.map(option => (
                                    <option key={option.value} value={option.value} >
                                        {option.label}
                                    </option>
                                ))}
                            </TextField>
                            <TextField
                                style={{marginTop: '4px'}}
                                id="contactName"
                                fullWidth
                                required
                                label={intl.formatMessage({id: 'brcgsRegister.siteContactName', defaultMessage: 'Site contact name'})}
                                value={values.contactName}
                                onChange={handleChange('contactName')}
                                margin="normal"
                            />
                            <TextField
                                id="contactEmail"
                                dir="ltr"
                                fullWidth
                                required
                                label={intl.formatMessage({id: 'brcgsRegister.siteContactEmail', defaultMessage: 'Site contact email'})}
                                type="email"
                                value={values.contactEmail}
                                onChange={handleChange('contactEmail')}
                                margin="normal"
                            />
                            <TextField
                                id="productCategory"
                                fullWidth
                                select
                                required
                                label={intl.formatMessage({id: 'signup.productCategory', defaultMessage: 'Product category'})}
                                value={values.productCategory}
                                onChange={handleChange('productCategory')}
                                SelectProps={{
                                    native: true,
                                }}
                                helperText={intl.formatMessage({id: 'brcgsRegister.productCategoryHelp', defaultMessage: 'Please select the primary BRCGS Product Category. If the site covers more than one Product Category, please select the main category.'})}
                                margin="normal"
                            >
                                <option value="" disabled></option>
                                {brcgsIndustries().map(option => (
                                    <option key={option} value={option}>
                                        {industryTypeTranslated(option, intl)}
                                    </option>
                                ))}
                            </TextField>
                            <TextField
                                id="defaultSurveyLanguage"
                                fullWidth
                                select
                                value={values.defaultSurveyLanguage}
                                onChange={handleChange('defaultSurveyLanguage')}
                                SelectProps={{
                                    native: true
                                }}
                                helperText={intl.formatMessage({id: 'brcgsRegister.defaultSurveyLanguageHelp', defaultMessage: 'Please specify the default survey language. If the customer did not specify a default language, leave this field set to English.'})}
                                margin="normal"
                            >
                                {Object.keys(SURVEY_LANGUAGES).map(loc => (
                                    <option key={loc} value={loc}>
                                        {languageTranslated(SURVEY_LANGUAGES[loc], intl)}
                                    </option>
                                ))}
                            </TextField>
                            <Box pt={3}>
                                <Typography variant="body1">
                                    <FormattedMessage id="brcgsRegister.targetNote" defaultMessage="Enter the total numbers for the site (the minimum targets will be calculated automatically)." />
                                </Typography>
                            </Box>
                            {roles.map((role, index) => (
                                <TextField
                                    key={role.value}
                                    error={targetError !== null}
                                    helperText={targetError !== null && roles.length === index+1 ? targetError : null}
                                    required
                                    type="number"
                                    InputProps={{
                                        inputProps: {
                                            step: 1,
                                            min: 0,
                                        }
                                    }}
                                    fullWidth
                                    label={role.label}
                                    value={values.roleTargets[role.value]}
                                    onChange={handleRoleTargetChange(role.value)}
                                    margin="normal"
                                />
                            ))}
                        </Box>
                        <FormControl fullWidth style={{paddingTop: 20, paddingBottom: 20}}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={confirmCheck}
                                        onChange={() => setConfirmCheck(!confirmCheck)}
                                        color="secondary"
                                        required
                                    />
                                }
                                label="Please confirm a customer representative approved the use of the above data (approval should have been provided during the customer's application)."
                            />
                        </FormControl>

                        <Button
                            variant="contained"
                            className="submit"
                            color="secondary"
                            type="submit"
                        >
                            <FormattedMessage id="generic.register" defaultMessage="Register" />
                        </Button>
                    </form>

                    <Dialog
                        open={confirmDialogOpen}
                        onClose={() => {setConfirmDialogOpen(false)}}
                    >
                        <DialogTitle><FormattedMessage id="signup.confirmTitle" defaultMessage="Important" /></DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                <FormattedMessage id="signup.confirmQuestion" defaultMessage="The details entered on this page cannot be changed after registration. Are you sure you want to proceed?" />
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button variant="contained" onClick={() => {setConfirmDialogOpen(false)}} color="primary">
                                <FormattedMessage id="signup.confirmCancel" defaultMessage="Cancel" />
                            </Button>
                            <Button variant="contained" onClick={handleSubmit} color="secondary" autoFocus>
                                <FormattedMessage id="signup.confirmConfirm" defaultMessage="Confirm" />
                            </Button>
                        </DialogActions>
                    </Dialog>
                </Fragment>
            </Grid>
        </Grid>
    );
}
