import { Fragment } from "react";
import ElementsTable from "./ElementsTable";
import { FormattedMessage } from "react-intl";

export default function StrategyDimension(props) {
    return (
        <Fragment>
            <p>
                <FormattedMessage
                    id="execSummaryDiscAndRecs.strategy"
                    defaultMessage={
                        "Communicating strategies and plans across an organization is often an underdeveloped area, yet finding "+
                        "an appropriate level of information provision about plans and improvements can build confidence in "+
                        "employees that safety and quality are genuinely core values and also make them feel a part of what is "+
                        "happening."
                    }
                />
            </p>
            <ElementsTable dimension={props.dimension} elements={props.elements} lowestElements={props.lowestElements} />
        </Fragment>
    );
}
