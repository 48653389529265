import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

export default function CompareArrow(props) {

    const arrowStyle = (change, large = false, priority = false) => {
        let angle = 0;
        let color = 'rgb(0,120,60)';

        if (priority) {
            if (change >= 0.5) {
                angle = -90;
            } else if (change >= 0.25) {
                angle = -45;
                color = 'rgb(85,150,40)';
            } else if (change >= -0.25) {
                angle = 0;
                color = 'rgb(255, 210, 0)';
            } else if (change >= -0.5) {
                angle = 45;
                color = 'rgb(225,105,0)';
            } else {
                angle = 90;
                color = 'rgb(210,50,0)';
            }
        } else {
            if (change >= 15) {
                angle = -90;
            } else if (change >= 10) {
                angle = -60;
                color = 'rgb(85,150,40)';
            } else if (change >= 5) {
                angle = -30;
                color = 'rgb(170,180,20)';
            } else if (change >= -4) {
                angle = 0;
                color = 'rgb(255, 210, 0)';
            } else if (change >= -9) {
                angle = 30;
                color = 'rgb(240,160,0)';
            } else if (change >= -14) {
                angle = 60;
                color = 'rgb(225,105,0)';
            } else {
                angle = 90;
                color = 'rgb(210,50,0)';
            }
        }

        const style = { color: color, transform: 'rotate(' + angle + 'deg)' };
        if (large) {
            style['width'] = '72px';
            style['height'] = '72px';
        }
        return style;
    }

    return(
        <ArrowForwardIcon style={arrowStyle(props.change, props.large, props.priority )} />
    );
}
