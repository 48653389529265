import { Box, Button, Grid, IconButton, Tooltip, Typography, makeStyles } from "@material-ui/core";
import { useState, useContext, useRef } from "react";
import PrintIcon from '@material-ui/icons/Print';
import { useIntl } from "react-intl";
import Barriers from "./ExecutiveSummaryPanels/Barriers";
import BasicDataAndBenchmarking from "./ExecutiveSummaryPanels/BasicDataAndBenchmarking";
import Comparisons from "./ExecutiveSummaryPanels/Comparisons";
import CulturalAreasForImprovement from "./ExecutiveSummaryPanels/CulturalAreasForImprovement";
import CulturalStrengths from "./ExecutiveSummaryPanels/CulturalStrengths";
import DefinitionsAndIdeasForImprovement from "./ExecutiveSummaryPanels/DefinitionsAndIdeasForImprovement";
import DiscussionsAndRecommendations from "./ExecutiveSummaryPanels/DiscussionsAndRecomendations";
import HighestHotspots from "./ExecutiveSummaryPanels/HighestHotspots";
import IntegratedInitiatives from "./ExecutiveSummaryPanels/IntegratedInitiatives";
import LowestHotspots from "./ExecutiveSummaryPanels/LowestHotspots";
import Positives from "./ExecutiveSummaryPanels/Positives";
import Priorities from "./ExecutiveSummaryPanels/Priorities";
import PriorityHotspots from "./ExecutiveSummaryPanels/PriorityHotspots";
import Risks from "./ExecutiveSummaryPanels/Risks";
import QualitativeComments from "./ExecutiveSummaryPanels/QualitativeComments";
import { UserContext } from "../../../Context/UserContext";
import CameraAltIcon from '@material-ui/icons/CameraAlt';
import { screenshot } from "../../../Utilities/Screenshot";

const buttonGridStyle = {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    gridGap: '6pt 0.75%',
};

const useStyles = makeStyles((theme) => ({
    screenshot: {
        [theme.breakpoints.down('lg')]: {
            paddingRight: 40
        },
    }
}));

export default function ExecutiveSummary(props) {
    const intl = useIntl();
    const classes = useStyles();
    const screenshotRef = useRef(null);
    const [activePanel, setActivePanel] = useState(0);

    const { user } = useContext(
        UserContext
    );

    if (!props.reportData.data) {
        return null;
    }

    const panels = [];
    panels.push(intl.formatMessage({id: 'execSummary.basicDataBenchmark', defaultMessage: 'Basic Data & Benchmarking'}));
    panels.push(intl.formatMessage({id: 'execSummary.comparisons', defaultMessage: 'Comparisons'}));
    panels.push(intl.formatMessage({id: 'execSummary.culturalStrengths', defaultMessage: 'Cultural Strengths'}));
    panels.push(intl.formatMessage({id: 'execSummary.highestHotspots', defaultMessage: 'Highest Hotspots'}));
    panels.push(intl.formatMessage({id: 'execSummary.positives', defaultMessage: 'Positives'}));
    panels.push(intl.formatMessage({id: 'execSummary.culturalAreasForImpr', defaultMessage: 'Cultural Areas for Improvement'}));
    panels.push(intl.formatMessage({id: 'execSummary.lowestHotspots', defaultMessage: 'Lowest Hotspots'}));
    panels.push(intl.formatMessage({id: 'execSummary.barriers', defaultMessage: 'Barriers'}));
    panels.push(intl.formatMessage({id: 'execSummary.priorities', defaultMessage: 'Priorities'}));
    panels.push(intl.formatMessage({id: 'execSummary.prorityHotspots', defaultMessage: 'Priority Hotspots'}));
    panels.push(intl.formatMessage({id: 'execSummary.risks', defaultMessage: 'Risks'}));
    panels.push(intl.formatMessage({id: 'execSummary.discussionsAndRem', defaultMessage: 'Discussions & Recommendations'}));
    panels.push(intl.formatMessage({id: 'execSummary.integratedInit', defaultMessage: 'Integrated Initiatives'}));
    if (user.role === 'admin' || -1 !== props.surveyData.attributes.reports.indexOf('qualitative-comments')) {
        panels.push(intl.formatMessage({id: 'execSummary.qualitativeComments', defaultMessage: 'Qualitative Comments'}));
    }
    panels.push(intl.formatMessage({id: 'execSummary.defAndIdeasForImpr', defaultMessage: 'Definitions & Ideas for Improvement'}));

    const panelContent = (current, title) => {
        switch (current) {
            case 0:
                return <BasicDataAndBenchmarking reportData={props.reportData.data} title={title} />

            case 1:
                return <Comparisons reportData={props.reportData.data} title={title} demographicQuestions={props.demographicQuestions} demographicOptionLabels={props.demographicOptionLabels} />

            case 2:
                return <CulturalStrengths reportData={props.reportData.data} title={title} />

            case 3:
                return <HighestHotspots reportData={props.reportData.data} title={title} demographicQuestions={props.demographicQuestions} demographicOptionLabels={props.demographicOptionLabels} />

            case 4:
                return <Positives reportData={props.reportData.data} title={title} />

            case 5:
                return <CulturalAreasForImprovement reportData={props.reportData.data} title={title} />

            case 6:
                return <LowestHotspots reportData={props.reportData.data} title={title} demographicQuestions={props.demographicQuestions} demographicOptionLabels={props.demographicOptionLabels} />

            case 7:
                return <Barriers reportData={props.reportData.data} title={title} />

            case 8:
                return <Priorities reportData={props.reportData.data} title={title} />

            case 9:
                return <PriorityHotspots reportData={props.reportData.data} title={title} demographicQuestions={props.demographicQuestions} demographicOptionLabels={props.demographicOptionLabels} />

            case 10:
                return <Risks reportData={props.reportData.data} title={title} surveyData={props.surveyData} />

            case 11:
                return <DiscussionsAndRecommendations reportData={props.reportData.data} title={title} />

            case 12:
                return <IntegratedInitiatives reportData={props.reportData.data} title={title} />

            case 13:
                return <QualitativeComments reportData={props.reportData.data} title={title} />

            case 14:
                return <DefinitionsAndIdeasForImprovement reportData={props.reportData.data} title={title} />

            default:
                return null;
        }
    };

    const panel = (current) => (
        <div ref={screenshotRef}>
            <Box py={2}>
                <Typography variant="h6">{panels[current]}</Typography>
            </Box>
            {panelContent(current, panels[current])}
        </div>
    );

    const encodeFilterParams = () => {
        const params = [];
        if (props.filter) {
            Object.keys(props.filter).forEach((demographic) => {
                if (props.filter[demographic] && props.filter[demographic].length) {
                    params.push(`demographics[${demographic}]=${encodeURIComponent(props.filter[demographic].join('|'))}`);
                }
            });
            return '?' + params.join('&');
        }
        return '';
    }

    return (
        <Box p={0}>
            <Grid container justifyContent="space-between" alignItems="center" direction="row">
                <Grid item>
                    <Tooltip title="Print">
                        <IconButton
                            size="small"
                            color="inherit"
                            target="_blank"
                            rel="noreferrer"
                            href={`/survey-report/${props.surveyData.attributes.combinedHash}/executive-summary-print${encodeFilterParams()}`}
                        >
                            <PrintIcon />
                        </IconButton>
                    </Tooltip>
                </Grid>
                <Grid item>
                    <Tooltip title="Screenshot">
                        <IconButton
                            className={classes.screenshot}
                            size="small"
                            color="inherit"
                            target="_blank"
                            rel="noreferrer"
                            onClick={() => {screenshot(screenshotRef, (panels[activePanel] ? panels[activePanel] : 'screenshot')+'.png')}}
                        >
                            <CameraAltIcon />
                        </IconButton>
                    </Tooltip>
                </Grid>
            </Grid>

            <div style={buttonGridStyle}>
                {panels.map((panel, i) => (
                    <Button
                        key={i}
                        variant="contained"
                        style={{textTransform: 'none', fontWeight: 'normal'}}
                        color={activePanel === i ? 'secondary' : 'primary'}
                        onClick={() => setActivePanel(i)}
                    >
                        {panel}
                    </Button>
                ))}
            </div>

            {panel(activePanel)}
        </Box>
    );
}
