import { useIntl } from "react-intl";

export default function DemographicLabel(props) {
    const intl = useIntl();

    const demographicLabel = (demographic) => {
        // Allow the use of both the demographic type and the title (i.e. "length of service" and "lengthofservice")
        switch(demographic.toLowerCase()) {
            case 'site':
            case 'workplace':
                return intl.formatMessage({id: 'demographic.site', defaultMessage: 'Site'});

            case 'role':
            case 'hierarchy level / role':
                return intl.formatMessage({id: 'demographic.role', defaultMessage: 'Hierarchy Level / Role'});

            case 'length of service':
            case 'lengthofservice':
                return intl.formatMessage({id: 'demographic.lengthOfService', defaultMessage: 'Length of Service'});

            case 'department':
                return intl.formatMessage({id: 'demographic.department', defaultMessage: 'Department'});

            case 'region':
                return intl.formatMessage({id: 'demographic.region', defaultMessage: 'Region'});

            case 'shift':
                return intl.formatMessage({id: 'demographic.shift', defaultMessage: 'Shift'});

            case 'wages':
                return intl.formatMessage({id: 'demographic.wages', defaultMessage: 'Wages'});

            case 'group':
                return intl.formatMessage({id: 'demographic.group', defaultMessage: 'Group'});

            case 'country':
                return intl.formatMessage({id: 'demographic.country', defaultMessage: 'Country'});

            default:
                return demographic
        }
    };

    return(
        <span>{(props.upperCase ? demographicLabel(props.demographic).toUpperCase() : (props.lowerCase ? demographicLabel(props.demographic).toLowerCase() : demographicLabel(props.demographic)))}</span>
    );
}
