import { Fragment } from "react";
import ElementsTable from "./ElementsTable";
import { FormattedMessage } from "react-intl";

export default function PremisesDimension(props) {
    return (
        <Fragment>
            <p>
                <FormattedMessage
                    id="execSummaryDiscAndRecs.premises"
                    defaultMessage={
                        "While a 'secondary' rather than 'primary' embedding mechanism for culture (Schein), the state of the "+
                        "premises can facilitate food safety and quality behaviours as well as promoting positive attitudes, for "+
                        "example pride in the workplace."
                    }
                />
            </p>
            <ElementsTable dimension={props.dimension} elements={props.elements} lowestElements={props.lowestElements} />
        </Fragment>
    );
}