import { Fragment } from "react";
import ElementsTable from "./ElementsTable";
import { FormattedMessage } from "react-intl";

export default function LearningDimension(props) {
    return (
        <Fragment>
            <p>
                <FormattedMessage
                    id="execSummaryDiscAndRecs.learning"
                    defaultMessage={
                        "Organisational learning relates to the creation, dissemination and utilisation of new knowledge, and its "+
                        "impact on safety and quality standards. In particular, it is the ability of a company to learn from "+
                        "mistakes and incidents to continually improve, which relies on a culture of justice and openness. 'How "+
                        "leaders react to critical events' is one of Schein's primary embedding mechanisms for culture, but it is "+
                        "also in the approach and reaction to everyday occurrences and situations that effective organisational "+
                        "learning can be influenced. Leaders need to encourage open communication of what goes wrong as well as "+
                        "what goes well, and ensure consistent, fair and transparent responses to errors and problems."
                    }
                />
            </p>
            <ElementsTable dimension={props.dimension} elements={props.elements} lowestElements={props.lowestElements} />
        </Fragment>
    );
}
