import { Box, Button, Grid, TextField, Typography } from "@material-ui/core";
import { Fragment, useContext, useState } from "react";
import { Redirect, NavLink } from "react-router-dom";
import { NotificationContext } from "../../../Context/NotificationContext";
import { SpinnerContext } from "../../../Context/SpinnerContext";
import UserService from "./../../../Services/UserService";
import { FormattedMessage, useIntl } from "react-intl";
import PasswordStrengthLazy from "./PasswordStrengthLazy";


export default function SetPassword() {
    const { updateLoading } = useContext(SpinnerContext);
    const { updateNotification } = useContext(NotificationContext);
    const [message, setMessage] = useState(null);
    const intl = useIntl();
    const minPasswordLength = 10;

    const [values, setValues] = useState({
        password: '',
        verifyPassword: ''
    });

    const [redirect, setRedirect] = useState(null);

    const handleChange = name => event => {
        setValues({ ...values, [name]: event.target.value });
    };

    const handleSubmit = event => {
        event.preventDefault();
        updateLoading(true);

        const query = new URLSearchParams(window.location.search);

        UserService.setPassword(values.password, query.get('uuid'), query.get('token'))
        .then(function (response) {
            updateNotification(true, intl.formatMessage({id: 'setPassword.passwordSetSuccess', defaultMessage: 'Password set successfully'}), 'success');
            if (query.get('theme') && ('brcgs' === query.get('theme') || 'ssafe' === query.get('theme'))) {
                setTimeout(() => {updateNotification(false, '', 'info'); setRedirect('/login?theme='+query.get('theme'));}, 2000);
            } else {
                setTimeout(() => {updateNotification(false, '', 'info'); setRedirect('/login')}, 2000);
            }
        }).catch(function (error) {
            if (error.response && error.response.status && 401 === error.response.status) {
                setMessage(<Fragment>
                    <Typography variant="h5" paragraph>
                        <FormattedMessage id="setPassword.failed" defaultMessage="Setting Password Failed" />
                    </Typography>
                    <Typography variant="body1" paragraph>
                        <FormattedMessage id="setPassword.couldNotSet" defaultMessage="The password could not be set." />
                        <br /><br />
                        <FormattedMessage
                            id="setPassword.passwordReset"
                            defaultMessage="If you need to change your password, please use the <strong>Forgot password</strong> link on the <a>login page</a>."
                            values = {{
                                a: chunks => <NavLink to="/login">{chunks}</NavLink>,
                                strong: chunks => <strong>{chunks}</strong>
                            }}
                        />
                    </Typography>
                </Fragment>);
                updateNotification(true, intl.formatMessage({id: 'setPassword.passwordSetError', defaultMessage: 'Password could not be set'}), 'warning');
            } else {
                updateNotification(true, intl.formatMessage({id: 'generic.unknownError', defaultMessage: 'An unknown error occurred!'}), 'error');
            }
            console.error(error);
        }).then(function() {
            updateLoading(false);
        });
    }

    if (redirect) {
        return (<Redirect to={redirect} />);
    }

    return(
        <Grid container spacing={3} justifyContent="center">
            <Grid item sm={8} md={4} lg={3}>
                {message ? message :
                    <Fragment>
                        <Typography variant="h5" paragraph>
                            <FormattedMessage id="setPassword.header" defaultMessage="Set Password" />
                        </Typography>
                        <Typography variant="body1" paragraph>
                            <FormattedMessage id="setPassword.notice" defaultMessage="Please enter your new password below:" />
                        </Typography>
                        <form onSubmit={handleSubmit}>
                            <Box pb={1}>
                                <TextField
                                    error={(values.password !== '' && values.password.length < minPasswordLength)}
                                    helperText={(values.password !== '' && values.password.length < minPasswordLength ? intl.formatMessage({id: 'setPassword.passwordTooShort', defaultMessage: 'Your password has to be at least 10 characters'}) : ' ')}
                                    id="password"
                                    fullWidth
                                    label={intl.formatMessage({id: 'setPassword.password', defaultMessage: 'Password'})}
                                    type="password"
                                    value={values.password}
                                    onChange={handleChange('password')}
                                    margin="normal"
                                    required
                                    style={{marginBottom: 0}}
                                />
                                <PasswordStrengthLazy password={values.password} minPasswordLength={minPasswordLength} />

                                <TextField
                                    error={values.verifyPassword !== '' && values.password !== values.verifyPassword}
                                    helperText={(values.verifyPassword !== '' && values.password !== values.verifyPassword ? intl.formatMessage({id: 'setPassword.passwordsDoNotMatch', defaultMessage: 'Passwords do not match!'}) : ' ')}
                                    id="verifyPassword"
                                    fullWidth
                                    label={intl.formatMessage({id: 'setPassword.repeatPassword', defaultMessage: 'Repeat password'})}
                                    type="password"
                                    value={values.verifyPassword}
                                    onChange={handleChange('verifyPassword')}
                                    margin="normal"
                                    required
                                />
                            </Box>
                            <Button
                                variant="contained"
                                className="submit"
                                color="secondary"
                                type="submit"
                                disabled={values.password !== values.verifyPassword || '' === values.password || values.password.length < minPasswordLength }
                            >
                                <FormattedMessage id="setPassword.setPassword" defaultMessage="Set Password" />
                            </Button>
                        </form>
                    </Fragment>
                }
            </Grid>
        </Grid>
    );
}