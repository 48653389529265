import { Box, Typography } from "@material-ui/core";
import { Fragment, useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import Barriers from "./ExecutiveSummaryPanels/Barriers";
import BasicDataAndBenchmarking from "./ExecutiveSummaryPanels/BasicDataAndBenchmarking";
import Comparisons from "./ExecutiveSummaryPanels/Comparisons";
import CulturalAreasForImprovement from "./ExecutiveSummaryPanels/CulturalAreasForImprovement";
import CulturalStrengths from "./ExecutiveSummaryPanels/CulturalStrengths";
import DefinitionsAndIdeasForImprovement from "./ExecutiveSummaryPanels/DefinitionsAndIdeasForImprovement";
import DiscussionsAndRecommendations from "./ExecutiveSummaryPanels/DiscussionsAndRecomendations";
import HighestHotspots from "./ExecutiveSummaryPanels/HighestHotspots";
import IntegratedInitiatives from "./ExecutiveSummaryPanels/IntegratedInitiatives";
import LowestHotspots from "./ExecutiveSummaryPanels/LowestHotspots";
import Positives from "./ExecutiveSummaryPanels/Positives";
import Priorities from "./ExecutiveSummaryPanels/Priorities";
import PriorityHotspots from "./ExecutiveSummaryPanels/PriorityHotspots";
import Risks from "./ExecutiveSummaryPanels/Risks";
import QualitativeComments from "./ExecutiveSummaryPanels/QualitativeComments";
import ceLogo from '../../App/img/default-square-dark.png';
import { useLocation } from "react-router-dom";


export default function ExecutiveSummaryPrint(props) {
    const intl = useIntl();
    const location = useLocation();
    const queryParamSiteFilter = new URLSearchParams(location.search).get('demographics[site]');
    const sites = [];
    if (queryParamSiteFilter) {
        sites.push(...queryParamSiteFilter.split('|'));
    }

    const siteQuestion = props.demographicQuestions.find(q => q.attributes.demographic === 'site');

    const panels = [
        {
            panel: BasicDataAndBenchmarking,
            title: intl.formatMessage({id: 'execSummary.basicDataBenchmark', defaultMessage: 'Basic Data & Benchmarking'})
        },
        {
            panel: Comparisons,
            title: intl.formatMessage({id: 'execSummary.comparisons', defaultMessage: 'Comparisons'})
        },
        {
            panel: CulturalStrengths,
            title: intl.formatMessage({id: 'execSummary.culturalStrengths', defaultMessage: 'Cultural Strengths'})
        },
        {
            panel: HighestHotspots,
            title: intl.formatMessage({id: 'execSummary.highestHotspots', defaultMessage: 'Highest Hotspots'})
        },
        {
            panel: Positives,
            title: intl.formatMessage({id: 'execSummary.positives', defaultMessage: 'Positives'})
        },
        {
            panel: CulturalAreasForImprovement,
            title: intl.formatMessage({id: 'execSummary.culturalAreasForImpr', defaultMessage: 'Cultural Areas for Improvement'}),
        },
        {
            panel: LowestHotspots,
            title: intl.formatMessage({id: 'execSummary.lowestHotspots', defaultMessage: 'Lowest Hotspots'})
        },
        {
            panel: Barriers,
            title: intl.formatMessage({id: 'execSummary.barriers', defaultMessage: 'Barriers'}),
        },
        {
            panel: Priorities,
            title: intl.formatMessage({id: 'execSummary.priorities', defaultMessage: 'Priorities'}),
        },
        {
            panel: PriorityHotspots,
            title: intl.formatMessage({id: 'execSummary.prorityHotspots', defaultMessage: 'Priority Hotspots'}),
        },
        {
            panel: Risks,
            title: intl.formatMessage({id: 'execSummary.risks', defaultMessage: 'Risks'})
        },
        {
            panel: DiscussionsAndRecommendations,
            title: intl.formatMessage({id: 'execSummary.discussionsAndRem', defaultMessage: 'Discussions & Recommendations'}),
        },
        {
            panel: IntegratedInitiatives,
            title: intl.formatMessage({id: 'execSummary.integratedInit', defaultMessage: 'Integrated Initiatives'})
        },
        {
            panel: QualitativeComments,
            title: intl.formatMessage({id: 'execSummary.qualitativeComments', defaultMessage: 'Qualitative Comments'}),
        },
        {
            panel: DefinitionsAndIdeasForImprovement,
            title: intl.formatMessage({id: 'execSummary.defAndIdeasForImpr', defaultMessage: 'Definitions & Ideas for Improvement'})
        }
    ];

    useEffect(() => {
        // Wait 2 seconds after report data is loaded to avoid glitchy print dialog
        if (props.reportData.data) {
            setTimeout(() => { window.print(); }, 2000);
        }
    }, [props.reportData.data]);

    if (!props.reportData.data) {
        return null;
    }

    const reportPanel = (PanelComponent, title) => {
        if (PanelComponent === PriorityHotspots ||
            PanelComponent === LowestHotspots ||
            PanelComponent === HighestHotspots ||
            PanelComponent === Comparisons
        ) {
            return <PanelComponent reportData={props.reportData.data} title={title} print={true} surveyData={props.surveyData} demographicQuestions={props.demographicQuestions} demographicOptionLabels={props.demographicOptionLabels} />
        } else {
            return <PanelComponent reportData={props.reportData.data} title={title} print={true} surveyData={props.surveyData} demographicOptionLabels={props.demographicOptionLabels}/>
        }
    };

    return (
        <Box style={{paddingTop: 0}} className="print-view">
            <Box pb={3} pt={0} textAlign="center">
                <img style={{ height: 120 }} alt="" src={ceLogo} />
            </Box>

            <Typography variant="h1" style={{fontWeight: 400, fontSize: '1.8em'}}>
                <FormattedMessage
                    id="execSummary.printHeader"
                    defaultMessage={"Executive Summary {companyName}"}
                    values={{
                        companyName: '('+props.surveyData.relationships.company.data.name+')'
                    }}
                />
            </Typography>

            <p>
                <FormattedMessage
                    id="execSummary.printP1"
                    defaultMessage={
                        "This report is based on data from {respondentCount} employees in {siteCount} site(s), "+
                        "from the <em>{surveyTitle}</em> survey. Full details of uptake numbers can be seen in the "+
                        "<strong>Tracking</strong> view, using the dynamic filters for each demographic."
                    }
                    values={{
                        strong: chunks => <strong>{chunks}</strong>,
                        em: chunks => <em>{chunks}</em>,
                        respondentCount: Object.values(props.reportData.data['demographics'].demographics.site).reduce((a,b) => a+b),
                        siteCount: Object.keys(props.reportData.data['demographics'].demographics.site).length,
                        surveyTitle: props.surveyData.relationships['survey-title'] ? props.surveyData.relationships['survey-title'].data.title : props.surveyData.attributes.title
                    }}
                />
            </p>

            <p>
                {sites && sites.length ? sites.map((s, i) => (
                    <Fragment><strong> • </strong>{s}</Fragment>
                )) : siteQuestion.attributes.options.map(option => (
                    <Fragment><strong> • </strong>{option.attributes.value}</Fragment>
                ))}
            </p>

            {panels.map((p, i) => {
                return (<Fragment key={i}>
                    {reportPanel(p.panel, p.title)}
                    {-1 === [
                        BasicDataAndBenchmarking,
                        HighestHotspots,
                        LowestHotspots,
                        IntegratedInitiatives,
                        QualitativeComments,
                        DefinitionsAndIdeasForImprovement
                    ].indexOf(p.panel) ? <div className="page-break"/> : null}
                </Fragment>);
            })}
        </Box>
    );
}
