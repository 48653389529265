import { Box, Button, Grid, TextField, Typography, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@material-ui/core";
import { useContext, useEffect, useRef, useState } from "react";
import { NavLink, useHistory } from "react-router-dom";
import { AppBarTitleContext } from "../../../Context/AppBarTitleContext";
import { NotificationContext } from "../../../Context/NotificationContext";
import { SpinnerContext } from "../../../Context/SpinnerContext";
import { UserContext } from "../../../Context/UserContext";
import UserService from "./../../../Services/UserService";
import ceLogo from './img/culture_excellence.png';
import { FormattedMessage, useIntl } from "react-intl";
import { LocaleContext } from "../../../Context/LocaleContext";
import LockIcon from '@material-ui/icons/Lock';

export default function Login() {
    let history = useHistory();

    const { user, updateUser, token, updateToken } = useContext(UserContext);
    const { loading, updateLoading } = useContext(SpinnerContext);
    const { updateNotification } = useContext(NotificationContext);
    const { locale, updateLocale } = useContext(LocaleContext);
    const { updateTitle } = useContext(AppBarTitleContext);
    const updateTitleRef = useRef();
    updateTitleRef.current = (newTitle) => {
        updateTitle(newTitle);
    };
    const intl = useIntl();

    const [values, setValues] = useState({
        email: '',
        password: ''
    });

    const [twoFactor, setTwoFactor] = useState(false);
    const [twoFactorCode, setTwoFactorCode] = useState('');


    useEffect(() => {
        // Log out user if they are logged in and somehow ended up here
        if (false === loading && null !== user) {
            updateUser(null);
        }
        if (false === loading && token && 0 !== token.expiry && null !== token.token) {
            updateToken({expiry: 0, token: null});
        }

        // Clear title
        updateTitleRef.current(null);

    }, [loading, user, updateUser, token, updateToken]);

    const handleChange = name => event => {
        setValues({ ...values, [name]: event.target.value });
    };

    const login = (response) => {
        updateUser({...response.data.included[0].data.attributes, id: response.data.included[0].data.id});
        updateToken(response.data.data.attributes);

        // Set locale if user has it set
        if (response.data.included[0].data.attributes.locale) {
            updateLocale(response.data.included[0].data.attributes.locale)
        }

        const redir = sessionStorage.getItem('redir');
        if (redir) {
            sessionStorage.removeItem('redir');
            history.push(redir);
        } else {
            history.push('/home');
        }
    };

    const handleSubmit = event => {
        event.preventDefault();
        updateLoading(true);
        UserService.login(values.email, values.password)
        .then(response => {
            if (response.status === 200 && response.data.data.type === 'token') {
                login(response);
            } else if (response.status === 200 && response.data.data.type === 'two-factor') {
                // 2FA auth
                setTwoFactor(true);
            }

        }).catch(error => {
            if (error.response && error.response.status && 401 === error.response.status) {
                updateNotification(true, intl.formatMessage({id: 'login.wrongEmailOrPassword', defaultMessage: 'Wrong email and/or password'}), 'warning');
            } else {
                updateNotification(true, intl.formatMessage({id: 'generic.unknownError', defaultMessage: 'An unknown error occurred!'}), 'error');
            }
            console.error(error);
        }).then(() => {
            updateLoading(false);
        });
    }

    const handleTwoFactorAuth = () => {
        updateLoading(true);
        UserService.twoFactorLogin(values.email, values.password, twoFactorCode).then(response => {
            login(response);
        }).catch(error => {
            if (error.response && error.response.status && 401 === error.response.status) {
                updateNotification(true, intl.formatMessage({id: 'login.incorrectTwoFactorCode', defaultMessage: 'Incorrect verification code'}), 'warning');
            } else {
                updateNotification(true, intl.formatMessage({id: 'generic.unknownError', defaultMessage: 'An unknown error occurred!'}), 'error');
            }
        }).then(() => {
            updateLoading(false);
        })
    };

    const twoFactorDialog = () => {
        return (
            <Dialog
                open={twoFactor}
                onClose={(event, reason) => {
                    if (reason !== 'backdropClick') {
                        setTwoFactor(false)
                    }
                }}
            >
                <DialogTitle><FormattedMessage id="generic.twoFactorAuth" defaultMessage="Two-Factor Authentication" /></DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <FormattedMessage id="login.enterTwoFactor" defaultMessage="Enter two-factor verification code to continue" />
                    </DialogContentText>

                    <Box display="flex" justifyContent="center" alignItems="center">
                        <LockIcon style={{fontSize: '92', color: '#3c3e40'}} />
                    </Box>
                    <Box display="flex" justifyContent="center" alignItems="center">
                        <TextField
                            label={intl.formatMessage({id: 'generic.verificationCode', defaultMessage: 'Verification code'})}
                            type="number"
                            onWheel={(e) => e.target.blur()} // Avoid changing value by accident on mouse scroll
                            onKeyDown={(e) => {
                                if (twoFactorCode.length === 6 && e && e.key === 'Enter') {
                                    handleTwoFactorAuth();
                                }
                            }}
                            InputProps={{
                                inputProps: {
                                    maxLength: 6,
                                    step: 1,
                                    min: 0,
                                    pattern: "[0-9]+" // only allow numbers
                                }
                            }}
                            value={twoFactorCode}
                            onChange={(event) => setTwoFactorCode(event.target.value)}
                        />
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={handleTwoFactorAuth} color="secondary" disabled={twoFactorCode.length !== 6}>
                        <FormattedMessage id="generic.verify" defaultMessage="Verify" />
                    </Button>
                </DialogActions>
            </Dialog>
        );
    };

    return(
        <Grid container spacing={0} justifyContent="center">
            <Grid item sm={8} md={4} lg={3}>
                <img
                    style={{ maxWidth: "100%" }}
                    title="Culture Excellence"
                    alt="Culture Excellence"
                    src={ceLogo}
                />
                <form onSubmit={handleSubmit}>
                    <Box pb={1}>
                        <TextField
                            id="email"
                            dir="ltr"
                            InputProps={{style: { textAlign: 'ar' === locale ? 'right' : 'left' }}}
                            fullWidth
                            autoComplete="username"
                            label={<FormattedMessage id="login.email" defaultMessage="Email" />}
                            type="email"
                            value={values.email}
                            onChange={handleChange('email')}
                            margin="normal"
                            required
                        />
                        <TextField
                            id="password"
                            fullWidth
                            autoComplete="current-password"
                            label={<FormattedMessage id="login.password" defaultMessage="Password" />}
                            type="password"
                            value={values.password}
                            onChange={handleChange('password')}
                            margin="normal"
                            required
                        />
                    </Box>
                    <Button
                        variant="contained"
                        className="submit"
                        color="secondary"
                        type="submit"
                    >
                        <FormattedMessage id="login.login" defaultMessage="Login" />
                    </Button>
                </form>
                <Box pt={2}>
                    <Typography variant="body1" paragraph>
                        <NavLink to="/forgot-password">
                            <FormattedMessage id="login.forgotPassword" defaultMessage="Forgot password?" />
                        </NavLink>
                    </Typography>
                </Box>
                {twoFactorDialog()}
            </Grid>
        </Grid>
    );
}