import { Grid, Typography } from "@material-ui/core";
import { useContext, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { NotificationContext } from "../../Context/NotificationContext";
import { SpinnerContext } from "../../Context/SpinnerContext";
import UserService from "../../Services/UserService";


export default function VerifyNewEmail(props) {
    const intl = useIntl();
    const [message, setMessage] = useState(intl.formatMessage({id: 'verifyNewEmail.inProgress', defaultMessage: 'Your new email address is being verified...'}));

    const { updateLoading } = useContext(SpinnerContext);
    const { updateNotification } = useContext(NotificationContext);

    useEffect(() => {
        updateLoading(true);
        const query = new URLSearchParams(window.location.search);
        UserService.verifyNewEmail(query.get('email'), query.get('uuid'), query.get('token')).then(function (response) {
            setMessage(intl.formatMessage({id: 'verifyNewEmail.success', defaultMessage: 'New email address successfully set on your account'}));
        }).catch(function (error) {
            if (error.response && error.response.status && 403 === error.response.status) {
                updateNotification(true, intl.formatMessage({id: 'verifyNewEmail.verificationFailed', defaultMessage: 'Failed to update email address, did you already use this verification link?'}), 'error');
                setMessage(intl.formatMessage({id: 'verifyNewEmail.verificationFailedMessage', defaultMessage: 'Failed to set new email address, did you already use this verification link?'}));
            } else {
                updateNotification(true, intl.formatMessage({id: 'generic.unknownError', defaultMessage: 'An unknown error occurred!'}), 'error');
            }
        }).then(function() {
            updateLoading(false);
        });
        // eslint-disable-next-line
    }, []);

    return(
        <Grid container spacing={0} justifyContent="center">
            <Grid item sm={10} md={5} lg={4}>
                <Typography variant="h6" paragraph>
                    <FormattedMessage id="verifyNewEmail.header" defaultMessage="Email Verification"/>
                </Typography>
                <Typography variant="body1" paragraph>
                    { message }
                </Typography>
            </Grid>
        </Grid>
    );
}