import { Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, FormControlLabel, Grid, makeStyles, Paper, TextField, Typography } from "@material-ui/core";
import { Fragment, useContext, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useLocation } from "react-router-dom";
import responseDistribution from './img/response-distribution.png';
import elementHotspots from './img/element-hotspots.png';
import priorities from './img/priorities.png';
import priorityHotspots from './img/priority-hotspots.png';
import priorityConflictHotspots from './img/priority-conflict-hotspots.png';
import risks from './img/risks.png';
import benchmarkCompany from './img/benchmark-company.png';
import benchmarkRegion from './img/benchmark-region.png';
import benchmarkCountry from './img/benchmark-country.png';
import benchmarkElementsCompany from './img/benchmark-elements-company.png';
import elementsComparison from './img/elements-comparison.png';
import prioritiesComparison from './img/priorities-comparison.png';
import risksComparison from './img/risks-comparison.png';
import correlation from './img/correlation.png';
import demographicComparison from './img/demographic-comparison.png';
import ContactService from "../../Services/ContactService";
import { NotificationContext } from "../../Context/NotificationContext";
import { SpinnerContext } from "../../Context/SpinnerContext";
import PriorityLabel from "../Translation/PriorityLabel";

const useStyles = makeStyles((theme) => ({
    img: {
        cursor: 'pointer',
        maxWidth: '100%',
        margin: 'auto',
        display: 'block',
        maxHeight: '90vh',
    },
}));

export default function Brcgs(props) {
    const intl = useIntl();
    const classes = useStyles();
    const location = useLocation();
    const q = new URLSearchParams(location.search);
    const type = q.get('type');
    const { updateNotification } = useContext(NotificationContext);
    const { updateLoading, loading } = useContext(SpinnerContext);
    const [siteCount, setSiteCount] = useState(1);
    const [consent, setConsent] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);

    const reports = {
        'demographic-comparison': {
            title: <Fragment><FormattedMessage id="reportmenu.categoriesAndDimensions" defaultMessage="Categories & Dimensions" /> -&gt; <FormattedMessage id="reportmenu.demographicComparison" defaultMessage="Demographic Comparison" /></Fragment>,
            img: demographicComparison,
            text: <FormattedMessage id="brcgs.upgradeDemographicComparison" defaultMessage="This report summarises the results per role and provides a quick overview of the different and often varying cultural perspectives." />,
        },
        'response-distribution': {
            title: <Fragment><FormattedMessage id="reportmenu.elements" defaultMessage="Elements" /> -&gt; <FormattedMessage id="reportmenu.responseDistribution" defaultMessage="Response Distribution" /></Fragment>,
            img: responseDistribution,
            imgText: intl.formatMessage({id: 'brcgs.upgradeResponseDistributionImgTxt', defaultMessage: 'Please see below for an example of this report (this shows example data from our demo account - this is not your data). The full report shows response distributions for all 82 Elements.'}),
            text: <FormattedMessage id="brcgs.upgradeResponseDistribution" defaultMessage="This report shows the distribution of responses for every Element, across the different answer options (for most Elements these options range from 'Strongly Agree' to 'Strongly Disagree'). This shows whether scoring, per Element, is based on strong consensus versus an average of a broad range of opinions." />
        },
        'element-hotspots': {
            title: <Fragment><FormattedMessage id="reportmenu.elements" defaultMessage="Elements" /> -&gt; <FormattedMessage id="reportmenu.hotspots" defaultMessage="Hotspots" /></Fragment>,
            img: elementHotspots,
            imgText: intl.formatMessage({id: 'brcgs.upgradeElementHotspotsImgTxt', defaultMessage: 'Please see below for an example of this report (this shows example data from our demo account - this is not your data). The full report shows the 50 highest and 50 lowest scoring Hotspot combinations.'}),
            text: <FormattedMessage id="brcgs.upgradeElementHotspots" defaultMessage="This report shows pockets of strength and low scores, by combining demographics automatically. It quickly provides visibility of areas for attention, to celebrate success and / or focus on improvement." />,
        },
        'priorities': {
            title: <Fragment><FormattedMessage id="reportmenu.priorities" defaultMessage="Priorities" /> -&gt; <FormattedMessage id="reportmenu.companyPersonal" defaultMessage={"Company & Personal"} /></Fragment>,
            img: priorities,
            text: <Fragment>
                <FormattedMessage id="brcgs.upgradePrioritiesPt1" defaultMessage="This report highlights alignment (or conflict) between employee personal prioritisation of the following criteria:" />
                <ul>
                    <li><PriorityLabel title="Health & safety" /></li>
                    <li><PriorityLabel title="Food safety" /></li>
                    <li><PriorityLabel title="Product quality" /></li>
                    <li><PriorityLabel title="Production costs" /></li>
                    <li><PriorityLabel title="Production speed" /></li>
                </ul>
                <FormattedMessage id="brcgs.upgradePrioritiesPt2" defaultMessage="The report also shows employee perception of company prioritisation of the same criteria. This report has proven to be a useful tool in predicting risk for organisations; specifically, employees tend to behave according to their perception of their employer's priorities. For example, if employees perceive the organisation prioritises production speed ahead of food safety, this can impact food safety-related behaviour." />
            </Fragment>,
        },
        'priority-hotspots': {
            title: <Fragment><FormattedMessage id="reportmenu.priorities" defaultMessage="Priorities" /> -&gt; <FormattedMessage id="reportmenu.companyPersonal" defaultMessage={"Company & Personal"} /></Fragment>,
            img: priorityHotspots,
            imgText: intl.formatMessage({id: 'brcgs.upgradePrioHotspotsImgTxt', defaultMessage: 'Please see below for an example of this report (this shows example data from our demo account - this is not your data). The full report shows the 50 highest and 50 lowest scoring Priority Hotspot combinations.'}),
            text: <FormattedMessage id="brcgs.upgradePrioHotspots" defaultMessage="This report shows areas of strongest prioritisations, both employee personal and perceived company prioritisations. This is useful to show whether prioritisations are appropriate." />,
        },
        'priority-conflict-hotspots': {
            title: <Fragment><FormattedMessage id="reportmenu.priorities" defaultMessage="Priorities" /> -&gt; <FormattedMessage id="reportmenu.conflictHotspots" defaultMessage={"Conflict Hotspots"} /></Fragment>,
            img: priorityConflictHotspots,
            imgText: intl.formatMessage({id: 'brcgs.upgradePrioConflictHotspotsImgTxt', defaultMessage: 'Please see below for an example of this report (this shows example data from our demo account - this is not your data). The full report shows the 50 highest and 50 lowest scoring Conflict Hotspot combinations.'}),
            text: <FormattedMessage id="brcgs.upgradePrioConflictHotspots" defaultMessage="This report shows areas of greatest conflict between employee prioritisation and perceived company prioritisations. Areas of misalignment of prioritisation are important to understand and address." />,
        },
        'risks': {
            title: <FormattedMessage id="reportmenu.riskRatings" defaultMessage="Risk Ratings" />,
            img: risks,
            text: <FormattedMessage id="brcgs.upgradeRisks" defaultMessage="This report shows the awareness and understanding of risk at the site. Specifically – employees are asked to evaluate the likelihood of various types of food safety risk in the scenario that the food safety controls were to fail at the site."/>,
        },
        'benchmark-company': {
            title: <Fragment><FormattedMessage id="reportmenu.benchmarking" defaultMessage="Benchmarking" /> -&gt; <FormattedMessage id="reportmenu.categoriesAndDimensions" defaultMessage="Categories & Dimensions" /> -&gt; <FormattedMessage id="reportmenu.benchmarkCompany" defaultMessage="Company" /></Fragment>,
            img: benchmarkCompany,
            text: <FormattedMessage id="brcgs.upgradeBenchCompany" defaultMessage="This report shows how the site's Category and Dimension scores compare to the rest of the company. Note: this only applies in the case of multi-site upgrade, i.e. when multiple sites in the same company are upgraded." />,
        },
        'benchmark-region': {
            title: <Fragment><FormattedMessage id="reportmenu.benchmarking" defaultMessage="Benchmarking" /> -&gt; <FormattedMessage id="reportmenu.categoriesAndDimensions" defaultMessage="Categories & Dimensions" /> -&gt; <FormattedMessage id="reportmenu.benchmarkRegion" defaultMessage="Region" /></Fragment>,
            img: benchmarkRegion,
            text: <FormattedMessage id="brcgs.upgradeBenchRegion" defaultMessage="This report shows how the site's Category and Dimension scores compare to other sites in a selected region (Africa, Arab States, Asia and Pacific, Europe, Middle East, North America or South/Latin America)." />,
        },
        'benchmark-country': {
            title: <Fragment><FormattedMessage id="reportmenu.benchmarking" defaultMessage="Benchmarking" /> -&gt; <FormattedMessage id="reportmenu.categoriesAndDimensions" defaultMessage="Categories & Dimensions" /> -&gt; <FormattedMessage id="reportmenu.benchmarkCountry" defaultMessage="Country"/></Fragment>,
            img: benchmarkCountry,
            text: <FormattedMessage id="brcgs.upgradeBenchCountry" defaultMessage="This report shows how the site's Category and Dimension scores compare to other sites in one or more selected countries." />,
        },
        'benchmark-elements-company': {
            title: <Fragment><FormattedMessage id="reportmenu.benchmarking" defaultMessage="Benchmarking" /> -&gt; <FormattedMessage id="reportmenu.elements" defaultMessage="Elements" /> -&gt; <FormattedMessage id="reportmenu.benchmarkCompany" defaultMessage="Company" /></Fragment>,
            img: benchmarkElementsCompany,
            imgText: intl.formatMessage({id: 'brcgs.upgradeBenchElementsCompanyImgTxt', defaultMessage: 'Please see below for an example of this report (this shows example data from our demo account - this is not your data). The full report shows benchmarking for all 82 Elements.'}),
            text: <FormattedMessage id="brcgs.upgradeBenchElementsCompany" defaultMessage="This report shows how the site's Element scores compare to the rest of the company. Note: this only applies in the case of multi-site upgrade, i.e. when multiple sites in the same company are upgraded." />,
        },
        'elements-comparison': {
            title: <Fragment><FormattedMessage id="reportmenu.changeOverTime" defaultMessage="Change Over Time" /> -&gt; <FormattedMessage id="reportmenu.elements" defaultMessage="Elements" /></Fragment>,
            img: elementsComparison,
            imgText: intl.formatMessage({id: 'brcgs.upgradeElementsComparisonImgTxt', defaultMessage: 'Please see below for an example of this report (this shows example data from our demo account - this is not your data).The full report shows change over time for all 82 Elements.'}),
            text: <FormattedMessage id="brcgs.upgradeElementsComparison" defaultMessage="This report shows how the site's Element scores compare over time. I.e. if a site has completed an assessment previously, they can compare the latest versus previous Elements scores. This enables an evaluation of whether prior action planning and actions taken have had the intended impact at Element level." />,
        },
        'priorities-comparison': {
            title: <Fragment><FormattedMessage id="reportmenu.changeOverTime" defaultMessage="Change Over Time" /> -&gt; <FormattedMessage id="reportmenu.priorities" defaultMessage="Priorities" /></Fragment>,
            img: prioritiesComparison,
            text: <FormattedMessage id="brcgs.upgradePrioComparison" defaultMessage="This report shows how employee prioritisations and employee perception of company prioritisations have changed over time. I.e. if a site has completed a BRCGS assessment previously, they can compare the prioritisations with previous prioritisations. This enables an evaluation of whether prior action planning and actions taken have had the intended impact." />,
        },
        'risks-comparison': {
            title: <Fragment><FormattedMessage id="reportmenu.changeOverTime" defaultMessage="Change Over Time" /> -&gt; <FormattedMessage id="reportmenu.risks" defaultMessage="Risks" /></Fragment>,
            img: risksComparison,
            text: <FormattedMessage id="brcgs.upgradeRisksComparison" defaultMessage="This report shows how employee awareness and understanding of food safety risks have changed over time. I.e. if a site has completed a BRCGS assessment previously, they can compare the risk ratings with previous ratings. This enables an evaluation of whether prior action planning and actions taken have had the intended impact." />,
        },
        'progression': {
            title: <Fragment><FormattedMessage id="reportmenu.changeOverTime" defaultMessage="Change Over Time" /> -&gt; <FormattedMessage id="reportmenu.progression" defaultMessage="Progression" /></Fragment>,
            img: null,
            text: null,
        },
        'correlation': {
            title: <Fragment><FormattedMessage id="reportmenu.information" defaultMessage="Information" /> -&gt; <FormattedMessage id="reportmenu.correlation" defaultMessage="Correlation" /></Fragment>,
            img: correlation,
            text: <FormattedMessage id="brcgs.upgradeCorrelation" defaultMessage="This tool enables sites to plot correlations between their culture assessment scores and other metrics. It allows users to add custom metrics (including, but no limited to, certification audit scores/grades, internal audit scores, complaint occurrences, regulatory audits) and enter data for sites. The report then generates graphs to show the correlation between culture scores and the customised metrics." />,
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        updateLoading(true);
        ContactService.brcgsUpgrade(parseInt(siteCount), props.surveyData.id)
        .then(function () {
            setDialogOpen(true);
        }).catch(function (error) {
            updateNotification(true, 'An unknown error occurred!', 'error');
            console.error(error);
        }).then(function() {
            updateLoading(false);
        });
    };

    if (!(type in reports) && type !== 'overview') {
        return null;
    }

    return (
        <Fragment>
            <Grid container spacing={0} justifyContent="center">
                <Grid item sm={12} md={10} lg={8}>
                    { type === 'overview' ?
                        <Fragment>
                            <Typography variant="h6">
                                <FormattedMessage id="brcgs.upgradeToPremium" defaultMessage="Upgrade to Premium" />
                            </Typography>
                            <Box py={2}>
                                <Typography variant="body1">
                                    <FormattedMessage id="brcgs.upgradeIntro" defaultMessage="If you would like to access a larger dataset and additional report types, these are available via upgrade to premium reporting. These additional data points are provided:" />
                                </Typography>
                                <ul>
                                    <li>
                                        <Typography variant="body1"><FormattedMessage id="brcgs.upgradePt1" defaultMessage="76 additional Element (question) scores."/></Typography>
                                    </li>
                                    <li>
                                        <Typography variant="body1"><FormattedMessage id="brcgs.upgradePt2" defaultMessage="Response distribution for 82 Elements."/></Typography>
                                    </li>
                                    <li>
                                        <Typography variant="body1"><FormattedMessage id="brcgs.upgradePt3" defaultMessage="100 hotspot scores (pockets of strength and weakness by demographic group)."/></Typography>
                                    </li>
                                    <li>
                                        <Typography variant="body1"><FormattedMessage id="brcgs.upgradePt4" defaultMessage="Priorities (priority ranking from employee perspective and perceived company perspective)."/></Typography>
                                    </li>
                                    <li>
                                        <Typography variant="body1"><FormattedMessage id="brcgs.upgradePt5" defaultMessage="Priority hotspots (areas of priority conflict and alignment by demographic group)."/></Typography>
                                    </li>
                                    <li>
                                        <Typography variant="body1"><FormattedMessage id="brcgs.upgradePt6" defaultMessage="10 additional cultural positive scores."/></Typography>
                                    </li>
                                    <li>
                                        <Typography variant="body1"><FormattedMessage id="brcgs.upgradePt7" defaultMessage="11 additional cultural barriers scores."/></Typography>
                                    </li>
                                    <li>
                                        <Typography variant="body1"><FormattedMessage id="brcgs.upgradePt8" defaultMessage="12 perceived operational risk scores."/></Typography>
                                    </li>
                                    <li>
                                        <Typography variant="body1"><FormattedMessage id="brcgs.upgradePt9" defaultMessage="Enhanced regional benchmarking."/></Typography>
                                    </li>
                                    <li>
                                        <Typography variant="body1"><FormattedMessage id="brcgs.upgradePt10" defaultMessage="Enhanced country benchmarking."/></Typography>
                                    </li>
                                    <li>
                                        <Typography variant="body1"><FormattedMessage id="brcgs.upgradePt11" defaultMessage="Enhanced industry benchmarking."/></Typography>
                                    </li>
                                    <li>
                                        <Typography variant="body1"><FormattedMessage id="brcgs.upgradePt12" defaultMessage="Change over time for 82 Elements (when applicable for sites with prior assessments)."/></Typography>
                                    </li>
                                    <li>
                                        <Typography variant="body1"><FormattedMessage id="brcgs.upgradePt13" defaultMessage="Change over time for priority rankings (when applicable)."/></Typography>
                                    </li>
                                    <li>
                                        <Typography variant="body1"><FormattedMessage id="brcgs.upgradePt14" defaultMessage="Change over time for risk scores (when applicable)."/></Typography>
                                    </li>
                                </ul>
                                <Typography variant="body1">
                                    <FormattedMessage id="brcgs.upgradeMoreDetails" defaultMessage="More details are provided in the dedicated pages under the 'Upgrade' menu on the left of your screen." />

                                </Typography>
                                <Box py={1}>
                                    <Typography variant="body1">
                                        <FormattedMessage id="brcgs.upgradeCost" defaultMessage="The cost for upgrade is GBP 850 for the first site and GBP 200 per additional site upgraded simultaneously. To apply for an upgrade please complete the following form:" />
                                    </Typography>
                                </Box>

                                <Box py={1}>
                                    <form onSubmit={handleSubmit}>
                                        <Box py={1}>
                                            <TextField
                                                id="siteCount"
                                                type="number"
                                                required
                                                label={intl.formatMessage({id: 'brcgs.upgradeNoSites', defaultMessage: 'Number of sites'})}
                                                helperText={intl.formatMessage({id: 'brcgs.upgradeNoSitesLabel', defaultMessage: 'How many sites would you like to upgrade?'})}
                                                value={siteCount}
                                                onChange={(e) => setSiteCount(e.target.value)}
                                                margin="normal"
                                                InputProps={{
                                                    inputProps: {
                                                        step: 1,
                                                        min: 1,
                                                        pattern: "[0-9]+" // only allow whole numbers, no - or .
                                                    },
                                                }}
                                            />
                                        </Box>
                                        <FormControl>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={consent}
                                                        onChange={(e) => setConsent(e.target.checked)}
                                                        name="enabled"
                                                        color="secondary"
                                                    />
                                                }
                                                label={
                                                    <div style={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        flexWrap: 'wrap',
                                                    }}>
                                                        <FormattedMessage id="brcgs.upgradeConsent" defaultMessage="Please confirm your consent to be contacted regarding upgrade" />
                                                    </div>
                                                }
                                            />
                                        </FormControl>
                                        <Box py={1}>
                                            <Button
                                                variant="contained"
                                                className="submit"
                                                color="secondary"
                                                type="submit"
                                                disabled={siteCount <= 0 || !consent || loading}
                                            >
                                                <FormattedMessage id="generic.submit" defaultMessage="Submit" />
                                            </Button>
                                        </Box>
                                    </form>
                                </Box>
                            </Box>
                        </Fragment>

                    :
                        <Fragment>
                            <Typography variant="body1">
                                <FormattedMessage
                                    id="brcgs.upgradeReportName"
                                    defaultMessage="The {reportName} report is available via upgrade."
                                    values={{
                                        reportName: <strong>{reports[type].title}</strong>
                                    }} />
                            </Typography>
                            { reports[type].text ?
                                <Box py={2}>
                                    <Typography variant="body1">{ reports[type].text }</Typography>
                                </Box>
                            : null }
                            { reports[type].img ?
                                <Box py={2}>
                                    <Typography variant="body1">{reports[type].imgText ? reports[type].imgText : intl.formatMessage({id: 'brcgs.upgradeSeeBelow', defaultMessage: 'Please see below for an example of this report (this shows example data from our demo account - this is not your data)'}) }</Typography>
                                    <Box py={2}>
                                        <Paper elevation={5}>
                                            <img onClick={() => {setModalOpen(true); return false;}} className={classes.img} src={reports[type].img} alt={type} />
                                        </Paper>
                                    </Box>
                                </Box>
                            : null }
                        </Fragment>
                    }
                </Grid>
            </Grid>
            <Dialog
                scroll="body"
                style={{paddingTop: 40}}
                fullWidth={true}
                maxWidth="xl"
                open={modalOpen}
                onClose={() => setModalOpen(false)}
            >
                <DialogContent>
                    { type !== 'overview' ?
                        <div style={{outline:'none'}}>
                            { reports[type].img ?
                                <img className={classes.img} src={reports[type].img} alt={type} onClick={() => {setModalOpen(false); return false;}} />
                            : null }
                        </div>
                    : null }
                </DialogContent>
            </Dialog>
            <Dialog
                open={dialogOpen}
                onClose={() => {setDialogOpen(false)}}
            >
                <DialogTitle><FormattedMessage id="brcgs.upgradeThankYou" defaultMessage="Thank you for your request" /></DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <FormattedMessage id="brcgs.upgradeWillBeInContact" defaultMessage="Someone will be in contact shortly to assist." />
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {setDialogOpen(false)}} color="secondary" autoFocus>
                        <FormattedMessage id="generic.close" defaultMessage="Close" />
                    </Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    );
}
