import axios from 'axios';
import BaseService from './BaseService';

class SurveySheetService extends BaseService {

    sheets(companyUuid, offset = 0, limit = 50, sort = null, search = null, filter = null, include = null) {
        const params = {
            'page[offset]': offset,
            'page[limit]': limit,
            'search': search,
            'sort': sort,
        };

        if (include) {
            params['include'] = include;
        }

        if (filter && 0 !== Object.keys(filter).length) {
            Object.keys(filter).forEach(key => {
                params['filter['+key+']'] = filter[key];
            });
        }

        return axios.get(this.apiUrl('/survey-sheets'), {
            params: params,
            headers: this.getAuthHeader()
        });
    }

    sheet(uuid) {
        return axios.get(this.apiUrl('/survey-sheets/'+uuid), {
            headers: this.getAuthHeader()
        });
    }

    createSheet(data, relationships, createFromSurvey = null, groupSitesByDemographic = null) {
        const params = {};

        if (createFromSurvey !== null) {
            params['createFromSurvey'] = createFromSurvey;
        }

        if (groupSitesByDemographic !== null) {
            params['groupSitesByDemographic'] = groupSitesByDemographic;
        }

        return axios.post(this.apiUrl('/survey-sheets'), {
            data: {
                type: 'survey-sheet',
                attributes: data,
                relationships: relationships
            },
        },{
            params: params,
            headers: this.getAuthHeader()
        });
    }

    apply(uuid) {
        return axios.post(this.apiUrl('/survey-sheets/'+uuid+'/apply'), {
            data: null
        },{
            headers: this.getAuthHeader()
        })
    }

    patch(uuid, data) {
        return axios.patch(this.apiUrl('/survey-sheets/'+uuid), {
            data: {
                type: 'survey-sheet',
                attributes: data
            }
        },{
            headers: this.getAuthHeader()
        });
    }

    delete(uuid) {
        return axios.delete(this.apiUrl('/survey-sheets/'+uuid), {
            headers: this.getAuthHeader()
        });
    }
}

export default new SurveySheetService();
