import { Box, Button, Grid, Paper, Typography } from "@material-ui/core";
import { BRCGS_CHART_COLORS } from "../../../Constants/BrcgsChartColors";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import { useState, Fragment } from "react";
import { Link as RouterLink } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";
import { positiveTranslated } from "../../Translation/Positive";
import BarLazy from "../../Chart/BarLazy";
import { BRCGS_CATEGORY_COLORS } from "../../../Constants/BrcgsCategoryColors";

export default function PositivesBrcgs(props) {
    const intl = useIntl();
    const [unit, setUnit] = useState('percentages');

    if (!props.reportData.data || !props.surveyData.type) {
        return null;
    }

    const chartData = {
        labels: [],
        datasets: [{
                barPercentage: 0.5,
                data: [],
                backgroundColor: [],
                borderColor: [],
                hoverBackgroundColor: [],
                hoverBorderColor: [],
        }],
    };

    BRCGS_CHART_COLORS.forEach(colors => {
        chartData.datasets[0].backgroundColor.push(colors['backgroundColor']);
        chartData.datasets[0].borderColor.push(colors['borderColor']);
        chartData.datasets[0].hoverBackgroundColor.push(colors['hoverBackgroundColor']);
        chartData.datasets[0].hoverBorderColor.push(colors['hoverBorderColor']);
    });

    let max = 0;
    Object.keys(props.reportData.data).forEach(index => {
        if ('other' !== props.reportData.data[index].title.toLowerCase()) {
            chartData.labels.push(positiveTranslated(props.reportData.data[index].title, intl))
        } else {
            // Others should be multi to save space
            chartData.labels.push([
                intl.formatMessage({id: 'positives.other', defaultMessage: 'Other'}),
                intl.formatMessage({id: 'positives.otherNote', defaultMessage: '(see Qualitative Comments)'}),
            ]);
        }

        if (unit === 'percentages') {
            chartData.datasets[0].data.push(props.reportData.data[index].percentage);
            max = props.reportData.data[index].percentage > max ? props.reportData.data[index].percentage : max;
        } else {
            chartData.datasets[0].data.push(props.reportData.data[index].count)
            max = props.reportData.data[index].count > max ? props.reportData.data[index].count : max;
        }

    });

    const chartOptions = {
        maintainAspectRatio: false,
        scales: {
            x: {
                title: {
                    display: true,
                    text: unit === 'percentages' ? intl.formatMessage({id: 'positives.percent', defaultMessage: 'Percent (%)'}) : intl.formatMessage({id: 'positives.responses', defaultMessage: 'Responses'})
                },
                min: 0,
                max: max + (max/10), // Add a bit of space at the end to make sure datalabels do not overflow
                ticks: {
                    callback: (value, index, values) => {
                        return index === values.length-1 ? '' : value
                    }
                },
            }
        },
        indexAxis: 'y',
        animation: {
            duration: 0,
        },
        plugins: {
            legend: {
                display: false,
            },
            tooltip: {
                callbacks: {
                    label: (context) => {
                        const label = context.label || '';
                        if ('percentages' === unit) {
                            return label + ': ' + context.parsed.x.toFixed(1) + '%';
                        } else {
                            return label + ': ' + context.parsed.x;
                        }

                    }
                },
            },
            datalabels: {
                anchor: 'end',
                align: 'end',
                clamp: true,
                offset: 5,
                formatter: (value) => {
                    return value && 'percentages' === unit ? value.toFixed(0)+' %' : value;
                }
            }
        },
    };

    return (
        <Fragment>
            <Box display="flex" justifyContent="center">
                <ToggleButtonGroup
                    value={unit}
                    exclusive
                    onChange={(event, newUnit) => setUnit(newUnit)}
                >
                    <ToggleButton value="percentages" style={{textTransform: 'none'}}>
                        <FormattedMessage id="positives.percentages" defaultMessage="Percentages"/>
                    </ToggleButton>
                    <ToggleButton value="numbers" style={{textTransform: 'none'}}>
                        <FormattedMessage id="positives.numbers" defaultMessage="Numbers"/>
                    </ToggleButton>
                </ToggleButtonGroup>
            </Box>
            <div ref={props.setScreenshotRef}>
                <BarLazy data={chartData} options={chartOptions} dataLabels={true} height={500} />
            </div>

            <Grid container justifyContent="center">
                <Grid item sm={5} xs={10}>
                    <Paper variant="outlined" style={{borderColor: BRCGS_CATEGORY_COLORS['people'].dark, backgroundColor: BRCGS_CATEGORY_COLORS['people'].light, marginTop: 10}}>
                        <Box p={1} px={2}>
                            <Typography variant="subtitle1" align="center">
                                The most-selected perceived Positives that contribute to food safety culture are shown above. Scoring for 10 additional Positives is accessible via upgrade.
                            </Typography>
                            <Box p={1} textAlign='center'>
                                <Button component={RouterLink} variant="contained" color="secondary" to={'/survey-report/'+props.surveyData.attributes.combinedHash+'/upgrade?type=overview'}>
                                    Click here to learn more about upgrade
                                </Button>
                            </Box>
                        </Box>
                    </Paper>
                </Grid>
            </Grid>
        </Fragment>
    );
}
