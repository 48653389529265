import { Button, Paper, Box, Grid, Typography } from "@material-ui/core";
import { Fragment, useContext, useEffect } from "react";
import { BRCGS_CATEGORY_COLORS } from "../../Constants/BrcgsCategoryColors";
import { AppBarTitleContext } from "../../Context/AppBarTitleContext";

export default function BrcgsFree() {
    const { updateTitle } = useContext(AppBarTitleContext);

    useEffect(() => {
        updateTitle(null);
    });

    return(
        <Fragment>
            <Grid container spacing={1} justifyContent="center">
                <Grid item sm={false} lg={3}></Grid>
                <Grid item sm={12} lg={6}>
                    <Paper variant="outlined" style={{ borderColor: BRCGS_CATEGORY_COLORS['people'].light, backgroundColor: BRCGS_CATEGORY_COLORS['people'].dark, color: 'white'}} pb={2}>
                        <Box p={1} textAlign='center'>
                            <Typography variant="subtitle1" align="center">
                                The BRCGS Food Safety Culture Excellence (FSCE) Module provides a full assessment of a site's food safety culture. It includes the following:
                            </Typography>
                        </Box>
                    </Paper>
                </Grid>
                <Grid item sm={false} lg={3}></Grid>

                <Grid item sm={false} lg={3}></Grid>
                <Grid item sm={12} lg={6}>
                    <Paper variant="outlined" style={{borderColor: BRCGS_CATEGORY_COLORS['people'].dark, backgroundColor: BRCGS_CATEGORY_COLORS['people'].light}} pb={2}>
                        <Box p={1} textAlign='center'>
                            <Typography variant="subtitle1" align="center">
                                A total score and grade, scores for the four Ps (People, Process, Purpose and Proactivity) and 20 Dimensions
                            </Typography>
                        </Box>
                    </Paper>
                </Grid>
                <Grid item sm={false} lg={3}></Grid>

                <Grid item sm={false} lg={3}></Grid>
                <Grid item sm={12} lg={6}>
                    <Paper variant="outlined" style={{borderColor: BRCGS_CATEGORY_COLORS['process'].dark, backgroundColor: BRCGS_CATEGORY_COLORS['process'].light}} pb={2}>
                        <Box p={1} textAlign='center'>
                            <Typography variant="subtitle1" align="center">
                                Industry and product category benchmarking for site culture score and grade
                            </Typography>
                        </Box>
                    </Paper>
                </Grid>
                <Grid item sm={false} lg={3}></Grid>

                <Grid item sm={false} lg={3}></Grid>
                <Grid item sm={12} lg={6}>
                    <Paper variant="outlined" style={{borderColor: BRCGS_CATEGORY_COLORS['people'].dark, backgroundColor: BRCGS_CATEGORY_COLORS['people'].light}} pb={2}>
                        <Box p={1} textAlign='center'>
                            <Typography variant="subtitle1" align="center">
                                Element scores for the two lowest and highest scoring questions
                            </Typography>
                        </Box>
                    </Paper>
                </Grid>
                <Grid item sm={false} lg={3}></Grid>

                <Grid item sm={false} lg={3}></Grid>
                <Grid item sm={12} lg={6} style={{width: '100%'}}>
                    <Paper variant="outlined" style={{borderColor: BRCGS_CATEGORY_COLORS['process'].dark, backgroundColor: BRCGS_CATEGORY_COLORS['process'].light}} pb={2}>
                        <Box p={1} textAlign='center'>
                            <Typography variant="subtitle1" align="center">
                                Perceived barriers and positives of culture
                            </Typography>
                        </Box>
                    </Paper>
                </Grid>
                <Grid item sm={false} lg={3}></Grid>

                <Grid item sm={false} lg={3}></Grid>
                <Grid item sm={12} lg={6} style={{width: '100%'}}>
                    <Paper variant="outlined" style={{borderColor: BRCGS_CATEGORY_COLORS['people'].dark, backgroundColor: BRCGS_CATEGORY_COLORS['people'].light}} pb={2}>
                        <Box p={1} textAlign='center'>
                            <Typography variant="subtitle1" align="center">
                                An example report is provided below (click to download):
                            </Typography>
                        </Box>
                        <Box p={1} textAlign='center'>
                            <a href="https://resources.culturexcellence.com/BRCGS_FSCE_Basic_Sample_Report.pdf" target="_blank" rel="noreferrer">
                                <img src="https://resources.culturexcellence.com/BRCGS_FSCE_Basic_Sample_Report.png" alt="Sample report" width="320" />
                            </a>
                        </Box>
                    </Paper>
                </Grid>
                <Grid item sm={false} lg={3}></Grid>

                <Grid item sm={8} lg={3}>
                    <Box p={1} textAlign='center'>
                        <Button fullWidth rel="noreferrer" target="_blank" variant="contained" color="primary" href={"https://www.brcgs.com/our-standards/food-safety-culture-excellence/"} style={{ backgroundColor: BRCGS_CATEGORY_COLORS['people'].dark}}>
                            Learn more about the full BRCGS FSCE assessment
                        </Button>
                    </Box>
                    <Box p={1} textAlign='center'>
                        <Button fullWidth rel="noreferrer" target="_blank" variant="contained" color="primary" href={"https://www.brcgs.com/product/food-safety-culture-excellence/p-687/"} style={{ backgroundColor: BRCGS_CATEGORY_COLORS['people'].dark}}>
                            Purchase the full BRCGS FSCE assessment
                        </Button>
                    </Box>
                </Grid>
            </Grid>
            <Box pb={2} />
        </Fragment>
    );
}
