import { Fragment } from "react";
import { FormattedMessage } from "react-intl";

export default function DefinitionsAndIdeasForImprovement(props) {

    return (<Fragment>
        { props.title && props.print ? <h2 style={{fontWeight: 400}}>{props.title}</h2> : null}
        <p>
            <FormattedMessage
                id="execSummaryDefAndIdeasForImp.p1"
                defaultMessage={
                    "For reference, definitions of terms used in this report can be seen in the <strong>Definitions</strong> "+
                    "view, and ideas for cultural improvements can be seen in the <strong>Ideas for Improvement</strong> "+
                    "view."
                }
                values={{
                    strong: chunks => <strong>{chunks}</strong>
                }}
            />
        </p>
    </Fragment>);
}
