import { Fragment, useState } from "react";
import { makeStyles, ThemeProvider, createTheme, StylesProvider, jssPreset } from '@material-ui/core/styles';
import { create } from 'jss';
import rtl from 'jss-rtl'
import { Link as RouterLink, BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import AppBar from '@material-ui/core/AppBar';
import Link from '@material-ui/core/Link';
import NavDrawer from './../NavDrawer/NavDrawer';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Box from '@material-ui/core/Box';
import Hidden from '@material-ui/core/Hidden';
import MenuIcon from '@material-ui/icons/Menu';
import SurveyRelaunch from './../SurveyRelaunch/SurveyRelaunch';
import Signup from './../Signup/Signup';
import Home from './../Home/Home';
import Surveys from './../Surveys/Surveys';
import SurveySheets from './../Surveys/SurveySheets';
import SurveySheetEdit from './../Surveys/SurveySheetEdit';
import SurveySheetPrint from './../Surveys/SurveySheetPrint';
import SurveyProgressReport from './../Surveys/SurveyProgressReport';
import Users from './../Users/Users';
import ElementMappings from './../ElementMappings/ElementMappings';
import SurveyCopyMerge from './../SurveyCopyMerge/SurveyCopyMerge';
import SurveyCopy from './../SurveyCopy/SurveyCopy';
import CustomReports from './../CustomReports/CustomReports';
import UserEdit from './../Users/UserEdit';
import UserNew from './../Users/UserNew';
import Companies from './../Companies/Companies';
import CompanyEdit from './../Companies/CompanyEdit';
import Sites from './../Sites/Sites';
import SiteEdit from './../Sites/SiteEdit';
import SurveyReport from './../SurveyReport/SurveyReport';
import defaultLogo from './img/default.png';
import ssafeLogo from './img/ssafe.png';
import brcgsLogo from './img/brcgs.png';
import { AccountCircle, Language } from '@material-ui/icons';
import { Backdrop, CircularProgress, Grid, Menu, MenuItem, Snackbar, SnackbarContent, Typography } from '@material-ui/core';
import { UserContext } from '../../Context/UserContext';
import { AppBarTitleContext } from '../../Context/AppBarTitleContext';
import { NotificationContext } from '../../Context/NotificationContext';
import { FilterContext } from '../../Context/FilterContext';
import { LocaleContext } from '../../Context/LocaleContext';
import CloseIcon from '@material-ui/icons/Close';
import SettingsIcon from '@material-ui/icons/Settings';
import InfoIcon from '@material-ui/icons/Info';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import WarningIcon from '@material-ui/icons/Warning';
import ErrorIcon from '@material-ui/icons/Error';
import { SpinnerContext } from '../../Context/SpinnerContext';
import Verify from '../Auth/Verify/Verify';
import ForgotPassword from '../Auth/ForgotPassword/ForgotPassword';
import Login from '../Auth/Login/Login';
import SetPassword from '../Auth/SetPassword/SetPassword';
import Logout from '../Auth/Logout/Logout';
import BrcgsFree from '../Upgrade/BrcgsFree';
import Ssafe from '../Upgrade/Ssafe';
import Resources from '../Resources/Resources';
import defaultTheme from '../../Themes/default';
import brcgsTheme from '../../Themes/brcgs';
import ssafeTheme from '../../Themes/ssafe';
import { IntlProvider } from 'react-intl';
import localeAr from '../../CompiledLang/ar.json';
import localeDa from '../../CompiledLang/da.json';
import localeEn from '../../CompiledLang/en.json';
import localeEs from '../../CompiledLang/es.json';
import localeFr from '../../CompiledLang/fr.json';
import localeRu from '../../CompiledLang/ru.json';
import localeZh from '../../CompiledLang/zh.json';
import { FormattedMessage } from "react-intl";
import Account from '../Account/Account';
import VerifyNewEmail from '../Account/VerifyNewEmail';
import SsafeLanguage from '../Signup/SsafeLanguage';
import UserService from '../../Services/UserService';
import UserPermissions from "../Users/UserPermissions";
import SiteMappings from "../Companies/SiteMappings";
import ImportSurvey from "../Companies/ImportSurvey";
import CompanyNew from "../Companies/CompanyNew";
import BrcgsRegister from "../Brcgs/BrcgsRegister";
import SurveyClose from "../Surveys/SurveyClose";
import SurveyRevisions from "../Surveys/SurveyRevisions";
import RogueResponses from "../Surveys/RogueResponses";
import OptionLabels from "../Surveys/OptionLabels";
import SurveyTitle from "../Surveys/SurveyTitle";
import ElementsCompare from "../ElementsCompare/ElementsCompare";
import ErrorBoundary from "../ErrorBoundary/ErrorBoundary";
import ForceTwoFactor from "../ForceTwoFactor/ForceTwoFactor";
import TokenErrorHandler from "../TokenErrorHandler/TokenErrorHandler";
import { NO_LOGIN_PATHS } from "../../Constants/NoLoginPaths";

const jss = create({ plugins: [...jssPreset().plugins, rtl()] });

const useStyles = makeStyles((theme) => ({
    grow: {
        flexGrow: 0, // TODO: What does this do?
    },
    menuDropdown: {
        paddingTop: '48px'
    },
    menuButton: {
        marginRight: theme.spacing(2),
        [theme.breakpoints.up('sm')]: {
            display: 'none',
        },
    },
    appBar: {
        zIndex: 1400
    },
    appBarTitle: {
        marginLeft: 'auto',
        paddingRight: '32px'
    },
    appBarButtons: {
        //marginLeft: 'auto',
    },
    toolbar: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        // Less padding on mobile
        [theme.breakpoints.down('xs')]: {
            padding: theme.spacing(1),
        },
        [theme.breakpoints.up('sm')]: {
            padding: theme.spacing(3),
        }
    },
    notificationError: {
        backgroundColor: theme.palette.error.main
    },
    notificationSuccess: {
        backgroundColor: theme.palette.success.main
    },
    notificationInfo: {
        backgroundColor: theme.palette.info.main
    },
    notificationWarning: {
        backgroundColor: theme.palette.warning.main
    },
    notificationIcon: {
        fontSize: 22,
        marginRight: 10,
        display: 'flex'
    },
    spinner: {
        zIndex: theme.zIndex.modal + 1,
    },
    navDrawerIcon: {
        [theme.breakpoints.down('xs')]: {
            padding: '3px'
        },
        [theme.breakpoints.up('sm')]: {
            padding: '6px'
        }
    },
    navDrawerIconArHack: {
        [theme.breakpoints.down('xs')]: {
            padding: '0px' // Hack to avoid top right icons sitting on top of each other in arabic on small mobile screens
        },
        [theme.breakpoints.up('sm')]: {
            padding: '6px'
        }
    }
}));

// Logos for navbar
const logo = {
    'default': (<img
        style={{ maxHeight: "54px", maxWidth: '100%' }}
        title="Culture Excellence"
        alt="Culture Excellence"
        src={defaultLogo}
    />),
    'ssafe': (<img
        style={{ maxHeight: "54px", maxWidth: '100%' }}
        title="SSAFE"
        alt="SSAFE"
        src={ssafeLogo}
    />),
    'brcgs': (<img
        style={{ maxHeight: "54px", maxWidth: '100%' }}
        title="BRCGS"
        alt="BRCGS"
        src={brcgsLogo}
    />)
};

// Context example with provider and consumer
// https://stackoverflow.com/questions/54738681/how-to-change-context-value-while-using-react-hook-of-usecontext


function App() {
    const localeMessages = {
        ar: localeAr,
        da: localeDa,
        en: localeEn,
        es: localeEs,
        fr: localeFr,
        ru: localeRu,
        zh: localeZh
    };
    const classes = useStyles();

    // SpinnerContext, global loading spinner
    const [loading, setLoading] = useState(false);
    const updateLoading = (loading) => {
        setLoading(loading);
    }

    // UserContext, store user and token in localstorage
    const [user, setUser] = useState(JSON.parse(localStorage.getItem('user')));
    const updateUser = (user) => {
        localStorage.setItem('user', JSON.stringify(user));
        setUser(user);
    };
    const [token, setToken] = useState(JSON.parse(localStorage.getItem('token')));
    const updateToken = (token) => {
        localStorage.setItem('token', JSON.stringify(token));
        setToken(token);
    };

    // NotificationContext, material-ui snackbar messages/alerts
    const [notification, setNotification] = useState({open: false, message: '', level: 'info'});
    const updateNotification = (open, message, level) => {
        setNotification({
            open: open,
            message: message,
            level: level,
        });
    }

    // FilterContext
    const [types, setTypes] = useState(null);
    const updateTypes = (types) => {
        setTypes(types);
    };
    const [demographics, setDemographics] = useState(null);
    const updateDemographics = (demographics) => {
        setDemographics(demographics);
    };
    const [surveyId, setSurveyId] = useState(null);
    const updateSurveyId = (surveyId) => {
        setSurveyId(surveyId);
    };

    // AppBarTitleContext
    const [title, setTitle] = useState(null);
    const updateTitle = (title) => {
        setTitle(title);
    }

    // LocaleContext
    const queryParams = new URLSearchParams(window.location.search);
    const [locale, setLocale] = useState(queryParams.get('locale') ? queryParams.get('locale') : (user && user.locale ? user.locale : 'en'));
    const updateLocale = (locale) => {
        setLocale(locale);
    }

    const handleCloseNotification = (event, reason) => {
        // Don't close when user clicks away, so it will only close on timeout OR clicking the x in the notification
        if ('clickaway' !== reason) {
            setNotification({
                open: false,
                message: '',
                level: notification.level,
            });
        }
    }
    const notificationStyle = () => {
        const level = notification.level;
        if ('info' === level) {
            return classes.notificationInfo;
        } else if ('success' === level) {
            return classes.notificationSuccess;
        } else if ('warning' === level) {
            return classes.notificationWarning;
        } else if ('error' === level) {
            return classes.notificationError;
        }
    }

    const notificationIcon = () => {
        if ('info' === notification.level) {
            return (<InfoIcon className={classes.notificationIcon} fontSize="small" />);
        } else if ('success' === notification.level) {
            return (<CheckCircleIcon className={classes.notificationIcon} fontSize="small" />);
        } else if ('warning' === notification.level) {
            return (<WarningIcon className={classes.notificationIcon} fontSize="small" />);
        } else if ('error' === notification.level) {
            return (<ErrorIcon className={classes.notificationIcon} fontSize="small" />);
        }
    }

    // Sidebar menu mobile
    const [mobileOpen, setMobileOpen] = useState(false);
    // Toggle mobile menu
    const handleDrawerToggle = () => {setMobileOpen(!mobileOpen);};

    // User menu
    const [userMenuAnchorElement, setUserMenuAnchorElement] = useState(null);
    const userMenuOpen = Boolean(userMenuAnchorElement);

    const handleAccountMenu = (event) => {
        if (userMenuAnchorElement) {
            setUserMenuAnchorElement(null);
        } else {
            setUserMenuAnchorElement(event.currentTarget);
            handleLocaleMenuClose();
            handleAdminMenuClose();
        }
    };

    const handleAccountMenuClose = () => {
        setUserMenuAnchorElement(null);
    };


    // Admin menu
    const [adminMenuAnchorElement, setAdminMenuAnchorElement] = useState(null);
    const adminMenuOpen = Boolean(adminMenuAnchorElement);

    const handleAdminMenu = (event) => {
        if (adminMenuAnchorElement) {
            setAdminMenuAnchorElement(null);
        } else {
            setAdminMenuAnchorElement(event.currentTarget);
            handleLocaleMenuClose();
            handleAccountMenuClose();
        }
    };

    const handleAdminMenuClose = () => {
        setAdminMenuAnchorElement(null);
    };

    // Locale menu
    const [localeMenuAnchorElement, setlocaleMenuAnchorElement] = useState(null);
    const localeMenuOpen = Boolean(localeMenuAnchorElement);

    const handleLocaleMenu = (event) => {
        if (localeMenuAnchorElement) {
            setlocaleMenuAnchorElement(null);
        } else {
            setlocaleMenuAnchorElement(event.currentTarget);
            handleAccountMenuClose();
            handleAdminMenuClose();
        }
    }

    const handleLocaleMenuClose = () => {
        setlocaleMenuAnchorElement(null);
    }

    const requireLogin = () => {
        const location = window.location;
        const isNoLoginPath = NO_LOGIN_PATHS.some(path => location.pathname.startsWith(path));
        if (!token || null === token.token || token.expiry < Math.floor(Date.now() / 1000)) {
            if (location.pathname !== '/' &&
                !isNoLoginPath
            ) {
                // Set where to redirect to after user has logged in, used and cleared in <Login/> component
                sessionStorage.setItem('redir', location.pathname + location.search);
            }
            return (<Redirect to="/logout" />);
        }
        return null;
    }

    // Branded logo for brcgs/ssafe users
    const logoImg = (user) => {
        const query = new URLSearchParams(window.location.search);
        if (query.get('theme') && 'brcgs' === query.get('theme')) {
            return logo.brcgs;
        } else if (query.get('theme') && 'ssafe' === query.get('theme')) {
            return logo.ssafe;
        } else if (user && -1 !== ['brcgs', 'brcgs-free', 'brcgs-admin'].indexOf(user.role)) {
            return logo.brcgs;
        } else if (user && 'ssafe' === user.role) {
            return logo.ssafe;
        } else if (window.location.pathname.startsWith('/signup/brcgs-basic')) {
            return logo.brcgs;
        } else if (window.location.pathname.startsWith('/signup/ssafe')) {
            return logo.ssafe;
        }
        return logo.default;
    };

    // Theme colors for brcgs/ssafe users
    const currentTheme = (user) => {
        // Theme override from URL parameters
        const query = new URLSearchParams(window.location.search);
        if (query.get('theme') && 'brcgs' === query.get('theme')) {
            return brcgsTheme;
        } else if (user && -1 !== ['brcgs', 'brcgs-free', 'brcgs-admin'].indexOf(user.role)) {
            return brcgsTheme;
        } else if (query.get('theme') && 'ssafe' === query.get('theme')) {
            return ssafeTheme;
        } else if (user && 'ssafe' === user.role) {
            return ssafeTheme
        } else if (window.location.pathname.startsWith('/signup/brcgs-basic')) {
            return brcgsTheme;
        } else if (window.location.pathname.startsWith('/signup/ssafe')) {
            return ssafeTheme;
        }
        return defaultTheme;
    };

    const setAccountLocale = (locale) => {
        if (user && token && token.expiry > Math.floor(Date.now() / 1000)) {
            // Set locale on user, ignore errors
            UserService.setLocale(locale).catch(function () {});

            // Set locale on localStorage user object
            updateUser({ ...user, locale: locale });
        }
    };

    const handleChangeLocale = (locale) => {
        handleLocaleMenuClose();
        setLocale(locale);
        setAccountLocale(locale);
    };

    const languageOptions = () => {

        let langOptions = {
            zh: '中文',
            en: 'English',
        };

        if (currentTheme(user) === ssafeTheme) {
            langOptions = {
                ar: 'عربى',
                zh: '中文',
                en: 'English',
                fr: 'Français',
                ru: 'Русский',
                es: 'Español',
            };
        }

        return Object.keys(langOptions).map(l => (
            <MenuItem key={l} selected={(locale === l)} onClick={() => { handleChangeLocale(l);}}>
                <span>{langOptions[l]}</span>
            </MenuItem>
        ));
    };

    const rtlLocales = ['ar'];

    const hideLocaleSelector = !(currentTheme(user) === ssafeTheme || currentTheme(user) === defaultTheme);

    const printView = window.location.pathname.endsWith('-print') || window.location.pathname.endsWith('/print');

    return (
        <ErrorBoundary>
            <LocaleContext.Provider value={{locale, updateLocale}}>
                <IntlProvider locale={locale} messages={(localeMessages[locale] ? localeMessages[locale] : localeMessages.en)} defaultLocale="en">
                    <StylesProvider jss={jss}>
                        <ThemeProvider theme={currentTheme(user)}>
                            <ThemeProvider
                                theme={(theme) =>
                                    createTheme({
                                        ...theme,
                                        direction: (-1 !== rtlLocales.indexOf(locale) ? 'rtl' : 'ltr')
                                    })
                                }
                            >
                                <AppBarTitleContext.Provider value={{ title, updateTitle }}>
                                    <UserContext.Provider value={{ user, updateUser, token, updateToken }}>
                                        <NotificationContext.Provider value={{ notification, updateNotification }}>
                                            <SpinnerContext.Provider value={{ loading, updateLoading }}>
                                                <Backdrop className={classes.spinner} open={loading}>
                                                    <CircularProgress color="secondary" />
                                                </Backdrop>
                                                <Snackbar
                                                    anchorOrigin={{
                                                        vertical: 'bottom',
                                                        horizontal: 'center',
                                                    }}
                                                    open={notification.open}
                                                    autoHideDuration={6000}
                                                    onClose={handleCloseNotification}
                                                >
                                                    <SnackbarContent
                                                        className={notificationStyle()}
                                                        message={
                                                            <Grid container direction="row" alignItems="center" wrap="nowrap" justifyContent="center">
                                                                <Grid item>{notificationIcon()}</Grid>
                                                                <Grid item>{notification.message}</Grid>
                                                            </Grid>
                                                        }
                                                        action={
                                                            <Fragment>
                                                                <IconButton size="small" color="inherit" onClick={handleCloseNotification}>
                                                                    <CloseIcon fontSize="small" />
                                                                </IconButton>
                                                            </Fragment>
                                                    }/>

                                                </Snackbar>
                                                <Router>
                                                    <Box display="flex">
                                                        <ForceTwoFactor />
                                                        <TokenErrorHandler />
                                                        <div className={classes.grow}>
                                                            {printView ? null :
                                                                <AppBar position="fixed" className={classes.appBar}>
                                                                    <Toolbar>
                                                                        {user ?
                                                                            <IconButton
                                                                                color="inherit"
                                                                                edge="start"
                                                                                onClick={handleDrawerToggle}
                                                                                className={classes.menuButton}
                                                                            >
                                                                                <MenuIcon />
                                                                            </IconButton>
                                                                        : null}
                                                                        <Box style={{maxHeight: '64px', maxWidth: '50%'}}>
                                                                            <Link component={RouterLink} to="/home" style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                                                                {logoImg(user)}
                                                                            </Link>
                                                                        </Box>
                                                                        {/* <div className={classes.grow}></div> */}
                                                                        <NavDrawer mobileOpen={mobileOpen} handleDrawerToggle={handleDrawerToggle} />
                                                                        <div className={classes.appBarTitle}>
                                                                            <Hidden xsDown implementation="css">
                                                                                <Typography title={title} variant="h6" style={{
                                                                                    maxWidth: '45vw',
                                                                                    overflow: 'hidden',
                                                                                    whiteSpace: 'nowrap',
                                                                                    textOverflow: 'ellipsis',
                                                                                    fontWeight: 500,
                                                                                    fontSize: '0.875rem'
                                                                                }}>
                                                                                    {title}
                                                                                </Typography>
                                                                            </Hidden>
                                                                        </div>
                                                                        <div className={classes.appBarButtons}>
                                                                            {user && user.role === 'admin' ?
                                                                                <Fragment>
                                                                                    <IconButton
                                                                                        color="inherit"
                                                                                        onClick={handleAdminMenu}
                                                                                        className={'ar' === locale ? classes.navDrawerIconArHack : classes.navDrawerIcon}
                                                                                    >
                                                                                        <SettingsIcon />
                                                                                    </IconButton>
                                                                                    <Menu
                                                                                        PopoverClasses={{
                                                                                            paper: classes.menuDropdown
                                                                                        }}
                                                                                        anchorEl={adminMenuAnchorElement}
                                                                                        anchorOrigin={{
                                                                                            vertical: 'top',
                                                                                            horizontal: 'center',
                                                                                        }}
                                                                                        keepMounted
                                                                                        transformOrigin={{
                                                                                            vertical: 'top',
                                                                                            horizontal: 'center',
                                                                                        }}
                                                                                        open={adminMenuOpen}
                                                                                        onClose={handleAdminMenuClose}
                                                                                    >
                                                                                        <Link component={RouterLink} to="/element-mappings">
                                                                                            <MenuItem onClick={handleAdminMenuClose} selected={window.location.pathname.startsWith('/element-mappings')}>
                                                                                                Element Mappings
                                                                                            </MenuItem>
                                                                                        </Link>
                                                                                        <Link component={RouterLink} to="/survey-merge-copy">
                                                                                            <MenuItem onClick={handleAdminMenuClose} selected={window.location.pathname.startsWith('/survey-merge-copy')}>
                                                                                                Merge Surveys
                                                                                            </MenuItem>
                                                                                        </Link>
                                                                                        <Link component={RouterLink} to="/survey-copy">
                                                                                            <MenuItem onClick={handleAdminMenuClose} selected={window.location.pathname.startsWith('/survey-copy')}>
                                                                                                Copy Survey
                                                                                            </MenuItem>
                                                                                        </Link>
                                                                                        <Link component={RouterLink} to="/custom-reports">
                                                                                            <MenuItem onClick={handleAdminMenuClose} selected={window.location.pathname.startsWith('/custom-reports')}>
                                                                                                Custom Reports
                                                                                            </MenuItem>
                                                                                        </Link>
                                                                                        <Link component={RouterLink} to="/elements-compare">
                                                                                            <MenuItem onClick={handleAdminMenuClose} selected={window.location.pathname.startsWith('/elements-compare')}>
                                                                                                Elements Comparison
                                                                                            </MenuItem>
                                                                                        </Link>
                                                                                    </Menu>
                                                                                </Fragment>
                                                                            : null}
                                                                            <Fragment>
                                                                                <IconButton
                                                                                    color="inherit"
                                                                                    onClick={handleLocaleMenu}
                                                                                    className={'ar' === locale ? classes.navDrawerIconArHack : classes.navDrawerIcon}
                                                                                    style={(hideLocaleSelector ? {display: 'none'} : null)}
                                                                                >
                                                                                    <Language />
                                                                                </IconButton>
                                                                                <Menu
                                                                                    PopoverClasses={{
                                                                                        paper: classes.menuDropdown
                                                                                    }}
                                                                                    anchorEl={localeMenuAnchorElement}
                                                                                    anchorOrigin={{
                                                                                        vertical: 'top',
                                                                                        horizontal: 'center',
                                                                                    }}
                                                                                    keepMounted
                                                                                    transformOrigin={{
                                                                                        vertical: 'top',
                                                                                        horizontal: 'center',
                                                                                    }}
                                                                                    open={localeMenuOpen}
                                                                                    onClose={handleLocaleMenuClose}
                                                                                >
                                                                                    {languageOptions()}
                                                                                </Menu>
                                                                            </Fragment>
                                                                            {user ?
                                                                                <Fragment>
                                                                                    <IconButton
                                                                                        color="inherit"
                                                                                        onClick={handleAccountMenu}
                                                                                        className={'ar' === locale ? classes.navDrawerIconArHack : classes.navDrawerIcon}
                                                                                    >
                                                                                        <AccountCircle />
                                                                                    </IconButton>
                                                                                    <Menu
                                                                                        PopoverClasses={{
                                                                                            paper: classes.menuDropdown
                                                                                        }}
                                                                                        anchorEl={userMenuAnchorElement}
                                                                                        anchorOrigin={{
                                                                                            vertical: 'top',
                                                                                            horizontal: 'center',
                                                                                        }}
                                                                                        keepMounted
                                                                                        transformOrigin={{
                                                                                            vertical: 'top',
                                                                                            horizontal: 'center',
                                                                                        }}
                                                                                        open={userMenuOpen}
                                                                                        onClose={handleAccountMenuClose}
                                                                                    >

                                                                                        <Link component={RouterLink} to="/account">
                                                                                            <MenuItem onClick={handleAccountMenuClose}>
                                                                                                <FormattedMessage id="appbar.account" defaultMessage="Account"/>
                                                                                            </MenuItem>
                                                                                        </Link>
                                                                                        <Link component={RouterLink} to="/logout">
                                                                                            <MenuItem onClick={handleAccountMenuClose}>
                                                                                                <FormattedMessage id="appbar.logout" defaultMessage="Logout"/>
                                                                                            </MenuItem>
                                                                                        </Link>
                                                                                    </Menu>
                                                                                </Fragment>
                                                                            : null}
                                                                        </div>
                                                                    </Toolbar>
                                                                </AppBar>
                                                            }
                                                        </div>
                                                        <main className={classes.content} style={printView ? {padding: 0} : {}} dir={-1 !== rtlLocales.indexOf(locale) ? 'rtl' : 'ltr'}>
                                                            {printView ? null : <div className={classes.toolbar} />}
                                                            <Switch>
                                                                <Route exact path="/">
                                                                    <Redirect to="/home" />
                                                                </Route>
                                                                <Route exact path="/logout">
                                                                    {requireLogin()}
                                                                    <Logout />
                                                                </Route>
                                                                <Route exact path="/login">
                                                                    <Login />
                                                                </Route>
                                                                <Route exact path="/forgot-password">
                                                                    <ForgotPassword />
                                                                </Route>
                                                                <Route exact path="/brcgs/register">
                                                                    <BrcgsRegister />
                                                                </Route>
                                                                <Route exact path="/signup/ssafe-language">
                                                                    <SsafeLanguage />
                                                                </Route>
                                                                <Route exact path="/signup/:type">
                                                                    <Signup />
                                                                </Route>
                                                                <Route exact path="/verify">
                                                                    <Verify />
                                                                </Route>
                                                                <Route exact path="/setpassword">
                                                                    <SetPassword />
                                                                </Route>
                                                                <Route exact path ="/closesurvey">
                                                                    <SurveyClose />
                                                                </Route>
                                                                <Route exact path="/home">
                                                                    {requireLogin()}
                                                                    <Home />
                                                                </Route>
                                                                <Route exact path="/account">
                                                                    {requireLogin()}
                                                                    <Account />
                                                                </Route>
                                                                <Route exact path="/account/verify-new-email">
                                                                    {requireLogin()}
                                                                    <VerifyNewEmail />
                                                                </Route>
                                                                <Route exact path="/upgrade/brcgs-free">
                                                                    {requireLogin()}
                                                                    <BrcgsFree />
                                                                </Route>
                                                                <Route exact path="/upgrade/ssafe">
                                                                    {requireLogin()}
                                                                    <Ssafe />
                                                                </Route>
                                                                <Route exact path="/resources">
                                                                    {requireLogin()}
                                                                    <Resources />
                                                                </Route>
                                                                <Route exact path="/surveys">
                                                                    {requireLogin()}
                                                                    <Surveys />
                                                                </Route>
                                                                <Route exact path="/surveys/:hash/rogue-responses">
                                                                    {requireLogin()}
                                                                    <RogueResponses />
                                                                </Route>
                                                                <Route exact path="/surveys/:hash/revisions">
                                                                    {requireLogin()}
                                                                    <SurveyRevisions />
                                                                </Route>
                                                                <Route exact path="/surveys/:hash/option-labels">
                                                                    {requireLogin()}
                                                                    <OptionLabels />
                                                                </Route>
                                                                <Route exact path="/surveys/:hash/title">
                                                                    {requireLogin()}
                                                                    <SurveyTitle />
                                                                </Route>
                                                                <Route exact path="/surveys/:hash/progress-report">
                                                                    {requireLogin()}
                                                                    <SurveyProgressReport />
                                                                </Route>
                                                                <Route exact path="/survey-report/:hash/:type">
                                                                    {requireLogin()}
                                                                    <FilterContext.Provider value={{types, updateTypes, demographics, updateDemographics, surveyId, updateSurveyId}}>
                                                                        <SurveyReport />
                                                                    </FilterContext.Provider>
                                                                </Route>
                                                                <Route exact path="/survey-copy">
                                                                    {requireLogin()}
                                                                    <SurveyCopy />
                                                                </Route>
                                                                <Route exact path="/survey-merge-copy">
                                                                    {requireLogin()}
                                                                    <SurveyCopyMerge />
                                                                </Route>
                                                                <Route exact path="/elements-compare">
                                                                    {requireLogin()}
                                                                    <ElementsCompare />
                                                                </Route>
                                                                <Route exact path="/custom-reports">
                                                                    {requireLogin()}
                                                                    <CustomReports />
                                                                </Route>
                                                                <Route exact path="/element-mappings">
                                                                    {requireLogin()}
                                                                    <ElementMappings />
                                                                </Route>
                                                                <Route exact path="/users">
                                                                    {requireLogin()}
                                                                    <Users />
                                                                </Route>
                                                                <Route exact path="/users/new">
                                                                    {requireLogin()}
                                                                    <UserNew />
                                                                </Route>
                                                                <Route exact path="/users/:uuid">
                                                                    {requireLogin()}
                                                                    <UserEdit />
                                                                </Route>
                                                                <Route exact path="/users/:uuid/permissions">
                                                                    {requireLogin()}
                                                                    <UserPermissions />
                                                                </Route>
                                                                <Route exact path="/companies">
                                                                    {requireLogin()}
                                                                    <Companies />
                                                                </Route>
                                                                <Route exact path="/companies/:uuid/site-mappings">
                                                                    {requireLogin()}
                                                                    <SiteMappings />
                                                                </Route>
                                                                <Route exact path="/companies/:uuid/import-survey">
                                                                    {requireLogin()}
                                                                    <ImportSurvey />
                                                                </Route>
                                                                <Route exact path="/companies/:uuid/survey-sheets">
                                                                    {requireLogin()}
                                                                    <SurveySheets />
                                                                </Route>
                                                                <Route exact path="/companies/:companyUuid/survey-sheets/:sheetUuid">
                                                                    {requireLogin()}
                                                                    <SurveySheetEdit />
                                                                </Route>
                                                                <Route exact path="/companies/:companyUuid/survey-sheets/:sheetUuid/print">
                                                                    {requireLogin()}
                                                                    <SurveySheetPrint />
                                                                </Route>
                                                                <Route exact path="/companies/new">
                                                                    {requireLogin()}
                                                                    <CompanyNew />
                                                                </Route>
                                                                <Route exact path="/companies/:uuid">
                                                                    {requireLogin()}
                                                                    <CompanyEdit />
                                                                </Route>
                                                                <Route exact path="/sites">
                                                                    {requireLogin()}
                                                                    <Sites />
                                                                </Route>
                                                                <Route exact path="/sites/:uuid">
                                                                    {requireLogin()}
                                                                    <SiteEdit />
                                                                </Route>
                                                                <Route exact path="/survey-relaunch">
                                                                    <SurveyRelaunch />
                                                                </Route>
                                                            </Switch>
                                                        </main>
                                                        { printView ? null :
                                                            <div style={{
                                                                position: 'fixed',
                                                                bottom: 0,
                                                                left: '50%',
                                                                margin: '0 auto',
                                                                transform: 'translate(-50%, 0%)',
                                                                fontSize: '12px',
                                                                color: '#BFBFBF',
                                                                userSelect: 'none'
                                                            }}>
                                                                &copy; 2015-{(new Date()).getFullYear()} TSI Quality Services
                                                            </div>
                                                        }
                                                    </Box>
                                                </Router>
                                            </SpinnerContext.Provider>
                                        </NotificationContext.Provider>
                                    </UserContext.Provider>
                                </AppBarTitleContext.Provider>
                            </ThemeProvider>
                        </ThemeProvider>
                    </StylesProvider>
                </IntlProvider>
            </LocaleContext.Provider>
        </ErrorBoundary>
    );
}
export default App;
