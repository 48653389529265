import { Box, Paper, Typography, Grid, Divider, Button, makeStyles } from "@material-ui/core";
import { Fragment } from "react";
import { FormattedMessage } from "react-intl";
import { NavLink } from "react-router-dom";
import { SSAFE_CATEGORY_COLORS } from '../../../Constants/SsafeCategoryColors';
import NoData from "../../NoData/NoData";
import ElementLabel from "../../Translation/ElementLabel";

const useStyles = makeStyles((theme) => ({
    gridLeft: {
        [theme.breakpoints.up("sm")]: {
            textAlign: 'left'
        },
        [theme.breakpoints.down("xs")]: {
            textAlign: 'center'
        },
    },
    gridRight: {
        [theme.breakpoints.up("sm")]: {
            textAlign: 'right'
        },
        [theme.breakpoints.down("xs")]: {
            textAlign: 'center'
        },
    },
}));


export default function SsafeElements(props) {
    const classes = useStyles();
    if (!props.reportData.data) {
        return null;
    }

    if (0 === props.reportData.data.length) {
        return <NoData />;
    }

    const hasSupervisorManagerQuestion = () => {
        const result = {
            supervisor: false,
            manager: false,
            both: false
        };

        props.reportData.data.forEach(element => {
            if (-1 !== element.shortName.indexOf('(M)')) {
                result.manager = true;
            }

            if (-1 !== element.shortName.indexOf('(S)')) {
                result.supervisor = true;
            }

            if (-1 !== element.shortName.indexOf('(S/M)')) {
                result.both = true;
            }
        });

        return result;
    };

    const lastElement = props.reportData.data.length - 1;
    return (
        <Fragment>
            <Box pb={2}>
                <Paper elevation={1} style={{borderLeft: '5px solid '+SSAFE_CATEGORY_COLORS['people'].dark, backgroundColor: SSAFE_CATEGORY_COLORS['people'].light}}>
                    <Box p={1} px={2}>
                        <Typography variant="h6">
                            <FormattedMessage id="ssafeElements.highestHeader" defaultMessage="Highest Elements (questions)" />
                        </Typography>
                        <Divider />
                        <Grid container direction="row" justifyContent="center" alignItems="center">
                            <Grid className={classes.gridLeft} item sm={10} xs={12} style={{fontSize: '1rem'}}>
                                <Box py={2}>
                                    {props.reportData.data[0].shortName ? <ElementLabel element={props.reportData.data[0].shortName}/> : props.reportData.data[0].title}
                                </Box>
                            </Grid>
                            <Grid className={classes.gridRight} item sm={2} xs={12} style={{fontSize: '1rem'}}>
                                <Box py={2}>
                                    {props.reportData.data[0].roundedScore}
                                </Box>
                            </Grid>
                        </Grid>
                        <Divider />
                        <Grid container direction="row" justifyContent="center" alignItems="center">
                            <Grid className={classes.gridLeft} item sm={10} xs={12} align="left" style={{fontSize: '1rem'}}>
                                <Box py={2}>
                                    {props.reportData.data[1].shortName ? <ElementLabel element={props.reportData.data[1].shortName}/> : props.reportData.data[1].title}
                                </Box>
                            </Grid>
                            <Grid className={classes.gridRight} item sm={2} xs={12} align="right" style={{fontSize: '1rem'}}>
                                <Box py={2}>
                                    {props.reportData.data[1].roundedScore}
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Paper>
            </Box>
            <Box pb={2}>
                <Paper elevation={1} style={{borderLeft: '5px solid '+SSAFE_CATEGORY_COLORS['process'].dark, backgroundColor: SSAFE_CATEGORY_COLORS['process'].light}}>
                    <Box p={1} px={2}>
                        <Typography variant="h6">
                            <FormattedMessage id="ssafeElements.lowestHeader" defaultMessage="Lowest Elements (questions)" />
                        </Typography>
                        <Divider />
                        <Grid container direction="row" justifyContent="center" alignItems="center">
                            <Grid className={classes.gridLeft} item sm={10} xs={12} align="left" style={{fontSize: '1rem'}}>
                                <Box py={2}>
                                    {props.reportData.data[lastElement].shortName ? <ElementLabel element={props.reportData.data[lastElement].shortName} /> : props.reportData.data[lastElement].title}
                                </Box>
                            </Grid>
                            <Grid className={classes.gridRight} item sm={2} xs={12} align="right" style={{fontSize: '1rem'}}>
                                <Box py={2}>
                                    {props.reportData.data[lastElement].roundedScore}
                                </Box>
                            </Grid>
                        </Grid>
                        <Divider />
                        <Grid container direction="row" justifyContent="center" alignItems="center">
                            <Grid className={classes.gridLeft} item sm={10} xs={12} align="left" style={{fontSize: '1rem'}}>
                                <Box py={2}>
                                    {props.reportData.data[lastElement-1].shortName ? <ElementLabel element={props.reportData.data[lastElement-1].shortName} /> : props.reportData.data[lastElement-1].title}
                                </Box>
                            </Grid>
                            <Grid className={classes.gridRight} item sm={2} xs={12} align="right" style={{fontSize: '1rem'}}>
                                <Box py={2}>
                                    {props.reportData.data[lastElement-1].roundedScore}
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Paper>
            </Box>

            <Box>
                <Paper elevation={0} style={{borderLeft: '5px solid #ffffff'}}>
                    <Box p={1} px={2}>
                        {hasSupervisorManagerQuestion().manager ?
                            <Typography variant="body1" paragraph>
                                <em><FormattedMessage id="ssafeElements.managerQuestionNote" defaultMessage="(M) indicates a question that was only asked of managers." /></em>
                            </Typography>
                        : null}
                        {hasSupervisorManagerQuestion().supervisor ?
                            <Typography variant="body1" paragraph>
                                <em><FormattedMessage id="ssafeElements.supervisorQuestionNote" defaultMessage="(S) indicates a question that was only asked of supervisors." /></em>
                            </Typography>
                        : null}
                        {hasSupervisorManagerQuestion().both ?
                            <Typography variant="body1" paragraph>
                                <em><FormattedMessage id="ssafeElements.supervisorManagerQuestionNote" defaultMessage="(S / M) indicates a question that was only asked of supervisors and managers." /></em>
                            </Typography>
                        : null}
                    </Box>
                </Paper>
            </Box>

            <Grid container justifyContent="center">
                <Grid item sm={5} xs={10}>
                    <Paper variant="outlined" style={{borderColor: SSAFE_CATEGORY_COLORS['people'].dark, backgroundColor: SSAFE_CATEGORY_COLORS['people'].light}}>
                        <Box p={1} px={2}>
                            <Typography variant="subtitle1" align="center">
                                <FormattedMessage id="ssafeElements.upgradeNotice" defaultMessage="Scores for additional Elements are reported with a full assessment." />
                            </Typography>
                            <Box p={1} textAlign='center'>
                                <Button variant="contained" color="primary" component={NavLink} to="/upgrade/ssafe" style={{ backgroundColor: SSAFE_CATEGORY_COLORS['people'].dark}}>
                                    <FormattedMessage id="ssafeElements.learnMoreButton" defaultMessage="Learn more about a full assessment" />
                                </Button>
                            </Box>
                        </Box>
                    </Paper>
                </Grid>
            </Grid>
            <Box pb={2} />
        </Fragment>
    );
}
