import { Box, Grid, Table, TableRow, TableHead, Typography, TableCell, TableBody, Chip } from "@material-ui/core";
import { useIntl } from 'react-intl'
import React, { Fragment, useContext, useEffect, useRef, useState } from "react";
import { Redirect } from "react-router-dom";
import { UserContext } from "../../Context/UserContext";
import { NotificationContext } from "../../Context/NotificationContext";
import { AppBarTitleContext } from "../../Context/AppBarTitleContext";
import { SpinnerContext } from "../../Context/SpinnerContext";
import SurveyService from "../../Services/SurveyService";
import SiteService from "../../Services/SiteService";
import SiteReportService from "../../Services/SiteReportService";
import CompanyService from "../../Services/CompanyService";
import SurveyTypeLabel from "../Translation/SurveyTypeLabel";
import { REGIONS } from "../../Constants/Regions";
import { COUNTRIES } from "../../Constants/Countries";


export default function Home() {
    const { updateNotification } = useContext(NotificationContext);
    const updateNotificationRef = useRef();
    updateNotificationRef.current = (open, message, level) => {
        updateNotification(open, message, level);
    };
    const { user } = useContext(UserContext);
    const { updateTitle } = useContext(AppBarTitleContext);
    const updateTitleRef = useRef();
    updateTitleRef.current = (title) => {
        updateTitle(title);
    };
    const { updateLoading } = useContext(SpinnerContext);
    const updateLoadingRef = useRef();
    updateLoadingRef.current = (loading) => {
        updateLoading(loading);
    };

    const [error, setError] = useState(false);
    const [redirect, setRedirect] = useState(null);
    const [stuckSurveys, setStuckSurveys] = useState(null);
    const [surveyCount, setSurveyCount] = useState(null);
    const [siteCount, setSiteCount] = useState(null);
    const [companyCount, setCompanyCount] = useState(null);
    const [siteReportStats, setSiteReportStats] = useState({});

    const intlRef = useRef();
    intlRef.current = useIntl();

    useEffect(() => {
        let mounted = true;
        const unknownError = intlRef.current.formatMessage({id: 'generic.unknownError', defaultMessage: 'An unknown error occurred!'});
        if (user && user.role === 'brcgs-admin') {
            setRedirect('/surveys');
        } else if (user && user.role && -1 !== ['brcgs-free', 'brcgs', 'ssafe', 'premium', 'premium-admin', 'partner'].indexOf(user.role)) {
            updateLoadingRef.current(true);
            // Find out how many surveys user has, redirect to basic report if it's only one
            SurveyService.surveysByHash(0, 1).then(function (response) {
                if (response && response.data && response.data.meta && 1 === response.data.meta.count) {
                    const scoredCount = response.data.data[0].attributes.scoredRespondentCount;
                    const surveyType = response.data.data[0].attributes.type;
                    // NOTE: If minimum respondents change in backend, they need to be updated here
                    if (('brcgs' === surveyType && scoredCount >= 5) ||
                        ('brcgs-free' === surveyType && scoredCount >= 4) ||
                        ('ssafe' === surveyType && scoredCount >= 5) ||
                        ('premium' === surveyType && scoredCount >= 5)
                    ) {
                        setRedirect('/survey-report/'+response.data.data[0].attributes.combinedHash+'/basic-data');
                    } else {
                        setRedirect('/surveys');
                    }

                } else {
                    setRedirect('/surveys');
                }
            }).catch(function (error) {
                updateNotificationRef.current(true, unknownError, 'error');
            }).then(() => {
                updateLoadingRef.current(false);
            });
        } else if (user && user.role === 'admin') {
            SiteReportService.stats().then(response => {
                const reportStats = {
                    ...response.data.data,
                    countries: Object.keys(response.data.data.countries).sort((a, b) => {
                        if (a === '-') {
                            return -1;
                        }
                        if (b === '-') {
                            return 1;
                        }
                        return COUNTRIES[a] > COUNTRIES[b] ? 1 : -1;
                    }).reduce((obj, key) => {
                        obj[key] = response.data.data.countries[key];
                        return obj;
                    }, {}),
                    regions: {}
                };

                // Count for regions
                for (const regionName in REGIONS) {
                    reportStats.regions[regionName] = 0;
                    REGIONS[regionName].forEach(cc => {
                        if (cc in reportStats.countries) {
                            reportStats.regions[regionName] += reportStats.countries[cc];
                        }
                    });
                }

                if (mounted) {
                    setSiteReportStats(reportStats);
                }
            }).catch(error => {
                setError(true);
                updateNotificationRef.current(true, unknownError, 'error');
            });

            SurveyService.stuck().then(response => {
                if (mounted) {
                    setStuckSurveys(response.data.data);
                }
            }).catch(error => {
                setError(true);
                updateNotificationRef.current(true, unknownError, 'error');
            });

            SiteService.sites(0, 1).then(response => {
                if (mounted) {
                    setSiteCount(response.data.meta.count);
                }
            }).catch(error => {
                setError(true);
                updateNotificationRef.current(true, unknownError, 'error');
            });

            SurveyService.surveysByHash(0, 1).then(response => {
                if (mounted) {
                    setSurveyCount(response.data.meta.count);
                }
            }).catch(error => {
                setError(true);
                updateNotificationRef.current(true, unknownError, 'error');
            });

            CompanyService.companies(0, 1).then(response => {
                if (mounted) {
                    setCompanyCount(response.data.meta.count);
                }
            }).catch(error => {
                setError(true);
                updateNotificationRef.current(true, unknownError, 'error');
            });
        }
        updateTitleRef.current(null);
        return () => {
            mounted = false
        };
    }, [user]);

    useEffect(() => {
        return () => {
            // Disable loading indicator on umount
            updateLoadingRef.current(false);
        }
    }, []);

    useEffect(() => {
        if (user && user.role === 'admin') {
            if (!error && (null === stuckSurveys || null === surveyCount || null === siteCount || null == companyCount)) {
                updateLoadingRef.current(true);
            } else {
                updateLoadingRef.current(false);
            }
        }
    }, [error, user, stuckSurveys, surveyCount, siteCount, companyCount]);

    if (redirect) {
        return (<Redirect to={redirect} />);
    }

    return(
        user && user.role === 'admin' ?
            <Grid container spacing={3} justifyContent="center">
                <Grid item sm={12} lg={6}>
                    <Typography variant="h5">Stats</Typography>
                    <Box pb={2}>
                        Surveys: {surveyCount} | Sites: {siteCount} | Companies: {companyCount}
                    </Box>
                    <Typography variant="h6">Benchmark Data</Typography>
                    <Grid container>
                        {siteReportStats && siteReportStats.industries && siteReportStats['best-in-class'] ?
                            <Grid item sm={4} xs={12} style={{borderLeft: '1px solid #ccc'}}>
                                <Table size="small">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Industry</TableCell>
                                            <TableCell align="right">Count</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow style={{backgroundColor: '#e6e6e6'}}>
                                            <TableCell><strong>Best in class (top10%)</strong></TableCell>
                                            <TableCell align="right"><strong>{siteReportStats['best-in-class']}</strong></TableCell>
                                        </TableRow>
                                        {Object.keys(siteReportStats.industries).map(industry => (
                                            <TableRow key={industry} style={{backgroundColor: industry === '-' ? '#e6e6e6' : null}}>
                                                <TableCell>{industry === '-' ? 'Not set' : industry}</TableCell>
                                                <TableCell align="right">{siteReportStats.industries[industry]}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </Grid>
                        : null}
                        {siteReportStats && siteReportStats.countries ?
                            <Grid item sm={4} xs={12} style={{borderLeft: '1px solid #ccc'}}>
                                <Table size="small">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Country</TableCell>
                                            <TableCell align="right">Count</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {Object.keys(siteReportStats.countries).map(cc => (
                                            <TableRow key={cc} style={{backgroundColor: cc === '-' ? '#e6e6e6' : null}}>
                                                <TableCell>{cc === '-' ? 'Not set' : COUNTRIES[cc]}</TableCell>
                                                <TableCell align="right">{siteReportStats.countries[cc]}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </Grid>
                        : null}
                        {siteReportStats && siteReportStats.regions ?
                            <Grid item sm={4} xs={12} style={{borderLeft: '1px solid #ccc'}}>
                                <Table size="small">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Region</TableCell>
                                            <TableCell align="right">Count</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {Object.keys(siteReportStats.regions).map(reg => (
                                            <TableRow key={reg}>
                                                <TableCell>{reg}</TableCell>
                                                <TableCell align="right">{siteReportStats.regions[reg]}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </Grid>
                        : null}
                    </Grid>
                </Grid>
                <Grid item sm={12} lg={6}>
                    <Typography variant="h5">Stuck surveys</Typography>
                    {stuckSurveys !== null && stuckSurveys.length === 0 ?
                        <Box py={2}><em>No stuck surveys 👍</em></Box>
                    :
                        <Fragment>
                            <em>Surveys that appear to be stuck (created more than 6 hours ago and are not done)</em>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Title</TableCell>
                                        <TableCell>Company</TableCell>
                                        <TableCell>Created</TableCell>
                                        <TableCell>Type</TableCell>
                                        <TableCell>Provider</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {stuckSurveys ? stuckSurveys.map((s, i) => {
                                        return (<TableRow key={i}>
                                            <TableCell>
                                                {s.attributes.title}
                                                <Chip label={'Rev: '+s.attributes.importRevision} size="small" style={{marginRight: 5, marginLeft: 5 }} />
                                                <Chip color="primary" label={s.attributes.status} size="small"/>
                                            </TableCell>
                                            <TableCell>{s.relationships.company.data.name}</TableCell>
                                            <TableCell>
                                                {(new Date(s.attributes.createdAt)).toISOString().replace('T', ' ').substring(0, 16)}
                                            </TableCell>
                                            <TableCell><SurveyTypeLabel type={s.attributes.type} /></TableCell>
                                            <TableCell>{s.attributes.providerId} {s.attributes.provider === 'alchemer_old' ? '🇺🇸': '🇪🇺'}</TableCell>
                                        </TableRow>);
                                    }) : null}
                                </TableBody>
                            </Table>
                        </Fragment>
                    }
                </Grid>
            </Grid>
        : null
    );
}
