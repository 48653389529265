import { Fragment } from "react";
import ElementsTable from "./ElementsTable";
import { FormattedMessage } from "react-intl";

export default function CommunicationDimension(props) {

    return (
        <Fragment>
            <p>
                <FormattedMessage
                    id="execSummaryDiscAndRecs.communication"
                    defaultMessage={
                        "Communication is a core part of disseminating safety and quality information, providing cueing "+
                        "mechanisms, and highlighting the importance of safety and quality. In representing 'what leaders pay "+
                        "attention to' it is one of Schein's primary embedding mechanisms of culture. However, making it "+
                        "impactful and memorable is an ongoing area for improvement in most companies, especially with a diverse "+
                        "workforce. Communication includes emails, briefings and meetings, and has scores for both frequency and "+
                        "effectiveness, as well as a separate score for the effectiveness of direct manager communication."
                    }
                />
            </p>
            <ElementsTable dimension={props.dimension} elements={props.elements} lowestElements={props.lowestElements} />
        </Fragment>
    );
}
