import { defineMessages } from "react-intl";

export const priorityTranslated = (prio, intl) => {
    const messages = defineMessages({
        "employee_health_and_safety": {
            id: "generic.priority.Employee_health_and_safety",
            defaultMessage: "Employee health and safety"
        },
        "food_safety": {
            id: "generic.priority.Food_safety",
            defaultMessage: "Food safety"
        },
        "product_quality": {
            id: "generic.priority.Product_quality",
            defaultMessage: "Product quality"
        },
        "production_costs": {
            id: "generic.priority.Production_costs",
            defaultMessage: "Production costs"
        },
        "production_speed": {
            id: "generic.priority.Production_speed",
            defaultMessage: "Production speed"
        },
        "product_price": {
            id: "generic.priority.Product_price",
            defaultMessage: "Product Price"
        },
        "product_safety": {
            id: "generic.priority.Product_safety",
            defaultMessage: "Product Safety"
        },
        "health_&_safety": {
            id: "generic.priority.Health_&_Safety",
            defaultMessage: "Health & Safety"
        },
        "environmental_sustainability": {
            id: "generic.priority.Environmental_sustainability",
            defaultMessage: "Environmental sustainability"
        }
    });
    const alias = prio ? prio.replace(/ /g, '_') : null;

    if (prio && alias.toLowerCase() in messages) {
        return intl.formatMessage(messages[alias.toLowerCase()]);
    } else {
        return prio;
    }
}
