import { Fragment } from "react";
import ElementsTable from "./ElementsTable";
import { FormattedMessage } from "react-intl";

export default function ConsistencyDimension(props) {
    return (
        <Fragment>
            <p>
                <FormattedMessage
                    id="execSummaryDiscAndRecs.consistency"
                    defaultMessage={
                        "Consistency is an important element of safety and quality. However, as companies get larger and more "+
                        "diverse, it can be increasingly difficult to maintain standardised working practices. And, as new "+
                        "policies and procedures generate additional requirements, it can be difficult to ensure they are "+
                        "implemented at all times (rather than focused on prior to external audits / when managers are looking). "+
                        "It is important to identify how to improve consistency, whether enhancing the clarity of work "+
                        "instructions, increasing effectiveness of communication and training around procedures, or increasing "+
                        "supervision and support."
                    }
                />
            </p>
            <ElementsTable dimension={props.dimension} elements={props.elements} lowestElements={props.lowestElements} />
        </Fragment>
    );
}
