import { Table, TableBody, TableHead, TableRow, Tabs, Tab, Typography, Box, TableCell, makeStyles } from "@material-ui/core";
import { Fragment } from "react";
import { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

const useStylesBrcgs = makeStyles(theme => ({
    topIndicator: {
        backgroundColor: theme.palette.primary.light,
    },
    topTabs: {
        backgroundColor: theme.palette.secondary.main,
        color: 'white'
    },
    categoryTab: {},
    dimensionTab: {},
    elementTab: {},
    scoreGuideTab: {},
    categoryTabs: {
        backgroundColor: theme.palette.primary.main,
        color: 'white'
    },
    peopleTab: {},
    processTab: {},
    purposeTab: {},
    proactivityTab: {},
    // People dimensions
    empowermentDim: {
        backgroundColor: theme.palette.primary.light,
        color: 'white',
    },
    rewardDim: {
        backgroundColor: theme.palette.primary.light,
        color: 'white',
    },
    teamworkDim: {
        backgroundColor: theme.palette.primary.light,
        color: 'white',
    },
    trainingDim: {
        backgroundColor: theme.palette.primary.light,
        color: 'white',
    },
    communicationDim: {
        backgroundColor: theme.palette.primary.light,
        color: 'white',
    },
    // Process dimensions
    controlDim: {
        backgroundColor: theme.palette.primary.light,
        color: 'white',
    },
    coordinationDim: {
        backgroundColor: theme.palette.primary.light,
        color: 'white',
    },
    consistencyDim: {
        backgroundColor: theme.palette.primary.light,
        color: 'white',
    },
    systemsDim: {
        backgroundColor: theme.palette.primary.light,
        color: 'white',
    },
    premisesDim: {
        backgroundColor: theme.palette.primary.light,
        color: 'white',
    },
    // Purpose dimensions
    visionDim: {
        backgroundColor: theme.palette.primary.light,
        color: 'white',
    },
    valuesDim: {
        backgroundColor: theme.palette.primary.light,
        color: 'white',
    },
    strategyDim: {
        backgroundColor: theme.palette.primary.light,
        color: 'white',
    },
    targetsDim: {
        backgroundColor: theme.palette.primary.light,
        color: 'white',
    },
    metricsDim: {
        backgroundColor: theme.palette.primary.light,
        color: 'white',
    },
    // Proactivity dimensions
    awarenessDim: {
        backgroundColor: theme.palette.primary.light,
        color: 'white',
    },
    foresightDim: {
        backgroundColor: theme.palette.primary.light,
        color: 'white',
    },
    innovationDim: {
        backgroundColor: theme.palette.primary.light,
        color: 'white',
    },
    learningDim: {
        backgroundColor: theme.palette.primary.light,
        color: 'white',
    },
    investmentDim: {
        backgroundColor: theme.palette.primary.light,
        color: 'white',
    },
}));

const useStylesSsafe = makeStyles(theme => ({
    topIndicator: {
        backgroundColor: theme.palette.primary.main,
    },
    topTabs: {
        backgroundColor: theme.palette.secondary.main,
        color: 'white'
    },
    categoryTab: {},
    dimensionTab: {},
    elementTab: {},
    scoreGuideTab: {},
    categoryTabs: {
        backgroundColor: theme.palette.primary.main,
        color: 'white'
    },
    peopleTab: {},
    processTab: {},
    purposeTab: {},
    proactivityTab: {},
    // People dimensions
    empowermentDim: {
        backgroundColor: theme.palette.primary.light,
        color: 'white',
    },
    rewardDim: {
        backgroundColor: theme.palette.primary.light,
        color: 'white',
    },
    teamworkDim: {
        backgroundColor: theme.palette.primary.light,
        color: 'white',
    },
    trainingDim: {
        backgroundColor: theme.palette.primary.light,
        color: 'white',
    },
    communicationDim: {
        backgroundColor: theme.palette.primary.light,
        color: 'white',
    },
    // Process dimensions
    controlDim: {
        backgroundColor: theme.palette.primary.light,
        color: 'white',
    },
    coordinationDim: {
        backgroundColor: theme.palette.primary.light,
        color: 'white',
    },
    consistencyDim: {
        backgroundColor: theme.palette.primary.light,
        color: 'white',
    },
    systemsDim: {
        backgroundColor: theme.palette.primary.light,
        color: 'white',
    },
    premisesDim: {
        backgroundColor: theme.palette.primary.light,
        color: 'white',
    },
    // Purpose dimensions
    visionDim: {
        backgroundColor: theme.palette.primary.light,
        color: 'white',
    },
    valuesDim: {
        backgroundColor: theme.palette.primary.light,
        color: 'white',
    },
    strategyDim: {
        backgroundColor: theme.palette.primary.light,
        color: 'white',
    },
    targetsDim: {
        backgroundColor: theme.palette.primary.light,
        color: 'white',
    },
    metricsDim: {
        backgroundColor: theme.palette.primary.light,
        color: 'white',
    },
    // Proactivity dimensions
    awarenessDim: {
        backgroundColor: theme.palette.primary.light,
        color: 'white',
    },
    foresightDim: {
        backgroundColor: theme.palette.primary.light,
        color: 'white',
    },
    innovationDim: {
        backgroundColor: theme.palette.primary.light,
        color: 'white',
    },
    learningDim: {
        backgroundColor: theme.palette.primary.light,
        color: 'white',
    },
    investmentDim: {
        backgroundColor: theme.palette.primary.light,
        color: 'white',
    },
}));

const useStyles = makeStyles(theme => ({
    topIndicator: {
        backgroundColor: theme.palette.secondary.main,
    },
    topTabs: {
        backgroundColor: theme.palette.primary.main,
        color: 'white'
    },
    categoryTab: {},
    dimensionTab: {},
    elementTab: {},
    scoreGuideTab: {},
    categoryTabs: {
        color: 'white'
    },
    peopleTab: {
        backgroundColor: theme.palette.people.main
    },
    processTab: {
        backgroundColor: theme.palette.process.main
    },
    purposeTab: {
        backgroundColor: theme.palette.purpose.main
    },
    proactivityTab: {
        backgroundColor: theme.palette.proactivity.main
    },
    // People dimensions
    empowermentDim: {
        backgroundColor: theme.palette.primary.light,
        color: 'white',
    },
    rewardDim: {
        backgroundColor: theme.palette.primary.light,
        color: 'white',
    },
    teamworkDim: {
        backgroundColor: theme.palette.primary.light,
        color: 'white',
    },
    trainingDim: {
        backgroundColor: theme.palette.primary.light,
        color: 'white',
    },
    communicationDim: {
        backgroundColor: theme.palette.primary.light,
        color: 'white',
    },
    // Process dimensions
    controlDim: {
        backgroundColor: theme.palette.primary.light,
        color: 'white',
    },
    coordinationDim: {
        backgroundColor: theme.palette.primary.light,
        color: 'white',
    },
    consistencyDim: {
        backgroundColor: theme.palette.primary.light,
        color: 'white',
    },
    systemsDim: {
        backgroundColor: theme.palette.primary.light,
        color: 'white',
    },
    premisesDim: {
        backgroundColor: theme.palette.primary.light,
        color: 'white',
    },
    // Purpose dimensions
    visionDim: {
        backgroundColor: theme.palette.primary.light,
        color: 'white',
    },
    valuesDim: {
        backgroundColor: theme.palette.primary.light,
        color: 'white',
    },
    strategyDim: {
        backgroundColor: theme.palette.primary.light,
        color: 'white',
    },
    targetsDim: {
        backgroundColor: theme.palette.primary.light,
        color: 'white',
    },
    metricsDim: {
        backgroundColor: theme.palette.primary.light,
        color: 'white',
    },
    // Proactivity dimensions
    awarenessDim: {
        backgroundColor: theme.palette.primary.light,
        color: 'white',
    },
    foresightDim: {
        backgroundColor: theme.palette.primary.light,
        color: 'white',
    },
    innovationDim: {
        backgroundColor: theme.palette.primary.light,
        color: 'white',
    },
    learningDim: {
        backgroundColor: theme.palette.primary.light,
        color: 'white',
    },
    investmentDim: {
        backgroundColor: theme.palette.primary.light,
        color: 'white',
    },
}));

export default function Definitions(props) {
    const classesBrcgs = useStylesBrcgs();
    const classesSsafe = useStylesSsafe();
    const classes = useStyles();

    const styledClasses = () => {
        // Use different styling depending on survey type
        if (props.surveyType && -1 !== ['brcgs-free', 'brcgs'].indexOf(props.surveyType)) {
            return classesBrcgs;
        } else if (props.surveyType && 'ssafe' === props.surveyType) {
            return classesSsafe;
        } else {
            return classes;
        }
    };

    const intl = useIntl();
    const [activeTab, setActiveTab] = useState(0);
    const [activeCategoryTab, setActiveCategoryTab] = useState(0);
    const [activeDimensionTab, setActiveDimensionTab] = useState(0);

    const handleTabChange = (event, newValue) => {
        setActiveTab(newValue);
    };

    const handleCategoryTabChange = (event, newValue) => {
        setActiveCategoryTab(newValue);
    };

    const handleDimensionTabChange = (event, newValue) => {
        setActiveDimensionTab(newValue);
    }


    const smallColStyle = {
        width: '10%',
        textAlign: 'center',
        whiteSpace: 'normal',
        fontSize: '1rem',
        paddingTop: '1px',
        paddingBottom: '1px',
        border: '1px solid #e0e0e0',
        height: '40px'
    };

    const expStyle = {
        whiteSpace: 'normal',
        fontSize: '1rem',
        paddingLeft: '24px',
        paddingRight: '24px',
        border: '1px solid #e0e0e0',
    };

    const dimHeaderStyle = {
        width: '25%',
        float: 'left',
        minHeight: '1pt',
        lineHeight: '48px',
        textAlign: 'center',
        fontSize: '1rem',
        fontWeight: 500,
    };

    const clearStyle = {
        clear: 'both',
    };

    // TODO: When right-to-left languages are introduced, update this to be based on global language direction
    const dirStyle = {
        direction: 'ltr',
        textAlign: 'left'
    };

    const tabs = (tab) => {
        if (0 === tab) {
            return (
                <Fragment>
                    <Box p={2}>
                        <Typography variant="body1" paragraph>
                            <FormattedMessage id="definitionsReport.categoryDescription1" defaultMessage="There are four categories in the Culture Excellence Model: People, Process, Purpose and Proactivity." />
                            <br /><br />
                            <FormattedMessage id="definitionsReport.categoryDescription2" defaultMessage="These make up the four broad areas of safety and quality within an organisation. They are described in more detail below (click the Category to see more information):" />
                        </Typography>
                    </Box>
                    <Tabs className={styledClasses().categoryTabs} value={activeCategoryTab} onChange={handleCategoryTabChange} variant="fullWidth">
                        <Tab className={styledClasses().peopleTab} label={intl.formatMessage({id:'definitionsReport.categoryPeople', defaultMessage: 'People'})} />
                        <Tab className={styledClasses().processTab} label={intl.formatMessage({id:'definitionsReport.categoryProcess', defaultMessage: 'Process'})} />
                        <Tab className={styledClasses().purposeTab} label={intl.formatMessage({id:'definitionsReport.categoryPurpose', defaultMessage: 'Purpose'})} />
                        <Tab className={styledClasses().proactivityTab} label={intl.formatMessage({id:'definitionsReport.categoryProactivity', defaultMessage: 'Proactivity'})} />
                    </Tabs>
                    <Box p={2}>
                        { activeCategoryTab === 0 ?
                            <Typography variant="body1" paragraph>
                                <FormattedMessage id="definitionsReport.categoryPeople1" defaultMessage="The People category focuses on employees at all levels and their experience at the organisation with relevance to safety and quality."/>
                                <br /><br />
                                <FormattedMessage id="definitionsReport.categoryPeople2" defaultMessage="It includes the frequency and impact of training and communication, the perceived level of empowerment, the effectiveness of teamwork and the perceived rewards and incentives that promote positive safety and quality behaviours."/>
                            </Typography>
                        : null}
                        { activeCategoryTab === 1 ?
                            <Typography variant="body1" paragraph>
                                <FormattedMessage id="definitionsReport.categoryProcess1" defaultMessage="The Process category focuses on the internal mechanisms by which the organisation manages safety and quality on a routine basis."/>
                                <br /><br />
                                <FormattedMessage id="definitionsReport.categoryProcess2" defaultMessage="It includes availability and satisfaction with safety and quality management systems, co-ordination of safety and quality across the organisation, consistency of practices and standards over time, and the perceptions of management control."/>
                            </Typography>
                        : null}
                        { activeCategoryTab === 2 ?
                            <Typography variant="body1" paragraph>
                                <FormattedMessage id="definitionsReport.categoryPurpose1" defaultMessage="The Purpose category focuses on the importance and clarity given to safety and quality in the overall mission of the organisation and its translation into daily operations."/>
                                <br /><br />
                                <FormattedMessage id="definitionsReport.categoryPurpose2" defaultMessage="It includes employee awareness and agreement with the long term vision of the organisation, the strategic plans to achieve the vision, the dominant core values of the organisation and the impact of these in short term goals and targets."/>
                            </Typography>
                        : null}
                        { activeCategoryTab === 3 ?
                            <Typography variant="body1" paragraph>
                                <FormattedMessage id="definitionsReport.categoryPoactivity1" defaultMessage="The Proactivity category focuses on the organisations awareness and response to external events and stakeholders that could impact safety and quality."/>
                                <br /><br />
                                <FormattedMessage id="definitionsReport.categoryProactivity2" defaultMessage="It includes employee perceptions of risks and priorities, awareness of external stakeholders such as suppliers and consumers, the level of change and innovation in the premises, and the effectiveness of organisational learning and continual improvement."/>
                            </Typography>
                        : null}
                    </Box>
                </Fragment>
            );
        } else if (1 === tab) {
            return (
                <Fragment>
                    <Box p={2}>
                        <Typography variant="body1" paragraph>
                            <FormattedMessage id="definitionsReport.dimensionDescription1" defaultMessage="There are 20 Dimensions in the Culture Excellence Model, grouped into four main Categories (People, Process, Purpose and Proactivity). Each of the 20 is a feature of an organisation that affects the prevailing safety and quality attitudes and practices at a particular site." />
                            <br /><br />
                            <FormattedMessage id="definitionsReport.dimensionDescription2" defaultMessage="They are described in more detail below (click the Category to see more information of its Dimensions):" />
                        </Typography>
                    </Box>
                    <Tabs className={styledClasses().categoryTabs} value={activeDimensionTab} onChange={handleDimensionTabChange} variant="fullWidth">
                        <Tab className={styledClasses().peopleTab} label={intl.formatMessage({id:'definitionsReport.categoryPeople', defaultMessage: 'People'})} />
                        <Tab className={styledClasses().processTab} label={intl.formatMessage({id:'definitionsReport.categoryProcess', defaultMessage: 'Process'})} />
                        <Tab className={styledClasses().purposeTab} label={intl.formatMessage({id:'definitionsReport.categoryPurpose', defaultMessage: 'Purpose'})} />
                        <Tab className={styledClasses().proactivityTab} label={intl.formatMessage({id:'definitionsReport.categoryProactivity', defaultMessage: 'Proactivity'})} />
                    </Tabs>

                    { activeDimensionTab === 0 ?
                        <Fragment>
                            <span style={{ height: '10pt', width: '1pt', display: 'block' }} />
                            <Box style={dimHeaderStyle} className={styledClasses().empowermentDim}>
                                {intl.formatMessage({id: 'definitionsReport.empowerment', defaultMessage: 'Empowerment'})}
                            </Box>
                            <div style={{ ...clearStyle, ...dirStyle }}>
                                <Box p={2}>
                                    <FormattedMessage
                                        id="definitionsReport.empowermentDescription"
                                        defaultMessage={
                                            'Empowerment reflects the level of autonomy and ownership felt by those working for the company, ' +
                                            'and their authority to deal with potential safety and quality problems.'
                                        }
                                    />
                                </Box>
                            </div>

                            <Box style={dimHeaderStyle} className={styledClasses().rewardDim}>
                                {intl.formatMessage({id: 'definitionsReport.reward', defaultMessage: 'Reward'})}
                            </Box>
                            <div style={{ ...clearStyle, ...dirStyle }}>
                                <Box p={2}>
                                    <FormattedMessage
                                        id="definitionsReport.rewardDescription"
                                        defaultMessage={
                                            'Reward relates to the use of incentives and disincentives to shape and manage correct ' +
                                            'behaviors, as well as opportunities to develop knowledge and skills.'
                                        }
                                    />
                                </Box>
                            </div>

                            <Box style={dimHeaderStyle} className={styledClasses().teamworkDim}>
                                {intl.formatMessage({id: 'definitionsReport.teamwork', defaultMessage: 'Teamwork'})}
                            </Box>
                            <div className="definitionContent people" style={{ ...clearStyle, ...dirStyle }}>
                                <Box p={2}>
                                    <FormattedMessage
                                        id="definitionsReport.teamworkDescription"
                                        defaultMessage={
                                            'Teamwork relates to a willingness to work in teams, the effectiveness of teamwork and the ' +
                                            'importance of the safety and quality team.'
                                        }
                                    />
                                </Box>
                            </div>

                            <Box style={dimHeaderStyle} className={styledClasses().trainingDim}>
                                {intl.formatMessage({id: 'definitionsReport.training', defaultMessage: 'Training'})}
                            </Box>
                            <div style={{ ...clearStyle, ...dirStyle }}>
                                <Box p={2}>
                                    <FormattedMessage
                                        id="definitionsReport.trainingDescription"
                                        defaultMessage={
                                            'Training reflects both the frequency of safety and quality training within the ' +
                                            'company, and an evaluation of its effectiveness.'
                                        }
                                    />
                                </Box>
                            </div>

                            <Box style={dimHeaderStyle} className={styledClasses().communicationDim}>
                                {intl.formatMessage({id: 'definitionsReport.communication', defaultMessage: 'Communication'})}
                            </Box>
                            <div style={{ ...clearStyle, ...dirStyle }}>
                                <Box p={2}>
                                    <FormattedMessage
                                        id="definitionsReport.communicationDescription"
                                        defaultMessage={
                                            'Communication reflects both the frequency of safety and quality communications ' +
                                            'within the company (e.g. meetings, emails, posters), and an evaluation of their effectiveness.'
                                        }
                                    />
                                </Box>
                            </div>
                        </Fragment>
                    : null}
                    { activeDimensionTab === 1 ?
                        <Fragment>
                            <span style={{ height: '10pt', width: '1pt', display: 'block' }} />
                            <div style={dimHeaderStyle} />
                            <Box style={dimHeaderStyle} className={styledClasses().controlDim}>
                                {intl.formatMessage({id: 'definitionsReport.control', defaultMessage: 'Control'})}
                            </Box>
                            <div style={{ ...clearStyle, ...dirStyle }}>
                                <Box p={2}>
                                    <FormattedMessage
                                        id="definitionsReport.controlDescription"
                                        defaultMessage={
                                            'Control relates to the effectiveness of managing and supervising staff throughout the ' +
                                            'company, to ensure their compliance with company standards.'
                                        }
                                    />
                                </Box>
                            </div>

                            <div style={dimHeaderStyle} />
                            <Box style={dimHeaderStyle} className={styledClasses().coordinationDim}>
                                {intl.formatMessage({id: 'definitionsReport.coordination', defaultMessage: 'Coordination'})}
                            </Box>
                            <div className="definitionContent process" style={{ ...clearStyle, ...dirStyle }}>
                                <Box p={2}>
                                    <FormattedMessage
                                        id="definitionsReport.coordinationDescription"
                                        defaultMessage={
                                            'Co-ordination reflects the ability of different departments and levels within the company to ' +
                                            'work effectively together.'
                                        }
                                    />
                                </Box>
                            </div>

                            <div style={dimHeaderStyle} />
                            <Box style={dimHeaderStyle} className={styledClasses().consistencyDim}>
                                {intl.formatMessage({id: 'definitionsReport.consistency', defaultMessage: 'Consistency'})}
                            </Box>
                            <div style={{ ...clearStyle, ...dirStyle }}>
                                <Box p={2}>
                                    <FormattedMessage
                                        id="definitionsReport.consistencyDescription"
                                        defaultMessage={
                                            'Consistency relates to having standard working methods that are effectively communicated and ' +
                                            'maintained throughout the company over time.'
                                        }
                                    />
                                </Box>
                            </div>

                            <div style={dimHeaderStyle} />
                            <Box style={dimHeaderStyle} className={styledClasses().systemsDim}>
                                {intl.formatMessage({id: 'definitionsReport.systems', defaultMessage: 'Systems'})}
                            </Box>
                            <div className="definitionContent process" style={{ ...clearStyle, ...dirStyle }}>
                                <Box p={2}>
                                    <FormattedMessage
                                        id="definitionsReport.systemsDescription"
                                        defaultMessage={
                                            'Systems reflects the level and appropriateness of key documents and records established ' +
                                            'within the company to support management processes.'
                                        }
                                    />
                                </Box>
                            </div>

                            <div style={dimHeaderStyle} />
                            <Box style={dimHeaderStyle} className={styledClasses().premisesDim}>
                                {intl.formatMessage({id: 'definitionsReport.premises', defaultMessage: 'Premises'})}
                            </Box>
                            <div className="definitionContent process" style={{ ...clearStyle, ...dirStyle }}>
                                <Box p={2}>
                                    <FormattedMessage
                                        id="definitionsReport.premisesDescription"
                                        defaultMessage={
                                            'Premises relates to the physical location, facilities and equipment within the ' +
                                            'company, and perceptions of its impact on safety and quality.'
                                        }
                                    />
                                </Box>
                            </div>
                        </Fragment>
                    : null}
                    { activeDimensionTab === 2 ?
                        <Fragment>
                            <span style={{ height: '10pt', width: '1pt', display: 'block' }} />
                            <div style={dimHeaderStyle} />
                            <div style={dimHeaderStyle} />
                            <Box style={dimHeaderStyle} className={styledClasses().visionDim}>
                                {intl.formatMessage({id: 'definitionsReport.vision', defaultMessage: 'Vision'})}
                            </Box>
                            <div style={{ ...clearStyle, ...dirStyle }}>
                                <Box p={2}>
                                    <FormattedMessage
                                        id="definitionsReport.visionDescription"
                                        defaultMessage={
                                            'Vision relates to the long-term aspirations of the company, and the direction employees ' +
                                            'perceive it to be moving in.'
                                        }
                                    />
                                </Box>
                            </div>

                            <div style={dimHeaderStyle} />
                            <div style={dimHeaderStyle} />
                            <Box style={dimHeaderStyle} className={styledClasses().valuesDim}>
                                {intl.formatMessage({id: 'definitionsReport.values', defaultMessage: 'Values'})}
                            </Box>
                            <div style={{ ...clearStyle, ...dirStyle }}>
                                <Box p={2}>
                                    <FormattedMessage
                                        id="definitionsReport.valuesDescription"
                                        defaultMessage={
                                            'Values reflects the extent to which safety and quality are seen as core company ' +
                                            'principles, and how they are directly and indirectly demonstrated in practice.'
                                        }
                                    />
                                </Box>
                            </div>

                            <div style={dimHeaderStyle} />
                            <div style={dimHeaderStyle} />
                            <Box style={dimHeaderStyle} className={styledClasses().strategyDim}>
                                {intl.formatMessage({id: 'definitionsReport.strategy', defaultMessage: 'Strategy'})}
                            </Box>
                            <div style={{ ...clearStyle, ...dirStyle }}>
                                <Box p={2}>
                                    <FormattedMessage
                                        id="definitionsReport.strategyDescription"
                                        defaultMessage={
                                            'Strategy reflects the plans in place to achieve the company vision, and the extent to which ' +
                                            'they are communicated and agreed with across the company.'
                                        }
                                    />
                                </Box>
                            </div>

                            <div style={dimHeaderStyle} />
                            <div style={dimHeaderStyle} />
                            <Box style={dimHeaderStyle} className={styledClasses().targetsDim}>
                                {intl.formatMessage({id: 'definitionsReport.targets', defaultMessage: 'Targets'})}
                            </Box>
                            <div style={{ ...clearStyle, ...dirStyle }}>
                                <Box p={2}>
                                    <FormattedMessage
                                        id="definitionsReport.targetsDescription"
                                        defaultMessage={
                                            'Targets relates to the establishment and management of short-term goals or objectives ' +
                                            'amongst employees, and the inclusion of safety and quality within these.'
                                        }
                                    />
                                </Box>
                            </div>

                            <div style={dimHeaderStyle} />
                            <div style={dimHeaderStyle} />
                            <Box style={dimHeaderStyle} className={styledClasses().metricsDim}>
                                {intl.formatMessage({id: 'definitionsReport.metrics', defaultMessage: 'Metrics'})}
                            </Box>
                            <div className="definitionContent purpose" style={{ ...clearStyle, ...dirStyle }}>
                                <Box p={2}>
                                    <FormattedMessage
                                        id="definitionsReport.metricsDescription"
                                        defaultMessage={
                                            'Metrics relates to the data that is gathered within the company to measure and ' +
                                            'improve safety and quality, and how effectively it is used.'
                                        }
                                    />
                                </Box>
                            </div>
                        </Fragment>
                    : null}
                    { activeDimensionTab === 3 ?
                        <Fragment>
                            <span style={{ height: '10pt', width: '1pt', display: 'block' }} />
                            <div style={dimHeaderStyle} />
                            <div style={dimHeaderStyle} />
                            <div style={dimHeaderStyle} />
                            <Box style={dimHeaderStyle} className={styledClasses().awarenessDim}>
                                {intl.formatMessage({id: 'definitionsReport.awareness', defaultMessage: 'Awareness'})}
                            </Box>
                            <div style={{ ...clearStyle, ...dirStyle }}>
                                <Box p={2}>
                                    <FormattedMessage
                                        id="definitionsReport.awarenessDescription"
                                        defaultMessage={
                                            'Awareness relates to the knowledge of external safety and quality issues, including ' +
                                            'external stakeholders (suppliers and customers) and general industry standards.'
                                        }
                                    />
                                </Box>
                            </div>

                            <div style={dimHeaderStyle} />
                            <div style={dimHeaderStyle} />
                            <div style={dimHeaderStyle} />
                            <Box style={dimHeaderStyle} className={styledClasses().foresightDim}>
                                {intl.formatMessage({id: 'definitionsReport.foresight', defaultMessage: 'Foresight'})}
                            </Box>
                            <div style={{ ...clearStyle, ...dirStyle }}>
                                <Box p={2}>
                                    <FormattedMessage
                                        id="definitionsReport.foresightDescription"
                                        defaultMessage={
                                            'Foresight reflects a risk awareness and ability to prioritise based on the likelihood ' +
                                            'of safety and quality problems and consequences associated with them.'
                                        }
                                    />
                                </Box>
                            </div>

                            <div style={dimHeaderStyle} />
                            <div style={dimHeaderStyle} />
                            <div style={dimHeaderStyle} />
                            <Box style={dimHeaderStyle} className={styledClasses().innovationDim}>
                                {intl.formatMessage({id: 'definitionsReport.innovation', defaultMessage: 'Innovation'})}
                            </Box>
                            <div style={{ ...clearStyle, ...dirStyle }}>
                                <Box p={2}>
                                    <FormattedMessage
                                        id="definitionsReport.innovationDescription"
                                        defaultMessage={
                                            'Innovation relates to both an openness to change within individuals and the level of ' +
                                            'safety and quality change and innovation in the company.'
                                        }
                                    />
                                </Box>
                            </div>

                            <div style={dimHeaderStyle} />
                            <div style={dimHeaderStyle} />
                            <div style={dimHeaderStyle} />
                            <Box style={dimHeaderStyle} className={styledClasses().learningDim}>
                                {intl.formatMessage({id: 'definitionsReport.learning', defaultMessage: 'Learning'})}
                            </Box>
                            <div style={{ ...clearStyle, ...dirStyle }}>
                                <Box p={2}>
                                    <FormattedMessage
                                        id="definitionsReport.learningDescription"
                                        defaultMessage={
                                            'Learning relates to the attitude of individuals towards organisational learning, its ' +
                                            'perceived effectiveness, and its impact on safety and quality standards.'
                                        }
                                    />
                                </Box>
                            </div>

                            <div style={dimHeaderStyle} />
                            <div style={dimHeaderStyle} />
                            <div style={dimHeaderStyle} />
                            <Box style={dimHeaderStyle} className={styledClasses().investmentDim}>
                                {intl.formatMessage({id: 'definitionsReport.investment', defaultMessage: 'Investment'})}
                            </Box>
                            <div style={{ ...clearStyle, ...dirStyle }}>
                                <Box p={2}>
                                    <FormattedMessage
                                        id="definitionsReport.investmentDescription"
                                        defaultMessage={
                                            'Investment relates to the allocation and spending of budget on safety and quality, ' +
                                            'and perceptions of the effectiveness of this.'
                                        }
                                    />
                                </Box>
                            </div>
                        </Fragment>
                    : null}
                </Fragment>
            );
        } else if (2 === tab) {
            return (
                <Box p={2}>
                    <Typography variant="body1" paragraph>
                        <FormattedMessage id="definitionsReport.elementDescription1" defaultMessage="Within the Culture Excellence survey there are 3 to 10 questions that are used to determine the score for each Dimension." />
                        <br /><br />
                        <FormattedMessage id="definitionsReport.elementDescription2" defaultMessage="These questions reflect different aspects of the Dimension, and are referred to as Elements within this report." />
                    </Typography>
                </Box>
            );
        } else if (3 === tab) {
            return (
                <Table padding="none">
                    <TableHead style={{backgroundColor: '#3c3e40'}}>
                        <TableRow>
                            <TableCell style={{ ...smallColStyle, ...{ color: 'white' } }}>
                                {intl.formatMessage({id:'definitionsReport.score', defaultMessage: 'Score'})}
                            </TableCell>
                            <TableCell style={{ ...smallColStyle, ...{ color: 'white' } }}>
                                {intl.formatMessage({id:'definitionsReport.grade', defaultMessage: 'Grade'})}
                            </TableCell>
                            <TableCell style={{...dirStyle, ...{border: '1px solid #e0e0e0', paddingLeft: '24px', color: 'white', fontSize: '1rem'}}}>
                                <FormattedMessage
                                    id="definitionsReport.scoreBasicExplanation"
                                    defaultMessage="Basic Explanation"
                                />
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow key="1">
                            <TableCell style={smallColStyle}>90 - 100</TableCell>
                            <TableCell style={smallColStyle}>A+</TableCell>
                            <TableCell style={{ ...expStyle, ...dirStyle }} rowSpan="2">
                                <FormattedMessage
                                    id="definitionsReport.scoreExplanationVeryHigh"
                                    defaultMessage={
                                        'Very high scores that are clearly above the ‘industry norm’ (60) and indicate a high level ' +
                                        'of satisfaction and agreement at the site. Practical tools and support mechanisms are in place.'
                                    }
                                />
                            </TableCell>
                        </TableRow>
                        <TableRow key="2">
                            <TableCell style={smallColStyle}>85 - 89</TableCell>
                            <TableCell style={smallColStyle}>A</TableCell>
                        </TableRow>
                        <TableRow key="3">
                            <TableCell style={smallColStyle}>80 - 84</TableCell>
                            <TableCell style={smallColStyle}>A-</TableCell>
                            <TableCell style={{ ...expStyle, ...dirStyle }} rowSpan="3">
                                <FormattedMessage
                                    id="definitionsReport.scoreExplanationHigh"
                                    defaultMessage={
                                        'High scores that are above the ‘industry norm’ and indicate general satisfaction and agreement. ' +
                                        'Tools and support mechanisms are in place, and these are mostly well utilised though there are ' +
                                        'ways in which they can be enhanced.'
                                    }
                                />
                            </TableCell>
                        </TableRow>
                        <TableRow key="4">
                            <TableCell style={smallColStyle}>75 - 79</TableCell>
                            <TableCell style={smallColStyle}>B+</TableCell>
                        </TableRow>
                        <TableRow key="5">
                            <TableCell style={smallColStyle}>70 - 74</TableCell>
                            <TableCell style={smallColStyle}>B</TableCell>
                        </TableRow>
                        <TableRow key="6">
                            <TableCell style={smallColStyle}>65 - 69</TableCell>
                            <TableCell style={smallColStyle}>B-</TableCell>
                            <TableCell style={{ ...expStyle, ...dirStyle }} rowSpan="3">
                                <FormattedMessage
                                    id="definitionsReport.scoreExplanationMedium"
                                    defaultMessage={
                                        'Medium scores that are close to the ‘industry norm’ and indicate partial satisfaction and ' +
                                        'agreement. Some tools and support mechanisms exist, though they vary in effectiveness. ' +
                                        'Design, management or communication improvements could be made.'
                                    }
                                />
                            </TableCell>
                        </TableRow>
                        <TableRow key="7">
                            <TableCell style={smallColStyle}>60 - 64</TableCell>
                            <TableCell style={smallColStyle}>C+</TableCell>
                        </TableRow>
                        <TableRow key="8">
                            <TableCell style={smallColStyle}>55 - 59</TableCell>
                            <TableCell style={smallColStyle}>C</TableCell>
                        </TableRow>
                        <TableRow key="9">
                            <TableCell style={smallColStyle}>50 - 54</TableCell>
                            <TableCell style={smallColStyle}>C-</TableCell>
                            <TableCell style={{ ...expStyle, ...dirStyle }} rowSpan="3">
                                <FormattedMessage
                                    id="definitionsReport.scoreExplanationLow"
                                    defaultMessage={
                                        'Low scores that are below the ‘industry norm’ and indicate general dissatisfaction and low ' +
                                        'levels of agreement. If tools and support mechanisms are in place, these are not working effectively or ' +
                                        'consistently, and require improvement.'
                                    }
                                />
                            </TableCell>
                        </TableRow>
                        <TableRow key="10">
                            <TableCell style={smallColStyle}>45 - 49</TableCell>
                            <TableCell style={smallColStyle}>D+</TableCell>
                        </TableRow>
                        <TableRow key="11">
                            <TableCell style={smallColStyle}>40 - 44</TableCell>
                            <TableCell style={smallColStyle}>D</TableCell>
                        </TableRow>
                        <TableRow key="12">
                            <TableCell style={smallColStyle}>35 - 39</TableCell>
                            <TableCell style={smallColStyle}>D-</TableCell>
                            <TableCell style={{ ...expStyle, ...dirStyle }} rowSpan="3">
                                <FormattedMessage
                                    id="definitionsReport.scoreExplanationVeryLow"
                                    defaultMessage={
                                        'Very low scores that are well below the ‘industry norm’ and indicate serious dissatisfaction ' +
                                        'and very low levels of agreement. Tools and support mechanisms are either not in place, or are ' +
                                        'ineffective and require change.'
                                    }
                                />
                            </TableCell>
                        </TableRow>
                        <TableRow key="13">
                            <TableCell style={smallColStyle}>30 - 34</TableCell>
                            <TableCell style={smallColStyle}>E+</TableCell>
                        </TableRow>
                        <TableRow key="14">
                            <TableCell style={smallColStyle}>0 - 29</TableCell>
                            <TableCell style={smallColStyle}>E</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            );
        } else if (4 === tab) {
            return (
                <Box p={2}>
                    <Typography variant="h6">
                        <FormattedMessage id="definitionsReport.surveyQuestions" defaultMessage="Survey Questions" />
                    </Typography>
                    <p>
                        <FormattedMessage id="definitionsReport.surveyQuestionsP1"
                            defaultMessage={
                                "The survey questions can be viewed in the <strong>Elements</strong> view on the platform. The elements "+
                                "are questions rephrased as statements. For example, in the survey employees are asked to evaluate: "
                            }
                            values = {{
                                strong: chunks => <strong>{chunks}</strong>
                            }}
                        />
                    </p>
                    <p>
                        <em>
                            <FormattedMessage id="definitionsReport.surveyQuestionsP2" defaultMessage={'"Our company plans include improvements in the efficiency of the site."'} />
                        </em>
                    </p>

                    <p>
                        <FormattedMessage id="definitionsReport.surveyQuestionsP3" defaultMessage={
                            "This is then rephrased as a statement in Elements: Company plans include improvements in efficiency, and "+
                            "given a score out of 100 as explained below."}
                        />
                    </p>

                    <p>
                        <FormattedMessage id="definitionsReport.surveyQuestionsP4" defaultMessage={
                            "The elements can be selected based on different categories and are colour coded for ease of use."}
                        />
                    </p>

                    <Typography variant="h6">
                        <FormattedMessage id="definitionsReport.scoring" defaultMessage="Scoring" />
                    </Typography>


                    <p>
                        <FormattedMessage id="definitionsReport.scoringP1" defaultMessage={
                            "The majority of the questions in the survey are based on a Likert scale denoting various degrees of "+
                            "agreement or disagreement:"}
                        />
                    </p>

                    <Table style={{tableLayout: 'fixed'}}>
                        <TableHead>
                            <TableRow>
                                <TableCell style={{ backgroundColor: '#dcdcdc', border: '1px solid #000', width: '20%'}}>20%</TableCell>
                                <TableCell style={{ backgroundColor: '#dcdcdc', border: '1px solid #000', width: '20%'}}>40%</TableCell>
                                <TableCell style={{ backgroundColor: '#dcdcdc', border: '1px solid #000', width: '20%'}}>60%</TableCell>
                                <TableCell style={{ backgroundColor: '#dcdcdc', border: '1px solid #000', width: '20%'}}>80%</TableCell>
                                <TableCell style={{ backgroundColor: '#dcdcdc', border: '1px solid #000', width: '20%'}}>100%</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell style={{ border: '1px solid #000'}}><FormattedMessage id="definitionsReport.stronglyDisagree" defaultMessage="Strongly Disagree" /></TableCell>
                                <TableCell style={{ border: '1px solid #000'}}><FormattedMessage id="definitionsReport.disagree" defaultMessage="Disagree" /></TableCell>
                                <TableCell style={{ border: '1px solid #000'}}><FormattedMessage id="definitionsReport.partlyAgree" defaultMessage="Partly Agree" /></TableCell>
                                <TableCell style={{ border: '1px solid #000'}}><FormattedMessage id="definitionsReport.agree" defaultMessage="Agree" /></TableCell>
                                <TableCell style={{ border: '1px solid #000'}}><FormattedMessage id="definitionsReport.stronglyAgree" defaultMessage="Strongly Agree" /></TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>

                    <p>
                        <FormattedMessage id="definitionsReport.scoringP2"
                            defaultMessage={"The following questions however are not based on agreement, and instead focus on frequency or other criteria:"}
                        />
                    </p>

                    <p>
                        <FormattedMessage id="definitionsReport.communicationFrequency" defaultMessage="Communication Frequency:" />
                    </p>

                    <Table style={{tableLayout: 'fixed'}}>
                        <TableHead>
                            <TableRow>
                                <TableCell style={{ backgroundColor: '#8d3c961a', border: '1px solid #000', width: '20%'}}>20%</TableCell>
                                <TableCell style={{ backgroundColor: '#8d3c961a', border: '1px solid #000', width: '20%'}}>40%</TableCell>
                                <TableCell style={{ backgroundColor: '#8d3c961a', border: '1px solid #000', width: '20%'}}>60%</TableCell>
                                <TableCell style={{ backgroundColor: '#8d3c961a', border: '1px solid #000', width: '20%'}}>80%</TableCell>
                                <TableCell style={{ backgroundColor: '#8d3c961a', border: '1px solid #000', width: '20%'}}>100%</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell style={{ border: '1px solid #000'}}><FormattedMessage id="definitionsReport.neverRarely" defaultMessage="Never / Rarely" /></TableCell>
                                <TableCell style={{ border: '1px solid #000'}}><FormattedMessage id="definitionsReport.yearly" defaultMessage="Yearly" /></TableCell>
                                <TableCell style={{ border: '1px solid #000'}}><FormattedMessage id="definitionsReport.fewTimesAYear" defaultMessage="A few times a year" /></TableCell>
                                <TableCell style={{ border: '1px solid #000'}}><FormattedMessage id="definitionsReport.monthly" defaultMessage="Monthly" /></TableCell>
                                <TableCell style={{ border: '1px solid #000'}}><FormattedMessage id="definitionsReport.weeklyOrMore" defaultMessage="Weekly or more" /></TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>

                    <p>
                        <FormattedMessage id="definitionsReport.communicationEffectiveness" defaultMessage="Communication Effectiveness:" />
                    </p>

                    <Table style={{tableLayout: 'fixed'}}>
                        <TableHead>
                            <TableRow>
                                <TableCell style={{ backgroundColor: '#8d3c961a', border: '1px solid #000', width: '20%'}}>20%</TableCell>
                                <TableCell style={{ backgroundColor: '#8d3c961a', border: '1px solid #000', width: '20%'}}>40%</TableCell>
                                <TableCell style={{ backgroundColor: '#8d3c961a', border: '1px solid #000', width: '20%'}}>60%</TableCell>
                                <TableCell style={{ backgroundColor: '#8d3c961a', border: '1px solid #000', width: '20%'}}>80%</TableCell>
                                <TableCell style={{ backgroundColor: '#8d3c961a', border: '1px solid #000', width: '20%'}}>100%</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell style={{ border: '1px solid #000'}}><FormattedMessage id="definitionsReport.neverSeen" defaultMessage="Never seen / been involved in any" /></TableCell>
                                <TableCell style={{ border: '1px solid #000'}}><FormattedMessage id="definitionsReport.basic" defaultMessage="Basic, need major improvement" /></TableCell>
                                <TableCell style={{ border: '1px solid #000'}}><FormattedMessage id="definitionsReport.ok" defaultMessage="Ok, need some improvement" /></TableCell>
                                <TableCell style={{ border: '1px solid #000'}}><FormattedMessage id="definitionsReport.good" defaultMessage="Good, could be enhanced" /></TableCell>
                                <TableCell style={{ border: '1px solid #000'}}><FormattedMessage id="definitionsReport.excellent" defaultMessage="Excellent, interesting and memorable" /></TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>

                    <p>
                        <FormattedMessage id="definitionsReport.trainingFrequency" defaultMessage="Training Frequency:" />
                    </p>

                    <Table style={{tableLayout: 'fixed'}}>
                        <TableHead>
                            <TableRow>
                                <TableCell style={{ backgroundColor: '#8d3c961a', border: '1px solid #000', width: '20%'}}>20%</TableCell>
                                <TableCell style={{ backgroundColor: '#8d3c961a', border: '1px solid #000', width: '20%'}}>40%</TableCell>
                                <TableCell style={{ backgroundColor: '#8d3c961a', border: '1px solid #000', width: '20%'}}>60%</TableCell>
                                <TableCell style={{ backgroundColor: '#8d3c961a', border: '1px solid #000', width: '20%'}}>80%</TableCell>
                                <TableCell style={{ backgroundColor: '#8d3c961a', border: '1px solid #000', width: '20%'}}>100%</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                            <TableCell style={{ border: '1px solid #000'}}><FormattedMessage id="definitionsReport.neverRarely" defaultMessage="Never / Rarely" /></TableCell>
                                <TableCell style={{ border: '1px solid #000'}}><FormattedMessage id="definitionsReport.everyFewYears" defaultMessage="Every few years" /></TableCell>
                                <TableCell style={{ border: '1px solid #000'}}><FormattedMessage id="definitionsReport.yearly" defaultMessage="Yearly" /></TableCell>
                                <TableCell style={{ border: '1px solid #000'}}><FormattedMessage id="definitionsReport.fewTimesAYear" defaultMessage="A few times a year" /></TableCell>
                                <TableCell style={{ border: '1px solid #000'}}><FormattedMessage id="definitionsReport.monthlyOrMore" defaultMessage="Monthly or more" /></TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>

                    <p>
                        <FormattedMessage id="definitionsReport.sharingDataFrequency" defaultMessage="Sharing Data Frequency:" />
                    </p>

                    <Table style={{tableLayout: 'fixed'}}>
                        <TableHead>
                            <TableRow>
                                <TableCell style={{ backgroundColor: '#e41c4c1a', border: '1px solid #000', width: '20%'}}>20%</TableCell>
                                <TableCell style={{ backgroundColor: '#e41c4c1a', border: '1px solid #000', width: '20%'}}>40%</TableCell>
                                <TableCell style={{ backgroundColor: '#e41c4c1a', border: '1px solid #000', width: '20%'}}>60%</TableCell>
                                <TableCell style={{ backgroundColor: '#e41c4c1a', border: '1px solid #000', width: '20%'}}>80%</TableCell>
                                <TableCell style={{ backgroundColor: '#e41c4c1a', border: '1px solid #000', width: '20%'}}>100%</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell style={{ border: '1px solid #000'}}><FormattedMessage id="definitionsReport.neverRarely" defaultMessage="Never / Rarely" /></TableCell>
                                <TableCell style={{ border: '1px solid #000'}}><FormattedMessage id="definitionsReport.everyFewYears" defaultMessage="Every few years" /></TableCell>
                                <TableCell style={{ border: '1px solid #000'}}><FormattedMessage id="definitionsReport.yearly" defaultMessage="Yearly" /></TableCell>
                                <TableCell style={{ border: '1px solid #000'}}><FormattedMessage id="definitionsReport.fewTimesAYear" defaultMessage="A few times a year" /></TableCell>
                                <TableCell style={{ border: '1px solid #000'}}><FormattedMessage id="definitionsReport.monthlyOrMore" defaultMessage="Monthly or more" /></TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>

                    <p>
                        <FormattedMessage id="definitionsReport.internalAuditFrequency" defaultMessage="Internal Audit Frequency:" />
                    </p>

                    <Table style={{tableLayout: 'fixed'}}>
                        <TableHead>
                            <TableRow>
                                <TableCell style={{ backgroundColor: '#08bbd61a', border: '1px solid #000', width: '20%'}}>20%</TableCell>
                                <TableCell style={{ backgroundColor: '#08bbd61a', border: '1px solid #000', width: '20%'}}>40%</TableCell>
                                <TableCell style={{ backgroundColor: '#08bbd61a', border: '1px solid #000', width: '20%'}}>60%</TableCell>
                                <TableCell style={{ backgroundColor: '#08bbd61a', border: '1px solid #000', width: '20%'}}>80%</TableCell>
                                <TableCell style={{ backgroundColor: '#08bbd61a', border: '1px solid #000', width: '20%'}}>100%</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell style={{ border: '1px solid #000'}}><FormattedMessage id="definitionsReport.neverRarely" defaultMessage="Never / Rarely" /></TableCell>
                                <TableCell style={{ border: '1px solid #000'}}><FormattedMessage id="definitionsReport.everyFewYears" defaultMessage="Every few years" /></TableCell>
                                <TableCell style={{ border: '1px solid #000'}}><FormattedMessage id="definitionsReport.yearly" defaultMessage="Yearly" /></TableCell>
                                <TableCell style={{ border: '1px solid #000'}}><FormattedMessage id="definitionsReport.fewTimesAYear" defaultMessage="A few times a year" /></TableCell>
                                <TableCell style={{ border: '1px solid #000'}}><FormattedMessage id="definitionsReport.monthlyOrMore" defaultMessage="Monthly or more" /></TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>

                    <p>
                        <FormattedMessage id="definitionsReport.evalToolsAtSite" defaultMessage="Evaluating tools at the site:" />
                    </p>

                    <Table style={{tableLayout: 'fixed'}}>
                        <TableHead>
                            <TableRow>
                                <TableCell style={{ backgroundColor: '#08bbd61a', border: '1px solid #000', width: '20%'}}>20%</TableCell>
                                <TableCell style={{ backgroundColor: '#08bbd61a', border: '1px solid #000', width: '20%'}}>40%</TableCell>
                                <TableCell style={{ backgroundColor: '#08bbd61a', border: '1px solid #000', width: '20%'}}>60%</TableCell>
                                <TableCell style={{ backgroundColor: '#08bbd61a', border: '1px solid #000', width: '20%'}}>80%</TableCell>
                                <TableCell style={{ backgroundColor: '#08bbd61a', border: '1px solid #000', width: '20%'}}>100%</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell style={{ border: '1px solid #000'}}><FormattedMessage id="definitionsReport.neverSeenAny" defaultMessage="Never seen any" /></TableCell>
                                <TableCell style={{ border: '1px solid #000'}}><FormattedMessage id="definitionsReport.basicNeedMajorImprovement" defaultMessage="Basic, need major improvement" /></TableCell>
                                <TableCell style={{ border: '1px solid #000'}}><FormattedMessage id="definitionsReport.okNeedSomeImprovement" defaultMessage="Ok, need some improvement" /></TableCell>
                                <TableCell style={{ border: '1px solid #000'}}><FormattedMessage id="definitionsReport.goodCouldBeEnhanced" defaultMessage="Good, could be enhanced" /></TableCell>
                                <TableCell style={{ border: '1px solid #000'}}><FormattedMessage id="definitionsReport.excellentPracticalAndEasy" defaultMessage="Excellent, practical and easy to use" /></TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>

                    <p>
                        <FormattedMessage id="definitionsReport.evalLvlOfPaperwork" defaultMessage="Evaluating levels of paperwork:" />
                    </p>

                    <Table style={{tableLayout: 'fixed'}}>
                        <TableHead>
                            <TableRow>
                                <TableCell style={{ backgroundColor: '#08bbd61a', border: '1px solid #000', width: '20%'}}>40%</TableCell>
                                <TableCell style={{ backgroundColor: '#08bbd61a', border: '1px solid #000', width: '20%'}}>80%</TableCell>
                                <TableCell style={{ backgroundColor: '#08bbd61a', border: '1px solid #000', width: '20%'}}>100%</TableCell>
                                <TableCell style={{ backgroundColor: '#08bbd61a', border: '1px solid #000', width: '20%'}}>60%</TableCell>
                                <TableCell style={{ backgroundColor: '#08bbd61a', border: '1px solid #000', width: '20%'}}>20%</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell style={{ border: '1px solid #000'}}><FormattedMessage id="definitionsReport.tooMuch" defaultMessage="Too much (not manageable)" /></TableCell>
                                <TableCell style={{ border: '1px solid #000'}}><FormattedMessage id="definitionsReport.aLittleTooMuch" defaultMessage="A little too much (manageable)" /></TableCell>
                                <TableCell style={{ border: '1px solid #000'}}><FormattedMessage id="definitionsReport.theRightAmount" defaultMessage="The right amount" /></TableCell>
                                <TableCell style={{ border: '1px solid #000'}}><FormattedMessage id="definitionsReport.notEnoughSmall" defaultMessage="Not enough (small gaps)" /></TableCell>
                                <TableCell style={{ border: '1px solid #000'}}><FormattedMessage id="definitionsReport.notEnoughMajor" defaultMessage="Not enough (major gaps)" /></TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>

                    <p>
                        <FormattedMessage id="definitionsReport.howInformedPeopleFeel" defaultMessage="How informed people feel about values, vision and strategy:" />
                    </p>

                    <Table style={{tableLayout: 'fixed'}}>
                        <TableHead>
                            <TableRow>
                                <TableCell style={{ backgroundColor: '#e41c4c1a', border: '1px solid #000', width: '20%'}}>20%</TableCell>
                                <TableCell style={{ backgroundColor: '#e41c4c1a', border: '1px solid #000', width: '20%'}}>40%</TableCell>
                                <TableCell style={{ backgroundColor: '#e41c4c1a', border: '1px solid #000', width: '20%'}}>60%</TableCell>
                                <TableCell style={{ backgroundColor: '#e41c4c1a', border: '1px solid #000', width: '20%'}}>80%</TableCell>
                                <TableCell style={{ backgroundColor: '#e41c4c1a', border: '1px solid #000', width: '20%'}}>100%</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell style={{ border: '1px solid #000'}}><FormattedMessage id="definitionsReport.notAtAll" defaultMessage="Not at all" /></TableCell>
                                <TableCell style={{ border: '1px solid #000'}}><FormattedMessage id="definitionsReport.aLittle" defaultMessage="A little" /></TableCell>
                                <TableCell style={{ border: '1px solid #000'}}><FormattedMessage id="definitionsReport.partly" defaultMessage="Partly" /></TableCell>
                                <TableCell style={{ border: '1px solid #000'}}><FormattedMessage id="definitionsReport.well" defaultMessage="Well informed" /></TableCell>
                                <TableCell style={{ border: '1px solid #000'}}><FormattedMessage id="definitionsReport.veryWell" defaultMessage="Very well informed" /></TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>

                </Box>
            );
        }
        return null;
    };

    return (
        (props.surveyType ?
        <Fragment>
            <Tabs classes={{ root: styledClasses().topTabs, indicator: styledClasses().topIndicator }} value={activeTab} onChange={handleTabChange} variant="fullWidth">
                <Tab label={intl.formatMessage({id:'definitionsReport.category', defaultMessage: 'Category'})} />
                <Tab label={intl.formatMessage({id:'definitionsReport.dimension', defaultMessage: 'Dimension'})} />
                <Tab label={intl.formatMessage({id:'definitionsReport.element', defaultMessage: 'Element'})} />
                <Tab label={intl.formatMessage({id:'definitionsReport.grades', defaultMessage: 'Grades'})} />
                <Tab label={intl.formatMessage({id:'definitionsReport.scoreGuide', defaultMessage: 'Score Guide'})} />
            </Tabs>
            {tabs(activeTab)}
        </Fragment>
        : null)
    );
}

