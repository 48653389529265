import { useIntl } from "react-intl";
import { riskTranslated } from "../../Translation/Risk";
import { useEffect, useRef } from "react";
import BarLazy from "../../Chart/BarLazy";

export default function RisksComparison(props) {
    const intl = useIntl();
    const chartRef = useRef(null);

    useEffect(() => {
        const mediaQueryListener = window.matchMedia('print');
        const onPrint = (mql) => {
            if (chartRef.current && mql.matches) {
                console.log('onPrint resize');
                chartRef.current.resize();
            }
        }

        const afterPrint = () => {
            if (chartRef.current) {
                console.log('afterPrint resize');
                chartRef.current.resize();
            }
        };
        window.addEventListener('afterprint', afterPrint);
        mediaQueryListener.addEventListener('change', onPrint);

        return () => {
            window.removeEventListener('afterprint', afterPrint);
            mediaQueryListener.removeEventListener('change', onPrint);
        }
    }, [chartRef]);

    if (!props.reportData.data) {
        return null;
    }

    const riskTypes = [
        'Low risk',
        'Low risk comparison',
        'Medium risk',
        'Medium risk comparison',
        'High risk',
        'High risk comparison'
    ];
    const riskColors = {
        'Low risk': {
            background: '#40ae49b3',
            border: '#3ca344',
        },
        'Low risk comparison': {
            background: '#8fd695b3',
            border: '#3ca344',
        },
        'Medium risk': {
            background: '#fd6a37b3',
            border: '#e05e31',
        },
        'Medium risk comparison': {
            background: '#feb39ab3',
            border: '#e05e31',
        },
        'High risk': {
            background: '#d11f25b3',
            border: '#ff1a1a',
        },
        'High risk comparison': {
            background: '#eb7a7eb3',
            border: '#ff1a1a',
        },
    };
    const chartData = {
        labels: [],
        datasets: [],
    };

    const riskTypesTranslated = {
        'Low risk': intl.formatMessage({id: 'risksReport.lowRisk', defaultMessage: 'Low risk'}),
        'Low risk comparison': intl.formatMessage({id: 'risksComparison.lowRiskComparison', defaultMessage: 'Low risk comparison'}),
        'Medium risk': intl.formatMessage({id: 'risksReport.mediumRisk', defaultMessage: 'Medium risk'}),
        'Medium risk comparison': intl.formatMessage({id: 'risksComparison.mediumRiskComparison', defaultMessage: 'Medium risk comparison'}),
        'High risk': intl.formatMessage({id: 'risksReport.highRisk', defaultMessage: 'High risk'}),
        'High risk comparison': intl.formatMessage({id: 'risksComparison.highRiskComparison', defaultMessage: 'High risk comparison'}),
    };

    riskTypes.forEach(riskType => {
        chartData.datasets.push({
            stack: riskType.endsWith(' comparison') ? '1' : '0',
            label: riskTypesTranslated[riskType],
            backgroundColor: riskColors[riskType] ? riskColors[riskType].background : null,
            borderColor: riskColors[riskType] ? riskColors[riskType].border : null,
            data: [],
        });
    });

    // Labels for data series
    Object.keys(props.reportData.data.current).forEach(riskKey => {
        const translatedRisk = riskTranslated(props.reportData.data.current[riskKey][0]['title'], intl);
        if (props.print) {
            chartData.labels.push(translatedRisk.split(' '));
        } else {
            chartData.labels.push(translatedRisk);
        }

        // Add each risktype to datasets
        riskTypes.forEach((riskType, index) => {
            let currentRiskType = null;
            if (riskType.endsWith('comparison')) {
                currentRiskType = props.reportData.data.previous && props.reportData.data.previous[riskKey] ? props.reportData.data.previous[riskKey].find(el => riskType.replace(' comparison', '').toLowerCase() === el['answer'].toLowerCase()) : null;
            } else {
                currentRiskType = props.reportData.data.current[riskKey].find(el => riskType.toLowerCase() === el['answer'].toLowerCase());
            }

            if (currentRiskType) {
                chartData.datasets[index].data.push(currentRiskType['percentage']);
            } else {
                chartData.datasets[index].data.push(0);
            }
        });
    });

    const chartOptions = {
        interaction: {
            intersect: false,
            mode: 'point',
        },
        scales: {
            y: {
                stacked: true,
                min: 0,
                max: 100,
                title: {
                    display: true,
                    text: intl.formatMessage({id: 'generic.totalPercent', defaultMessage: 'Total (%)'})
                }
            },
            x: {
                stacked: true,
            }
        },
        animation: {
            duration: 0,
        },
        plugins: {
            tooltip: {
                position: 'nearest',
                callbacks: {
                    label: (context) => {
                        const label = context.dataset.label || '';
                        return label ? label + ': ' + context.parsed.y.toFixed(1) + '%' : '';
                    }
                },
            },
            datalabels: {
                anchor: 'center',
                align: 'center',
                clamp: true,
                color: '#3c3e40',
                font: {
                    weight: '600'
                },
                formatter: (value, context) => {
                    return value ? value.toFixed(0)+'%' : '';
                }
            }
        },
    };

    if (props.print) {
        chartOptions.devicePixelRatio = 4; // Avoid charts looking blurry when printed
        chartOptions.hover = {mode: null}; // Hover makes the chart disappear when printed
        chartOptions.plugins.tooltip.enabled = false; // Tooltip makes the chart disappear when printed
    }

    return (
        <div style={{overflow: 'auto', width: '100%', margin: '0 auto'}} ref={props.setScreenshotRef}>
            <BarLazy innerRef={chartRef} className="chart-canvas" data={chartData} options={chartOptions} dataLabels={true} />
        </div>
    );
}
