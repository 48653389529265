import { FormattedMessage } from "react-intl";
import { Fragment } from "react";

export default function QualitativeComments(props) {
    return (
        <Fragment>
            { props.title && props.print ? <h2 style={{fontWeight: 400}}>{props.title}</h2> : null}

            <p>
                <FormattedMessage id="execSummaryQualComments.p1"
                    defaultMessage={
                        "Qualitative comments can add detailed insights but it is important to bear in mind that they are "+
                        "individual comments rather than aggregated data. Where they add context to the quantitative data, or "+
                        "patterns / trends are observed, they can be of most value."
                    }
                />
            </p>

            <p>
                <FormattedMessage id="execSummaryQualComments.p2"
                    defaultMessage={
                        "When looking at the qualitative data to get further insight into the quantitative findings, it is "+
                        "important to use a variety of relevant search terms. For example, if exploring issues related to "+
                        "training and communication, search terms could include: train, communicate, communication, learn, talk, "+
                        "listen, speak, email, meeting, poster, etc."
                    }
                />
            </p>
        </Fragment>
    );
}
