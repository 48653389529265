export const CATEGORY_COLORS = {
    people: {
        dark: '#8e3c97',
        light: 'rgba(142, 60, 151, 0.1)',
    },
    process: {
        dark: '#08bbd6',
        light: 'rgba(8, 187, 214, 0.1)'
    },
    purpose: {
        dark: '#e41c4c',
        light: 'rgba(228, 28, 76, 0.1)'
    },
    proactivity: {
        dark: '#8cc43f',
        light: 'rgba(140, 196, 63, 0.1)'
    }
};