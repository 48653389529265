import { Grid, Paper, Typography } from "@material-ui/core";
import { FormattedMessage } from "react-intl";
import { BRCGS_CATEGORY_COLORS } from '../../Constants/BrcgsCategoryColors';

export default function BrcgsFreeResources(props) {

    return (
        <Grid container direction="row" justifyContent="center" alignItems="center">
            <Grid item>
                <Typography variant="h6" paragraph>
                    <FormattedMessage id="resources.brcgsFreeInstructionManual" defaultMessage="Instruction Manual" />
                </Typography>
                <Paper variant="outlined" style={{borderColor: BRCGS_CATEGORY_COLORS['people'].dark, backgroundColor: BRCGS_CATEGORY_COLORS['people'].light}}>
                    <a href="https://resources.culturexcellence.com/BRCGS_FSCE_Basic_Instruction_Manual.pdf" target="_blank" rel="noreferrer">
                        <img src="https://resources.culturexcellence.com/BRCGS_FSCE_Basic_Instruction_Manual.png" alt="Instruction manual" width="320" />
                    </a>
                </Paper>
            </Grid>
        </Grid>
    );
}