import { Card, CardContent, CardHeader, Grid, makeStyles, Table, TableBody, TableCell, TableRow, Tooltip, Typography } from "@material-ui/core";
import { REGIONS } from "../../../Constants/Regions";
import { ComposableMap, Geographies, Geography  } from "react-simple-maps";
import { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { countryTranslated } from "../../Translation/Country";

const useStyles = makeStyles((theme) => ({
    cardHeader: {
        padding: 8,
        // backgroundColor: CATEGORY_COLORS.process.dark,
        backgroundColor: theme.palette.primary.main,
        textAlign: 'center',
        color: '#ffffff'
    },
    card: {
        height: '100%'
    },
    cardContent: {
        height: 'calc(100% - 48px)'
    },
    tableCell: {
        borderBottom: 'none'
    }
}));

export default function Summary(props) {
    const classes = useStyles();
    const intl = useIntl();

    const [mapTooltip, setMapTooltip] = useState('');
    const [mapTooltipPos, setMapTooltipPos] = useState({ x: undefined, y: undefined });

    if (!props.reportData.data) {
        return null;
    }

    const responseCount = props.reportData.data.demographics &&
        props.reportData.data.demographics.demographics &&
        props.reportData.data.demographics.demographics.site ?
        Object.values(props.reportData.data.demographics.demographics.site).reduce((a,b) => a+b) : '-';

    const totalCount = props.reportData.data.demographics &&
        props.reportData.data.demographics.targets ?
        Object.values(props.reportData.data.demographics.targets).reduce((sum, val) => (sum + val.total), 0) : '-';

    let targetCount = null;
    if (props.reportData.data.demographics && props.reportData.data.demographics.targets) {
        Object.keys(props.reportData.data.demographics.targets).forEach(siteValue => {
            if (props.reportData.data.demographics.targets[siteValue].roles) {
                Object.keys(props.reportData.data.demographics.targets[siteValue].roles).forEach(roleValue => {
                    targetCount = (targetCount ? targetCount : 0) + props.reportData.data.demographics.targets[siteValue].roles[roleValue].target;
                })
            } else if (props.reportData.data.demographics.targets[siteValue].total) {
                targetCount = (targetCount ? targetCount : 0) + props.reportData.data.demographics.targets[siteValue].target;
            }
        });
    }

    const siteCount = props.reportData.data.demographics &&
        props.reportData.data.demographics.demographics &&
        props.reportData.data.demographics.demographics.site ?
        Object.keys(props.reportData.data.demographics.demographics.site).filter(k => props.reportData.data.demographics.demographics.site[k] !== 0).length : '-';

    const countryCount = props.reportData.data.countries &&
        props.reportData.data.countries.length ?
        props.reportData.data.countries.length : '-';

    const countryList = props.reportData.data.countries &&
        props.reportData.data.countries.length ?
        props.reportData.data.countries.slice() : [];

    // Map uses GB for 2 letter country code
    if (-1 !== countryList.indexOf('UK')) {
        countryList.push('GB');
    }

    const regions = [];
    //const tooltips = [];
    if ('-' !== countryCount) {
        props.reportData.data.countries.forEach((countryCode) => {
            for (const [region, countryCodes] of Object.entries(REGIONS)) {
                if (-1 !== countryCodes.indexOf(countryCode) && -1 === regions.indexOf(region)) {
                    regions.push(region);
                }
            }
            // TODO: Build tooltips
        })
    }
    const regionCount = regions.length ? regions.length : '-';

    return (
        <Grid container spacing={2} ref={props.setScreenshotRef}>
            <Grid item xs={12} lg={3}>
                <Card className={classes.card}>
                    <CardHeader title={<FormattedMessage id="summary.totalScoreAndGrade" defaultMessage={"Total Score & Grade"} />} titleTypographyProps={{variant: 'h6'}} className={classes.cardHeader} />
                    <CardContent className={classes.cardContent}>
                        <Grid
                            container
                            direction="row"
                            justifyContent="space-around"
                            alignItems="center"
                            style={{height: '100%'}}
                        >
                            <Grid item >
                                <Typography style={{height: '100%'}} variant="h2">{props.reportData.data.total ? props.reportData.data.total.roundedScore : '-'}</Typography>
                            </Grid>
                            <Grid item>
                                <Typography variant="h2">{props.reportData.data.total ? props.reportData.data.total.grade : '-'}</Typography>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={12} lg={3}>
                <Card className={classes.card}>
                    <CardHeader title={<FormattedMessage id="summary.locations" defaultMessage={"Locations"} />} titleTypographyProps={{variant: 'h6'}} className={classes.cardHeader} />
                    <CardContent className={classes.cardContent}>
                        <Table size="small">
                            <TableBody>
                                <TableRow>
                                    <TableCell align="left" className={classes.tableCell}>
                                        <Typography variant="body1">
                                            <FormattedMessage id="summary.sites" defaultMessage={"Sites:"} />
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="right" className={classes.tableCell}>
                                        {siteCount}
                                    </TableCell>
                                </TableRow>
                                {props.surveyData && props.surveyData.relationships.company.data.countryCode === 'CN' ? null :
                                    <TableRow>
                                        <TableCell align="left" className={classes.tableCell}>
                                            <Typography variant="body1">
                                                <FormattedMessage id="summary.countries" defaultMessage={"Countries:"}/>
                                            </Typography>
                                        </TableCell>
                                        <TableCell align="right" className={classes.tableCell}>
                                            {countryCount}
                                        </TableCell>
                                    </TableRow>
                                }
                                <TableRow>
                                    <TableCell align="left" className={classes.tableCell}>
                                        <Typography variant="body1">
                                            <FormattedMessage id="summary.regions" defaultMessage={"Regions:"}/>
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="right" className={classes.tableCell}>
                                        {regionCount}
                                    </TableCell>
                                </TableRow>

                            </TableBody>
                        </Table>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={12} lg={6}>
                <Card className={classes.card}>
                    <CardHeader title={intl.formatMessage({id: 'generic.responses', defaultMessage: 'Responses'})} titleTypographyProps={{variant: 'h6'}} className={classes.cardHeader} />
                    <CardContent className={classes.cardContent}>
                        <Table size="small">
                            <TableBody>
                                <TableRow>
                                    <TableCell align="left" className={classes.tableCell}>
                                        <Typography variant="body1">
                                            <FormattedMessage id="summary.responses" defaultMessage={"Responses:"}/>
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="right" className={classes.tableCell}>
                                        {responseCount ? responseCount : '-'}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell align="left" className={classes.tableCell}>
                                        <Typography variant="body1">
                                            <FormattedMessage id="summary.totalEmployees" defaultMessage={"Total employees:"}/>
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="right" className={classes.tableCell}>
                                        {totalCount ? totalCount : '-'}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell align="left" className={classes.tableCell}>
                                        <Typography variant="body1">
                                            <FormattedMessage id="summary.targetResponses" defaultMessage={"Target responses:"}/>
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="right" className={classes.tableCell}>
                                        {targetCount ? targetCount : '-'}
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </CardContent>
                </Card>
            </Grid>
            {props.surveyData && props.surveyData.relationships.company.data.countryCode === 'CN' ? null :
                <Grid item xs={12}>
                    <ComposableMap
                        projectionConfig={{
                            scale: 100,
                            rotate: [-10, 0, 0],
                            center: [0, 25],
                        }}
                        projection="geoMercator"
                        width={775}
                        height={350}
                        style={{ width: "100%", maxHeight: "auto" }}
                    >
                        <Geographies geography="/static/world-110m.json">
                            {({ geographies }) =>
                                geographies.map(geo => {
                                    return <Geography
                                        key={geo.rsmKey}
                                        geography={geo}
                                        fill={-1 === countryList.indexOf(geo.properties.ISO_A2) ? '#ededed' : 'rgb(8, 187, 214)'}
                                        stroke="#d6d6d6"
                                        strokeWidth={0.5}
                                        onMouseEnter={() => {
                                            if (-1 !== countryList.indexOf(geo.properties.ISO_A2)) {
                                                setMapTooltip(<span>
                                                    {countryTranslated(geo.properties.NAME, intl)}
                                                </span>);
                                            } else {
                                                setMapTooltip('');
                                            }
                                        }}
                                        onMouseLeave={() => {
                                            setMapTooltip('');
                                        }}
                                        onMouseMove={e => setMapTooltipPos({ x: e.pageX, y: e.pageY })}
                                        style={{
                                            default: { outline: "none" },
                                            hover: { outline: "none" },
                                            pressed: { outline: "none" },
                                        }}
                                    />
                                })
                            }
                        </Geographies>
                    </ComposableMap>
                    {mapTooltip !== '' ?
                        <Tooltip
                            style={'' === mapTooltip ? {display: 'none'} : {}}
                            title={mapTooltip}
                            open={mapTooltip !== ''}
                            //open={showMapTooltip}
                            enterDelay={0} leaveDelay={0}
                            PopperProps={{
                                anchorEl: {
                                    clientHeight: 0,
                                    clientWidth: 0,
                                    getBoundingClientRect: () => ({
                                        top: mapTooltipPos.y,
                                        left: mapTooltipPos.x,
                                        right: mapTooltipPos.x,
                                        bottom: mapTooltipPos.y,
                                        width: 0,
                                        height: 0,
                                    })
                                }
                            }}
                        >
                            {/* Tooltip has to have a child element */}
                            <span />
                        </Tooltip>
                    : null}
                </Grid>
            }
        </Grid>
    );
}
