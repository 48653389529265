import { Box, Grid, Paper, Table, TableBody, TableCell as MuiTableCell, TableContainer, TableRow, Typography, withStyles } from "@material-ui/core";
import { BRCGS_CATEGORY_COLORS } from '../../../Constants/BrcgsCategoryColors';
import peopleLogo from '../img/category/brcgs-people.png';
import proactivityLogo from '../img/category/brcgs-proactivity.png';
import purposeLogo from '../img/category/brcgs-purpose.png';
import processLogo from '../img/category/brcgs-process.png';
import DimensionLabel from "../../Translation/DimensionLabel";
import CategoryLabel from "../../Translation/CategoryLabel";

const TableCell = withStyles({
    root: {
        borderBottom: "none"
    }
})(MuiTableCell);

export default function BrcgsBasicData(props) {

    const categoryLogos = {
        people: peopleLogo,
        proactivity: proactivityLogo,
        purpose: purposeLogo,
        process: processLogo,
    };

    if (!props.reportData.data || 0 === Object.keys(props.reportData.data).length) {
        return null;
    }

    return (
        <div ref={props.setScreenshotRef}>
            {Object.keys(props.reportData.data.categories).map(categoryKey => (
                <Box pb={2} key={categoryKey}>
                    <Paper key={categoryKey} elevation={1} style={{borderLeft: '5px solid '+BRCGS_CATEGORY_COLORS[categoryKey].dark}}>
                        <Grid container direction="row" justifyContent="center" alignItems="center" style={{backgroundColor: BRCGS_CATEGORY_COLORS[categoryKey].light}}>
                            <Grid item sm={2} align="center">
                                <img style={{ maxWidth: '110px', maxHeight: "100%" }} alt={categoryKey} src={categoryLogos[categoryKey]} />
                                <Typography variant="subtitle1">
                                    <CategoryLabel category={categoryKey} upperCase={true} />
                                </Typography>
                            </Grid>
                            <Grid item sm={8} align="left">
                                <Box pb={2} pt={2}>
                                    <TableContainer>
                                        <Table size="small">
                                            <TableBody>
                                                {Object.keys(props.reportData.data.categories[categoryKey].dimensions).map(dimensionKey => (
                                                    <TableRow key={dimensionKey}>
                                                        <TableCell style={{ width: "30%", textAlign: 'right' }}>
                                                            <DimensionLabel dimension={dimensionKey} />
                                                        </TableCell>
                                                        <TableCell style={{ width: "10%", textAlign: 'right', fontWeight: 'bold' }}>
                                                            <span title={props.reportData.data.categories[categoryKey].dimensions[dimensionKey].score ? props.reportData.data.categories[categoryKey].dimensions[dimensionKey].score : ''}>
                                                                {null !== props.reportData.data.categories[categoryKey].dimensions[dimensionKey].roundedScore ? props.reportData.data.categories[categoryKey].dimensions[dimensionKey].roundedScore : '-'}
                                                            </span>
                                                        </TableCell>
                                                        <TableCell style={{ width: "60%" }}>
                                                            {props.reportData.data.categories[categoryKey].dimensions[dimensionKey].roundedScore ?
                                                                <div style={{backgroundColor: BRCGS_CATEGORY_COLORS[categoryKey].dark, height: '15px', width: Math.round(props.reportData.data.categories[categoryKey].dimensions[dimensionKey].roundedScore) + '%'}} />
                                                            : ''}
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Box>
                            </Grid>
                            <Grid item sm={2} align="center">
                                <Typography title={props.reportData.data.categories[categoryKey].score ? props.reportData.data.categories[categoryKey].score : ''} variant="h2" style={{fontWeight: 'normal'}}>{props.reportData.data.categories[categoryKey].roundedScore ? props.reportData.data.categories[categoryKey].roundedScore : '-'}</Typography>
                            </Grid>
                        </Grid>
                    </Paper>
                </Box>
            ))}
        </div>
    );
}