import { Backdrop, withStyles } from "@material-ui/core";

export default withStyles({
    root: {
        position: "absolute",
        zIndex: 1,
        backgroundColor: 'rgba(255, 255, 255, 0.25)',
        transitionTimingFunction: 'ease-in',
        //animation: 'fadein 2.6s',
    }
})(Backdrop);