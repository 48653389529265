export const dimensionTranslated = (dimension, intl) => {
    if (!dimension) {
        return null;
    }

    switch (dimension.toLowerCase()) {
        case 'reinforcement':
        case 'reward':
            return intl.formatMessage({id: 'generic.rewardDimension', defaultMessage: 'Reward'});

        case 'objectives':
        case 'targets':
            return intl.formatMessage({id: 'generic.targetsDimension', defaultMessage: 'Targets'});

        case 'change':
        case 'innovation':
            return intl.formatMessage({id: 'generic.innovationDimension', defaultMessage: 'Innovation'});

        case 'empowerment':
            return intl.formatMessage({id: 'generic.empowermentDimension', defaultMessage: 'Empowerment'});

        case 'teamwork':
            return intl.formatMessage({id: 'generic.teamworkDimension', defaultMessage: 'Teamwork'});

        case 'training':
            return intl.formatMessage({id: 'generic.trainingDimension', defaultMessage: 'Training'});

        case 'communication':
            return intl.formatMessage({id: 'generic.communicationDimension', defaultMessage: 'Communication'});

        case 'control':
            return intl.formatMessage({id: 'generic.controlDimension', defaultMessage: 'Control'});

        case 'coordination':
            return intl.formatMessage({id: 'generic.coordinationDimension', defaultMessage: 'Coordination'});

        case 'consistency':
            return intl.formatMessage({id: 'generic.consistencyDimension', defaultMessage: 'Consistency'});

        case 'systems':
            return intl.formatMessage({id: 'generic.systemsDimension', defaultMessage: 'Systems'});

        case 'premises':
            return intl.formatMessage({id: 'generic.premisesDimension', defaultMessage: 'Premises'});

        case 'vision':
            return intl.formatMessage({id: 'generic.visionDimension', defaultMessage: 'Vision'});

        case 'values':
            return intl.formatMessage({id: 'generic.valuesDimension', defaultMessage: 'Values'});

        case 'strategy':
            return intl.formatMessage({id: 'generic.strategyDimension', defaultMessage: 'Strategy'});

        case 'metrics':
            return intl.formatMessage({id: 'generic.metricsDimension', defaultMessage: 'Metrics'});

        case 'awareness':
            return intl.formatMessage({id: 'generic.awarenessDimension', defaultMessage: 'Awareness'});

        case 'foresight':
            return intl.formatMessage({id: 'generic.foresightDimension', defaultMessage: 'Foresight'});

        case 'learning':
            return intl.formatMessage({id: 'generic.learningDimension', defaultMessage: 'Learning'});

        case 'investment':
            return intl.formatMessage({id: 'generic.investmentDimension', defaultMessage: 'Investment'});

        default:
            return dimension.charAt(0).toUpperCase() + dimension.slice(1);
    }
}