import { createTheme } from '@material-ui/core/styles';

const white = '#ffffff';

// BRCGS theme
const brcgs = createTheme({
    typography: {
        button: {
            textTransform: 'none',
            fontSize: '1rem'
        },
        subtitle1: {
            fontWeight: 500
        }
    },
    palette: {
        primary: {
            main: '#3c3e40',
        },
        secondary: {
            main: '#ea7600',
        },
        text: {
            primary: '#403938',
        },
        background: {
            default: white,
            paper: white,
        },
        // Custom palette colors
        people: {
            main: 'rgb(142, 60, 151)',
            light: 'rgba(142, 60, 151, 0.1)'
        },
        process: {
            main: 'rgb(8, 187, 214)',
            light: 'rgba(8, 187, 214, 0.1)'
        },
        purpose: {
            main: 'rgb(228, 28, 76)',
            light: 'rgba(228, 28, 76, 0.1)',
        },
        proactivity: {
            main: 'rgb(140, 196, 63)',
            light: 'rgba(140, 196, 63, 0.1)'
        }
    },
    overrides: {
        MuiSnackbarContent: {
            root: {
                fontSize: "12pt"
            }
        },
        MuiTooltip: {
            tooltip: {
                fontSize: "0.75rem",
                fontWeight: 400,
            }
        },
        MuiButton: {
            root: {
                borderRadius: 12,
                paddingLeft: '24px',
                paddingRight: '24px',
                paddingTop: '8px',
                paddingBottom: '8px',
            },
            containedSecondary: {
                color: "#ffffff"
            }
        },
        MuiFab: {
            label: {
                color: '#ffffff'
            },
        },
        MuiTableRow: {
            root: {
                "&:last-child td": {
                    borderBottom: 0,
                },
            }
        },
        MUIDataTableToolbar: {
            root: {
                paddingLeft: '16px',
                paddingRight: '16px',
            }
        },
    }
  });
export default brcgs;