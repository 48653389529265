import { Fragment } from "react";
import { Button, Box, Grid, Paper, Table, TableBody, TableCell as MuiTableCell, TableContainer, TableRow, Typography, withStyles } from "@material-ui/core";
import { BRCGS_CATEGORY_COLORS } from '../../../Constants/BrcgsCategoryColors';
import { NavLink } from "react-router-dom";
import peopleLogo from '../img/category/brcgs-people.png';
import proactivityLogo from '../img/category/brcgs-proactivity.png';
import purposeLogo from '../img/category/brcgs-purpose.png';
import processLogo from '../img/category/brcgs-process.png';
import NoData from "../../NoData/NoData";
import CategoryLabel from "../../Translation/CategoryLabel";


const TableCell = withStyles({
    root: {
        borderBottom: "none"
    }
})(MuiTableCell);

export default function BrcgsFreeBasicData(props) {

    const categoryLogos = {
        people: peopleLogo,
        proactivity: proactivityLogo,
        purpose: purposeLogo,
        process: processLogo,
    };

    if (!props.reportData.data || 0 === Object.keys(props.reportData.data).length) {
        return null;
    }

    const upgradeTexts = {
        reinforcement: 'Reward, Teamwork, Training and Communication are not scored with the Basic tool.',
        coordination: 'Coordination, Consistency, Systems and Premises are not scored with the Basic tool.',
        values: 'Values, Strategy, Targets and Metrics are not scored with the Basic tool.',
        foresight: 'Foresight, Innovation, Learning and Investment are not scored with the Basic tool.'
    };

    if (null === props.reportData.data.total.score) {
        return <NoData />;
    }

    const dimensionTexts = (dimension) => {
        switch (dimension) {
            case 'reinforcement':
                return 'Reward';

            case 'objectives':
                return 'Targets';

            case 'change':
                return 'Innovation';

            default:
                return dimension.charAt(0).toUpperCase() + dimension.slice(1);
        }
    }

    return (
        <Fragment>
            {Object.keys(props.reportData.data.categories).map(categoryKey => (
                <Box pb={2} key={categoryKey}>
                    <Paper key={categoryKey} elevation={1} style={{borderLeft: '5px solid '+BRCGS_CATEGORY_COLORS[categoryKey].dark}}>
                        <Grid container direction="row" justifyContent="center" alignItems="center" style={{backgroundColor: BRCGS_CATEGORY_COLORS[categoryKey].light}}>
                            <Grid item sm={2} align="center">
                                <img style={{ maxWidth: '110px', maxHeight: "100%" }} alt={categoryKey} src={categoryLogos[categoryKey]} /><br />
                                <Typography variant="subtitle1" style={{fontWeight: 500}}>
                                    <CategoryLabel category={categoryKey} upperCase={true} />
                                </Typography>
                            </Grid>
                            <Grid item sm={2} align="center">
                                <Typography title={props.reportData.data.categories[categoryKey].score ? props.reportData.data.categories[categoryKey].score : ''} variant="h2" style={{fontWeight: 'normal'}}>{props.reportData.data.categories[categoryKey].roundedScore ? props.reportData.data.categories[categoryKey].roundedScore : '-'}</Typography>
                            </Grid>
                            <Grid item sm={8} align="left">
                                <Box pb={2} pt={2}>
                                    <TableContainer>
                                        <Table size="small">
                                            <TableBody>
                                                {Object.keys(props.reportData.data.categories[categoryKey].dimensions).map(dimensionKey => (
                                                    <TableRow key={dimensionKey}>
                                                        <TableCell style={{ width: "30%", textAlign: 'right', fontSize: '1rem' }}>
                                                            {dimensionTexts(dimensionKey)}
                                                        </TableCell>
                                                        <TableCell style={{ width: "10%", textAlign: 'right', fontWeight: 'bold', paddingRight: '24px', fontSize: '1rem' }}>
                                                            <span title={props.reportData.data.categories[categoryKey].dimensions[dimensionKey].score ? props.reportData.data.categories[categoryKey].dimensions[dimensionKey].score : ''}>
                                                                {null !== props.reportData.data.categories[categoryKey].dimensions[dimensionKey].roundedScore ? props.reportData.data.categories[categoryKey].dimensions[dimensionKey].roundedScore : '-'}
                                                            </span>
                                                        </TableCell>
                                                        {['empowerment', 'control', 'vision', 'awareness'].includes(dimensionKey) ?
                                                            <TableCell style={{ width: "60%" }}>
                                                                {props.reportData.data.categories[categoryKey].dimensions[dimensionKey].roundedScore ?
                                                                    <div style={{backgroundColor: BRCGS_CATEGORY_COLORS['people'].dark, height: '15px', width: Math.round(props.reportData.data.categories[categoryKey].dimensions[dimensionKey].roundedScore) + '%'}} />
                                                                : null}
                                                            </TableCell>
                                                        : null}
                                                        {['reinforcement', 'coordination', 'values', 'foresight'].includes(dimensionKey) ?
                                                            <TableCell style={{ width: '60%' }} rowSpan={4}>
                                                                <Paper variant="outlined">
                                                                    <Box p={1} textAlign='center'>
                                                                        <Typography variant="subtitle1" align="center">
                                                                            {upgradeTexts[dimensionKey]}
                                                                        </Typography>
                                                                        <Box p={1} textAlign="center">
                                                                            <Button variant="contained" color="primary" component={NavLink} to="/upgrade/brcgs-free" style={{ backgroundColor: BRCGS_CATEGORY_COLORS['people'].dark}}>
                                                                                Learn more about a full assessment
                                                                            </Button>
                                                                        </Box>
                                                                    </Box>
                                                                </Paper>
                                                            </TableCell>
                                                        : null}
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Box>
                            </Grid>
                        </Grid>
                    </Paper>
                </Box>
            ))}
        </Fragment>
    );

}