import { useContext, useEffect, useRef, useState } from "react";
import { Button, Grid, Table, TableBody, TableCell, TableHead, TableRow, TextField, Typography } from "@material-ui/core";
import { useParams } from "react-router-dom";
import DemographicOptionLabelService from "../../Services/DemographicOptionLabelService";
import SurveyService from "../../Services/SurveyService";
import { NotificationContext } from "../../Context/NotificationContext";
import { AppBarTitleContext } from "../../Context/AppBarTitleContext";
import { SpinnerContext } from "../../Context/SpinnerContext";

export default function OptionLabels() {
    let { hash } = useParams();
    const [refresh, setRefresh] = useState(0);
    const [labels, setLabels] = useState(null);
    const [labelObjects, setLabelObjects] = useState([]);
    const [survey, setSurvey] = useState(null);
    const [demographicQuestions, setDemographicQuestions] = useState(null);

    const { updateNotification } = useContext(NotificationContext);
    const updateNotificationRef = useRef();
    updateNotificationRef.current = (open, message, level) => {
        updateNotification(open, message, level);
    }

    const { updateTitle } = useContext(AppBarTitleContext);
    const updateTitleRef = useRef();
    updateTitleRef.current = (title) => {
        updateTitle(title);
    };

    const { updateLoading } = useContext(SpinnerContext);
    const updateLoadingRef = useRef();
    updateLoadingRef.current = (loading) => {
        updateLoading(loading);
    };

    useEffect(() => {
        updateTitleRef.current(null);
        updateLoadingRef.current(true);

        const surveyPromise = SurveyService.surveyByHash(hash);
        const labelsPromise = DemographicOptionLabelService.labelsForSurvey(hash);

        Promise.all([surveyPromise, labelsPromise]).then(function(response) {
            const [surveyResponse, labelsResponse] = response;
            const demQs = surveyResponse.data.included ? surveyResponse.data.included.filter(incl => incl.type === 'question') : [];
            setSurvey(surveyResponse.data.data);
            setDemographicQuestions(surveyResponse.data.included ? surveyResponse.data.included.filter(incl => incl.type === 'question') : []);
            setLabelObjects(labelsResponse.data.data);

            const labelsToSet = {};
            demQs.forEach(demQ => {
                labelsToSet[demQ.attributes.demographic] = {};
                if (demQ.attributes.options && demQ.attributes.options.length) {
                    demQ.attributes.options.forEach(opt => {
                        const findOverride = labelsResponse.data.data.find(l => (l.attributes.demographic === demQ.attributes.demographic && l.attributes.labels && opt.attributes.title in l.attributes.labels));
                        labelsToSet[demQ.attributes.demographic][opt.attributes.title] = findOverride ? findOverride.attributes.labels[opt.attributes.title] : '';
                    });
                }
            });

            setLabels(labelsToSet);
        }).catch((error) => {
            console.error(error);
            updateNotificationRef.current(true, 'An unknown error occurred!', 'error');
        }).then(() => {
            updateLoadingRef.current(false);
        });
    }, [hash, refresh]);

    const handleChange = (demographic, optTitle) => event => {
        const newOpts = labels[demographic];
        newOpts[optTitle] = event.target.value;
        setLabels({...labels, [demographic]: newOpts});
    };

    const handleSave = () => {
        const requests = [];
        Object.keys(labels).forEach(dem => {
            const labelObj = labelObjects.find(lo => lo.attributes.demographic === dem);
            if (Object.values(labels[dem]).some(optTitle => optTitle && optTitle !== '' && optTitle.trim())) {
                //Update or create
                if (labelObj) {
                    requests.push(DemographicOptionLabelService.patch(labelObj.id, {
                        labels: Object.keys(labels[dem]).filter((k) => labels[dem][k] !== '').reduce((a, k) => ({ ...a, [k]: labels[dem][k] }), {})
                    }))
                } else {
                    // Create
                    requests.push(DemographicOptionLabelService.create({
                        surveyCombinedHash: hash,
                        demographic: dem,
                        labels: Object.keys(labels[dem]).filter((k) => labels[dem][k] !== '').reduce((a, k) => ({ ...a, [k]: labels[dem][k] }), {})
                    }));
                }
            } else if (labelObj) {
                // Delete since all options are empty for this demographic
                requests.push(DemographicOptionLabelService.delete(labelObj.id));
            }
        });

        updateLoadingRef.current(true);
        if (requests.length) {
            Promise.all(requests).then(response => {
                setRefresh(prev => prev+1);
            }).catch((error) => {
                console.error(error);
                updateNotificationRef.current(true, 'An unknown error occurred!', 'error');
            }).then(() => {
                updateLoadingRef.current(false);
            });
        } else {
            setRefresh(prev => prev+1);
        }
    };

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Typography variant="h5">
                    {survey && survey.attributes ? (survey.relationships['survey-title'] ? survey.relationships['survey-title'].data.title : survey.attributes.title) + ' | Demographic Labels' : null}
                </Typography>
            </Grid>
            {demographicQuestions ?
                demographicQuestions.map(demQ => (
                    <Grid item xs={12} sm={6} key={demQ.id}>
                        <Typography variant="h6">{demQ.attributes.shortName}</Typography>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Reporting value</TableCell>
                                    <TableCell>Title</TableCell>
                                    <TableCell>Override title</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {demQ.attributes.options ? demQ.attributes.options.map(opt => (
                                    <TableRow key={opt.id}>
                                        <TableCell>{opt.attributes.value}</TableCell>
                                        <TableCell>{opt.attributes.title}</TableCell>
                                        <TableCell>
                                            <TextField
                                                size="small"
                                                value={labels && demQ.attributes.demographic in labels && labels[demQ.attributes.demographic][opt.attributes.title] ? labels[demQ.attributes.demographic][opt.attributes.title] : ''}
                                                fullWidth
                                                onChange={handleChange(demQ.attributes.demographic, opt.attributes.title)} />
                                        </TableCell>
                                    </TableRow>
                                )) : null}
                            </TableBody>
                        </Table>
                    </Grid>
                ))
            : null}
            <Grid item xs={12}>
                <Button onClick={handleSave} variant="contained" color="secondary">Save</Button>
            </Grid>
        </Grid>
    );
}
