export const BRCGS_CHART_COLORS = [
    {
        backgroundColor: '#ea7600',
        borderColor: '#ea760080',
        hoverBackgroundColor: '#ea760080',
        hoverBorderColor: '#ea760080',
    },
    {
        backgroundColor: '#3c3e40',
        borderColor: '#3c3e4080',
        hoverBackgroundColor: '#3c3e4080',
        hoverBorderColor: '#3c3e4080',
    },
];