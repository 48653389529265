export const languageTranslated = (language, intl) => {
    switch (language) {
        case 'Albanian':
            return intl.formatMessage({id: 'language.Albanian', defaultMessage: 'Albanian'});

        case 'Arabic':
            return intl.formatMessage({id: 'language.Arabic', defaultMessage: 'Arabic'});

        case 'Armenian':
            return intl.formatMessage({id: 'language.Armenian', defaultMessage: 'Armenian'});

        case 'Belarusian':
            return intl.formatMessage({id: 'language.Belarusian', defaultMessage: 'Belarusian'});

        case 'Bengali':
            return intl.formatMessage({id: 'language.Bengali', defaultMessage: 'Bengali'});

        case 'Bosnian':
            return intl.formatMessage({id: 'language.Bosnian', defaultMessage: 'Bosnian'});

        case 'Bulgarian':
            return intl.formatMessage({id: 'language.Bulgarian', defaultMessage: 'Bulgarian'});

        case 'Burmese':
            return intl.formatMessage({id: 'language.Burmese', defaultMessage: 'Burmese'});

        case 'Chinese (traditional)':
            return intl.formatMessage({id: 'language.ChineseTraditional', defaultMessage: 'Chinese (traditional)'});

        case 'Chinese (simplified)':
            return intl.formatMessage({id: 'language.ChineseSimplified', defaultMessage: 'Chinese (simplified)'});

        case 'Croatian':
            return intl.formatMessage({id: 'language.Croatian', defaultMessage: 'Croatian'});

        case 'Czech':
            return intl.formatMessage({id: 'language.Czech', defaultMessage: 'Czech'});

        case 'Danish':
            return intl.formatMessage({id: 'language.Danish', defaultMessage: 'Danish'});

        case 'Dutch':
            return intl.formatMessage({id: 'language.Dutch', defaultMessage: 'Dutch'});

        case 'English':
            return intl.formatMessage({id: 'language.English', defaultMessage: 'English'});

        case 'Estonian':
            return intl.formatMessage({id: 'language.Estonian', defaultMessage: 'Estonian'});

        case 'Finnish':
            return intl.formatMessage({id: 'language.Finnish', defaultMessage: 'Finnish'});

        case 'French':
            return intl.formatMessage({id: 'language.French', defaultMessage: 'French'});

        case 'German':
            return intl.formatMessage({id: 'language.German', defaultMessage: 'German'});

        case 'Greek':
            return intl.formatMessage({id: 'language.Greek', defaultMessage: 'Greek'});

        case 'Hindi':
            return intl.formatMessage({id: 'language.Hindi', defaultMessage: 'Hindi'});

        case 'Hungarian':
            return intl.formatMessage({id: 'language.Hungarian', defaultMessage: 'Hungarian'});

        case 'Icelandic':
            return intl.formatMessage({id: 'language.Icelandic', defaultMessage: 'Icelandic'});

        case 'Indonesian':
            return intl.formatMessage({id: 'language.Indonesian', defaultMessage: 'Indonesian'});

        case 'Italian':
            return intl.formatMessage({id: 'language.Italian', defaultMessage: 'Italian'});

        case 'Japanese':
            return intl.formatMessage({id: 'language.Japanese', defaultMessage: 'Japanese'});

        case 'Khmer':
            return intl.formatMessage({id: 'language.Khmer', defaultMessage: 'Khmer'});

        case 'Korean':
            return intl.formatMessage({id: 'language.Korean', defaultMessage: 'Korean'});

        case 'Latvian':
            return intl.formatMessage({id: 'language.Latvian', defaultMessage: 'Latvian'});

        case 'Lithuanian':
            return intl.formatMessage({id: 'language.Lithuanian', defaultMessage: 'Lithuanian'});

        case 'Macedonian':
            return intl.formatMessage({id: 'language.Macedonian', defaultMessage: 'Macedonian'});

        case 'Malay':
            return intl.formatMessage({id: 'language.Malay', defaultMessage: 'Malay'});

        case 'Malayalam':
            return intl.formatMessage({id: 'language.Malayalam', defaultMessage: 'Malayalam'});

        case 'Marathi':
            return intl.formatMessage({id: 'language.Marathi', defaultMessage: 'Marathi'});

        case 'Nepali':
            return intl.formatMessage({id: 'language.Nepali', defaultMessage: 'Nepali'});

        case 'Norwegian':
            return intl.formatMessage({id: 'language.Norwegian', defaultMessage: 'Norwegian'});

        case 'Polish':
            return intl.formatMessage({id: 'language.Polish', defaultMessage: 'Polish'});

        case 'Portuguese':
            return intl.formatMessage({id: 'language.Portuguese', defaultMessage: 'Portuguese'});

        case 'Portuguese (Brazil)':
            return intl.formatMessage({id: 'language.PortugueseBrazil', defaultMessage: 'Portuguese (Brazil)'});

        case 'Romanian':
            return intl.formatMessage({id: 'language.Romanian', defaultMessage: 'Romanian'});

        case 'Russian':
            return intl.formatMessage({id: 'language.Russian', defaultMessage: 'Russian'});

        case 'Serbian':
            return intl.formatMessage({id: 'language.Serbian', defaultMessage: 'Serbian'});

        case 'Sinhala':
            return intl.formatMessage({id: 'language.Sinhala', defaultMessage: 'Sinhala'});

        case 'Slovak':
            return intl.formatMessage({id: 'language.Slovak', defaultMessage: 'Slovak'});

        case 'Slovenian':
            return intl.formatMessage({id: 'language.Slovenian', defaultMessage: 'Slovenian'});

        case 'Spanish':
            return intl.formatMessage({id: 'language.Spanish', defaultMessage: 'Spanish'});

        case 'Spanish (LATAM)':
            return intl.formatMessage({id: 'language.SpanishLATAM', defaultMessage: 'Spanish (LATAM)'});

        case 'Swedish':
            return intl.formatMessage({id: 'language.Swedish', defaultMessage: 'Swedish'});

        case 'Tagalog (Philippines)':
            return intl.formatMessage({id: 'language.TagalogPhilippines', defaultMessage: 'Tagalog (Philippines)'});

        case 'Tamil':
            return intl.formatMessage({id: 'language.Tamil', defaultMessage: 'Tamil'});

        case 'Thai':
            return intl.formatMessage({id: 'language.Thai', defaultMessage: 'Thai'});

        case 'Turkish':
            return intl.formatMessage({id: 'language.Turkish', defaultMessage: 'Turkish'});

        case 'Ukrainian':
            return intl.formatMessage({id: 'language.Ukrainian', defaultMessage: 'Ukrainian'});

        case 'Urdu':
            return intl.formatMessage({id: 'language.Urdu', defaultMessage: 'Urdu'});

        case 'Vietnamese':
            return intl.formatMessage({id: 'language.Vietnamese', defaultMessage: 'Vietnamese'});

        default:
            // Unknown language
            return language;
    }
}
