import { Alert } from "@material-ui/lab";
import { Fragment } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { positiveTranslated } from "../../../Translation/Positive";

export default function Positives(props) {
    const intl = useIntl();

    return (
        <Fragment>
            { props.title && props.print ? <h2 style={{fontWeight: 400}}>{props.title}</h2> : null}

            {!props.reportData['positives'] || props.reportData['positives'].length === 0 ? (
                <Alert style={{justifyContent: 'center'}} severity="warning" variant="filled">
                    {intl.formatMessage({id: 'report.noDataError', defaultMessage: 'There is no data available for this report'})}
                </Alert>
            ) : (
                <Fragment>
                    <p>
                        <FormattedMessage
                            id="execSummaryPositives.p1"
                            defaultMessage="When asked for their opinion on cultural positives, most people chose:"
                        />
                    </p>

                    <ol>
                        {props.reportData['positives'].slice(0, 3).map((p, i) => (
                            <li key={i}>
                                {positiveTranslated(p.title, intl)}: {p.count} {intl.formatMessage({id: 'execSummaryPositives.people', defaultMessage: 'people'})}
                            </li>
                        ))}
                    </ol>


                    <p>
                        <FormattedMessage
                            id="execSummaryPositives.p2"
                            defaultMessage={
                                "The full range of cultural positives identified can be seen and filtered in the "+
                                "<strong>Positives</strong> view; these can be cross-referenced with the highest "+
                                "dimensions and elements to gain a deeper insight and understanding of what is "+
                                "working well."
                            }
                            values = {{
                                strong: chunks => <strong>{chunks}</strong>
                            }}
                        />
                    </p>
                </Fragment>
            )}
        </Fragment>
    );
}
