import { Box, Button, Grid, TextField, Typography, makeStyles } from "@material-ui/core";
import { useIntl } from 'react-intl'
import { Fragment, useContext, useEffect, useRef, useState } from "react";
import { NotificationContext } from "../../Context/NotificationContext";
import { AppBarTitleContext } from "../../Context/AppBarTitleContext";
import { SpinnerContext } from "../../Context/SpinnerContext";
import CompanyService from "../../Services/CompanyService";
import SurveyService from "../../Services/SurveyService";
import { useParams } from "react-router";

const useStyles = makeStyles((theme) => ({
    warning: {
        color: theme.palette.warning.main,
    },
}));

export default function ImportSurvey() {
    const classes = useStyles();
    const { updateNotification } = useContext(NotificationContext);
    const { updateTitle } = useContext(AppBarTitleContext);
    const { updateLoading } = useContext(SpinnerContext);
    const [company, setCompany] = useState(null);
    const [survey, setSurvey] = useState(null);
    const [surveyStates, setSurveyStates] = useState([]);
    const logEndRef = useRef(null);
    const statesRef = useRef(surveyStates);
    statesRef.current = surveyStates;
    const intl = useIntl();

    let { uuid } = useParams();

    const [values, setValues] = useState({
        providerId: '',
        surveyProvider: '',
        surveyType: 'premium',
        closeDate: '',
    });

    useEffect(() => {
        updateTitle(null);
        updateLoading(true);
        // Fetch company info
        CompanyService.company(uuid).then(function (response) {
            setCompany(response.data.data);
        }).catch(function (error) {
            updateNotification(true, intl.formatMessage({id: 'generic.unknownError', defaultMessage: 'An unknown error occurred!'}), 'error');
        }).then(function() {
            updateLoading(false);
        });
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        logEndRef.current?.scrollIntoView({ behavior: "smooth" });
    }, [surveyStates]);

    const handleChange = name => event => {
        setValues({ ...values, [name]: event.target.value });
    };

    const importStatus = (surveyUuid) => {
        updateLoading(true);
        SurveyService.survey(surveyUuid).then(function (response) {

            if (statesRef.current.length === 0) {
                setSurveyStates([response.data.data]);
            } else {
                // If updatedAt is different compared to the most recent state, add this new survey state
                const lastState = statesRef.current[statesRef.current.length-1];
                if (lastState.attributes.updatedAt !== response.data.data.attributes.updatedAt) {
                    setSurveyStates([...statesRef.current, response.data.data]);
                }
            }

            setSurvey(response.data.data);

            if (-1 === ['done', 'downloading-failed', 'reporting-failed'].indexOf(response.data.data.attributes.status)) {
                setTimeout(() => {
                    importStatus(surveyUuid);
                }, 10000);
            } else {
                // Clear values for a new import
                setSurvey(null);
                setValues({
                    providerId: '',
                    surveyProvider: '',
                    surveyType: 'premium',
                });
            }
        }).catch(function (error) {
            updateNotification(true, intl.formatMessage({id: 'generic.unknownError', defaultMessage: 'An unknown error occurred!'}), 'error');
        }).then(function() {
            updateLoading(false);
        });
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        updateLoading(true);
        setSurveyStates([]);

        CompanyService.importSurvey(uuid, values.surveyProvider, values.providerId, values.surveyType, values.closeDate).then(function (response) {
            updateNotification(true, 'Survey import queued', 'success');
            setSurvey(response.data.data);
            importStatus(response.data.data.id);
        }).catch(function (error) {
            if (409 === error.response.status) {
                updateNotification(true, error.response.data.error.detail, 'error');
            } else {
                updateNotification(true, intl.formatMessage({id: 'generic.unknownError', defaultMessage: 'An unknown error occurred!'}), 'error');
            }
            updateLoading(false);
        });
    };

    return(
        <Grid container spacing={0} justifyContent="center">
            <Grid item sm={10} md={8} lg={6}>
                <Box pb={1}>
                    <Typography variant="h6" paragraph>
                        Import Survey {company ? 'for Company: '+company.attributes.name : null}
                    </Typography>

                    <form onSubmit={handleSubmit}>
                        <Box pb={1}>
                            <TextField
                                fullWidth
                                required
                                type="number"
                                label="Provider ID"
                                value={values.providerId}
                                onChange={handleChange('providerId')}
                                margin="normal"
                                disabled={survey !== null}
                            />
                            <TextField
                                fullWidth
                                select
                                required
                                label="Survey provider"
                                value={values.surveyProvider}
                                onChange={handleChange('surveyProvider')}
                                SelectProps={{
                                    native: true,
                                }}
                                margin="normal"
                                disabled={survey !== null}
                            >
                                <option value="" disabled></option>
                                <option value="alchemer_new">Alchemer EU</option>
                                <option value="alchemer_old">Alchemer US (offline import)</option>
                            </TextField>
                            <TextField
                                fullWidth
                                select
                                required
                                label="Survey type"
                                value={values.surveyType}
                                onChange={handleChange('surveyType')}
                                SelectProps={{
                                    native: true,
                                }}
                                margin="normal"
                                disabled={survey !== null}
                                helperText={values.surveyType === 'brcgs' ? <span className={classes.warning}><strong>WARNING: </strong> Do not import BRCGS surveys that are still in progress</span> : null}
                            >
                                <option value="" disabled></option>
                                <option value="premium">Premium</option>
                                <option value="brcgs">BRCGS</option>
                            </TextField>
                            {values.surveyType === 'brcgs' ?
                                <TextField
                                    fullWidth
                                    label="BRCGS survey close date"
                                    type="date"
                                    disabled={survey !== null}
                                    value={values.closeDate}
                                    onChange={handleChange('closeDate')}
                                    onFocus={(event) => event.target.showPicker()}
                                    onClick={(event) => event.target.showPicker()}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            : null}
                        </Box>
                        <Button
                            variant="contained"
                            className="submit"
                            color="secondary"
                            type="submit"
                            disabled={survey !== null || values.providerId.trim() === '' || values.surveyProvider === '' || values.surveyType === ''}
                        >
                            Queue Import
                        </Button>
                    </form>
                </Box>
                <hr />
                <Box pb={2}>
                    {surveyStates.length ?
                        <Fragment>
                            <Typography variant="h6" paragraph>
                                Survey:
                            </Typography>

                            {surveyStates.slice(-1).map(s => (
                                <dl key={s.id}>
                                    <dt><strong>Title</strong></dt>
                                    <dd>{s.attributes.title}</dd>

                                    <dt><strong>Provider</strong></dt>
                                    <dd>{s.attributes.providerId} - ({s.attributes.provider === 'alchemer_old' ? 'Alchemer US' : 'Alchemer EU'})</dd>

                                    <dt><strong>Revision</strong></dt>
                                    <dd>{s.attributes.importRevision}</dd>

                                    <dt><strong>Type</strong></dt>
                                    <dd>{s.attributes.type}</dd>

                                    <dt><strong>Question Types</strong></dt>
                                    <dd>{s.attributes.questionTypes.length ? s.attributes.questionTypes.join(', ') : '-'}</dd>

                                    <dt><strong>Comment Types</strong></dt>
                                    <dd>{s.attributes.commentTypes.length ? s.attributes.commentTypes.join(', ') : '-'}</dd>

                                    <dt><strong>Choice Types</strong></dt>
                                    <dd>{s.attributes.choiceTypes.length ? s.attributes.choiceTypes.join(', ') : '-'} </dd>

                                    <dt><strong>Rank Types</strong></dt>
                                    <dd>{s.attributes.rankTypes.length ? s.attributes.rankTypes.join(', ') : '-'}</dd>

                                    <dt><strong>Risk Types</strong></dt>
                                    <dd>{s.attributes.riskTypes.length ? s.attributes.riskTypes.join(', ') : '-'}</dd>
                                </dl>
                            ))}
                        </Fragment>
                    : null}

                    {survey ?
                        <Typography variant="h6" paragraph>
                            Import Log:
                        </Typography>
                    : null}
                    <pre style={{backgroundColor:'#ccc', maxHeight: 400, overflowY: 'scroll'}}>
                        <code>
                            {surveyStates.map((s, i) => {
                                return <Fragment key={i}>
                                    <strong>[{s.attributes.updatedAt ? s.attributes.updatedAt : s.attributes.createdAt }]:</strong> status: <strong>{s.attributes.status}</strong>, scored-respondents: <strong>{s.attributes.scoredRespondentCount ? s.attributes.scoredRespondentCount : '-'}</strong>, last-respondent-at: <strong>{s.attributes.lastRespondentAt ? s.attributes.lastRespondentAt : '-'}<br /></strong>
                                </Fragment>
                            })}
                            <span ref={logEndRef}></span>
                        </code>
                    </pre>
                </Box>
            </Grid>
        </Grid>
    );
}
