export const qualityTranslated = (quality, intl) => {
    if (!quality) {
        return null;
    }

    const labels = {
        'safe product': intl.formatMessage({id: 'perceptionOfQuality.safe', defaultMessage: 'Safe product'}),
        'superior product': intl.formatMessage({id: 'perceptionOfQuality.superior', defaultMessage: 'Superior product'}),
        'product with no defects': intl.formatMessage({id: 'perceptionOfQuality.defects', defaultMessage: 'Product with no defects'}),
        'customer satisfaction': intl.formatMessage({id: 'perceptionOfQuality.customer', defaultMessage: 'Customer satisfaction'}),
        'nutritional product': intl.formatMessage({id: 'perceptionOfQuality.nutritional', defaultMessage: 'Nutritional product'}),
        'ethically made product': intl.formatMessage({id: 'perceptionOfQuality.ethical', defaultMessage: 'Ethically made product'}),
        'quality management': intl.formatMessage({id: 'perceptionOfQuality.qms', defaultMessage: 'Quality management'}),
        'product consistency': intl.formatMessage({id: 'perceptionOfQuality.consistency', defaultMessage: 'Product consistency'}),
    };

    if (quality.toLowerCase() in labels) {
        return labels[quality.toLowerCase()];
    }
console.log('nomatch')
    return quality;
}