import { Fragment } from "react";
import ElementsTable from "./ElementsTable";
import { FormattedMessage } from "react-intl";

export default function MetricsDimension(props) {
    return (
        <Fragment>
            <p>
                <FormattedMessage
                    id="execSummaryDiscAndRecs.metrics"
                    defaultMessage={
                        "Metrics are an important feature of quality and safety systems and help to embed quality and safety into "+
                        "organisational culture. Schein (2010) highlights 'what leaders pay attention to, measure, and control on "+
                        "a regular basis' as a primary embedding mechanism of culture, and the measurement aspect of this is key. "+
                        "There are important leading and lagging indicators of safety and quality that should be gathered and "+
                        "utilised to maintain control, prevent problems and drive continual improvement; by also sharing these "+
                        "with colleagues and highlighting them in discussions, as well as setting targets based on them and "+
                        "recognising when things are going well, the importance of safety and quality is promoted. Furthermore, "+
                        "the culture data itself can become an important metric after taking the survey, and can be viewed as a "+
                        "'leading indicator' of safety and quality."
                    }
                />
            </p>
            <ElementsTable dimension={props.dimension} elements={props.elements} lowestElements={props.lowestElements} />
        </Fragment>
    );
}
