import { Fragment } from "react";
import ElementsTable from "./ElementsTable";
import { FormattedMessage } from "react-intl";

export default function CoordinationDimension(props) {
    return (
        <Fragment>
            <p>
                <FormattedMessage
                    id="execSummaryDiscAndRecs.coordination"
                    defaultMessage={
                        "Different departments within a food business can have differing priorities and pressures, leading to "+
                        "potential conflict. It is also common to see a lack of information sharing and collaboration when people "+
                        "are busy within their own teams. However, this is a potential area of safety and quality breakdown as "+
                        "well as reducing openness and responsibility, and organizational learning. Further discussion and "+
                        "qualitative data analysis can help to identify the specific location and nature of the problem and "+
                        "identify solutions going forward."
                    }
                />
            </p>
            <ElementsTable dimension={props.dimension} elements={props.elements} lowestElements={props.lowestElements} />
        </Fragment>
    );
}
