export const BRCGS_CATEGORY_COLORS = {
    people: {
        dark: '#ea7600',
        light: '#feeade',
    },
    process: {
        dark: '#c4c3c3',
        light: '#f1f1f1'
    },
    purpose: {
        dark: '#ea7600',
        light: '#feeade',
    },
    proactivity: {
        dark: '#c4c3c3',
        light: '#f1f1f1'
    }
};