export const SSAFE_CATEGORY_COLORS = {
    people: {
        dark: '#0f5192',
        light: '#e7eef4',
    },
    process: {
        dark: '#c4c3c3',
        light: '#f1f1f1'
    },
    purpose: {
        dark: '#0f5192',
        light: '#e7eef4',
    },
    proactivity: {
        dark: '#c4c3c3',
        light: '#f1f1f1'
    }
};