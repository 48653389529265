import { Box, Button, Grid, TextField, Typography } from "@material-ui/core";
import { Fragment, useContext, useState } from "react";
import { NotificationContext } from "../../../Context/NotificationContext";
import { SpinnerContext } from "../../../Context/SpinnerContext";
import UserService from "./../../../Services/UserService";
import { FormattedMessage, useIntl } from "react-intl";


export default function ForgotPassword() {

    const { updateLoading } = useContext(SpinnerContext);
    const { updateNotification } = useContext(NotificationContext);
    const [message, setMessage] = useState(null);
    const intl = useIntl();

    const [email, setEmail] = useState('');

    const handleChange = (event) => {
        setEmail(event.target.value);
    };

    const handleSubmit = event => {
        event.preventDefault();
        updateLoading(true);

        UserService.forgotPassword(email)
        .then(function (response) {
            updateNotification(true, intl.formatMessage({id: 'forgotPassword.success', defaultMessage: 'Password reset request was sent'}), 'success');
            setMessage(<Fragment>
                <Typography variant="h5">
                    <FormattedMessage id="forgotPassword.successHeader" defaultMessage="Password Reset Request Sent"/>
                </Typography>
                <Typography variant="body1" paragraph>
                    <FormattedMessage id="forgotPassword.successNotice" defaultMessage="An email with instructions on how to reset your password has been sent." />
                </Typography>
            </Fragment>);
        }).catch(function (error) {
            if (error.response && error.response.status && 404 === error.response.status) {
                updateNotification(true, intl.formatMessage({id: 'forgotPassword.notFoundError', defaultMessage: 'No user exists with the given email address'}), 'warning');
            } else {
                updateNotification(true, intl.formatMessage({id: 'generic.unknownError', defaultMessage: 'An unknown error occurred!'}), 'error');
            }
            console.error(error);
        }).then(function() {
            updateLoading(false);
        });
    }

    return(
        <Grid container spacing={0} justifyContent="center">
            <Grid item sm={8} md={4} lg={3}>
                {message ? message :
                    <Fragment>
                        <Typography variant="h5" paragraph>
                            <FormattedMessage id="forgotPassword.header" defaultMessage="Forgot Password" />
                        </Typography>
                        <Typography variant="body1" paragraph>
                            <FormattedMessage id="forgotPassword.notice" defaultMessage="Instructions on how to reset your password will be sent to your email." />
                        </Typography>
                        <form onSubmit={handleSubmit}>
                            <Box pb={1}>
                                <TextField
                                    id="email"
                                    fullWidth
                                    required
                                    label={intl.formatMessage({id: 'forgotPassword.email', defaultMessage: 'Account email'})}
                                    type="email"
                                    value={email}
                                    onChange={handleChange}
                                    margin="normal"
                                />
                            </Box>
                            <Button
                                variant="contained"
                                className="submit"
                                color="secondary"
                                type="submit"
                                disabled={email === ''}
                            >
                            <FormattedMessage id="forgotPassword.resetPassword" defaultMessage="Reset Password" />
                            </Button>
                        </form>
                    </Fragment>
                }
            </Grid>
        </Grid>
    );
}