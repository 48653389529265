import axios from 'axios';
import BaseService from './BaseService';

class SurveyTitleService extends BaseService {
    create(data) {
        return axios.post(this.apiUrl('/survey-titles'), {
            data: {
                type: 'survey-title',
                attributes: data,
            },
        },{
            headers: this.getAuthHeader()
        });
    }

    patch(uuid, data) {
        return axios.patch(this.apiUrl('/survey-titles/'+uuid), {
            data: {
                type: 'survey-title',
                attributes: data
            }
        },{
            headers: this.getAuthHeader()
        });
    }

    delete(uuid) {
        return axios.delete(this.apiUrl('/survey-titles/'+uuid), {
            headers: this.getAuthHeader()
        });
    }
}

export default new SurveyTitleService();
