export const barrierTranslated = (barrier, intl) => {
    if (!barrier) {
        return null;
    }

    const labels = {
        'incentives / benefits': intl.formatMessage({id: 'barriers.incentives', defaultMessage: 'Incentives / benefits'}),
        'staff turnover': intl.formatMessage({id: 'barriers.staffTurnover', defaultMessage: 'Staff turnover'}),
        'budget': intl.formatMessage({id: 'barriers.budget', defaultMessage: 'Budget'}),
        'time': intl.formatMessage({id: 'barriers.time', defaultMessage: 'Time'}),
        'employee commitment': intl.formatMessage({id: 'barriers.employeeCommitment', defaultMessage: 'Employee commitment'}),
        'training / communication': intl.formatMessage({id: 'barriers.trainingCommunication', defaultMessage: 'Training / communication'}),
        'accountability / responsibility': intl.formatMessage({id: 'barriers.accountabilityResponsability', defaultMessage: 'Accountability / responsibility'}),
        'multi-cultural workforce (language barriers)': intl.formatMessage({id: 'barriers.multiCulturalLang', defaultMessage: 'Multi-cultural workforce (language barriers)'}),
        'management commitment': intl.formatMessage({id: 'barriers.mgmntCommitment', defaultMessage: 'Management commitment'}),
        'co-ordination across departments / sites': intl.formatMessage({id: 'barriers.coordinationAcross', defaultMessage: 'Co-ordination across departments / sites'}),
        'co-ordination across departments': intl.formatMessage({id: 'barriers.coordinationAcrossDepartments', defaultMessage: 'Co-ordination across departments'}),
        'physical space / premises': intl.formatMessage({id: 'barriers.physical', defaultMessage: 'Physical space / premises'}),
        'awareness of culture': intl.formatMessage({id: 'barriers.awarenessOfCulture', defaultMessage: 'Awareness of culture'}),
        'technology': intl.formatMessage({id: 'barriers.tech', defaultMessage: 'Technology'}),
        'multi-cultural workforce (different cultures)': intl.formatMessage({id: 'barriers.multiCultural', defaultMessage: 'Multi-cultural workforce (different cultures)'}),
    };

    if (barrier.toLowerCase() in labels) {
        return labels[barrier.toLowerCase()];
    }

    return barrier;
}