import axios from 'axios';
import BaseService from './BaseService';

class ElementMappingService extends BaseService {

    elementMappings(offset = 0, limit = 50) {
        const params = {
            'page[offset]': offset,
            'page[limit]': limit,
        };

        return axios.get(this.apiUrl('/element-mappings'), {
            params: params,
            headers: this.getAuthHeader()
        });
    }

    uniqueElements() {
        return axios.get(this.apiUrl('/elements'), {
            params: {},
            headers: this.getAuthHeader()
        });
    }

    async allElementMappings(offset = null) {
        const response = await this.elementMappings(offset, 50);
        const data = response.data.data;
        if (response.data.links.next) {
            return data.concat(await this.allElementMappings(offset+50));
        } else {
            return data;
        }
    }

    delete(uuid) {
        return axios.delete(this.apiUrl('/element-mappings/'+uuid), {
            headers: this.getAuthHeader()
        });
    }

    create(attributes) {
        return axios.post(this.apiUrl('/element-mappings'), {
            data: {
                type: 'element-mapping',
                attributes: attributes,
            }
        },{
            headers: this.getAuthHeader()
        });
    }

    patch(uuid, attributes) {
        return axios.patch(this.apiUrl('/element-mappings/'+uuid), {
            data: {
                type: 'element-mapping',
                attributes: attributes
            }
        },{
            headers: this.getAuthHeader()
        });
    }
}

export default new ElementMappingService();
