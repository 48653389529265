import axios from 'axios';
import BaseService from './BaseService';

class CustomReportService extends BaseService {
    fetchReport(type) {
        return axios.get(this.apiUrl('/custom-reports/'+type), {
            headers: this.getAuthHeader(),
            responseType: 'blob',
        });
    }
}

export default new CustomReportService();