const allIndustries = {
    'Agents and brokers': {
        brcgs: true,
        sector: 'Office / corporate / ancilliary'
    },
    'Alcoholic drinks and fermented/brewed products': {
        brcgs: true,
        sector: 'Food manufacturing'
    },
    'Ambient foods (e.g. sauces)': {
        brcgs: true,
        sector: 'Food manufacturing'
    },
    'Bakery': {
        brcgs: true,
        sector: 'Food manufacturing'
    },
    'Beverages': {
        brcgs: true,
        sector: 'Food manufacturing'
    },
    'Catering and food service': {
        brcgs: true,
        sector: 'Retail and food service'
    },
    'Cereals and snacks': {
        brcgs: true,
        sector: 'Food manufacturing'
    },
    'Confectionery': {
        brcgs: true,
        sector: 'Food manufacturing'
    },
    'Cooked meat/fish products': {
        brcgs: true,
        sector: 'Food manufacturing'
    },
    'Dairy, liquid egg': {
        brcgs: true,
        sector: 'Food manufacturing'
    },
    'Dried foods and ingredients': {
        brcgs: true,
        sector: 'Food manufacturing'
    },
    'Equipment': {
        brcgs: false,
        sector: 'Equipment / packaging'
    },
    'Farming of animals, fish, fruits and vegetables': {
        brcgs: true,
        sector: 'Primary production and processing'
    },
    'Feed production': {
        brcgs: true,
        sector: 'Food manufacturing'
    },
    'Fruit juices and smoothies (pasteurised)': {
        brcgs: true,
        sector: 'Food manufacturing'
    },
    'Fruit, vegetables and nuts': {
        brcgs: true,
        sector: 'Food manufacturing'
    },
    'Government / ministry': {
        brcgs: true,
        sector: 'Office / corporate / ancilliary'
    },
    'Head office / corporate / ancillary': {
        brcgs: true,
        sector: 'Office / corporate / ancilliary'
    },
    'Home care': {
        brcgs: false,
        sector: 'Consumer products'
    },
    'Logistics': {
        brcgs: true,
        sector: 'Packing, storage and distribution'
    },
    'Low/high acid in cans/glass/plastic containers': {
        brcgs: true,
        sector: 'Food manufacturing'
    },
    'Non-governmental organisation (NGO)': {
        brcgs: false,
        sector: 'Office / corporate / ancilliary'
    },
    'Oils and fats': {
        brcgs: true,
        sector: 'Food manufacturing'
    },
    'Other': {
        brcgs: false,
        sector: 'Other'
    },
    'Packaging': {
        brcgs: true,
        sector: 'Equipment / packaging'
    },
    'Packing': {
        brcgs: true,
        sector: 'Packing, storage and distribution'
    },
    'Personal care': {
        brcgs: false,
        sector: 'Consumer products'
    },
    'Prepared fruit, vegetables and nuts': {
        brcgs: true,
        sector: 'Food manufacturing'
    },
    'Raw cured and/or fermented meat and fish': {
        brcgs: true,
        sector: 'Food manufacturing'
    },
    'Raw fish products and preparations': {
        brcgs: true,
        sector: 'Food manufacturing'
    },
    'Raw poultry': {
        brcgs: true,
        sector: 'Food manufacturing'
    },
    'Raw prepared products (meat and vegetarian)': {
        brcgs: true,
        sector: 'Food manufacturing'
    },
    'Raw red meat': {
        brcgs: true,
        sector: 'Food manufacturing'
    },
    'Ready meals and sandwiches; ready-to-eat desserts': {
        brcgs: true,
        sector: 'Food manufacturing'
    },
    'Retail': {
        brcgs: true,
        sector: 'Retail and food service'
    },
    'Slaughtering and primal cutting': {
        brcgs: true,
        sector: 'Primary production and processing'
    },
    'Storage and distribution': {
        brcgs: true,
        sector: 'Packing, storage and distribution'
    },
    'Trade association': {
        brcgs: false,
        sector: 'Office / corporate / ancilliary'
    },
};

export function brcgsIndustries() {
    return Object.keys(allIndustries).filter(ind => allIndustries[ind].brcgs);
};

export function industries() {
    return Object.keys(allIndustries);
}

export function sectors() {
    const sectors = {};
    Object.keys(allIndustries).forEach(ind => {
        if (!(allIndustries[ind].sector in sectors)) {
            sectors[allIndustries[ind].sector] = [];
        }
        sectors[allIndustries[ind].sector].push(ind);
    });
    return sectors;
};