import { Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import { Fragment } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import CategoryLabel from "../../../Translation/CategoryLabel";
import DimensionLabel from "../../../Translation/DimensionLabel";
import ElementLabel from "../../../Translation/ElementLabel";
import { CATEGORY_COLORS } from '../../../../Constants/CategoryColors';
import { countDimensions, listDimensionCounts } from "./Common";
import DimensionDescription from "../../../Translation/DimensionDescription";

export default function CulturalStrengths(props) {
    const intl = useIntl();
    const textWrapColumnStyle = {
        whiteSpace: 'normal',
    };

    const smallColStyle = {
        color: 'white',
        textAlign: 'center',
        whiteSpace: 'normal',
        paddingTop: '1px',
        paddingBottom: '1px',
        border: '1px solid #e0e0e0',
        height: '40px'
    };

    const dimensions = [];
    for (const key in props.reportData['basic-data'].dimensions) {
        dimensions.push({
            label: <DimensionLabel dimension={key} />,
            name: key,
            ...props.reportData['basic-data'].dimensions[key],
        });
    }

    // Sort dimensions from highest to lowest score
    dimensions.sort((a, b) => b.score - a.score);

    const topElements = JSON.parse(JSON.stringify(props.reportData.elements ? props.reportData.elements : []));
    topElements.sort((a, b) => parseFloat(a.score) < parseFloat(b.score) ? 1 : -1);


    const tableStyle = {padding: 6, fontSize: '9pt', lineHeight: 1.3};
    return (
        <Fragment>
            { props.title && props.print ? <h2 style={{fontWeight: 400}}>{props.title}</h2> : null}
            <p>
                <FormattedMessage id="execSummaryCulturalStr.p1"
                    defaultMessage={
                        "The main cultural strengths can be seen in the highest Dimensions and Elements. They can be filtered in "+
                        "any combination, and the <strong>Hotspots</strong> view provides a calculation of the highest overall "+
                        "combinations. Supporting data can also be seen in views that do not contribute to the overall score but "+
                        "represent additional views of the employees - for example the <strong>Positives</strong> view."
                    }
                    values= {{
                        strong: chunks => <strong>{chunks}</strong>
                    }}
                />
            </p>

            <p><FormattedMessage id="execSummaryCulturalStr.p2" defaultMessage={"Overall, the highest dimensions emerged as:"} /></p>

            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell style={tableStyle}>{intl.formatMessage({id: 'generic.dimension', defaultMessage:"Dimension"})}</TableCell>
                        <TableCell style={tableStyle}>{intl.formatMessage({id: 'generic.score', defaultMessage:"Score"})}</TableCell>
                        <TableCell style={tableStyle}>{intl.formatMessage({id: 'generic.description', defaultMessage:"Description"})}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {dimensions.slice(0, 3).map((dimension, i) => (
                        <TableRow key={i}>
                            <TableCell style={tableStyle}>{dimension.label}</TableCell>
                            <TableCell style={tableStyle}>{parseFloat(dimension.score).toFixed(1)}</TableCell>
                            <TableCell style={tableStyle}>
                                <DimensionDescription dimension={dimension.name} />
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>

            <p><FormattedMessage id="execSummaryCulturalStr.p3" defaultMessage={"The highest scoring questions (elements) across all participants are shown below:"} /></p>


            <Table>
                <TableHead style={{backgroundColor: '#3c3e40'}}>
                    <TableRow>
                        <TableCell style={{...smallColStyle, ...{width: '100pt'}, ...tableStyle}}>
                            {intl.formatMessage({id: 'generic.category', defaultMessage: 'Category'})}
                        </TableCell>
                        <TableCell style={{...smallColStyle, ...tableStyle}}>{intl.formatMessage({id: 'generic.element', defaultMessage: 'Element'})}</TableCell>
                        <TableCell style={{...smallColStyle, ...{width: '100pt'}, ...tableStyle}}>
                            {intl.formatMessage({id: 'generic.scorePercent', defaultMessage: 'Score (%)'})}
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {topElements.slice(0, 6).map((element, index) => (
                        <TableRow key={index} style={{backgroundColor: CATEGORY_COLORS[element.category].light, borderBottom: '10px solid white'}}>
                            <TableCell style={{...{width: '100pt', borderLeft: '5px solid '+CATEGORY_COLORS[element.category].dark}, ...tableStyle}}>
                                <CategoryLabel category={element.category} />
                            </TableCell>
                            <TableCell style={{...textWrapColumnStyle, ...tableStyle}}>
                                <ElementLabel element={element.shortName} />
                            </TableCell>
                            <TableCell style={{ ...{ width: '100pt', textAlign: 'center' }, ...tableStyle}}>
                                {parseFloat(element.score).toFixed(1)}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>

            <p>
                <FormattedMessage id="execSummaryCulturalStr.p4"
                    defaultMessage={
                        "These elements come from the following dimensions: {listDimensions}. The specific  meaning of the numbers "+
                        "can be seen in the appendix of this report, and the <strong>Response Distribution</strong> view shows the "+
                        "full range of responses."
                    }
                    values= {{
                        strong: chunks => <strong>{chunks}</strong>,
                        listDimensions: listDimensionCounts(countDimensions(topElements.slice(0, 6)))
                    }}
                />
            </p>

            <p>
                <FormattedMessage id="execSummaryCulturalStr.p5"
                    defaultMessage={
                        "Note: (S/M) indicates a question was only provided to people with supervisory and managerial roles, (M) "+
                        "indicates it was only provided to people with managerial roles."
                    }
                />
            </p>
        </Fragment>
    );
}
