import React, { Fragment, useContext, useEffect, useState } from 'react';
import { Link as RouterLink, useLocation } from "react-router-dom";
import Link from '@material-ui/core/Link';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import PeopleIcon from '@material-ui/icons/People';
import BusinessIcon from '@material-ui/icons/Business';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import LightBulbIcon from '@material-ui/icons/EmojiObjects';
import AssignmentIcon from '@material-ui/icons/Assignment';
import NewReleasesIcon from '@material-ui/icons/NewReleases';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { UserContext } from '../../Context/UserContext';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { useIntl } from "react-intl";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    tab: {
        minWidth: 120,
        width: 120,
    },
    root: {
        display: 'flex',
    },
    drawer: {
        [theme.breakpoints.up('sm')]: {
            width: drawerWidth,
            flexShrink: 0,
        },
    },
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
        width: drawerWidth,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    }
}));

function NavDrawer(props) {
    const { w } = props;
    const classes = useStyles();
    const theme = useTheme();
    const location = useLocation();
    const intl = useIntl();

    const { user } = useContext(UserContext);

    const [activeTab, setActiveTab] = useState(false);

    // Set the active tab depending on location.pathname
    useEffect(() => {
        let currentTab = false;
        if (location.pathname.startsWith('/surveys') || location.pathname.startsWith('/survey-report')) {
            currentTab = 0;
        } else if (location.pathname.startsWith('/upgrade/brcgs-free')) {
            currentTab = 1;
        } else if (location.pathname.startsWith('/upgrade/ssafe')) {
            currentTab = 2;
        } else if (location.pathname.startsWith('/resources')) {
            currentTab = 3;
        } else if (location.pathname.startsWith('/users')) {
            currentTab = 4;
        } else if (location.pathname.startsWith('/companies')) {
            currentTab = 5;
        } else if (location.pathname.startsWith('/sites')) {
            currentTab = 6;
        }
        setActiveTab(currentTab);
    }, [location.pathname]);

    // Don't show if user is not logged in
    if (!user) {
        return null;
    }

    const menuItems = [
        {
            label: intl.formatMessage({id:'navbar.surveys', defaultMessage: 'SURVEYS'}),
            mobileLabel: intl.formatMessage({id:'navbar.mobileSurveys', defaultMessage: 'Surveys'}),
            icon: <AssignmentIcon />,
            to: "/surveys",
            visible: true
        },
        {
            label: intl.formatMessage({id:'navbar.upgrade', defaultMessage: 'UPGRADE'}),
            mobileLabel: intl.formatMessage({id:'navbar.mobileUpgrade', defaultMessage: 'Upgrade'}),
            icon: <NewReleasesIcon />,
            to: "/upgrade/brcgs-free",
            visible: user && 'brcgs-free' === user.role
        },
        {
            label: intl.formatMessage({id:'navbar.nextSteps', defaultMessage: 'NEXT STEPS'}),
            mobileLabel: intl.formatMessage({id:'navbar.mobileNextSteps', defaultMessage: 'Next Steps'}),
            icon: <NewReleasesIcon />,
            to: "/upgrade/ssafe",
            visible: user && 'ssafe' === user.role
        },
        {
            label: intl.formatMessage({id:'navbar.resources', defaultMessage: 'RESOURCES'}),
            mobileLabel: intl.formatMessage({id:'navbar.mobileResources', defaultMessage: 'Resources'}),
            icon: <LightBulbIcon />,
            to: "/resources",
            visible: user && ('brcgs-free' === user.role || 'brcgs-admin' === user.role)
        },
        {
            label: intl.formatMessage({id:'navbar.users', defaultMessage: 'USERS'}),
            mobileLabel: intl.formatMessage({id:'navbar.mobileUsers', defaultMessage: 'Users'}),
            icon: <PeopleIcon />,
            to: "/users",
            visible: user && (-1 !== ['admin', 'premium-admin'].indexOf(user.role))
        },
        {
            label: intl.formatMessage({id:'navbar.companies', defaultMessage: 'COMPANIES'}),
            mobileLabel: intl.formatMessage({id:'navbar.mobileCompanies', defaultMessage: 'Companies'}),
            icon: <BusinessIcon />,
            to: "/companies",
            visible: user && (-1 !== ['admin', 'partner'].indexOf(user.role))
        },
        {
            label: intl.formatMessage({id:'navbar.sites', defaultMessage: 'SITES'}),
            mobileLabel: intl.formatMessage({id:'navbar.mobileSites', defaultMessage: 'Sites'}),
            icon: <LocationOnIcon />,
            to: "/sites",
            visible: user && (-1 !== ['admin', 'premium-admin'].indexOf(user.role))
        },
    ];

    const drawer = (
        <div>
            <div className={classes.toolbar} />
            <List>
                {menuItems.map(menuItem => (
                    menuItem.visible ?
                    <Link key={menuItem.to} component={RouterLink} to={menuItem.to} onClick={props.handleDrawerToggle}>
                        <ListItem button key={menuItem.to}>
                            <ListItemIcon>{menuItem.icon}</ListItemIcon>
                            <ListItemText primary={menuItem.mobileLabel} />
                        </ListItem>
                    </Link>
                    : null
                ))}
            </List>
        </div>
    );

    const container = w !== undefined ? () => w().document.body : undefined;

    return (
        <Fragment>
            {/* Desktop menu */}
            <Hidden xsDown implementation="css">
                {/* TODO: Figure out padding left for menu so it matches the content area for survey reports */}
                <Tabs style={{paddingLeft: drawerWidth-184+'px'}} value={activeTab}>
                    {menuItems.map(menuItem => (
                        <Tab key={menuItem.to} label={menuItem.label} className={classes.tab} component={RouterLink} to={menuItem.to} style={menuItem.visible ? {} : {display: 'none'}} />
                    ))}
                </Tabs>
            </Hidden>
            {/* Mobile menu */}
            <Hidden smUp implementation="css">
                <nav className={classes.drawer}>
                    <Drawer
                        // BackdropProps={{ invisible: true }}
                        container={container}
                        variant="temporary"
                        anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                        open={props.mobileOpen}
                        onClose={props.handleDrawerToggle}
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                        ModalProps={{
                            keepMounted: true, // Better open performance on mobile?
                        }}
                    >
                        {drawer}
                    </Drawer>
                </nav>
            </Hidden>
        </Fragment>
    );
}

export default NavDrawer;
