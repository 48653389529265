import { Box, Checkbox, FormControlLabel, FormGroup, Grid, makeStyles, Tab, Tabs } from "@material-ui/core";
import { Fragment, useRef, useState } from "react";
import { useIntl } from 'react-intl'
import { CATEGORIES_AND_DIMENSIONS } from "../../../Constants/Categories";
import { CATEGORY_COLORS } from "../../../Constants/CategoryColors";
import { BRCGS_CATEGORY_COLORS } from "../../../Constants/BrcgsCategoryColors";
import LineLazy from "../../Chart/LineLazy";
import { dimensionTranslated } from "../../Translation/Dimension";

const useStyles = makeStyles(theme => ({
    tabs: {
        backgroundColor: theme.palette.primary.main,
        color: 'white'
    },
    label: {
        marginLeft: 0
    },
}));

export default function Progression(props) {
    const [activeTab, setActiveTab] = useState(0);
    const classes = useStyles();
    const intl = useIntl();
    const chartRef = useRef(null);
    const [showCategories, setShowCategories] = useState({
        people: true,
        process: true,
        purpose: true,
        proactivity: true,
        total: false
    });

    const toggleCategory = (category) => {
        setShowCategories(prevState => ({
            ...prevState,
            [category]: !prevState[category]
        }));
    };

    const initialDimensions = {};
    Object.keys(CATEGORIES_AND_DIMENSIONS).forEach(cat => {
        CATEGORIES_AND_DIMENSIONS[cat].forEach(dim => {
            initialDimensions[dim] = true;
        })
    });

    const [showDimensions, setShowDimensions] = useState(initialDimensions);

    const toggleDimension = (dim) => {
        setShowDimensions(prevState => ({
            ...prevState,
            [dim]: !prevState[dim]
        }));
    };

    if (!props.reportData.data || 0 === Object.keys(props.reportData.data).length || !props.surveyData || 0 === Object.keys(props.surveyData.attributes).length) {
        return null;
    }

    // Determine colors for report based on type
    const categoryColors = props.surveyData.attributes.type === 'brcgs' ? BRCGS_CATEGORY_COLORS : CATEGORY_COLORS;


    const categories = {
        people: {
            color: categoryColors.people.dark,
            border: categoryColors.people.light,
            label: intl.formatMessage({id: 'generic.peopleCategory', defaultMessage: 'People'})
        },
        process: {
            color: categoryColors.process.dark,
            border: categoryColors.process.light,
            label: intl.formatMessage({id: 'generic.processCategory', defaultMessage: 'Process'})
        },
        purpose: {
            color: categoryColors.purpose.dark,
            border: categoryColors.purpose.light,
            label: intl.formatMessage({id: 'generic.purposeCategory', defaultMessage: 'Purpose'})
        },
        proactivity: {
            color: categoryColors.proactivity.dark,
            border: categoryColors.proactivity.light,
            label: intl.formatMessage({id: 'generic.proactivityCategory', defaultMessage: 'Proactivity'})
        },
        total: {
            color: '#ffd600',
            border: '#fcf6d7',
            label: intl.formatMessage({id: 'generic.total', defaultMessage: 'Total'})
        },
    };

    const tabContent = (tab) => {
        const chartOptions = {
            interaction: {
                intersect: false,
                mode: 'index',
            },
            maintainAspectRatio: true,
            responsive: true,
            scales: {
                y: {
                    min: 100,
                    max: 0,
                    title: {
                        display: true,
                        text: intl.formatMessage({id: 'generic.score', defaultMessage: 'Score'})
                    },
                    ticks: {
                        stepSize: 5,
                    }
                },
            },
            animation: {
                duration: 0,
            },
            plugins: {
                legend: {
                    display: false,
                },
                tooltip: {
                    position: 'nearest',
                },
            },
        };

        const chartData = {
            labels: [],
            datasets: [],
        };

        // Survey titles as labels (x axis)
        props.reportData.data.forEach(rep => {
            chartData.labels.push(rep.survey.title);
        });

        // Total & categories
        if (0 === tab) {
            Object.keys(categories).forEach((cat) => {
                const dataset = {
                    label: categories[cat].label,
                    backgroundColor: categories[cat].color,
                    borderColor: categories[cat].color,
                    data: [],
                };

                props.reportData.data.forEach(rep => {
                    if ('total' === cat) {
                        dataset.data.push(rep.report.total.score ? parseFloat(rep.report.total.score) : null);
                    } else {
                        dataset.data.push(rep.report.categories[cat].score ? parseFloat(rep.report.categories[cat].score) : null);
                    }

                    // Min/max
                    if (dataset.data.length) {
                        if (dataset.data[dataset.data.length-1] !== null && dataset.data[dataset.data.length-1] > chartOptions.scales.y.max) {
                            chartOptions.scales.y.max = dataset.data[dataset.data.length-1];
                        }
                        if (dataset.data[dataset.data.length-1] !== null && dataset.data[dataset.data.length-1] < chartOptions.scales.y.min) {
                            chartOptions.scales.y.min = dataset.data[dataset.data.length-1];
                        }
                    }
                });
                if (showCategories[cat]) {
                    chartData.datasets.push(dataset);
                }
            });
        } else { // Dimensions
            Object.keys(props.reportData.data[props.reportData.data.length-1].report.categories).forEach((cat) => {
                Object.keys(props.reportData.data[props.reportData.data.length-1].report.categories[cat].dimensions).forEach((dim) => {
                    const dataset = {
                        label: dimensionTranslated(dim, intl),
                        backgroundColor: categories[cat].color,
                        borderColor: categories[cat].color,
                        data: [],
                    };

                    props.reportData.data.forEach(rep => {
                        dataset.data.push(rep.report.dimensions[dim].score ? parseFloat(rep.report.dimensions[dim].score) : null);

                        // Min/max
                        if (dataset.data.length) {
                            //console.log(dim+' | y val: '+dataset.data[dataset.data.length-1]);
                            if (dataset.data[dataset.data.length-1] !== null && dataset.data[dataset.data.length-1] > chartOptions.scales.y.max) {
                                //console.log('y max: '+dataset.data[dataset.data.length-1]);
                                chartOptions.scales.y.max = dataset.data[dataset.data.length-1];
                            }
                            if (dataset.data[dataset.data.length-1] !== null && dataset.data[dataset.data.length-1] < chartOptions.scales.y.min) {
                                //console.log('y min: '+dataset.data[dataset.data.length-1]);
                                chartOptions.scales.y.min = dataset.data[dataset.data.length-1];
                            }
                        }
                    });

                    if (showDimensions[dim]) {
                        chartData.datasets.push(dataset);
                    }
                });
            });
        }

        // Make sure points on chart are not right up against top/bottom
        chartOptions.scales.y.max = Math.ceil(chartOptions.scales.y.max / 5) * 5;
        chartOptions.scales.y.min = Math.floor(chartOptions.scales.y.min / 5) * 5;
        return (<LineLazy innerRef={chartRef} className="chart-canvas" data={chartData} options={chartOptions} />);
    };

    const selectorContent = (tab) => {
        // Total & categories
        if (0 === tab) {
            return <FormGroup row style={{justifyContent: 'center'}}>
                {Object.keys(categories).map(cat => (
                    <FormControlLabel
                        key={cat}
                        label={categories[cat].label}
                        style={{backgroundColor: categories[cat].border, borderLeft: '5px solid '+categories[cat].color, paddingRight: '10px', marginLeft: 0}}
                        control={
                            <Checkbox
                                color="primary"
                                style={{ padding: '5px' }}
                                checked={showCategories[cat]}
                                onChange={() => toggleCategory(cat)}
                            />
                        }
                    />
                ))}
            </FormGroup>
        } else { // Dimensions
            return (
                <Grid container spacing={0} justifyContent="center">
                    {Object.keys(CATEGORIES_AND_DIMENSIONS).map((cat, i) => (
                        <Grid item key={i}>
                            <FormGroup style={{justifyContent: 'center', paddingBottom: 5}} key={i}>
                                {CATEGORIES_AND_DIMENSIONS[cat].map(dim => (
                                    <FormControlLabel
                                        key={dim}
                                        label={dimensionTranslated(dim, intl)}
                                        style={{backgroundColor: categories[cat].border, borderLeft: '5px solid '+categories[cat].color, paddingRight: '10px', marginLeft: 0}}
                                        control={
                                            <Checkbox
                                                color="primary"
                                                style={{ padding: '5px' }}
                                                checked={showDimensions[dim]}
                                                onChange={() => toggleDimension(dim)}
                                            />
                                        }
                                    />
                                ))}
                            </FormGroup>
                        </Grid>
                    ))}
                </Grid>
            );
        }
    };

    return (
        <Fragment>
            <Tabs value={activeTab} onChange={(event, newValue) => setActiveTab(newValue)} variant="fullWidth" className={classes.tabs}>
                <Tab label={intl.formatMessage({id: 'progression.totalAndCategories', defaultMessage: 'Total & Categories'})} />
                <Tab label={intl.formatMessage({id: 'generic.dimensions', defaultMessage: 'Dimensions'})} />
            </Tabs>
            <Box style={{overflow: 'auto', width: '100%', margin: '0 auto'}} pt={1}>
                <div ref={props.setScreenshotRef}>
                    {selectorContent(activeTab)}
                    {tabContent(activeTab)}
                </div>
            </Box>
        </Fragment>
    );
}