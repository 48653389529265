import axios from 'axios';
import BaseService from './BaseService';

class UserService extends BaseService {

    me() {
        return axios.get(this.apiUrl('/users/me'), {
            headers: this.getAuthHeader()
        });
    }

    patchMe(name, email, password) {
        return axios.patch(this.apiUrl('/users/me'), {
            data: {
                name: name,
                email: email,
                password: password,
            }
        },{
            headers: this.getAuthHeader()
        });
    }

    twoFactorLogin(email, password, code) {
        return axios.post(this.apiUrl('/auth/two-factor'), {
            data: {
                email: email,
                password: password,
                code: code
            }
        });
    }

    twoFactorDisable(code) {
        return axios.post(this.apiUrl('/users/me/two-factor-remove'), {
            data: {
                code: code
            }
        },{
            headers: this.getAuthHeader()
        });
    }

    twoFactorSetup(password) {
        return axios.post(this.apiUrl('/users/me/two-factor-setup'), {
            data: {
                password: password
            }
        },{
            headers: this.getAuthHeader()
        });
    }

    twoFactorConfirm(password, secret, code) {
        return axios.post(this.apiUrl('/users/me/two-factor-confirm'), {
            data: {
                password: password,
                secret: secret,
                code: code
            }
        },{
            headers: this.getAuthHeader()
        });
    }

    setLocale(locale) {
        return axios.patch(this.apiUrl('/users/me'), {
            data: {
                locale: locale,
            }
        },{
            headers: this.getAuthHeader()
        });
    }

    verifyNewEmail(email, uuid, token) {
        return axios.post(this.apiUrl('/users/verify-email'), {
            data: {
                email: email,
                uuid: uuid,
                token: token
            }
        }, {
            headers: this.getAuthHeader()
        });
    }

    verify(token) {
        return axios.post(this.apiUrl('/auth/verify'), {
            data: {
                token: token,
            }
        });
    }

    login(email, password) {
        return axios.post(this.apiUrl('/auth/login'), {
            data: {
                email: email,
                password: password
            }
        });
    }

    logout() {
        // Skip the request if there's no token
        const authHeader = this.getAuthHeader();
        if (authHeader === null ||
            JSON.stringify(authHeader) === '{}' ||
            authHeader['X-Auth-Token'] === null
            // TODO: expired token?
        ) {
            return Promise.resolve(null);
        }

        return axios.delete(this.apiUrl('/auth/logout'), {
            headers: authHeader
        });
    }

    setPassword(password, uuid, token) {
        return axios.post(this.apiUrl('/auth/set-password'), {
            data: {
                uuid: uuid,
                token: token,
                password: password
            }
        });
    }

    forgotPassword(email) {
        return axios.post(this.apiUrl('/auth/forgot-password'), {
            data: {
                email: email
            }
        });
    }

    signup(data, captchaToken) {
        return axios.post(this.apiUrl('/auth/signup'), {
            data: {
                type: 'user',
                attributes: {
                    name: data.contactName.trim(),
                    email: data.contactEmail.trim(),
                    password: "testtest",
                    role: data.type,
                    enabled: true,
                    locale: data.locale,
                    captchaToken: captchaToken,
                },
            },
            included: [
                {
                    type: 'company',
                    attributes: {
                        name: data.companyName.trim(),
                        defaultSurveyLanguage: data.defaultSurveyLanguage,
                    }
                },
                {
                    type: 'site',
                    attributes: {
                        name: data.siteName.trim(),
                        value: data.siteName.trim(),
                        countryCode: data.siteCountry,
                        industry: data.productCategory,
                        code: data.siteCode.trim() ? data.siteCode.trim() : null,
                    }
                }
            ]
        });
    }

    users(offset = 0, limit = 50, sort = null, search = null, filter = null, include = null) {
        const params = {
            'page[offset]': offset,
            'page[limit]': limit,
            'search': search,
            'sort': sort,
        };

        if (include) {
            params['include'] = include;
        }

        if (filter && 0 !== Object.keys(filter).length) {
            Object.keys(filter).forEach(key => {
                params['filter['+key+']'] = filter[key];
            });
        }

        return axios.get(this.apiUrl('/users'), {
            params: params,
            headers: this.getAuthHeader()
        });
    }

    async allUsers(sort = null, search = null, filter = null, include = null, offset = 0) {
        const response = await this.users(offset, 50, sort, search, filter, include);
        const data = response.data.data;

        //Move included stuff onto user objects
        if (response.data.included && response.data.included.length) {
            data.forEach((user, userIndex) => {
                if ('user-permissions' in user.relationships) {
                    user.relationships['user-permissions'].forEach((upRef, index) => {
                        response.data.included.forEach((up) => {
                            if (up.id === upRef.id) {
                                data[userIndex].relationships['user-permissions'][index] = up;
                            }
                        });
                    });
                }
            });
        }

        if (response.data.links.next) {
            return data.concat(await this.allUsers(sort, search, filter, include, offset+50));
        } else {
            return data;
        }
    }

    user(uuid) {
        return axios.get(this.apiUrl('/users/'+uuid), {
            headers: this.getAuthHeader()
        });
    }

    createUser(data, relationships) {
        return axios.post(this.apiUrl('/users'), {
            data: {
                type: 'user',
                attributes: data,
                relationships: relationships
            },
        },{
            headers: this.getAuthHeader()
        });
    }

    patchUser(uuid, data) {
        return axios.patch(this.apiUrl('/users/'+uuid), {
            data: {
                type: 'user',
                attributes: data
            }
        },{
            headers: this.getAuthHeader()
        });
    }

    delete(uuid) {
        return axios.delete(this.apiUrl('/users/'+uuid), {
            headers: this.getAuthHeader()
        });
    }

    permissions(uuid, offset = 0, limit = 50) {
        const params = {
            'page[offset]': offset,
            'page[limit]': limit,
            'include': ['survey', 'site', 'company'].join('|')
        };

        return axios.get(this.apiUrl('/users/'+uuid+'/permissions'), {
            params: params,
            headers: this.getAuthHeader()
        });
    }

    deletePermission(userUuid, permissionUuid) {
        return axios.delete(this.apiUrl('/users/'+userUuid+'/permissions/'+permissionUuid), {
            headers: this.getAuthHeader()
        });
    }

    patchPermission(userUuid, permissionUuid, data, relationships) {
        return axios.patch(this.apiUrl('/users/'+userUuid+'/permissions/'+permissionUuid), {
            data: {
                type: 'user-permission',
                attributes: data,
                relationships: relationships
            }
        },{
            headers: this.getAuthHeader()
        });
    }

    createPermission(userUuid, data, relationships) {
        return axios.post(this.apiUrl('/users/'+userUuid+'/permissions'), {
            data: {
                type: 'user-permission',
                attributes: data,
                relationships: relationships
            }
        },{
            headers: this.getAuthHeader()
        });
    }
}

export default new UserService();
