export function chartColors(type, value) {
    const colors = [
        {
            backgroundColor: 'rgba(128, 128, 128, 1)',
            borderColor: 'rgba(79, 79, 79, 1)',
            hoverBackgroundColor: 'rgba(128, 128, 128, 0.6)',
            hoverBorderColor: 'rgba(79, 79, 79, 0.4)',
        },
        {
            backgroundColor: 'rgba(182,60,60,1)',
            borderColor: 'rgba(182,60,60,0.8)',
            hoverBackgroundColor: 'rgba(182,60,60,0.6)',
            hoverBorderColor: 'rgba(182,60,60,0.4)',
        },
        {
            backgroundColor: 'rgba(223,9,64,1)',
            borderColor: 'rgba(223,9,64,0.8)',
            hoverBackgroundColor: 'rgba(223,9,64,0.6)',
            hoverBorderColor: 'rgba(223,9,64,0.4)',
        },
        {
            backgroundColor: 'rgba(147,192,32,1)',
            borderColor: 'rgba(147,192,32,0.8)',
            hoverBackgroundColor: 'rgba(147,192,32,0.6)',
            hoverBorderColor: 'rgba(147,192,32,0.4)',
        },
        {
            backgroundColor: 'rgba(31,183,214,1)',
            borderColor: 'rgba(31,183,214,0.8)',
            hoverBackgroundColor: 'rgba(31,183,214,0.6)',
            hoverBorderColor: 'rgba(31,183,214,0.4)',
        },
        {
            backgroundColor: 'rgba(147,51,138,1)',
            borderColor: 'rgba(147,51,138,0.8)',
            hoverBackgroundColor: 'rgba(147,51,138,0.6)',
            hoverBorderColor: 'rgba(147,51,138,0.4)',
        },
        {
            backgroundColor: 'rgba(198,67,145,1)',
            borderColor: 'rgba(198,67,145,0.8)',
            hoverBackgroundColor: 'rgba(198,67,145,0.6)',
            hoverBorderColor: 'rgba(198,67,145,0.4)',
        },
        {
            backgroundColor: 'rgba(214,80,51,1)',
            borderColor: 'rgba(214,80,51,0.8)',
            hoverBackgroundColor: 'rgba(214,80,51,0.6)',
            hoverBorderColor: 'rgba(214,80,51,0.4)',
        },
        {
            backgroundColor: 'rgba(191,171,23,1)',
            borderColor: 'rgba(191,171,23,0.8)',
            hoverBackgroundColor: 'rgba(191,171,23,0.6)',
            hoverBorderColor: 'rgba(191,171,23,0.4)',
        },
        {
            backgroundColor: 'rgba(214,210,25,1)',
            borderColor: 'rgba(214,210,25,0.8)',
            hoverBackgroundColor: 'rgba(214,210,25,0.6)',
            hoverBorderColor: 'rgba(214,210,25,0.4)',
        },
        {
            backgroundColor: 'rgba(94,50,138,1)',
            borderColor: 'rgba(94,50,138,0.8)',
            hoverBackgroundColor: 'rgba(94,50,138,0.6)',
            hoverBorderColor: 'rgba(94,50,138,0.4)',
        },
        {
            backgroundColor: 'rgba(56,93,168,1)',
            borderColor: 'rgba(56,93,168,0.8)',
            hoverBackgroundColor: 'rgba(56,93,168,0.6)',
            hoverBorderColor: 'rgba(56,93,168,0.4)',
        },
        {
            backgroundColor: 'rgba(100,192,190,1)',
            borderColor: 'rgba(100,192,190,0.8)',
            hoverBackgroundColor: 'rgba(100,192,190,0.6)',
            hoverBorderColor: 'rgba(100,192,190,0.4)',
        },
        {
            backgroundColor: 'rgba(92,186,152,1)',
            borderColor: 'rgba(92,186,152,0.8)',
            hoverBackgroundColor: 'rgba(92,186,152,0.6)',
            hoverBorderColor: 'rgba(92,186,152,0.4)',
        },
        {
            backgroundColor: 'rgba(191,12,107,1)',
            borderColor: 'rgba(191,12,107,0.8)',
            hoverBackgroundColor: 'rgba(191,12,107,0.6)',
            hoverBorderColor: 'rgba(191,12,107,0.4)',
        },
        {
            backgroundColor: 'rgba(240,131,85,1)',
            borderColor: 'rgba(240,131,85,0.8)',
            hoverBackgroundColor: 'rgba(240,131,85,0.6)',
            hoverBorderColor: 'rgba(240,131,85,0.4)',
        },
        {
            backgroundColor: 'rgba(94,184,133,1)',
            borderColor: 'rgba(94,184,133,0.8)',
            hoverBackgroundColor: 'rgba(94,184,133,0.6)',
            hoverBorderColor: 'rgba(94,184,133,0.4)',
        },
        {
            backgroundColor: 'rgba(196,190,126,1)',
            borderColor: 'rgba(196,190,126,0.8)',
            hoverBackgroundColor: 'rgba(196,190,126,0.6)',
            hoverBorderColor: 'rgba(196,190,126,0.4)',
        },
        {
            backgroundColor: 'rgba(229,161,141,1)',
            borderColor: 'rgba(229,161,141,0.8)',
            hoverBackgroundColor: 'rgba(229,161,141,0.6)',
            hoverBorderColor: 'rgba(229,161,141,0.4)',
        },
        {
            backgroundColor: 'rgba(254,156,16,1)',
            borderColor: 'rgba(254,156,16,0.8)',
            hoverBackgroundColor: 'rgba(254,156,16,0.6)',
            hoverBorderColor: 'rgba(254,156,16,0.4)',
        },
    ];

    if ('positives' === type || 'barriers' === type) {
        switch (value.toLowerCase()) {
            case 'Accountability / responsibility'.toLowerCase():
                return colors[1];

            case 'Awareness of culture'.toLowerCase():
            case 'Lack of positive / engaging culture'.toLowerCase():
            case 'Positive / engaging culture'.toLowerCase():
                return colors[2];

            case 'Budget'.toLowerCase():
                return colors[3];

            case 'Co-ordination across departments'.toLowerCase():
            case 'Co-ordination across departments / plants'.toLowerCase():
            case 'Co-ordination across departments / sites'.toLowerCase():
                return colors[4];

            case 'Employee commitment'.toLowerCase():
                return colors[5];

            case 'Incentives / benefits'.toLowerCase():
                return colors[6];

            case 'Other (please share your thoughts and ideas here)'.toLowerCase():
            case 'Other'.toLowerCase():
                return colors[7];

            case 'Management commitment'.toLowerCase():
                return colors[8];

            case 'Multi-cultural workforce (different cultures)'.toLowerCase():
                return colors[9];

            case 'Language barriers'.toLowerCase():
            case 'Multi-cultural workforce (language barriers)'.toLowerCase():
                return colors[10];


            case 'Physical space / premises'.toLowerCase():
            case 'Physical space / premises / age of site'.toLowerCase():
                return colors[11];

            case 'Staff retention'.toLowerCase():
            case 'Staff turnover'.toLowerCase():
                return colors[12];

            case 'Technology'.toLowerCase():
                return colors[13];

            case 'Time'.toLowerCase():
                return colors[14];

            case 'Training'.toLowerCase():
            case 'Training / communication'.toLowerCase():
            case 'Communication'.toLowerCase():
                return colors[15];

            default:
                return colors[0];
        }
    } else if ('quality' === type) {
        switch (value.toLowerCase()) {
            case 'Customer / consumer satisfaction'.toLowerCase():
                return colors[1];

            case 'Ethically made product (made in a cruelty-free / environmentally friendly way)'.toLowerCase():
            case 'Ethically made product (made in a sustainable / cruelty and slavery free / environmentally friendly way)'.toLowerCase():
            case 'Ethically made product (made in a sustainable / cruelty and slavery-free / environmentally friendly way)'.toLowerCase():
            case 'Ethically made product (made in a sustainable / environmentally friendly way)'.toLowerCase():
                return colors[2];

            case 'Nutritional product (healthy and balanced)'.toLowerCase():
                return colors[3];

            case 'Other'.toLowerCase():
                return colors[4];

            case 'Product consistency (exactly the same every time)'.toLowerCase():
                return colors[5];

            case 'Product with no defects / issues'.toLowerCase():
            case 'Product with no defects / issues (that comply with specifications)'.toLowerCase():
                return colors[6];

            case 'Safe product (no risk of harm)'.toLowerCase():
                return colors[7];

            case 'Superior product'.toLowerCase():
            case 'Superior product (better materials / features / appearance)'.toLowerCase():
            case 'Superior product (better taste / texture / appearance)'.toLowerCase():
            case 'Superior product (e.g. appearance)'.toLowerCase():
                return colors[8];

            case 'Well managed quality policies and procedures'.toLowerCase():
                return colors[9];

            default:
                return colors[0];
        }
    } else if ('environment' === type) {
        switch (value.toLowerCase()) {
            case 'Emissions'.toLowerCase():
            case 'Emissions (M)'.toLowerCase():
                return colors[7];

            case 'Energy use'.toLowerCase():
            case 'Energy use (M)'.toLowerCase():
                return colors[2];

            case 'Recycling'.toLowerCase():
            case 'Recycling (M)'.toLowerCase():
                return colors[3];

            case 'Waste'.toLowerCase():
            case 'Waste (M)'.toLowerCase():
                return colors[5];

            case 'Water use'.toLowerCase():
            case 'Water use (M)'.toLowerCase():
                return colors[4];

            default:
                return colors[0];
        }
    }

    return colors[0];
}