import { Fragment } from "react";
import ElementsTable from "./ElementsTable";
import { FormattedMessage } from "react-intl";

export default function VisionDimension(props) {
    return (
        <Fragment>
            <p>
                <FormattedMessage
                    id="execSummaryDiscAndRecs.vision"
                    defaultMessage={
                        "Vision relates to the long-term aspirations and goals of the company, and the inclusion of safety and "+
                        "quality within these. The communication of company vision typically starts as a document made visible "+
                        "for employees, and indeed Schein identifies 'formal statements of organisational philosophy, creeds, and "+
                        "charters' as a secondary embedding mechanism for culture. To show employees that a company takes its "+
                        "formally documented vison seriously, it must be supported in practice by the plans in place to achieve "+
                        "it and the demonstration of its importance by leaders."
                    }
                />
            </p>
            <ElementsTable dimension={props.dimension} elements={props.elements} lowestElements={props.lowestElements} />
        </Fragment>
    );
}
