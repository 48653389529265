import React from 'react';

export const UserContext = React.createContext({
    user: null,
    updateUser: () => {},
    token: {
        token: null,
        expiry: 0
    },
    updateToken: () => {},
});