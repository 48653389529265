import { Box, Paper, Typography, Grid, Divider, Button } from "@material-ui/core";
import { Fragment } from "react";
import { NavLink } from "react-router-dom";
import { BRCGS_CATEGORY_COLORS } from '../../../Constants/BrcgsCategoryColors';
import NoData from "../../NoData/NoData";
import CategoryLabel from "../../Translation/CategoryLabel";
import ElementLabel from "../../Translation/ElementLabel";


export default function BrcgsFreeElements(props) {
    if (!props.reportData.data) {
        return null;
    }

    if (0 === props.reportData.data.length) {
        return <NoData />;
    }

    const lastElement = props.reportData.data.length - 1;
    return (
        <Fragment>
            <Box pb={2}>
                <Paper elevation={1} style={{borderLeft: '5px solid '+BRCGS_CATEGORY_COLORS['people'].dark, backgroundColor: BRCGS_CATEGORY_COLORS['people'].light}}>
                    <Box p={1} px={2}>
                        <Typography variant="h6">Highest Element</Typography>
                        <Divider />
                        <Grid container direction="row" justifyContent="center" alignItems="center">
                            <Grid item sm={2} align="left">
                                <Box py={2} style={{fontSize: '1rem'}}>
                                    <CategoryLabel category={props.reportData.data[0].category} />
                                </Box>
                            </Grid>
                            <Grid item sm={8} align="left" style={{fontSize: '1rem'}}>
                                {props.reportData.data[0].shortName ? <ElementLabel element={props.reportData.data[0].shortName} /> : props.reportData.data[0].title}
                            </Grid>
                            <Grid item sm={2} align="right" style={{fontSize: '1rem'}}>
                                {props.reportData.data[0].roundedScore}
                            </Grid>
                        </Grid>
                    </Box>
                </Paper>
            </Box>
            <Box pb={2}>
                <Paper elevation={1} style={{borderLeft: '5px solid '+BRCGS_CATEGORY_COLORS['process'].dark, backgroundColor: BRCGS_CATEGORY_COLORS['process'].light}}>
                    <Box p={1} px={2}>
                        <Typography variant="h6">Lowest Element</Typography>
                        <Divider />
                        <Grid container direction="row" justifyContent="center" alignItems="center">
                            <Grid item sm={2} align="left">
                                <Box py={2} style={{fontSize: '1rem'}}>
                                    <CategoryLabel category={props.reportData.data[lastElement].category} />
                                </Box>
                            </Grid>
                            <Grid item sm={8} align="left" style={{fontSize: '1rem'}}>
                                {props.reportData.data[lastElement].shortName ? <ElementLabel element={props.reportData.data[lastElement].shortName} /> : props.reportData.data[lastElement].title}
                            </Grid>
                            <Grid item sm={2} align="right" style={{fontSize: '1rem'}}>
                                {props.reportData.data[lastElement].roundedScore}
                            </Grid>
                        </Grid>
                    </Box>
                </Paper>
            </Box>

            <Grid container justifyContent="center">
                <Grid item sm={5} xs={10}>
                    <Paper variant="outlined" style={{borderColor: BRCGS_CATEGORY_COLORS['people'].dark, backgroundColor: BRCGS_CATEGORY_COLORS['people'].light}}>
                        <Box p={1} px={2}>
                            <Typography variant="subtitle1" align="center">
                                Scores for additional Elements are reported with a full assessment.
                            </Typography>
                            <Box p={1} textAlign='center'>
                                <Button variant="contained" color="primary" component={NavLink} to="/upgrade/brcgs-free" style={{ backgroundColor: BRCGS_CATEGORY_COLORS['people'].dark}}>
                                    Learn more about a full assessment
                                </Button>
                            </Box>
                        </Box>
                    </Paper>
                </Grid>
            </Grid>
        </Fragment>
    );
}
