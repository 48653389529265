import { Box, Grid, Typography, Table, TableBody, TableCell, TableHead, TableRow, IconButton, Tooltip } from "@material-ui/core";
import GetAppIcon from '@material-ui/icons/GetApp';
import { useContext, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { NotificationContext } from "../../Context/NotificationContext";
import { AppBarTitleContext } from "../../Context/AppBarTitleContext";
import { SpinnerContext } from "../../Context/SpinnerContext";
import SurveyService from "../../Services/SurveyService";

export default function RogueResponses(props) {
    let { hash } = useParams();
    const [survey, setSurvey] = useState(null);
    const [rogueResponses, setRogueResponses] = useState(null);

    const { updateNotification } = useContext(NotificationContext);
    const updateNotificationRef = useRef();
    updateNotificationRef.current = (open, message, level) => {
        updateNotification(open, message, level);
    }

    const { updateTitle } = useContext(AppBarTitleContext);
    const updateTitleRef = useRef();
    updateTitleRef.current = (title) => {
        updateTitle(title);
    };

    const { updateLoading } = useContext(SpinnerContext);
    const updateLoadingRef = useRef();
    updateLoadingRef.current = (loading) => {
        updateLoading(loading);
    };

    const downloadQuestionRoleReport = (element, role) => () => {
        updateLoading(true);
        SurveyService.rogueResponsesElementRole(hash, element, role)
        .then(function (response) {
            const blob = new Blob([response.data], { type: 'text/csv' });
            let url = window.URL.createObjectURL(blob);
            let a = document.createElement('a');
            a.href = url;
            const date = new Date();
            a.download = element.replace(/(\W+)/gi, '-')+'_'+role.replace(/(\W+)/gi, '-')+'_'+date.getFullYear()+'-'+(date.getMonth()+1)+'-'+date.getDate()+'_'+date.getHours()+'-'+date.getMinutes()+'.csv';
            a.click();
        }).catch(function (error) {
            console.log(error);
            updateNotification(true, 'An unknown error occurred!', 'error');
        }).then(function() {
            updateLoading(false);
        });
    };

    useEffect(() => {
        updateTitleRef.current(null);
        updateLoadingRef.current(true);

        const surveyPromise = SurveyService.surveyByHash(hash, null, []);
        const rogueResponsesPromise = SurveyService.rogueResponses(hash);
        updateLoadingRef.current(true);
        Promise.all([surveyPromise, rogueResponsesPromise]).then(function(response) {
            const [surveyData, rogueResponses] = response;
            setSurvey(surveyData.data.data);
            setRogueResponses(rogueResponses.data.data);
        }).catch((error) => {
            console.error(error);
            updateNotificationRef.current(true, 'An unknown error occurred!', 'error');
        }).then(() => {
            updateLoadingRef.current(false);
        });
    }, [hash]);

    return (
        <Grid container spacing={0}>
            <Grid item xs={12} md={6}>
                <Typography variant="h5">
                    {survey && survey.attributes ? survey.attributes.title + ' | Rogue Responses' : null}
                </Typography>
                <Typography variant="body1">
                    {survey ? 'All elements in the survey that contain something in parenthesis at the end and how many of each role responsed to that question' : null}
                </Typography>
            </Grid>
            {rogueResponses ?
                <Grid item xs={12}>
                    <Box pt={2}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Element</TableCell>
                                    <TableCell>Roles</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {Object.keys(rogueResponses).map((element, i) => (
                                    <TableRow key={i}>
                                        <TableCell>
                                            {element}
                                        </TableCell>
                                        <TableCell>
                                            {rogueResponses[element] ?
                                                <ul>
                                                    {Object.keys(rogueResponses[element]).map((role, j) => (
                                                        <li key={j}>
                                                            {role}: <strong>{rogueResponses[element][role]}</strong> <Tooltip title ="Download"><IconButton size="small" color="primary" onClick={downloadQuestionRoleReport(element, role)}><GetAppIcon fontSize="small"/></IconButton></Tooltip>
                                                        </li>
                                                    ))}
                                                </ul>
                                            : null}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </Box>
                </Grid>
            : null }
        </Grid>
    );
}