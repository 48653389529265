export const environmentTranslated = (env, intl) => {
    if (!env) {
        return null;
    }

    const labels = {
        'energy use': intl.formatMessage({id: 'environment.energyUse', defaultMessage: 'Energy use'}),
        'energy use (m)': intl.formatMessage({id: 'environment.energyUseM', defaultMessage: 'Energy use (M)'}),
        'water use': intl.formatMessage({id: 'environment.waterUse', defaultMessage: 'Water use'}),
        'water use (m)': intl.formatMessage({id: 'environment.waterUseM', defaultMessage: 'Water use (M)'}),
        'waste': intl.formatMessage({id: 'environment.waste', defaultMessage: 'Waste'}),
        'waste (m)': intl.formatMessage({id: 'environment.wasteM', defaultMessage: 'Waste (M)'}),
        'emissions': intl.formatMessage({id: 'environment.emissions', defaultMessage: 'Emissions'}),
        'emissions (m)': intl.formatMessage({id: 'environment.emissionsM', defaultMessage: 'Emissions (M)'}),
        'recycling': intl.formatMessage({id: 'environment.recycling', defaultMessage: 'Recycling'}),
        'recycling (m)': intl.formatMessage({id: 'environment.recyclingM', defaultMessage: 'Recycling (M)'}),
    };

    if (env.toLowerCase() in labels) {
        return labels[env.toLowerCase()];
    }

    return env;
}