import { Table, TableCell, TableHead, TableRow, TableBody } from "@material-ui/core";
import { Fragment } from "react";
import { FormattedMessage } from "react-intl";
import DimensionLabel from "../../../../Translation/DimensionLabel";
import ElementLabel from "../../../../Translation/ElementLabel";
import Decimal from 'decimal.js';

export default function ElementsTable(props) {

    const tableOutro = (dim) => {
        if (dim === 'training') {
            return <FormattedMessage
                id="execSummaryDiscAndRecs.trainingDimensionTableOutro"
                defaultMessage={
                    "The lowest scoring elements in this section reflect areas to focus on first, especially where sub-groups "+
                    "have particularly low hotspot scores. For reference, a score of 60 for training frequency reflects the "+
                    "response of 'yearly', 40 reflects 'every few years' and 80 'a few times a year'."
                }
            />;
        } else if (dim === 'metrics') {
            return <FormattedMessage
                id="execSummaryDiscAndRecs.metricsDimensionTableOutro"
                defaultMessage={
                    "The lowest scoring elements in this section reflect areas to focus on first, especially where sub-groups "+
                    "have particularly low hotspot scores. For reference, a score of 60 for frequency in metrics reflects the "+
                    "response of 'yearly', 40 reflects 'every few years' and 80 'a few times a year'."
                }
            />;
        } else if (dim === 'communication') {
            return <FormattedMessage
                id="execSummaryDiscAndRecs.communicationDimensionTableOutro"
                defaultMessage={
                    "The lowest scoring elements in this section reflect areas to focus on first, especially where sub-groups "+
                    "have particularly low hotspot scores. For reference, a score of 60 for communication frequency reflects "+
                    "the response of 'a few times a year', 40 reflects 'yearly' and 80 "+
                    "'monthly'."
                }
            />;
        }

        return <FormattedMessage
            id="execSummaryDiscAndRecs.dimensionTableOutro"
            defaultMessage={
                "The lowest scoring elements in this section reflect areas to focus on first, especially where sub-groups "+
                "have particularly low hotspot scores."
            }
        />;
    };

    const lowestScore = (elementName) => {
        for (const i in props.lowestElements.lowest) {
            if (elementName === props.lowestElements.lowest[i].element) {
                return Decimal(props.lowestElements.lowest[i].score).toFixed(1);
            }
        }
        return '-';
    };

    const tableContent = () => {
        if ('training' === props.dimension) {
            const effectivenessElements = [];
            const frequencyElements = [];
            for (const element of props.elements) {
                if (element.shortName.toLowerCase().indexOf('frequency') === 0) {
                    frequencyElements.push(element);
                    continue;
                }
                effectivenessElements.push(element);
            }

            return (<Table>
                <TableHead>
                    <TableRow>
                        <TableCell><FormattedMessage id="execSummaryDiscAndRecs.trainingFrequency" defaultMessage="Element: Frequency" /></TableCell>
                        <TableCell align="center" style={{width: '10%'}}><FormattedMessage id="generic.average" defaultMessage="Average" /></TableCell>
                        <TableCell align="center" style={{width: '10%'}}><FormattedMessage id="execSummary.lowestHotspot" defaultMessage="Lowest Hotspot" /></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {frequencyElements.map((element, i) => {
                        if (props.dimension !== element.dimension) {
                            return null;
                        }
                        return (
                            <TableRow key={i}>
                                <TableCell><ElementLabel element={element.shortName} /></TableCell>
                                <TableCell align="center">{Decimal(element.score).toFixed(1)}</TableCell>
                                <TableCell align="center">{lowestScore(element.shortName)}</TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
                <TableHead>
                    <TableRow>
                        <TableCell><FormattedMessage id="execSummaryDiscAndRecs.trainingEffectiveness" defaultMessage="Element: Effectiveness" /></TableCell>
                        <TableCell align="center" style={{width: '10%'}}><FormattedMessage id="generic.average" defaultMessage="Average" /></TableCell>
                        <TableCell align="center" style={{width: '10%'}}><FormattedMessage id="execSummary.lowestHotspot" defaultMessage="Lowest Hotspot" /></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {effectivenessElements.map((element, i) => {
                        if (props.dimension !== element.dimension) {
                            return null;
                        }
                        return (
                            <TableRow key={i}>
                                <TableCell><ElementLabel element={element.shortName} /></TableCell>
                                <TableCell align="center">{Decimal(element.score).toFixed(1)}</TableCell>
                                <TableCell align="center">{lowestScore(element.shortName)}</TableCell>
                            </TableRow>
                        );
                    })}

                </TableBody>
            </Table>);
        }

        return (<Table>
            <TableHead>
                <TableRow>
                    <TableCell><FormattedMessage id="generic.element" defaultMessage="Element" /></TableCell>
                    <TableCell align="center" style={{width: '10%'}}><FormattedMessage id="generic.average" defaultMessage="Average" /></TableCell>
                    <TableCell align="center" style={{width: '10%'}}><FormattedMessage id="execSummary.lowestHotspots" defaultMessage="Lowest Hotspots" /></TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {props.elements.map((element, i) => {
                    if (props.dimension !== element.dimension) {
                        return null;
                    }
                    return (
                        <TableRow key={i}>
                            <TableCell><ElementLabel element={element.shortName} /></TableCell>
                            <TableCell align="center">{Decimal(element.score).toFixed(1)}</TableCell>
                            <TableCell align="center">{lowestScore(element.shortName)}</TableCell>
                        </TableRow>
                    );
                })}
            </TableBody>
        </Table>);
    }

    return (
        <Fragment>
            <p>
                <FormattedMessage
                    id="execSummaryDiscAndRecs.dimensionTableIntro"
                    defaultMessage={
                        "The average scores for {dimension} elements, and their lowest hotspots (if applicable), are shown below:"
                    }
                    values={{
                        dimension: <DimensionLabel lowerCase={true} dimension={props.dimension} />
                    }}
                />
            </p>
            {tableContent()}
            <p>
                {tableOutro(props.dimension)}
            </p>
        </Fragment>
    );
}
