import { Box, Grid, Paper, Typography } from "@material-ui/core";
import { CATEGORY_COLORS } from '../../../Constants/CategoryColors';
import peopleLogo from '../img/category/people.png';
import proactivityLogo from '../img/category/proactivity.png';
import purposeLogo from '../img/category/purpose.png';
import processLogo from '../img/category/process.png';
import CategoryLabel from "../../Translation/CategoryLabel";
import CompareArrow from "./Common/CompareArrow";
import DimensionLabel from "../../Translation/DimensionLabel";
import Decimal from 'decimal.js';
import { SSAFE_CATEGORY_COLORS } from "../../../Constants/SsafeCategoryColors";


export default function BenchmarkSsafe(props) {

    const categoryLogos = {
        people: peopleLogo,
        proactivity: proactivityLogo,
        purpose: purposeLogo,
        process: processLogo,
    };

    if (!props.reportData.data || 0 === Object.keys(props.reportData.data).length) {
        return null;
    }

    const scoreChange = (current, ssafe, precision = 0) => {
        if (!current || !SSAFE_CATEGORY_COLORS) {
            return '-';
        }
        return (Decimal(current).minus(Decimal(ssafe))).toFixed(precision);
    };

    return (
        Object.keys(props.reportData.data.current.categories).map((categoryKey, categoryIndex) => {
            const categoryChange = scoreChange(props.reportData.data.current.categories[categoryKey].roundedScore, props.reportData.data.ssafe ? props.reportData.data.ssafe.categories[categoryKey].roundedScore : null)
            return (
                <Box pb={2} pt={(categoryIndex === 0 ? 2 : 0)} key={categoryKey}>
                    <Grid container spacing={2}>
                        {/* Categories */}
                        <Grid item sm={6} xs={12} align="center">
                            {0 === categoryIndex ?
                                <Grid container direction="row" justifyContent="center" alignItems="center">
                                    <Grid item sm={3} align="center"></Grid>
                                    <Grid item sm={3} align="center"><Typography variant="subtitle1">Score</Typography></Grid>
                                    <Grid item sm={3} align="center"><Typography variant="subtitle1">SSAFE</Typography></Grid>
                                    <Grid item sm={3} align="center"><Typography variant="subtitle1">Change</Typography></Grid>
                                </Grid>
                            : null}
                            <Paper key={categoryKey} elevation={1} style={{borderLeft: '5px solid '+CATEGORY_COLORS[categoryKey].dark, height: 200, backgroundColor: CATEGORY_COLORS[categoryKey].light}}>
                                <Grid container direction="row" justifyContent="center" alignItems="center" style={{height: '100%'}}>
                                    <Grid item sm={3} align="center">
                                        <img style={{ maxWidth: '110px', maxHeight: "100%" }} alt={categoryKey} src={categoryLogos[categoryKey]} />
                                        <Typography variant="subtitle1">
                                            <CategoryLabel category={categoryKey} upperCase={true} />
                                        </Typography>
                                    </Grid>
                                    <Grid item sm={3} align="center">
                                        {/* Current */}
                                        <Typography title={props.reportData.data.current.categories[categoryKey].score ? props.reportData.data.current.categories[categoryKey].score : ''} variant="h2" style={{fontWeight: 'normal'}}>{props.reportData.data.current.categories[categoryKey].roundedScore ? props.reportData.data.current.categories[categoryKey].roundedScore : '-'}</Typography>
                                    </Grid>
                                    <Grid item sm={3} align="center">
                                        {/* SSAFE */}
                                        {props.reportData.data.ssafe && props.reportData.data.ssafe.categories[categoryKey] && props.reportData.data.ssafe.categories[categoryKey].roundedScore ?
                                            <Typography title={props.reportData.data.ssafe.categories[categoryKey].score ? props.reportData.data.ssafe.categories[categoryKey].score : ''} variant="h2" style={{fontWeight: 'normal'}}>{props.reportData.data.ssafe.categories[categoryKey].roundedScore ? props.reportData.data.ssafe.categories[categoryKey].roundedScore : '-'}</Typography>
                                        : <Typography variant="h2">-</Typography>}
                                    </Grid>
                                    <Grid item sm={3} align="center">
                                        {/* Change */}
                                        {'-' !== categoryChange ?
                                            <div style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center'
                                            }}>
                                                <Typography variant="h2" style={{fontWeight: 'normal'}}>
                                                    {categoryChange}
                                                </Typography>
                                                <CompareArrow change={categoryChange} large={true} />
                                            </div>
                                        : <Typography variant="h2">-</Typography>}
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                        <Grid item sm={6} xs={12} align="center">
                            {0 === categoryIndex ?
                                <Grid container direction="row" justifyContent="center" alignItems="center">
                                    <Grid item sm={3} align="center"></Grid>
                                    <Grid item sm={3} align="center"><Typography variant="subtitle1">Score</Typography></Grid>
                                    <Grid item sm={3} align="center"><Typography variant="subtitle1">SSAFE</Typography></Grid>
                                    <Grid item sm={3} align="center"><Typography variant="subtitle1">Change</Typography></Grid>
                                </Grid>
                            : null}
                            {/* Dimensions */}
                            <Paper key={categoryKey} elevation={1} style={{borderLeft: '5px solid '+CATEGORY_COLORS[categoryKey].dark, height: 200, backgroundColor: CATEGORY_COLORS[categoryKey].light}}>
                                {Object.keys(props.reportData.data.current.categories[categoryKey].dimensions).map((dimensionKey, dimensionIndex) => {
                                    const dimensionChange = scoreChange(props.reportData.data.current.categories[categoryKey].dimensions[dimensionKey].roundedScore, props.reportData.data.ssafe ? props.reportData.data.ssafe.dimensions[dimensionKey].roundedScore : null)
                                    return (
                                        <Grid container direction="row" justifyContent="center" alignItems="center" key={dimensionIndex} style={{height: '20%'}}>
                                            <Grid item sm={3} align="left">
                                                <Box pl={2}>
                                                    <DimensionLabel dimension={dimensionKey} />
                                                </Box>
                                            </Grid>
                                            <Grid item sm={3} align="center">
                                                <Typography variant="subtitle1">
                                                    {props.reportData.data.current.categories[categoryKey].dimensions[dimensionKey].roundedScore ? props.reportData.data.current.categories[categoryKey].dimensions[dimensionKey].roundedScore : '-'}
                                                </Typography>
                                            </Grid>
                                            <Grid item sm={3} align="center">
                                                <Typography variant="subtitle1">
                                                    {props.reportData.data.ssafe && props.reportData.data.ssafe.dimensions[dimensionKey].roundedScore ? props.reportData.data.ssafe.dimensions[dimensionKey].roundedScore : '-'}
                                                </Typography>
                                            </Grid>
                                            <Grid item sm={3} align="center">
                                                {'-' !== dimensionChange ?
                                                    <div style={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'center'
                                                    }}>
                                                        <Typography variant="subtitle1" style={{fontWeight: 'normal'}}>
                                                            {dimensionChange}
                                                        </Typography>
                                                        <CompareArrow change={dimensionChange} />
                                                    </div>
                                                : '-'}
                                            </Grid>
                                        </Grid>
                                    );
                                })}
                            </Paper>
                        </Grid>
                    </Grid>
                </Box>
            );
        })
    );
}
