import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, IconButton, makeStyles, Tooltip, Typography } from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import { Fragment, useContext, useEffect, useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useLocation, useParams } from "react-router-dom";
import { AppBarTitleContext } from "../../Context/AppBarTitleContext";
import { NotificationContext } from "../../Context/NotificationContext";
import { UserContext } from "../../Context/UserContext";
import SurveyService from "../../Services/SurveyService";
import LocalBackdrop from "../LocalBackdrop/LocalBackdrop";
import DeleteIcon from '@material-ui/icons/Delete';
import SurveyTypeLabel from "../Translation/SurveyTypeLabel";
import { SpinnerContext } from "../../Context/SpinnerContext";

const useStyles = makeStyles((theme) => ({
    centeredHeader: {
        '& > span': {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        textAlign: 'center'
    },
    fab: {
        position: 'fixed',
        bottom: theme.spacing(1.5),
        right: theme.spacing(1.5),
    },
    buttonHeader: {
        maxWidth: '25px'
    },
    centeredCell: {
        [theme.breakpoints.down('xs')]: {
            textAlign: 'left'
        },
        [theme.breakpoints.up('sm')]: {
            textAlign: 'center'
        }
    },
    error: {
        backgroundColor: theme.palette.error.main,
        borderRadius: 5,
        padding: 5,
        color: '#ffffff',
        margin: 2,
    },
    info: {
        backgroundColor: theme.palette.info.main,
        borderRadius: 5,
        padding: 5,
        color: '#ffffff',
        margin: 2,
    },
    warning: {
        backgroundColor: theme.palette.warning.main,
        borderRadius: 5,
        padding: 5,
        color: '#ffffff',
        margin: 2,
    },
}));

export default function SurveyRevisions(props) {
    const classes = useStyles();
    const intl = useIntl();
    const location = useLocation();
    let { hash } = useParams();

    const { user } = useContext(UserContext);
    const { updateNotification } = useContext(NotificationContext);
    const { updateTitle } = useContext(AppBarTitleContext);
    const updateTitleRef = useRef();
    updateTitleRef.current = (newTitle) => {
        updateTitle(newTitle);
    };
    const { updateLoading } = useContext(SpinnerContext);

    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [deleteUuid, setDeleteUuid] = useState(null);
    const [importMessagesDialogOpen, setImportMessagesDialogOpen] = useState(false);
    const [importMessages, setImportMessages] = useState([]);
    const [isLoading, setLoading] = useState(true);
    const [forceRefresh, setForceRefresh] = useState(false);
    const [tableData, setTableData] = useState({
        page: 0,
        count: 1,
        rowsPerPage: 10,
        data: [],
    });

    const columns = [{
        name: 'id',
        options: {
            display: false,
            viewColumns: false,
            filter: false,
            sort: false,
            searchable: false,
            print: false,
            download: false
        }
    },
    {
        name: 'attributes.combinedHash',
        options: {
            display: false,
            viewColumns: false,
            filter: false,
            sort: false,
            searchable: false,
            print: false,
            download: false
        }
    },
    {
        label: intl.formatMessage({id: 'surveys.surveyTitleColumn', defaultMessage: 'Survey title'}),
        name: 'attributes.title',
    },
    {
        name: 'attributes.type',
        label: intl.formatMessage({id: 'surveys.typeColumn', defaultMessage: 'Type'}),
        options: {
            customBodyRender: (value) => (<SurveyTypeLabel type={value} />)
        }
    },
    {
        name: 'attributes.importRevision',
        label: 'Version',
        options: {
            setCellProps: () => ({
                className: classes.centeredCell,
            }),
            setCellHeaderProps: () => ({
                className: classes.centeredHeader,
            })
        }
    },
    {
        name: 'attributes.scoredRespondentCount',
        label: 'Responses',
        options: {
            setCellProps: () => ({
                className: classes.centeredCell,
            }),
            setCellHeaderProps: () => ({
                className: classes.centeredHeader,
            })
        }
    },
    {
        name: 'attributes.providerId',
        options: {
            display: false,
            viewColumns: false,
            filter: false,
            sort: false,
            searchable: false,
            print: false,
            download: false
        }
    },
    {
        name: 'attributes.providerStatus',
        label: 'Provider status',
        options: {
            display: (user && user.role && (user.role === 'admin' || user.role === 'brcgs-admin') ? true : false) // Only show survey provider status for admins and brcgs-admins
        }
    },
    {
        name: 'attributes.status',
        label: 'Import status',
        options: {
            display: (user && user.role && (user.role === 'admin' || user.role === 'brcgs-admin') ? true : false) // Only show survey provider status for admins and brcgs-admins
        }
    },
    {
        name: 'relationships.company.data.name',
        label: 'Company',
        options: {
            display: (user && user.role && (user.role === 'admin' || user.role === 'brcgs-admin' ) ? true : false) // Only show company for admins and brcgs-admins
        }
    },
    {
        name: 'attributes.provider',
        label: intl.formatMessage({id: 'surveys.providerColumn', defaultMessage: 'Provider'}),
        options: {
            sort: false,
            display: (user && user.role && user.role === 'admin' ? true : false), // Only show survey provider for admins
            customBodyRender: (value, tableMeta) => {
                // NOTE: If minimum respondents change in backend, they need to be updated here
                if (value === 'alchemer_new') {
                    return <span>{tableMeta.rowData[6]} (Alc. 🇪🇺)</span>;
                } else if (value === 'alchemer_old') {
                    return <span>{tableMeta.rowData[6]} (Alc. 🇺🇸)</span>;
                } else {
                    return <span>{value}</span>;
                }
            },
            setCellProps: () => ({
                className: classes.centeredCell,
            }),
            setCellHeaderProps: () => ({
                className: classes.centeredHeader,
            })
        }
    },
    {
        name: 'relationships.survey-import-messages',
        label: 'Import message(s)',
        options: {
            sort: false,
            display: (user && user.role && user.role === 'admin' ? true : false), // Only show for admins
            customBodyRender: (value) => {
                if (value && value.length) {
                    const infos = value.filter(m => m.data.level === 'info');
                    const warnings = value.filter(m => m.data.level === 'warning');
                    const errors = value.filter(m => m.data.level === 'error');
                    const content = [];
                    if (infos.length) {
                        content.push(<span key="info" className={classes.info} style={{whiteSpace: 'nowrap'}}>Info: {infos.length}</span>)
                    }
                    if (warnings.length) {
                        content.push(<span key="warn" className={classes.warning} style={{whiteSpace: 'nowrap'}}>Warning: {warnings.length}</span>)
                    }
                    if (errors.length) {
                        content.push(<span key="err" className={classes.error} style={{whiteSpace: 'nowrap'}}>Error: {errors.length}</span>);
                    }
                    return <span style={{cursor: 'pointer'}} onClick={() => {setImportMessagesDialogOpen(true); setImportMessages(value);}}>{content}</span>;
                } else {
                    return null;
                }
            },
        }
    },
    {
        name: 'attributes.lastRespondentAt',
        label: intl.formatMessage({id: 'surveys.lastResponseColumn', defaultMessage: 'Last response'}),
        options: {
            customBodyRender: (value) => {
                if (value) {
                    // DD MMM YYYY, i.e. "01 Jan 2021"
                    return <span title={value}>{(new Date(value)).toLocaleDateString('en-gb', {year: 'numeric', month: 'short', day: 'numeric'})}</span>
                }
                return '';
            }
        }
    },
    {
        name: 'id',
        label: ' ',
        options: {
            sort: false,
            display: (user && user.role && user.role === 'admin' ? true : false),
            customBodyRender: (value, tableMeta) => (
                <Fragment>
                    {(user && user.role === 'admin') ?
                        <Tooltip title={(intl.formatMessage({id: 'generic.delete', defaultMessage: 'Delete'}))}>
                            <IconButton className={classes.rowButton} color="secondary" onClick={() => {setDeleteUuid(value); setDeleteDialogOpen(true)}}>
                                <DeleteIcon fontSize="small" />
                            </IconButton>
                        </Tooltip>
                    : null }
                </Fragment>
            ),
            setCellProps: () => ({
                align: 'right'
            }),
        }
    },
];

    const options = {
        textLabels: {
            body: {
                noMatch: isLoading ? '...' : 'No data...',
            }
        },
        pagination: tableData.count > 10, // Only show pagination if total is above pagination limit
        tableBodyHeight: 'auto',
        search: false,
        sort: false,
        enableNestedDataAccess: '.',
        selectableRows: 'none',
        filter: false,
        download: false,
        print: false,
        viewColumns: false,
        elevation: 0,
        serverSide: true,
        count: tableData.count,
        rowsPerPage: tableData.rowsPerPage,
        rowsPerPageOptions: [],
        onTableChange: (action, tableState) => {

            switch (action) {
                case 'changePage':
                    fetchSurveys(tableState.page, tableState.rowsPerPage);
                    break;

                case 'propsUpdate':
                    if (forceRefresh) {
                        setForceRefresh(false, fetchSurveys(tableState.page, tableState.rowsPerPage));
                    }
                    break;

                default:
                    console.log(action, 'action not handled.');
            }
        },
    };

    const fetchSurveys = (page, pageSize) => {
        console.log('FETCH#', page, pageSize);

        setLoading(true);
        SurveyService.surveyRevisionsByHash(hash, page*pageSize, pageSize)
        .then(function (response) {
            setTableData({
                data: response.data.data,
                count: response.data.meta.count,
                rowsPerPage: 10,
            });
        }).catch(function (error) {
            updateNotification(true, intl.formatMessage({id: 'generic.unknownError', defaultMessage: 'An unknown error occurred!'}), 'error');
        }).then(function() {
            setLoading(false);
        });
    };

    const fetchSurveysRef = useRef();
    fetchSurveysRef.current = (page, pageSize) => {
        fetchSurveys(page, pageSize);
    }

    useEffect(() => {
        fetchSurveysRef.current(0, tableData.rowsPerPage);
        updateTitleRef.current(null);
    }, [location.search, tableData.rowsPerPage]);

    const handleDelete = () => {
        // To avoid multiple submits
        if (!deleteDialogOpen) {
            return;
        }
        setDeleteDialogOpen(false);
        updateLoading(true);
        setLoading(true);

        SurveyService.delete(deleteUuid)
        .then(function (response) {
            setDeleteUuid(null);
            setForceRefresh(true);
        }).catch(function (error) {
            if (error.response && error.response.status) {
                if (error.response.data.error.detail) {
                    updateNotification(true, error.response.data.error.detail, 'error');
                } else {
                    updateNotification(true, intl.formatMessage({id: 'generic.unknownError', defaultMessage: 'An unknown error occurred!'}), 'error');
                }
            } else {
                updateNotification(true, intl.formatMessage({id: 'generic.unknownError', defaultMessage: 'An unknown error occurred!'}), 'error');
            }
            console.error(error);
        }).then(function () {
            updateLoading(false);
            setLoading(false);
        });
    }

    return (
        <Fragment>
            <Grid container spacing={0} justifyContent="center">
                <Grid item sm={12}>
                    <LocalBackdrop open={isLoading} transitionDuration={600}>
                        <CircularProgress color="secondary" size={40} />
                    </LocalBackdrop>
                    <MUIDataTable
                        title={<Typography variant="body1">Import history</Typography>}
                        data={tableData.data}
                        columns={columns}
                        options={options}
                    />
                </Grid>
            </Grid>
            <Dialog
                open={deleteDialogOpen}
                onClose={() => {setDeleteDialogOpen(false)}}
            >
                <DialogTitle><FormattedMessage id="sites.delete" defaultMessage="Delete" /></DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <span>
                            This will delete this version of the survey as well as ALL associated response and benchmark data, continue?<br />
                            <br />
                            <strong>NOTE:</strong> the deletion process can take a few minutes, depending on the size of the survey.
                        </span>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={() => {setDeleteDialogOpen(false)}} color="primary" autoFocus>
                        <FormattedMessage id="generic.cancel" defaultMessage="Cancel" />
                    </Button>
                    <Button variant="contained" onClick={handleDelete} color="secondary">
                        <FormattedMessage id="generic.confirm" defaultMessage="Confirm" />
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={importMessagesDialogOpen}
                onClose={() => {setImportMessagesDialogOpen(false); setImportMessages([]);}}
            >
                <DialogTitle>Import Message(s)</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {importMessages.map(im => {
                            if (im.data.level === 'info') {
                                return <div key="info" className={classes.info}><strong>Info</strong>: {im.data.message}</div>
                            }
                            if (im.data.level === 'warning') {
                                return <div key="warning" className={classes.warning}><strong>Warning</strong>: {im.data.message}</div>
                            }
                            if (im.data.level === 'error') {
                                return <div key="error" className={classes.error}><strong>Error</strong>: {im.data.message}</div>
                            }
                            return null;
                        })}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={() => {setImportMessagesDialogOpen(false); setImportMessages([])}} color="primary" autoFocus>
                        <FormattedMessage id="generic.close" defaultMessage="Close" />
                    </Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    );
}
