import { Button, Grid, Typography } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import { Fragment } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { AppBarTitleContext } from "../../Context/AppBarTitleContext";
import { NotificationContext } from "../../Context/NotificationContext";
import { SpinnerContext } from "../../Context/SpinnerContext";
import SurveyService from "../../Services/SurveyService";

export default function SurveyRelaunch() {
    const { updateLoading } = useContext(SpinnerContext);
    const { updateNotification } = useContext(NotificationContext);
    const { updateTitle } = useContext(AppBarTitleContext);
    const intl = useIntl();
    const [values, setValues] = useState({
        uuid: null,
        token: null
    });
    const [message, setMessage] = useState(null);


    useEffect(() => {
        updateTitle(null);
        const query = new URLSearchParams(window.location.search);
        setValues({uuid: query.get('uuid'), token: query.get('token')});
        // eslint-disable-next-line
    }, []);

    const relaunch = () => {
        SurveyService.relaunch(values.uuid, values.token)
        .then(function (response) {
            updateNotification(true, intl.formatMessage({id: 'surveyRelaunch.relaunchSuccess', defaultMessage: 'Survey has been restarted'}), 'success');
            setMessage(<Fragment>
                <Typography variant="h5" paragraph>
                    <FormattedMessage id="surveyRelaunch.surveyRelaunchedHeader" defaultMessage="Survey Restarted" />
                </Typography>
                <Typography variant="body1" paragraph>
                    <FormattedMessage
                        id="surveyRelaunch.relaunchSuccessNotice"
                        defaultMessage="Thank you for restarting your survey. You will receive a welcome email when your survey has been set up, this may take a few minutes!"
                    />
                </Typography>
            </Fragment>);
        }).catch(function (error) {
            if (error.response && error.response.status && 403 === error.response.status) {
                setMessage(<Fragment>
                    <Typography variant="h5" paragraph>
                        <FormattedMessage id="surveyRelaunch.failedTokenHeader" defaultMessage="Restarting Survey Failed" />
                    </Typography>
                    <Typography variant="body1" paragraph>
                        <FormattedMessage
                            id="surveyRelaunch.failedTokenNotice"
                            defaultMessage="If you have already restarted your survey, please check your inbox (including spam/junk folders) for an email from <em>noreply@culturexcellence.com</em>."
                            values = {{
                                em: chunks => <em>{chunks}</em>,
                            }}
                        />
                    </Typography>
                </Fragment>);
                updateNotification(true, intl.formatMessage({id: 'surveyRelaunch.failedTokenError', defaultMessage: 'Survey could not be restarted.'}), 'warning');
            } else {
                updateNotification(true, intl.formatMessage({id: 'generic.unknownError', defaultMessage: 'An unknown error occurred!'}), 'error');
            }
            console.error(error);
        }).then(function() {
            updateLoading(false);
        });
    }

    return (
        <Grid container spacing={3} justifyContent="center">
            <Grid item sm={8} md={4} lg={3}>
                {message ? message :
                    <Fragment>
                        <Typography variant="h5" paragraph>
                            <FormattedMessage id="surveyRelaunch.header" defaultMessage="Restart Survey?" />
                        </Typography>
                        <Typography variant="body1" paragraph>
                            <FormattedMessage id="surveyRelaunch.notice" defaultMessage="Click the button below to restart your survey." />
                        </Typography>
                        <Button onClick={relaunch} variant="contained" color="secondary" disabled={(!values.uuid || !values.token)}>
                            <FormattedMessage id="surveyRelaunch.button" defaultMessage="Restart survey" />
                        </Button>
                    </Fragment>
                }
            </Grid>
        </Grid>
    );
}