import { Fragment } from "react";
import { FormattedMessage, useIntl } from "react-intl";

export default function IntegratedInitiatives(props) {
    const intl = useIntl();

    return (
        <Fragment>
            { props.title && props.print ? <h2 style={{fontWeight: 400}}>{props.title}</h2> : null}
            <p>
                <FormattedMessage id="execSummaryIntegInitiatives.p1"
                    defaultMessage={
                        "It is important to explore how more than one dimension or element can be integrated into a single "+
                        "initiative. This is especially the case for dimensions such as training and communication."
                    }
                />
            </p>

            <p>
                <FormattedMessage id="execSummaryIntegInitiatives.p2"
                    defaultMessage={
                        "For example, planning a series of communications on incidents and how to learn from them can improve "+
                        "multiple dimensions if planned and managed carefully. By using examples from within the company and "+
                        "externally, and taking the opportunity to thank people involved for their responsiveness, at least 4 "+
                        "dimensions can be positively impacted:"
                    }
                />
            </p>

            <ul>
                <li>{intl.formatMessage({id: 'execSummaryIntegInitiatives.l1' , defaultMessage: 'Communication (frequency),'})}</li>
                <li>{intl.formatMessage({id: 'execSummaryIntegInitiatives.l2' , defaultMessage: 'Organisational learning (learning from internal mistakes),'})}</li>
                <li>{intl.formatMessage({id: 'execSummaryIntegInitiatives.l3' , defaultMessage: 'Reward (praising people for identifying problems) for internal examples,'})}</li>
                <li>{intl.formatMessage({id: 'execSummaryIntegInitiatives.l4' , defaultMessage: 'External awareness (learning from external events) for external examples.'})}</li>
            </ul>
        </Fragment>
    );
}
