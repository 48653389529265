import { Table, TableBody, TableHead, TableCell, TableContainer, TableRow } from "@material-ui/core";
import { Fragment } from "react";
import NoData from "../../NoData/NoData";
import Decimal from 'decimal.js';
import { FormattedMessage } from "react-intl";
import DemographicOptionLabel from "../../Translation/DemographicOptionLabel";

export default function Demographics(props) {
    if (!props.reportData.data) {
        return null;
    }

    if (Array.isArray(props.reportData.data) && 0 === props.reportData.data.length) {
        return <NoData />;
    }

    // Sort the options as they are in the filter bar
    const sortedData = {};
    props.demographicQuestions.forEach((question) => {
        sortedData[question.attributes.demographic] = [];
        question.attributes.options.forEach((option) => {
            if (option.attributes.value in props.reportData.data.demographics[question.attributes.demographic]) {
                sortedData[question.attributes.demographic].push({
                    option: <DemographicOptionLabel option={option.attributes.title} translations={option.attributes.translations} demographic={question.attributes.demographic} demographicOptionLabels={props.demographicOptionLabels}   />,
                    responses: props.reportData.data.demographics[question.attributes.demographic][option.attributes.value]
                });
            }
        });
    });

    const bottomRowStyle = {backgroundColor: '#e8e8e8', borderBottom: '6px solid white'};
    let totalEmployees = null;
    let targetResponses = null;
    let percentOfTotalEmployees = null;
    let percentOfTargetEmployees = null;
    if (props.reportData.data.targets) {
        Object.keys(props.reportData.data.targets).forEach(siteValue => {
            if (props.reportData.data.targets[siteValue].roles) {
                Object.keys(props.reportData.data.targets[siteValue].roles).forEach(roleValue => {
                    totalEmployees = (totalEmployees ? totalEmployees : 0) + props.reportData.data.targets[siteValue].roles[roleValue].total;
                    targetResponses = (targetResponses ? targetResponses : 0) + props.reportData.data.targets[siteValue].roles[roleValue].target;
                })
            } else if (props.reportData.data.targets[siteValue].total) {
                totalEmployees = (totalEmployees ? totalEmployees : 0) + props.reportData.data.targets[siteValue].total;
                targetResponses = (targetResponses ? targetResponses : 0) + props.reportData.data.targets[siteValue].target;
            }
        });
    }

    return (
        <TableContainer ref={props.setScreenshotRef}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>
                            <FormattedMessage id="generic.role" defaultMessage="Role" />
                        </TableCell>
                        <TableCell align="right">
                            <FormattedMessage id="generic.responses" defaultMessage="Responses" />
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {Object.keys(sortedData).map(demographic => {
                        if ('role' === demographic) {
                            const totalResponses = Object.values(props.reportData.data.demographics[demographic]).reduce(function(a, b) { return a + b });
                            if (totalResponses && totalEmployees) {
                                percentOfTotalEmployees = Decimal(totalResponses).dividedBy(Decimal(totalEmployees)).times(100).toFixed(2) + '%';
                            }
                            if (totalResponses && targetResponses) {
                                percentOfTargetEmployees = Decimal(totalResponses).dividedBy(Decimal(targetResponses)).times(100).toFixed(2) + '%';;
                            }

                            return (
                                <Fragment key={demographic}>
                                {Object.keys(sortedData[demographic]).map(val => (
                                    <TableRow key={val}>
                                        <TableCell>{sortedData[demographic][val].option}</TableCell>
                                        <TableCell align="right">{sortedData[demographic][val].responses}</TableCell>
                                    </TableRow>
                                ))}
                                <TableRow key="total" style={{...bottomRowStyle, borderBottom: '36px solid white'}}>
                                    <TableCell>
                                        <strong>
                                            <FormattedMessage id="demographics.totalResponses" defaultMessage="Total responses" />
                                        </strong>
                                    </TableCell>
                                    <TableCell align="right">
                                        <strong>
                                            {totalResponses}
                                        </strong>
                                    </TableCell>
                                </TableRow>
                                <TableRow key="employees" style={bottomRowStyle}>
                                    <TableCell>
                                        <strong>
                                            <FormattedMessage id="demographics.totalEmployees" defaultMessage="Total employees" />
                                        </strong>
                                    </TableCell>
                                    <TableCell align="right">
                                        <strong>
                                            {totalEmployees ? totalEmployees : '-'}
                                        </strong>
                                    </TableCell>
                                </TableRow>
                                <TableRow key="target" style={bottomRowStyle}>
                                    <TableCell>
                                        <strong>
                                            <FormattedMessage id="demographics.targetResponses" defaultMessage="Target responses" />
                                        </strong>
                                    </TableCell>
                                    <TableCell align="right">
                                        <strong>
                                            {targetResponses ? targetResponses : '-'}
                                        </strong>
                                    </TableCell>
                                </TableRow>
                                <TableRow key="pTotal" style={bottomRowStyle}>
                                    <TableCell>
                                        <strong>
                                            <FormattedMessage id="demographics.percentOfTotal" defaultMessage="% of total employees" />
                                        </strong>
                                    </TableCell>
                                    <TableCell align="right">
                                        <strong>
                                            {percentOfTotalEmployees ? percentOfTotalEmployees : '-'}
                                        </strong>
                                    </TableCell>
                                </TableRow>
                                <TableRow key="pTarget" style={bottomRowStyle}>
                                    <TableCell>
                                        <strong>
                                            <FormattedMessage id="demographics.percentOfTarget" defaultMessage="% of target employees" />
                                        </strong>
                                    </TableCell>
                                    <TableCell align="right">
                                        <strong>
                                            {percentOfTargetEmployees ? percentOfTargetEmployees : '-'}
                                        </strong>
                                    </TableCell>
                                </TableRow>
                            </Fragment>
                            );
                        } else {
                            return null;
                        }
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

