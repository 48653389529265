import { useContext, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { NotificationContext } from "../../../Context/NotificationContext";
import { SpinnerContext } from "../../../Context/SpinnerContext";
import SurveyService from "../../../Services/SurveyService";

export default function ExecutiveReportBrcgs(props) {
    const intl = useIntl();
    const { updateNotification } = useContext(NotificationContext);
    const { updateLoading } = useContext(SpinnerContext);
    const [pdfData, setPdfData] = useState(null);

    useEffect(() => {
        updateLoading(true);
        // TODO: Cancel request stuff
        SurveyService.surveyStaticReport(props.surveyData.id, 'pdf')
        .then(function (response) {
            setPdfData(window.URL.createObjectURL(response.data));
        }).catch(function (error) {
            console.log(error);
            updateNotification(true, intl.formatMessage({id: 'generic.unknownError', defaultMessage: 'An unknown error occurred!'}), 'error');
        }).then(function() {
            updateLoading(false);
        });
        // eslint-disable-next-line
    }, []);

    if (null === pdfData) {
        return null;
    }

    return (
        <object data={pdfData} type="application/pdf" style={{width: '100%', height: '90vh'}} aria-label="PDF report"></object>
    );
}