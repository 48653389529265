export const industryTypeTranslated = (industry, intl) => {
    switch (industry) {

        case 'Best in class (top 10%)':
            return intl.formatMessage({id: 'industry.bestInClass', defaultMessage: 'Best in class (top 10%)'});

        case 'Agents and brokers':
            return intl.formatMessage({id: 'industry.agentsAndBrokers', defaultMessage: 'Agents and brokers'});

        case 'Alcoholic drinks and fermented/brewed products':
            return intl.formatMessage({id: 'industry.alcoholic', defaultMessage: 'Alcoholic drinks and fermented/brewed products'});

        case 'Ambient foods (e.g. sauces)':
            return intl.formatMessage({id: 'industry.ambient', defaultMessage: 'Ambient foods (e.g. sauces)'});

        case 'Bakery':
            return intl.formatMessage({id: 'industry.bakery', defaultMessage: 'Bakery'});

        case 'Beverages':
            return intl.formatMessage({id: 'industry.beverages', defaultMessage: 'Beverages'});

        case 'Catering and food service':
            return intl.formatMessage({id: 'industry.catering', defaultMessage: 'Catering and food service'});

        case 'Cereals and snacks':
            return intl.formatMessage({id: 'industry.cerealsAndSnacks', defaultMessage: 'Cereals and snacks'});

        case 'Confectionery':
            return intl.formatMessage({id: 'industry.confectionery', defaultMessage: 'Confectionery'});

        case 'Cooked meat/fish products':
            return intl.formatMessage({id: 'industry.cookedMeatFish', defaultMessage: 'Cooked meat/fish products'});

        case 'Dairy, liquid egg':
            return intl.formatMessage({id: 'industry.dairyLiquidEgg', defaultMessage: 'Dairy, liquid egg'});

        case 'Dried foods and ingredients':
            return intl.formatMessage({id: 'industry.driedFoodsIngredients', defaultMessage: 'Dried foods and ingredients'});

        case 'Equipment':
            return intl.formatMessage({id: 'industry.equipment', defaultMessage: 'Equipment'});

        case 'Farming of animals, fish, fruits and vegetables':
            return intl.formatMessage({id: 'industry.farming', defaultMessage: 'Farming of animals, fish, fruits and vegetables'});

        case 'Feed production':
            return intl.formatMessage({id: 'industry.feedProduction', defaultMessage: 'Feed production'});

        case 'Fruit, vegetables and nuts':
            return intl.formatMessage({id: 'industry.fruitVegNuts', defaultMessage: 'Fruit, vegetables and nuts'});

        case 'Fruit juices and smoothies (pasteurised)':
            return intl.formatMessage({id: 'industry.fruitJuicesSmoothiesPast', defaultMessage: 'Fruit juices and smoothies (pasteurised)'});

        case 'Government / ministry':
            return intl.formatMessage({id: 'industry.govMinistry', defaultMessage: 'Government / ministry'});

        case 'Head office / corporate / ancillary':
            return intl.formatMessage({id: 'industry.headOfficeCorpAncillary', defaultMessage: 'Head office / corporate / ancillary'});

        case 'Home care':
            return intl.formatMessage({id: 'industry.homeCare', defaultMessage: 'Home care'});

        case 'Logistics':
            return intl.formatMessage({id: 'industry.logistics', defaultMessage: 'Logistics'});

        case 'Low/high acid in cans/glass/plastic containers':
            return intl.formatMessage({id: 'industry.acidInContainers', defaultMessage: 'Low/high acid in cans/glass/plastic containers'});

        case 'Non-governmental organisation (NGO)':
            return intl.formatMessage({id: 'industry.ngo', defaultMessage: 'Non-governmental organisation (NGO)'});

        case 'Oils and fats':
            return intl.formatMessage({id: 'industry.oilsFats', defaultMessage: 'Oils and fats'});

        case 'Other':
            return intl.formatMessage({id: 'industry.other', defaultMessage: 'Other'});

        case 'Packaging':
            return intl.formatMessage({id: 'industry.packaging', defaultMessage: 'Packaging'});

        case 'Packing':
            return intl.formatMessage({id: 'industry.packing', defaultMessage: 'Packing'});

        case 'Personal care':
            return intl.formatMessage({id: 'industry.personalCare', defaultMessage: 'Personal care'});

        case 'Prepared fruit, vegetables and nuts':
            return intl.formatMessage({id: 'industry.preparedFruitVegNuts', defaultMessage: 'Prepared fruit, vegetables and nuts'});

        case 'Raw cured and/or fermented meat and fish':
            return intl.formatMessage({id: 'industry.rawCuredFermMeatFish', defaultMessage: 'Raw cured and/or fermented meat and fish'});

        case 'Raw fish products and preparations':
            return intl.formatMessage({id: 'industry.rawFishProdPrep', defaultMessage: 'Raw fish products and preparations'});

        case 'Raw poultry':
            return intl.formatMessage({id: 'industry.rawPoultry', defaultMessage: 'Raw poultry'});

        case 'Raw prepared products (meat and vegetarian)':
            return intl.formatMessage({id: 'industry.rawPrepProducts', defaultMessage: 'Raw prepared products (meat and vegetarian)'});

        case 'Raw red meat':
            return intl.formatMessage({id: 'industry.rawRedMeat', defaultMessage: 'Raw red meat'});

        case 'Ready meals and sandwiches; ready-to-eat desserts':
            return intl.formatMessage({id: 'industry.readyMeals', defaultMessage: 'Ready meals and sandwiches; ready-to-eat desserts'});

        case 'Retail':
            return intl.formatMessage({id: 'industry.retail', defaultMessage: 'Retail'});

        case 'Slaughtering and primal cutting':
            return intl.formatMessage({id: 'industry.slaughteringCutting', defaultMessage: 'Slaughtering and primal cutting'});

        case 'Storage and distribution':
            return intl.formatMessage({id: 'industry.storageDistr', defaultMessage: 'Storage and distribution'});

        case 'Trade association':
            return intl.formatMessage({id: 'industry.tradeAssociation', defaultMessage: 'Trade association'});

        default:
            // Unknown industry
            return industry;
    }
}
