import { Fragment } from "react"
import { FormattedMessage } from "react-intl";
import DimensionLabel from "../../../Translation/DimensionLabel";
import ChangeDimension from "./DiscussionsAndRecomendations/ChangeDimension";
import ReinforcementDimension from "./DiscussionsAndRecomendations/ReinforcementDimension";
import EmpowermentDimension from "./DiscussionsAndRecomendations/EmpowermentDimension";
import TeamworkDimension from "./DiscussionsAndRecomendations/TeamworkDimension";
import TrainingDimension from "./DiscussionsAndRecomendations/TrainingDimension";
import CommunicationDimension from "./DiscussionsAndRecomendations/CommunicationDimension";
import ControlDimension from "./DiscussionsAndRecomendations/ControlDimension";
import CoordinationDimension from "./DiscussionsAndRecomendations/CoordinationDimension";
import ConsistencyDimension from "./DiscussionsAndRecomendations/ConsistencyDimension";
import SystemsDimension from "./DiscussionsAndRecomendations/SystemsDimension";
import PremisesDimension from "./DiscussionsAndRecomendations/PremisesDimension";
import VisionDimension from "./DiscussionsAndRecomendations/VisionDimension";
import ValuesDimension from "./DiscussionsAndRecomendations/ValuesDimension";
import StrategyDimension from "./DiscussionsAndRecomendations/StrategyDimension";
import ObjectivesDimension from "./DiscussionsAndRecomendations/ObjectivesDimension";
import MetricsDimension from "./DiscussionsAndRecomendations/MetricsDimension";
import AwarenessDimension from "./DiscussionsAndRecomendations/AwarenessDimension";
import ForesightDimension from "./DiscussionsAndRecomendations/ForesightDimension";
import LearningDimension from "./DiscussionsAndRecomendations/LearningDimension";
import InvestmentDimension from "./DiscussionsAndRecomendations/InvestmentDimension";


export default function DiscussionsAndRecommendations(props) {
    const dimensionComponent = (dim) => {
        switch(dim) {
            case 'empowerment':
                return EmpowermentDimension;

            case 'reinforcement':
                return ReinforcementDimension;

            case 'teamwork':
                return TeamworkDimension;

            case 'training':
                return TrainingDimension;

            case 'communication':
                return CommunicationDimension;

            case 'control':
                return ControlDimension;

            case 'coordination':
                return CoordinationDimension;

            case 'consistency':
                return ConsistencyDimension;

            case 'systems':
                return SystemsDimension;

            case 'premises':
                return PremisesDimension

            case 'vision':
                return VisionDimension;

            case 'values':
                return ValuesDimension;

            case 'strategy':
                return StrategyDimension;

            case 'objectives':
                return ObjectivesDimension;

            case 'metrics':
                return MetricsDimension;

            case 'awareness':
                return AwarenessDimension;

            case 'foresight':
                return ForesightDimension;

            case 'change':
                return ChangeDimension;

            case 'learning':
                return LearningDimension;

            case 'investment':
                return InvestmentDimension;

            default:
                return null;
        }
    };

    if (!props.reportData['basic-data'].dimensions || !props.reportData['elements']) {
        return null;
    }

    const lowestDimensions = Object.keys(props.reportData['basic-data'].dimensions).map(d => {
        return {
            label: <DimensionLabel dimension={d} />,
            dimension: d,
            grade: props.reportData['basic-data'].dimensions[d].grade,
            score: props.reportData['basic-data'].dimensions[d].score,
            roundedScore: props.reportData['basic-data'].dimensions[d].roundedScore,
        }
    });
    lowestDimensions.sort((a, b) => parseFloat(a.score) > parseFloat(b.score) ? 1 : -1);

    return (<Fragment>
        { props.title && props.print ? <h2 style={{fontWeight: 400}}>{props.title}</h2> : null}
        <p>
            <FormattedMessage
                id="execSummaryDiscAndRecs.p1"
                defaultMessage={
                    "Typically, the lowest dimensions are a useful starting point when deciding which areas to focus on for "+
                    "improvement. They should be cross-checked with data from elements, hotspots and barriers to gain a "+
                    "fuller picture, and this data can be used to form SMART culture action plans (i.e. those that are "+
                    "specific, measurable, achievable, relevant and time-bound). To assist in this process, the three lowest "+
                    "dimensions are discussed below."
                }
            />
        </p>
        {lowestDimensions.filter((currentDim) => {return currentDim.score !== null;}).slice(0,3).map((dim, i) => {
            const DimensionComponent = dimensionComponent(dim.dimension);
            return (<Fragment key={i}>
                    <h3 style={{fontWeight: 500}}>{i+1}. {dim.label}</h3>
                    {DimensionComponent !== null ? <DimensionComponent dimension={dim.dimension} elements={props.reportData['elements'].slice().reverse()} lowestElements={props.reportData['element-hotspots-lowest']} /> : null}
                </Fragment>
            );
        })}
    </Fragment>);
}
