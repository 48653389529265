import { Button, Paper, Box, Grid, Typography } from "@material-ui/core";
import { Fragment, useContext, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { SSAFE_CATEGORY_COLORS } from "../../Constants/SsafeCategoryColors";
import { AppBarTitleContext } from "../../Context/AppBarTitleContext";

export default function Ssafe() {
    const { updateTitle } = useContext(AppBarTitleContext);

    useEffect(() => {
        updateTitle(null);
    });

    return(
        <Fragment>
            <Grid container spacing={0}>
                <Grid item sm={false} lg={3} />
                <Grid item sm={12} lg={6}>
                    <Grid container spacing={1} justifyContent="center" direction="column" alignItems="stretch">
                        <Grid item sm={12}>
                            <Paper variant="outlined" style={{ borderColor: SSAFE_CATEGORY_COLORS['people'].light, backgroundColor: SSAFE_CATEGORY_COLORS['people'].dark, color: 'white'}} pb={2}>
                                <Box p={1} textAlign='center'>
                                    <Typography variant="subtitle1" align="center">
                                        <FormattedMessage id="ssafeUpgrade.header" defaultMessage="The Culture Excellence Assessment provides a full assessment of a site’s food safety culture. It includes the following:" />
                                    </Typography>
                                </Box>
                            </Paper>
                        </Grid>

                        <Grid item sm={12}>
                            <Paper variant="outlined" style={{borderColor: SSAFE_CATEGORY_COLORS['people'].dark, backgroundColor: SSAFE_CATEGORY_COLORS['people'].light}} pb={2}>
                                <Box p={1} textAlign='center'>
                                    <Typography variant="subtitle1" align="center">
                                        <FormattedMessage id="ssafeUpgrade.categoryScores" defaultMessage="Scores for all Categories of culture (People, Process, Purpose and Proactivity)" />
                                    </Typography>
                                </Box>
                            </Paper>
                        </Grid>

                        <Grid item sm={12}>
                            <Paper variant="outlined" style={{borderColor: SSAFE_CATEGORY_COLORS['process'].dark, backgroundColor: SSAFE_CATEGORY_COLORS['process'].light}} pb={2}>
                                <Box p={1} textAlign='center'>
                                    <Typography variant="subtitle1" align="center">
                                        <FormattedMessage id="ssafeUpgrade.dimensions" defaultMessage="Scores for 20 Dimensions" />
                                    </Typography>
                                </Box>
                            </Paper>
                        </Grid>

                        <Grid item sm={12}>
                            <Paper variant="outlined" style={{borderColor: SSAFE_CATEGORY_COLORS['people'].dark, backgroundColor: SSAFE_CATEGORY_COLORS['people'].light}} pb={2}>
                                <Box p={1} textAlign='center'>
                                    <Typography variant="subtitle1" align="center">
                                        <FormattedMessage id="ssafeUpgrade.questions" defaultMessage="Scores for 60+ questions" />
                                    </Typography>
                                </Box>
                            </Paper>
                        </Grid>

                        <Grid item sm={12} style={{width: '100%'}}>
                            <Paper variant="outlined" style={{borderColor: SSAFE_CATEGORY_COLORS['process'].dark, backgroundColor: SSAFE_CATEGORY_COLORS['process'].light}} pb={2}>
                                <Box p={1} textAlign='center'>
                                    <Typography variant="subtitle1" align="center">
                                        <FormattedMessage id="ssafeUpgrade.industry" defaultMessage="Industry and activity benchmarking for workplace culture score and grade" />
                                    </Typography>
                                </Box>
                            </Paper>
                        </Grid>

                        <Grid item sm={12}>
                            <Paper variant="outlined" style={{borderColor: SSAFE_CATEGORY_COLORS['people'].dark, backgroundColor: SSAFE_CATEGORY_COLORS['people'].light}} pb={2}>
                                <Box p={1} textAlign='center'>
                                    <Typography variant="subtitle1" align="center">
                                        <FormattedMessage id="ssafeUpgrade.priority" defaultMessage="Priority and priority conflicts" />
                                    </Typography>
                                </Box>
                            </Paper>
                        </Grid>

                        <Grid item sm={12} style={{width: '100%'}}>
                            <Paper variant="outlined" style={{borderColor: SSAFE_CATEGORY_COLORS['process'].dark, backgroundColor: SSAFE_CATEGORY_COLORS['process'].light}} pb={2}>
                                <Box p={1} textAlign='center'>
                                    <Typography variant="subtitle1" align="center">
                                        <FormattedMessage id="ssafeUpgrade.barriersPositives" defaultMessage="Perceived barriers and positives of culture" />
                                    </Typography>
                                </Box>
                            </Paper>
                        </Grid>

                        <Grid item sm={12}>
                            <Paper variant="outlined" style={{borderColor: SSAFE_CATEGORY_COLORS['people'].dark, backgroundColor: SSAFE_CATEGORY_COLORS['people'].light}} pb={2}>
                                <Box p={1} textAlign='center'>
                                    <Typography variant="subtitle1" align="center">
                                        <FormattedMessage id="ssafeUpgrade.dataPoints" defaultMessage="500+ data points overall" />
                                    </Typography>
                                </Box>
                            </Paper>
                        </Grid>

                        <Grid container direction="row" justifyContent="center" alignItems="center" spacing={0}>
                            <Grid item sm />
                            <Grid item sm={12} lg={6} style={{flexBasis: '100%'}}>
                                <Grid item xs={12} align="center">
                                    <Box p={1} textAlign="center">
                                        <Button fullWidth rel="noreferrer" target="_blank" variant="contained" color="primary" href={"https://www.culturexcellence.com/pricing-ssafe"} style={{ backgroundColor: SSAFE_CATEGORY_COLORS['people'].dark}}>
                                            <FormattedMessage id="ssafeUpgrade.learnMore" defaultMessage="Learn more about the full Culture Excellence assessment" />
                                        </Button>
                                    </Box>
                                </Grid>
                            </Grid>
                            <Grid item sm />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item sm={false} lg={3} />
            </Grid>
            <Box pb={2}></Box>
        </Fragment>
    );
}
