import { Box, Button, Card, CardActions, CardContent, CardHeader, Typography } from "@material-ui/core";
import { useContext } from "react";
import { SpinnerContext } from "../../Context/SpinnerContext";
import CustomReportService from "../../Services/CustomReportService";
import { NotificationContext } from "../../Context/NotificationContext";

export default function Reports(props) {
    const { updateLoading } = useContext(SpinnerContext);
    const { updateNotification } = useContext(NotificationContext);

    const reports = {
        'brcgs-basic': [
            {title: 'BRCGS Basic Signups (raw)', subheader: 'All signups with status', description: '', type: 'brcgs-basic-signups', filename: 'brcgs-basic-signups-%s.csv'},
            {title: 'BRCGS Basic Signups (no duplicates)', subheader: 'Signups with duplicates removed and simplified columns', description: '', type: 'brcgs-basic-signups-no-duplicates', filename: 'brcgs-basic-signups-no-duplicates-%s.csv'},
        ],
        'ssafe': [
            {title: 'SSAFE Signups (raw)', subheader: 'All signups with status', description: '', type: 'ssafe-signups', filename: 'ssafe-signups-%s.csv'},
        ],
        'sites': [
            {title: 'Sites', subheader: 'All sites with company, country and industry', description: '', type: 'sites', filename: 'sites-%s.csv'},
        ],
        'users': [
            {title: 'Users With All Sites & Surveys', subheader: 'Users that have both "all sites" and "all surveys" permissions', description: '', type: 'users-all-sites-all-surveys', filename: 'users-all-sites-and-all-surveys-%s.csv'},
            {title: 'Users With All Surveys', subheader: 'Users that have "all surveys" permission', description: '', type: 'users-all-surveys', filename: 'users-all-all-surveys-%s.csv'},

        ]
    };

    const downloadReport = (type, filename) => () => {
        updateLoading(true);
        CustomReportService.fetchReport(type)
        .then(function (response) {
            let url = window.URL.createObjectURL(response.data);
            let a = document.createElement('a');
            a.href = url;
            const date = new Date();
            a.download = -1 !== filename.indexOf('%s') ? (filename.replace('%s', date.getFullYear()+'-'+(date.getMonth()+1)+'-'+date.getDate()+'_'+date.getHours()+'-'+date.getMinutes())) : filename;
            a.click();
        }).catch(function (error) {
            console.log(error);
            updateNotification(true, 'An unknown error occurred!', 'error');
        }).then(function() {
            updateLoading(false);
        });
    };

    return (
        reports[props.type].map((rep, i) => (
            <Box pb={2} key={i}>
                <Card>
                    <CardHeader title={<Typography variant="h6">{rep.title}</Typography>} subheader={rep.subheader ? rep.subheader : null}/>
                    {rep.description ?
                        <CardContent>
                            <Typography variant="body2">{rep.description}</Typography>
                        </CardContent>
                    : null}
                    <CardActions>
                        <Button size="small" color="secondary" variant="outlined" onClick={downloadReport(rep.type, rep.filename)}>
                            Download (CSV)
                        </Button>
                    </CardActions>
                </Card>
            </Box>
        ))
    );
}