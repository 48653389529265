import { useIntl } from "react-intl";
import { dimensionTranslated } from "./Dimension";

export default function DimensionLabel(props) {
    const intl = useIntl();

    const label = props.upperCase ? dimensionTranslated(props.dimension, intl).toUpperCase() : (props.lowerCase ? dimensionTranslated(props.dimension, intl).toLowerCase() : dimensionTranslated(props.dimension, intl));
    return(
        <span style={-1 === label.indexOf(' ') ? {whiteSpace: 'nowrap'} : null}>{label}</span>
    );
}
