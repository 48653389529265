import axios from 'axios';
import BaseService from './BaseService';

class SiteReportService extends BaseService {
    stats() {
        return axios.get(this.apiUrl('/site-reports/stats'), {
            headers: this.getAuthHeader()
        });
    }
}

export default new SiteReportService();