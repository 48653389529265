import axios from 'axios';
import BaseService from './BaseService';

class SiteService extends BaseService {

    sites(offset = 0, limit = 50, sort = null, search = null, filter = null) {
        const params = {
            'page[offset]': offset,
            'page[limit]': limit,
            'search': search,
            'sort': sort,
        };

        if (filter && 0 !== Object.keys(filter).length) {
            Object.keys(filter).forEach(key => {
                params['filter['+key+']'] = filter[key];
            });
        }

        return axios.get(this.apiUrl('/sites'), {
            params: params,
            headers: this.getAuthHeader()
        });
    }

    async allSites(sort = null, search = null, filter = null, offset = 0) {
        const response = await this.sites(offset, 50, sort, search, filter);
        const data = response.data.data;
        if (response.data.links.next) {
            return data.concat(await this.allSites(sort, search, filter, offset+50));
        } else {
            return data;
        }
    }

    site(uuid) {
        return axios.get(this.apiUrl('/sites/'+uuid), {
            headers: this.getAuthHeader()
        });
    }

    delete(uuid) {
        return axios.delete(this.apiUrl('/sites/'+uuid), {
            headers: this.getAuthHeader()
        });
    }

    patch(uuid, data) {
        if ('code' in data && data.code === '') {
            data.code = null;
        }
        if ('countryCode' in data && data.countryCode === '') {
            data.countryCode = null;
        }
        if ('industry' in data && data.industry === '') {
            data.industry = null;
        }

        if ('totalTarget' in data && data.totalTarget === '') {
            data.totalTarget = null;
        }

        const nonEmptyRoleTargets = {};
        if ('roleTargets' in data) {
            Object.keys(data.roleTargets).forEach((role) => {
                if (null !== data.roleTargets[role] && '' !== data.roleTargets[role]) {
                    nonEmptyRoleTargets[role] = parseInt(data.roleTargets[role]);
                }
            });
            data.roleTargets = nonEmptyRoleTargets;
        }

        return axios.patch(this.apiUrl('/sites/'+uuid), {
            data: {
                type: 'site',
                attributes: data
            }
        },{
            headers: this.getAuthHeader()
        });
    }

    industries() {
        return axios.get(this.apiUrl('/sites/industries'), {
            headers: this.getAuthHeader()
        });
    }

    countries() {
        return axios.get(this.apiUrl('/sites/countries'), {
            headers: this.getAuthHeader()
        });
    }
}

export default new SiteService();
