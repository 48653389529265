import { Box, FormControlLabel, FormGroup, makeStyles, Tab, Tabs, Checkbox } from "@material-ui/core";
import { CATEGORY_COLORS } from '../../../Constants/CategoryColors';
import { BRCGS_CATEGORY_COLORS } from "../../../Constants/BrcgsCategoryColors";
import { useEffect, useRef, useState } from "react";
import { Fragment } from "react";
import { useIntl } from "react-intl";
import DemographicLabel from "../../Translation/DemographicLabel";
import { demographicOptionTranslated } from "../../Translation/DemographicOption";
import BarLazy from "../../Chart/BarLazy";

const useStyles = makeStyles(theme => ({
    tabs: {
        backgroundColor: theme.palette.primary.main,
        color: 'white'
    },
    label: {
        marginLeft: 0
    },
}));

export default function DemographicComparison(props) {
    const intl = useIntl();
    const classes = useStyles();
    const chartContainerRef = useRef(null);
    const [activeTab, setActiveTab] = useState(0);
    const [chartSize, setChartSize] = useState(null);
    const [showCategories, setShowCategories] = useState({
        people: true,
        process: true,
        purpose: true,
        proactivity: true,
        total: false
    });

    useEffect(() => {
        if (chartContainerRef.current) {
            setChartSize({
                height: chartContainerRef.current.offsetHeight,
                width: chartContainerRef.current.offsetWidth
            });
        }
     }, [chartContainerRef]);

    const toggleCategory = (category) => {
        setShowCategories(prevState => ({
            ...prevState,
            [category]: !prevState[category]
        }));
    };

    // Determine colors for report based on type
    const categoryColors = props.surveyData && props.surveyData.attributes && props.surveyData.attributes.type === 'brcgs' ? BRCGS_CATEGORY_COLORS : CATEGORY_COLORS;

    const categories = {
        people: {
            background: categoryColors.people.dark,
            border: categoryColors.people.light,
            label: intl.formatMessage({id: 'generic.peopleCategory', defaultMessage: 'People'})

        },
        process: {
            background: categoryColors.process.dark,
            border: categoryColors.process.light,
            label: intl.formatMessage({id: 'generic.processCategory', defaultMessage: 'Process'})

        },
        purpose: {
            background: categoryColors.purpose.dark,
            border: categoryColors.purpose.light,
            label: intl.formatMessage({id: 'generic.purposeCategory', defaultMessage: 'Purpose'})
        },
        proactivity: {
            background: categoryColors.proactivity.dark,
            border: categoryColors.proactivity.light,
            label: intl.formatMessage({id: 'generic.proactivityCategory', defaultMessage: 'Proactivity'})
        },
        total: {
            background: '#ffd600',
            border: '#fcf6d7',
            label: intl.formatMessage({id: 'generic.total', defaultMessage: 'Total'})
        },
    };

    if (!props.reportData.data) {
        return null;
    }

    const optionLabel = (demographic, value) => {
        let label = value;
        const demQ = props.demographicQuestions.find(q => q.attributes.demographic === demographic);
        if (!demQ) {
            return label;
        }

        const option = demQ.attributes.options.find(o => o.attributes.value === value);
        if (option) {
            label = demographicOptionTranslated(
                option.attributes.title,
                option.attributes.translations,
                intl,
                demographic,
                props.demographicOptionLabels
            );
        }

        // Split on stuff in parenthesis or very long titles
        if (-1 !== label.indexOf('(')) {
            label = label.replace('(', '\n(').split('\n');
        } else if (label.length > 40) {
            const spaces = [...label.matchAll(/\s/g)].map(m => m.index);
            if (spaces.length === 1) {
                label = [label.substring(0, spaces[0]), label.substring(spaces[0]+1)];
            } else {
                let distanceToCenter = label.length;
                for (let i = 0; i < spaces.length; i++) {
                    const curDistance = Math.abs((label.length / 2) - spaces[i]);
                    const betterMatch = curDistance < distanceToCenter;
                    if (betterMatch) {
                        distanceToCenter = curDistance;
                    }

                    if (i === spaces.length-1 || !betterMatch) {
                        // BREAK STRING
                        label = [label.substring(0, spaces[i-1]), label.substring(spaces[i-1]+1)];
                        break;
                    }
                }
            }
        }
        return label;
    };

    const chartData = {};

    Object.keys(props.reportData.data).forEach(demographic => {
        chartData[demographic] = {
            labels: [],
            datasets: []
        };

        Object.keys(categories).forEach(cat => {
            chartData[demographic].datasets.push({
                label: categories[cat].label,
                backgroundColor: categories[cat].background,
                borderColor: categories[cat].border,
                data: [],
                hidden: !showCategories[cat],
                maxBarThickness: 35,
            });
        });

        Object.keys(props.reportData.data[demographic]).forEach(current => {
            chartData[demographic].labels.push(optionLabel(demographic, current));
            Object.keys(categories).forEach((cat, index) => {
                if ('total' !== cat) {
                    chartData[demographic].datasets[index].data.push(props.reportData.data[demographic][current].categories[cat].roundedScore);
                } else {
                    chartData[demographic].datasets[index].data.push(props.reportData.data[demographic][current].roundedScore);
                }
            });
        })
    });

    const chartOptions = {
        maintainAspectRatio: false,
        responsive: true,
        events: ['click'],
        scales: {
            x: {
                title: {
                    display: true,
                    text: intl.formatMessage({id: 'generic.scorePercent', defaultMessage: 'Score (%)'})
                },
                min: 0,
                max: 104,
                ticks: {
                    stepSize: 10,
                    callback: value => value % 10 === 0 ? value : '',
                },
            },
        },
        indexAxis: 'y',
        animation: false,
        hover: {mode: null},
        plugins: {
            tooltip: {
                enabled: false,
            },
            legend: {
                display: false,
            },
            datalabels: {
                anchor: 'end',
                align: 'end',
                clamp: true,
                offset: 5
            }
        },
    };

    return (
        <Fragment>
            <Tabs value={activeTab} onChange={(event, newValue) => setActiveTab(newValue)} variant="fullWidth" className={classes.tabs}>
                {Object.keys(props.reportData.data).map((demographic, index) => (
                    <Tab label={<DemographicLabel demographic={demographic} />} key={index} />
                ))}
            </Tabs>
            <div ref={props.setScreenshotRef}>
                <Box pt={1}>
                    <FormGroup row style={{justifyContent: 'center'}}>
                        {Object.keys(categories).map(cat => (
                            <FormControlLabel
                                key={cat}
                                className={classes.label}
                                label={categories[cat].label}
                                style={{backgroundColor: categories[cat].border, borderLeft: '5px solid '+categories[cat].background, paddingRight: '10px'}}
                                control={
                                    <Checkbox
                                        color="primary"
                                        style={{ padding: '5px' }}
                                        checked={showCategories[cat]}
                                        onChange={() => toggleCategory(cat)}
                                    />
                                }
                            />
                        ))}
                    </FormGroup>
                </Box>
                {Object.keys(props.reportData.data).map((demographic, index) => {
                    if (index !== activeTab) {
                        return null;
                    }

                    const chartElementCount = Object.keys(props.reportData.data[demographic]).length;
                    return (
                        <Box key={index} ref={chartContainerRef} style={{width: '100%'}}>
                            {null !== chartSize ? <BarLazy data={chartData[demographic]} options={chartOptions} dataLabels={true} style={{height: (chartElementCount*120)+(chartElementCount < 2 ? 50 : 0)+'px', width: chartSize.width}}/> : null}
                        </Box>
                    );
                })}
            </div>
        </Fragment>
    );
}
