import { Box, Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { Fragment } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { CATEGORY_COLORS } from "../../../../Constants/CategoryColors";
import CategoryLabel from "../../../Translation/CategoryLabel";
import ElementLabel from "../../../Translation/ElementLabel";
import { riskTranslated } from "../../../Translation/Risk";
import RisksReport from "../Risks";


export default function Risks(props) {
    const intl = useIntl();

    const smallColStyle = {
        color: 'white',
        textAlign: 'center',
        whiteSpace: 'normal',
        paddingTop: '1px',
        paddingBottom: '1px',
        border: '1px solid #e0e0e0',
        height: '40px'
    };

    const containsAllWords = (str, words) => {
        for (const word of words) {
            if (str.indexOf(word) === -1) {
                return false;
            }
        }
        return true;
    };

    const filteredElements = props.reportData['elements']
        .filter(ele => {
            const name = ele.shortName.toLowerCase();
            return (
                containsAllWords(name, 'food safety risk seriously'.split(' ')) ||
                containsAllWords(name, 'knowledge of food safety risks'.split(' '))
            );
        })
        .sort((a, b) => b.score - a.score);

    const risks = [];
    for (const key in props.reportData['risks']) {

        const highRisk = props.reportData['risks'][key].find(riskType => riskType.answer.toLowerCase() === 'high risk');

        risks.push({
            label: props.reportData['risks'][key][0].title,
            highRisk: highRisk ? highRisk : {answer: 'High risk', count: 0, percentage: 0, title: ''}, // Fake empty high risk for sorting (if there is none)
            ...props.reportData['risks'][key],
        });
    }

    // Sort risks by high risk from highest to lowest count
    risks.sort((a, b) => b.highRisk.count - a.highRisk.count);
    const tableStyle = {padding: 6, fontSize: '9pt', lineHeight: 1.3};

    return (
        <Fragment>
            { props.title && props.print ? <h2 style={{fontWeight: 400}}>{props.title}</h2> : null}
            { -1 === props.surveyData.attributes.questionTypes.indexOf('foodsafety') || risks.length === 0 || filteredElements.length === 0 ? (
                <Alert style={{justifyContent: 'center'}} severity="warning" variant="filled">
                    {intl.formatMessage({id: 'report.noDataError', defaultMessage: 'There is no data available for this report'})}
                </Alert>
            ) : (
                <Fragment>
                    <p>
                        <FormattedMessage
                            id="execSummaryRisks.p1"
                            defaultMessage={
                                "In relation to risk foresight, the overall result was "+
                                "{foresightDimScore}% and elements related to food safety "+
                                "risk perception were as follows:"
                            }
                            values={{
                                foresightDimScore: Math.round(props.reportData['basic-data'].dimensions.foresight.score),
                            }}
                        />
                    </p>

                    <Table>
                        <TableHead style={{backgroundColor: '#3c3e40'}}>
                            <TableRow>
                                <TableCell style={{...smallColStyle, ...{width: '100pt'}, ...tableStyle}}>{intl.formatMessage({id: 'generic.category', defaultMessage:"Category"})}</TableCell>
                                <TableCell style={{ ...smallColStyle, ...tableStyle}}>{intl.formatMessage({id: 'generic.element', defaultMessage:"Element"})}</TableCell>
                                <TableCell style={{ ...smallColStyle, ...{ width: '100pt' }, ...tableStyle}}>{intl.formatMessage({id: 'generic.scorePercent', defaultMessage:"Score (%)"})}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {filteredElements.map((element, i) => (
                                <TableRow key={i} style={{backgroundColor: CATEGORY_COLORS[element.category].light, borderBottom: '10px solid white'}}>
                                    <TableCell style={tableStyle}><CategoryLabel category={element.category} /></TableCell>
                                    <TableCell style={tableStyle}><ElementLabel element={element.shortName} /></TableCell>
                                    <TableCell style={tableStyle} align="center">
                                        {parseFloat(element.score).toFixed(1)}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>

                    <p>
                        <FormattedMessage
                            id="execSummaryRisks.p2"
                            defaultMessage={
                                "When it came to specific hazards and their level of risk, on average the following number of "+
                                "employees felt these to be high risk:"
                            }
                        />
                    </p>

                    <ul>
                        {risks.map((risk, i) => (
                            <li key={i}>
                                {Math.round(risk.highRisk.percentage)}% for {riskTranslated(risk.label, intl)}
                                {i < risks.length - 1 ? ',' : '.'}
                            </li>
                        ))}
                    </ul>

                    <p/>

                    <Box p={0} style={{width: '175mm', margin: '0 auto'}}>
                        <RisksReport reportData={{data: props.reportData['risks']}} print={true} />
                    </Box>

                    <p>
                        <FormattedMessage
                                id="execSummaryRisks.p3"
                                defaultMessage={
                                    "Specific details of which hazards were seen to be most important can be viewed and filtered for "+
                                    "maximum relevance in the <strong>Risks</strong> view."
                                }
                                values={{
                                    strong: chunks => <strong>{chunks}</strong>
                                }}
                            />
                    </p>

                    <p>
                        <FormattedMessage
                            id="execSummaryRisks.p4"
                            defaultMessage={
                                "It is important to look at different sites separately and consider the relative level of risk, "+
                                "and consistency across the different groups, to judge the accuracy of these perceptions."
                            }
                            values={{
                                strong: chunks => <strong>{chunks}</strong>
                            }}
                        />

                    </p>

                    <p>
                        <FormattedMessage
                            id="execSummaryRisks.p5"
                            defaultMessage="It can also be valuable to compare this data to company hazard analysis documents."
                        />
                    </p>
                </Fragment>
            )}
        </Fragment>
    );
}
