import { lazy, Suspense } from "react";
const Bar = lazy(() => import(/* webpackChunkName: "charts" */'./Bar'));

export default function BarLazy(props) {
    const {innerRef, ...rest} = props;
    return (
        <Suspense fallback={<div />}>
            <Bar innerRef={innerRef} {...rest} />
        </Suspense>
    );
}