import { Box, Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import { Fragment } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import PriorityLabel from "../../../Translation/PriorityLabel";
import PrioritiesReport from "../Priorities";

export default function Priorities(props) {
    const intl = useIntl();

    const priorities = [];
    for (const key in props.reportData.priorities) {
        priorities.push({
            label: key,
            personal: props.reportData.priorities[key].personal,
            company: props.reportData.priorities[key].company,
            delta: Math.abs(props.reportData.priorities[key].personal - props.reportData.priorities[key].company),
        });
    }

    // We want highest to lowest delta
    priorities.sort((a, b) => b.delta - a.delta);

    const personalPriorities = priorities.slice().sort((a, b) => b.personal - a.personal);
    const companyPriorities = priorities.slice().sort((a, b) => b.company - a.company);
    const tableStyle = {padding: 6, fontSize: '9pt', lineHeight: 1.3};
    return (
        <Fragment>
            { props.title && props.print ? <h2 style={{fontWeight: 400}}>{props.title}</h2> : null}
            <p>
                <FormattedMessage
                    id="execSummaryPrio.p1"
                    defaultMessage={
                        "In the <strong>Priorities</strong> view, the respondents are asked to rank their personal and then "+
                        "perceived company priorities in relation to important features of safety, quality and other business "+
                        "outcomes. Depending on the company type, the ideal outcome may vary hence these results do not "+
                        "contribute to the overall score but provide an additional set of data."
                    }
                    values = {{
                        strong: chunks => <strong>{chunks}</strong>
                    }}
                />
            </p>

            <p>
                <FormattedMessage
                    id="execSummaryPrio.p2"
                    defaultMessage={
                        "It is important to make sure the order of priorities is as it should be, and that there is close "+
                        "alignment between personal and company priorities. As an example, a company with a strong safety culture "+
                        "would rank relevant features of safety first and foremost. Likewise, personal and perceived company "+
                        "priorities on safety would be closely aligned."
                    }
                />
            </p>

            <p>
                <FormattedMessage
                    id="execSummaryPrio.p3"
                    defaultMessage={"In this case, the results are as follows:"}
                />
            </p>

            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell style={tableStyle}><FormattedMessage id="execSummaryPrio.priorityOrder" defaultMessage="Priority Order" /></TableCell>
                        <TableCell style={tableStyle}>{intl.formatMessage({id: 'priorities.personal', defaultMessage: 'Personal'})}</TableCell>
                        <TableCell style={tableStyle}>{intl.formatMessage({id: 'priorities.company', defaultMessage: 'Company'})}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {priorities.map((_, i) => {
                        return (
                            <TableRow key={i + 1}>
                                <TableCell style={tableStyle}>{i + 1}</TableCell>
                                <TableCell style={tableStyle}><PriorityLabel title={personalPriorities[i].label} /></TableCell>
                                <TableCell style={tableStyle}><PriorityLabel title={companyPriorities[i].label} /></TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>


            <p>
                <FormattedMessage
                    id="execSummaryPrio.p4"
                    defaultMessage={
                        "There is the greatest lack of alignment in: <strong>{prio1}</strong> "+
                        "and <strong>{prio2}</strong>."
                    }
                    values = {{
                        prio1: (priorities.length ? <PriorityLabel title={priorities[0].label} /> : '-'),
                        prio2: (priorities.length > 1 ? <PriorityLabel title={priorities[1].label} /> : '-'),
                        strong: chunks => <strong>{chunks}</strong>
                    }}
                />
            </p>
            <Box p={0} style={{width: '200mm', margin: '0 auto'}}>
                <PrioritiesReport reportData={{data: props.reportData.priorities}} print={true} />
            </Box>
        </Fragment>
    );
}
