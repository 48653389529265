import { useIntl } from "react-intl";

export default function DimensionDescription(props) {
    const intl = useIntl();

    const dimensionText = (demographic) => {
        // Allow the use of both the demographic type and the title (i.e. "length of service" and "lengthofservice")
        switch(demographic.toLowerCase()) {
            case "empowerment":
                return intl.formatMessage({
                    id: 'definitionsReport.empowermentDescription',
                    defaultMessage:
                        'Empowerment reflects the level of autonomy and ownership felt by those working for the company, ' +
                        'and their authority to deal with potential safety and quality problems.'
                });

            case "reward":
            case "reinforcement":
                return intl.formatMessage({
                    id: 'definitionsReport.rewardDescription',
                    defaultMessage:
                        'Reward relates to the use of incentives and disincentives to shape and manage correct ' +
                        'behaviors, as well as opportunities to develop knowledge and skills.'
                });

            case "teamwork":
                return intl.formatMessage({
                    id: 'definitionsReport.teamworkDescription',
                    defaultMessage:
                        'Teamwork relates to a willingness to work in teams, the effectiveness of teamwork and the ' +
                        'importance of the safety and quality team.'
                });

            case "training":
                return intl.formatMessage({
                    id: 'definitionsReport.trainingDescription',
                    defaultMessage:
                        'Training reflects both the frequency of safety and quality training within the ' +
                        'company, and an evaluation of its effectiveness.'
                });

            case "communication":
                return intl.formatMessage({
                    id: 'definitionsReport.communicationDescription',
                    defaultMessage:
                        'Communication reflects both the frequency of safety and quality communications ' +
                        'within the company (e.g. meetings, emails, posters), and an evaluation of their effectiveness.'
                });

            case "control":
                return intl.formatMessage({
                    id: 'definitionsReport.controlDescription',
                    defaultMessage:
                        'Control relates to the effectiveness of managing and supervising staff throughout the ' +
                        'company, to ensure their compliance with company standards.'
                });

            case "coordination":
                return intl.formatMessage({
                    id: 'definitionsReport.coordinationDescription',
                    defaultMessage:
                        'Co-ordination reflects the ability of different departments and levels within the company to ' +
                        'work effectively together.'
                });

            case "consistency":
                return intl.formatMessage({
                    id: 'definitionsReport.consistencyDescription',
                    defaultMessage:
                        'Consistency relates to having standard working methods that are effectively communicated and ' +
                        'maintained throughout the company over time.'
                });

            case "systems":
                return intl.formatMessage({
                    id: 'definitionsReport.systemsDescription',
                    defaultMessage:
                        'Systems reflects the level and appropriateness of key documents and records established ' +
                        'within the company to support management processes.'
                });

            case "premises":
                return intl.formatMessage({
                    id: 'definitionsReport.premisesDescription',
                    defaultMessage:
                        'Premises relates to the physical location, facilities and equipment within the ' +
                        'company, and perceptions of its impact on safety and quality.'
                });

            case "vision":
                return intl.formatMessage({
                    id: 'definitionsReport.visionDescription',
                    defaultMessage:
                        'Vision relates to the long-term aspirations of the company, and the direction employees ' +
                        'perceive it to be moving in.'
                });

            case "values":
                return intl.formatMessage({
                    id: 'definitionsReport.valuesDescription',
                    defaultMessage:
                        'Values reflects the extent to which safety and quality are seen as core company ' +
                        'principles, and how they are directly and indirectly demonstrated in practice.'
                });

            case "strategy":
                return intl.formatMessage({
                    id: 'definitionsReport.strategyDescription',
                    defaultMessage:
                        'Strategy reflects the plans in place to achieve the company vision, and the extent to which ' +
                        'they are communicated and agreed with across the company.'
                });

            case "targets":
            case "objectives":
                return intl.formatMessage({
                    id: 'definitionsReport.targetsDescription',
                    defaultMessage:
                        'Targets relates to the establishment and management of short-term goals or objectives ' +
                        'amongst employees, and the inclusion of safety and quality within these.'
                });

            case "metrics":
                return intl.formatMessage({
                    id: 'definitionsReport.metricsDescription',
                    defaultMessage:
                        'Metrics relates to the data that is gathered within the company to measure and ' +
                        'improve safety and quality, and how effectively it is used.'
                });

            case "awareness":
                return intl.formatMessage({
                    id: 'definitionsReport.awarenessDescription',
                    defaultMessage:
                        'Awareness relates to the knowledge of external safety and quality issues, including ' +
                        'external stakeholders (suppliers and customers) and general industry standards.'
                });

            case "foresight":
                return intl.formatMessage({
                    id: 'definitionsReport.foresightDescription',
                    defaultMessage:
                        'Foresight reflects a risk awareness and ability to prioritise based on the likelihood ' +
                        'of safety and quality problems and consequences associated with them.'
                });

            case "innovation":
            case "change":
                return intl.formatMessage({
                    id: 'definitionsReport.innovationDescription',
                    defaultMessage:
                        'Innovation relates to both an openness to change within individuals and the level of ' +
                        'safety and quality change and innovation in the company.'
                });

            case "learning":
                return intl.formatMessage({
                    id: 'definitionsReport.learningDescription',
                    defaultMessage:
                        'Learning relates to the attitude of individuals towards organisational learning, its ' +
                        'perceived effectiveness, and its impact on safety and quality standards.'
                });

            case "investment":
                return intl.formatMessage({
                    id: 'definitionsReport.investmentDescription',
                    defaultMessage:
                        'Investment relates to the allocation and spending of budget on safety and quality, ' +
                    'and perceptions of the effectiveness of this.'
                });

            default:
                return demographic
        }
    };

    return(
        <span>{(props.upperCase ? dimensionText(props.dimension).toUpperCase() : (props.lowerCase ? dimensionText(props.dimension).toLowerCase() : dimensionText(props.dimension)))}</span>
    );
}
