import { useContext } from "react";
import { useLocation, Redirect } from "react-router-dom";
import { UserContext } from "../../Context/UserContext";

export default function ForceTwoFactor(props) {
    const { user, token } = useContext(UserContext);
    const location = useLocation();

    if (!location.pathname.startsWith('/account') &&
        !location.pathname.startsWith('/logout') &&
        token &&
        token.token &&
        token.expiry &&
        token.expiry > Math.floor(Date.now() / 1000) &&
        user &&
        user.hasOwnProperty('forceTwoFactorAuth') &&
        user.forceTwoFactorAuth &&
        !user.twoFactorAuth
    ) {
        return <Redirect to="/account" />
    }

    return null;
}