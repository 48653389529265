import { Box, Button, Dialog, Checkbox, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControlLabel, Grid, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography, makeStyles } from "@material-ui/core";
import { useParams } from "react-router-dom";
import { Fragment, useContext, useEffect, useState } from "react";
import { NotificationContext } from "../../Context/NotificationContext";
import { SpinnerContext } from "../../Context/SpinnerContext";
import SurveyService from "./../../Services/SurveyService";
import { AppBarTitleContext } from "../../Context/AppBarTitleContext";
import FilterNoneIcon from '@material-ui/icons/FilterNone';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import { FormattedMessage, useIntl } from "react-intl";
import UserService from "../../Services/UserService";
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import MUIDataTable from "mui-datatables";


const useStyles = makeStyles((theme) => ({
    rowButton: {
        padding: '6px'
    },
}));

export default function SurveyProgressReport() {
    const classes = useStyles();
    const [isLoading, setLoading] = useState(true);
    const [tableData, setTableData] = useState({
        page: 0,
        count: 1,
        rowsPerPage: 20,
        sortOrder: {},
        data: [],
        included: []
    });
    const hiddenOptions = {
        display: false,
        viewColumns: false,
        filter: false,
        sort: false,
        searchable: false,
        print: false,
        download: false
    };

    const { updateNotification } = useContext(NotificationContext);
    const { updateTitle } = useContext(AppBarTitleContext);
    let { hash } = useParams();
    const intl = useIntl();
    const { updateLoading } = useContext(SpinnerContext);
    const [surveyData, setSurveyData] = useState(null);
    const [surveyIncluded, setSurveyIncluded] = useState(null);
    const [selectedDemographics, setSelectedDemographics] = useState(null);
    const [users, setUsers] = useState([]);
    const [checked, setChecked] = useState({});
    const [forceRefresh, setForceRefresh] = useState(false);
    const initialDays = {mon: false, tue: false, wed: false, thu: false, fri: false, sat: false, sun: false};
    const [schedDays, setSchedDays] = useState(initialDays);
    const [editUuid, setEditUuid] = useState(null);
    const [deleteUuid, setDeleteUuid] = useState(null);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

    useEffect(() => {
        fetchSurvey(hash);
        fetchSchedules(0, tableData.rowsPerPage);
        // eslint-disable-next-line
    }, [hash])

    const columns = [{
            name: 'id',
            options: hiddenOptions
        },
        {
            label: 'Demographics',
            name: 'attributes.demographics',
            options: {
                sort: false,
                customBodyRender: (value, tableMeta) => {
                    return <ul>{value.map(dem => (<li key={dem}>{dem}</li>))}</ul>
                }
            }
        },
        {
            label: 'Days',
            name: 'attributes.days',
            options: {
                sort: false,
                customBodyRender: (value, tableMeta) => {
                    return <ul>{value.map(day => (<li key={day}>{day}</li>))}</ul>
                }
            }
        },
        {
            label: 'Users',
            name: 'relationships.users',
            options: {
                sort: false,
                customBodyRender: (value, tableMeta) => {
                    return <ul>{value.map(user => (<li key={user.data.email}>{user.data.name} - {user.data.email}</li>))}</ul>
                }
            }
        },
        {
            name: 'attributes.createdAt',
            label: intl.formatMessage({id: 'generic.created', defaultMessage: 'Created'}),
            options: {
                customBodyRender: (value) => {
                    if (value) {
                        return <span title={value}>{(new Date(value)).toLocaleDateString('en-gb', {year: 'numeric', month: 'short', day: 'numeric'})}</span>
                    }
                    return '';
                }
            }
        },
        {
            name: 'id',
            label: ' ',
            options: {
                customBodyRender: (value, tableMeta) => (
                    <Box>
                        <IconButton className={classes.rowButton} onClick={() => {setEditUuid(value); setCheckedUsersOnly(tableMeta.rowData[3]); setCheckedDemographicsOnly(tableMeta.rowData[1]); setSelectedDaysOnly(tableMeta.rowData[2]);}}>
                            <EditIcon fontSize="small" style={{ color: '#f57c00'}} />
                        </IconButton>
                        <IconButton className={classes.rowButton} color="secondary" onClick={() => {setDeleteUuid(value); setDeleteDialogOpen(true);}}>
                            <DeleteIcon fontSize="small" />
                        </IconButton>
                    </Box>
                ),
                setCellProps: () => ({
                    align: 'right'
                }),
            }

        }
    ];

    const options = {
        textLabels: {
            body: {
                noMatch: isLoading ? '...' : intl.formatMessage({id: 'generic.noData', defaultMessage: 'No data...'}),
            }
        },
        pagination: tableData.count > 20, // Only show pagination if total is above pagination limit
        tableBodyHeight: 'auto',
        enableNestedDataAccess: '.',
        selectableRows: 'none',
        filter: false,
        search: false,
        download: false,
        print: false,
        viewColumns: false,
        elevation: 0,
        serverSide: true,
        count: tableData.count,
        rowsPerPage: tableData.rowsPerPage,
        rowsPerPageOptions: [],
        sortOrder: tableData.sortOrder,
        onTableChange: (action, tableState) => {
            const sortCol = tableState.sortOrder.name ? (tableState.sortOrder.name.replace('attributes.', '')) : 'createdAt';
            const sortDir = tableState.sortOrder.direction ? (tableState.sortOrder.direction === 'asc' ? '' : '-') : '';

            switch (action) {
                case 'changePage':
                    fetchSchedules(tableState.page, tableState.rowsPerPage, sortDir+sortCol, tableState.searchText);
                    break;

                case 'propsUpdate':
                    if (forceRefresh) {
                        setForceRefresh(false, fetchSchedules((tableState.page ? tableState.page : 0), (tableState.rowsPerPage ? tableState.rowsPerPage : 20)));
                    }
                    break;

                default:
                    console.log(action, 'action not handled.');
            }
        },
    };

    const fetchSchedules = (page, pageSize) => {
        console.log('FETCH#', page, pageSize);
        setLoading(true);
        SurveyService.surveyProgressReportSchedules(hash, page*pageSize, pageSize)
        .then(function (response) {
            setTableData({
                data: response.data.data,
                count: response.data.meta.count,
                included: response.data.included
            });
        }).catch(function (error) {
            updateNotification(true, intl.formatMessage({id: 'generic.unknownError', defaultMessage: 'An unknown error occurred!'}), 'error');
        }).then(function() {
            setLoading(false);
        });
    };


    const userSites = (user) => {
        if (user.relationships['user-permissions']) {
            let allSites = false;
            let siteCount = 0;
            user.relationships['user-permissions'].forEach(up => {
                if (up.attributes.permission.survey) {
                    if ((up.attributes.permission.survey.length === 1 && up.attributes.permission.survey[0] === null) ||
                        -1 !== up.attributes.permission.survey.indexOf(hash)
                    ) {
                        if (up.attributes.permission.site) {
                            if (up.attributes.permission.site.length === 1 && up.attributes.permission.site[0] === null) {
                                allSites = true;
                            } else {
                                siteCount += up.attributes.permission.site.length;
                            }
                        }
                    }
                }
            });

            if (allSites) {
                return <span>All</span>
            } else if (siteCount > 0) {
                return siteCount;
            } else {
                return null;
            }
        } else {
            return null;
        }
    };

    const fetchUsers = (companyId) => {
        updateLoading(false)
        UserService.allUsers('name', null, {company: companyId}, 'user-permission').then(response => {
            setUsers(response);
            const clearChecked = {};
            response.forEach((user) => {
                if (null !== userSites(user)) {
                    clearChecked[user.id] = false;
                }
            });
            setChecked(clearChecked);
        }).catch(error => {
            console.log(error);
        }).then(() => {
            updateLoading(false);
        });
    };

    const fetchSurvey = (hash) => {
        updateTitle(null);
        updateLoading(true);
        SurveyService.surveyByHash(hash)
        .then(function (response) {
            setSurveyIncluded(response.data.included);
            // Reset types selected
            const selectedDems = {};
            response.data.included.forEach((question) => {
                if (question.attributes.demographic !== 'site') {
                    selectedDems[question.attributes.demographic] = {
                        shortName: (question.attributes.shortName ? question.attributes.shortName : question.attributes.title),
                        checked: false
                    };
                }
            });
            setSelectedDemographics(selectedDems);

            setSurveyData(response.data.data);
            fetchUsers(response.data.data.relationships.company.id);
        }).catch(function (error) {
            updateNotification(true, intl.formatMessage({id: 'generic.unknownError', defaultMessage: 'An unknown error occurred!'}), 'error');
            console.log(error);
            updateLoading(false)
        });
    };

    if (!surveyData) {
        return null;
    }

    const handleDelete = () => {
        // To avoid multiple submits
        if (!deleteDialogOpen) {
            return;
        }
        setDeleteDialogOpen(false);
        updateLoading(true);

        SurveyService.deleteSurveyProgressReportSchedule(deleteUuid)
        .then(function (response) {
            setDeleteUuid(null);
            setEditUuid(null);
            setCheckedUsersOnly([]);
            setSelectedDaysOnly([]);
            setCheckedDemographicsOnly([]);
            setForceRefresh(true);
        }).catch(function (error) {
            if (error.response && error.response.status) {
                if (error.response.data.error.detail) {
                    updateNotification(true, error.response.data.error.detail, 'error');
                } else {
                    updateNotification(true, intl.formatMessage({id: 'generic.unknownError', defaultMessage: 'An unknown error occurred!'}), 'error');
                }
            } else {
                updateNotification(true, intl.formatMessage({id: 'generic.unknownError', defaultMessage: 'An unknown error occurred!'}), 'error');
            }
            console.error(error);
        }).then(function () {
            updateLoading(false);
        });
    }

    const sendreport = () => {
        const userIds = [];
        for (const userId in checked) {
            if (checked[userId]) {
                userIds.push(userId);
            }
        }

        const demTypes = [];
        for (const key in selectedDemographics) {
            if (selectedDemographics[key].checked) {
                demTypes.push(key);
            }
        }
        updateLoading(true);
        SurveyService.surveyProgressReport(hash, userIds, demTypes)
        .then(function (response) {
            updateNotification(true, 'Report(s) sent', 'success');
        }).catch(function (error) {
            updateNotification(true, intl.formatMessage({id: 'generic.unknownError', defaultMessage: 'An unknown error occurred!'}), 'error');
            console.log(error);
        }).then(() => {
            updateLoading(false);
        });
    };

    const anyChecked = () => {
        const anyUsersChecked = Object.keys(checked).some((key) => {
            return checked[key];
        });

        if (!anyUsersChecked) {
            return false;
        }

        const anyDemChecked = Object.keys(selectedDemographics).some((key) => {
            return selectedDemographics[key].checked;
        });

        if (!anyDemChecked) {
            return false;
        }
        return true;
    };

    const toggleAll = (check) => {
        const clearChecked = {};
        for (const userId in checked) {
            clearChecked[userId] = check;
        }
        setChecked(clearChecked);
    };

    const toggle = (id) => event => {
        setChecked(prevState => ({
            ...prevState,
            [id]: !prevState[id]
        }));
    };

    const setCheckedUsersOnly = (users) => {
        const clearChecked = {};
        for (const userId in checked) {
            clearChecked[userId] = false;
        }
        users.forEach(u => {
            clearChecked[u.id] = true;
        });
        setChecked(clearChecked);
    }

    const setCheckedDemographicsOnly = (dems) => {
        const selectedDems = {};
        Object.keys(selectedDemographics).forEach(key => {
            selectedDems[key] = selectedDemographics[key];
            selectedDems[key].checked = false;
        });

        dems.forEach(d => {
            selectedDems[d].checked = true;
        });
        setSelectedDemographics(selectedDems);
    };

    const toggleDemographic = (type) => event => {
        const toggled = {
            checked: !selectedDemographics[type].checked,
            shortName: selectedDemographics[type].shortName
        };
        setSelectedDemographics(prevState => ({
            ...prevState,
            [type]: toggled
        }))
    };

    const setSelectedDaysOnly = (days) => {
        const selDays = {};
        Object.keys(initialDays).forEach(d => {
            selDays[d] = false;
        })

        days.forEach(d => {
            selDays[d] = true;
        });

        setSchedDays(selDays);
    };

    const handleSchedule = () => {
        const userIds = [];
        for (const userId in checked) {
            if (checked[userId]) {
                userIds.push(userId);
            }
        }

        const demTypes = [];
        for (const key in selectedDemographics) {
            if (selectedDemographics[key].checked) {
                demTypes.push(key);
            }
        }
        updateLoading(true);

        if (editUuid) {
            SurveyService.patchSurveyProgressReportSchedyle(editUuid, hash, userIds, demTypes, Object.keys(schedDays).filter(day => schedDays[day]))
            .then(function (response) {
                updateNotification(true, 'Report(s) schedule updated', 'success');
                setForceRefresh(true);
                setEditUuid(null);
                setCheckedUsersOnly([]);
                setSelectedDaysOnly([]);
                setCheckedDemographicsOnly([]);
            }).catch(function (error) {
                updateNotification(true, intl.formatMessage({id: 'generic.unknownError', defaultMessage: 'An unknown error occurred!'}), 'error');
                console.log(error);
            }).then(() => {
                updateLoading(false);
            });
        } else {
            SurveyService.createSurveyProgressReportSchedule(hash, userIds, demTypes, Object.keys(schedDays).filter(day => schedDays[day]))
            .then(function (response) {
                updateNotification(true, 'Report(s) scheduled', 'success');
                setForceRefresh(true);
                setCheckedUsersOnly([]);
                setSelectedDaysOnly([]);
                setCheckedDemographicsOnly([]);
            }).catch(function (error) {
                updateNotification(true, intl.formatMessage({id: 'generic.unknownError', defaultMessage: 'An unknown error occurred!'}), 'error');
                console.log(error);
            }).then(() => {
                updateLoading(false);
            });
        }
    };

    const userRows = () => {
        if (!users.length) {
            return null;
        }

        if (!Object.keys(checked).length) {
            return null;
        }

        return users.map(curUser => {
            if (null === userSites(curUser) || !curUser.attributes.enabled) {
                return null;
            } else {
                return (<TableRow key={curUser.id}>
                    <TableCell>
                        <Checkbox
                            checked={checked[curUser.id]}
                            onChange={toggle(curUser.id)}
                            color="primary"
                        />
                    </TableCell>
                    <TableCell>{curUser.attributes.name}</TableCell>
                    <TableCell>{curUser.attributes.email}</TableCell>
                    <TableCell>{curUser.attributes.role}</TableCell>
                    <TableCell align="center">{userSites(curUser)}</TableCell>
                </TableRow>);
            }
        });
    };

    return(
        <Fragment>
            <Grid container spacing={0} justifyContent="center">
                <Grid item sm={12} md={8}>
                    <Typography variant="h6">
                        {(surveyData.relationships['survey-title'] ? surveyData.relationships['survey-title'].data.title : surveyData.attributes.title) + ' | Progress Report'}
                    </Typography>
                    <Box py={2}>
                        <span style={{fontWeight: 500}}>Demographics</span>
                        <ul>
                            { surveyIncluded && selectedDemographics ?
                                Object.keys(selectedDemographics).map((key) => (
                                    <li key={key}>
                                        <Checkbox
                                            checked={selectedDemographics[key].checked}
                                            onChange={toggleDemographic(key)}
                                            color="primary"
                                        />
                                        {selectedDemographics[key].shortName}
                                    </li>
                                ))
                            : null}
                        </ul>
                    </Box>
                    <hr />
                    <Tooltip title="Uncheck all">
                        <IconButton onClick={event => toggleAll(false)}>
                            <FilterNoneIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Check all">
                        <IconButton onClick={event => toggleAll(true)}>
                            <DoneAllIcon />
                        </IconButton>
                    </Tooltip>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell></TableCell>
                                    <TableCell>Name</TableCell>
                                    <TableCell>Email</TableCell>
                                    <TableCell>Role</TableCell>
                                    <TableCell align="center">Site(s)</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {userRows()}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Box p={2}>
                        <p style={{fontWeight: 500}}>Schedule</p>
                        <p>Select one or more days of the week below, reports are sent out every morning 07:00 UTC <em>(only applies to schedule)</em></p>
                        {Object.keys(schedDays).map(day => (
                            <FormControlLabel
                                key={day}
                                control={<Checkbox
                                    checked={schedDays[day]}
                                    onChange={() => setSchedDays(prevState => ({
                                        ...prevState,
                                        [day]: !prevState[day]
                                    }))}
                                    color="primary"
                                />}
                                label={day}
                            />
                        ))}
                    </Box>
                    <Button variant="contained" onClick={() => {sendreport()}} color="secondary" disabled={!anyChecked()}>
                        Send Report Now
                    </Button>
                    <Button variant="contained" style={{marginLeft: 20}} onClick={handleSchedule} color="primary" disabled={!anyChecked() || !Object.keys(schedDays).some(day => (
                        schedDays[day]
                    ))}>
                        {editUuid ? 'Save Schedule' : 'Create Schedule'}
                    </Button>
                </Grid>
                <Grid item sm={12} md={8} style={{ paddingTop:20 }}>
                    <hr />
                    <Typography variant="h6">Scheduled Reports</Typography>
                    <MUIDataTable
                        title=""
                        data={tableData.data}
                        columns={columns}
                        options={options}
                    />
                </Grid>
            </Grid>

            <Dialog
                open={deleteDialogOpen}
                onClose={() => {setDeleteDialogOpen(false)}}
            >
                <DialogTitle><FormattedMessage id="generic.delete" defaultMessage="Delete" /></DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        This will delete the schedule, continue?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={() => {setDeleteDialogOpen(false)}} color="primary" autoFocus>
                        <FormattedMessage id="generic.cancel" defaultMessage="Cancel" />
                    </Button>
                    <Button variant="contained" onClick={handleDelete} color="secondary">
                        <FormattedMessage id="generic.confirm" defaultMessage="Confirm" />
                    </Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    );
}
