import { Box, Typography } from "@material-ui/core";
import { FolderOpen } from "@material-ui/icons";

export default function NoData(props) {

    return (
        <Box display="flex" justifyContent="center" alignItems="center" style={{width: (props.height ? props.height : '100%'), height: (props.height ? props.height : '90vh')}}>
            <Box align="center" style={{ color: '#AEAEAE'}}>
                <FolderOpen style={{ fontSize: 100 }} />
                <Typography variant="body1">{(props.text ? props.text : 'No data...')}</Typography>
            </Box>
        </Box>
    );
}