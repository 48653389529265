import { Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { Fragment } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import PriorityLabel from "../../../Translation/PriorityLabel";
import { hotspotDemographicLabel } from "./Common";

export default function PriorityHotspots(props) {
    const intl = useIntl();
    const elementsColStyle = {
        whiteSpace: 'normal',
        wordWrap: 'break-word',
        border: '1px solid #e0e0e0',
    };

    const scoreColStyle = {
        paddingLeft: '24px',
        paddingRight: '24px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        border: '1px solid #e0e0e0',
    };

    const tableStyle = {padding: 6, fontSize: '9pt', lineHeight: 1.3};
    return (
        <Fragment>
            { props.title && props.print ? <h2 style={{fontWeight: 400}}>{props.title}</h2> : null}

            {
                (!props.reportData['priority-hotspots'].all || props.reportData['priority-hotspots'].all.length === 0) ?
            (
                <Alert style={{justifyContent: 'center'}} severity="warning" variant="filled">
                    {intl.formatMessage({id: 'report.noDataError', defaultMessage: 'There is no data available for this report'})}
                </Alert>
            ) : (
                <Fragment>
                    <p>
                        <FormattedMessage
                            id="execSummaryPrioHotspots.p1"
                            defaultMessage={
                                "Using the <strong>Hotspots</strong> view more insight can be gained by looking at which "+
                                "demographic filter combinations rank features of quality and safety highest and lowest, as well "+
                                "as where the greatest differences between individuals and companies on perception lie."
                            }
                            values={{
                                strong: chunks => <strong>{chunks}</strong>
                            }}
                        />
                    </p>

                    <p>
                        <FormattedMessage
                            id="execSummaryPrioHotspots.p2"
                            defaultMessage={"To investigate further, it is recommended to:"}
                        />
                    </p>

                    <p>
                        <FormattedMessage
                            id="execSummaryPrioHotspots.p3"
                            defaultMessage={
                                "1. Identify which specific areas within the company have the greatest level of conflict / "+
                                "misunderstanding around priorities, using the <strong>Hotspots</strong> view. An example is "+
                                "shown below, highlighting the greatest difference between individuals and companies:"
                            }
                            values={{
                                strong: chunks => <strong>{chunks}</strong>
                            }}
                        />
                    </p>

                    <Table className="reportTable elementsReport">
                        <TableHead style={{backgroundColor: '#3c3e40'}}>
                            <TableRow>
                                <TableCell style={{...elementsColStyle, ...{color: 'white', width: '60%'}, ...tableStyle}}>
                                    <FormattedMessage id="priorityHotspotsReport.highestDemographicCombinations" defaultMessage={"Highest Demographic Combinations"} />
                                </TableCell>
                                <TableCell style={{...scoreColStyle, ...{color: 'white', width: '20%'}, ...tableStyle}}>
                                    <FormattedMessage id="generic.priority" defaultMessage={"Priority"} />
                                </TableCell>
                                <TableCell style={{...scoreColStyle, ...{textAlign: 'center', color: 'white', width: '20%'}, ...tableStyle}}>
                                    <FormattedMessage id="generic.difference" defaultMessage={"Difference"} />
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {props.reportData['priority-conflict-hotspots'].all.slice(0, 6).map((element, index) => {
                                return (
                                    <TableRow
                                        key={index}
                                        style={{ backgroundColor: index % 2 === 0 ? '#e8e8e8' : '#ffffff' }}>
                                        <TableCell style={{ ...elementsColStyle, ...tableStyle}}>
                                            {hotspotDemographicLabel(element, props.demographicQuestions, props.demographicOptionLabels, props.print)}
                                        </TableCell>
                                        <TableCell style={{ ...scoreColStyle, ...tableStyle}}>
                                            <PriorityLabel title={element.title} />
                                        </TableCell>
                                        <TableCell style={{...scoreColStyle, ...{textAlign: 'center'}, ...tableStyle}}>
                                            {element.score}
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>

                    <p>
                        <FormattedMessage
                            id="execSummaryPrioHotspots.p4"
                            defaultMessage={
                                "2. Identify which specific areas within the company are most likely to view cost or speed as a "+
                                "high priority (5 being highest priority and 1 lowest), using the <strong>Hotspots</strong> view. "+
                                "An example is shown below, showing which specific groups ranked cost or speed highest:"
                            }
                            values={{
                                strong: chunks => <strong>{chunks}</strong>
                            }}
                        />
                    </p>

                    <Table className="reportTable elementsReport">
                        <TableHead style={{backgroundColor: '#3c3e40'}}>
                            <TableRow>
                                <TableCell style={{...elementsColStyle, ...{color: 'white', width: '50%'}, ...tableStyle}}>
                                    <FormattedMessage id="priorityHotspotsReport.highestDemographicCombinations" defaultMessage={"Highest Demographic Combinations"} />
                                </TableCell>
                                <TableCell style={{...scoreColStyle, ...{color: 'white', width: '10%'}, ...tableStyle}}>
                                    <FormattedMessage id="generic.type" defaultMessage="Type" />
                                </TableCell>
                                <TableCell style={{...scoreColStyle, ...{color: 'white', width: '20%'}, ...tableStyle}}>
                                    <FormattedMessage id="generic.priority" defaultMessage={"Priority"} />
                                </TableCell>
                                <TableCell style={{ ...{ textAlign: 'center', color: 'white', width: '20%'}, ...tableStyle}}>
                                    <FormattedMessage id="execSummaryPrioHotspots.rank" defaultMessage={"Rank (5 is highest)"} />
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {props.reportData['priority-hotspots'].all.map((element, index) => {
                                // NOTE: If new options are added that relate to speed/cost then they need to be added below
                                if (-1 !== ['production costs', 'production speed', 'product price', 'cost control', 'service speed'].indexOf(element.title.toLowerCase())) {
                                    return (
                                        <TableRow
                                            key={index}
                                            style={{ backgroundColor: index % 2 === 0 ? '#e8e8e8' : '#ffffff' }}>
                                            <TableCell style={{ ...elementsColStyle, ...tableStyle}}>
                                                {hotspotDemographicLabel(element, props.demographicQuestions, props.demographicOptionLabels, props.print)}
                                            </TableCell>
                                            <TableCell style={{ ...scoreColStyle, ...tableStyle}}>
                                                {element.type === 'personal' ? <FormattedMessage id="generic.personal" defaultMessage={"Personal"} /> : <FormattedMessage id="generic.company" defaultMessage={"Company"} />}
                                            </TableCell>
                                            <TableCell style={{ ...scoreColStyle, ...tableStyle }}>
                                                <PriorityLabel title={element.title} />
                                            </TableCell>
                                            <TableCell style={{ ...{ width: '25%', textAlign: 'center' }, ...tableStyle}}>
                                                {element.score}
                                            </TableCell>
                                        </TableRow>
                                    );
                                }
                                return null;
                            }).filter(r => r !== null).slice(0, 6)}
                        </TableBody>
                    </Table>

                    <p>
                        <FormattedMessage
                            id="execSummaryPrioHotspots.p5"
                            defaultMessage={
                                "3. Based on the findings, hold a discussion group with people in the areas where this is the "+
                                "greatest concern, to find out what could be causing it. This will allow a deeper understanding "+
                                "of the cause of the issue, show people that they are being listened to, and hopefully generate "+
                                "ideas for improvement."
                            }
                        />
                    </p>

                    <p>
                        <FormattedMessage
                            id="execSummaryPrioHotspots.p6"
                            defaultMessage={
                                "Note: It is important, as the discussion group is not anonymous, to do this in a way that "+
                                "protects the individuals and allows them to be open and honest."
                            }
                        />
                    </p>
                </Fragment>
            )}
        </Fragment>
    );
}
