import { Fragment } from "react";
import ElementsTable from "./ElementsTable";
import { FormattedMessage } from "react-intl";

export default function InvestmentDimension(props) {
    return (
        <Fragment>
            <p>
                <FormattedMessage
                    id="execSummaryDiscAndRecs.investment"
                    defaultMessage={
                        "Investment can make an impact on both behaviour (facilitating better standards, new initiatives, "+
                        "innovation, etc.) and attitude (pride, perception of management commitment, etc.). When managers visibly "+
                        "invest in safety and quality they are stating that it is important to them, and the observed criteria by "+
                        "which managers allocate budget is seen as a primary embedding mechanism for culture (Schein)."
                    }
                />
            </p>
            <ElementsTable dimension={props.dimension} elements={props.elements} lowestElements={props.lowestElements} />
        </Fragment>
    );
}
