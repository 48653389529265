import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, InputAdornment, TextField, Tooltip, Typography, makeStyles } from "@material-ui/core";
import { FormattedMessage, useIntl } from 'react-intl'
import { Fragment, useContext, useEffect, useState } from "react";
import { NotificationContext } from "../../Context/NotificationContext";
import { AppBarTitleContext } from "../../Context/AppBarTitleContext";
import { SpinnerContext } from "../../Context/SpinnerContext";
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import LockIcon from '@material-ui/icons/Lock';
import UserService from "../../Services/UserService";
import PasswordStrengthLazy from "../Auth/SetPassword/PasswordStrengthLazy";
import {QRCodeSVG} from 'qrcode.react';
import { UserContext } from "../../Context/UserContext";

const useStyles = makeStyles((theme) => ({
    buttons: {
        '& > *': {
            marginRight: theme.spacing(2),
        },
    },
}));

export default function Account() {
    const classes = useStyles();
    const { updateNotification } = useContext(NotificationContext);
    const { updateTitle } = useContext(AppBarTitleContext);
    const { updateLoading } = useContext(SpinnerContext);
    const { updateUser, user } = useContext(UserContext);

    const [demo, setDemo] = useState(false);
    const intl = useIntl();
    const minPasswordLength = 10;

    const forceTwoFactor = user && user.hasOwnProperty('forceTwoFactorAuth') && user.forceTwoFactorAuth && !user.twoFactorAuth;
    const [twoFactorStep, setTwoFactorStep] = useState(forceTwoFactor ? 1 : 0);
    const [twoFactorPassword, setTwoFactorPassword] = useState('');
    const [twoFactorSecret, setTwoFactorSecret] = useState({secret: '', uri: ''});
    const [otpVerifyCode, setOtpVerifyCode] = useState('');

    const [values, setValues] = useState({
        name: '',
        email: '',
        password: '',
        verifyPassword: '',
    });

    const [myUser, setMyUser] = useState(null);

    useEffect(() => {
        updateTitle(null);
        updateLoading(true);

        // Fetch user info
        UserService.me().then(function (response) {
            setValues({
                name: response.data.data.attributes.name ? response.data.data.attributes.name : '',
                email: response.data.data.attributes.email,
                password: '',
                verifyPassword: '',
            });

            setMyUser(response.data.data);

            if (response.data.data.attributes.name &&
                -1 !== response.data.data.attributes.name.toLowerCase().indexOf('demo') &&
                -1 !== response.data.data.attributes.email.toLowerCase().indexOf('@culturexcellence.com')
            ) {
                setDemo(true);
            }

        }).catch(function (error) {
            console.error(error);
            if (error.response && error.response.status !== 403) {
                updateNotification(true, intl.formatMessage({id: 'generic.unknownError', defaultMessage: 'An unknown error occurred!'}), 'error');
            }
        }).then(function() {
            updateLoading(false);
        });
        // eslint-disable-next-line
    }, []);

    const handleChange = name => event => {
        setValues({ ...values, [name]: event.target.value });
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        updateLoading(true);

        UserService.patchMe(values.name, values.email, values.password).then(function (response) {
            updateUser({...response.data.data.attributes, id: response.data.data.id});
            updateNotification(true, intl.formatMessage({id: 'account.accountUpdated', defaultMessage: 'Account updated'}), 'success')
        }).catch(function (error) {
            updateNotification(true, intl.formatMessage({id: 'generic.unknownError', defaultMessage: 'An unknown error occurred!'}), 'error');
        }).then(function() {
            updateLoading(false);
        });
    };

    const handleTwoFactorStep = () => {
        updateLoading(true);

        if (twoFactorStep === 1) {
            UserService.twoFactorSetup(twoFactorPassword).then(response => {
                setTwoFactorSecret({secret: response.data.data.otpSecret, uri: response.data.data.otpUri});
                setTwoFactorStep(2);
            }).catch(error => {
                if (error.response && error.response.status && 401 === error.response.status) {
                    updateNotification(true, intl.formatMessage({id: 'generic.incorrectPassword', defaultMessage: 'Incorrect password'}), 'error');
                } else {
                    updateNotification(true, intl.formatMessage({id: 'generic.unknownError', defaultMessage: 'An unknown error occurred!'}), 'error');
                }

            }).then(() => {
                updateLoading(false);
            })
        } else if (twoFactorStep === 2) {
            UserService.twoFactorConfirm(twoFactorPassword, twoFactorSecret.secret, otpVerifyCode).then(response => {
                updateNotification(true, intl.formatMessage({id: 'account.twoFactorEnabled', defaultMessage: 'Two-factor authentication enabled'}), 'success')
                setTwoFactorStep(0);
                setMyUser(response.data.data);
                updateUser({...response.data.data.attributes, id: response.data.data.id});
                setOtpVerifyCode('');
            }).catch(error => {
                if (error.response && error.response.status && 401 === error.response.status) {
                    updateNotification(true, intl.formatMessage({id: 'login.incorrectTwoFactorCode', defaultMessage: 'Incorrect verification code'}), 'error');
                } else {
                    updateNotification(true, intl.formatMessage({id: 'generic.unknownError', defaultMessage: 'An unknown error occurred!'}), 'error');
                }
            }).then(() => {
                updateLoading(false);
            });
        } else if (twoFactorStep === 3) {
            UserService.twoFactorDisable(otpVerifyCode).then(response => {
                updateNotification(true, intl.formatMessage({id: 'account.twoFactorDisabled', defaultMessage: 'Two-factor authentication disabled'}), 'success')
                setTwoFactorStep(0);
                setMyUser(response.data.data);
                updateUser({...response.data.data.attributes, id: response.data.data.id});
                setOtpVerifyCode('');
            }).catch(error => {
                if (error.response && error.response.status && 401 === error.response.status) {
                    updateNotification(true, intl.formatMessage({id: 'login.incorrectTwoFactorCode', defaultMessage: 'Incorrect verification code'}), 'error');
                } else {
                    updateNotification(true, intl.formatMessage({id: 'generic.unknownError', defaultMessage: 'An unknown error occurred!'}), 'error');
                }
            }).then(() => {
                updateLoading(false);
            })
        }
    };

    const twoFactorDialog = () => {
        return (
            <Dialog
                open={twoFactorStep !== 0}
                onClose={(event, reason) => {
                    if (reason !== 'backdropClick') {
                        setTwoFactorStep(0)
                    }
                }}
                style={{
                    zIndex: 1401
                }}
            >
                <DialogTitle>{twoFactorStep === 3 ? intl.formatMessage({id: 'account.disableTwoFactorAuth', defaultMessage: 'Disable Two-Factor Authentication'}) : intl.formatMessage({id: 'account.twoFactorAuthSetup', defaultMessage: 'Two-Factor Authentication Setup'})}</DialogTitle>
                <DialogContent>
                    {twoFactorStep === 1 ?
                        <Fragment>
                            <DialogContentText>
                                {forceTwoFactor ? intl.formatMessage({id: 'account.twoFactorRequired', defaultMessage: 'Your organisation requires the use of two-factor authentication. '}) : intl.formatMessage({id: 'account.twoFactorProtectAccount', defaultMessage: 'Protect your account with an additional layer of security. '})}
                                {intl.formatMessage({id: 'account.twoFactorOnceEnabled', defaultMessage: 'Once enabled, you will need your password and a verification code to login to your account.'})}
                            </DialogContentText>
                            <Box display="flex" justifyContent="center" alignItems="center">
                                <LockIcon style={{ fontSize: 92, color: '#3c3e40', padding: 10 }} />
                            </Box>
                            <DialogContentText>
                                <FormattedMessage id="account.twoFactorEnterPasswordToContinue" defaultMessage="Enter your password to continue..." />
                            </DialogContentText>
                            <TextField label={<FormattedMessage id="login.email" defaultMessage="Email" />} type="email" fullWidth disabled value={myUser ? myUser.attributes.email : ''} />
                            <TextField label={<FormattedMessage id="login.password" defaultMessage="Password" />} type="password" fullWidth value={twoFactorPassword} onChange={(event) => {setTwoFactorPassword(event.target.value)}} required />
                        </Fragment>
                    : null}

                    {twoFactorStep === 2 ?
                        <Fragment>
                            <DialogContentText>
                                <strong>1.</strong> {intl.formatMessage({id: 'account.twoFactorStep1', defaultMessage: 'Download an authenticator app to your phone, we recommend using either Google or Microsoft Authenticator'})}
                            </DialogContentText>
                            <Grid container direction="row" justifyContent="space-around" alignItems="center" style={{textAlign: 'center'}}>
                                <Grid item>
                                    <p style={{fontWeight: 500}}>Google Authenticator</p>
                                    <p>
                                        <a rel="noreferrer" target="_blank" href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2">
                                            <img alt="Google" src="/static/2fa/google.png" />
                                        </a>
                                    </p>
                                    <p>
                                        <a rel="noreferrer" target="_blank" href="https://apps.apple.com/app/google-authenticator/id388497605">
                                            <img alt="Apple" src="/static/2fa/apple.png" />
                                        </a>
                                    </p>
                                </Grid>
                                <Grid item>
                                    <p style={{fontWeight: 500}}>Microsoft Authenticator</p>
                                    <p>
                                        <a rel="noreferrer" target="_blank" href="https://play.google.com/store/apps/details?id=com.azure.authenticator">
                                            <img alt="Google" src="/static/2fa/google.png" />
                                        </a>
                                    </p>
                                    <p>
                                        <a rel="noreferrer" target="_blank" href="https://apps.apple.com/app/microsoft-authenticator/id983156458">
                                            <img alt="Apple" src="/static/2fa/apple.png" />
                                        </a>
                                    </p>
                                </Grid>
                            </Grid>
                            <DialogContentText>
                                <strong>2.</strong> {intl.formatMessage({id: 'account.twoFactorStep2', defaultMessage: 'Open your authenticator app and scan the QR-code below or copy the key and paste it in the authenticator app'})}
                            </DialogContentText>
                            <Grid container alignItems="center" spacing={0} justifyContent="center">
                                <Grid item xs={12} style={{textAlign: 'center', padding: 20}}>
                                    <div style={{paddingBottom:15}}><QRCodeSVG value={twoFactorSecret.uri} size={192} /></div>

                                    <Tooltip title="Copy secret to clipboard">
                                        <Button
                                            variant="outlined"
                                            color="primary"
                                            className={classes.button}
                                            onClick={() => {navigator.clipboard.writeText(twoFactorSecret.secret);}}
                                        >
                                            <FormattedMessage id="generic.copy" defaultMessage="Copy" />
                                        </Button>
                                    </Tooltip>
                                </Grid>
                                <Grid item xs={12}>
                                    <DialogContentText>
                                        <strong>3.</strong> {intl.formatMessage({id: 'account.twoFactorStep3', defaultMessage: 'Enter the six digit code below and click confirm'})}
                                    </DialogContentText>
                                    <Box display="flex"
                                        justifyContent="center"
                                        alignItems="center"
                                    >
                                        <TextField
                                            label={intl.formatMessage({id: 'generic.verificationCode', defaultMessage: 'Verification code'})}
                                            type="number"
                                            onWheel={(e) => e.target.blur()} // Avoid changing value by accident on mouse scroll
                                            InputProps={{
                                                inputProps: {
                                                    maxLength: 6,
                                                    step: 1,
                                                    min: 0,
                                                    pattern: "[0-9]+" // only allow numbers
                                                }
                                            }}
                                            value={otpVerifyCode}
                                            onChange={(event) => setOtpVerifyCode(event.target.value)}
                                        />
                                    </Box>
                                </Grid>
                            </Grid>
                        </Fragment>
                    : null}

                    {twoFactorStep === 3 ?
                        <Fragment>
                            <DialogContentText><FormattedMessage id="account.verifyDisableTwoFactor" defaultMessage="Enter your verification code to disable two-factor authentication" /></DialogContentText>
                            <Box display="flex"
                                justifyContent="center"
                                alignItems="center"
                            >
                                <TextField
                                    label={intl.formatMessage({id: 'generic.verificationCode', defaultMessage: 'Verification code'})}
                                    type="number"
                                    onWheel={(e) => e.target.blur()} // Avoid changing value by accident on mouse scroll
                                    InputProps={{
                                        inputProps: {
                                            maxLength: 6,
                                            step: 1,
                                            min: 0,
                                            pattern: "[0-9]+" // only allow numbers
                                        }
                                    }}
                                    value={otpVerifyCode}
                                    onChange={(event) => setOtpVerifyCode(event.target.value)}
                                />
                            </Box>
                        </Fragment>
                    : null}

                </DialogContent>
                <DialogActions>
                    {!forceTwoFactor ?
                        <Button variant="contained" onClick={() => {setTwoFactorStep(0)}} color="primary">
                            <FormattedMessage id="generic.cancel" defaultMessage="Cancel" />
                        </Button>
                    :
                        <Button variant="contained" color="primary" href="/logout">
                            <FormattedMessage id="appbar.logout" defaultMessage="Logout"/>
                        </Button>
                    }
                    <Button variant="contained" onClick={handleTwoFactorStep} color="secondary" disabled={(twoFactorStep === 1 && twoFactorPassword === '') || (twoFactorStep === 2 && otpVerifyCode.length !== 6)|| (twoFactorStep === 3 && otpVerifyCode.length !== 6)}>
                        {twoFactorStep === 3 ? <span>{intl.formatMessage({id: 'generic.disable', defaultMessage: 'Disable'})}</span> : <FormattedMessage id="generic.confirm" defaultMessage="Confirm" />}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    return(
        <Grid container spacing={0} justifyContent="center">
            <Grid item sm={10} md={5} lg={4}>
                <Box pb={1}>
                    <Typography variant="h6" paragraph>
                        <FormattedMessage id="account.accountHeader" defaultMessage="User Account" />
                    </Typography>
                    <form onSubmit={handleSubmit}>
                        <Box pb={1}>
                            <TextField
                                id="name"
                                fullWidth
                                disabled={demo}
                                required
                                label={intl.formatMessage({id: 'account.name', defaultMessage: 'Name'})}
                                value={values.name}
                                onChange={handleChange('name')}
                                margin="normal" />
                            <TextField
                                id="email"
                                fullWidth
                                disabled={demo}
                                type="email"
                                required
                                autoComplete="username"
                                label={intl.formatMessage({id: 'account.email', defaultMessage: 'Email'})}
                                value={values.email}
                                onChange={handleChange('email')}
                                margin="normal"
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <Tooltip title={intl.formatMessage({id: 'account.emailTooltip', defaultMessage: 'If you change your email address, a verification email will be sent to the new address.'})} placement="right">
                                                <HelpOutlineIcon />
                                            </Tooltip>
                                        </InputAdornment>
                                    )
                                }} />
                            <TextField
                                error={(values.password !== '' && values.password.length < minPasswordLength)}
                                helperText={(values.password !== '' && values.password.length < minPasswordLength ? intl.formatMessage({id: 'setPassword.passwordTooShort', defaultMessage: 'Your password has to be at least 10 characters'}) : ' ')}
                                id="password"
                                fullWidth
                                disabled={demo}
                                type="password"
                                autoComplete="new-password"
                                label={intl.formatMessage({id: 'account.newPassword', defaultMessage: 'New password'})}
                                value={values.password}
                                onChange={handleChange('password')}
                                margin="normal"
                                style={{marginBottom: 0}}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <Tooltip title={intl.formatMessage({id: 'account.newPasswordTooltip', defaultMessage: 'If you do not wish to change your password, you can leave this field blank.'})} placement="right">
                                                <HelpOutlineIcon />
                                            </Tooltip>
                                        </InputAdornment>
                                    )
                                }} />
                            <PasswordStrengthLazy password={values.password} minPasswordLength={minPasswordLength} />
                            <TextField
                                id="verifyPassword"
                                error={values.verifyPassword !== '' && values.password !== values.verifyPassword}
                                helperText={(values.verifyPassword !== '' && values.password !== values.verifyPassword ? intl.formatMessage({id: 'setPassword.passwordsDoNotMatch', defaultMessage: 'Passwords do not match!'}) : ' ')}
                                fullWidth
                                disabled={demo}
                                type="password"
                                autoComplete="new-password"
                                label={intl.formatMessage({id: 'account.repeatNewPassword', defaultMessage: 'Repeat new password'})}
                                value={values.verifyPassword}
                                onChange={handleChange('verifyPassword')}
                                margin="normal"
                                style={{marginTop: 0}}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <Tooltip title={intl.formatMessage({id: 'account.newPasswordTooltip', defaultMessage: 'If you do not wish to change your password, you can leave this field blank.'})} placement="right">
                                                <HelpOutlineIcon />
                                            </Tooltip>
                                        </InputAdornment>
                                    )
                                }} />
                        </Box>
                        <div className={classes.buttons}>
                            <Button
                                variant="contained"
                                className="submit"
                                color="secondary"
                                type="submit"
                                disabled={values.password !== values.verifyPassword || ('' !== values.password && values.password.length < minPasswordLength) || demo }
                            >
                                <FormattedMessage id="account.update" defaultMessage="Update" />
                            </Button>
                            {myUser && !myUser.attributes.twoFactorAuth ?
                                <Tooltip title={intl.formatMessage({id: 'account.enableTwoFactorTooltip', defaultMessage: 'Enable two-factor authentication'})}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => setTwoFactorStep(1)}
                                    >
                                        {intl.formatMessage({id: 'account.enableTwoFactor', defaultMessage: 'Enable 2FA'})}
                                    </Button>
                                </Tooltip>
                            : null}
                            {myUser && myUser.attributes.twoFactorAuth ?
                                <Tooltip title={intl.formatMessage({id: 'account.disableTwoFactorTooltip', defaultMessage: 'Disable two-factor authentication'})}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => setTwoFactorStep(3)}
                                    >
                                        {intl.formatMessage({id: 'account.disableTwoFactor', defaultMessage: 'Disable 2FA'})}
                                    </Button>
                                </Tooltip>
                            : null}
                        </div>
                    </form>
                    {twoFactorDialog()}
                </Box>
            </Grid>
        </Grid>
    );
}
