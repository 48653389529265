import { lazy, Suspense } from "react";
const Scatter = lazy(() => import(/* webpackChunkName: "charts" */'./Scatter'));

export default function ScatterLazy(props) {
    const {innerRef, ...rest} = props;
    return (
        <Suspense fallback={<div />}>
            <Scatter innerRef={innerRef} {...rest} />
        </Suspense>
    );
}