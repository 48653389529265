import { Fragment, useEffect, useRef } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { CATEGORY_COLORS } from "../../../../Constants/CategoryColors";
import { Box } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { demographicOptionTranslated } from "../../../Translation/DemographicOption";
import BarLazy from "../../../Chart/BarLazy";

export default function Comparisons(props) {

    const intl = useIntl();
    const chartRef = useRef(null);
    const chartData = {
        labels: [],
        datasets: []
    };
    const chartOptions = {
        maintainAspectRatio: false,
        responsive: true,
        scales: {
            x: {
                title: {
                    display: true,
                    text: intl.formatMessage({id: 'generic.scorePercent', defaultMessage: 'Score (%)'})
                },
                min: 0,
                max: 100,
            }
        },
        indexAxis: 'y',
        animation: {
            duration: 0,
        },
        hover: {
            mode: null
        },
        plugins: {
            datalabels: {
                anchor: 'end',
                align: 'end',
                clamp: true,
                offset: 5
            },
            tooltip: {
                enabled: false
            }
        },
    };

    const categories = {
        people: {
            background: CATEGORY_COLORS.people.dark,
            border: CATEGORY_COLORS.people.light,
            label: intl.formatMessage({id: 'generic.peopleCategory', defaultMessage: 'People'})

        },
        process: {
            background: CATEGORY_COLORS.process.dark,
            border: CATEGORY_COLORS.process.light,
            label: intl.formatMessage({id: 'generic.processCategory', defaultMessage: 'Process'})

        },
        purpose: {
            background: CATEGORY_COLORS.purpose.dark,
            border: CATEGORY_COLORS.purpose.light,
            label: intl.formatMessage({id: 'generic.purposeCategory', defaultMessage: 'Purpose'})
        },
        proactivity: {
            background: CATEGORY_COLORS.proactivity.dark,
            border: CATEGORY_COLORS.proactivity.light,
            label: intl.formatMessage({id: 'generic.proactivityCategory', defaultMessage: 'Proactivity'})
        },
        total: {
            background: '#ffd600',
            border: '#fcf6d7',
            label: intl.formatMessage({id: 'generic.total', defaultMessage: 'Total'})
        },
    };

    useEffect(() => {
        const mediaQueryListener = window.matchMedia('print');
        const onPrint = (mql) => {
            if (chartRef.current && mql.matches) {
                console.log('onPrint resize');
                chartRef.current.resize();
            }
        }

        const afterPrint = () => {
            if (chartRef.current) {
                console.log('afterPrint resize');
                chartRef.current.resize();
            }
        };
        window.addEventListener('afterprint', afterPrint);
        mediaQueryListener.addEventListener('change', onPrint);

        return () => {
            window.removeEventListener('afterprint', afterPrint);
            mediaQueryListener.removeEventListener('change', onPrint);
        }
    }, [chartRef]);

    if (!props.reportData) {
        return null;
    }

    Object.keys(categories).forEach(cat => {
        chartData.datasets.push({
            label: categories[cat].label,
            backgroundColor: categories[cat].background,
            borderColor: categories[cat].border,
            data: [],
            //maxBarThickness: 15,
            barPercentage: 0.6,
            hidden: cat === 'total'
        });
    });

    const optionLabel = (demographic, value) => {
        let label = value;
        const demQ = props.demographicQuestions.find(q => q.attributes.demographic === demographic);
        if (!demQ) {
            return label;
        }

        const option = demQ.attributes.options.find(o => o.attributes.value === value);
        if (option) {
            label = demographicOptionTranslated(
                option.attributes.title,
                option.attributes.translations,
                intl,
                demographic,
                props.demographicOptionLabels
            );
        }

        // Split on stuff in parenthesis or very long titles
        if (-1 !== label.indexOf('(')) {
            label = label.replace('(', '\n(').split('\n');
        } else if (label.length > 40) {
            const spaces = [...label.matchAll(/\s/g)].map(m => m.index);
            if (spaces.length === 1) {
                label = [label.substring(0, spaces[0]), label.substring(spaces[0]+1)];
            } else {
                let distanceToCenter = label.length;
                for (let i = 0; i < spaces.length; i++) {
                    const curDistance = Math.abs((label.length / 2) - spaces[i]);
                    const betterMatch = curDistance < distanceToCenter;
                    if (betterMatch) {
                        distanceToCenter = curDistance;
                    }

                    if (i === spaces.length-1 || !betterMatch) {
                        // BREAK STRING
                        label = [label.substring(0, spaces[i-1]), label.substring(spaces[i-1]+1)];
                        break;
                    }
                }
            }
        }

        return label;
    };

    Object.keys(props.reportData['demographic-comparison'].role).forEach(current => {
        chartData.labels.push(optionLabel('role', current));
        Object.keys(categories).forEach((cat, index) => {
            if ('total' !== cat) {
                chartData.datasets[index].data.push(props.reportData['demographic-comparison'].role[current].categories[cat].roundedScore);
            } else {
                chartData.datasets[index].data.push(props.reportData['demographic-comparison'].role[current].roundedScore);
            }
        });
    })

    if (!props.reportData['demographic-comparison'].role) {
        return (
            <Fragment>
                { props.title && props.print ? <h2 style={{fontWeight: 400}}>{props.title}</h2> : null}
                <Alert style={{justifyContent: 'center'}} severity="warning" variant="filled">
                    {intl.formatMessage({id: 'report.noDataError', defaultMessage: 'There is no data available for this report'})}
                </Alert>
            </Fragment>
        );
    }

    const roleScores = Object.keys(props.reportData['demographic-comparison'].role).map(role => {
        return parseFloat(props.reportData['demographic-comparison'].role[role].score);
    });

    const variation = Math.round(Math.max(...roleScores) - Math.min(...roleScores));

    const industryAverage = 5;
    const range = 2;
    const variationDescription = (variation) => {
        if (variation > industryAverage + range) {
            return intl.formatMessage({id: 'execSummaryComparison.higherThan', defaultMessage: 'higher than'});
        } else if (variation < industryAverage - range) {
            return intl.formatMessage({id: 'execSummaryComparison.lowerThan', defaultMessage: 'lower than'});
        }
        return intl.formatMessage({id: 'execSummaryComparison.similarTo', defaultMessage: 'similar to'});
    }

    return (
        <Fragment>
            { props.title && props.print ? <h2 style={{fontWeight: 400}}>{props.title}</h2> : null}
            <p>
                <FormattedMessage
                    id="execSummaryComparison.p1"
                    defaultMessage={
                        "The culture data can be filtered based on the demographic set-up of the survey, allowing a more in-depth "+
                        "view of potential variations between groups. One of the most important demographic filters is role, as "+
                        "this commonly shows variation between different groups. The variation between roles was {variation}%, "+
                        "which is {variationDescription} the average industry variation ({industryAverage}%). Typically, "+
                        "the more senior the role, the higher the score; if this is not the case, and/or the difference is higher "+
                        "than average, it is worth exploring why this might be. All role scores can be seen in the "+
                        "<strong>Comparisons</strong> view, and all data views can be filtered by role."
                    }
                    values={{
                        variation: variation,
                        industryAverage: industryAverage,
                        variationDescription: variationDescription(variation),
                        strong: chunks => <strong>{chunks}</strong>
                    }}
                />
            </p>

            <p>
                <FormattedMessage
                    id="execSummaryComparison.p2"
                    defaultMessage={
                        "To see differences between the demographics in specific issues, filtering should be used when looking at "+
                        "each data view. Furthermore, the calculation of highest and lowest combinations of scores across all "+
                        "demographics can be seen in the <strong>Hotspots</strong> view."
                    }
                    values={{
                        strong: chunks => <strong>{chunks}</strong>
                    }}
                />
            </p>
            <Box style={{overflow: 'auto', width: '75%', margin: '0 auto', height: '375px'}}>
                <BarLazy innerRef={chartRef} className="chart-canvas" data={chartData} options={chartOptions} dataLabels={true} />
            </Box>
        </Fragment>
    );
}
