import { useLocation, useHistory } from "react-router-dom";
import axios from "axios";
import { useContext, useEffect, useRef } from "react";
import { NotificationContext } from "../../Context/NotificationContext";
import { useIntl } from "react-intl";
import { NO_LOGIN_PATHS } from "../../Constants/NoLoginPaths";

export default function TokenErrorHandler(props) {
    const history = useHistory();
    const historyRef = useRef();
    historyRef.current = () => history;
    const { updateNotification } = useContext(NotificationContext);
    const updateNotificationRef = useRef();
    updateNotificationRef.current = (open, message, level) => {
        updateNotification(open, message, level);
    }

    const intlRef = useRef();
    intlRef.current = useIntl();

    const locationRef = useRef();
    locationRef.current = useLocation();

    useEffect(() => {
        axios.interceptors.response.use((response) => response, (error) => {
            if (error.response && error.response.status && error.response.status === 403 &&
                error.response.data && error.response.data.error && error.response.data.error.detail === 'X-Auth-Token header value expired and/or wrong' // Only on 403 errors caused by the token
            ) {
                const isNoLoginPath = NO_LOGIN_PATHS.some(path => locationRef.current.pathname.startsWith(path));
                if (locationRef.current.pathname !== '/' &&
                    !isNoLoginPath
                ) {
                    // Set where to redirect to after user has logged in, used and cleared in <Login/> component
                    sessionStorage.setItem('redir', locationRef.current.pathname + locationRef.current.search);
                    updateNotificationRef.current(true, intlRef.current.formatMessage({id: 'generic.sessionExpired', defaultMessage: 'Your login session has expired!'}), 'error');
                    historyRef.current().push('/logout');
                }
            }

            return Promise.reject(error);
        })
      }, [historyRef]);

    return null;
}