import { Component } from "react";
import CrashService from "../../Services/CrashService";

class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = { error: null };
    }

    componentDidCatch(error, info) {
        // Catch errors in child components and set the error details
        this.setState({error: error});

        const errorMsg = error && error.message ? error.message : 'unknown error';
        const errorStack = error && error.stack ? error.stack : '';
        const compStack = info && info.componentStack ? info.componentStack : '';

        CrashService.log(errorMsg, errorStack, compStack).catch((error) => {
            // Out of options
            console.log(error);
        });
    }

    render() {
        // Only render if an error occurred
        if (this.state.error) {
            return (
                <div style={{paddingLeft: 10, paddingRight: 10}}>
                    <h2>Something went wrong.</h2>
                    <p>An unexpected error has occurred, this has been logged.</p>
                    <p>Please <a href="#err" onClick={() => window.location.reload(true)}>reload the page</a> and try again.</p>
                </div>
            );
        }
        // Render child components
        return this.props.children;
    }
}

export default ErrorBoundary;