import { Fragment } from "react";
import { FormattedMessage, useIntl } from "react-intl";

export default function BasicDataAndBenchmarking(props) {
    const intl = useIntl();

    const dimensionScores = Object.keys(props.reportData['basic-data'].dimensions).map(d => {
        return parseFloat(props.reportData['basic-data'].dimensions[d].score);
    });
    const minDimScore = Math.min(...dimensionScores.filter(x => !isNaN(x)));
    const maxDimScore = Math.max(...dimensionScores.filter(x => !isNaN(x)));

    const scoreDescription = (score) => {
        if (score >= 85) {
            return intl.formatMessage({id: 'generic.veryHigh', defaultMessage: 'very high'});
        } else if (score >= 70) {
            return intl.formatMessage({id: 'generic.high', defaultMessage: 'high'});
        } else if (score >= 55) {
            return intl.formatMessage({id: 'generic.medium', defaultMessage: 'medium'});
        } else if (score >= 40) {
            return intl.formatMessage({id: 'generic.low', defaultMessage: 'low'});
        }
        return intl.formatMessage({id: 'generic.veryLow', defaultMessage: 'very low'});
    }

    return (
        <Fragment>
            { props.title && props.print ? <h2 style={{fontWeight: 400}}>{props.title}</h2> : null}
            <p>
                <FormattedMessage
                    id="execSummaryBasicData.p1"
                    defaultMessage={
                        "The overall total culture result was {totalCultureScore}%, with dimension averages ranging from "+
                        "{minDimScore}% to {maxDimScore}%, which represents mostly {minDimScoreDescription} to "+
                        "{maxDimScoreDescription} scores overall. It can be compared to a mid-point of 60% and a current "+
                        "industry average of {industryAvgScore}%."
                    }
                    values={{
                        totalCultureScore: Math.round(props.reportData['basic-data'].total.score),
                        minDimScore: Math.round(minDimScore),
                        maxDimScore: Math.round(maxDimScore),
                        minDimScoreDescription: scoreDescription(minDimScore),
                        maxDimScoreDescription: scoreDescription(maxDimScore),
                        industryAvgScore: props.reportData['benchmark-industry'].industry ? Math.round(props.reportData['benchmark-industry'].industry.total.score) : '-'
                    }}
                />
            </p>

            <p>
                <FormattedMessage
                    id="execSummaryBasicData.p2"
                    defaultMessage="Comparisons to the industry average (which are dynamic) can be seen in the <strong>Benchmarking</strong> view."
                    values = {{
                        strong: chunks => <strong>{chunks}</strong>
                    }}
                />
            </p>

            {/* TODO: Not implemented yet */}
            {/* It is also possible to compare scores to &#39;best in class&#39; which represents the top 10% of all
            companies rather than the average, as well as the most relevant product categories. */}
        </Fragment>
    );
}
