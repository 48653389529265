import { createTheme } from '@material-ui/core/styles';

const white = '#ffffff';

// SSAFE theme
const ssafe = createTheme({
    palette: {
        primary: {
            main: '#3c3e40',
        },
        secondary: {
            main: '#005a98',
        },
        text: {
            primary: 'rgba(0, 0, 0, 0.87)',
        },
        background: {
            default: white,
            paper: white,
        },
        // Custom palette colors
        people: {
            main: '#0f5192',
            light: '#e7eef4'
        },
        process: {
            main: '#c4c3c3',
            light: '#f1f1f1'
        },
        purpose: {
            main: '#0f5192',
            light: '#e7eef4'
        },
        proactivity: {
            main: '#c4c3c3',
            light: '#f1f1f1'
        }
    },
    overrides: {
        MuiSnackbarContent: {
            root: {
                fontSize: "12pt"
            }
        },
        MuiTooltip: {
            tooltip: {
                fontSize: "0.75rem",
                fontWeight: 400,
            }
        }
    }
  });
export default ssafe;