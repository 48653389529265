import { Box, Button, Grid, InputAdornment, TextField, Tooltip, Typography, FormControl, FormControlLabel, Checkbox } from "@material-ui/core";
import { FormattedMessage, useIntl } from 'react-intl'
import { useContext, useEffect, useState, useMemo } from "react";
import { NotificationContext } from "../../Context/NotificationContext";
import { AppBarTitleContext } from "../../Context/AppBarTitleContext";
import { UserContext } from "../../Context/UserContext";
import { SpinnerContext } from "../../Context/SpinnerContext";
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import UserService from "../../Services/UserService";
import CompanyService from "../../Services/CompanyService";
import { Autocomplete } from "@material-ui/lab";
import debounce from 'lodash.debounce';
import { Redirect } from "react-router-dom";
import { Fragment } from "react";
import PasswordStrengthLazy from "../Auth/SetPassword/PasswordStrengthLazy";

export default function UserNew() {
    const { updateNotification } = useContext(NotificationContext);
    const { user } = useContext(UserContext);
    const { updateTitle } = useContext(AppBarTitleContext);
    const { updateLoading } = useContext(SpinnerContext);
    const intl = useIntl();
    const minPasswordLength = 10;

    const [redirect, setRedirect] = useState(null);

    const [values, setValues] = useState({
        enabled: false,
        company: '',
        role: '',
        name: '',
        email: '',
        emailVerified: true,
        password: '',
        verifyPassword: '',
        locale: '',
    });

    const [companies, setCompanies] = useState([]);
    const [loadingCompanies, setLoadingCompanies] = useState(false);

    useEffect(() => {
        updateTitle(null);
        // eslint-disable-next-line
    }, []);

    const handleChange = name => event => {
        if ('enabled' === name) {
            setValues({ ...values, [name]: event.target.checked });
        } else {
            setValues({ ...values, [name]: event.target.value });
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        updateLoading(true);
        const valuesCopy = JSON.parse(JSON.stringify(values ? values : {}));
        delete valuesCopy.verifyPassword;
        delete valuesCopy.company;
        valuesCopy.locale = valuesCopy.locale ? valuesCopy.locale : null; // Null instead of empty string if not set
        UserService.createUser(valuesCopy, {company: values.company}).then(function () {
            updateNotification(true, intl.formatMessage({id: 'account.accountCreated', defaultMessage: 'Account created'}), 'success')
            setRedirect('/users');
        }).catch(function (error) {
            updateNotification(true, intl.formatMessage({id: 'generic.unknownError', defaultMessage: 'An unknown error occurred!'}), 'error');
        }).then(function() {
            updateLoading(false);
        });
    };

    const allowedRoles = () => {
        if ('admin' === user.role) {
            return ['admin', 'partner', 'premium', 'premium-admin', 'brcgs-admin'];

        }

        if ('premium-admin' === user.role) {
            return ['premium'];
        }

        return [];
    }


    return(
        <Fragment>
            {redirect ? <Redirect to={redirect} /> : null}
            <Grid container spacing={0} justifyContent="center">
                <Grid item sm={10} md={5} lg={4}>
                    <Box pb={1}>
                        <Typography variant="h6" paragraph>
                            <FormattedMessage id="createUser.header" defaultMessage="Create User" />
                        </Typography>
                        <form onSubmit={handleSubmit}>
                            <Box pb={1}>
                                <TextField
                                    id="name"
                                    fullWidth
                                    required
                                    label="Name"
                                    value={values.name}
                                    onChange={handleChange('name')}
                                    margin="normal" />
                                <TextField
                                    id="email"
                                    fullWidth
                                    type="email"
                                    required
                                    label="Email"
                                    value={values.email}
                                    onChange={handleChange('email')}
                                    margin="normal" />
                                <TextField
                                    id="role"
                                    fullWidth
                                    select
                                    required
                                    label="Role"
                                    value={values.role}
                                    onChange={handleChange('role')}
                                    SelectProps={{
                                        native: true,
                                    }}
                                    margin="normal">
                                        <option value="" disabled></option>
                                        {allowedRoles().map(option => (
                                            <option key={option} value={option}>
                                                {option}
                                            </option>
                                        ))}
                                </TextField>

                                <Autocomplete
                                    loading={loadingCompanies}
                                    options={companies}
                                    getOptionSelected={(option, value) => option.id === value.id}
                                    getOptionLabel={(option) => option.attributes.name}
                                    onChange={(event, newValue) => {
                                        setValues(oldValues => ({ ...oldValues, ...{company: (newValue ? {type: 'company', id: newValue.id}: '')}}));
                                    }}
                                    onInputChange = {useMemo(() => debounce((event, value) => {
                                        setLoadingCompanies(true);
                                        CompanyService.companies(0, 20, 'name', value)
                                        .then(function (response) {
                                            setCompanies(response.data.data);
                                        }).catch(function () {
                                            updateNotification(true, intl.formatMessage({id: 'generic.unknownError', defaultMessage: 'An unknown error occurred!'}), 'error');
                                        }).then(function() {
                                            setLoadingCompanies(false);
                                        });
                                    }, 200), [intl, updateNotification])}
                                    renderInput={(params) => <TextField {...params} margin="normal" label="Company" required />}
                                />

                                <TextField
                                    error={(values.password !== '' && values.password.length < minPasswordLength)}
                                    helperText={(values.password !== '' && values.password.length < minPasswordLength ? intl.formatMessage({id: 'userEdit.passwordTooShort', defaultMessage: 'The password has to be at least 10 characters'}) : ' ')}
                                    id="password"
                                    fullWidth
                                    type="password"
                                    label="New password"
                                    value={values.password}
                                    onChange={handleChange('password')}
                                    margin="normal"
                                    style={{marginBottom: 0}}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <Tooltip title={intl.formatMessage({id: 'userEdit.newPasswordTooltip', defaultMessage: 'If you do not wish to change the password, you can leave this field blank.'})} placement="right">
                                                    <HelpOutlineIcon />
                                                </Tooltip>
                                            </InputAdornment>
                                        )
                                    }} />
                                <PasswordStrengthLazy password={values.password} minPasswordLength={minPasswordLength} />
                                <TextField
                                    id="verifyPassword"
                                    error={values.verifyPassword !== '' && values.password !== values.verifyPassword}
                                    helperText={(values.verifyPassword !== '' && values.password !== values.verifyPassword ? intl.formatMessage({id: 'userEdit.passwordsDoNotMatch', defaultMessage: 'Passwords do not match!'}) : ' ')}
                                    fullWidth
                                    type="password"
                                    label="Repeat new password"
                                    value={values.verifyPassword}
                                    onChange={handleChange('verifyPassword')}
                                    margin="normal"
                                    style={{marginTop: 0}}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <Tooltip title={intl.formatMessage({id: 'userEdit.newPasswordTooltip', defaultMessage: 'If you do not wish to change the password, you can leave this field blank.'})} placement="right">
                                                    <HelpOutlineIcon />
                                                </Tooltip>
                                            </InputAdornment>
                                        )
                                    }}
                                />
                                <FormControl fullWidth>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={values.enabled}
                                                onChange={handleChange('enabled')}
                                                name="enabled"
                                                color="secondary"
                                            />
                                        }
                                        label="Enabled?"
                                    />
                                </FormControl>
                            </Box>
                            <Button
                                variant="contained"
                                className="submit"
                                color="secondary"
                                type="submit"
                                disabled={values.password !== values.verifyPassword || (values.password.length < minPasswordLength) }
                            >
                                <FormattedMessage id="generic.create" defaultMessage="Create" />
                            </Button>
                        </form>
                    </Box>
                </Grid>
            </Grid>
        </Fragment>
    );
}
