import { Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import { Fragment } from "react";
import { FormattedMessage } from "react-intl";
import ElementLabel from "../../../Translation/ElementLabel";
import { hotspotDemographicLabel } from "./Common";
import { CATEGORY_COLORS } from '../../../../Constants/CategoryColors';

export default function HighestHotspots(props) {

    const elementsColStyle = {
        whiteSpace: 'normal',
        wordWrap: 'break-word',
        paddingTop: '1px',
        paddingBottom: '1px',
        border: '1px solid #e0e0e0',
        height: 40
    };

    if (!props.reportData['element-hotspots'].all) {
        return null;
    }

    const topHotspots = props.reportData['element-hotspots'].all.slice(0, 6);

    return (
        <Fragment>
            { props.title && props.print ? <h2 style={{fontWeight: 400}}>{props.title}</h2> : null}
            <p>
                <FormattedMessage id="execSummaryHighHotspots.p1"
                    defaultMessage={
                        "The highest hotspots are the highest element combinations in the data, and show where specific "+
                        "cultural sub-groups within the company have particularly high scores. The highest hotspot score "+
                        "in this case was {highestHotspotScore}. Six of the highest hotspots shown below "+
                        "as an example, and the full range can be viewed in the <strong>Hotspots</strong> view and filtered "+
                        "by Culture Type for most relevance."
                    }
                    values= {{
                        highestHotspotScore: topHotspots.length ? Math.round(topHotspots[0].score)+'%' : '-',
                        strong: chunks => <strong>{chunks}</strong>
                    }}
                />
            </p>

            <Table className="reportTable elementsReport">
                <TableHead style={{backgroundColor: '#3c3e40'}}>
                    <TableRow>
                        <TableCell style={{ ...elementsColStyle, ...{color: 'white', width:'45%', fontSize: '9pt', lineHeight: 1.3, padding: 6}}}>
                            <FormattedMessage id="elementHotspotsReport.highestDemographicCombinations" defaultMessage="Highest Demographic Combinations" />
                        </TableCell>
                        <TableCell style={{ ...elementsColStyle, ...{color: 'white', width:'45%', fontSize: '9pt', lineHeight: 1.3, padding: 6}}}>
                            <FormattedMessage id="generic.element" defaultMessage="Element" />
                        </TableCell>
                        <TableCell style={{ ...elementsColStyle, ...{color: 'white', textAlign: 'center', width: '10%', fontSize: '9pt', lineHeight: 1.3, padding: 6}}}>
                            <FormattedMessage id="generic.scorePercent" defaultMessage="Score (%)" />
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {topHotspots.map((element, index) => {
                        return (
                            <TableRow key={index} style={{backgroundColor: CATEGORY_COLORS[element.category].light, borderBottom: '10px solid white'}}>
                                <TableCell style={{borderLeft: '5px solid '+CATEGORY_COLORS[element.category].dark, fontSize: '9pt', lineHeight: 1.3, padding: 6}}>
                                    {hotspotDemographicLabel(element, props.demographicQuestions, props.demographicOptionLabels, props.print)}
                                </TableCell>
                                <TableCell style={{fontSize: '9pt', lineHeight: 1.3, padding: 6}}>
                                    <ElementLabel element={element.element} />
                                </TableCell>
                                <TableCell style={{textAlign: 'center', fontSize: '9pt', lineHeight: 1.3, padding: 6}}>
                                    {parseFloat(element.score).toFixed(1)}
                                </TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>

            <p>
                <FormattedMessage id="execSummaryHighHotspots.p2"
                    defaultMessage={
                        "The highest Hotspots will often reflect some of the highest average element scores, but they can "+
                        "also introduce new elements for consideration. They can also highlight trends, for example there "+
                        "might be certain elements that feature more commonly than others and show where elements of the "+
                        "culture are working particularly well for multiple sub-groups."
                    }
                />
            </p>
        </Fragment>
    );
}
