import { Fragment } from "react";
import ElementsTable from "./ElementsTable";
import { FormattedMessage } from "react-intl";

export default function ReinforcementDimension(props) {
    return (
        <Fragment>
            <p>
                <FormattedMessage
                    id="execSummaryDiscAndRecs.reinforcement"
                    defaultMessage={
                        "Feeling appreciated and incentivised are key drivers of employee engagement and performance in the work "+
                        "place, and Schein identifies ‘how rewards and status are allocated’ as a primary embedding mechanism for "+
                        "culture. To shape positive behaviour and motivate employees in their jobs it is important to incentivise "+
                        "and reward them. It is also important that they perceive these incentives and rewards to be fair and "+
                        "accessible. This topic is a common challenge in the industry, as it combines management skill with "+
                        "psychological understanding and requires constant effort throughout all levels and departments. Not only "+
                        "do people need to feel appreciated and incentivised in general, they also need to see safety and quality "+
                        "as clearly and consistently embedded within reward and incentive schemes, and as key factors that will "+
                        "help them fit in and do well in the company."
                    }
                />
            </p>
            <ElementsTable dimension={props.dimension} elements={props.elements} lowestElements={props.lowestElements} />
        </Fragment>
    );
}
