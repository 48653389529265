import { Button, Chip, CircularProgress, Grid, Table, TableBody, TableCell, TableHead, TableRow, TableSortLabel, TextField, Typography } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { Fragment, useContext, useEffect, useMemo, useRef, useState } from "react";
import debounce from 'lodash.debounce';
import SurveyService from "../../Services/SurveyService";
import { NotificationContext } from "../../Context/NotificationContext";
import { SpinnerContext } from "../../Context/SpinnerContext";
import ElementMappingService from "../../Services/ElementMappingService";
import { CATEGORY_COLORS } from "../../Constants/CategoryColors";
import DimensionLabel from "../Translation/DimensionLabel";
import CategoryLabel from "../Translation/CategoryLabel";
import { typeTranslated } from "../Translation/Type";
import { useIntl } from "react-intl";
import { useLocation } from "react-router-dom";

export default function ElementsCompare() {
    const intl = useIntl();
    const { search } = useLocation();
    const searchParams = useMemo(() => new URLSearchParams(search), [search]);
    const combinedHashSurveyA = searchParams.get('surveyA');
    const combinedHashSurveyB = searchParams.get('surveyB');

    const { updateLoading } = useContext(SpinnerContext);
    const updateLoadingRef = useRef();
    updateLoadingRef.current = (loading) => {
        updateLoading(loading);
    };

    const { updateNotification } = useContext(NotificationContext);
    const updateNotificationRef = useRef();
    updateNotificationRef.current = (open, message, level) => {
        updateNotification(open, message, level);
    }

    const [autoCompare, setAutoCompare] = useState(false);
    const [loadingSurveysA, setLoadingSurveysA] = useState(false);
    const [loadingSurveysB, setLoadingSurveysB] = useState(false);
    const [elementMappings, setElementMappings] = useState(null);
    const [surveysA, setSurveysA] = useState([]);
    const [surveysB, setSurveysB] = useState([]);
    const [surveyA, setSurveyA] = useState(null);
    const [surveyB, setSurveyB] = useState(null);
    const [titleSurveyA, setTitleSurveyA] = useState(null);
    const [titleSurveyB, setTitleSurveyB] = useState(null);
    const [typesSurveyA, setTypesSurveyA] = useState([]);
    const [typesSurveyB, setTypesSurveyB] = useState([]);
    const [elementsSurveyA, setElementsSurveyA] = useState(null);
    const [elementsSurveyB, setElementsSurveyB] = useState(null);

    const [sortElementsA, setSortElementsA] = useState({col: 'dimension', order: 'asc'});
    const [sortElementsB, setSortElementsB] = useState({col: 'dimension', order: 'asc'});

    useEffect(() => {
        if (combinedHashSurveyA && combinedHashSurveyB) {
            const surveyPromiseA = SurveyService.surveyByHash(combinedHashSurveyA);
            const surveyPromiseB = SurveyService.surveyByHash(combinedHashSurveyB);
            updateLoadingRef.current(true);
            Promise.all([surveyPromiseA, surveyPromiseB]).then(function(response) {
                const [newestSurveyA, newestSurveyB] = response;
                setSurveyA(newestSurveyA.data.data);
                setSurveyB(newestSurveyB.data.data);
                setAutoCompare(true);
            }).catch((error) => {
                console.error(error);
                updateNotificationRef.current(true, 'An unknown error occurred!', 'error');
            }).then(() => {
                updateLoadingRef.current(false);
            });
        }
    }, [combinedHashSurveyA, combinedHashSurveyB]);

    // All element mappings are fetched at "compare"
    const compare = () => {
        setElementsSurveyA(null);
        setElementsSurveyB(null);
        setElementMappings(null);
        setTypesSurveyA(null);
        setTypesSurveyB(null);

        updateLoading(true);
        setTypesSurveyA(surveyA.attributes.questionTypes);
        setTypesSurveyB(surveyB.attributes.questionTypes);
        setTitleSurveyA(surveyA.relationships['survey-title'] ? surveyA.relationships['survey-title'].data.title : surveyA.attributes.title);
        setTitleSurveyB(surveyB.relationships['survey-title'] ? surveyB.relationships['survey-title'].data.title : surveyB.attributes.title);
        const surveyReportPromiseA = SurveyService.surveyReport(surveyA.id, 'elements');
        const surveyReportPromiseB = SurveyService.surveyReport(surveyB.id, 'elements');
        Promise.all([ElementMappingService.allElementMappings(), surveyReportPromiseA, surveyReportPromiseB]).then(function(response) {
            const [ems, surveyReportA, surveyReportB] = response;
            setElementMappings(ems);

            // Build map based on element.shortName
            const mapA = {};
            const mapB = {};
            surveyReportA.data.data.forEach(e => mapA[e.shortName] = e);
            surveyReportB.data.data.forEach(e => mapB[e.shortName] = e);
            setElementsSurveyA(mapA);
            setElementsSurveyB(mapB);
        }).catch((error) => {
            console.error(error);
            updateNotification(true, 'An unknown error occurred!', 'error');
        }).then(() => {
            updateLoading(false);
        });
    }

    const compareRef = useRef();
    compareRef.current = () => {
        compare();
    };

    useEffect(() => {
        if (surveyA && surveyB && autoCompare) {
            compareRef.current();
        }
    }, [surveyA, surveyB, autoCompare]);

    // Compare and find the elements that are not in both surveys (take mapping into account)
    const surveyDiffA = [];
    const surveyDiffB = [];

    if (elementsSurveyA && elementsSurveyB && elementMappings && typesSurveyA && typesSurveyB) {
        Object.keys(elementsSurveyA).forEach((keyA, i) => {
            // Find in report B
            if (!(keyA in elementsSurveyB)) {
                // Check if keyA is in element maps AND is present in B
                let found = false;
                for (const em of elementMappings) {
                    if (em.attributes.elements && -1 !== em.attributes.elements.indexOf(keyA)) {
                        // Check if any of the elements in the map are in surveyB
                        for (let j = 0; j < em.attributes.elements.length; j++) {
                            if (em.attributes.elements[j] in elementsSurveyB) {
                                found = true;
                                break;
                            }
                        }
                    }

                    if (found) {
                        break;
                    }
                }

                if (!found && -1 !== typesSurveyB.indexOf(elementsSurveyA[keyA].type)) {
                    surveyDiffA.push(elementsSurveyA[keyA]);
                }
            }
        });

        Object.keys(elementsSurveyB).forEach((keyB, i) => {
            // Find in report A
            if (!(keyB in elementsSurveyA)) {
                // Check if keyA is in element maps AND is present in B
                let found = false;
                for (const em of elementMappings) {
                    if (em.attributes.elements && -1 !== em.attributes.elements.indexOf(keyB)) {
                        // Check if any of the elements in the map are in surveyA
                        for (let j = 0; j < em.attributes.elements.length; j++) {
                            if (em.attributes.elements[j] in elementsSurveyA) {
                                found = true;
                                break;
                            }
                        }
                    }

                    if (found) {
                        break;
                    }
                }

                if (!found && -1 !== typesSurveyA.indexOf(elementsSurveyB[keyB].type)) {
                    surveyDiffB.push(elementsSurveyB[keyB]);
                }
            }
        });
    }

    // Sort surveyDiffB and surveyDiffA
    surveyDiffA.sort((a, b) => {
        if (sortElementsA.order === 'asc') {
            return a[sortElementsA.col] > b[sortElementsA.col] ? 1 : -1;
        } else {
            return a[sortElementsA.col] < b[sortElementsA.col] ? 1 : -1;
        }
    });
    surveyDiffB.sort((a, b) => {
        if (sortElementsB.order === 'asc') {
            return a[sortElementsB.col] > b[sortElementsB.col] ? 1 : -1;
        } else {
            return a[sortElementsB.col] < b[sortElementsB.col] ? 1 : -1;
        }
    });

    return (
        <Grid container spacing={2} justifyContent="center">
            <Grid item sm={6}>
                <Typography variant="h6">Survey A</Typography>
                <Autocomplete
                    value={surveyA}
                    disabled={false}
                    options={surveysA}
                    loading={loadingSurveysA}
                    getOptionDisabled={(option) => (surveyB !== null && option.id === surveyB.id)}
                    onInputChange = {useMemo(() => debounce((event, value) => {
                        setLoadingSurveysA(true)
                        SurveyService.surveysByHash(0, 20, null, value)
                        .then(function (response) {
                            setSurveysA(response.data.data);
                        }).catch(function () {
                            updateNotification(true, 'An unknown error occurred!', 'error');
                        }).then(function() {
                            setLoadingSurveysA(false);
                        });
                    }, 200), [updateNotification])}
                    onChange={(event, newValue) => {
                        if (newValue) {
                            setSurveyA(newValue);
                        }
                    }}
                    getOptionLabel={(option) => null !== option ? (option.relationships['survey-title'] ? option.relationships['survey-title'].data.title : option.attributes.title) : null}
                    renderOption={(option) => (
                        <Fragment>
                            {null !== option ? (option.relationships['survey-title'] ? option.relationships['survey-title'].data.title : option.attributes.title) : null}
                            <Chip color="primary" label={'Status: '+option.attributes.providerStatus} size="small" style={{marginRight: 5, marginLeft: 5 }} />
                            <Chip color="secondary" label={'Company: '+option.relationships.company.data.name} size="small" style={{marginRight: 5 }} />
                            {option.attributes.lastRespondentAt ? <Chip label={'Last response: '+(new Date(option.attributes.lastRespondentAt)).toLocaleDateString('en-gb', {year: 'numeric', month: 'short', day: 'numeric'})} size="small" style={{marginRight: 5}} />: null}
                        </Fragment>
                    )}
                    getOptionSelected={(option, value) => option.id === value.id}
                    renderInput={(params) => (
                        <TextField {...params}
                            label="Survey"
                            required
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                  <Fragment>
                                      {loadingSurveysA ? <CircularProgress color="inherit" size={20} /> : null}
                                      {params.InputProps.endAdornment}
                                  </Fragment>
                                ),
                              }}
                        />
                    )}
                />
            </Grid>
            <Grid item sm={6}>
                <Typography variant="h6">Survey B</Typography>
                <Autocomplete
                    value={surveyB}
                    disabled={false}
                    options={surveysB}
                    loading={loadingSurveysB}
                    getOptionDisabled={(option) => (surveyA !== null && option.id === surveyA.id)}
                    onInputChange = {useMemo(() => debounce((event, value) => {
                        setLoadingSurveysB(true)
                        SurveyService.surveysByHash(0, 20, null, value)
                        .then(function (response) {
                            setSurveysB(response.data.data);
                        }).catch(function () {
                            updateNotification(true, 'An unknown error occurred!', 'error');
                        }).then(function() {
                            setLoadingSurveysB(false);
                        });
                    }, 200), [updateNotification])}
                    onChange={(event, newValue) => {
                        if (newValue) {
                            setSurveyB(newValue);
                        }
                    }}
                    getOptionLabel={(option) => null !== option ? (option.relationships['survey-title'] ? option.relationships['survey-title'].data.title : option.attributes.title) : null}
                    renderOption={(option) => (
                        <Fragment>
                            {null !== option ? (option.relationships['survey-title'] ? option.relationships['survey-title'].data.title : option.attributes.title) : null}
                            <Chip color="primary" label={'Status: '+option.attributes.providerStatus} size="small" style={{marginRight: 5, marginLeft: 5 }} />
                            <Chip color="secondary" label={'Company: '+option.relationships.company.data.name} size="small" style={{marginRight: 5 }} />
                            {option.attributes.lastRespondentAt ? <Chip label={'Last response: '+(new Date(option.attributes.lastRespondentAt)).toLocaleDateString('en-gb', {year: 'numeric', month: 'short', day: 'numeric'})} size="small" style={{marginRight: 5}} />: null}
                        </Fragment>
                    )}
                    getOptionSelected={(option, value) => option.id === value.id}
                    renderInput={(params) => (
                        <TextField {...params}
                            label="Survey"
                            required
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                  <Fragment>
                                      {loadingSurveysB ? <CircularProgress color="inherit" size={20} /> : null}
                                      {params.InputProps.endAdornment}
                                  </Fragment>
                                ),
                              }}
                        />
                    )}
                />
            </Grid>
            <Grid item sm={12}>
                <Button variant="contained" onClick={() => {compare()}} color="secondary" disabled={ !surveyA || !surveyB }>
                    Compare Elements
                </Button>
            </Grid>
            <Grid item sm={6} style={{borderRight:'1px solid #ccc'}}>
                {}
                <Typography variant="h6">Elements Found in A But Not B</Typography>
                {typesSurveyA && titleSurveyA ? <Fragment>
                    <strong>Title:</strong> {titleSurveyA}<br />
                    <strong>Question types:</strong> {typesSurveyA.map(t => typeTranslated(t, intl)).join(', ')}
                </Fragment> : null}
                {surveyDiffA && surveyDiffA.length ?
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    <TableSortLabel
                                        active={'category' === sortElementsA.col}
                                        direction={sortElementsA.order}
                                        onClick={() => {setSortElementsA({col: 'category', order: (sortElementsA.col === 'category' ? (sortElementsA.order === 'asc' ? 'desc' : 'asc') : sortElementsA.order)})}}
                                    >
                                        Category
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell>
                                    <TableSortLabel
                                        active={'dimension' === sortElementsA.col}
                                        direction={sortElementsA.order}
                                        onClick={() => {setSortElementsA({col: 'dimension', order: (sortElementsA.col === 'dimension' ? (sortElementsA.order === 'asc' ? 'desc' : 'asc') : sortElementsA.order)})}}
                                    >
                                        Dimension
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell>
                                    <TableSortLabel
                                        active={'type' === sortElementsA.col}
                                        direction={sortElementsA.order}
                                        onClick={() => {setSortElementsA({col: 'type', order: (sortElementsA.col === 'type' ? (sortElementsA.order === 'asc' ? 'desc' : 'asc') : sortElementsA.order)})}}
                                    >
                                        Type
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell>
                                    <TableSortLabel
                                        active={'shortName' === sortElementsA.col}
                                        direction={sortElementsA.order}
                                        onClick={() => {setSortElementsA({col: 'shortName', order: (sortElementsA.col === 'shortName' ? (sortElementsA.order === 'asc' ? 'desc' : 'asc') : sortElementsA.order)})}}
                                    >
                                        Element
                                    </TableSortLabel>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {surveyDiffA.map((diffA, i) => <TableRow key={i} style={{backgroundColor: CATEGORY_COLORS[diffA.category].light, borderBottom: '8px solid white'}}>
                                <TableCell style={{borderLeft: '5px solid '+CATEGORY_COLORS[diffA.category].dark}}>
                                    <CategoryLabel category={diffA.category} />
                                </TableCell>
                                <TableCell><DimensionLabel dimension={diffA.dimension} /></TableCell>
                                <TableCell>{typeTranslated(diffA.type, intl)}</TableCell>
                                <TableCell>
                                    {diffA.shortName}
                                </TableCell>
                            </TableRow>)}
                        </TableBody>
                    </Table>
                : null}
            </Grid>
            <Grid item sm={6} style={{borderLeft:'1px solid #ccc'}}>
                <Typography variant="h6">Elements Found in B But Not A</Typography>
                {typesSurveyB && titleSurveyB ? <Fragment>
                    <strong>Title:</strong> {titleSurveyB}<br />
                    <strong>Question types:</strong> {typesSurveyB.map(t => typeTranslated(t, intl)).join(', ')}
                </Fragment> : null}
                {surveyDiffB && surveyDiffB.length ?
                    <Table>
                        <TableHead>
                            <TableRow>
                            <TableCell>
                                <TableSortLabel
                                        active={'category' === sortElementsB.col}
                                        direction={sortElementsB.order}
                                        onClick={() => {setSortElementsB({col: 'category', order: (sortElementsB.col === 'category' ? (sortElementsB.order === 'asc' ? 'desc' : 'asc') : sortElementsB.order)})}}
                                    >
                                        Category
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell>
                                    <TableSortLabel
                                        active={'dimension' === sortElementsB.col}
                                        direction={sortElementsB.order}
                                        onClick={() => {setSortElementsB({col: 'dimension', order: (sortElementsB.col === 'dimension' ? (sortElementsB.order === 'asc' ? 'desc' : 'asc') : sortElementsB.order)})}}
                                    >
                                        Dimension
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell>
                                    <TableSortLabel
                                        active={'type' === sortElementsB.col}
                                        direction={sortElementsB.order}
                                        onClick={() => {setSortElementsB({col: 'type', order: (sortElementsB.col === 'type' ? (sortElementsB.order === 'asc' ? 'desc' : 'asc') : sortElementsB.order)})}}
                                    >
                                        Type
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell>
                                    <TableSortLabel
                                        active={'shortName' === sortElementsB.col}
                                        direction={sortElementsB.order}
                                        onClick={() => {setSortElementsB({col: 'shortName', order: (sortElementsB.col === 'shortName' ? (sortElementsB.order === 'asc' ? 'desc' : 'asc') : sortElementsB.order)})}}
                                    >
                                        Element
                                    </TableSortLabel>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {surveyDiffB.map((diffB, i) => <TableRow key={i} style={{backgroundColor: CATEGORY_COLORS[diffB.category].light, borderBottom: '8px solid white'}}>
                                <TableCell style={{borderLeft: '5px solid '+CATEGORY_COLORS[diffB.category].dark}}>
                                    <CategoryLabel category={diffB.category} />
                                </TableCell>
                                <TableCell><DimensionLabel dimension={diffB.dimension} /></TableCell>
                                <TableCell>{typeTranslated(diffB.type, intl)}</TableCell>
                                <TableCell>
                                    {diffB.shortName}
                                </TableCell>
                            </TableRow>)}
                        </TableBody>
                    </Table>
                : null}
            </Grid>
        </Grid>
    );
}
