import { lazy, Suspense } from "react";
const PolarArea = lazy(() => import(/* webpackChunkName: "charts" */'./PolarArea'));

export default function PolarAreaLazy(props) {
    const {innerRef, ...rest} = props;
    return (
        <Suspense fallback={<div />}>
            <PolarArea innerRef={innerRef} {...rest} />
        </Suspense>
    );
}