export const demographicOptionTranslated = (option, translations, intl, demographic, demographicOptionLabels) => {

    const optionLabel = (option) => {
        switch(option.toLowerCase()) {
            // Roles
            case 'agency / temporary worker':
                return intl.formatMessage({id: 'demographic.role.agencyTempWorker', defaultMessage: 'Agency / Temporary Worker'});

            case 'driver':
                return intl.formatMessage({id: 'demographic.role.driver', defaultMessage: 'Driver'});

            case 'manager':
                return intl.formatMessage({id: 'demographic.role.manager', defaultMessage: 'Manager'});

            case 'manager / senior manager':
                return intl.formatMessage({id: 'demographic.role.managerSeniorManager', defaultMessage: 'Manager / Senior Manager'});

            case 'operator / team member / employee':
                return intl.formatMessage({id: 'demographic.role.operatorTeamMemberEmployee', defaultMessage: 'Operator / Team Member / Employee'});

            case 'operator / operative':
                return intl.formatMessage({id: 'demographic.role.operatorOperative', defaultMessage: 'Operator / Operative'});

            case 'operator':
                return intl.formatMessage({id: 'demographic.role.operator', defaultMessage: 'Operator'});

            case 'other':
                return intl.formatMessage({id: 'demographic.role.other', defaultMessage: 'Other'});

            case 'supervisor / team leader':
                return intl.formatMessage({id: 'demographic.role.supervisorTeamLeader', defaultMessage: 'Supervisor / Team Leader'});

            case 'technician':
                return intl.formatMessage({id: 'demographic.role.technician', defaultMessage: 'Technician'});

            // Length of service
            case 'less than a year':
                return intl.formatMessage({id: 'demographic.lengthofservice.LessThanAYear', defaultMessage: 'Less than a year'});

            case '1 - 5 years':
                return intl.formatMessage({id: 'demographic.lengthofservice.1to5Years', defaultMessage: '1 - 5 years'});

            case 'more than 5 years':
                return intl.formatMessage({id: 'demographic.lengthofservice.moreThan5Years', defaultMessage: 'More than 5 years'});

            case 'more than 10 years':
                return intl.formatMessage({id: 'demographic.lengthofservice.moreThan10Years', defaultMessage: 'More than 10 years'});

            // Department
            case 'production / processing / manufacturing':
                return intl.formatMessage({id: 'demographic.department.productionProcessingManufacturing', defaultMessage: 'Production / Processing / Manufacturing'});

            case 'administration / support':
                return intl.formatMessage({id: 'demographic.department.administrationSupport', defaultMessage: 'Administration / Support'});

            case 'logistics / dispatch / warehouse':
                return intl.formatMessage({id: 'demographic.department.logisticsDispatchWarehouse', defaultMessage: 'Logistics / Dispatch / Warehouse'});

            case 'engineering / maintenance / sanitation':
                return intl.formatMessage({id: 'demographic.department.engineeringMaintenanceSanitation', defaultMessage: 'Engineering / Maintenance / Sanitation'});

            case 'technical / safety / quality':
                return intl.formatMessage({id: 'demographic.department.technicalSafetyQuality', defaultMessage: 'Technical / Safety / Quality'});

            default:
                return option
        }
    };

    // If translation is available from survey provider, use that
    if (translations && intl.locale && (intl.locale in translations)) {
        return translations[intl.locale];
    }

    const labelsOverride = demographicOptionLabels ? demographicOptionLabels.find(dol => dol.attributes.demographic === demographic) : null;
    const optionToUse = labelsOverride && labelsOverride.attributes.labels && option in labelsOverride.attributes.labels ? labelsOverride.attributes.labels[option] : option

    return optionLabel(optionToUse);
}
