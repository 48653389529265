import { Fragment } from "react";
import ElementsTable from "./ElementsTable";
import { FormattedMessage } from "react-intl";

export default function AwarenessDimension(props) {
    return (
        <Fragment>
            <p>
                <FormattedMessage
                    id="execSummaryDiscAndRecs.awareness"
                    defaultMessage={
                        "External awareness relates to knowledge of external safety and quality issues, including external "+
                        "stakeholders and general industry standards. It is an important part of proactivity, as external events "+
                        "and stakeholders can impact safety and quality (e.g. suppliers) and also provide important opportunities "+
                        "to continually improve (e.g. learning from events in other companies and actively seeking customer "+
                        "feedback). To improve in this area, it is important to identify which elements were lowest and consider "+
                        "whether they could be a risk factor or area for improvement. In this dimension, junior employees are "+
                        "asked less of the questions because they would not be expected to have knowledge in some of these areas, "+
                        "so the score is unlikely to be lower due to lack of communication."
                    }
                />
            </p>
            <ElementsTable dimension={props.dimension} elements={props.elements} lowestElements={props.lowestElements} />
        </Fragment>
    );
}
