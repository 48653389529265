import { CircularProgress, Fab, Grid, IconButton, Link, makeStyles, Tooltip } from "@material-ui/core";
import EditIcon from '@material-ui/icons/Edit';
import ListAltIcon from '@material-ui/icons/ListAlt';
import { Link as RouterLink, useLocation, useHistory } from "react-router-dom";
import { Fragment, useContext, useEffect, useState } from "react";
import { NotificationContext } from "../../Context/NotificationContext";
import MUIDataTable, { debounceSearchRender } from "mui-datatables";
import CompanyService from "./../../Services/CompanyService";
import { AppBarTitleContext } from "../../Context/AppBarTitleContext";
import LocalBackdrop from "../LocalBackdrop/LocalBackdrop";
import { useIntl } from "react-intl";
import { UserContext } from "../../Context/UserContext";
import AddIcon from '@material-ui/icons/Add';
import AddToQueueIcon from '@material-ui/icons/AddToQueue';
import AssignmentIcon from '@material-ui/icons/Assignment';

const useStyles = makeStyles((theme) => ({
    fab: {
        position: 'fixed',
        bottom: theme.spacing(1.5),
        right: theme.spacing(1.5),
    },
    centeredNonSortHeader: {
        textAlign: 'center'
    },
    centeredCell: {
        [theme.breakpoints.down('xs')]: {
            textAlign: 'left'
        },
        [theme.breakpoints.up('sm')]: {
            textAlign: 'center'
        }
    }
}));

export default function Companies() {
    const classes = useStyles();
    const { updateNotification } = useContext(NotificationContext);
    const [isLoading, setLoading] = useState(true);
    const { updateTitle } = useContext(AppBarTitleContext);
    const intl = useIntl();
    const { user } = useContext(UserContext);
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const urlParamSearch = searchParams.get('search') ? searchParams.get('search') : null;
    const history = useHistory();

    const [tableData, setTableData] = useState({
        page: 0,
        count: 1,
        rowsPerPage: 20,
        sortOrder: {name: 'title', direction: 'asc'},
        data: []
    });

    const columns = [{
            name: 'id',
            options: {
                display: false,
                viewColumns: false,
                filter: false,
                sort: false,
                searchable: false,
                print: false,
                download: false
            }
        },
        {
            label: intl.formatMessage({id: 'companies.nameColumn', defaultMessage: 'Company name'}),
            name: 'attributes.name',
        },
        {
            name: 'attributes.surveyTypes',
            label: intl.formatMessage({id: 'companies.surveyTypesColumn', defaultMessage: 'Survey types'}),
            options: {
                sort: false,
                customBodyRender: (value) => {
                    if (value && value.length) {
                        return value.map(t => {
                            if ('brcgs' === t) {
                                return 'BRCGS';
                            } else if ('brcgs-free' === t) {
                                return 'BRCGS-Basic';
                            } else if ('ssafe' === t) {
                                return 'SSAFE';
                            }
                            return 'Premium'
                        }).join(', ');
                    }
                    return null;
                }
            }
        },
        {
            name: 'attributes.countryCode',
            label: intl.formatMessage({id: 'companies.countryCodeColumn', defaultMessage: 'Country code'}),
        },
        {
            name: 'attributes.createdAt',
            label: intl.formatMessage({id: 'companies.createdColumn', defaultMessage: 'Created'}),
            options: {
                customBodyRender: (value) => {
                    if (value) {
                        // DD MMM YYYY, i.e. "01 Jan 2021"
                        return <span title={value}>{(new Date(value)).toLocaleDateString('en-gb', {year: 'numeric', month: 'short', day: 'numeric'})}</span>
                    }
                    return '';
                }
            }
        },
        {
            name: 'attributes.updatedAt',
            label: intl.formatMessage({id: 'companies.updatedColumn', defaultMessage: 'Updated'}),
            options: {
                display: user && user.role && user.role === 'admin' ? true : false,
                customBodyRender: (value) => {
                    if (value) {
                        // DD MMM YYYY, i.e. "01 Jan 2021"
                        return <span title={value}>{(new Date(value)).toLocaleDateString('en-gb', {year: 'numeric', month: 'short', day: 'numeric'})}</span>
                    }
                    return '';
                }
            }
        },
        {
            label: intl.formatMessage({id: 'companies.surveysColumn', defaultMessage: 'Surveys'}),
            name: 'attributes.surveyCount',
            options: {
                sort: false,
                setCellProps: () => ({
                    className: classes.centeredCell,
                }),
                setCellHeaderProps: () => ({
                    className: classes.centeredNonSortHeader,
                }),
                customBodyRender: (value, tableMeta) => {
                    if (value) {
                        return <Link style={{ textDecoration: 'underline' }} component={RouterLink} to={'/surveys?company='+tableMeta.rowData[0]}>{value}</Link>
                    }
                    return value;
                }
            }
        },
        {
            label: intl.formatMessage({id: 'companies.usersColumn', defaultMessage: 'Users'}),
            name: 'attributes.userCount',
            options: {
                display: user && user.role && user.role === 'admin' ? true : false,
                sort: false,
                setCellProps: () => ({
                    className: classes.centeredCell,
                }),
                setCellHeaderProps: () => ({
                    className: classes.centeredNonSortHeader,
                }),
                customBodyRender: (value, tableMeta) => {
                    if (value) {
                        return <Link style={{ textDecoration: 'underline' }} component={RouterLink} to={'/users?company='+tableMeta.rowData[0]}>{value}</Link>
                    }
                    return null;
                }
            }
        },
        {
            label: intl.formatMessage({id: 'companies.sitesColumn', defaultMessage: 'Sites'}),
            name: 'attributes.siteCount',
            options: {
                display: user && user.role && user.role === 'admin' ? true : false,
                sort: false,
                setCellProps: () => ({
                    className: classes.centeredCell,
                }),
                setCellHeaderProps: () => ({
                    className: classes.centeredNonSortHeader,
                }),
                customBodyRender: (value, tableMeta) => {
                    if (value) {
                        return <Link style={{ textDecoration: 'underline' }} component={RouterLink} to={'/sites?company='+tableMeta.rowData[0]}>{value}</Link>
                    }
                    return null;
                }
            }
        },
        {
            name: 'id',
            label: ' ',
            options: {
                display: user && user.role && user.role === 'admin' ? true : false,
                filter: false,
                sort: false,
                searchable: false,
                customBodyRender: (value) => (
                    <Fragment>
                        <Tooltip title={(intl.formatMessage({id: 'generic.edit', defaultMessage: 'Edit'}))}>
                            <IconButton className={classes.rowButton} component={RouterLink} to={'/companies/'+value}>
                                <EditIcon fontSize="small" style={{ color: '#f57c00'}} />
                            </IconButton>
                        </Tooltip>
                        { user && user.role === 'admin' ?
                            <Fragment>
                                <Tooltip title="Site mapping" >
                                    <IconButton className={classes.rowButton} component={RouterLink} to={'/companies/'+value+'/site-mappings'}>
                                        <ListAltIcon fontSize="small" />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title="Import survey" >
                                    <IconButton className={classes.rowButton} component={RouterLink} to={'/companies/'+value+'/import-survey'}>
                                        <AddToQueueIcon fontSize="small" style={{ color: '#4caf50'}} />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title="Survey sheets" >
                                    <IconButton className={classes.rowButton} component={RouterLink} to={'/companies/'+value+'/survey-sheets'}>
                                        <AssignmentIcon fontSize="small" style={{ color: '#2196f3'}} />
                                    </IconButton>
                                </Tooltip>
                            </Fragment>
                        : null}
                    </Fragment>
                ),
                setCellProps: () => ({
                    align: 'right'
                }),
            }
        },
    ];

    const options = {
        textLabels: {
            body: {
                noMatch: isLoading ? '...' : intl.formatMessage({id: 'generic.noData', defaultMessage: 'No data...'}),
            }
        },
        pagination: tableData.count > 20, // Only show pagination if total is above pagination limit
        tableBodyHeight: 'auto',
        searchPlaceholder: intl.formatMessage({id: 'companies.searchPlaceholder', defaultMessage: 'Company name...'}),
        enableNestedDataAccess: '.',
        selectableRows: 'none',
        customSearchRender: debounceSearchRender(500),
        filter: false,
        searchOpen: false,
        download: false,
        searchText: urlParamSearch,
        print: false,
        viewColumns: false,
        elevation: 0,
        serverSide: true,
        count: tableData.count,
        rowsPerPage: tableData.rowsPerPage,
        rowsPerPageOptions: [],
        sortOrder: tableData.sortOrder,
        onTableChange: (action, tableState) => {
            const sortCol = tableState.sortOrder.name ? (tableState.sortOrder.name.replace('attributes.', '')) : 'title';
            const sortDir = tableState.sortOrder.direction ? (tableState.sortOrder.direction === 'asc' ? '' : '-') : '';

            switch (action) {
                case 'changePage':
                    fetchCompanies(tableState.page, tableState.rowsPerPage, sortDir+sortCol, tableState.searchText);
                    break;

                case 'sort':
                    fetchCompanies(tableState.page, tableState.rowsPerPage, sortDir+sortCol, tableState.searchText);
                    break;

                case 'search':
                    fetchCompanies(tableState.page, tableState.rowsPerPage, sortDir+sortCol, tableState.searchText)
                    break;

                default:
                    console.log(action, 'action not handled.');
            }
        },
    };

    const fetchCompanies = (page, pageSize, sort, search = null) => {
        search = search === '' ? null : search;
        const queryParams = [];
        if (search !== null) {
            queryParams.push('search='+search);
        }
        history.push({search: queryParams.join('&')});

        console.log('FETCH#', page, pageSize, search);
        setLoading(true);
        CompanyService.companies(page*pageSize, pageSize, sort, search)
        .then(function (response) {
            setTableData({
                data: response.data.data,
                count: response.data.meta.count,
            });
        }).catch(function (error) {
            updateNotification(true, intl.formatMessage({id: 'generic.unknownError', defaultMessage: 'An unknown error occurred!'}), 'error');
        }).then(function() {
            setLoading(false);
        });
    };

    useEffect(() => {
        fetchCompanies(0, tableData.rowsPerPage, null, urlParamSearch);
        updateTitle(null);
        // eslint-disable-next-line
    }, []);

    return(
        <Fragment>
            <Grid container spacing={0} justifyContent="center">
                <Grid item sm={12}>
                    <LocalBackdrop open={isLoading} transitionDuration={600}>
                        <CircularProgress color="secondary" size={40} />
                    </LocalBackdrop>
                    <MUIDataTable
                        title=""
                        data={tableData.data}
                        columns={columns}
                        options={options}
                    />
                </Grid>
            </Grid>
            <Tooltip title="Add company" enterDelay={0}>
                <div className={classes.fab}>
                    <Fab color="secondary" component={RouterLink} to="/companies/new">
                        <AddIcon />
                    </Fab>
                </div>
            </Tooltip>
        </Fragment>
    );
}
