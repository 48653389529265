import { Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import { Fragment } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import CategoryLabel from "../../../Translation/CategoryLabel";
import DimensionLabel from "../../../Translation/DimensionLabel";
import ElementLabel from "../../../Translation/ElementLabel";
import { CATEGORY_COLORS } from '../../../../Constants/CategoryColors';
import { countDimensions, listDimensionCounts } from "./Common";
import DimensionDescription from "../../../Translation/DimensionDescription";

export default function CulturalAreasForImprovement(props) {
    const intl = useIntl();
    const textWrapColumnStyle = {
        whiteSpace: 'normal',
    };

    const smallColStyle = {
        color: 'white',
        textAlign: 'center',
        whiteSpace: 'normal',
        paddingTop: '1px',
        paddingBottom: '1px',
        border: '1px solid #e0e0e0',
        height: '40px'
    };

    const dimensions = [];
    for (const key in props.reportData['basic-data'].dimensions) {
        if (null !== props.reportData['basic-data'].dimensions[key].score) {
            dimensions.push({
                label: <DimensionLabel dimension={key} />,
                name: key,
                ...props.reportData['basic-data'].dimensions[key],
            });
        }
    }

    // Sort dimensions from highest to lowest score
    dimensions.sort((a, b) => a.score - b.score);

    const bottomElements = JSON.parse(JSON.stringify(props.reportData.elements ? props.reportData.elements : []));
    bottomElements.sort((a, b) => parseFloat(a.score) > parseFloat(b.score) ? 1 : -1);
    const tableStyle = {padding: 6, fontSize: '9pt', lineHeight: 1.3};

    return (
        <Fragment>
            { props.title && props.print ? <h2 style={{fontWeight: 400}}>{props.title}</h2> : null}
            <p>
                <FormattedMessage id="execSummaryCulturalAreasForImpr.p1"
                    defaultMessage={
                        "The main opportunities for improvement can be seen in the lowest Dimensions and Elements. They can be "+
                        "filtered in any combination, and the <strong>Hotspots</strong> view provides a calculation of the lowest "+
                        "overall combinations. Supporting data can also be seen in views that do not contribute to the overall "+
                        "score but represent additional views of the employees, for example the <strong>Barriers</strong> view."
                    }
                    values= {{
                        strong: chunks => <strong>{chunks}</strong>
                    }}
                />
            </p>

            <p><FormattedMessage id="execSummaryCulturalAreasForImpr.p2" defaultMessage={"Overall, the lowest dimensions emerged as:"} /></p>

            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell style={tableStyle}>{intl.formatMessage({id: 'generic.dimension', defaultMessage:"Dimension"})}</TableCell>
                        <TableCell style={tableStyle}>{intl.formatMessage({id: 'generic.score', defaultMessage:"Score"})}</TableCell>
                        <TableCell style={tableStyle}>{intl.formatMessage({id: 'generic.description', defaultMessage:"Description"})}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {dimensions.slice(0, 3).map((dimension, i) => (
                        <TableRow key={i}>
                            <TableCell style={tableStyle}>{dimension.label}</TableCell>
                            <TableCell style={tableStyle}>{parseFloat(dimension.score).toFixed(1)}</TableCell>
                            <TableCell style={tableStyle}>
                                <DimensionDescription dimension={dimension.name} />
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>

            <p><FormattedMessage id="execSummaryCulturalAreasForImpr.p3" defaultMessage={"The lowest scoring questions (elements) across all participants are shown below:"} /></p>

            <Table>
                <TableHead style={{backgroundColor: '#3c3e40'}}>
                    <TableRow>
                        <TableCell style={{...smallColStyle, ...{width: '100pt'}}}>
                            {intl.formatMessage({id: 'generic.category', defaultMessage: 'Category'})}
                        </TableCell>
                        <TableCell style={smallColStyle}>{intl.formatMessage({id: 'generic.element', defaultMessage: 'Element'})}</TableCell>
                        <TableCell style={{...smallColStyle, ...{width: '100pt'}}}>
                            {intl.formatMessage({id: 'generic.scorePercent', defaultMessage: 'Score (%)'})}
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {bottomElements.slice(0, 6).map((element, index) => (
                        <TableRow key={index} style={{backgroundColor: CATEGORY_COLORS[element.category].light, borderBottom: '10px solid white'}}>
                            <TableCell style={{ ...{width: '100pt', borderLeft: '5px solid '+CATEGORY_COLORS[element.category].dark}, ...tableStyle}}>
                                <CategoryLabel category={element.category} />
                            </TableCell>
                            <TableCell style={{  ...textWrapColumnStyle, ...tableStyle}}>
                                <ElementLabel element={element.shortName} />
                            </TableCell>
                            <TableCell style={{ ...{ width: '100pt', textAlign: 'center' }, ...tableStyle}}>
                                {parseFloat(element.score).toFixed(1)}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>

            <p>
                <FormattedMessage id="execSummaryCulturalAreasForImpr.p4"
                    defaultMessage={
                        "These elements come from the following dimensions: {listDimensions}. The specific "+
                        "meaning of the numbers can be seen in the appendix of this report, and the "+
                        "<strong>Response Distribution</strong> view shows the full range of responses."
                    }
                    values= {{
                        strong: chunks => <strong>{chunks}</strong>,
                        listDimensions: listDimensionCounts(countDimensions(bottomElements.slice(0, 6)))
                    }}
                />
            </p>

            <p>
                <FormattedMessage id="execSummaryCulturalAreasForImpr.p5"
                    defaultMessage={
                        "Note: S / M indicates a question was only provided to supervisors and managers, M indicates it was only "+
                        "provided to managers."
                    }
                />
            </p>
        </Fragment>
    );
}
