import { Box, Table, TableCell, TableContainer, TableHead, TableSortLabel, TableRow, TableBody } from "@material-ui/core";
import { Fragment, useState } from "react";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import { BRCGS_CHART_COLORS } from "../../../Constants/BrcgsChartColors";
import { FormattedMessage, useIntl } from "react-intl";
import CompareArrow from "./Common/CompareArrow";
import FilterListIcon from '@material-ui/icons/FilterList';
import { priorityTranslated } from "../../Translation/Priority";
import PriorityLabel from "../../Translation/PriorityLabel";
import BarLazy from "../../Chart/BarLazy";

export default function PrioritiesComparison(props) {
    const intl = useIntl();
    const [visType, setVisType] = useState('chart');
    const [sortBy, setSortBy] = useState(null);
    const [sortOrder, setSortOrder] = useState('desc');

    if (!props.reportData.data || 0 === Object.keys(props.reportData.data).length || !props.surveyData || 0 === Object.keys(props.surveyData.attributes).length) {
        return null;
    }

    const prioTypes = ['Personal', 'Personal Comparison', 'Company', 'Company Comparison'];
    const prioColors = {
        chart: {
            'Personal': {
                background: props.surveyData.attributes.type === 'brcgs' ? BRCGS_CHART_COLORS[0].backgroundColor : '#f8de57',
                border: props.surveyData.attributes.type === 'brcgs' ? BRCGS_CHART_COLORS[0].borderColor : '#fbeea5',
            },
            'Personal Comparison': {
                background: props.surveyData.attributes.type === 'brcgs' ? BRCGS_CHART_COLORS[0].hoverBackgroundColor : '#fbeea5',
                border: props.surveyData.attributes.type === 'brcgs' ? BRCGS_CHART_COLORS[0].hoverBorderColor : '#f8de57',
            },
            'Company': {
                background: props.surveyData.attributes.type === 'brcgs' ? BRCGS_CHART_COLORS[1].backgroundColor : '#e21f4d',
                border: props.surveyData.attributes.type === 'brcgs' ? BRCGS_CHART_COLORS[1].borderColor : '#ef848e',
            },
            'Company Comparison': {
                background: props.surveyData.attributes.type === 'brcgs' ? BRCGS_CHART_COLORS[1].hoverBackgroundColor : '#ef848e',
                border: props.surveyData.attributes.type === 'brcgs' ? BRCGS_CHART_COLORS[1].hoverBorderColor : '#e21f4d',
            }
        },
        table: {
            Personal: {
                head: props.surveyData.attributes.type === 'brcgs' ? BRCGS_CHART_COLORS[0].hoverBackgroundColor : '#fbeea5',
                body: props.surveyData.attributes.type === 'brcgs' ? BRCGS_CHART_COLORS[0].hoverBackgroundColor : '#fef6ce',
                border: props.surveyData.attributes.type === 'brcgs' ? BRCGS_CHART_COLORS[0].borderColor : '#ffd600',

            },
            Company: {
                head: props.surveyData.attributes.type === 'brcgs' ? BRCGS_CHART_COLORS[1].hoverBorderColor : '#ef848e',
                body: props.surveyData.attributes.type === 'brcgs' ? BRCGS_CHART_COLORS[1].hoverBorderColor : '#f6bbbc',
                border: props.surveyData.attributes.type === 'brcgs' ? BRCGS_CHART_COLORS[1].borderColor : '#e21f4d',
            },
        }

    };

    const toggleSort = (column) => {
        // Toggle direction on already sorted by column
        if (sortBy === column) {
            setSortOrder(prevOrder => (prevOrder === 'asc' ? 'desc' : 'asc'));
        } else {
            // Sort by new column, descending
            setSortBy(column);
            setSortOrder('desc');
        }
    };

    const chartData = {
        labels: [],
        datasets: [],
    };

    const prioTypesTranslated = {
        'Personal': intl.formatMessage({id: 'priorities.personal', defaultMessage: 'Personal'}),
        'Personal Comparison': intl.formatMessage({id: 'prioritiesComparison.personalComparison', defaultMessage: 'Personal Comparison'}),
        'Company': intl.formatMessage({id: 'priorities.company', defaultMessage: 'Company'}),
        'Company Comparison': intl.formatMessage({id: 'prioritiesComparison.companyComparison', defaultMessage: 'Company Comparison'}),
    };

    prioTypes.forEach(prioType => {
        chartData.datasets.push({
            label: prioTypesTranslated[prioType],
            backgroundColor: prioColors.chart[prioType] ? prioColors.chart[prioType].background : null,
            borderColor: prioColors.chart[prioType] ? prioColors.chart[prioType].border : null,
            data: [],
        });
    });

    // Labels for data series
    Object.keys(props.reportData.data.current).forEach(prioKey => {
        const previousKey = props.reportData.data.previous ? Object.keys(props.reportData.data.previous).find(
            k => k.toLowerCase() === prioKey.toLowerCase()
        ) : null;

        const prioLabel = priorityTranslated(prioKey, intl);
        chartData.labels.push(prioLabel);

        // Add each prio type to datasets
        prioTypes.forEach((prioType, index) => {
            if (-1 !== ['Personal', 'Company'].indexOf(prioType)) {
                chartData.datasets[index].data.push(props.reportData.data.current[prioKey][prioType.toLowerCase()]);
            } else {
                if (props.reportData.data.previous && props.reportData.data.previous[previousKey]) {
                    chartData.datasets[index].data.push(props.reportData.data.previous[previousKey][('Personal Comparison' === prioType ? 'personal' : 'company')]);
                } else {
                    chartData.datasets[index].data.push(null);
                }
            }
        });
    });

    const chartOptions = {
        scales: {
            y: {
                min: 0,
                max: 5,
                title: {
                    display: true,
                    text: intl.formatMessage({id: 'priorities.axisTitle', defaultMessage: 'Average ranking (5 is highest)'})
                },
                ticks: {
                    stepSize: 0.5
                }
            }
        },
        animation: {
            duration: 0,
        },
        plugins: {
            tooltip: {
                position: 'nearest',
            },
            datalabels: {
                anchor: 'end',
                align: 'top',
                clamp: true
            }
        },
    };

    const table = () => {
        const sortedPersonalData = [];
        const sortedCompanyData = [];
        const sortedConflictData = [];

        Object.keys(props.reportData.data.current).forEach((element) => {
            const previousKey = props.reportData.data.previous ? Object.keys(props.reportData.data.previous).find(
                k => k.toLowerCase() === element.toLowerCase()
            ) : null;

            sortedPersonalData.push({
                current: props.reportData.data.current[element].personal,
                previous: previousKey && props.reportData.data.previous[previousKey] ? props.reportData.data.previous[previousKey].personal : null,
                label: element,
                change: previousKey && props.reportData.data.previous[previousKey] ? (props.reportData.data.current[element].personal-props.reportData.data.previous[previousKey].personal).toFixed(2) : null
            });

            sortedCompanyData.push({
                current: props.reportData.data.current[element].company,
                previous: previousKey && props.reportData.data.previous[previousKey] ? props.reportData.data.previous[previousKey].company : null,
                label: element,
                change: previousKey && props.reportData.data.previous[previousKey] ? (props.reportData.data.current[element].company-props.reportData.data.previous[previousKey].company).toFixed(2) : null
            });

            const conflictCurrent = Math.abs((props.reportData.data.current[element].personal-props.reportData.data.current[element].company).toFixed(2));
            const conflictPrevious = previousKey && props.reportData.data.previous[previousKey] ? Math.abs((props.reportData.data.previous[previousKey].personal-props.reportData.data.previous[previousKey].company).toFixed(2)) : null;
            sortedConflictData.push({
                current: conflictCurrent,
                previous: conflictPrevious,
                label: element,
                change: previousKey && props.reportData.data.previous[previousKey] ? (conflictCurrent-conflictPrevious).toFixed(2) : null,
            });
        });

        if (sortBy) {
            const sortAsc = (a, b) => {
                return (a[sortBy] === null ? Number.MIN_SAFE_INTEGER : parseFloat(a[sortBy])) > (b[sortBy] === null ? Number.MIN_SAFE_INTEGER : parseFloat(b[sortBy])) ? 1 : -1
            };

            sortedPersonalData.sort(sortAsc);
            sortedCompanyData.sort(sortAsc);
            sortedConflictData.sort(sortAsc);

            if (sortOrder === 'desc') {
                sortedPersonalData.reverse();
                sortedCompanyData.reverse();
                sortedConflictData.reverse();
            }
        }

        const personalStyle = {backgroundColor: prioColors.table['Personal'].head, borderColor: prioColors.table['Personal'].border};
        const companyStyle = {backgroundColor: prioColors.table['Company'].body, borderColor: prioColors.table['Company'].border};
        const conflictStyle = {backgroundColor: '#E8E8E8'};

        return (
            <Fragment>
                <Box pt={2} pb={2}>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center" style={personalStyle}>
                                        <FormattedMessage id="priorities.personal" defaultMessage={"Personal"} />
                                    </TableCell>
                                    <TableCell align="center">
                                        <TableSortLabel
                                            IconComponent={FilterListIcon}
                                            active={sortBy === 'current'}
                                            direction={(sortBy === 'current' ? sortOrder : 'desc')}
                                            onClick={() => {toggleSort('current')}}
                                        >
                                            <FormattedMessage id="generic.priority" defaultMessage={"Priority"} />
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell align="center">
                                        <TableSortLabel
                                            IconComponent={FilterListIcon}
                                            active={sortBy === 'previous'}
                                            direction={(sortBy === 'previous' ? sortOrder : 'desc')}
                                            onClick={() => {toggleSort('previous')}}
                                        >
                                            <FormattedMessage id="generic.comparison" defaultMessage={"Comparison"} />
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell align="center">
                                        <TableSortLabel
                                            IconComponent={FilterListIcon}
                                            active={sortBy === 'change'}
                                            direction={(sortBy === 'change' ? sortOrder : 'desc')}
                                            onClick={() => {toggleSort('change')}}
                                        >
                                            <FormattedMessage id="generic.difference" defaultMessage={"Difference"} />
                                        </TableSortLabel>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {sortedPersonalData.map(row => (
                                    <TableRow key={row.label}>
                                        <TableCell><PriorityLabel title={row.label} /></TableCell>
                                        <TableCell align="center" style={personalStyle}>{row.current}</TableCell>
                                        <TableCell align="center" style={personalStyle}>{row.previous}</TableCell>
                                        <TableCell align="center">
                                            { null !== row.change ? <div style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center'
                                                }}>
                                                    <span>{row.change}</span>
                                                    <CompareArrow change={row.change} priority={true} />
                                            </div>
                                            : null}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
                <Box pt={2} pb={2}>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center" style={companyStyle}>
                                        <FormattedMessage id="priorities.company" defaultMessage={"Company"} />
                                    </TableCell>
                                    <TableCell align="center">
                                        <span style={{paddingLeft: 13, paddingRight:13}}>
                                            <FormattedMessage id="generic.priority" defaultMessage={"Priority"} />
                                        </span>
                                    </TableCell>
                                    <TableCell align="center">
                                        <span style={{paddingLeft: 13, paddingRight:13}}>
                                            <FormattedMessage id="generic.comparison" defaultMessage={"Comparison"} />
                                        </span>
                                    </TableCell>
                                    <TableCell align="center">
                                        <span style={{paddingLeft: 13, paddingRight:13}}>
                                            <FormattedMessage id="generic.difference" defaultMessage={"Difference"} />
                                        </span>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {sortedCompanyData.map(row => (
                                    <TableRow key={row.label}>
                                        <TableCell><PriorityLabel title={row.label} /></TableCell>
                                        <TableCell align="center" style={companyStyle}>{row.current}</TableCell>
                                        <TableCell align="center" style={companyStyle}>{row.previous}</TableCell>
                                        <TableCell align="center">
                                            { null !== row.change ? <div style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center'
                                                }}>
                                                    <span>{row.change}</span>
                                                    <CompareArrow change={row.change} priority={true} />
                                            </div>
                                            : null}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
                <Box pt={2} pb={2}>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center" style={conflictStyle}>
                                        <FormattedMessage id="generic.conflict" defaultMessage={"Conflict"} />
                                    </TableCell>
                                    <TableCell align="center">
                                        <span style={{paddingLeft: 13, paddingRight:13}}>
                                            <FormattedMessage id="generic.priority" defaultMessage={"Priority"} />
                                        </span>
                                    </TableCell>
                                    <TableCell align="center">
                                        <span style={{paddingLeft: 13, paddingRight:13}}>
                                            <FormattedMessage id="generic.comparison" defaultMessage={"Comparison"} />
                                        </span>
                                    </TableCell>
                                    <TableCell align="center">
                                        <span style={{paddingLeft: 13, paddingRight:13}}>
                                            <FormattedMessage id="generic.difference" defaultMessage={"Difference"} />
                                        </span>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {sortedConflictData.map(row => (
                                    <TableRow key={row.label}>
                                        <TableCell><PriorityLabel title={row.label} /></TableCell>
                                        <TableCell style={conflictStyle} align="center">{row.current}</TableCell>
                                        <TableCell style={conflictStyle} align="center">{row.previous}</TableCell>
                                        <TableCell align="center">
                                            { null !== row.change ? <div style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center'
                                                }}>
                                                    <span>{row.change}</span>
                                                    <CompareArrow change={row.change} priority={true} />
                                            </div>
                                            : null}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            </Fragment>
        );
    }

    return (
        <Fragment>
            <Box display="flex" justifyContent="center">
                <ToggleButtonGroup
                    value={visType}
                    exclusive
                    onChange={(event, newVisType) => setVisType(newVisType)}
                >
                    <ToggleButton value="chart" style={{textTransform: 'none'}}>
                        <FormattedMessage id="priorities.chart" defaultMessage="Chart"/>
                    </ToggleButton>
                    <ToggleButton value="table" style={{textTransform: 'none'}}>
                        <FormattedMessage id="priorities.table" defaultMessage="Table"/>
                    </ToggleButton>
                </ToggleButtonGroup>
            </Box>
            <div ref={props.setScreenshotRef}>
                {visType === 'chart' ? <BarLazy data={chartData} options={chartOptions} dataLabels={true} /> : table() }
            </div>
        </Fragment>
    );
}
