import { Fragment, useContext, useEffect, useState } from "react"
import { NotificationContext } from "../../Context/NotificationContext";
import { AppBarTitleContext } from "../../Context/AppBarTitleContext";
import { SpinnerContext } from "../../Context/SpinnerContext";
import { useParams } from "react-router";
import { useIntl } from "react-intl";
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import CompanyService from "../../Services/CompanyService";
import { Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, Fab, Grid, IconButton, List, ListItem, ListItemSecondaryAction, ListItemText, makeStyles, Table, TableBody, TableCell, TableHead, TableRow, Tooltip, Typography } from "@material-ui/core";
import SiteService from "../../Services/SiteService";

const useStyles = makeStyles((theme) => ({
    fab: {
        position: 'fixed',
        bottom: theme.spacing(1.5),
        right: theme.spacing(1.5),
    },
    rowButton: {
        padding: '6px'
    },
    list: {
        width: '100%',
    },
}));

export default function SiteMappings(props) {
    const classes = useStyles();
    const { updateNotification } = useContext(NotificationContext);
    const { updateTitle } = useContext(AppBarTitleContext);
    const { updateLoading } = useContext(SpinnerContext);
    const [company, setCompany] = useState(null);
    const [sites, setSites] = useState([]);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [deleteUuid, setDeleteUuid] = useState(null);
    const [siteMappings, setSiteMappings] = useState([]);
    const [selectedSites, setSelectedSites] = useState([]);
    const intl = useIntl();
    let { uuid } = useParams();

    useEffect(() => {
        updateTitle(null);
        updateLoading(true);

        CompanyService.company(uuid).then(function (response) {
            setCompany(response.data);
        }).catch(function (error) {
            updateNotification(true, intl.formatMessage({id: 'generic.unknownError', defaultMessage: 'An unknown error occurred!'}), 'error');
        }).then(function() {
            updateLoading(false);
        });

        fetchAllSites();
        fetchSiteMappings();

        // eslint-disable-next-line
    }, [uuid]);

    const fetchAllSites = () => {
        SiteService.allSites('name', null, {company: uuid}).then(response => {
            setSites(response);
        }).catch(error => {
            console.log(error);
        }).then(() => {
            updateLoading(false);
        });
    }

    const fetchSiteMappings = () => {
        updateLoading(true);
        CompanyService.allSiteMappings(uuid).then(response => {
            setSiteMappings(response);
        }).catch(error => {
            updateNotification(true, 'An unknown error occurred', 'error');
            console.log(error);
        }).then(() => {
            updateLoading(false);
        });
    };

    const handleDelete = () => {
        // To avoid multiple submits
        if (!deleteDialogOpen) {
            return;
        }

        setDeleteDialogOpen(false);
        updateLoading(true);

        CompanyService.deleteSiteMapping(uuid, deleteUuid)
        .then(function (response) {
            setDeleteUuid(null);

            // Refetch stuff
            fetchSiteMappings();
            fetchAllSites();
        }).catch(function (error) {
            if (error.response && error.response.status) {
                if (error.response.data.error.detail) {
                    updateNotification(true, error.response.data.error.detail, 'error');
                } else {
                    updateNotification(true, 'An unknown error occurred!', 'error');
                }
            } else {
                updateNotification(true, 'An unknown error occurred!', 'error');
            }
            console.error(error);
        }).then(function () {
            updateLoading(false);
        });
    }

    const isInSiteMapping = (site) => {

        const result = siteMappings.find((sm) => {
            for (const s of sm.relationships.sites) {
                if (s.id === site.id) {
                    return true;
                }
            }
            return false;
        });
        return result !== undefined;
    };

    const toggleSite = (site) => {
        const currentIndex = selectedSites.indexOf(site.id);
        const newChecked = [...selectedSites];

        if (currentIndex === -1) {
            newChecked.push(site.id);
        } else {
            newChecked.splice(currentIndex, 1);
        }
        setSelectedSites(newChecked);
    };

    const createSiteMapping = () => {
        updateLoading(true);
        console.log(selectedSites);
        CompanyService.createSiteMapping(uuid, selectedSites)
        .then(function (response) {
            // Refetch stuff
            setSelectedSites([]);
            fetchSiteMappings();
            fetchAllSites();
        }).catch(function (error) {
            if (error.response && error.response.status) {
                if (error.response.data.error.detail) {
                    updateNotification(true, error.response.data.error.detail, 'error');
                } else {
                    updateNotification(true, 'An unknown error occurred!', 'error');
                }
            } else {
                updateNotification(true, 'An unknown error occurred!', 'error');
            }
            console.error(error);
        }).then(function () {
            updateLoading(false);
        });
    }

    if (0 === sites.length || null === company) {
        return null;
    }

    return (
        <Fragment>
            <Grid container spacing={2} justifyContent="center">
                <Grid item sm={6}>
                    <Typography variant="h6">Unmapped Sites</Typography>
                    <Box pt={2} style={{maxHeight: '85vh', overflow: 'auto'}}>
                        <List className={classes.list}>
                            {sites.map((site) => {
                                if (!isInSiteMapping(site)) {
                                    return <Fragment key={site.id}>
                                        <ListItem button onClick={() => toggleSite(site)}>
                                            <ListItemText primary={<Fragment>
                                                <Tooltip title="Name">
                                                    <span style={{fontWeight: 'bold'}}>{site.attributes.name}</span>
                                                </Tooltip>
                                                <Tooltip title="Reporting value">
                                                    <em style={{float:'right'}}>{site.attributes.value}</em>
                                                </Tooltip>
                                            </Fragment>} />
                                            <ListItemSecondaryAction>
                                                <Checkbox
                                                    edge="end"
                                                    onChange={() => toggleSite(site)}
                                                    checked={selectedSites.indexOf(site.id) !== -1}
                                                />
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                        <Divider variant="inset" component="li" />
                                    </Fragment>
                                } else {
                                    return null;
                                }
                            })}
                        </List>
                    </Box>
                </Grid>
                <Grid item sm={6}>
                    <Typography variant="h6">Mapped Sites</Typography>
                    <Box pt={2}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Sites</TableCell>
                                    <TableCell align="center">Created</TableCell>
                                    <TableCell align="right"></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {siteMappings.map((sm, i) => (
                                    <TableRow key={i}>
                                        <TableCell>
                                            <ul>
                                                {sm.relationships.sites.map((s, j) => (
                                                    <li key={j}>
                                                        <Fragment>
                                                            <Tooltip title="Name">
                                                                <span style={{fontWeight: 'bold'}}>{s.data.name}</span>
                                                            </Tooltip>
                                                            <Tooltip title="Reporting value">
                                                                <em style={{float:'right'}}>{s.data.value}</em>
                                                            </Tooltip>
                                                        </Fragment>
                                                    </li>
                                                ))}
                                            </ul>
                                        </TableCell>
                                        <TableCell align="center">{sm.attributes.createdAt}</TableCell>
                                        <TableCell align="right">
                                            <IconButton className={classes.rowButton} color="secondary" onClick={() => {setDeleteDialogOpen(true); setDeleteUuid(sm.id)}}>
                                                <DeleteIcon fontSize="small" />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </Box>
                </Grid>
            </Grid>

            <Dialog
                open={deleteDialogOpen}
                onClose={() => {setDeleteDialogOpen(false)}}
            >
                <DialogTitle>Delete</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        This will delete the site mapping, continue?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={() => {setDeleteDialogOpen(false)}} color="primary" autoFocus>
                        Cancel
                    </Button>
                    <Button variant="contained" onClick={handleDelete} color="secondary">
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
            <Tooltip title="Map selected elements" enterDelay={0}>
                <div className={classes.fab}>
                    <Fab disabled={selectedSites.length < 2} color="secondary" onClick={() => {createSiteMapping()}}>
                        <AddIcon />
                    </Fab>
                </div>
            </Tooltip>
        </Fragment>
    );
}