import { Typography, Box, Grid, Button, Paper } from "@material-ui/core";
import { useState } from "react";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";

export default function Ideas(props) {

    const intl = useIntl();

    const messages = defineMessages({
        trainingWorkshopsHeader: {
            id: 'ideas.trainingWorkshopsHeader',
            defaultMessage: 'Training / Workshops',
        },
        communicationStrategyHeader: {
            id: 'ideas.communicationStrategyHeader',
            defaultMessage: 'Communication Strategy',
        },
        teambuildingActivitiesEventsHeader: {
            id: 'ideas.teambuildingActivitiesEventsHeader',
            defaultMessage: 'Teambuilding Activities & Events',
        },
        internalCoachingReviewHeader: {
            id: 'ideas.internalCoachingReviewHeader',
            defaultMessage: 'Internal Coaching & Review',
        },
        recognitionIncentiveSchemeHeader: {
            id: 'ideas.recognitionIncentiveSchemeHeader',
            defaultMessage: 'Recognition / Incentive Scheme',
        },
        focusedInternalAuditHeader: {
            id: 'ideas.focusedInternalAuditHeader',
            defaultMessage: 'Focused Internal Audit',
        },
        systemEnhancementHeader: {
            id: 'ideas.systemEnhancementHeader',
            defaultMessage: 'System Enhancement',
        },
        premisesFacilitiesEquipmentHeader: {
            id: 'ideas.premisesFacilitiesEquipmentHeader',
            defaultMessage: 'Premises, Facilities & Equipment',
        },
        leadershipMessageConsistencyHeader: {
            id: 'ideas.leadershipMessageConsistencyHeader',
            defaultMessage: 'Leadership Message Consistency',
        },
    });

    const ideas = [
        [
            {
                name: intl.formatMessage(messages.trainingWorkshopsHeader),
                idea: (
                    <span>
                        <FormattedMessage
                            id="ideaReport.trainingPart1"
                            defaultMessage={
                                'Focused training sessions or workshops can increase awareness in many cultural dimensions ' +
                                'from increasing understanding of risk (foresight) to identifying common corrective actions and ways to ' +
                                'reduce their likelihood in the future (learning). If they include interaction and group activities they ' +
                                'can also build positive working relationships (teamwork) and if they bring people together from different ' +
                                'departments, either within the teaching role or by learning together, they can build greater ' +
                                'cross-departmental understanding (co-ordination).'
                            }
                        />
                        <br />
                        <br />
                        <FormattedMessage
                            id="ideasReport.trainingPart2"
                            defaultMessage={
                                'It is important that all training sessions and workshops are carefully designed, planned, ' +
                                'managed and evaluated to ensure they have a positive effect on learning (training) as well as improving ' +
                                'knowledge, attitude and / or behaviour of the key topics. Fun and enjoyment should be considered, as ' +
                                'well as making them a genuine learning experience.'
                            }
                        />
                    </span>
                ),
            },
            {
                name: intl.formatMessage(messages.communicationStrategyHeader),
                idea: (
                    <span>
                        <FormattedMessage
                            id="ideaReport.communicationPart1"
                            defaultMessage={
                                'A focused communication strategy can increase awareness in many cultural dimensions in a ' +
                                'broader way than training or workshops as they can reach a larger audience. These are best for shorter ' +
                                'or simpler pieces of information that require a less detailed explanation, and don’t need the same ' +
                                'impact on working relationships and teamwork.'
                            }
                        />
                        <br />
                        <br />
                        <FormattedMessage
                            id="ideaReport.communicationPart2"
                            defaultMessage={
                                'Traditional communication methods such as meetings, emails and posters can used in ' +
                                'conjunction with social media (e.g. Facebook, Twitter, Instagram) depending on what would work best ' +
                                'within the site.'
                            }
                        />
                        <br />
                        <br />
                        <FormattedMessage
                            id="ideaReport.communicationPart3"
                            defaultMessage={
                                'For example, a communication strategy can be very useful to increase knowledge of where ' +
                                'the company is heading and why (e.g. vision, values, strategy) as well as to share external issues ' +
                                'of importance that affect the company (awareness) or to clarify and build support for new plans or ' +
                                'initiatives that are underway (innovation).'
                            }
                        />
                        <br />
                        <br />

                        <FormattedMessage
                            id="ideaReport.communicationPart4"
                            defaultMessage={
                                'The more honest and fair communications are perceived to be, the more value they will have. ' +
                                'Focusing on positive issues and bringing up any negative issues diplomatically and constructively will help. ' +
                                'Furthermore, allowing feedback (a ‘two way’ communication), listening to issues and concerns, and ' +
                                'demonstrating how this feedback is taken seriously can increase people’s sense of involvement and value.'
                            }
                        />
                        <br />
                        <br />
                    </span>
                ),
            },
            {
                name: intl.formatMessage(messages.teambuildingActivitiesEventsHeader),
                idea: (
                    <span>
                        <FormattedMessage
                            id="ideaReport.teambuildingActivitiesPart1"
                            defaultMessage={
                                'Team building activities can build and enhance working relationships and make a positive ' +
                                'impact on culture, especially when planned between teams (teamwork) and departments (co-ordination) ' +
                                'to focus on these improvements. Team building activities can be on a small or large scale depending ' +
                                'on the resources available, from a small activity during meetings (e.g. a team challenge or quiz) to ' +
                                'an excursion away from the site (e.g. a sporting activity). It is important to bear in mind different ' +
                                'personalities, tastes and national cultures, to ensure the activities are appropriate and enjoyable. ' +
                                'It is also important to reflect on how they can build teamwork and collaboration as well as being an ' +
                                'enjoyable event.'
                            }
                        />
                        <br />
                        <br />
                        <FormattedMessage
                            id="ideaReport.teambuildingActivitiesPart2"
                            defaultMessage={
                                'Asking for ideas and feedback from employees is a good way to increase the likelihood of ' +
                                'success, or giving options for people to choose between. It is also possible to build an element of cultural ' +
                                'awareness into the activity, for example having an ‘away day’ in which teams are asked to create their own ' +
                                'communication materials for the site that focus on specific elements of the company culture (e.g. values, ' +
                                'vision, etc.).'
                            }
                        />
                        <br />
                        <br />
                        <FormattedMessage
                            id="ideaReport.teambuildingActivitiesPart3"
                            defaultMessage={
                                'Alternatively there could be a focused ‘culture day’ at the site, with specific areas ' +
                                'communicated and focused on, with activities built around them. It is important to consider how ' +
                                'many people will be involved, and how they are selected, to ensure no negative issues in relation ' +
                                'to reinforcement (reward).'
                            }
                        />
                    </span>
                ),
            },
        ],
        [
            {
                name: intl.formatMessage(messages.internalCoachingReviewHeader),
                idea: (
                    <span>
                        <FormattedMessage
                            id="ideaReport.internalCoachingReviewPart1"
                            defaultMessage={
                                'An internal coaching and review scheme can help to build knowledge and skills, ' +
                                'and facilitate career progression, amongst employees. If carefully developed, managed and ' +
                                'evaluated, this can create benefits for both the coachee (training and empowerment) and also ' +
                                'the coach in terms of their job satisfaction (reward) and greater working relationships ' +
                                '(co-ordination). A regular element of review is necessary to help employees understand how ' +
                                'they are progressing, and this should be something significantly more than just a brief ' +
                                'appraisal, with genuine interaction and support. The coaches and reviewers should be ' +
                                'recognized for the efforts, for example in the form of communication of their efforts or ' +
                                'certification.'
                            }
                        />
                        <br />
                        <br />
                        <FormattedMessage
                            id="ideaReport.internalCoachingReviewPart2"
                            defaultMessage={
                                'The scheme should ideally link to other forms of appraisal and evaluation to ' +
                                'ensure consistency, and also link with and clarify regular goals (targets). If there is an ' +
                                'existing coaching and / or review scheme in place, it might be beneficial to investigate ' +
                                'how to enhance this, as well as to investigate how clearly food safety is included within it. ' +
                                'As with most cultural improvement plans, it is important to consider how many people will be ' +
                                'involved, and how they are selected, to ensure no negative issues in relation to reinforcement ' +
                                '(reward).'
                            }
                        />
                    </span>
                ),
            },
            {
                name: intl.formatMessage(messages.recognitionIncentiveSchemeHeader),
                idea: (
                    <span>
                        <FormattedMessage
                            id="ideaReport.recognitionIncentiveSchemePart1"
                            defaultMessage={
                                'To increase employee motivation and satisfaction, recognition or incentive schemes can ' +
                                'be very valuable. If designed, implemented, communicated and evaluated carefully, they can ' +
                                'reinforce positive behaviours and inspire others (reward). By linking them to other cultural ' +
                                'dimensions they can also increase the achievement of goals (targets), reduce the level or error ' +
                                'or repeated mistakes (learning), and encourage people to work in the right way every time (consistency).'
                            }
                        />
                        <br />
                        <br />
                        <FormattedMessage
                            id="ideaReport.recognitionIncentiveSchemePart2"
                            defaultMessage={
                                'To ensure reinforcement schemes are effective they must be perceived to be fair. A reward or ' +
                                'recognition scheme will only motivate those who perceive it to have been genuinely earned, with the ' +
                                'potential for themselves to achieve equivalent recognition or reward for equivalently positive behavior. ' +
                                'Reinforcement must also be consistently applied, and in a timely manner (i.e. close to the event). If it ' +
                                'is perceived to be inconsistent (i.e. applied some times and not others) its effect is reduced.'
                            }
                        />
                        <br />
                        <br />
                        <FormattedMessage
                            id="ideaReport.recognitionIncentiveSchemePart3"
                            defaultMessage={
                                'Reinforcement does not have to be expensive, and praise, thanks and recognition of achievement ' +
                                'can be highly effective when utilized effectively. The level of reward should be commensurate to the behavior ' +
                                '/ achievement that it is linked to for greatest benefit. For example, if there is a particularly challenging ' +
                                'or difficult task, or someone identifies a major issue and raises / resolves it in time to prevent loss, a ' +
                                'greater level of reward or recognition would be recommended.'
                            }
                        />
                    </span>
                ),
            },
            {
                name: intl.formatMessage(messages.focusedInternalAuditHeader),
                idea: (
                    <span>
                        <FormattedMessage
                            id="ideaReport.focusedInternalAuditPart1"
                            defaultMessage={
                                'Internal audit is generally carried out to review and improve elements of the food safety and ' +
                                'quality management systems that are running, and is a requirement of certified external audits. Their ' +
                                'success and value depends on how well they are managed, how much they are prioritized, and how well they ' +
                                'are responded to. If internal audits are working effectively within the company, cultural dimensions can ' +
                                'be integrated into them to build on their existing success. For example, audit findings could be used as ' +
                                'a source of recognition or incentive (reward), as the basis for focused workshops (training) in relation ' +
                                'to preventative action (learning), or their links to short term goals (targets) or longer term plans ' +
                                '(vision and strategy) could be strengthened.'
                            }
                        />
                        <br />
                        <br />
                        <FormattedMessage
                            id="ideaReport.focusedInternalAuditPart2"
                            defaultMessage={
                                'If audits are not as effective as they could be, there is an opportunity to increase their utility. ' +
                                'Using a multi-disciplinary and inter-hierarchical team approach in a positive and constructive manner can ' +
                                'enhance working relationships, and if this works across departments it can create opportunities for greater ' +
                                'collaboration (teamwork, co-ordination). Identifying clear leadership and management roles for the audit team, ' +
                                'providing additional resources where necessary (based on feedback), and communicating and rewarding any actions ' +
                                'that take place (reward, learning) can all help to do this.'
                            }
                        />
                        <br />
                        <br />
                        <FormattedMessage
                            id="ideaReport.focusedInternalAuditPart3"
                            defaultMessage={
                                'If issues with consistency have been raised in the audit, this is an opportunity to assess working' +
                                'practices in a less formal setting than the annual external audit, and to identify what might be causing this. ' +
                                'It will also encourage employees to be ‘audit ready’ when they understand that internal teams will also be ' +
                                'thoroughly checking that everything is in place, especially if these are focused and specific (based on areas ' +
                                'that require improvement) rather than checking everything. Ensuing the audit process is two-way, with feedback ' +
                                'and ideas from all employees involved and observed, can help to identify why there may sometimes be mistakes / ' +
                                'errors or non-compliances.'
                            }
                        />
                        <br />
                        <br />
                        <FormattedMessage
                            id="ideaReport.focusedInternalAuditPart4"
                            defaultMessage={
                                'The whole process must be perceived to be fair and for the purposes of improvement rather than ' +
                                'finding fault or attributing blame. Positive reinforcement works better than negative reinforcement in most ' +
                                'situations, though a subtle combination of both may be required.'
                            }
                        />
                    </span>
                ),
            },
        ],
        [
            {
                name: intl.formatMessage(messages.systemEnhancementHeader),
                idea: (
                    <span>
                        <FormattedMessage
                            id="ideaReport.systemEnhancementPart1"
                            defaultMessage={
                                'Effective systems in place for food safety and quality drive standardized behaviours and the ' +
                                'attainment of standards (consistency), provide sufficient information for employees to take action when ' +
                                'required (empowerment), and are a core part of the implementation of company food safety plans (strategy, ' +
                                'vision, targets). Effective corrective action plans help a company with continual improvement (learning), ' +
                                'and clear, practical documents are an excellent basis for on-the-job training and competency assessment ' +
                                '(training). In this way, effectively enhancing or promoting certain elements of a management system can ' +
                                'be a very useful focus for a cultural improvement plan.'
                            }
                        />
                        <br />
                        <br />
                        <FormattedMessage
                            id="ideaReport.systemEnhancementPart2"
                            defaultMessage={
                                'In particular, the clarity and practicality of documents and records can be an area for continual ' +
                                'improvement, especially where documents and records are essential for safety (e.g. at CCPs). Using simple language, ' +
                                'being careful to focus only on key messages, and reducing words with the use of images and photographs, can make ' +
                                'them more likely to be followed. It is important to include end-users in the development of enhancements, as they ' +
                                'will be the people that have to follow them.'
                            }
                        />
                        <br />
                        <br />
                        <FormattedMessage
                            id="ideaReport.systemEnhancementPart3"
                            defaultMessage={
                                'It also creates an opportunity for collaboration (co-ordination) and teamwork. Reviewing the ' +
                                'level of paperwork in place is an important on-going activity, as additional documents and records can be ' +
                                'added over time without fully assessing the additional burden they create, or whether their addition is ' +
                                'duplicating or conflicting with anything previously created. Wherever a document or record does not clearly ' +
                                'add value, or takes more time than the perceived value it adds, it creates a negative impact on the system ' +
                                'as a whole. Opportunities for synthesis and trimming should be investigated, again with the involvement ' +
                                'and feedback of the end-user.'
                            }
                        />
                    </span>
                ),
            },
            {
                name: intl.formatMessage(messages.premisesFacilitiesEquipmentHeader),
                idea: (
                    <span>
                        <FormattedMessage
                            id="ideaReport.premisesFacilitiesEquipmentPart1"
                            defaultMessage={
                                'Investment in the premises, facilities or equipment of a site can make a direct impact on ' +
                                'food safety and quality by improving standards and reducing risk. In addition to this, it can also ' +
                                'affect food safety and quality culture by enhancing motivation and pride in the site when compared ' +
                                'to others (awareness) and raising its profile as innovative (innovation) and committed to food safety ' +
                                'and quality (values and foresight).'
                            }
                        />
                    </span>
                ),
            },
            {
                name: intl.formatMessage(messages.leadershipMessageConsistencyHeader),
                idea: (
                    <span>
                        <FormattedMessage
                            id="ideaReport.leadershipMessageConsistencyPart1"
                            defaultMessage={
                                'If there are lower than desired scores in risk foresight or company values, it may be that ' +
                                'a change is required in how leaders convey messages about the importance of food safety and quality. ' +
                                'Employees in a company learn about what is most important, and where food safety and quality fit, ' +
                                'by observing what the leaders of the company pay attention to.'
                            }
                        />
                        <br />
                        <br />
                        <FormattedMessage
                            id="ideaReport.leadershipMessageConsistencyPart2"
                            defaultMessage={
                                'This can be in direct ways (what is formally monitored, rewarded / punished, invested in, ' +
                                'and communicated on a regular basis) but also indirect ones (e.g. informal comments or reactions to ' +
                                'events that show a pattern of underlying beliefs). It is important that leaders within the company are ' +
                                'aware of how they communicate the priorities and values of the company, and are consistent in how ' +
                                'they do this (both individually and as a group).'
                            }
                        />
                    </span>
                ),
            },
        ],
    ];

    const [activeIdea, setActiveIdea] = useState({
        name: ideas[0][0].name,
        content: ideas[0][0].idea
    });

    return (
        <Box>
            <Grid container spacing={1} justifyContent="center">
                {ideas.map((row, rowIndex) => {
                    return (
                        row.map((idea, ideaIndex) => (
                            <Grid item xs={4} key={ideaIndex}>
                                <Button
                                    variant="contained"
                                    color={activeIdea.name === idea.name ? 'secondary' : 'primary'}
                                    key={rowIndex+'-'+ideaIndex}
                                    onClick={() => setActiveIdea({name: idea.name, content: idea.idea})}
                                    style={{width: '100%'}}
                                >
                                    {idea.name}
                                </Button>
                            </Grid>
                        ))
                    )
                })}
                <Grid item xs={12}>
                    <Box pt={1}>
                        <Paper elevation={0}>
                            <Typography variant="body1">
                                {activeIdea.content}
                            </Typography>
                        </Paper>
                    </Box>
                </Grid>
            </Grid>

        </Box>
    );
}

