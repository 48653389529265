import { Fragment } from "react";
import ElementsTable from "./ElementsTable";

export default function EmpowermentDimension(props) {

    return (
        <Fragment>
            {/* TODO: Why does this dimension have no description in the old code? */}
            <ElementsTable dimension={props.dimension} elements={props.elements} lowestElements={props.lowestElements} />
        </Fragment>
    );
}
