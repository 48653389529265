import { useIntl } from "react-intl"
import { barrierTranslated } from "../../Translation/Barrier";
import BarLazy from "../../Chart/BarLazy";
import { RGBAToHexA } from "../../../Utilities/Color";
import { chartColors } from "../../../Utilities/ChartColors";

export default function BarriersComparison(props) {
    const intl = useIntl();

    if (!props.reportData.data ) {
        return null;
    }

    const chartData = {
        labels: [],
        datasets: [{
            label: 'Current',
            stack: 'current',
            // barPercentage: 3,
            // categoryPercentage: 0.3,
            data: [],
            backgroundColor: [],
            borderColor: [],
            hoverBackgroundColor: [],
            hoverBorderColor: [],
        },{
            label: 'Comparison',
            stack: 'previous',
            // barPercentage: 3,
            // categoryPercentage: 0.3,
            data: [],
            backgroundColor: [],
            borderColor: [],
            hoverBackgroundColor: [],
            hoverBorderColor: [],
        }],
    };

    let max = 0;
    Object.keys(props.reportData.data.current).forEach(index => {
        if ('other' !== props.reportData.data.current[index].title.toLowerCase()) {
            chartData.labels.push(barrierTranslated(props.reportData.data.current[index].title, intl));
        } else {
            chartData.labels.push(intl.formatMessage({id: 'barriers.other', defaultMessage: 'Other'}));
        }
        chartData.datasets[0].data.push(props.reportData.data.current[index].percentage)
        max = props.reportData.data.current[index].percentage > max ? props.reportData.data.current[index].percentage : max;

        const c = chartColors('barriers', props.reportData.data.current[index].title);
        chartData.datasets[0].backgroundColor.push(c.backgroundColor);
        chartData.datasets[0].borderColor.push(c.borderColor);
        chartData.datasets[0].hoverBackgroundColor.push(c.hoverBackgroundColor);
        chartData.datasets[0].hoverBorderColor.push(c.hoverBorderColor);

        let prevColor = '#cccccc';
        const alpha = '80';
        if (c['backgroundColor'].startsWith('rgba(')) {
            prevColor = RGBAToHexA(c['backgroundColor']).substring(0,7)+alpha;
        } else if (c['backgroundColor'].startsWith('#') && c['backgroundColor'].length === 7) {
            prevColor = c['backgroundColor']+alpha;
        }
        chartData.datasets[1].backgroundColor.push(prevColor);
        chartData.datasets[1].borderColor.push(prevColor);
        chartData.datasets[1].hoverBackgroundColor.push(prevColor);
        chartData.datasets[1].hoverBorderColor.push(prevColor);

        // Data from previous (if present)
        if (props.reportData.data.previous) {
            const matchingPrevious = props.reportData.data.previous.find(opt => opt.title.toLowerCase() === props.reportData.data.current[index].title.toLowerCase())

            if (matchingPrevious) {
                max = matchingPrevious.percentage > max ? matchingPrevious.percentage : max;
                chartData.datasets[1].data.push(matchingPrevious.percentage);
            } else {
                chartData.datasets[1].data.push(0);
            }
        } else {
            chartData.datasets[1].data.push(0);
        }
    });

    const chartOptions = {
        scales: {
            y: {
                // stacked: true,
            },
            x: {
                // stacked: true,
                title: {
                    display: true,
                    text: intl.formatMessage({id: 'barriers.percent', defaultMessage: 'Percent (%)'}),
                },
                min: 0,
                max: max + (max/10), // Add a bit of space at the end to make sure datalabels do not overflow
                ticks: {
                    callback: (value, index, values) => {
                        return index === values.length-1 ? '' : value
                    }
                },
            }
        },
        indexAxis: 'y',
        animation: {
            duration: 0,
        },
        plugins: {
            legend: {
                display: false,
            },
            tooltip: {
                callbacks: {
                    label: (context) => {
                        const label = context.label || '';
                        return label + (context.dataset.stack === 'previous' ? ' (comparison)' : '') +': ' + context.parsed.x.toFixed(1) + '%';
                    }
                },
            },
            datalabels: {
                anchor: 'end',
                align: 'end',
                clamp: true,
                offset: 5,
                formatter: (value) => {
                    return value.toFixed(0)+' %';
                }
            }
        },
    };

    return (
        <div ref={props.setScreenshotRef}>
            <BarLazy data={chartData} options={chartOptions} dataLabels={true} />
        </div>
    );
}