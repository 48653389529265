import { Fragment } from "react";
import DemographicOptionLabel from "../../../Translation/DemographicOptionLabel";
import DimensionLabel from "../../../Translation/DimensionLabel";

export function countDimensions(elements) {
    const counts = {};
    for (const element of elements) {
        if (!(element.dimension in counts)) {
            counts[element.dimension] = 0;
        }
        counts[element.dimension]++;
    }
    const out = [];
    for (const key in counts) {
        out.push({
            label: <DimensionLabel dimension={key} />,
            count: counts[key],
        });
    }
    return out;
};

export function listDimensionCounts(dimensionCounts) {
    if (dimensionCounts.length === 0) {
        return '';
    } else if (dimensionCounts.length === 1) {
        return <Fragment>{dimensionCounts[0].label} ({dimensionCounts[0].count})</Fragment>
    }

    const first = dimensionCounts.slice(0, -1);
    const last = dimensionCounts[dimensionCounts.length - 1];
    const firstParts = first.map((dim, i) => (
        <Fragment key={i}> {dim.label} ({dim.count}){ i !== first.length-1 ? ', ' : ''}</Fragment>
    ));

    return (<Fragment>
        {firstParts} and {last.label} ({last.count})
    </Fragment>);
};

export function hotspotDemographicLabel(hotspotElement, demographicQuestions, demographicOptionLabels, print = false) {
    const label = [];
    demographicQuestions.forEach((demQ, i) => {
        if (demQ.attributes.demographic in hotspotElement.demographics) {
            demQ.attributes.options.forEach((option) => {
                if (hotspotElement.demographics[demQ.attributes.demographic] === option.attributes.value) {
                    label.push(<DemographicOptionLabel key={i} option={option.attributes.title} translations={option.attributes.translations} demographic={demQ.attributes.demographic} demographicOptionLabels={demographicOptionLabels} hideOverride={print}/>)
                    label.push(<strong key={'sep'+i}> | </strong>);
                }
            })
        }
    });

    // Remove last separator
    label.pop();
    return label;
};

