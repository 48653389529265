export const categoryTranslated = (category, intl) => {
    if (!category) {
        return null;
    }

    const labels = {
        people: intl.formatMessage({id: 'generic.peopleCategory', defaultMessage: 'People'}),
        process: intl.formatMessage({id: 'generic.processCategory', defaultMessage: 'Process'}),
        purpose: intl.formatMessage({id: 'generic.purposeCategory', defaultMessage: 'Purpose'}),
        proactivity: intl.formatMessage({id: 'generic.proactivityCategory', defaultMessage: 'Proactivity'})
    };

    if (category.toLowerCase() in labels) {
        return labels[category.toLowerCase()];
    }

    return category.charAt(0).toUpperCase() + category.slice(1);
}