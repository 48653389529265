import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { CircularProgress, Grid, Link, Button, IconButton, Dialog, DialogContent, DialogTitle, DialogActions, DialogContentText, Tooltip } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import { Link as RouterLink, useLocation, useHistory } from "react-router-dom";
import { Fragment, useContext, useEffect, useState } from "react";
import { NotificationContext } from "../../Context/NotificationContext";
import MUIDataTable, { debounceSearchRender } from "mui-datatables";
import SiteService from "./../../Services/SiteService";
import { AppBarTitleContext } from "../../Context/AppBarTitleContext";
import { UserContext } from "../../Context/UserContext";
import LocalBackdrop from "../LocalBackdrop/LocalBackdrop";
import { useIntl, FormattedMessage } from "react-intl";
import { SpinnerContext } from '../../Context/SpinnerContext';
import { COUNTRIES } from '../../Constants/Countries';
import { countryTranslated } from '../Translation/Country';
import UserService from '../../Services/UserService';
import CompanyService from '../../Services/CompanyService';


const useStyles = makeStyles((theme) => ({
    rowButton: {
        padding: '6px'
    },
}));

export default function Sites() {
    const { updateNotification } = useContext(NotificationContext);
    const [isLoading, setIsLoading] = useState(true);
    const { updateTitle } = useContext(AppBarTitleContext);
    const { updateLoading } = useContext(SpinnerContext);
    const { user } = useContext(UserContext);
    const intl = useIntl();
    const location = useLocation();
    const classes = useStyles();

    const [users, setUsers] = useState([]);
    const [usersLoading, setUsersLoading] = useState(false);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [deleteUuid, setDeleteUuid] = useState(null);
    const [forceRefresh, setForceRefresh] = useState(false);
    const [company, setCompany] = useState(null);
    const [tableData, setTableData] = useState({
        page: 0,
        count: 1,
        rowsPerPage: 20,
        sortOrder: {name: 'name', direction: 'asc'},
        data: [],
        searchText: '',
    });
    const searchParams = new URLSearchParams(location.search);
    const companyId = searchParams.get('company');
    const urlParamSearch = searchParams.get('search') ? searchParams.get('search') : null;
    const history = useHistory();

    const hiddenOptions = {
        display: false,
        viewColumns: false,
        filter: false,
        sort: false,
        searchable: false,
        print: false,
        download: false
    };

    const columns = [{
            name: 'id',
            options: hiddenOptions
        },
        {
            label: intl.formatMessage({id: 'sites.nameColumn', defaultMessage: 'Site name'}),
            name: 'attributes.name',
        },
        {
            name: 'relationships.company',
            label: intl.formatMessage({id: 'sites.companyColumn', defaultMessage: 'Company'}),
            options: user && user.role === 'admin' ? {
                sort: false,
                customBodyRender: (value) => (
                    <Link style={{ textDecoration: 'underline' }} component={RouterLink} to={'/companies/'+value.id}>{value.data.name}</Link>
                )
            } : hiddenOptions
        },
        {
            name: 'attributes.code',
            label: intl.formatMessage({id: 'sites.codeColumn', defaultMessage: 'Code'}),
            options: user && user.role !== 'admin' ? hiddenOptions : {}
        },
        {
            name: 'attributes.countryCode',
            label: intl.formatMessage({id: 'sites.countryColumn', defaultMessage: 'Country'}),
            options: {
                sort: false,
                customBodyRender: (value) => (
                    COUNTRIES[value] ? countryTranslated(COUNTRIES[value], intl) + ' - ('+value+')' : value
                )
            }
        },
        {
            name: 'attributes.industry',
            label: intl.formatMessage({id: 'sites.industryColumn', defaultMessage: 'Industry'}),
        },
        {
            name: 'relationships.site-mapping',
            label: intl.formatMessage({id: 'sites.mappingColumn', defaultMessage: 'Mapping'}),
            options: {
                display: user && user.role === 'admin',
                customBodyRender: (mappedSiteValues) => {
                    if (mappedSiteValues) {
                        return <ul>
                            {mappedSiteValues.data['site-values'].map((m, i) => <li key={i}>{m}</li>)}
                        </ul>
                    }
                    return null;
                }
            }
        },
        {
            name: 'attributes.createdAt',
            label: intl.formatMessage({id: 'sites.createdColumn', defaultMessage: 'Created'}),
            options: {
                display: location.search === '',
                customBodyRender: (value) => {
                    if (value) {
                        // DD MMM YYYY, i.e. "01 Jan 2021"
                        return <span title={value}>{(new Date(value)).toLocaleDateString('en-gb', {year: 'numeric', month: 'short', day: 'numeric'})}</span>
                    }
                    return '';
                }
            }
        },
        {
            name: 'attributes.updatedAt',
            label: intl.formatMessage({id: 'sites.updatedColumn', defaultMessage: 'Updated'}),
            options: {
                display: location.search === '',
                customBodyRender: (value) => {
                    if (value) {
                        // DD MMM YYYY, i.e. "01 Jan 2021"
                        return <span title={value}>{(new Date(value)).toLocaleDateString('en-gb', {year: 'numeric', month: 'short', day: 'numeric'})}</span>
                    }
                    return '';
                }
            }
        },
        {
            name: 'relationships',
            label: intl.formatMessage({id: 'generic.surveys', defaultMessage: 'Surveys'}),
            options: {
                display: location.search !== '',
                filter: false,
                sort: false,
                searchable: false,
                customBodyRender: (relationships, tableMeta) => {
                    if (usersLoading) {
                        return <CircularProgress color="primary" size={20} />
                    } else if (relationships.surveys) {
                        return (<div style={{margin: 5}}>
                            {relationships.surveys.map((survey, i) => (
                                <Fragment key={i}>
                                    <strong>{survey.data.title}</strong>
                                    <ul>
                                        {users.filter(u => {
                                            // console.log(u.relationships);
                                            if (!('user-permissions' in u.relationships)) {
                                                return false;
                                            }

                                            // Does permission include current survey?
                                            const surveyAccess = u.relationships['user-permissions'].some(rel => {
                                                if (!Array.isArray(rel.data.permission.survey) || rel.data.permission.survey.length === 0) {
                                                    return false;
                                                }

                                                // User has access to all surveys
                                                if (rel.data.permission.survey.length === 1 && rel.data.permission.survey[0] === null) {
                                                    return true;
                                                }

                                                return -1 !== rel.data.permission.survey.indexOf(survey.data.combinedHash);
                                            });

                                            // Does permission include current site?
                                            const siteAccess = u.relationships['user-permissions'].some(rel => {
                                                if (!Array.isArray(rel.data.permission.site) || rel.data.permission.site.length === 0) {
                                                    return false;
                                                }

                                                // User has access to all sites
                                                if (rel.data.permission.site.length === 1 && rel.data.permission.site[0] === null) {
                                                    return true;
                                                }

                                                return -1 !== rel.data.permission.site.indexOf(tableMeta.rowData[0]);
                                            });

                                            // Show user if they have access to survey AND site
                                            return surveyAccess && siteAccess;
                                        }).map((u,j) => (
                                            <li key={j}>{u.attributes.name} - ({u.attributes.email})</li>
                                        ))}
                                    </ul>
                                </Fragment>
                            ))}
                        </div>);
                    } else {
                        return null;
                    }
                }
            }
        },
        {
            name: 'id',
            label: ' ',
            options: {
                sort: false,
                customBodyRender: (value) => (
                    <Fragment>
                        <Tooltip title={(intl.formatMessage({id: 'generic.edit', defaultMessage: 'Edit'}))}>
                            <IconButton className={classes.rowButton} component={RouterLink} to={'/sites/'+value}>
                                <EditIcon fontSize="small" style={{ color: '#f57c00'}} />
                            </IconButton>
                        </Tooltip>
                        { user && user.role === 'admin'  ?
                            <Tooltip title={(intl.formatMessage({id: 'generic.delete', defaultMessage: 'Delete'}))}>
                                <IconButton className={classes.rowButton} color="secondary" onClick={() => {setDeleteUuid(value); setDeleteDialogOpen(true)}}>
                                    <DeleteIcon fontSize="small" />
                                </IconButton>
                            </Tooltip>
                        : null}
                    </Fragment>
                ),
                setCellProps: () => ({
                    align: 'right'
                }),
            }
        },
    ];

    const options = {
        textLabels: {
            body: {
                noMatch: isLoading ? '...' : intl.formatMessage({id: 'generic.noData', defaultMessage: 'No data...'}),
            }
        },
        tableBodyHeight: 'auto',
        searchPlaceholder: intl.formatMessage({id: 'sites.searchPlaceholder', defaultMessage: 'Site name/industry/code/country code/company name...'}),
        enableNestedDataAccess: '.',
        selectableRows: 'none',
        customSearchRender: debounceSearchRender(500),
        searchText: urlParamSearch,
        filter: false,
        searchOpen: false,
        download: false,
        print: false,
        viewColumns: false,
        elevation: 0,
        serverSide: true,
        count: tableData.count,
        rowsPerPage: tableData.rowsPerPage,
        rowsPerPageOptions: [20, 50, 100],
        onChangeRowsPerPage: (newPageSize) => setTableData(prevTableData => ({...prevTableData, rowsPerPage: newPageSize})),
        sortOrder: tableData.sortOrder,
        onTableChange: (action, tableState) => {
            const sortCol = tableState.sortOrder.name ? (tableState.sortOrder.name.replace('attributes.', '')) : 'title';
            const sortDir = tableState.sortOrder.direction ? (tableState.sortOrder.direction === 'asc' ? '' : '-') : '';

            switch (action) {
                case 'sort':
                case 'changePage':
                    fetchSites(tableState.page, tableState.rowsPerPage, sortDir+sortCol, tableState.searchText);
                    break;

                case 'search':
                    fetchSites(tableState.page, tableState.rowsPerPage, sortDir+sortCol, tableState.searchText)
                    break;

                case 'propsUpdate':
                    if (forceRefresh) {
                        setForceRefresh(false, fetchSites(tableState.page, tableState.rowsPerPage, sortDir+sortCol, tableState.searchText));
                    }
                    break;

                case 'changeRowsPerPage':
                    // console.log(tableData, tableState);
                    fetchSites(tableState.page, tableState.rowsPerPage, sortDir+sortCol, tableState.searchText);
                    break;

                default:
                    console.log(action, 'action not handled.');
            }
        },
    };

    const fetchSites = (page, pageSize, sort, search = null) => {
        search = search === '' ? null : search;
        const queryParams = [];
        if (companyId) {
            queryParams.push('company='+companyId);
        }
        if (search !== null) {
            queryParams.push('search='+search);
        }
        history.push({search: queryParams.join('&')});
        console.log('FETCH#', page, pageSize, search);
        setIsLoading(true);
        const filter = {};

        if (companyId) {
            filter['company'] = companyId;
        }
        SiteService.sites(page*pageSize, pageSize, sort, search, filter)
        .then(function (response) {
            setTableData({
                data: response.data.data,
                count: response.data.meta.count,
                rowsPerPage: pageSize,
                searchText: search,
                page: page
            });
        }).catch(function (error) {
            updateNotification(true, intl.formatMessage({id: 'generic.unknownError', defaultMessage: 'An unknown error occurred!'}), 'error');
        }).then(function() {
            setIsLoading(false);
        });
    };

    useEffect(() => {
        if (companyId) {
            // Fetch users for company
            setUsersLoading(true);
            UserService.allUsers('name', null, {company: companyId}).then(response => {
                setUsers(response);
            }).catch(error => {
                console.log(error);
            }).then(function() {
                setUsersLoading(false);
            });

            // Fetch company info
            CompanyService.company(companyId).then(response => {
                setCompany(response.data.data);
            }).catch(error => {
                console.log(error);
            });
        } else {
            setCompany(null);
        }

        fetchSites(0, tableData.rowsPerPage, 'name', urlParamSearch);
        updateTitle(null);
        // eslint-disable-next-line
    }, [companyId]);

    const handleDelete = () => {
        // To avoid multiple submits
        if (!deleteDialogOpen) {
            return;
        }
        setDeleteDialogOpen(false);
        updateLoading(true);

        SiteService.delete(deleteUuid)
        .then(function (response) {
            setDeleteUuid(null);
            setForceRefresh(true);
        }).catch(function (error) {
            if (error.response && error.response.status) {
                if (error.response.data.error.detail) {
                    updateNotification(true, error.response.data.error.detail, 'error');
                } else {
                    updateNotification(true, intl.formatMessage({id: 'generic.unknownError', defaultMessage: 'An unknown error occurred!'}), 'error');
                }
            } else {
                updateNotification(true, intl.formatMessage({id: 'generic.unknownError', defaultMessage: 'An unknown error occurred!'}), 'error');
            }
            console.error(error);
        }).then(function () {
            updateLoading(false);
        });
    }

    return(
        <Fragment>
            <Grid container spacing={0} justifyContent="center">
                <Grid item sm={12}>
                    <LocalBackdrop open={isLoading} transitionDuration={600}>
                        <CircularProgress color="secondary" size={40} />
                    </LocalBackdrop>
                    <MUIDataTable
                        title={company ? 'Sites for: '+company.attributes.name : ''}
                        data={tableData.data}
                        columns={columns}
                        options={options}
                    />
                </Grid>
            </Grid>
            <Dialog
                open={deleteDialogOpen}
                onClose={() => {setDeleteDialogOpen(false)}}
            >
                <DialogTitle><FormattedMessage id="generic.delete" defaultMessage="Delete" /></DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <FormattedMessage id="sites.confirmDelete" defaultMessage="This will delete the site and all associated data, continue?" />
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={() => {setDeleteDialogOpen(false)}} color="primary" autoFocus>
                        <FormattedMessage id="generic.cancel" defaultMessage="Cancel" />
                    </Button>
                    <Button variant="contained" onClick={handleDelete} color="secondary">
                        <FormattedMessage id="generic.confirm" defaultMessage="Confirm" />
                    </Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    );
}
