import { Table, TableBody, TableRow, Box, Tabs, Tab, makeStyles, TableCell, withStyles, Badge } from "@material-ui/core";
import Alert from '@material-ui/lab/Alert';
import { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

const useStyles = makeStyles(theme => ({
    tabs: {
        backgroundColor: theme.palette.primary.main,
        color: 'white'
    }
}));

const StyledTableRow = withStyles((theme) => ({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }))(TableRow);


export default function QualitativeComments(props) {
    const intl = useIntl();
    const classes = useStyles();
    const [activeTab, setActiveTab] = useState(0);

    const handleTabChange = (event, newValue) => {
        setActiveTab(newValue);
    };

    if (!props.reportData.data) {
        return null;
    }

    const tabTitles = (alias) => {
        switch (alias) {
            case 'challenges':
            case 'barriers':
                return intl.formatMessage({id:'qualitativeCommentsReport.barriers', defaultMessage: 'Barriers'});

            case 'positives':
                return intl.formatMessage({id:'qualitativeCommentsReport.positives', defaultMessage: 'Positives'});

            case 'quality':
                return intl.formatMessage({id:'qualitativeCommentsReport.quality', defaultMessage: 'Perception of Quality'});

            case 'general':
                return intl.formatMessage({id:'qualitativeCommentsReport.general', defaultMessage: 'General'});

            case 'covid':
                return intl.formatMessage({id:'qualitativeCommentsReport.covid', defaultMessage: 'Covid'});

            default:
                return alias.charAt(0).toUpperCase() + alias.slice(1);
        }
    };

    return (
        <Box>
            <Alert style={{justifyContent: 'center'}} severity="info" variant="filled">
                <FormattedMessage id="qualitativeCommentsReport.topInfoNote" defaultMessage="The comments are taken directly from the survey and are not edited or filtered in any way"/>
            </Alert>
            <Tabs value={activeTab} onChange={handleTabChange} className={classes.tabs} variant="fullWidth">
                {Object.keys(props.reportData.data).map((type, index) => (
                    <Tab label={<Badge overlap="rectangular" badgeContent={props.reportData.data[type].length} color="secondary" style={{ padding: '0px 12px 0px 0px' }} max={999}>{tabTitles(type)}</Badge> } key={index} />
                ))}
            </Tabs>
            {Object.keys(props.reportData.data).map((type, index) => {
                if (index !== activeTab) {
                    return null;
                }

                return (
                    <Table key={index}>
                        <TableBody>
                            {props.reportData.data[type].map((comment, commentIndex) =>
                                <StyledTableRow key={commentIndex}>
                                    <TableCell>{comment}</TableCell>
                                </StyledTableRow>
                            )}
                        </TableBody>
                    </Table>
                );
            })}
        </Box>
    );
}
