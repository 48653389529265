import axios from 'axios';
import BaseService from './BaseService';

class DemographicOptionLabelService extends BaseService {
    labelsForSurvey(surveyCombinedHash) {
        const params = {'filter[surveyCombinedHash]': surveyCombinedHash};

        return axios.get(this.apiUrl('/demographic-option-labels'), {
            params: params,
            headers: this.getAuthHeader()
        });
    }

    create(data) {
        return axios.post(this.apiUrl('/demographic-option-labels'), {
            data: {
                type: 'demographic-option-label',
                attributes: data,
            },
        },{
            headers: this.getAuthHeader()
        });
    }

    patch(uuid, data) {
        return axios.patch(this.apiUrl('/demographic-option-labels/'+uuid), {
            data: {
                type: 'demographic-option-label',
                attributes: data
            }
        },{
            headers: this.getAuthHeader()
        });
    }

    delete(uuid) {
        return axios.delete(this.apiUrl('/demographic-option-labels/'+uuid), {
            headers: this.getAuthHeader()
        });
    }
}

export default new DemographicOptionLabelService();
