import { Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import { Fragment } from "react";
import { FormattedMessage } from "react-intl";
import ElementLabel from "../../../Translation/ElementLabel";
import { hotspotDemographicLabel } from "./Common";
import { CATEGORY_COLORS } from '../../../../Constants/CategoryColors';

export default function LowestHotspots(props) {
    const elementsColStyle = {
        whiteSpace: 'normal',
        wordWrap: 'break-word',
        width: '45%',
        paddingTop: '1px',
        paddingBottom: '1px',
        border: '1px solid #e0e0e0',
        height: 40
    };

    if (!props.reportData['element-hotspots'].all) {
        return null;
    }

    const bottomHotspots = props.reportData['element-hotspots'].all.slice().reverse().slice(0, 6);

    return (
        <Fragment>
            { props.title && props.print ? <h2 style={{fontWeight: 400}}>{props.title}</h2> : null}
            <p>
                <FormattedMessage id="execSummarLowHotspots.p1"
                    defaultMessage={
                        "The lowest hotspots are the lowest element combinations in the data, and show where specific "+
                        "cultural sub-groups within the company have particularly low scores. The lowest hotspot score in "+
                        "this case was {lowestHotspotScore}. Six of the lowest hotspots "+
                        "are shown below as an example, and the full range can be viewed in the <strong>Hotspots</strong> "+
                        "view and filtered by Culture Type for most relevance."
                    }
                    values= {{
                        lowestHotspotScore:  bottomHotspots.length ? Math.round(bottomHotspots[0].score)+'%' : '-',
                        strong: chunks => <strong>{chunks}</strong>
                    }}
                />
            </p>

            <Table className="reportTable elementsReport">
                <TableHead style={{backgroundColor: '#3c3e40'}}>
                    <TableRow>
                        <TableCell style={{ ...elementsColStyle, ...{color: 'white', width:'45%', fontSize: '9pt', lineHeight: 1.3, padding: 6}}}>
                            <FormattedMessage id="elementHotspotsReport.lowestDemographicCombinations" defaultMessage="Lowest Demographic Combinations" />
                        </TableCell>
                        <TableCell style={{ ...elementsColStyle, ...{color: 'white', width:'45%', fontSize: '9pt', lineHeight: 1.3, padding: 6}}}>
                            <FormattedMessage id="generic.element" defaultMessage="Element" />
                        </TableCell>
                        <TableCell style={{ ...elementsColStyle, ...{color: 'white', textAlign: 'center', width:'10%', fontSize: '9pt', lineHeight: 1.3, padding: 6}}}>
                            <FormattedMessage id="generic.scorePercent" defaultMessage="Score (%)" />
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {bottomHotspots.map((element, index) => {
                        return (
                            <TableRow key={index} style={{backgroundColor: CATEGORY_COLORS[element.category].light, borderBottom: '10px solid white'}}>
                                <TableCell style={{width: '100pt', borderLeft: '5px solid '+CATEGORY_COLORS[element.category].dark, fontSize: '9pt', lineHeight: 1.3, padding: 6}}>
                                    {hotspotDemographicLabel(element, props.demographicQuestions, props.demographicOptionLabels, props.print)}
                                </TableCell>
                                <TableCell style={{fontSize: '9pt', lineHeight: 1.3, padding: 6}}>
                                    <ElementLabel element={element.element} />
                                </TableCell>
                                <TableCell style={{fontSize: '9pt', lineHeight: 1.3, padding: 6}}>
                                    {parseFloat(element.score).toFixed(1)}
                                </TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </Fragment>
    );
}
