import { Button, Box, Grid, Paper, Table, TableBody, TableCell as MuiTableCell, TableContainer, TableRow, Typography, withStyles, makeStyles } from "@material-ui/core";
import { SSAFE_CATEGORY_COLORS } from '../../../Constants/SsafeCategoryColors';
import { NavLink } from "react-router-dom";
import peopleLogo from '../img/category/people.svg';
import proactivityLogo from '../img/category/proactivity.svg';
import purposeLogo from '../img/category/purpose.svg';
import processLogo from '../img/category/process.svg';
import NoData from "../../NoData/NoData";
import { FormattedMessage, useIntl } from "react-intl";
import CategoryLabel from "../../Translation/CategoryLabel";
import DimensionLabel from "../../Translation/DimensionLabel";
import { Hidden } from "@material-ui/core";


const TableCell = withStyles({
    root: {
        borderBottom: "none"
    }
})(MuiTableCell);

const useStyles = makeStyles((theme) => ({
    dimensionColumn: {
        [theme.breakpoints.up('sm')]: {
            textAlign: 'right'
        },
        [theme.breakpoints.down('xs')]: {
            textAlign: 'left'
        },
        width: "30%",
        textAlign: 'left',
        fontSize: '1rem'
    },
}));

export default function SsafeBasicData(props) {
    const intl = useIntl();
    const classes = useStyles();

    const categoryLogos = {
        people: peopleLogo,
        proactivity: proactivityLogo,
        purpose: purposeLogo,
        process: processLogo,
    };

    if (!props.reportData.data || 0 === Object.keys(props.reportData.data).length) {
        return null;
    }

    const upgradeTexts = {
        control: intl.formatMessage({id: 'ssafeBasicData.processUpgrade', defaultMessage: 'The Process Category is not assessed in the SSAFE assessment.'}),
        vision: intl.formatMessage({id: 'ssafeBasicData.purposeUpgrade', defaultMessage: 'The Purpose Category is not assessed in the SSAFE assessment.'}),
        awareness: intl.formatMessage({id: 'ssafeBasicData.proactivityUpgrade', defaultMessage: 'The Proactivity Category is not assessed in the SSAFE assessment.'})
    };

    if (null === props.reportData.data.total.score) {
        return <NoData />;
    }

    const upgrade = (dimension, small) => (<Paper variant="outlined">
            <Box p={1} textAlign='center'>
                <Typography variant={small ? 'body1' : 'subtitle1'} align="center">
                    {upgradeTexts[dimension]}
                </Typography>
                <Box pt={1} pb={1} textAlign="center">
                    <Button size={small ? 'small' : 'medium'} variant="contained" color="primary" component={NavLink} to="/upgrade/ssafe" style={{fontSize: small ? '12px' : null, backgroundColor: SSAFE_CATEGORY_COLORS['people'].dark}}>
                        <FormattedMessage id="ssafeBasicData.learnMoreButton" defaultMessage="Learn more about a full assessment" />
                    </Button>
                </Box>
            </Box>
        </Paper>);

    return (
        <div>
            {Object.keys(props.reportData.data.categories).map(categoryKey => (
                <Box pb={2} key={categoryKey}>
                    <Paper key={categoryKey} elevation={1} style={{borderLeft: '5px solid '+SSAFE_CATEGORY_COLORS[categoryKey].dark}}>
                        <Grid container direction="row" justifyContent="center" alignItems="center" style={{backgroundColor: SSAFE_CATEGORY_COLORS[categoryKey].light}} spacing={0}>
                            <Grid item sm={2} align="center">
                                <div style={(categoryKey === 'people' ? {} : {opacity: '0.5'} )}>
                                    <img style={{ maxWidth: '128px', maxHeight: "100%" }} alt={categoryKey} src={categoryLogos[categoryKey]} /><br />
                                    <Typography variant="subtitle1" style={{fontWeight: 500}}>
                                        <CategoryLabel category={categoryKey} upperCase={true} />
                                    </Typography>
                                </div>
                            </Grid>
                            <Grid item sm={2} xs={12} align="center">
                                <div style={(categoryKey === 'people' ? {} : {opacity: '0.5'} )}>
                                    <Typography title={props.reportData.data.categories[categoryKey].score ? props.reportData.data.categories[categoryKey].score : ''} variant="h2" style={{fontWeight: 'normal'}}>{props.reportData.data.categories[categoryKey].roundedScore ? props.reportData.data.categories[categoryKey].roundedScore : '-'}</Typography>
                                </div>
                            </Grid>
                            <Grid item sm={8} align="left">
                                <Box pb={2} pt={2}>
                                    <TableContainer>
                                        <Table size="small">
                                            <TableBody>
                                                {Object.keys(props.reportData.data.categories[categoryKey].dimensions).map(dimensionKey => (
                                                    <TableRow key={dimensionKey}>
                                                        <TableCell className={classes.dimensionColumn}>
                                                            <div style={(categoryKey === 'people' ? {} : {opacity: '0.5'})}>
                                                                <DimensionLabel dimension={dimensionKey} />
                                                            </div>
                                                        </TableCell>
                                                        <TableCell style={{ width: "10%", textAlign: 'right', fontWeight: 'bold', paddingRight: '24px', fontSize: '1rem' }}>
                                                            <span style={(categoryKey === 'people' ? {} : {opacity: '0.5'})} title={props.reportData.data.categories[categoryKey].dimensions[dimensionKey].score ? props.reportData.data.categories[categoryKey].dimensions[dimensionKey].score : ''}>
                                                                {null !== props.reportData.data.categories[categoryKey].dimensions[dimensionKey].roundedScore ? props.reportData.data.categories[categoryKey].dimensions[dimensionKey].roundedScore : '-'}
                                                            </span>
                                                        </TableCell>
                                                        {['empowerment', 'reinforcement', 'teamwork', 'training', 'communication'].includes(dimensionKey) ?
                                                            <Hidden xsDown>
                                                                <TableCell style={{ width: "60%" }}>
                                                                    {props.reportData.data.categories[categoryKey].dimensions[dimensionKey].roundedScore ?
                                                                        <div style={{backgroundColor: SSAFE_CATEGORY_COLORS['people'].dark, height: '15px', width: Math.round(props.reportData.data.categories[categoryKey].dimensions[dimensionKey].roundedScore) + '%'}} />
                                                                    : null}
                                                                </TableCell>
                                                            </Hidden>
                                                        : null}
                                                        {['control', 'vision', 'awareness'].includes(dimensionKey) ?
                                                            <Hidden xsDown>
                                                                <TableCell style={{ width: '60%' }} rowSpan={5}>
                                                                    {upgrade(dimensionKey, false)}
                                                                </TableCell>
                                                            </Hidden>
                                                        : null}
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                    {Object.keys(props.reportData.data.categories[categoryKey].dimensions).map((dimensionKey, index) => (
                                        ['control', 'vision', 'awareness'].includes(dimensionKey) ?
                                            <Hidden smUp key={index+dimensionKey}>
                                                <Box p={1}>
                                                    {upgrade(dimensionKey, true)}
                                                </Box>
                                            </Hidden>
                                        : null
                                    ))}
                                </Box>
                            </Grid>
                        </Grid>
                    </Paper>
                </Box>
            ))}
        </div>
    );

}