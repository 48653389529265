import { useIntl } from "react-intl";
import { demographicOptionTranslated } from "./DemographicOption";
import { UserContext } from "../../Context/UserContext";
import { useContext } from "react";
import { Badge, Tooltip, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    info: {
        backgroundColor: theme.palette.info.main,
        color: '#fff',
        opacity: 0.7,
        '@media print' : {
            display: 'none'
        }
    },
}));

export default function DemographicOptionLabel(props) {
    const classes = useStyles();
    const intl = useIntl();
    const { user } = useContext(UserContext);
    const label = demographicOptionTranslated(props.option, props.translations, intl, props.demographic, props.demographicOptionLabels);

    let showOverride = false;
    if (user && user.role === 'admin') {
        // Find out if the label is overridden
        const labelsOverride = props.demographicOptionLabels ? props.demographicOptionLabels.find(dol => dol.attributes.demographic === props.demographic) : null;
        showOverride = labelsOverride && labelsOverride.attributes.labels && props.option in labelsOverride.attributes.labels;
    }

    if (showOverride && !props.hideOverride) {
        return (
            <Tooltip title="Overridden" placement="right">
                <Badge badgeContent={'i'} overlap="rectangular" classes={{ badge: classes.info }}>
                    {label}
                </Badge>
            </Tooltip>
        );
    } else {
        return (
            <span>{label}</span>
        );
    }
}
