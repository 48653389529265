import { Box, Grid, Paper, Table, TableBody, TableCell as MuiTableCell, TableContainer, TableRow, Typography, withStyles, Tooltip, IconButton } from "@material-ui/core";
import { useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { CATEGORY_COLORS } from '../../../Constants/CategoryColors';
import FilterListIcon from '@material-ui/icons/FilterList';
import peopleLogo from '../img/category/people.svg';
import proactivityLogo from '../img/category/proactivity.svg';
import purposeLogo from '../img/category/purpose.svg';
import processLogo from '../img/category/process.svg';
import DimensionLabel from "../../Translation/DimensionLabel";
import CategoryLabel from "../../Translation/CategoryLabel";

const TableCell = withStyles({
    root: {
        borderBottom: "none"
    }
})(MuiTableCell);

export default function BasicData(props) {
    const intlRef = useRef();
    intlRef.current = useIntl();
    const [sort, setSort] = useState(null);
    const setSortRef = useRef();
    setSortRef.current = (s) => {
        setSort(s);
    };
    const setButtonRef = useRef();
    setButtonRef.current = (b) => {
        props.setTopLeftButton(b);
    };

    useEffect(() => {
        const sortButton = (
            <Tooltip title={intlRef.current.formatMessage({id: 'generic.sort', defaultMessage: 'Sort'})}>
                <IconButton
                    size="small"
                    color="inherit"
                    onClick={() => {
                        if (sort === null) {
                            setSortRef.current(true);
                        } else if (sort === true) {
                            setSortRef.current(false);
                        } else {
                            setSortRef.current(null);
                        }
                    }}
                >
                    <FilterListIcon style={sort === null ? {opacity: 0.5} : (sort === true ? {} : {transform: 'rotate(180deg)'} )} />
                </IconButton>
            </Tooltip>
        );
        setButtonRef.current(sortButton);

        return () => {
            // Clear top left button on unmount}
            setButtonRef.current(null);
        }
    }, [sort]);

    const categoryLogos = {
        people: peopleLogo,
        proactivity: proactivityLogo,
        purpose: purposeLogo,
        process: processLogo,
    };

    if (!props.reportData.data || 0 === Object.keys(props.reportData.data).length) {
        return null;
    }

    const sortedCategoryKeys = null === sort ? Object.keys(props.reportData.data.categories) : Object.keys(props.reportData.data.categories).sort((a, b) => {
        const aScore = parseFloat(props.reportData.data.categories[a].score ? props.reportData.data.categories[a].score : '0');
        const bScore = parseFloat(props.reportData.data.categories[b].score ? props.reportData.data.categories[b].score : '0');

        if (sort) {
            return aScore > bScore ? -1 : 1;
        }
        return aScore > bScore ? 1 : -1;
    });

    const sortedDimensionKeysByCategory = {};
    Object.keys(props.reportData.data.categories).forEach(curCat => {
        if (null === sort) {
            sortedDimensionKeysByCategory[curCat] = Object.keys(props.reportData.data.categories[curCat].dimensions);
        } else {
            sortedDimensionKeysByCategory[curCat] = Object.keys(props.reportData.data.categories[curCat].dimensions).sort((a, b) => {
                const aScore = parseFloat(props.reportData.data.categories[curCat].dimensions[a].score ? props.reportData.data.categories[curCat].dimensions[a].score : '0');
                const bScore = parseFloat(props.reportData.data.categories[curCat].dimensions[b].score ? props.reportData.data.categories[curCat].dimensions[b].score : '0');

                if (sort) {
                    return aScore > bScore ? -1 : 1;
                }
                return aScore > bScore ? 1 : -1;
            });
        }
    });

    return (
        <Box p={0}>
            <div ref={props.setScreenshotRef} >
                {sortedCategoryKeys.map((categoryKey, i) => (
                    <Box pb={i === 3 ? 0 : 2} key={categoryKey}>
                        <Paper key={categoryKey} elevation={1} style={{borderLeft: '5px solid '+CATEGORY_COLORS[categoryKey].dark}}>
                            <Grid container direction="row" justifyContent="center" alignItems="center" style={{backgroundColor: CATEGORY_COLORS[categoryKey].light}}>
                                <Grid item sm={2} align="center">
                                    <img style={{ maxWidth: '128px', maxHeight: "100%" }} alt={categoryKey} src={categoryLogos[categoryKey]} />
                                    <Typography variant="subtitle1">
                                        <CategoryLabel category={categoryKey} upperCase={true} />
                                    </Typography>
                                </Grid>
                                <Grid item sm={8} align="left">
                                    <Box pb={2} pt={2}>
                                        <TableContainer>
                                            <Table size="small">
                                                <TableBody>
                                                    {sortedDimensionKeysByCategory[categoryKey].map(dimensionKey => (
                                                        <TableRow key={dimensionKey}>
                                                            <TableCell style={{ width: "30%", textAlign: 'right' }}>
                                                                <DimensionLabel dimension={dimensionKey} />
                                                            </TableCell>
                                                            <TableCell style={{ width: "10%", textAlign: 'right', fontWeight: 'bold' }}>
                                                                <span title={props.reportData.data.categories[categoryKey].dimensions[dimensionKey].score ? props.reportData.data.categories[categoryKey].dimensions[dimensionKey].score : ''}>
                                                                    {null !== props.reportData.data.categories[categoryKey].dimensions[dimensionKey].roundedScore ? props.reportData.data.categories[categoryKey].dimensions[dimensionKey].roundedScore : '-'}
                                                                </span>
                                                            </TableCell>
                                                            <TableCell style={{ width: "60%" }}>
                                                                {props.reportData.data.categories[categoryKey].dimensions[dimensionKey].roundedScore ?
                                                                    <div style={{backgroundColor: CATEGORY_COLORS[categoryKey].dark, height: '15px', width: Math.round(props.reportData.data.categories[categoryKey].dimensions[dimensionKey].roundedScore) + '%'}} />
                                                                : ''}
                                                            </TableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Box>
                                </Grid>
                                <Grid item sm={2} align="center">
                                    <Typography title={props.reportData.data.categories[categoryKey].score ? props.reportData.data.categories[categoryKey].score : ''} variant="h2" style={{fontWeight: 'normal'}}>{props.reportData.data.categories[categoryKey].roundedScore ? props.reportData.data.categories[categoryKey].roundedScore : '-'}</Typography>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Box>
                ))}
            </div>
        </Box>
    );
}