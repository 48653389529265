export const sectorTranslated = (sector, intl) => {
    switch (sector) {
        case 'Consumer products':
            return intl.formatMessage({id: 'sector.consumerProducts', defaultMessage: 'Consumer products'});

        case 'Equipment / packaging':
            return intl.formatMessage({id: 'sector.equipmentPackaging', defaultMessage: 'Equipment / packaging'});

        case 'Food manufacturing':
            return intl.formatMessage({id: 'sector.foodManufacturing', defaultMessage: 'Food manufacturing'});

        case 'Office / corporate / ancilliary':
            return intl.formatMessage({id: 'sector.officeCorpAncil', defaultMessage: 'Office / corporate / ancilliary'});

        case 'Other':
            return intl.formatMessage({id: 'generic.other', defaultMessage: 'Other'});

        case 'Packing, storage and distribution':
            return intl.formatMessage({id: 'sector.packingStorageAndDistr', defaultMessage: 'Packing, storage and distribution'});

        case 'Primary production and processing':
            return intl.formatMessage({id: 'sector.primaryProductionAndProcessing', defaultMessage: 'Primary production and processing'});

        case 'Retail and food service':
            return intl.formatMessage({id: 'sector.retailAndFoodservice', defaultMessage: 'Retail and food service'})

        default:
            // Unknown region
            return sector;
    }
}
