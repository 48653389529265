import { Fragment } from "react";
import ElementsTable from "./ElementsTable";
import { FormattedMessage } from "react-intl";

export default function ChangeDimension(props) {

    return (
        <Fragment>
            <p>
                <FormattedMessage
                    id="execSummaryDiscAndRecs.change"
                    defaultMessage={
                        "Depending on the company, expected levels of innovation vary. A company focused on traditional methods "+
                        "of food production would not expect to score as highly as a company using the latest technology. "+
                        "However, the ability to change and stay up-to-date allows companies to continually improve, meet best "+
                        "practice standards, and excite and motivate employees, and most of the elements within this section "+
                        "focus on areas that the majority of companies would aspire to."
                    }
                />
            </p>
            <ElementsTable dimension={props.dimension} elements={props.elements} lowestElements={props.lowestElements} />
        </Fragment>
    );
}
